<template>
  <ul class="common_list_horizontal">
    <li class="quarter ">
      <my-select
      :name="main_name"
      v-model="main"
      :options="main_options"
      ></my-select>
    </li>
    <li class="quarter ">
      <my-select
      :name="other_name"
      v-model="other"
      :options="other_options"
      :disabled="isActive">
      </my-select>
    </li>
  </ul>
</template>

<script>
import MySelect from '@/components//MySelect.vue';


export default {
  components: {
    MySelect,
  },
  props: {
    initial_main: { type: String, required: true },
    initial_other: { type: String, required: true },
    main_name: { type: String, required: false },
    other_name: { type: String, required: false },
    main_options: { type: Object, required: true },
    other_options: { type: Object, required: true },
    disabled_number: { type: String, required: true },
    type: { type: String, required: true },
    placeholder: { type: String, required: false },
  },
  data() {
    return {
      main: this.initial_main,
      other: this.initial_other,
    }
  },
  watch: {
    main: function (e) {
      this.main = e
      if (e !== this.disabled_number) {
        this.other = ''
      }
      this.$emit('update:main', e)
    },
    other: function (e) {
      this.other = e
      this.$emit('update:other', e)
    },
  },
  computed: {
    isActive() {
      const var_country_main = this.main;
      if (var_country_main !== this.disabled_number) return true
      return false
    },

  }

  
};
</script>
