<template>
  <li v-for="(option, index) in options" :key="index">
    <label>
      <input type="checkbox" :name="name" :value="option.value" :checked="isChecked(option, modelValue)" @change="updateValue" :ref="setValuesRef" />
      <span class="checkbox_text">{{ option.label }}</span>
    </label>
  </li>
</template>

<script>
import MyCheckbox from './MyCheckbox'
export default {
  mixins: [
    MyCheckbox
  ],
};
</script>
