<template>
  <table class="common_table" v-if="list.length">
    <tbody>
      <tr>
        <th>公開日時</th>
        <th>対象サイト</th>
        <th>会社名</th>
        <th>タイトル</th>
        <th>ステータス</th>
        <th colspan="2" v-if="isShowUI">編集</th>
      </tr>

      <tr v-for="(val, key) in list" :key="key">
        <td>{{ displayDateStr(val.display_date, val.display_time) }}</td>
        <td>{{ val.target_site }}サイト</td>
        <td>{{ val.company_name }}</td>
        <td><a :href="val.press_release_url" target="_blank" rel=”noopener”>{{ val.press_release_title }}</a></td>
        <td>{{ displayStatus(val.display_date, val.display_time) }}</td>
        <td class="edit_cell" v-if="isShowUI">
          <a @click.prevent="editHandler($event, key)" href="#wax1" class="common_button_t2 modalOpen">変更</a>
        </td>
        <td class="edit_cell" v-if="isShowUI">
          <a @click.prevent="deleteHandler($event, key)" class="common_button_t2 gray_button delete_button">削除</a>
        </td>
      </tr>
    </tbody>
  </table>
  <div class="no_entry" v-else>登録がありません。「新規追加」ボタンより登録をお願いします。</div>
  <template v-if="isShowUI">
  <div>
    <a @click.prevent="newHandler" href="#wax1" class="common_add_button modalOpen">新規追加</a>
  </div>
  </template>
  <Teleport to="body">

    <!-- モーダルコンテンツ / -->
    <!-- 製作注意問い合わせ先は何種類かあります。読み込み形式で簡単に編集できるようにしたほうが良いかと思います。-->
    <div class="overlay" id="wax1">
      <div id="modal_content" class="modal_contents w80">
        <!-- modal contents ここから-->
        <div class="modalClose_ctn">
          <a @click.prevent="modalClose" class="modalClose"></a>
        </div>
        <div class="entry_title">
          <h4>プレスリリースリンク掲出登録</h4>
          <h5>プレスリリースリンク掲出登録&nbsp;&nbsp;{{ uploadLabel }}</h5>
        </div>
        <div class="entry_modal_ctn">
          <table class="common_table">
            <tbody>
              <tr>
                <th>公開日</th>
                <td><input type="date" :min="releaseDateMin" :max="releaseDateMax" v-model="form.display_date">
                  <p class="alert" v-if="errors.display_date">{{ errors.display_date }}</p>
                </td>
              </tr>
              <tr>
                <th nowrap="">公開希望時間</th>
                <td>
                  <div class="select_ctn">
                    <select name="display_time" v-model="form.display_time">
                      <option
                        v-for="(v, k) in timeList"
                        :key="k"
                        :value="v"
                        :selected="form.display_time === v">{{ v }}</option>
                    </select>
                  </div>
                </td>
              </tr>
              <tr>
                <th nowrap="">日本語/英語</th>
                <td>
                  <div class="select_ctn">
                    <select name="target_site" v-model="form.target_site">
                      <option
                        v-for="(v, k) in langOptions"
                        :key="k"
                        :value="v"
                        :selected="form.target_site === v">{{ v }}</option>
                    </select>
                  </div>
                  <p class="alert" v-if="errors.target_site">{{ errors.target_site }}</p>
                </td>
              </tr>
              <tr>
                <th nowrap="">会社名</th>
                <td><input name="company_name" type="text" class="full" v-model="form.company_name">
                  <p class="alert" v-if="errors.company_name">{{ errors.company_name }}</p></td>
              </tr>
              <tr>
                <th nowrap="">プレスリリースタイトル</th>
                <td><input name="press_release_title" type="text" class="full" v-model="form.press_release_title">
                  <p class="alert" v-if="errors.press_release_title">{{ errors.press_release_title }}</p></td>
              </tr>
              <tr>
                <th nowrap="">プレスリリースURL</th>
                <td><input name="press_release_url" type="text" class="full" v-model="form.press_release_url">
                  <p class="alert" v-if="errors.press_release_url">{{ errors.press_release_url }}</p></td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="button_ctn">
          <ul>
            <li>
              <button @click.prevent="modalClose" class="gray_button modalClose">キャンセル</button>
            </li>
            <li>
              <button @click="sendForm" class="regist_button">
                上記内容で登録
              </button>
            </li>
          </ul>
        </div>
        <!-- modal contents ここまで-->
      </div>
    </div>
    <!-- / モーダルコンテンツ -->
  </Teleport>
</template>

<script setup>
import { reactive, computed } from 'vue'
import APIClient from '@/store/repository/APIClient'
import { forEach, cloneDeep } from 'lodash'
import { FormValidate } from '@/helper/FormValidate'
import * as yup from 'yup'
import moment from "moment"

import {
  modalOpen,
  modalClose,
} from '@/helper/modal'

import ApiConst from '@/const/api'

import { isShowable as isShowUI } from '@/helper/indexStatus'

const langOptions = ['日本語', '英語']

const releaseDateMin = moment('2025-09-01').format("YYYY-MM-DD")
const releaseDateMax = moment('2025-11-10').format("YYYY-MM-DD")

const start = moment("2000-01-01 00:00:00")
const end = moment("2000-01-01 23:30:00")

const timeList = []

for ( const target = start.clone();target.isBefore(end);target.add(30, "minutes") ) {
  timeList.push(target.format("HH:mm"))
}

const api = new APIClient()

const list = reactive([])

const formInit = {
  application_id: null,
  display_date: "",
  display_time: "",
  company_name: "",
  press_release_title: "",
  press_release_url: "",
  target_site: "",
}
const form = reactive(cloneDeep(formInit))

const uploadLabel = computed(() => {
  return form.application_id ? '変更' : '新規登録'
})

const displayDateStr = (date, time) => {
  return moment(date + ' ' + time).format("MM-DD HH:mm")
}

const displayStatus = (date, time) => {
  if ( moment(date + ' ' + time).isBefore(moment()) ) return '公開済'
  return '未公開'
}

const fetch = async () => {
  const query = {
    form_no: 1,
    application_div: "pressrelease_link",
  }
  const fetchApi = await api.get(ApiConst.API_APP_LIST, query)
  const res = fetchApi.response
  const errors = res.data?.error_results
  if (errors) {
    throw new Error('Not found.')
  }
  list.splice(0)
  list.value = res.data.results.list.map((el) => {
    return {
      application_id: el.application_id,
      input_date: moment(el.updated_at).format("YY-MM-DD"),
      seq_no: el.seq_no,
      ...el.json_data
    }
  }).forEach((val, key) => {
    forEach(val, (v, k) => {
      val[k] = v ?? formInit[k]
    })
    list[key] = val
  })
}
fetch()

const newHandler = (event) => {
  forEach(formInit, (v, k) => {
    form[k] = v
  })
  modalOpen(event)
}

const editHandler = (event, key) => {
  forEach(form, (v, k) => {
    form[k] = list[key][k]
  })
  modalOpen(event)
}

const deleteHandler = async (event, key) => {
  if (!window.confirm('削除して宜しいですか？')) return false

  try {
    const application_id = list[key].application_id
    const operation = "D"
    const query = { operation, application_id }
    const res = await api.post(ApiConst.API_APP_EDIT, query)
    if (res.response.data?.error_results) {
      throw new Error('API Validation Error')
    }
    fetch()
  } catch (e) {
    return false
  }
}

const scheme = {
  display_date: yup.string().trim().required('必須の項目です。'),
  display_time: yup.string().trim().required('必須の項目です。'),
  company_name: yup.string().trim().required('必須の項目です。'),
  target_site: yup.string().trim().required('必須の項目です。'),
  press_release_title: yup.string().trim().required('必須の項目です。'),
  press_release_url: yup.string().url('URL形式で入力してください。').trim().required('必須の項目です。'),
}
const validate = new FormValidate(scheme, form)
const errors = validate.errors
const sendForm = async () => {
  try {
    if ((Object.keys(await validate.getErrorObject()).length)) {
      throw new Error('入力項目に不備があります。')
    }
    const params = cloneDeep(form)
    const application_id = params.application_id
    delete params.application_id
    const operation = application_id ? "R" : "I"
    const query = operation === "I"
      ? { operation, application_div: "pressrelease_link", form_no: 1, json_data: params }
      : { operation, application_id, json_data: params }
    const res = await api.post(ApiConst.API_APP_EDIT, query)
    if (res.response.data?.error_results) {
      throw new Error('API Validation Error')
    }
    fetch()
    modalClose()
  } catch (e) {
    return false
  }
}
</script>

<style></style>
