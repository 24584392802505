import APIClient from "@/store/repository/APIClient";
import { store } from '@/store'
import { useRoute } from 'vue-router';
import { includes } from 'lodash';
import MakerConst from '@/const/maker'

const api = new APIClient();

const MAKERS = MakerConst.EXHIBITOR_ID

// STAFF_AUTHORITY_MATRIX TABLE HEAD
//  0: 国内乗用車（会員）
//  1: 外国乗用車
//  2: 国産商用車
//  3: 外国商用車
//  4: 国産二輪車
//  5: 外国二輪車
//  6: 車体
//  7: 車体（屋外）
//  8: 車体（共同）
//  9: 部品
// 10: 部品（共同）
// 11: 次世代モビリティ関連
// 12: KidZania（会員）
// 13: KidZania（会員外）
// 14: スポンサー
// 15: コラボ・連携
// 16: スポンサー(事務局管理)
// 17: 主催者プログラム
// 18: 関連企画
// 19: 新聞雑誌
// 20: グッズ・飲食

const CATEGORY_DISP_CONFIG = {
  1: { label: "国内乗用車", staff_authority_index: 0},
  2: { label: "外国乗用車", staff_authority_index: 1},
  3: { label: "外国乗用車(主催者枠)", staff_authority_index: 1},
  4: { label: "国内商用車", staff_authority_index: 2},
  5: { label: "外国商用車", staff_authority_index: 3},
  6: { label: "国内二輪車", staff_authority_index: 4},
  7: { label: "外国二輪車", staff_authority_index: 5},
  8: { label: "カロッツェリア", staff_authority_index: null},
  9: { label: "車体(屋内)", staff_authority_index: 6},
  10: { label: "車体(屋外)", staff_authority_index: 7},
  11: { label: "車体(共同)", staff_authority_index: 8},
  12: { label: "部品・機械器具", staff_authority_index: 9},
  13: { label: "部品(共同)", staff_authority_index: 10},
  14: { label: "次世代モビリティ関連", staff_authority_index: 11},
  15: { label: "kidZania(会員)", staff_authority_index: 12},
  16: { label: "kidZania(会員外)", staff_authority_index: 13},
  17: { label: "スポンサー", staff_authority_index: 14},
  18: { label: "コラボ・連携", staff_authority_index: 15},
  19: { label: "スポンサー(事務局管理)", staff_authority_index: 16},
  20: { label: "主催者プログラム", staff_authority_index: 17},
  21: { label: "関連企画", staff_authority_index: 18},
  22: { label: "新聞雑誌", staff_authority_index: 19},
  23: { label: "グッズ・飲食", staff_authority_index: 20},
  99: { label: "その他", staff_authority_index: null},
}

const PAGE_NAME_LABELS = {
  "p3_publicity_exhibitors_list" : "出展者一覧（リンクURL登録）",
  "p3_publicity_num_exhibits" : "出展車両数一覧",
  "p3_publicity_booth_info" : "ブース情報",
  "p3_publicity_exhibits_list" : "出展物リスト",
  "p3_publicity_pressrelease_link" : "プレスリリースリンク掲出登録",
  "p3_exhibition_collaboration": "協業情報",
  "p3_exhibition_lot_worker": "小間内装飾業者届 *",
  "p3_exhibition_lot_planning": "小間設計図届 *",
  "p3_exhibition_carry_inout": "計画搬入出届",
  "p3_exhibition_tax_cargo": "保税貨物明細",
  "p3_exhibition_cleaning": "小間内清掃、ワックス清掃申込",
  "p3_exhibition_anchor_bolt": "アンカーボルト使用届",
  "p3_exhibition_ceiling_structure": "天井構造物設置届",
  "p3_exhibition_roof_hang": "天井吊下げ施工届",
  "p3_exhibition_lighting_change": "施設天井照明変更申込",
  "p3_exhibition_detector": "無線式煙（熱）感知器・消火器使用申込",
  "p3_exhibition_electro_regist": "電気使用申込 *",
  "p3_exhibition_electro_completed": "電気工事落成届 *",
  "p3_exhibition_sanitary_regist": "給水使用申込?",
  "p3_exhibition_extra_comm": "臨時通信設備使用申込(一般電話、ISDN回線)",
  "p3_exhibition_extra_comm2": "臨時通信設備使用申込(高速通信回線)",
  "p3_exhibition_antenna_regist": "アンテナ使用申込",
  "p3_exhibition_operation_plan": "運営計画書届",
  "p3_exhibition_wireless_mic": "ワイヤレスマイク使用届",
  "p3_exhibition_survey_questionnaires": "調査・アンケート実施届",
  "p3_exhibition_food_handling": "食品取扱届",
  "p3_exhibition_showlogo": "ショーロゴ入りグッズ販売届",
  "p3_exhibition_lot_executive" : "小間内責任者届",
  "p3_exhibition_prohibited_acts" : "禁止行為解除承認申請",
  "p3_exhibition_staff_anteroom" : "出展者用控室使用申込",
  "p3_exhibition_two_storied" : "二階建施設設計図届",
  "p3_ticket_event_participation" : "オフィシャルデー招待状の申込",
  "p3_ticket_entrance_ticket" : "出展者入門証・出展関係業者バッジ",
  "p3_ticket_normal_ticket" : "一般入場券・自動車ガイドブック引換券・封筒",
  "p3_ticket_guidebook_order" : "自動車ガイドブック現物",
  "p3_ticket_parking_pass" : "地下駐車場パスカード",
}

const STAFF_AUTHORITY_MATRIX = {
  "出展者一覧（リンクURL登録）" : ["B","B","B","B","B","B","B","B","B","B","B","B","B","B",null,"B",null,null,null,null,null],
  "出展車両数一覧" : ["B","B","B","B","B","B","B","B","B","B","B","B","B","B",null,"B",null,null,null,null,null],
  "ブース情報" : ["A","A","A","A","A","A","A","A","A","A","A","A","A","A",null,"A",null,null,null,null,null],
  "出展物リスト" : ["A","A","A","A","A","A","A","A","A","A","A","A","A","A",null,"A",null,null,null,null,null],
  "プレスリリースリンク掲出登録" : ["B","B","B","B","B","B","B","B","B","B","B","B","B","B",null,"B",null,null,null,null,null],
  "協業情報" : ["B","B","B","B","B","B","B","B","B","B","B","B","B","B",null,"B",null,null,null,null,null],
  "小間内責任者届" : ["B","B","B","B","B","B","B","B",null,"B",null,"B","B","B","B","B","B","B","B","B","B"],
  "小間内装飾業者届 *" : ["B","B","B","B","B","B","B","B",null,"B",null,"B","B","B","B","B","B","B",null,null,null],
  "小間設計図届 *" : ["B","B","B","B","B","B","B","B",null,null,null,null,null,null,null,null,null,"B",null,null,null],
  "計画搬入出届" : ["B","B","B","B","B","B","B","B",null,"B",null,"B","B","B","B","B","B","B","B","B","B"],
  "保税貨物明細" : ["B","B","B","B","B","B","B","B",null,"B",null,"B","B","B","B","B","B","B","B",null,null],
  "小間内清掃、ワックス清掃申込" : ["B","B","B","B","B","B","B","B",null,"B",null,"B","B","B","B","B","B",null,"B","B",null],
  "アンカーボルト使用届" : ["B","B","B","B","B","B","B",null,null,"B",null,"B","B","B","B","B","B","B","B",null,null],
  "天井構造物設置届" : ["B","B","B","B","B","B","B",null,null,"B",null,"B","B","B","B","B","B",null,null,null,null],
  "二階建施設設計図届" : ["B","B","B","B","B","B","B",null,null,"Z",null,"Z",null,null,null,null,null,null,null,null,null],
  "天井吊下げ施工届" : ["B","B","B","B","B","B","B",null,null,null,null,null,null,null,null,null,null,null,null,null,null],
  "施設天井照明変更申込" : ["B","B","B","B","B","B","B",null,null,null,null,null,null,null,null,null,null,null,null,null,null],
  "禁止行為解除承認申請" : ["B1","B1","B1","B1","B1","B1","B1",null,null,"B2",null,"B2","B2","B2","B2","B2","B2","B2","B2",null,"B2"],
  "無線式煙（熱）感知器・消火器使用申込" : ["B","B","B","B","B","B","B",null,null,"B",null,"B","B","B","B","B","B","B","B",null,"B"],
  "電気使用申込 *" : ["B","B","B","B","B","B","B","B",null,"B",null,"B","B","B","B","B","B","B","B","B","B"],
  "電気工事落成届 *" : ["B","B","B","B","B","B","B","B",null,"B",null,"B","B","B","B","B","B","B","B",null,"B"],
  "給水使用申込?" : ["B","B","B","B","B","B","B",null,null,"B",null,"B","B","B","B","B","B","B","B",null,"B"],
  "臨時通信設備使用申込(一般電話、ISDN回線)" : ["B","B","B","B","B","B","B","B",null,"B",null,"B","B","B","B","B","B","B","B","B","B"],
  "臨時通信設備使用申込(高速通信回線)" : ["B","B","B","B","B","B","B","B",null,"B",null,"B","B","B","B","B","B","B","B","B","B"],
  "アンテナ使用申込" : ["B","B","B","B","B","B","B","B",null,"B",null,"B","B","B","B","B","B","B","B","B",null],
  "出展者用控室使用申込" : ["D","D","D","D","D","D","D",null,null,null,null,null,null,null,null,null,null,null,null,null,null],
  "運営計画書届" : ["B","B","B","B","B","B","B","B",null,"B",null,"B","B","B",null,null,null,null,null,null,null],
  "ワイヤレスマイク使用届" : ["B","B","B","B","B","B","B","B",null,"B",null,"B","B","B","B","B","B","B","B",null,null],
  "調査・アンケート実施届" : ["B","B","B","B","B","B","B","B",null,"B","B","B","B","B",null,null,null,null,null,null,null],
  "食品取扱届" : ["B","B","B","B","B","B","B","B",null,"B","B","B","B","B","B","B","B","B","B","B","B"],
  "ショーロゴ入りグッズ販売届" : ["B","B","B","B","B","B","B","B",null,"B","B","B","B","B","B","B","B",null,"B","B",null],
  "ショーロゴ入りグッズ売上報告" : ["B","B","B","B","B","B","B","B",null,"B","B","B","B","B","B","B","B",null,"B","B",null],
  "オフィシャルデー招待状の申込" : [null,"B1",null,"B1",null,"B1","B1","B1","B2","B2","B2","B2","B2","B2","C2","B1",null,null,null,null,null],
  "出展者入門証・出展関係業者バッジ" : ["B","B","B","B","B","B","B","B","B","B","B","B","B","B",null,null,null,null,"B","B",null],
  "一般入場券・自動車ガイドブック引換券・封筒" : ["B1","B2","B1","B2","B1","B2","B2","B2","B2","B2","B2","B2","B1","B2","B2","B2","B2",null,"B2","B2",null],
  "自動車ガイドブック現物" : ["B1","B2","B1","B2","B1","B2","B2","B2","B2","B2","B2","B2","B1","B2","B2","B2","B2",null,"B2","B2",null],
  "地下駐車場パスカード" : ["B","B","B","B","B","B","B","B","B","B","B","B","B","B","B","B",null,null,"B","B",null],
}

const getStaffLabelByPageName = (str) => {
  return PAGE_NAME_LABELS[str] ?? false
}

export const getRequiredStaffLevel = async (str) => {
  try {
    const entrydatRes = await api.GetPreEntryDetail()
    const categoryDisp = entrydatRes.data.results.json_data.category_disp
    const staff_authority_index = CATEGORY_DISP_CONFIG[categoryDisp].staff_authority_index
  
    return STAFF_AUTHORITY_MATRIX[getStaffLabelByPageName(str)][staff_authority_index]
  } catch (e) {
    console.log(e)
  }
  return false
}

export const isStaffAuthorized = (requiredLevelList, staffAuthority) => {
  const route = useRoute()
  try {
    const list = requiredLevelList.split('-')
    const level = getLevel(list[0])
    if (level === 'A') {
      if (staffAuthority !== 1) {
        throw new Error('Only exhibitors are granted access.')
      }
      return true
    }
    if (level === 'B') {
      return true
    }
    if (level === 'C') {
      return true
    }
    if (level === 'D') {
      const exhibitor_id = Number(store.state.entrydata.exhibitor_id)
      const makerMatch = MAKERS.some((u) => u.value === exhibitor_id)
      if (!makerMatch) {
        throw new Error('Not an assigned exhibitor ID.')
      }
      return true
    }
    if (level === 'Z') {
      // "12": "部品・機械器具"
      // "13": "部品(共同)"
      // "14": "関連サービス" ⇒ 次世代モビリティ関連
      const allowedCategories = [12, 13, 14]
      if (!includes(allowedCategories, route.meta.categoryDisp)) {
        throw new Error('Categories not allowed.')
      }
      if (route.meta.decideArea < 6) {
        throw new Error('Booth application area is less than the definition.')
      }
      return true
    }
  } catch (e) {
    console.log(e)
    return false
  }
  return false
}

export const isShowByStaffAuthority = (name) => {
  const route = useRoute()
  const categoryDisp = route.meta.categoryDisp
  const staffAuthority = route.meta.staffAuthority
  try {
    const requiredStaffLevel = getValueByMatrixCell(name, categoryDisp)
    return isStaffAuthorized(requiredStaffLevel, staffAuthority)
  } catch (e) {
    console.log(e)
  }
  return false
}

export const getLevel = (value) => value.substr(0, 1)

export const getNoteNumber = (value) => value.replace(/[^0-9]/g, '')

export const getValueByMatrixCell = (name) => {
  const route = useRoute()
  const categoryDisp = route.meta.categoryDisp
  try {
    const staff_authority_index = CATEGORY_DISP_CONFIG[categoryDisp].staff_authority_index
    const requiredStaffLevel = STAFF_AUTHORITY_MATRIX[getStaffLabelByPageName(name)][staff_authority_index]
    return requiredStaffLevel
  } catch (e) {
    console.log(e)
  }
  return false
}
