<template>
  <div class="billing_address_ctn" v-if="forms.length">
    <h4>登録済み納品先</h4>
    <ul class="billing_address_lists">
      <li class="billing_address_list" :class="(selectedDelivery === key ? 'on' : '')" v-for="(val, key) in storedList"
        :key="key">
        <div class="select_address">納品先{{ key + 1 }}</div>
        <div class="billing_address_innner_ctn">
          <h5 class="billing_address_title">会社情報</h5>

          <div class="corp_name">{{ val.company_name }}</div>
          <div class="address">
            {{ makeZip(val.company_zip) }}<br />{{ val.company_pref }}{{ val.company_address }}
          </div>
          <h5 class="billing_address_title">担当者情報</h5>
          <div class="staff_post">{{ val.staff_affiliation }}&nbsp;&nbsp;{{ val.staff_post }}</div>
          <div class="staff_name">{{ val.staff_name }}</div>
          <div class="staff_tel">{{ val.staff_tel1 }}</div>
          <div class="staff_tel2">{{ val.staff_tel2 }}</div>
          <div class="staff_email">{{ val.staff_email }}</div>
          <ul class="button_list">
            <li @click="setForm(val.delivery_id)" class="slide-down-btn">編集</li>
            <li @click="selectDelivery(val.delivery_id)" class="select_list">この送り先に設定</li>
          </ul>
        </div>
      </li>
    </ul>
  </div>
  <div class="button_ctn">
    <ul>
      <li>
        <button @click="setForm" id="address_add_button" class="slide-down-btn">
          納品先を追加
        </button>
      </li>
    </ul>
  </div>
  <div class="billing_address_input_ctn" :style="toggleForm">
    <table class="common_table">
      <tbody>
        <tr>
          <th colspan="2" class="required">会社名</th>
          <td><input type="text" class="full" v-model="form.company_name">
            <p class="alert" v-if="errors.company_name">{{ errors.company_name }}</p>
          </td>
        </tr>
        <tr>
          <th colspan="2" class="required">会社名カナ</th>
          <td><input type="text" class="full" v-model="form.company_name_kana">
            <p class="alert" v-if="errors.company_name_kana">{{ errors.company_name_kana }}</p>
          </td>
        </tr>
        <tr>
          <th rowspan="3">所在地</th>
          <template v-if="!isForeign">
          <th class="required">郵便番号</th>
          <td>
            <input type="text" class="quarter" v-model="form.company_zip" @input="onZipInput" @keyup="onZipChange">
            <p class="alert" v-if="errors.company_zip">{{ errors.company_zip }}</p>
          </td>
          </template>
        </tr>

        <tr>
          <th class="required">都道府県</th>
          <td>
            <div class="select_ctn">
              <select v-model="form.company_pref">
                <option value="">都道府県</option>
                <option v-for="(v, k) in addressPrefOptions" :key="k" :value="v.value"
                  :selected="form.company_pref === v.label">{{ v.label }}</option>
              </select>
            </div>
            <p class="alert" v-if="errors.company_pref">{{ errors.company_pref }}</p>
          </td>
        </tr>
        <tr>
          <th class="required">住所</th>
          <td><input name="delivery_worker_address" type="text" size="40" class="full"
              v-model="form.company_address">
            <p class="alert" v-if="errors.company_address">{{ errors.company_address }}</p>
          </td>
        </tr>
        <tr>
          <th rowspan="7">担当者</th>
          <th>部署</th>
          <td><input name="delivery_quarter_name" type="text" class="quarter" v-model="form.staff_affiliation">
            <p class="alert" v-if="errors.staff_affiliation">{{ errors.staff_affiliation }}</p>
          </td>
        </tr>
        <tr>
          <th>役職</th>
          <td><input name="delivery_quarter_name" type="text" class="quarter" v-model="form.staff_post">
            <p class="alert" v-if="errors.staff_post">{{ errors.staff_post }}</p>
          </td>
        </tr>
        <tr>
          <th class="required">氏名</th>
          <td><input name="delivery_prepared_name" type="text" size="30" class="validate[required]"
              v-model="form.staff_name">
            <p class="alert" v-if="errors.staff_name">{{ errors.staff_name }}</p>
          </td>
        </tr>
        <tr>
          <th class="required">氏名フリガナ</th>
          <td><input name="delivery_prepared_name" type="text" size="30" class="validate[required]"
              v-model="form.staff_name_kana">
            <p class="alert" v-if="errors.staff_name_kana">{{ errors.staff_name_kana }}</p>
          </td>
        </tr>
        <tr>
          <th>電話番号</th>
          <td><input type="text" name="delivery_worker_tel" class="validate[required]" v-model="form.staff_tel1">
            <p class="alert" v-if="errors.staff_tel1">{{ errors.staff_tel1 }}</p>
          </td>
        </tr>
        <tr>
          <th class="required">緊急連絡先</th>
          <td><input type="text" name="delivery_worker_tel2" class="validate[required]" v-model="form.staff_tel2">
            <p class="alert" v-if="errors.staff_tel2">{{ errors.staff_tel2 }}</p>
          </td>
        </tr>

        <tr>
          <th>E-mail</th>
          <td><input type="text" name="delivery_worker_email" class="validate[custom[email]]"
              v-model="form.staff_email">
            <p class="alert" v-if="errors.staff_email">{{ errors.staff_email }}</p>
          </td>
        </tr>

      </tbody>
    </table>
    <div class="button_ctn">
      <ul>
        <li>
          <button @click="cancelRegister" class="gray_button slide-up-btn">キャンセル</button>
        </li>

        <li>
          <button @click="register" class="slide-up-btn2">納品先を登録</button>
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup>
import APIClient from '@/store/repository/APIClient'
import { reactive, ref, computed, defineEmits, defineProps, watch, toRefs } from "vue"
import { mapValues, forEach, cloneDeep } from 'lodash'
import { FormValidate } from '@/helper/FormValidate'
import * as yup from 'yup'
import KenAll from 'ken-all'
import ADDRESS from '@/const/address_elements.js'
import { isValue } from '@/helper/index';
import { store } from '@/store'

import {
  zipNumOnlyTest,
  makeZip,
  isKana,
} from '@/helper/index'

import YupConst from '@/const/yup'

const zipRegExp = new RegExp(YupConst.ZIP_PATTERN)

const addressPrefOptions = ADDRESS.address_pref_options

import ApiConst from '@/const/api'
import DeliveryConst from '@/const/delivery'

const emit = defineEmits(['emitSelectDelivery', 'emitFetch'])

const props = defineProps({
  deliveryId: {
    type: Number,
    default: null,
  },
})
const { deliveryId } = toRefs(props);
watch(deliveryId, (i) => {
  edit_delivery_id.value = i
})

const formInit = DeliveryConst.FORM_INIT

const storedList = computed(() => {
  return store.state.delivery.list
})


const api = new APIClient()

const forms = reactive([])
const edit_delivery_id = ref(null)
const selectedDelivery = computed(() => {
  const i = forms.findIndex(el => el.delivery_id === edit_delivery_id.value)
  return i
})

const isForeign = computed(() => {
  return form.company_pref === addressPrefOptions.find(el => el.label === ADDRESS.address_pref_foreign)?.value
})

const fetch = async () => {
  try {
    const fetchApi = await api.get(ApiConst.API_DELIVERY_LIST)
    const res = fetchApi.response
    const errors = res.data?.error_results
    if (errors) {
      throw new Error('Not found.')
    }
    forms.splice(0)
    res.data.results.list.forEach((el) => {
      forms.push(mapValues(el, (v, k) => {
        if (!isValue(v)) {
          return formInit[k]
        }
        return v
      }))
    })
    store.commit('delivery/list', forms)
    emit('emitFetch', forms)
  } catch (e) {
    console.log(e)
    return false
  }
}
fetch()

const selectDelivery = (i) => {
  edit_delivery_id.value = i
  emit('emitSelectDelivery', i)
}

const onZipInput = (e) => {
  if (!zipNumOnlyTest(e.target.value)) return
  const zipValue = makeZip(e.target.value)
  form.company_zip = zipValue
}
const onZipChange = (e) => {
  const value = e.target.value.replace(/[^0-9]/g, '')
  if (value.length !== 7) return
  KenAll(value).then(res => {
    if (res.length === 0) return
    const address = res[0]
    const prefecture = address.shift()
    const pref_match = addressPrefOptions.find(item => item.label === prefecture)
    form.company_pref = pref_match.value
    form.company_address = address.join('')
  })
}

const showForm = ref(false)
const toggleForm = computed(() => {
  if (showForm.value) return {display: 'block'}
  return {}
})
const form = reactive(cloneDeep(formInit))
const cancelRegister = () => {
  showForm.value = false
}
const setForm = (i) => {
  showForm.value = true
  forEach(formInit, (v, k) => {
    form[k] = v
  })
  const editor = cloneDeep(forms.find(el => el.delivery_id === i))
  if (!editor) {
    forEach(formInit, (v, k) => {
      form[k] = v
    })
    return
  }
  forEach(formInit, (v, k) => {
    form[k] = editor[k]
  })
}

const scheme = {
  company_name: yup.string().trim().required('必須の項目です。'),
  company_name_kana: yup.string().test('katakana-checker', 'カタカナで入力して下さい。', isKana).trim().required('必須の項目です。'),
  company_zip: yup.string().trim()
    .test(
      'company_zip_required',
      '必須の項目です。',
      function(v) {
        if ( isForeign.value ) return true
        return v.length
      }
    )
    .test(
      'company_zip_pref',
      YupConst.ZIP_PATTERN_MSG,
      function(v) {
        if ( isForeign.value ) return true
        return v.match(zipRegExp)
      }
    ),
  company_pref: yup.string().trim().required('必須の項目です。'),
  company_address: yup.string().trim().required('必須の項目です。'),
  staff_affiliation: yup.string().trim(),
  staff_post: yup.string().trim(),
  staff_name: yup.string().trim().required('必須の項目です。'),
  staff_name_kana: yup.string().test('katakana-checker', 'カタカナで入力して下さい。', isKana).trim().required('必須の項目です。'),
  staff_tel1: yup.string().trim(),
  staff_tel2: yup.string().trim().required('必須の項目です。'),
  staff_email: yup.string().trim().email('メールアドレスの形式が不正です。'),
}
const validate = new FormValidate(scheme, form)
const errors = validate.errors
const register = async () => {
  try {
    if ((Object.keys(await validate.getErrorObject()).length)) {
      throw new Error('入力項目に不備があります。')
    }
    const params = cloneDeep(form)
    const delivery_id = params.delivery_id
    delete params.delivery_id
    const operation = delivery_id ? "R" : "I"
    const query = operation === "I"
      ? { operation, ...params }
      : { operation, delivery_id, ...params }
    const fetchApi = await api.post(ApiConst.API_DELIVERY_EDIT, query)
    const res = fetchApi.response
    if (res.data?.error_results) {
      throw new Error('API Validation Error')
    }
    selectDelivery(res.data.results.delivery_id)
    fetch()
    cancelRegister()
  } catch (e) {
    console.log(e)
    return false
  }
}
</script>

<style scoped>
.modal_contents .billing_address_lists > li.on .select_address::after {
  content: "-この納品先を選択済み-";

  display: inline;

}
</style>
