<template>
  <div id="admin">
    <header>
      <h1>JMS 2025 出展者登録DB 管理者用ページ</h1>
    </header>
    <article>
      <h2>一次申込管理</h2>
      <div id="form_ctn" class="form_box">
        <admin-pre-search></admin-pre-search>
      </div>

      <div class="button_ctn">
          <ul>
            <li><router-link :to="{name:'AdminIndex'}">メニュー画面に戻る</router-link>
            </li>
          </ul>
        </div>
    </article>
    </div>
</template>

<script>

import APIClient from '@/store/repository/APIClient'

import AdminPreSearch from '@/components//AdminPreSearch.vue';

export default {
  components: {
    AdminPreSearch
  },
  setup() {


    return {
      

    };
  },

  data() {
    return {

    }
  },
  async created() {
    this.api = new APIClient()
 
  },
  mounted() {

  },
  methods: {

  },
  watch: {


  },
  computed: {


  }
};
</script>

<style>

</style>
