<template>
  <header>
    <h1>JAPAN MOBILITY SHOW 2025 <br class="pc_none" />出展者登録サイト</h1>
  </header>
  <article id="p2">
    <PhaseHeader />
    <div id="app">
      <div id="form_ctn" class="form_box">

        <PhaseNav
          :current="2"
          :category="2"
          :categoryTop="true"
          pageName="出展展示関係"
        />

        <div id="form_ctn" class="form_box">
          <h3>出展・展示関係</h3>
          <!-- 協業情報 / -->
          <div class="entry_ctn" v-if="isShow('p3_exhibition_collaboration')">
            <h4>協業情報</h4>
            <p>第三者との共同事業・協業等による製品、サービス等を展示する場合</p>
            <div class="status_clocing_ctn">
              <DisplayIndexStatus
                name="p3_exhibition_collaboration"
              />
              <!--手入力↓-->
              <div class="closing_day">締切：9月7日(木)</div>
            </div>
            <!-- 登録期間が終わっても閲覧可能にする-->
            <DeadlineButton
              name="p3_exhibition_collaboration"
            />
            <div class="contact_list_link"><a @click.prevent="modalOpen" href="#contact_tbs" class="modalOpen">お問い合わせ先</a></div>
          </div>
          <!-- / 協業情報 -->
          <!-- 小間内責任者届[規程2-11] / -->
          <div class="entry_ctn" v-if="isShow('p3_exhibition_lot_executive')">
            <p class="required_entry">全出展者必須の提出書類</p>
            <h4>小間内責任者届[規程2-11]</h4>
            <p>小間内の管理責任者、火元責任者の登録</p>
            <div class="status_clocing_ctn">
              <DisplayIndexStatus
                name="p3_exhibition_lot_executive"
              />
              <!--管理画面で設定↓-->
              <div class="closing_day">締切：9月4日(月)</div>
            </div>
            <!-- 登録期間が終わっても閲覧可能にする-->
            <DeadlineButton
              name="p3_exhibition_lot_executive"
            />
            <div class="contact_list_link"><a @click.prevent="modalOpen" href="#contact_tbs" class="modalOpen">お問い合わせ先</a></div>
          </div>
          <!-- / 小間内責任者届[規程2-11] -->

          <!-- 小間内装飾業者届 / -->
          <div class="entry_ctn" v-if="isShow('p3_exhibition_lot_worker')">
            <p class="required_entry">全出展者必須の提出書類</p>
            <h4>小間内装飾業者届[規程2-9]</h4>
            <p>小間内装飾業者及び工事責任者の登録</p>
            <div class="status_clocing_ctn">
              <DisplayIndexStatus
                name="p3_exhibition_lot_worker"
              />
              <!--管理画面で設定↓-->
              <div class="closing_day">締切：9月4日(月)</div>
            </div>
            <!-- 登録期間が終わっても閲覧可能にする-->
            <DeadlineButton
              name="p3_exhibition_lot_worker"
            />
            <div class="contact_list_link"><a @click.prevent="modalOpen" href="#contact_tbs" class="modalOpen">お問い合わせ先</a></div>
          </div>
          <!-- / 小間内装飾業者届 -->

          <!-- 小間設計図届[規程3-4] / -->
          <div class="entry_ctn" v-if="isShow('p3_exhibition_lot_planning')">
            <p class="required_entry">全出展者必須の提出書類</p>
            <h4>小間設計図届[規程3-4]</h4>
            <p>平面図、立面図の提出</p>
            <div class="status_clocing_ctn">
              <DisplayIndexStatus
                name="p3_exhibition_lot_planning"
              />
              <!--管理画面で設定↓-->
              <div class="closing_day">締切：9月4日(月)</div>
            </div>
            <!-- 登録期間が終わっても閲覧可能にする-->
            <DeadlineButton
              name="p3_exhibition_lot_planning"
            />
            <div class="contact_list_link"><a @click.prevent="modalOpen" href="#contact_tbs" class="modalOpen">お問い合わせ先</a></div>
          </div>
          <!-- / 小間設計図届[規程3-4] -->

          <!-- 計画搬入出調査書[規程5-1] / -->
          <div class="entry_ctn" v-if="isShow('p3_exhibition_carry_inout')">
            <p class="required_entry">全出展者必須の提出書類</p>
            <h4>計画搬入出調査書[規程5-1] </h4>
            <p>搬入出車両の予定台数等に関する調査書の提出</p>
            <div class="status_clocing_ctn">
              <DisplayIndexStatus
                name="p3_exhibition_carry_inout"
              />
              <!--管理画面で設定↓-->
              <div class="closing_day">締切：9月4日(月)</div>
            </div>
            <!-- 登録期間が終わっても閲覧可能にする-->
            <DeadlineButton
              name="p3_exhibition_carry_inout"
            />
            <div class="contact_list_link"><a @click.prevent="modalOpen" href="#contact_tbs_logistics" class="modalOpen">お問い合わせ先</a></div>
          </div>
          <!-- / 計画搬入出調査書[規程5-1] -->

          <!-- 保税貨物明細[規程5-2] / -->
          <div class="entry_ctn" v-if="isShow('p3_exhibition_tax_cargo')">
            <h4>保税貨物明細[規程5-2]</h4>
            <p>出展物等を保税扱いにする場合</p>
            <div class="status_clocing_ctn">
              <DisplayIndexStatus
                name="p3_exhibition_tax_cargo"
              />
              <!--管理画面で設定↓-->
              <div class="closing_day">締切：8月24日(木)</div>
            </div>
            <!-- 登録期間が終わっても閲覧可能にする-->
            <DeadlineButton
              name="p3_exhibition_tax_cargo"
            />
            <div class="contact_list_link"><a @click.prevent="modalOpen" href="#contact_ishikawagumi" class="modalOpen">お問い合わせ先</a></div>
          </div>
          <!-- / 保税貨物明細[規程5-2] -->

          <!-- 小間内清掃、ワックス清掃申込[規程5-3] / -->
          <div class="entry_ctn" v-if="isShow('p3_exhibition_cleaning')">
            <h4>小間内清掃、ワックス清掃申込[規程5-3]</h4>
            <p>小間内清掃、ワックス清掃サービスを利用する場合</p>
            <div class="status_clocing_ctn">
              <DisplayIndexStatus
                name="p3_exhibition_cleaning"
              />
              <!--管理画面で設定↓-->
              <div class="closing_day">締切：10月6日(金)</div>
            </div>
            <!--
            <ul class="status_option">
              <li>料金合計 ¥3,413,000</li>
            </ul>
            -->
            <!-- 登録期間が終わっても閲覧可能にする-->
            <DeadlineButton
              name="p3_exhibition_cleaning"
            />
            <div class="contact_list_link"><a @click.prevent="modalOpen" href="#contact_bigsight_service" class="modalOpen">お問い合わせ先</a></div>
          </div>
          <!-- / 小間内清掃、ワックス清掃申込[規程5-3] -->

          <!-- アンカーボルト使用届[規程6-3] / -->
          <div class="entry_ctn" v-if="isShow('p3_exhibition_anchor_bolt')">
            <h4>アンカーボルト使用届[規程6-3]</h4>
            <p>アンカーボルト施工を行う場合</p>
            <div class="status_clocing_ctn">
              <DisplayIndexStatus
                name="p3_exhibition_anchor_bolt"
              />
              <!--管理画面で設定↓-->
              <div class="closing_day">締切：10月2日(月)</div>
            </div>
            <!-- 登録期間が終わっても閲覧可能にする-->
            <!--
            <ul class="status_option">
              <li>使用料合計 ¥20,000</li>
            </ul>
            -->
            <DeadlineButton
              name="p3_exhibition_anchor_bolt"
            />
            <div class="contact_list_link"><a @click.prevent="modalOpen" href="#contact_tbs" class="modalOpen">お問い合わせ先</a></div>
          </div>
          <!-- / アンカーボルト使用届[規程6-3] -->

          <!-- 天井構造物設置届[規程6-5] / -->
          <div class="entry_ctn" v-if="isShow('p3_exhibition_ceiling_structure')">
            <h4>天井構造物設置届[規程6-5]</h4>
            <p>天井構造物を設置する場合</p>
            <div class="status_clocing_ctn">
              <DisplayIndexStatus
                name="p3_exhibition_ceiling_structure"
              />
              <!--管理画面で設定↓-->
              <div class="closing_day">締切：9月4日(月)</div>
            </div>
            <!-- 登録期間が終わっても閲覧可能にする-->
            <DeadlineButton
              name="p3_exhibition_ceiling_structure"
            />
            <div class="contact_list_link"><a @click.prevent="modalOpen" href="#contact_tbs" class="modalOpen">お問い合わせ先</a></div>
          </div>
          <!-- / 天井構造物設置届[規程6-5] -->

          <!-- 二階建施設設計図届[規程6-6] / -->
          <div class="entry_ctn" v-if="isShow('p3_exhibition_two_storied')">
            <h4>二階建施設設計図届[規程6-6]</h4>
            <p>二階建施設を設置する場合</p>
            <div class="status_clocing_ctn">
              <DisplayIndexStatus
                name="p3_exhibition_two_storied"
              />
              <!--管理画面で設定↓-->
              <div class="closing_day">締切：9月4日(月)</div>
            </div>
            <!-- 登録期間が終わっても閲覧可能にする-->
            <DeadlineButton
              name="p3_exhibition_two_storied"
            />
            <div class="contact_list_link"><a @click.prevent="modalOpen" href="#contact_tbs" class="modalOpen">お問い合わせ先</a></div>
          </div>
          <!-- / 二階建施設設計図届[規程6-6] -->


          <!-- 天井吊下げ施工届[規程6-7] / -->
          <div class="entry_ctn" v-if="isShow('p3_exhibition_roof_hang')">
            <h4>天井吊下げ施工届[規程6-7]</h4>
            <p>天井吊下げ施工を行う場合</p>
            <div class="status_clocing_ctn">
              <DisplayIndexStatus
                name="p3_exhibition_roof_hang"
              />
              <!--管理画面で設定↓-->
              <div class="closing_day">締切：9月4日(月)</div>
            </div>
            <!-- 登録期間が終わっても閲覧可能にする-->
            <DeadlineButton
              name="p3_exhibition_roof_hang"
            />
            <div class="contact_list_link"><a @click.prevent="modalOpen" href="#contact_tbs" class="modalOpen">お問い合わせ先</a></div>
          </div>
          <!-- / 天井吊下げ施工届[規程6-7] -->

          <!-- 施設天井照明変更申込[規程6-1] / -->
          <div class="entry_ctn" v-if="isShow('p3_exhibition_lighting_change')">
            <h4>施設天井照明変更申込[規程6-1]</h4>
            <p>天井吊り下げ施工に伴い、自社小間内の垂直上に位置する施設天井照明の消灯を希望する場合</p>
            <div class="status_clocing_ctn">
              <DisplayIndexStatus
                name="p3_exhibition_lighting_change"
              />
              <!--管理画面で設定↓-->
              <div class="closing_day">締切：9月4日(月)</div>
            </div>
            <!-- 登録期間が終わっても閲覧可能にする-->
            <DeadlineButton
              name="p3_exhibition_lighting_change"
            />
            <div class="contact_list_link"><a @click.prevent="modalOpen" href="#contact_tbs" class="modalOpen">お問い合わせ先</a></div>
          </div>
          <!-- / 施設天井照明変更申込[規程6-1] -->

          <!-- 禁止行為解除承認申請[規程6-8] / -->
          <div class="entry_ctn" v-if="isShow('p3_exhibition_prohibited_acts')">
            <h4>禁止行為解除承認申請[規程6-8]</h4>
            <p>裸火・危険物の使用、持込み</p>
            <div class="status_clocing_ctn">
              <DisplayIndexStatus
                name="p3_exhibition_prohibited_acts"
              />
              <!--管理画面で設定↓-->
              <div class="closing_day">締切：9月4日(月)</div>
            </div>
            <!-- 登録期間が終わっても閲覧可能にする-->
            <DeadlineButton
              name="p3_exhibition_prohibited_acts"
            />
            <div class="contact_list_link"><a @click.prevent="modalOpen" href="#contact_tbs" class="modalOpen">お問い合わせ先</a></div>
          </div>
          <!-- / 禁止行為解除承認申請[規程6-8] -->

          <!-- 無線式煙(熱)感知器・消火器使用申込[規程6-8] / -->
          <div class="entry_ctn" v-if="isShow('p3_exhibition_detector')">
            <h4>無線式煙(熱)感知器・消火器使用申込[規程6-8]</h4>
            <p>煙感知器・消火器の設置をする場合</p>
            <div class="status_clocing_ctn">
              <DisplayIndexStatus
                name="p3_exhibition_detector"
              />
              <!--管理画面で設定↓-->
              <div class="closing_day">締切：10月2日(月)</div>
            </div>
            <ul class="status_option">
              <li>合算料金 ¥{{ calcDetector }}</li>
            </ul>
            <!-- 登録期間が終わっても閲覧可能にする-->
            <DeadlineButton
              name="p3_exhibition_detector"
            />
            <div class="contact_list_link"><a @click.prevent="modalOpen" href="#contact_tbs" class="modalOpen">お問い合わせ先</a></div>
          </div>
          <!-- / 無線式煙(熱)感知器・消火器使用申込[規程6-8] -->


          <!-- 電気使用申込(電気工事設計図)[規程7-1] / -->
          <div class="entry_ctn" v-if="isShow('p3_exhibition_electro_regist')">
            <p class="required_entry">全出展者必須の提出書類</p>
            <h4>電気使用申込(電気工事設計図)[規程7-1]</h4>
            <p>電気使用の申込、電気工事設計図の届出</p>
            <div class="status_clocing_ctn">
              <DisplayIndexStatus
                name="p3_exhibition_electro_regist"
              />
              <!--管理画面で設定↓-->
              <div class="closing_day">締切：9月4日(月)</div>
            </div>
            <ul class="status_option">
              <li>幹線工事費 ¥{{ calcBackboneWorkCost.toLocaleString() }}</li>
              <li>電気使用料金 ¥{{ calcElectroUseCost.toLocaleString() }}</li>
            </ul>
            <!-- 登録期間が終わっても閲覧可能にする-->
            <DeadlineButton
              name="p3_exhibition_electro_regist"
            />
            <div class="contact_list_link"><a @click.prevent="modalOpen" href="#contact_tbs" class="modalOpen">お問い合わせ先</a></div>
          </div>
          <!-- / 電気使用申込(電気工事設計図)[規程7-1] -->

          <!-- 電気工事落成届[規程7-1] / -->
          <div class="entry_ctn" v-if="isShow('p3_exhibition_electro_completed')">
            <p class="required_entry">全出展者必須の提出書類</p>
            <h4>電気工事落成届[規程7-1]</h4>
            <p>電気工事完了後の落成届の提出</p>
            <div class="status_clocing_ctn">
              <DisplayIndexStatus
                name="p3_exhibition_electro_completed"
              />
              <!--管理画面で設定↓-->
              <div class="closing_day">締切：10月24日(火)</div>
            </div>

            <!-- 登録期間が終わっても閲覧可能にする-->
            <DeadlineButton
              name="p3_exhibition_electro_completed"
            />
            <div class="contact_list_link"><a @click.prevent="modalOpen" href="#contact_tbs" class="modalOpen">お問い合わせ先</a></div>
          </div>
          <!-- / 電気工事落成届[規程7-1] -->


          <!-- 給水使用申込[規程7-2] / -->
          <div class="entry_ctn" v-if="isShow('p3_exhibition_sanitary_regist')">
            <h4>給水使用申込[規程7-2]</h4>
            <p>小間内で給水設備を使用する場合</p>
            <div class="status_clocing_ctn">
              <DisplayIndexStatus
                name="p3_exhibition_sanitary_regist"
              />
              <!--管理画面で設定↓-->
              <div class="closing_day">締切：9月4日(月)</div>
            </div>
            
            <ul class="status_option">
              <li>給水基本設備工事費 ¥{{ calcWaterSupplyAmount }}</li>
            </ul>
           
            <!-- 登録期間が終わっても閲覧可能にする-->
            <DeadlineButton
              name="p3_exhibition_sanitary_regist"
            />
            <div class="contact_list_link"><a @click.prevent="modalOpen" href="#contact_tbs" class="modalOpen">お問い合わせ先</a></div>
          </div>
          <!-- / 給水使用申込[規程7-2] -->

          <!-- 臨時通信設備使用申込 (一般電話、ISDN回線)[規程7-3] / -->
          <div class="entry_ctn" v-if="isShow('p3_exhibition_extra_comm')">
            <h4>臨時通信設備使用申込 (一般電話)[規程7-3]</h4>
            <p>小間内で一般電話を使用する場合</p>
            <div class="status_clocing_ctn">
              <DisplayIndexStatus
                name="p3_exhibition_extra_comm"
              />
              <!--管理画面で設定↓-->
              <div class="closing_day"> 締切：10月2日(月)</div>
            </div>
            
            <ul class="status_option">
              <li>回線使用料 ¥{{ calcExtraComm }}</li>
            </ul>
           
            <!-- 登録期間が終わっても閲覧可能にする-->
            <DeadlineButton
              name="p3_exhibition_extra_comm"
            />
            <div class="contact_list_link"><a @click.prevent="modalOpen" href="#contact_tsushin_bigsight_service" class="modalOpen">お問い合わせ先</a></div>
          </div>
          <!-- / 臨時通信設備使用申込 (一般電話、ISDN回線)[規程7-3] -->

          <!-- 臨時通信設備使用申込(高速通信回線) / -->
          <div class="entry_ctn" v-if="isShow('p3_exhibition_extra_comm2')">
            <h4>臨時通信設備使用申込(高速通信回線)</h4>
            <p>小間内で、光高速通信回線を使用する場合</p>
            <div class="status_clocing_ctn">
              <DisplayIndexStatus
                name="p3_exhibition_extra_comm2"
              />
              <!--管理画面で設定↓-->
              <div class="closing_day">締切：10月2日(月)</div>
            </div>
            
            <ul class="status_option">
              <li>合計金額 ¥{{ calcExtraComm2 }}</li>
            </ul>
           
            <!-- 登録期間が終わっても閲覧可能にする-->
            <DeadlineButton
              name="p3_exhibition_extra_comm2"
            />
            <div class="contact_list_link"><a @click.prevent="modalOpen" href="#contact_tsushin_bigsight_service" class="modalOpen">お問い合わせ先</a></div>
          </div>
          <!-- / 臨時通信設備使用申込(高速通信回線) -->


          <!-- アンテナ使用申込[規程7-4] / -->
          <div class="entry_ctn" v-if="isShow('p3_exhibition_antenna_regist')">
            <h4>アンテナ使用申込[規程7-4]</h4>
            <p>地上デジタル放送、デジタル衛星放送を受信する場合</p>
            <div class="status_clocing_ctn">
              <DisplayIndexStatus
                name="p3_exhibition_antenna_regist"
              />
              <!--管理画面で設定↓-->
              <div class="closing_day">締切：10月2日(月)</div>
            </div>
            <ul class="status_option">
              <li>アンテナ設備費 ¥{{ calcAntennaRegist }}</li>
            </ul>
            <!-- 登録期間が終わっても閲覧可能にする-->
            <DeadlineButton
              name="p3_exhibition_antenna_regist"
            />
            <div class="contact_list_link"><a @click.prevent="modalOpen" href="#contact_tbs" class="modalOpen">お問い合わせ先</a></div>
          </div>
          <!-- / アンテナ使用申込[規程7-4] -->

          

          <!-- 出展者用控室申込 / -->
          <div class="entry_ctn" v-if="isShow('p3_exhibition_staff_anteroom')">
            <h4>出展者用控室申込</h4>
            <p>出展者用控室の利用を希望する場合</p>
            <div class="status_clocing_ctn">
              <DisplayIndexStatus
                name="p3_exhibition_staff_anteroom"
              />
              <!--管理画面で設定↓-->
              <div class="closing_day">締切：9月4日(月)</div>
            </div>
            <ul class="status_option">
            </ul>
            <!-- 登録期間が終わっても閲覧可能にする-->
            <DeadlineButton
              name="p3_exhibition_staff_anteroom"
            />
            <div class="contact_list_link"><a @click.prevent="modalOpen" href="#contact_tbs" class="modalOpen">お問い合わせ先</a></div>
          </div>
          <!-- / 出展者用控室申込 -->

          <!-- 運営計画書届[規程8-1] / -->
          <div class="entry_ctn" v-if="isShow('p3_exhibition_operation_plan')">
            <h4>運営計画書届[規程8-1]</h4>
            <p>会場周辺で個別イベント（試乗会等）や発煙（スモークマシン等）を行う場合</p>
            <div class="status_clocing_ctn">
              <DisplayIndexStatus
                name="p3_exhibition_operation_plan"
              />
              <!--管理画面で設定↓-->
              <div class="closing_day">締切：9月4日(月)</div>
            </div>
            <ul class="status_option">
            </ul>
            <!-- 登録期間が終わっても閲覧可能にする-->
            <DeadlineButton
              name="p3_exhibition_operation_plan"
            />
            <div class="contact_list_link"><a @click.prevent="modalOpen" href="#contact_tbs" class="modalOpen">お問い合わせ先</a></div>
          </div>
          <!-- / 運営計画書届[規程8-1] -->

          <!-- ワイヤレスマイク使用届[規程8-2] / -->
          <div class="entry_ctn" v-if="isShow('p3_exhibition_wireless_mic')">
            <h4>ワイヤレスマイク使用届[規程8-2]</h4>
            <p>ワイヤレスマイクを使用する場合</p>
            <div class="status_clocing_ctn">
              <DisplayIndexStatus
                name="p3_exhibition_wireless_mic"
              />
              <!--管理画面で設定↓-->
              <div class="closing_day">締切：10月11日(水)</div>
            </div>
            <ul class="status_option">
            </ul>
            <!-- 登録期間が終わっても閲覧可能にする-->
            <DeadlineButton
              name="p3_exhibition_wireless_mic"
            />
            <div class="contact_list_link"><a @click.prevent="modalOpen" href="#contact_tbs" class="modalOpen">お問い合わせ先</a></div>
          </div>
          <!-- / ワイヤレスマイク使用届[規程8-2] -->

          <!-- 調査・アンケート実施届[規程8-4] / -->
          <div class="entry_ctn" v-if="isShow('p3_exhibition_survey_questionnaires')">
            <h4>調査・アンケート実施届[規程8-4]</h4>
            <p>小間外でアンケートを実施する場合</p>
            <div class="status_clocing_ctn">
              <DisplayIndexStatus
                name="p3_exhibition_survey_questionnaires"
              />
              <!--管理画面で設定↓-->
              <div class="closing_day">締切：9月4日(月)</div>
            </div>
            <ul class="status_option">
            </ul>
            <!-- 登録期間が終わっても閲覧可能にする-->
            <DeadlineButton
              name="p3_exhibition_survey_questionnaires"
            />
            <div class="contact_list_link"><a @click.prevent="modalOpen" href="#contact_tbs" class="modalOpen">お問い合わせ先</a></div>
          </div>
          <!-- / 調査・アンケート実施届[規程8-4] -->

          <!-- 食品取扱届[規程8-6] / -->
          <div class="entry_ctn" v-if="isShow('p3_exhibition_food_handling')">
            <h4>食品取扱届[規程8-6]</h4>
            <p>小間内で飲食物を供する場合、江東区保健所に提出</p>
            <div class="status_clocing_ctn">
              <DisplayIndexStatus
                name="p3_exhibition_food_handling"
              />
              <!--管理画面で設定↓-->
              <div class="closing_day">締切：9月29日(金)</div>
            </div>
            <ul class="status_option">
            </ul>
            <!-- 登録期間が終わっても閲覧可能にする-->
            <DeadlineButton
              name="p3_exhibition_food_handling"
            />
            <div class="contact_list_link"><a @click.prevent="modalOpen" href="#contact_koto_health_centre" class="modalOpen">お問い合わせ先</a></div>
          </div>
          <!-- / 食品取扱届[規程8-6] -->


          <!-- 出展者オリジナルグッズ販売届 / -->
          <div class="entry_ctn" v-if="isShow('p3_exhibition_showlogo')">
            <h4>出展者オリジナルグッズ販売届</h4>
            <p>出展者オリジナルグッズを販売する場合</p>
            <div class="status_clocing_ctn">
              <DisplayIndexStatus
                name="p3_exhibition_showlogo"
              />
              <div class="closing_day">[販売計画] 締切：10月2日(月)<br>
                [売上報告] 受付開始：11月5日(日) / 11月10日(金)</div>
            </div>
            <!--
            <ul class="status_option">
              <li>販売計画&nbsp;&nbsp;未登録</li>
              <li>売上報告&nbsp;&nbsp;未登録</li>
            </ul>
            -->
            <!-- 登録期間が終わっても閲覧可能にする-->
            <DeadlineButton
              name="p3_exhibition_showlogo"
            />
            <div class="contact_list_link"><a @click.prevent="modalOpen" href="#contact_tbs" class="modalOpen">お問い合わせ先</a></div>
          </div>
          <!-- / 出展者オリジナルグッズ販売届 -->



        </div>
        <ModalTokyoBigSight :isButton="false" />
        <ModalIshikawagumi :isButton="false" />
        <ModalBigsightService :isButton="false" />
        <ModalTsushinBigsightService :isButton="false" />
        <ModalKotoHealthCentre :isButton="false" />
        <ModalTokyoBigSightLogistics :isButton="false" />
        <ModalJAMA :isButton="false" />
      </div>
    </div>
  </article>
</template>


<script setup>
import { ref, computed } from "vue"
import { sum } from 'lodash'
import PhaseHeader from '@/components/PhaseHeader'
import PhaseNav from '@/components/PhaseNav'
import DeadlineButton from './IndexStatus/DeadlineButton'
import DisplayIndexStatus from './IndexStatus/DisplayIndexStatus'
import ModalTokyoBigSight from "@/components/modal/ModalTokyoBigSight"
import ModalIshikawagumi from "@/components/modal/ModalIshikawagumi"
import ModalBigsightService from "@/components/modal/ModalBigsightService"
import ModalTsushinBigsightService from "@/components/modal/ModalTsushinBigsightService"
import ModalKotoHealthCentre from "@/components/modal/ModalKotoHealthCentre"
import ModalTokyoBigSightLogistics from "@/components/modal/ModalTokyoBigSightLogistics"
import ModalJAMA from "@/components/modal/ModalJAMA"
import { getIndexStatus } from '@/helper/indexStatus'
import ElectroRegist from '@/const/electro_regist'
import SANITARY_REGIST from '@/const/sanitary_regist'
import EXTRA_COMM from '@/const/extra_comm'

import {
  modalOpen,
} from '@/helper/modal'

import { isShowByStaffAuthority } from "@/helper/staffAuthority"

const statusList = ref({})
;(async () => {
  statusList.value = await getIndexStatus()
})()

const calcTotalkW = computed(() => {
  return statusList.value.electro_regist?.status_other?.total_kw ?? 0
})

const constructionCost = ElectroRegist.CONSTRUCTION_COST
const electricityUsageFee = ElectroRegist.ELECTRICITY_USAGE_FEE

const calcBackboneWorkCost = computed(() => {
  return constructionCost * calcTotalkW.value
})

const calcElectroUseCost = computed(() => {
  return electricityUsageFee * calcTotalkW.value
})

const calcWaterSupplyAmount = computed(() => {
  const statusOther = statusList.value.sanitary_regist?.status_other
  const sizes = SANITARY_REGIST.SIZES
  const total = sum([
    (statusOther?.phi13 ?? 0) * sizes.find(el => el.index === 'phi13').cost,
    (statusOther?.phi20 ?? 0) * sizes.find(el => el.index === 'phi20').cost,
    (statusOther?.phi25 ?? 0) * sizes.find(el => el.index === 'phi25').cost,
    (statusOther?.phi32 ?? 0) * sizes.find(el => el.index === 'phi32').cost,
  ])
  return total.toLocaleString()
})

const calcDetector = computed(() => {
  const statusOther = statusList.value.detector?.status_other
  const total = sum([
    (statusOther?.total_price_1 ?? 0),
    (statusOther?.total_price_2 ?? 0),
  ])
  return total.toLocaleString()
})

const calcExtraComm = computed(() => {
  const statusOther = statusList.value.extra_comm?.status_other
  const total = sum([
    (statusOther?.qty ?? 0) * EXTRA_COMM.UNIT_COST,
  ])
  return total.toLocaleString()
})

const calcExtraComm2 = computed(() => {
  const statusOther = statusList.value.extra_comm2?.status_other
  const total = sum([
    (statusOther?.total_price_1 ?? 0),
    (statusOther?.total_price_2 ?? 0),
  ])
  return total.toLocaleString()
})

const calcAntennaRegist = computed(() => {
  const statusOther = statusList.value.antenna_regist?.status_other
  const total = statusOther?.total_price ?? 0
  return total.toLocaleString()
})

const isShow = (name) => {
  return isShowByStaffAuthority(name)
}
</script>

<style></style>
