<template>
  <div id="admin">
    <header>
      <h1>JAIS 2025 出展者登録DB&nbsp;&nbsp;管理者用ページ</h1>
    </header>
    <article>
      <h2>管理者メニュー</h2>
      <div id="form_ctn" class="form_box">
        <AdminNav :categoryTop="true" pageName="各種書類締切日の設定" />
        <h3>各種書類締切日の設定</h3>
        <p class="c_green fw_bold">※『yyyymmdd』で入力して下さい。<br>
          ※未入力の場合は無制限表示になります。
          <br>
          ※申込開始は入力日の0:00から受付開始(20251001ならば2025年10月1日0:00からスタート)<br>
          ※申込期限は入力日の23:59:59で受付終了(20251001ならば2025年10月1日23:59:59で終了)</p>
        <table class="common_table zebra_color va_middle va_middle">
          <thead>
            <tr>
              <th>申込項目</th>
              <th>申込開始</th>
              <th>申込期限</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(val, needle, key) in list"
              :key="key"
              :set="form['application_div_' + Number(key + 1)] = needle"
            >
              <td>{{ val }}</td>
              <td><input v-model="form['date_from_' + Number(key + 1)]" type="text"></td>
              <td><input v-model="form['date_to_' + Number(key + 1)]" type="text"></td>
            </tr>
          </tbody>
        </table>
        <ul class="common_list_horizontal">
          <li>
            <router-link
              :to="{name: 'AdminIndex'}"
              custom
              v-slot="{ navigate }"
            >
              <button
                @click="navigate"
                class="common_button gray_button"
              >
                管理メニュートップに戻る
              </button>
            </router-link>
          </li>
          <li><button class="common_button" @click="sendForm" :disabled="sendDisabled">{{ sendLabel }}</button></li>
        </ul>

      </div>
    </article>
  </div>
</template>

<script setup>
import { reactive, ref } from 'vue'
import { cloneDeep, forEach } from 'lodash'

import APIClient from '@/store/repository/APIClient'

import ApiConst from '@/const/api'
import Applications from '@/const/applications'

import AdminNav from "@/components/AdminNav"

const api = new APIClient()

const list = Applications.list
const form = reactive({
})

const fetch = async () => {
  try {
    const fetchApi = await api.get(ApiConst.API_ADMIN_DEADLINE_LIST)
    const res = fetchApi.response
    const errors = res.data?.error_results
    if (errors) {
      throw new Error('Not found.')
    }

    let counter = 0
    forEach(list, (val, key) => {
      counter ++
      const resList = res.data.results.list
      const v = resList.find(el => el.application_div === key)
      form['application_div_' + counter] = key
      form['date_from_' + counter] = v?.date_from ?? ''
      form['date_to_' + counter] = v?.date_to ?? ''
    })
  } catch (e) {
    console.log(e)
    return false
  }
}
fetch()

const sendDisabled = ref(false)
const defaultSendLabel = '  登  録  '
const sendLabel = ref(defaultSendLabel)

const pushRestriction = (message) => {
  sendLabel.value = message
  sendDisabled.value = true
  setTimeout(() => {
    sendLabel.value = defaultSendLabel
    sendDisabled.value = false
  }, 2000)
}
const onInvalidSubmit = (errors) => {
  console.error(errors)
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  })
}
const sendForm = async () => {
  sendDisabled.value = true
  sendLabel.value = '情報を更新しています'
  try {
    const query = cloneDeep(form)
    const res = await api.post(ApiConst.API_ADMIN_DEADLINE_BULKEDIT, query)
    if (res.response.data?.error_results) {
      onInvalidSubmit('入力項目に不備があります。')
      pushRestriction('入力項目に不備があります。')
      throw new Error('API Validation Error')
    }
    fetch()
  } catch (e) {
    onInvalidSubmit(e)
    pushRestriction(e)
    return false
  }
  pushRestriction('情報を更新しています')
}
</script>

<style></style>
