<template>
  <header>
    <h1>JAPAN MOBILITY SHOW 2025&emsp;出展手続きサイト・出展者専用サイト</h1>
  </header>
  <article>
    <h2>二次申込（出展申込書の作成）</h2>
    <div id="form_ctn" class="form_box">

      <ErrorComponent></ErrorComponent>

      <SecondSteps step="1" />
      <h3>出展代理者または連絡先会社名（出展会社と異なる場合）</h3>


      <h4>和文名称</h4>
      <my-input name="deputy_exhibitor_name" width_class="full" type="text" v-model="deputy_exhibitor_name"
        placeholder="例：一般社団法人日本自動車工業会">
      </my-input>
      <p>法人表記は「株式会社」「○○法人」とし、「(株)」などの略称は使用しないで下さい。</p>

      <h4>フリガナ</h4>
      <my-input name="deputy_exhibitor_name_kana" width_class="full" type="text" v-model="deputy_exhibitor_name_kana"
        placeholder="例：ニホンジドウシャコウギョウカイ"></my-input>

      <h4>英文名称</h4>
      <my-input name="deputy_exhibitor_name_en" width_class="full" type="text" v-model="deputy_exhibitor_name_en"
        placeholder="例：Japan Automobile Manufacturers Association, Inc"></my-input>




      <h3>法人代表者（出展会社と異なる場合）</h3>
      <h4>役職</h4>
      <my-input name="deputy_representative_position" width_class="half" type="text"
        v-model="deputy_representative_position" placeholder="例：代表取締役社長"></my-input>
      <h4>氏名</h4>
      <ul class="common_list_horizontal">
        <li class="quater mr10">
          <my-input name="deputy_representative_last_name" width_class="full" type="text"
            v-model="deputy_representative_last_name" placeholder="例：山田"></my-input>
        </li>
        <li class="quater">
          <my-input name="deputy_representative_first_name" width_class="full" type="text"
            v-model="deputy_representative_first_name" placeholder="例：太郎"></my-input>
        </li>
      </ul>

      <!-- one staff /-->
      <h3>主担当者</h3>
      <h4 class="required">部署</h4>
      <my-input name="staff_department" width_class="half" type="text" v-model="staff_department" placeholder="例：広報部">
      </my-input>
      <p class="alert" v-if="errors.staff_department">{{ errors.staff_department }}</p>
      <h4>役職</h4>
      <my-input name="staff_position" width_class="half" type="text" v-model="staff_position" placeholder="例：部長">
      </my-input>
      <h4 class="required">氏名</h4>
      <my-input name="staff_last_name" width_class="quarter mr10" type="text" v-model="staff_last_name"
        placeholder="例：山田">
      </my-input>
      <my-input name="staff_first_name" width_class="quarter" type="text" v-model="staff_first_name" placeholder="例：太郎">
      </my-input>
      <p class="alert" v-if="errors.staff_last_name">{{ errors.staff_last_name }}</p>
      <p class="alert" v-if="errors.staff_first_name">{{ errors.staff_first_name }}</p>

      <template v-if="!isForeign(manager_address_pref)">
      <h4>郵便番号</h4>
      <input type="text" id="manager_postCode" class="input" name="manager_postCode" v-model="manager_postCode"
        maxlength="8" @input="onZipInput($event, 'manager_postCode')" @keyup="onZipChange($event, this, 'manager_address_pref', 'manager_address_jp')"
        placeholder="例：123-4567">
      <p class="alert" v-if="errors.manager_postCode">{{ errors.manager_postCode }}</p>
      </template>
      <h4 class="required">和文所在地</h4>
      <ul class="common_list_horizontal">
        <li class="quarter ">
          <div class="select_ctn">
            <select name="manager_address_pref" v-model="manager_address_pref">
              <option value="">都道府県</option>
              <option v-for="(v, k) in address_pref_options" :key="k" :value="v.value">{{ v.label }}</option>
            </select>
          </div>
        </li>
        <template v-if="!isForeign(manager_address_pref)">
        <li class="three_quarters pl10">
          <my-input name="manager_address_jp" width_class="full" type="text" v-model="manager_address_jp"
            placeholder="例：港区芝大門1-1-30&emsp;日本自動車会館">
          </my-input>

        </li>
        </template>
      </ul>
      <p class="alert" v-if="errors.manager_address_pref">{{ errors.manager_address_pref }}</p>
      <p class="alert" v-if="errors.manager_address_jp">{{ errors.manager_address_jp }}</p>

      <h4 class="required">英文所在地</h4>
      <my-input name="manager_address_en" width_class="full" type="text" v-model="manager_address_en"
        placeholder="例：Jidosha Kaikan, 1-30, Shiba Daimon 1-chome, Minato-ku, Tokyo 105-0012">
      </my-input>
      <p class="alert" v-if="errors.manager_address_en">{{ errors.manager_address_en }}</p>

      <h4 class="required">電話番号</h4>
      <my-input name="staff_tel" width_class="half" type="text" v-model="staff_tel" placeholder="例：03-1234-1234">
      </my-input>
      <p class="alert" v-if="errors.staff_tel">{{ errors.staff_tel }}</p>

      <h4>担当者メールアドレス</h4>
      {{staff_email}}

      <!-- / one staff-->

      <!-- one staff /-->
      <div class="v-if_box" v-for="(deputy, index) in _deputies" :key="deputy.key">
        <h3>副担当者{{ index + 1 }}</h3>
        <h4 class="required">部署</h4>
        <Field :name="`deputies[${index}].department`" v-slot="{ field }">
          <input class="half" type="text" placeholder="例：広報部" v-bind="field" />
        </Field>
        <p class="alert" v-if="errors[`deputies[${index}].department`]">{{ errors[`deputies[${index}].department`] }}
        </p>
        <h4>役職</h4>
        <Field :name="`deputies[${index}].position`" v-slot="{ field }">
          <input class="half" type="text" placeholder="例：部長" v-bind="field" />
        </Field>
        <h4 class="required">氏名</h4>
        <Field :name="`deputies[${index}].last_name`" v-slot="{ field }">
          <input class="quarter mr10" type="text" placeholder="例：山田" v-bind="field" />
        </Field>
        <Field :name="`deputies[${index}].first_name`" v-slot="{ field }">
          <input class="quarter" type="text" placeholder="例：太郎" v-bind="field" />
        </Field>
        <p class="alert" v-if="errors[`deputies[${index}].last_name`]">{{ errors[`deputies[${index}].last_name`] }}</p>
        <p class="alert" v-if="errors[`deputies[${index}].first_name`]">{{ errors[`deputies[${index}].first_name`] }}
        </p>

        <template v-if="!isForeign(deputies.find(el => el.key === index)?.value?.address_pref)">
        <h4>郵便番号</h4>
        <Field :name="`deputies[${index}].postCode`" v-slot="{ field }">
          <input type="text" id="`deputy_[${index}]_postCode`" class="input" maxlength="8"
            @input="onZipInput($event)"
            @keyup="onZipChange2($event, `deputies[${index}].address_pref`, `deputies[${index}].address_jp`)"
            placeholder="例：123-4567" v-bind="field">
        </Field>
        <p class="alert" v-if="errors[`deputies[${index}].postCode`]">{{ errors[`deputies[${index}].postCode`]
        }}</p>
        </template>
        <h4 class="required">和文所在地</h4>
        <ul class="common_list_horizontal">
          <li class="quarter ">
            <div class="select_ctn">
              <Field :name="`deputies[${index}].address_pref`" v-slot="{ field }" :ref="setDeputyElsRef">
                <select v-bind="field">
                  <option value="">都道府県</option>
                  <option v-for="(v, k) in address_pref_options" :key="k" :value="v.value">{{ v.label }}</option>
                </select>
              </Field>
            </div>
          </li>
          <template v-if="!isForeign(deputies.find(el => el.key === index)?.value?.address_pref)">
          <li class="three_quarters pl10">
            <Field :name="`deputies[${index}].address_jp`" v-slot="{ field }" :ref="setDeputyElsRef">
              <input class="full" type="text" placeholder="例：港区芝大門1-1-30&emsp;日本自動車会館" v-bind="field" />
            </Field>
          </li>
          </template>
        </ul>
        <p class="alert" v-if="errors[`deputies[${index}].address_pref`]">{{ errors[`deputies[${index}].address_pref`]
        }}</p>
        <p class="alert" v-if="errors[`deputies[${index}].address_jp`]">{{ errors[`deputies[${index}].address_jp`] }}
        </p>

        <h4 class="required">英文所在地</h4>
        <Field :name="`deputies[${index}].address_en`" v-slot="{ field }">
          <input class="full" type="text"
            placeholder="例：Jidosha Kaikan, 1-30, Shiba Daimon 1-chome, Minato-ku, Tokyo 105-0012" v-bind="field" />
        </Field>
        <p class="alert" v-if="errors[`deputies[${index}].address_en`]">{{ errors[`deputies[${index}].address_en`] }}
        </p>

        <h4 class="required">電話番号</h4>
        <Field :name="`deputies[${index}].tel`" v-slot="{ field }">
          <input class="half" type="text" placeholder="例：03-1234-1234" v-bind="field" />
        </Field>
        <p class="alert" v-if="errors[`deputies[${index}].tel`]">{{ errors[`deputies[${index}].tel`] }}</p>

        <h4 class="required">担当者メールアドレス</h4>
        <Field :name="`deputies[${index}].email`" v-slot="{ field }">
          <input class="full" type="text" placeholder="例：example@example.co.jp" v-bind="field" />
        </Field>
        <p class="alert" v-if="errors[`deputies[${index}].email`]">{{ errors[`deputies[${index}].email`] }}</p>
      </div>
      <!-- / one staff-->

      <div class="add_sub_staff">
        <p style="margin-bottom: 0.5em">※担当者を4名まで追加登録できます。</p>
        <p>※副担当者を追加した際は、副担当者宛てにパスワード設定依頼メールが送信されます。</p>
        <div class="common_add_button" @click="removeDeputy(sub_staff_num - 1); sub_staff_num--"
          v-if="sub_staff_num > 0">副担当者{{ sub_staff_num }}の削除</div><br>
        <div class="common_add_button" @click="addDeputy({
          department: '',
          last_name: '',
          first_name: '',
          address_pref: '',
          address_jp: '',
          address_en: '',
          tel: '',
          email: '',
        }); sub_staff_num++" v-if="sub_staff_num < 4">副担当者{{ sub_staff_num + 1 }}の追加</div>
      </div>

    </div>
    <div class="button_ctn">
      <button class="submit" @click="sendForm">登録内容の確認</button>
    </div>
  </article>
</template>

<script>
import MyInput from '@/components//MyInput.vue';
import ErrorComponent from '@/components/ErrorComponent.vue';
import { configure, Field, useField, useFieldArray, useForm } from 'vee-validate';
import * as yup from 'yup';
import { useRouter } from 'vue-router'
import KenAll from 'ken-all';
import ADDRESS from '../const/address_elements.js'
import { store } from '@/store'
import SecondSteps from './SecondSteps.vue';
import YupConst from '@/const/yup';
import {
  zipNumOnlyTest,
  makeZip
} from '@/helper/index';

const zipRegExp = new RegExp(YupConst.ZIP_NULLABLE_PATTERN)

configure({
  validateOnModelUpdate: false,
  validateOnChange: false,
  validateOnBlur: false,
});

const phoneRegExp = new RegExp(YupConst.PHONE_PATTERN)

const foreignID =  ADDRESS.address_pref_options.find(el => {
  return el.label === ADDRESS.address_pref_foreign
})?.value

const isForeign = (v) => {
  return v === foreignID
}

export default {
  components: {
    MyInput,
    Field,
    ErrorComponent,
    SecondSteps,
  },
  setup() {
    const router = useRouter();

    const schema = yup.object({
      staff_department: yup.string().required('必須の項目です。'),
      staff_last_name: yup.string().required('必須の項目です。'),
      staff_first_name: yup.string().required('必須の項目です。'),
      manager_postCode: yup.string()
        .test(
          'manager_postCode_pref',
          YupConst.ZIP_PATTERN_MSG,
          function(v) {
            if (v === undefined) return true
            if ( isForeign(manager_address_pref.value) ) return true
            return v.match(zipRegExp)
          }
        ),
      manager_address_pref: yup.string().required('必須の項目です。'),
      manager_address_jp: yup.string()
        .test(
          'manager_address_jp_required',
          "必須です。",
          function(v) {
            if (isForeign(manager_address_pref.value)) return true
            if (v === undefined) return false
            if (v.length) return true
            return false
          }
        ),
      manager_address_en: yup.string().required('必須の項目です。'),
      staff_tel: yup.string().required('必須の項目です。').matches(phoneRegExp, YupConst.PHONE_PATTERN_MSG),

      deputies: yup
        .array()
        .max(5, "お申込み出来る人数は5人までです")
        .of(
          yup.object().shape({
            department: yup.string().required("必須です。"),
            last_name: yup.string().required("必須です。"),
            first_name: yup.string().required("必須です。"),
            postCode: yup.string()
              .test(
                'deputy_postCode_pref',
                YupConst.ZIP_PATTERN_MSG,
                function(v) {
                  if (v === undefined) return true
                  if ( isForeign(this.parent.address_pref) ) return true
                  return v.match(zipRegExp)
                }
              ),
            address_pref: yup.string().required("必須です。"),
            address_jp: yup.string()
              .test(
                'deputy_address_jp_required',
                "必須です。",
                function(v) {
                  if (isForeign(this.parent.address_pref)) return true
                  if (v === undefined) return false
                  if (v.length) return true
                  return false
                }
              ),
            address_en: yup.string().required("必須です。"),
            tel: yup.string().required('必須の項目です。').matches(phoneRegExp, YupConst.PHONE_PATTERN_MSG),
            email: yup.string().required("必須の項目です。").email("メールアドレスの形式が不正です。"),
          })
        ),

    });

    const { errors, handleSubmit, values } = useForm({
      validationSchema: schema,
    });

    const onInvalidSubmit = () => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    }

    const sendForm = handleSubmit(() => {
      router.push({ path: '/second/confirm' });
    }, onInvalidSubmit);

    const { value: staff_department } = useField('staff_department', null, {
      validateOnValueUpdate: false,
    });
    const { value: staff_last_name } = useField('staff_last_name', null, {
      validateOnValueUpdate: false,
    });
    const { value: staff_first_name } = useField('staff_first_name', null, {
      validateOnValueUpdate: false,
    });
    const { value: staff_tel } = useField('staff_tel', null, {
      validateOnValueUpdate: false,
    });

    const { value: manager_postCode } = useField('manager_postCode', null, {
      validateOnValueUpdate: false,
    });
    const { value: manager_address_pref } = useField('manager_address_pref', null, {
      validateOnValueUpdate: false,
    });
    const { value: manager_address_jp } = useField('manager_address_jp', null, {
      validateOnValueUpdate: false,
    });
    const { value: manager_address_en } = useField('manager_address_en', null, {
      validateOnValueUpdate: false,
    });

    const { remove: removeDeputy, push: addDeputy, fields: deputies } = useFieldArray("deputies", null, {
      validateOnValueUpdate: false,
    });
    
    let sub_staff_num = 0

    store.state.entrydata.deputies?.forEach(item => {
      sub_staff_num ++
      addDeputy({
        department: item.department,
        position: item.position,
        last_name: item.last_name,
        first_name: item.first_name,
        postCode: item.postCode,
        address_pref: item.address_pref,
        address_jp: item.address_jp,
        address_en: item.address_en,
        tel: item.tel,
        email: item.email,
      })
    })

    return {
      errors,
      values,
      staff_department,
      staff_last_name,
      staff_first_name,
      staff_tel,
      manager_postCode,
      manager_address_pref,
      manager_address_jp,
      manager_address_en,
      deputies,

      removeDeputy,
      addDeputy,
      sub_staff_num,

      sendForm,
      isForeign,
    };
  },
  data() {
    return {
      deputy_exhibitor_name: '',
      deputy_exhibitor_name_kana: '',
      deputy_exhibitor_name_en: '',
      deputy_representative_position: '',
      deputy_representative_last_name: '',
      deputy_representative_first_name: '',
      staff_position: '',

      address_pref_options: ADDRESS.address_pref_options,
      sub_staff1_postCode: '',
      deputy_form_els: [],


    }
  },
  async created() {

  },
  mounted() {
    this.inputStoreData('deputy_exhibitor_name')
    this.inputStoreData('deputy_exhibitor_name_kana')
    this.inputStoreData('deputy_exhibitor_name_en')
    this.inputStoreData('deputy_representative_position')
    this.inputStoreData('deputy_representative_last_name')
    this.inputStoreData('deputy_representative_first_name')
    this.inputStoreData('staff_department')
    this.inputStoreData('staff_position')
    this.inputStoreData('staff_last_name')
    this.inputStoreData('staff_first_name')
    this.inputStoreData('manager_postCode')
    this.inputStoreData('manager_address_pref')
    this.inputStoreData('manager_address_jp')
    this.inputStoreData('manager_address_en')
    this.inputStoreData('staff_first_name')
    this.inputStoreData('staff_tel')
    this.inputStoreData('staff_email')
    this.inputStoreData('address_jp')
    this.inputStoreData('address_en')
  },
  methods: {
    onZipInput(e, key = null) {
      if (!zipNumOnlyTest(e.target.value)) return
      const zipValue = makeZip(e.target.value)
      if (key) {
        this[key] = zipValue
      } else {
        e.target.value = zipValue
      }
    },
    inputStoreData(key) {
      if (!this.$store.state.entrydata[key]) return;
      this[key] = this.$store.state.entrydata[key]
    },
    onZipChange(e, target, pref, address) {
      const val = e.target.value.replace(/[^0-9]/g, '')
      if (val.length === 7) {
        KenAll(val).then(res => {
          const prefecture = res[0].shift()
          if (res.length === 0) {
            target[pref] = '該当する住所はありません';
            target[address] = '該当する住所はありません';
          } else {
            const pref_match = this.address_pref_options.find(item => item.label === prefecture)
            target[pref] = pref_match.value;
            target[address] = res[0].join('');
          }
        });
      }
    },
    onZipChange2(e, pref, address) {
      const val = e.target.value.replace(/[^0-9]/g, '')
      if (val.length === 7) {
        KenAll(val).then(res => {
          const prefEl = this.deputy_form_els.filter(item => {
            return item.$el.nextElementSibling.name === pref
          })[0]
          const addressEl = this.deputy_form_els.filter(item => {
            return item.$el.nextElementSibling.name === address
          })[0]
          if (res.length === 0) {
            prefEl.handleChange('該当する住所はありません')
            addressEl.handleChange('該当する住所はありません')
          } else {
            const prefecture = res[0].shift()
            const pref_match = this.address_pref_options.find(item => item.label === prefecture)
            prefEl.handleChange(pref_match.value)
            addressEl.handleChange(res[0].join(''))
          }
        });
      }
    },
    setDeputyElsRef(el) {
      if (el) {
        this.deputy_form_els.push(el)
      }
    },
  },
  beforeUpdate() {
    this.deputy_form_els = []
  },
  watch: {
    deputy_exhibitor_name: function (v) {
      this.$store.commit('entrydata/deputy_exhibitor_name', v)
    },
    deputy_exhibitor_name_kana: function (v) {
      this.$store.commit('entrydata/deputy_exhibitor_name_kana', v)
    },
    deputy_exhibitor_name_en: function (v) {
      this.$store.commit('entrydata/deputy_exhibitor_name_en', v)
    },
    deputy_representative_position: function (v) {
      this.$store.commit('entrydata/deputy_representative_position', v)
    },
    deputy_representative_last_name: function (v) {
      this.$store.commit('entrydata/deputy_representative_last_name', v)
    },
    deputy_representative_first_name: function (v) {
      this.$store.commit('entrydata/deputy_representative_first_name', v)
    },
    manager_postCode: function (v) {
      this.$store.commit('entrydata/manager_postCode', v)
    },
    manager_address_pref: function (v) {
      this.$store.commit('entrydata/manager_address_pref', v)
      if ( isForeign(v) ) {
        this.manager_postCode = ''
        this.manager_address_jp = ''
      }
    },
    manager_address_jp: function (v) {
      this.$store.commit('entrydata/manager_address_jp', v)
    },
    manager_address_en: function (v) {
      this.$store.commit('entrydata/manager_address_en', v)
    },
    staff_department: function (v) {
      this.$store.commit('entrydata/staff_department', v)
    },
    staff_position: function (v) {
      this.$store.commit('entrydata/staff_position', v)
    },
    staff_last_name: function (v) {
      this.$store.commit('entrydata/staff_last_name', v)
    },
    staff_first_name: function (v) {
      this.$store.commit('entrydata/staff_first_name', v)
    },
    staff_tel: function (v) {
      this.$store.commit('entrydata/staff_tel', v)
    },
    staff_email: function (v) {
      this.$store.commit('entrydata/staff_email', v)
    },

  },
  computed: {

    _deputies() {
      const deputies = this.values.deputies
      if (!deputies) return false
      this.$store.commit('entrydata/deputies', deputies.map(item => ({ ...item })))
      return deputies
    },
  }
};
</script>

<style>

</style>
