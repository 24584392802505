import ApiConst from '@/const/api'
import APIClient from '@/store/repository/APIClient'
const api = new APIClient()

export const fileEncode = async (file, callback) => {
  return new Promise(resolve => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => {
      callback(reader.result)
      resolve()
    }
  })
}

export const loadFile = async (event, callback) => {
  try {
    if (!event.target.files[0]) {
      throw new Error('File does not exist')
    }
    const file = event.target.files[0]
    await fileEncode(file, callback)
  } catch (e) {
    console.log(e)
    return false
  }
}

export const getFileExtension = (v) => {
  return v.toString().slice(v.indexOf('/') + 1, v.indexOf(';'))
}

export const uploadFile = async (application_div, form_no, value, relation_application_id) => {
  try {
    const ext = getFileExtension(value)
    const query = {
      application_div : application_div,
      form_no: form_no,
      upload_file: value,
      extension: ext,
      json_data: {
        relation_application_id
      },
    }
    const res = await api.post(ApiConst.API_APP_UPLOAD, query)
    if (res.response.data?.error_results) {
      throw new Error('API Validation Error')
    }
  } catch (e) {
    return false
  }
}

export const deleteFile = async (application_id) => {
  try {
    const operation = "D"
    const query = { operation, application_id }
    const res = await api.post(ApiConst.API_APP_EDIT, query)
    if (res.response.data?.error_results) {
      throw new Error('API Validation Error')
    }
  } catch (e) {
    return false
  }
}
