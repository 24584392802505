<template>
  <textarea
    :type="type"
    :class="width_class"
    :value="modelValue"
    :placeholder="placeholder"
    :rows="rows"
    :cols="cols"
    @input="updateValue"
    :disabled="disabled"
  ></textarea>
</template>

<script>
export default {
  name: "MyTextarea",
  props: {
    modelValue: { type: String, required: true },
    placeholder: { type: String, required: false },
    rows: { type: Number, required: false },
    cols: { type: Number, required: false },
    disabled: {
      type: Boolean,
      default: false,
      required: false
    },
    width_class: {
      type: String,
      required: false
    }
  },
  methods: {
    updateValue: function(e) {
      this.$emit('update:modelValue', e.target.value)
    }
  },
};
</script>