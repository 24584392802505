<template>
  <header>
    <h1>JAPAN MOBILITY SHOW 2025&emsp;出展申込サイト</h1>
  </header>
  <article>
    <h2>一次申込&emsp;確認</h2>
    <div id="form_ctn" class="form_box">

      <ErrorComponent></ErrorComponent>

      <h3>会社名</h3>
      <h4>和文名称</h4>
      <p>{{ exhibitor_name }}</p>

      <h4>フリガナ</h4>
      <p>{{ exhibitor_name_kana }}</p>

      <h4>英文名称</h4>
      <p>{{ exhibitor_name_en }}</p>

      <h3>担当者</h3>
      <h4>氏名</h4>
      <p>{{ staff_last_name }} {{ staff_first_name }}</p>

      <h4>電話番号</h4>
      <p>{{ staff_tel }}</p>

      <h4>担当者メールアドレス</h4>
      <p>{{ staff_email }}</p>

      <h3>会員区分</h3>
      <p>{{ membership_category_label }}</p>

      <h3>過去の出展実績</h3>
      <p>{{ past_achievement_label }}</p>

      <h3>出展カテゴリー</h3>
      <p>{{ category_div }}</p>

      <h3>企業WEBサイト</h3>
      <p>{{ site_url }}</p>
      
      <div v-if="appeal_information_visible">
      <h3>主な製品/サービス情報</h3>
      <p>{{ appeal_information }}</p>
    </div>

      <div class="button_ctn">
        <ul>
          <li><button class="submit gray_button" @click="previousForm">修正</button></li>
          <li><button class="submit" @click="sendForm">送信</button></li>
        </ul>

      </div>

    </div>
  </article>
</template>

<script>
import APIClient from '@/store/repository/APIClient'
import ErrorComponent from '@/components/ErrorComponent.vue';
import {
  perseIntOrNull
} from '@/helper/index';

export default {
  components: {
    ErrorComponent,
  },
  async created() {
    this.api = new APIClient()
    const res = await this.api.getConfig()

    this.$store.commit('disicion/membership_category_options', res.data.results.membership_category_options)
    this.$store.commit('disicion/past_achievement_options', res.data.results.past_achievement_options)
  },
  methods: {
    previousForm() {
      this.$router.push({ path: '/' })
    },

    async sendForm() {
      try {
        const category_div_store = this.$store.state.form.category_div
        const category_div1 = category_div_store.category_div.join(',')
        const category_div2 = category_div_store["category_div_" + category_div1]
                      ? String(category_div_store["category_div_" + category_div1].join(','))
                      : null
        
        const query = {
          //左がAPI仕様書の名前 右がdataの名前
          exhibitor_name: this.exhibitor_name,
          exhibitor_name_kana: this.exhibitor_name_kana,
          exhibitor_name_en: this.exhibitor_name_en,
          staff_last_name: this.staff_last_name,
          staff_first_name: this.staff_first_name,
          staff_tel: this.staff_tel,
          staff_email: this.staff_email,
          membership_category: perseIntOrNull(this.membership_category),

          past_achievement: perseIntOrNull(this.past_achievement),
          site_url: this.site_url,
          appeal_information: this.appeal_information,
          category_div1: perseIntOrNull(category_div1),
          category_div2: category_div2,
        }

        const res = await this.api.post('/api/exhibit_participation', query)
        if (res.response.data?.error_results) {
          throw new Error('API Validation Error')
        }
      } catch (e) {
        window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
        console.error(e.message)
        return false
      }
      this.$router.push({ path: 'complete' })
    }

  },
  computed: {
    exhibitor_name() {
      return this.$store.state.form.exhibitor_name
    },
    exhibitor_name_kana() {
      return this.$store.state.form.exhibitor_name_kana
    },
    exhibitor_name_en() {
      return this.$store.state.form.exhibitor_name_en
    },
    staff_last_name() {
      return this.$store.state.form.staff_last_name
    },
    staff_first_name() {
      return this.$store.state.form.staff_first_name
    },
    staff_tel() {
      return this.$store.state.form.staff_tel
    },
    staff_email() {
      return this.$store.state.form.staff_email
    },
    membership_category() {
      return this.$store.state.form.membership_category
    },


    membership_category_label() {
      const array = this.$store.state.disicion.membership_category_options
      return array.find(item => item.value === this.membership_category).label
    },
    past_achievement() {
      return this.$store.state.form.past_achievement
    },
    past_achievement_label() {
      const array = this.$store.state.disicion.past_achievement_options
      return array.find(item => item.value === this.past_achievement).label
    },
    category_div() {
      const checked_options = this.$store.state.form.category_div
      const options = this.$store.state.disicion.category_div_options
      const results = Object.entries(checked_options).map(value => {
        const label = value[0]
        const values = value[1]
        const parentValue = label.replace('category_div_', '')
        const parentObj = options.find(v => v.value === parentValue)
        let opts = options
        if (parentObj) {
          opts = parentObj.options
        }
        const labels = values.map(v => {
          const m = opts.find(f => f.value === v)
          return m.label
        })
        return {
          'label': label,
          'value': labels,
        }
      })
      results.sort((a, b) => {
        return (a.label.length - b.label.length);
      })
      return results.map(v => v.value).join(' - ')//ツナギ文字

    },
    site_url() {
      return this.$store.state.form.site_url
    },
    appeal_information() {
      return this.$store.state.form.appeal_information
    },
    appeal_information_visible() {
      const category_div_store = this.$store.state.form.category_div
      const category_div1 = category_div_store.category_div
      if (!category_div1.includes('10')) return true
      return false
    },
  }
}
</script>
