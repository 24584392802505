<template>
  <ol class="breadcrumbs" v-if="props.pageName">
    <li><router-link :to="{name: 'AdminIndex'}">管理者メニュー</router-link></li>
    <template v-if="!props.categoryTop">
    <li v-if="!props.category"><router-link :to="{name: 'AdminApplicationSearch'}">データ入力状況検索</router-link></li>
    <li v-if="props.category === 2"><router-link :to="{name: 'AdminSpoofingSearch'}">各種申込の代理登録</router-link></li>
    <li v-if="props.category === 3"><router-link :to="{
      name: 'AdminDemandSearch',
      params: {
        admin_demand_search : route.name
      }
    }">{{ category3arhiveName }}</router-link></li>
    <li v-if="props.category === 4"><router-link :to="{name: 'AdminStatusSearch'}">各出展者の各種申込ステータス確認</router-link></li>
    </template>
    <li>{{ props.pageName }}</li>
  </ol>
</template>

<script setup>
import { defineProps } from 'vue'
import { useRoute } from 'vue-router'
import ADMIN_DEMAND_SEARCH from '@/const/admin_demand_search'

const props = defineProps({
  category: Number,
  categoryTop: Boolean,
  pageName: String,
})

const route = useRoute()

const category3arhiveName = (() => ADMIN_DEMAND_SEARCH.PAGES[route.name])()
</script>
