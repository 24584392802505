<template>
  <header>
    <h1>JAPAN MOBILITY SHOW 2025 <br class="pc_none">出展手続きサイト・出展者専用サイト</h1>
  </header>
  <article id="p2">
    <PhaseHeader />
    <div id="form_ctn" class="form_box">
      <PhaseNav
        :current="3"
      />

      <ErrorComponent></ErrorComponent>


      <div id="form_ctn" class="form_box">

        <h3>出展代理者または連絡先会社名（出展会社と異なる場合）</h3>

        <h4>和文名称</h4>
        
        <p>{{ deputy_exhibitor_name }}<span v-if="deputy_exhibitor_name == ''">{{ exhibitor_name }}</span></p>

        <h4>フリガナ</h4>
        <p>{{ deputy_exhibitor_name_kana }}<span v-if="deputy_exhibitor_name_kana == ''">{{ exhibitor_name_kana }}</span>
        </p>
        <h4>英文名称</h4>
        <p>{{ deputy_exhibitor_name_en }}<span v-if="deputy_exhibitor_name_en == ''">{{ exhibitor_name_en }}</span></p>

        <h3>法人代表者（出展会社と異なる場合）</h3>
        <h4>役職</h4>
        <p>{{ deputy_representative_position }}<span v-if="deputy_representative_position == ''">{{
          representative_position
        }}</span></p>
        <h4>氏名</h4>
        <p>{{ deputy_representative_last_name }}<span v-if="deputy_representative_last_name == ''">{{
          representative_last_name
        }}</span>&emsp;{{ deputy_representative_first_name }}<span v-if="deputy_representative_first_name == ''">{{
  representative_first_name
}}</span></p>


        <h3>主担当者</h3>
        <h4>部署</h4>
        <p>{{ staff_department }}</p>
        <h4>役職</h4>
        <p>{{ staff_position }}</p>
        <h4>氏名</h4>
        <p>{{ staff_last_name }}&emsp;{{ staff_first_name }}</p>


        <h4>郵便番号</h4>
        <p>{{ manager_postCode }}</p>
        <h4>和文所在地</h4>
        <p>{{ manager_address_pref_label }}{{ manager_address_jp }}</p>
        <h4>英文所在地</h4>
        <p>{{ manager_address_en }}</p>
        <h4>電話番号</h4>
        <p>{{ staff_tel }}</p>
        <h4>メールアドレス</h4>
        <p>{{ staff_email }}</p>

        <div class="v-if_box" v-for="(deputy, index) in deputies" :key="deputy.key">
          <h3>副担当者{{ index + 1 }}</h3>


          <h4>部署</h4>
          <p>{{ deputy.department }}</p>
          <h4>役職</h4>
          <p>{{ deputy.position }}</p>
          <h4>氏名</h4>
          <p>{{ deputy.last_name }}&emsp;{{ deputy.first_name }}</p>


          <h4>郵便番号</h4>
          <p>{{ deputy.postCode }}</p>
          <h4>和文所在地</h4>
          <p>{{ deputy.address_pref_label }}{{ deputy.address_jp }}</p>
          <h4>英文所在地</h4>
          <p>{{ deputy.address_en }}</p>
          <h4>電話番号</h4>
          <p>{{ deputy.tel }}</p>
          <h4>メールアドレス</h4>
          <p>{{ deputy.email }}</p>
          <h4>アカウントの種類</h4>
          <p>{{ deputy.staff_authority_label }}</p>
        </div>



      </div>

      <div class="button_ctn">
        <ul>
          <li><router-link to="/p2/form02" class="submit gray_button">編集画面に戻る</router-link></li>
          <li v-if="this.$store.state.login_user.staff_authority === 1"><button :class="sendStatus"
              @click="registComplete">{{ sendLabel }}</button></li>
        </ul>

      </div>



    </div>









  </article>
</template>

<script>
import PhaseHeader from '@/components/PhaseHeader'
import PhaseNav from '@/components/PhaseNav'
import APIClient from '@/store/repository/APIClient'
import ErrorComponent from '@/components/ErrorComponent.vue';
import {
  indoorVisible,
  outdoorVisible,
  partsMemberVisible,
  partsVisible,
  mobilityVisible,
  kidzaniaMemberVisible,
  kidzaniaVisible,
  otherVisible,
} from '@/helper/categoryVisible';
import {
  perseIntOrNull,
  isValue
} from '@/helper/index';
import {
  logout
} from '@/helper/logout';
import ADDRESS from '@/const/address_elements.js'

const defaultSendLabel = "この内容で登録"

export default {
  components: {
    ErrorComponent,
    PhaseHeader,
    PhaseNav,
  },
  data() {
    return {
      postCode: '',
      exhibitor_name: '',
      deputy_exhibitor_name: '',
      deputy_exhibitor_name_kana: '',
      deputy_exhibitor_name_en: '',
      deputy_representative_position: '',
      deputy_representative_last_name: '',
      deputy_representative_first_name: '',
      address_pref: this.$store.state.entrydata.address_pref,
      
      address_pref_options: ADDRESS.address_pref_options,
      sendStatus: 'submit',
      sendLabel: defaultSendLabel,
    }
  },
  async created() {
    this.api = new APIClient()
    const res = await this.api.getConfig()
    this.$store.commit('disicion/membership_category_options', res.data.results.membership_category_options)
    this.$store.commit('disicion/category_div_options', res.data.results.category_div_options)
    this.$store.commit('disicion/joint_adjacent_options', res.data.results.joint_adjacent_options)
    this.$store.commit('disicion/country_main_options', res.data.results.country_main_options)
    this.$store.commit('disicion/country_other_options', res.data.results.country_other_options)

  },
  mounted() {
    this.inputStoreData('koma_bango')
    this.inputStoreData('exhibitor_name')
    this.inputStoreData('exhibitor_name_kana')
    this.inputStoreData('exhibitor_name_en')
    this.inputStoreData('staff_last_name')
    this.inputStoreData('staff_first_name')
    this.inputStoreData('staff_tel')
    this.inputStoreData('staff_email')
    this.inputStoreData('postCode')

    this.inputStoreData('representative_position')
    this.inputStoreData('representative_last_name')
    this.inputStoreData('representative_first_name')
    this.inputStoreData('staff_authority')
    this.inputStoreData('staff_authority_1')
    this.inputStoreData('staff_authority_2')
    this.inputStoreData('staff_authority_3')
    this.inputStoreData('staff_authority_4')

    //form04
    this.inputStoreData('deputy_exhibitor_name')
    this.inputStoreData('deputy_exhibitor_name_kana')
    this.inputStoreData('deputy_exhibitor_name_en')
    this.inputStoreData('deputy_representative_position')
    this.inputStoreData('deputy_representative_last_name')
    this.inputStoreData('deputy_representative_first_name')
    this.inputStoreData('manager_postCode')
    this.inputStoreData('manager_address_pref')
    this.inputStoreData('manager_address_jp')
    this.inputStoreData('manager_address_en')
    this.inputStoreData('staff_department')
    this.inputStoreData('staff_position')


  },
  methods: {
    inputStoreData(key) {
      if (!this.$store.state.entrydata[key]) return;
      this[key] = this.$store.state.entrydata[key]
    },
    async registComplete() {

      const current_detail_res = await this.api.get("/api/exhibitor/second_order/detail")

      const current_staff_email = current_detail_res?.response?.data?.results?.json_data?.staff_email

      try {

        const query = {
          //左がAPI仕様書の名前 右がdataの名前

          representative_post: this.representative_position,
          representative_last_name: this.representative_last_name,
          representative_first_name: this.representative_first_name,

          agent_name: this.deputy_exhibitor_name,
          agent_name_kana: this.deputy_exhibitor_name_kana,
          agent_name_en: this.deputy_exhibitor_name_en,
          agent_representative_post: this.deputy_representative_position,
          agent_representative_last_name: this.deputy_representative_last_name,
          agent_representative_first_name: this.deputy_representative_first_name,

          staff_department: this.staff_department,
          staff_post: this.staff_position ?? null,
          staff_last_name: this.staff_last_name,
          staff_first_name: this.staff_first_name,
          staff_zip: this.manager_postCode,
          staff_pref: this.manager_address_pref,
          staff_address: this.manager_address_jp,
          staff_address_en: this.manager_address_en,
          staff_tel: this.staff_tel,
          staff_email: this.staff_email, //必須
          staff_authority: 1, //必須

          staff_department_1: this.deputies?.[0]?.department ?? null,
          staff_post_1: this.deputies?.[0]?.position ?? null,
          staff_last_name_1: this.deputies?.[0]?.last_name ?? null,
          staff_first_name_1: this.deputies?.[0]?.first_name ?? null,
          staff_zip_1: this.deputies?.[0]?.postCode ?? null,
          staff_pref_1: this.deputies?.[0]?.address_pref ?? null,
          staff_address_1: this.deputies?.[0]?.address_jp ?? null,
          staff_address_en_1: this.deputies?.[0]?.address_en ?? null,
          staff_tel_1: this.deputies?.[0]?.tel ?? null,
          staff_email_1: this.deputies?.[0]?.email ?? null,
          staff_authority_1: perseIntOrNull(this.deputies?.[0]?.staff_authority),

          staff_department_2: this.deputies?.[1]?.department ?? null,
          staff_post_2: this.deputies?.[1]?.position ?? null,
          staff_last_name_2: this.deputies?.[1]?.last_name ?? null,
          staff_first_name_2: this.deputies?.[1]?.first_name ?? null,
          staff_zip_2: this.deputies?.[1]?.postCode ?? null,
          staff_pref_2: this.deputies?.[1]?.address_pref ?? null,
          staff_address_2: this.deputies?.[1]?.address_jp ?? null,
          staff_address_en_2: this.deputies?.[1]?.address_en ?? null,
          staff_tel_2: this.deputies?.[1]?.tel ?? null,
          staff_email_2: this.deputies?.[1]?.email ?? null,
          staff_authority_2: perseIntOrNull(this.deputies?.[1]?.staff_authority),

          staff_department_3: this.deputies?.[2]?.department ?? null,
          staff_post_3: this.deputies?.[2]?.position ?? null,
          staff_last_name_3: this.deputies?.[2]?.last_name ?? null,
          staff_first_name_3: this.deputies?.[2]?.first_name ?? null,
          staff_zip_3: this.deputies?.[2]?.postCode ?? null,
          staff_pref_3: this.deputies?.[2]?.address_pref ?? null,
          staff_address_3: this.deputies?.[2]?.address_jp ?? null,
          staff_address_en_3: this.deputies?.[2]?.address_en ?? null,
          staff_tel_3: this.deputies?.[2]?.tel ?? null,
          staff_email_3: this.deputies?.[2]?.email ?? null,
          staff_authority_3: perseIntOrNull(this.deputies?.[2]?.staff_authority),

          staff_department_4: this.deputies?.[3]?.department ?? null,
          staff_post_4: this.deputies?.[3]?.position ?? null,
          staff_last_name_4: this.deputies?.[3]?.last_name ?? null,
          staff_first_name_4: this.deputies?.[3]?.first_name ?? null,
          staff_zip_4: this.deputies?.[3]?.postCode ?? null,
          staff_pref_4: this.deputies?.[3]?.address_pref ?? null,
          staff_address_4: this.deputies?.[3]?.address_jp ?? null,
          staff_address_en_4: this.deputies?.[3]?.address_en ?? null,
          staff_tel_4: this.deputies?.[3]?.tel ?? null,
          staff_email_4: this.deputies?.[3]?.email ?? null,
          staff_authority_4: perseIntOrNull(this.deputies?.[3]?.staff_authority),



        }

        const res = await this.api.post('/api/exhibitor/edit', query)


        if (res.response.data?.error_results) {
          throw new Error('API Validation Error')
        }



      } catch (e) {
        console.error(e.message)
        return false
      }

      if (current_staff_email === this.staff_email) {
        this.sendStatus = 'comptele'
        this.sendLabel = '登録が完了しました。5秒後にアカウント情報に戻ります。'
        setTimeout(() => {
          this.$router.push({ path: '/p2/confirm' })
        }, 5000)
      } else {
        this.sendStatus = 'comptele'
        this.sendLabel = '登録が完了しました。5秒後にログアウトします。'
        setTimeout(() => {
          logout()
        }, 5000)
      }


    }
  },
  computed: {



    staff_department() {
      return this.$store.state.entrydata.staff_department
    },
    staff_last_name() {
      return this.$store.state.entrydata.staff_last_name
    },
    staff_first_name() {
      return this.$store.state.entrydata.staff_first_name
    },
    manager_postCode() {
      return this.$store.state.entrydata.manager_postCode
    },
    manager_address_pref() {
      return this.$store.state.entrydata.manager_address_pref
    },
    manager_address_jp() {
      return this.$store.state.entrydata.manager_address_jp
    },
    manager_address_en() {
      return this.$store.state.entrydata.manager_address_en
    },
    staff_tel() {
      return this.$store.state.entrydata.staff_tel
    },
    staff_email() {
      return this.$store.state.entrydata.staff_email
    },

    staff_position() {
      return this.$store.state.entrydata.staff_position
    },
    country_main() {
      return String(this.$store.state.entrydata.country_main)
    },
    country_main_label() {
      const array = this.$store.state.disicion.country_main_options
      if (!array) return
      if (this.country_main == 99999999) return
      return array.find(item => item.value === this.country_main)?.label
    },
    country_other() {
      return this.$store.state.entrydata.country_other
    },
    country_other_label() {
      const array = this.$store.state.disicion.country_other_options
      if (!array) return
      if (this.country_other == "") return
      return array.find(item => item.value === this.country_other)?.label || ''
    },
    brand1_country_main_label() {
      const array = this.$store.state.disicion.country_main_options
      if (!array) return
      if (this.brand1_country_main == 99999999) return
      return array.find(item => item.value === this.brand1_country_main)?.label
    },
    brand1_country_other_label() {
      const array = this.$store.state.disicion.country_other_options
      if (!array) return
      if (this.brand1_country_other == "") return
      return array.find(item => item.value === this.brand1_country_other)?.label
    },
    brand2_country_main_label() {
      const array = this.$store.state.disicion.country_main_options
      if (!array) return
      if (this.brand2_country_main == 99999999) return
      return array.find(item => item.value === this.brand2_country_main)?.label
    },
    brand2_country_other_label() {
      const array = this.$store.state.disicion.country_other_options
      if (!array) return
      if (!isValue(this.brand2_country_other)) return ''
      return array.find(item => item.value === this.brand2_country_other)?.label || ''
    },
    brand3_country_main_label() {
      const array = this.$store.state.disicion.country_main_options
      if (!array) return
      if (this.brand3_country_main == 99999999) return
      return array.find(item => item.value === this.brand3_country_main)?.label
    },
    brand3_country_other_label() {
      const array = this.$store.state.disicion.country_other_options
      if (!array) return
      if (this.brand3_country_other == "") return
      return array.find(item => item.value === this.brand3_country_other)?.label
    },
    brand4_country_main_label() {
      const array = this.$store.state.disicion.country_main_options
      if (!array) return
      if (this.brand4_country_main == 99999999) return
      return array.find(item => item.value === this.brand4_country_main)?.label
    },
    brand4_country_other_label() {
      const array = this.$store.state.disicion.country_other_options
      if (!array) return
      if (!isValue(this.brand4_country_other)) return ''
      return array.find(item => item.value === this.brand4_country_other)?.label
    },
    brand5_country_main_label() {
      const array = this.$store.state.disicion.country_main_options
      if (!array) return
      if (this.brand5_country_main == 99999999) return
      return array.find(item => item.value === this.brand5_country_main)?.label
    },
    brand5_country_other_label() {
      const array = this.$store.state.disicion.country_other_options
      if (!array) return
      if (!isValue(this.brand5_country_other)) return ''
      return array.find(item => item.value === this.brand5_country_other)?.label
    },


    representative_position() {
      return this.$store.state.entrydata.representative_position
    },
    representative_last_name() {
      return this.$store.state.entrydata.representative_last_name
    },
    representative_first_name() {
      return this.$store.state.entrydata.representative_first_name
    },
    address_pref_label() {
      const array = this.address_pref_options
      return array.find(item => item.value === this.address_pref)?.label
    },
    manager_address_pref_label() {
      const array = this.address_pref_options
      return array.find(item => item.value === this.manager_address_pref)?.label
    },
    deputies() {
      const dups = this.$store.state.entrydata.deputies
      dups?.forEach(dup => {
        dup.address_pref_label = this.address_pref_options.find(item => item.value === dup.address_pref)?.label
        dup.staff_authority_label = isValue(dup.staff_authority) ? this.$store.state.disicion.staff_authority_options[parseInt(dup.staff_authority, 10)]?.label : ''
      });


      return dups
    },



    indoorVisible() {
      return indoorVisible(this)
    },
    outdoorVisible() {
      return outdoorVisible(this)
    },
    partsMemberVisible() {
      return partsMemberVisible(this)
    },
    partsVisible() {
      return partsVisible(this)
    },
    mobilityVisible() {
      return mobilityVisible(this)
    },
    kidzaniaMemberVisible() {
      return kidzaniaMemberVisible(this)
    },
    kidzaniaVisible() {
      return kidzaniaVisible(this)
    },
    otherVisible() {
      return otherVisible(this)
    },

  }
}
</script>
