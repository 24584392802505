import { computed } from 'vue'
import { store } from '@/store'
import moment from "moment"
import ApiConst from '@/const/api'
import APIClient from '@/store/repository/APIClient'
import { useRoute } from 'vue-router'
import { sleep } from '@/helper/index'
const KEY = 'exhibitor_application_list'
const api = new APIClient()

export const searchIndexStatusByStore = (key) => {
  try {
    const updateAt = store.state.api_cache[key].updated_at
    if (!updateAt) {
      throw new Error('No updated data available.')
    }
    const now = moment()
    const diff = moment(updateAt).add(10, 's')
    if (diff < now) {
      throw new Error('Update data is out of date.')
    }
    return store.state.api_cache[key].data
  } catch (e) {
    console.log(e)
    return false
  }
}

export const getIndexStatus = async () => {
  const key = KEY
  const cachedStatus = searchIndexStatusByStore(key)
  if (cachedStatus) return cachedStatus

  if (store.state.api_cache.updating) {
    // キャッシュ更新時は更新を待つ、指定回数後
    // ステータスが変わらなければ、ストアを返して終了
    const loopMax = 10
    for (let i = 0; i <= loopMax; i++) {
      await sleep(100)
      if (!store.state.api_cache.updating) {
        return searchIndexStatusByStore(key)
      }
      if (i >= loopMax) {
        // 更新完了ステータス
        store.commit('api_cache/updating', false)
        return searchIndexStatusByStore(key)
      }
    }
  }

  // 更新中ステータス
  store.commit('api_cache/updating', true)

  try {
    const fetchApi = await api.get(ApiConst.API_EXHIBITOR_APP_APPLIST)
    const res = fetchApi.response
    const errors = res.data?.error_results
    if (errors) {
      throw new Error('Not found.')
    }
    store.dispatch('api_cache/update', {
      data: res.data.results,
      key
    })
    // 更新完了ステータス
    store.commit('api_cache/updating', false)
    return store.state.api_cache[key].data
  } catch (e) {
    console.log(e)
    // 更新完了ステータス
    store.commit('api_cache/updating', false)
    return {}
  }
}

export const getIndexByRouterName = (name) => {
  try {
    const index = name.replace(/^(?:p3_publicity_|p3_exhibition_|p3_ticket_)/g, '')
    if (index === 'parking_pass') return 'parking_card'
    return index
  } catch (e) {
    console.log(e)
    return name
  }
}

export const isShowable = computed(() => {
  const route = useRoute()
  if (route.meta.adminSpoofing) {
    return true
  }
  getIndexStatus()
  const key = KEY
  const index = getIndexByRouterName(route.name)
  const cachedStatus = searchIndexStatusByStore(key)
  try {
    const pointer = cachedStatus[index]
    if (!pointer) {
      throw new Error('Status not found.')
    }
    const date_to = pointer.date_to
    const to = date_to ? moment(date_to).add(1, 'd') : false
    const now = moment()
    if ( !to) {
      return true
    }
    if ( to < now ) {
      throw new Error('Deadline has passed.')
    }
    return true
  } catch (e) {
    console.log(e)
    return false
  }
})
