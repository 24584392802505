export default {
  namespaced: true,
  state: {
  },
  mutations: {
    exhibit_participation_status(state, payload) {
      state.exhibit_participation_status = payload
    },
    category_div(state, payload) {
      state.category_div = payload
    },
    second_order_status_options(state, payload) {
      state.second_order_status_options = payload
    },
  },
}
