<template>
  <table class="common_table" v-if="!isNew">
    <tbody>
      <tr>
        <th>種別</th>
        <th>単価（消費税込）</th>
        <th>小間面積</th><!--自動表示-->
        <th>２階建施設床面積</th>
        <th>料金（消費税込）</th>
      </tr>
      <tr>
        <td>小間内清掃</td>
        <td>{{ unitCost.toLocaleString() }}円/12日間・m<sup>2</sup></td>
        <td>{{ decideArea.toLocaleString() }} m<sup>2</sup></td>
        <td class="ta_right"><span v-if="currentData.floor_space === null">0</span>{{ currentData.floor_space }} m<sup>2</sup></td>
        <td class="ta_right">{{ calcCurrentTotal.toLocaleString() }} 円</td>
      </tr>
    </tbody>
  </table>
  <div class="no_entry" v-else>登録がありません。「新規追加」ボタンより登録をお願いします。</div>
  <template v-if="isShowUI">
  <div><a
    v-if="isNew"
    @click.prevent="newHandler"
    href="#form1"
    class="common_add_button modalOpen"
  >新規追加</a><a
    v-else
    @click.prevent="editHandler"
    href="#form1"
    class="common_add_button modalOpen"
  >変更</a>&nbsp;&nbsp;<a
    @click.prevent="deleteHandler"
    href="#form1"
    class="common_add_button gray_button"
  >クリア</a></div>
  </template>
  <Teleport to="body">
    <!-- モーダルコンテンツ / -->
    <div class="overlay" id="form1">
      <div id="modal_content" class="modal_contents w80">
        <div class="modalClose_ctn"><a @click.prevent="modalClose" class="modalClose"></a></div>
        <!-- modal contents ここから-->
        <div class="entry_title">
          <h4>小間内清掃</h4>



          <div class="entry_title">
            <h5>小間内清掃&nbsp;&nbsp;{{ uploadLabel }}</h5>
          </div>


          <div class="entry_modal_ctn">
            <table class="common_table">
              <tbody>
                <tr>
                  <th>種別</th>
                  <th>単価（消費税込）</th>
                  <th>小間面積</th><!--自動表示-->
                  <th>２階建施設床面積</th>
                  <th>料金（消費税込）</th>
                </tr>
                <tr>
                  <th>小間内清掃</th>
                  <td>{{ unitCost.toLocaleString() }}円/12日間・m<sup>2</sup></td>
                  <td>{{ decideArea.toLocaleString() }} m<sup>2</sup></td>
                  <td style="text-align:right"><input v-model="form.floor_space" id="floor_space" name="floor_space"
                      type="number" size="10" style="ime-mode: inactive; text-align: right;"
                      class="validate[required,custom[number_volume]]"
                      min="0"> m<sup>2</sup>
                    <p class="alert" v-if="errors.floor_space">{{ errors.floor_space }}</p>
                  </td>
                  <td style="text-align:right">{{ calcTotal }} 円</td>
                </tr>
              </tbody>
            </table>


            <div class="button_ctn">
              <ul>
                <li><button @click="modalClose" class="gray_button modalClose">キャンセル</button></li>
                <li><button @click="sendForm" class="regist_button">上記内容で登録</button></li>
              </ul>
            </div>
            <!-- modal contents ここまで-->
          </div>
        </div>
        <!-- / モーダルコンテンツ -->
      </div>
    </div>
    <!-- / モーダルコンテンツ -->
  </Teleport>
</template>

<script setup>
import { reactive, ref, computed } from 'vue'
import { forEach, cloneDeep } from 'lodash'

import APIClient from '@/store/repository/APIClient'
import * as yup from 'yup'
import moment from "moment"
import { FormValidate } from '@/helper/FormValidate'

import {
  modalOpen,
  modalClose,
} from '@/helper/modal'

import ApiConst from '@/const/api'

import { isShowable as isShowUI } from '@/helper/indexStatus'

const unitCost = 1320
const decideArea = ref(0)

const api = new APIClient()

const formInit = {
  application_id: null,
  floor_space: null,
}

const form = reactive(cloneDeep(formInit))
const currentData = reactive(cloneDeep(formInit))

const calcTotal = computed(() => {
  return (Number(decideArea.value) + Number(form.floor_space)) * Number(unitCost)
})

const calcCurrentTotal = computed(() => {
  return (Number(decideArea.value) + Number(currentData.floor_space)) * Number(unitCost)
})

const isNew = computed(() => {
  return !currentData.application_id
})

const uploadLabel = computed(() => {
  return !isNew.value ? '変更' : '新規登録'
})

const fetchEntryDetail = async () => {
  try {
    const res = await api.GetPreEntryDetail()
    const errors = res.data?.error_results
    if (errors) {
      throw new Error('Not found.')
    }
    const category_disp =  res.data.results.json_data.category_disp
    const decide_area = res.data.results.json_data.decide_area ?? 0
    if ( !(1 <= category_disp && category_disp <=11) && category_disp !== 18 ) {
      decideArea.value = decide_area * 9
    } else {
      decideArea.value = decide_area
    }
  } catch (e) {
    console.log(e)
    return false
  }
}
fetchEntryDetail()

const fetch = async () => {
  try {
    const query = {
      form_no: 1,
      application_div: "cleaning",
    }
    const fetchApi = await api.get(ApiConst.API_APP_LIST, query)
    const res = fetchApi.response
    const errors = res.data?.error_results
    if (errors) {
      throw new Error('Not found.')
    }
    forEach(currentData, (v, k) => {
      currentData[k] = formInit[k]
    })
    const data = res.data.results.list.map((el) => {
      return {
        application_id: el.application_id,
        input_date: moment(el.updated_at).format("YY-MM-DD"),
        seq_no: el.seq_no,
        ...el.json_data
      }
    })[0]
    forEach(currentData, (v, k) => {
      currentData[k] = data[k]
    })
    forEach(form, (v, k) => {
      form[k] = data[k] ?? formInit[k]
    })
  } catch (e) {
    console.log(e)
    return false
  }
}
fetch()

const newHandler = (event) => {
  forEach(formInit, (v, k) => {
    form[k] = v
  })
  modalOpen(event)
}

const editHandler = (event) => {
  modalOpen(event)
}

const deleteHandler = async () => {
  if (!window.confirm('クリアして宜しいでしょうか？')) return false

  try {
    const application_id = currentData.application_id
    const operation = "D"
    const query = { operation, application_id }
    const res = await api.post(ApiConst.API_APP_EDIT, query)
    if (res.response.data?.error_results) {
      throw new Error('API Validation Error')
    }
    fetch()
  } catch (e) {
    return false
  }
}

const scheme = {
  floor_space: yup.number().nullable().transform((value, originalValue) =>
    String(originalValue).trim() === '' ? null : value
  ).typeError('数値を入力してください。').min(0, '0以上の数値を入力してください。'),
}
const validate = new FormValidate(scheme, form)
const errors = validate.errors
const sendForm = async () => {
  try {
    if ((Object.keys(await validate.getErrorObject()).length)) {
      throw new Error('入力項目に不備があります。')
    }
    const params = cloneDeep(form)
    params.total_price = calcTotal.value
    params.decide_area = decideArea.value
    const application_id = params.application_id
    delete params.application_id
    const operation = application_id ? "R" : "I"
    const query = operation === "I"
      ? { operation, application_div: "cleaning", form_no: 1, json_data: params }
      : { operation, application_id, json_data: params }
    const res = await api.post(ApiConst.API_APP_EDIT, query)
    if (res.response.data?.error_results) {
      throw new Error('API Validation Error')
    }
    fetch()
    modalClose()
  } catch (e) {
    return false
  }
}
</script>

<style></style>
