export default Object.freeze({
  "DEFAULT_RATE": {
    "label": "10%",
    "value": 1.1,
  },
  "RATE": [
    {
      "label": "8%",
      "value": 1.08,
    },
    {
      "label": "10%",
      "value": 1.1,
    }
  ]
})
