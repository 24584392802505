<template>
  <header>

    <h1>JAPAN MOBILITY SHOW 2025 <br class="pc_none">出展手続きサイト・出展者専用サイト</h1>

  </header>
  <article id="p2">
    <PhaseHeader />
    <div id="form_ctn" class="form_box">
      <PhaseNav :current="1" />

      <!--
          <div class="pre_info">
            <h4 style="border-bottom: none;text-align: center;margin-top: 50px;">出展者ページには、まだ情報がありません。</h4>
            <div style="border:1px solid #ccc;padding: 30px;background-color: #fff;">
              <p>今後、出展者ニュースの他、会場の小間割図、展示規程図、天井吊規程図や、テーマロゴのデータなどをダウンロードいただける他、出展担当者情報の閲覧・変更が可能となります。</p>
              <p style="margin-bottom: 0;">また7月以降、出展の諸手続および各種申込のオンライン受付を開始する予定です。</p>
            </div>
          </div>
          -->

          <div class="demand_alert" v-if="billingErrorList.length > 0">
            <h4>以下の申込フォームは請求先が登録されておりません。<br>ご登録をお願いいたします。</h4>
            <ul class="common_ul">
              <template v-for="(v, k) in billingErrorList" :key="k">
              <li>
                <template v-if="!v.status">
                  {{ v.label }}
                </template>
                <router-link v-else :to="{name: v.route}">{{ v.label }}</router-link>
              </li>
              </template>
            </ul>
            <div class="demand_alert_info">
            <p class="demand_alert_info_title">なお、申込フォームの〆切等で新たに請求先の登録ができない場合は、 次の情報を 東京ビッグサイト事務局までご連絡ください。</p>
            <div class="clm2_ctn">
              <div class="w35p">
            <h5>【請求情報 】（すべて必須項目）</h5>
            <ul class="common_ul">
              <li>会社名</li>
              <li>会社名カナ</li>
              <li>所在地（郵便番号、住所）</li>
              <li>担当者（所属、氏名、電話番号、E-mail）</li>
              <li>請求言語（日本語 or 英語）</li>
            </ul>
              </div>
              <div class="w60p">
            <h5>【お問合せ先】</h5>
            <p>JAPAN MOBILITY SHOW 2025&nbsp;東京ビッグサイト事務局<br>（株式会社東京ビッグサイト）<br>
             tel：03-5530-1369<br>
             e-mail：<a href="mailto:tms@tokyo-bigsight.co.jp">tms@tokyo-bigsight.co.jp</a></p>
               
              <p>ご協力のほど何卒よろしくお願い申し上げます。</p>
              </div>
            </div>
          </div>
          </div>

      <div class="clm2_ctn">
        <div class="w60p">
          <h3>出展者ニュース</h3>
          <table class="common_table koma_chart">
            <tbody>
              <tr>
                <th>N37</th>
                <th>2025/11/06</th>
                <td><a href="/docs/2025/news/N37.pdf" target="_blank">来場者数の公式発表について</a><br>
                </td>
              </tr>
              <tr>
                <th>N36</th>
                <th>2025/11/02</th>
                <td><a href="/docs/2025/news/N36.pdf" target="_blank">特設宅配カウンターの設置について</a><br>
                </td>
              </tr>
              <tr>
                <th>N35</th>
                <th>2025/11/02</th>
                <td><a href="/docs/2025/news/N35.pdf" target="_blank">搬出作業に関する確認とお知らせ</a><br>
                  <a href="/docs/2025/news/N35_e.pdf" target="_blank">Notice Regarding Removal Work</a><br>
                </td>
              </tr>
              <tr>
                <th>N34</th>
                <th>2025/10/31</th>
                <td><a href="/docs/2025/news/N34.pdf" target="_blank">優先搬出車両ステッカー配布</a><br>
                  <a href="/docs/2025/news/N34_e.pdf" target="_blank">Distribution of The Sticker of Priority Removal Vehicle.pd</a><br>
                </td>
              </tr>
              <tr>
                <th>N33</th>
                <th>2025/10/24</th>
                <td><a href="/docs/2025/news/N33.pdf" target="_blank">プレスブリーフィング映像の提供に係る協力について</a><br>
                  <a href="/docs/2025/news/N33_e.pdf" target="_blank">Request for the provision of press briefing video</a><br>
                </td>
              </tr>
              <tr>
                <th>N32</th>
                <th>2025/10/23</th>
                <td><a href="/docs/2025/news/N32.pdf" target="_blank">記録写真（映像）の協力依頼</a><br>
                  <a href="/docs/2025/news/N32_e.pdf" target="_blank">Request for Cooperation regarding Official Records</a><br>
                </td>
              </tr>
              <tr>
                <th>N31</th>
                <th>2025/10/23</th>
                <td><a href="/docs/2025/news/N31.pdf" target="_blank">プレスデー音出し規制.</a><br>
                  <a href="/docs/2025/news/N31_e.pdf" target="_blank">Volume limitation during Press Briefings</a><br>
                </td>
              </tr>
              <tr>
                <th>N30</th>
                <th>2025/10/23</th>
                <td><a href="/docs/2025/news/N30.pdf" target="_blank">プレス証デザイン</a><br>
                  <a href="/docs/2025/news/N30_e.pdf" target="_blank">Volume limitation during Press Briefings</a><br>
                </td>
              </tr>
              <tr>
                <th>N29</th>
                <th>2025/10/21</th>
                <td><a href="/docs/2025/news/N29.pdf" target="_blank">公式行事の概要およびご高覧のリハーサルの実施について</a><br>
                  <a href="/docs/2025/news/N29_e.pdf" target="_blank">JAPAN MOBILITY SHOW 2025-Outline of Official Opening-Related Events</a><br>
                </td>
              </tr>
              <tr>
                <th>N28</th>
                <th>2025/10/19</th>
                <td><a href="/docs/2025/news/N28.pdf" target="_blank">請求金額の計算に関するご連絡</a>
                </td>
              </tr>
              <tr>
                <th>N27</th>
                <th>2025/10/19</th>
                <td><a href="/docs/2025/news/N27.pdf" target="_blank">消防検査実施に関するご連絡</a><br>
                  <a href="/docs/2025/news/N27_e.pdf" target="_blank">Notice of fire prevention inspection</a><br>
                </td>
              </tr>
              <tr>
                <th>N26</th>
                <th>2025/10/19</th>
                <td><a href="/docs/2025/news/N26.pdf" target="_blank">ホール事務局における出展者サービスについて</a><br>
                  <a href="/docs/2025/news/N26_e.pdf" target="_blank">Exhibitor Services at the Hall Secretariat Offices</a><br>
                </td>
              </tr>
              <tr>
                <th>N25</th>
                <th>2025/10/16</th>
                <td><a href="/docs/2025/news/N25.pdf" target="_blank">「取材の手引き」について</a><br>
                  添付：<a href="/docs/2025/pressinfo/PressInfo_J1010.pdf" target="_blank">取材の手引き(10月10日発行版)</a><br>
                  <a href="/docs/2025/news/N25_e.pdf" target="_blank">Press Information</a><br>
                </td>
              </tr>
              <tr>
                <th>N24</th>
                <th>2025/10/11</th>
                <td><a href="/docs/2025/news/N24.pdf" target="_blank">会場管理計画及び小間内の管理責任者、火元責任者の登録について</a><br>
                  添付：<a href="/docs/2025/news/N24-01.pdf" target="_blank">会場管理計画書(防災・防犯)</a><br>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<a href="/docs/2025/news/N24-02.xls" download="">小間内防災点検表.xls</a><br>
                  <a href="/docs/2025/news/N24_e.pdf" target="_blank">Venue Management Plan</a><br>
                  Attachment: <a href="/docs/2025/news/N24-01_e.pdf" target="_blank">Venue Management Plan(Emergency ResponseSecurity)</a><br>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<a href="/docs/2025/news/N24-02_e.xls" download="">Booth Fire Prevention Inspection Chart.xls</a><br>
                </td>
              </tr>
              <tr>
                <th>N23</th>
                <th>2025/10/11</th>
                <td><a href="/docs/2025/news/N23.pdf" target="_blank">出展者ニュース_交通対策</a><br>
                  <a href="/docs/2025/news/N23_e.pdf" target="_blank">Transportation to and from the show</a><br>
                </td>
              </tr>
              <tr>
                <th>N22</th>
                <th>2025/10/11</th>
                <td><a href="/docs/2025/news/N22.pdf" target="_blank">搬入搬出及び出展者の入場について</a><br>
                  添付：<a href="/docs/2025/news/N22-01.pdf" target="_blank">搬入搬出及び会期中の出展者出入口について </a><br>
                  <a href="/docs/2025/news/N22_e.pdf" target="_blank">Delivery-removal and exhibitor access</a><br>
                  Attachment: <a href="/docs/2025/news/N22-01_e.pdf" target="_blank">Entrance for Exhibitors during the delivery removal and the show period</a><br>
                </td>
              </tr>
              <tr>
                <th>N21</th>
                <th>2025/10/06</th>
                <td><a href="/docs/2025/news/N21.pdf" target="_blank">自動車ジャーナリストと巡るJMSの各社訴求ポイントについて</a><br>
                  添付：<a href="/docs/2025/news/N21-01.pdf" target="_blank">訴求ポイント、注意・禁止事項ヒアリングシート </a><br>
                  <a href="/docs/2025/news/N21_e.pdf" target="_blank">Tour of the JMS2025 Guided by an Automotive Journalist</a><br>
                  Attachment: <a href="/docs/2025/news/N21-01_e.pdf" target="_blank">Questionnaire of booth’s highlight</a><br>
                </td>
              </tr>
              <tr>
                <th>N20</th>
                <th>2025/09/22</th>
                <td><a href="/docs/2025/news/N20.pdf" target="_blank">展示ホールシャッターの開放について</a><br>
                  添付：<a href="/docs/2025/news/N20-01.pdf" target="_blank">展示ホールシャッター位置図 </a><br>
                  <a href="/docs/2025/news/N20_e.pdf" target="_blank">Opening of Exhibition Hall Shutters</a><br>
                  Attachment: <a href="/docs/2025/news/N20-01_e.pdf" target="_blank">Location Map of Exhibition Hall Shutters </a><br>
                </td>
              </tr>
              <tr>
                <th>N19</th>
                <th>2025/09/22</th>
                <td><a href="/docs/2025/news/N19.pdf?2" target="_blank">共同休憩室について</a><br>
                  <a href="/docs/2025/news/N19_e.pdf?2" target="_blank">Common Staff Rooms</a><br>
                </td>
              </tr>
              <tr>
                <th>N18</th>
                <th>2025/09/11</th>
                <td><a href="/docs/2025/news/N18.pdf?2" target="_blank">出展者向け駐車場の提供について</a><br>
                  <a href="/docs/2025/news/N18_e.pdf?2" target="_blank">Exhibitor Facilities and Installations</a><br>
                </td>
              </tr>
              <tr>
                <th>N17</th>
                <th>2025/09/11</th>
                <td><a href="/docs/2025/news/N17.pdf" target="_blank">プレスブリーフィングの実施について</a><br>
                  <a href="/docs/2025/news/N17_e.pdf" target="_blank">Press Briefing</a><br>
                </td>
              </tr>
              <tr>
                <th>N16</th>
                <th>2025/09/08</th>
                <td><a href="/docs/2025/news/N16.pdf" target="_blank">出展物リストに関する協力のお願い</a><br>
                  <a href="/docs/2025/news/N16_e.pdf" target="_blank">Request for List of Exhibit Items</a><br>
                </td>
              </tr>
              <tr>
                <th>N15</th>
                <th>2025/08/25</th>
                <td><a href="/docs/2025/news/N15.pdf" target="_blank">招待券の配布、ショー用品並びに出展者入門証のご案内</a><br>
                  <a href="/docs/2025/news/N15_e.pdf" target="_blank">Distribution of Invitation Tickets Show Merchandise
                    and Exhibitor Admission Cards</a><br>
                </td>
              </tr>
              <tr>
                <th>N14</th>
                <th>2025/08/25</th>
                <td><a href="/docs/2025/news/N14.pdf" target="_blank">Webによる諸手続き（続報②）及び操作マニュアルについて</a><br>
                  添付：<a href="/docs/2025/news/N14-01.pdf" target="_blank">Webによる諸手続きおよび各種申込マニュアル_manual</a><br>
                  <a href="/docs/2025/news/N14_e.pdf" target="_blank">Online Procedure and Applications(Subsequent2) and
                    its manual</a>
                </td>
              </tr>
              <tr>
                <th>N13</th>
                <th>2025/08/25</th>
                <td><a href="/docs/2025/news/N13.pdf" target="_blank">自動車ガイドブック第70巻（2025-2024）予約販売のご案内</a><br>
                  添付：<a href="/docs/2025/news/N13-01.pdf" target="_blank">自動車ガイドブック70巻発行概要 </a><br>
                  <a href="/docs/2025/news/N13_e.pdf" target="_blank">Japanese Motor Vehicles Guidebook Vol.70 (2025-2024)
                    Pre-order Sales Announcement</a><br>
                  Attachment: <a href="/docs/2025/news/N13-01_e.pdf" target="_blank">Outline of Japanese Motor Vehicles
                    Guidebook Vol.70</a>

                </td>
              </tr>

              <tr>
                <th>N12</th>
                <th>2025/08/24</th>
                <td><a href="/docs/2025/news/N12.pdf" target="_blank">出展者利用施設および会場設備について</a><br>
                  添付：<a href="/docs/2025/news/N12-01.pdf" target="_blank">JMS23-控室予定位置図 </a><br>

                  <a href="/docs/2025/news/N12_e.pdf" target="_blank">Exhibitor Facilities and Installations</a><br>
                  Attachment: <a href="/docs/2025/news/N12-01_e.pdf" target="_blank">JMS23-Location map of exhibitor’s
                    waiting rooms (planned)</a>
                </td>
              </tr>



              <tr>
                <th>N11</th>
                <th>2025/08/23</th>
                <td><a href="/docs/2025/news/N11.pdf" target="_blank">オフィシャルデー招待状のご案内</a>
                </td>
              </tr>
              <tr>
                <th>N10</th>
                <th>2025/08/21</th>
                <td><a href="/docs/2025/news/N10.pdf" target="_blank">オフィシャルトラベルエージェンシーサービス</a><br>
                  <a href="/docs/2025/news/N10_e.pdf" target="_blank">Official Travel Agency Service</a>
                </td>
              </tr>
              <tr>
                <th>N09</th>
                <th>2025/08/07</th>
                <td><a href="/docs/2025/news/N09.pdf" target="_blank">Webによる諸手続きおよび各種申込のご案内（続報）</a><br>
                  <a href="/docs/2025/news/N09_e.pdf" target="_blank">Information Regarding Online Procedure and
                    Applications(Subsequent)</a>
                </td>
              </tr>
              <tr>
                <th>N08</th>
                <th>2025/07/26</th>
                <td><a href="/docs/2025/news/N08.pdf" target="_blank">ロゴガイドライン</a><br>
                  <a href="/docs/2025/news/N08_e.pdf" target="_blank">Logo guideline</a>
                </td>
              </tr>
              <tr>
                <th>N07</th>
                <th>2025/07/20</th>
                <td><a href="/docs/2025/news/N07.pdf?20250720" target="_blank">施設天井照明の消灯について（東展示棟 1-6ホール）</a><br>
                  添付：<a href="/docs/2025/news/N07-01.pdf" target="_blank">施設天井照明位置図</a><br>

                  <a href="/docs/2025/news/N07_e.pdf" target="_blank">Turning Off Exhibition Facility's Ceiling Lights
                    (East Exhibition Hall 1-6)</a><br>
                  Attachment: <a href="/docs/2025/news/N07-01_e.pdf" target="_blank">Position Diagram of the Exhibition
                    Facility's Ceiling Light</a>
                </td>
              </tr>
              <tr>
                <th>N06</th>
                <th>2025/07/10</th>
                <td><a href="/docs/2025/news/N06.pdf" target="_blank">消防上の規定について</a><br>

                  <a href="/docs/2025/news/N06_e.pdf" target="_blank">Fire Regulations</a>
                </td>
              </tr>
              <tr>
                <th>N05</th>
                <th>2025/07/10</th>
                <td><a href="/docs/2025/news/N05.pdf" target="_blank">天井吊下げ工事に伴う施工上の注意について</a><br>

                  <a href="/docs/2025/news/N05_e.pdf" target="_blank">Information on Hanging Structures</a>
                </td>
              </tr>
              <tr>
                <th>N04</th>
                <th>2025/07/10</th>
                <td><a href="/docs/2025/news/N04.pdf" target="_blank">Webによる諸手続きおよび各種申込のご案内</a><br>

                  <a href="/docs/2025/news/N04_e.pdf" target="_blank">Information Regarding Online Procedure and
                    Applications</a>
                </td>
              </tr>
              <tr>
                <th>N03</th>
                <th>2025/07/04</th>
                <td><a href="/docs/2025/news/N03.pdf" target="_blank">プレスデーの運営について</a><br>
                  添付：<a href="/docs/2025/news/N03-01.docx" download="N03-01.docx">プレスブリーフィング実施希望回答書</a><br>
                  <a href="/docs/2025/news/N03_e.pdf" target="_blank">Press Days</a><br>
                  Attachment:<a href="/docs/2025/news/N03-01_e.docx" download="N03-01_e.docx">Indication of Intention to
                    Hold Press Briefing</a>
                </td>
              </tr>
              <tr>
                <th>N02</th>
                <th>2025/06/30</th>
                <td><a href="/docs/2025/news/N02.pdf" target="_blank">各社の報道発表について</a><br>
                  <a href="/docs/2025/news/N02_e.pdf" target="_blank">Press Announcements by Exhibitors</a>
                </td>
              </tr>
              <tr>
                <th>N01</th>
                <th>2025/05/19</th>
                <td><a href="/docs/2025/news/N01.pdf" target="_blank">JAPAN MOBILITY SHOW 2025
                    出展者ページ開設について</a><br><a href="/docs/2025/news/N01_e.pdf" target="_blank">Japan Mobility Show 2025
                    Opening of “Exhibitors Page”</a></td>
              </tr>


            </tbody>
          </table>
        </div>
        <div class="w35p">
          <h3>小間割図</h3>
          <table class="common_table koma_chart">
            <tbody>
              <tr>
                <th colspan="4">ホール</th>
              </tr>
              <tr>
                <th>図面種別</th>
                <th colspan="2">言語</th>
                <th>更新日</th>
              </tr>

              <tr>
                <td colspan="4" class="cl1">会場計画図</td>
              </tr>
              <tr>
                <td>&nbsp;</td>
                <td><a href="/docs/2025/data/001_JMS23-MAP-J-230810.pdf" target="_blank">日本語版</a></td>
                <td><a href="/docs/2025/data/001_JMS23-MAP-E-230810.pdf" target="_blank">英語版</a></td>
                <td>2025/08/10</td>
              </tr>
              <!--
                  <tr>
                      <td colspan="4" class="cl1">西1,2ホール</td>
                    </tr>
                    <tr>
                      <td>小間割図</td>
                    <td><a href="/docs/2025/data/002_46TMS-W-12hall-P-J-0905.pdf" target="_blank">日本語版</a></td>
                    <td><a href="/docs/2025/data/002_46TMS-W-12hall-P-E-0905.pdf" target="_blank">英語版</a></td>
                    <td>2019/09/05</td>
                  </tr>
                
                  <tr>
                    <td>展示規程図</td>
                    <td><a href="/docs/2025/data/007_46TMS-W-12hall-R-J-0905.pdf" target="_blank">日本語版</a></td>
                    <td><a href="/docs/2025/data/007_46TMS-W-12hall-R-E-0905.pdf" target="_blank">英語版</a></td>
                    <td>2019/09/05</td>
                    </tr>
                    <tr>
                      <td>天井吊規程図</td>
                    <td><a href="/docs/2025/data/012_46TMS-W-12hall-C-J-0905.pdf" target="_blank">日本語版</a></td>
                    <td><a href="/docs/2025/data/012_46TMS-W-12hall-C-E-0905.pdf" target="_blank">英語版</a></td>
                    <td>2019/09/05</td>
                  </tr>
                -->
              <tr>
                <td colspan="4" class="cl1">西3,4ホール</td>
              </tr>
              <tr>
                <td>小間割図</td>
                <td><a href="/docs/2025/data/005_JMS23-W-34hall-P-J-230810.pdf" target="_blank">日本語版</a></td>
                <td><a href="/docs/2025/data/005_JMS23-W-34hall-P-E-230810.pdf" target="_blank">英語版</a></td>
                <td>2025/08/10</td>
              </tr>
              <tr>
                <td>展示規程図</td>
                <td><a href="/docs/2025/data/009_JMS23-W-34hall-R-J-230616.pdf" target="_blank">日本語版</a></td>
                <td><a href="/docs/2025/data/009_JMS23-W-34hall-R-E-230616.pdf" target="_blank">英語版</a></td>
                <td>2025/06/16</td>
              </tr>

              <tr>
                <td colspan="4" class="cl1">東1･2･3 ホール</td>
              </tr>
              <tr>
                <td>小間割図</td>
                <td><a href="/docs/2025/data/002_JMS23-E-123hall-P-J-230619.pdf" target="_blank">日本語版</a></td>
                <td><a href="/docs/2025/data/002_JMS23-E-123hall-P-E-230619.pdf" target="_blank">英語版</a></td>
                <td>2025/06/19</td>
              </tr>
              <tr>
                <td>展示規程図</td>
                <td><a href="/docs/2025/data/006_JMS23-E-123hall-R-J-230619.pdf" target="_blank">日本語版</a></td>
                <td><a href="/docs/2025/data/006_JMS23-E-123hall-R-E-230619.pdf" target="_blank">英語版</a></td>
                <td>2025/06/19</td>
              </tr>
              <tr>
                <td>天井吊規程図</td>
                <td><a href="/docs/2025/data/010_JMS23-E-123hall-SI-J-230619.pdf" target="_blank">日本語版</a></td>
                <td><a href="/docs/2025/data/010_JMS23-E-123hall-SI-E-230619.pdf" target="_blank">英語版</a></td>
                <td>2025/06/19</td>
              </tr>
              <tr>
                <td colspan="4" class="cl1">東4・5・6 ホール</td>
              </tr>
              <tr>
                <td>小間割図</td>
                <td><a href="/docs/2025/data/003_JMS23-E-456hall-P-J-230630.pdf" target="_blank">日本語版</a></td>
                <td><a href="/docs/2025/data/003_JMS23-E-456hall-P-E-230630.pdf" target="_blank">英語版</a></td>
                <td>2025/06/30</td>
              </tr>
              <tr>
                <td>展示規程図</td>
                <td><a href="/docs/2025/data/007_JMS23-E-456hall-R-J-230630.pdf" target="_blank">日本語版</a></td>
                <td><a href="/docs/2025/data/007_JMS23-E-456hall-R-E-230630.pdf" target="_blank">英語版</a></td>
                <td>2025/06/30</td>
              </tr>
              <tr>
                <td>天井吊規程図</td>
                <td><a href="/docs/2025/data/011_JMS23-E-456hall-SI-J-230630.pdf" target="_blank">日本語版</a></td>
                <td><a href="/docs/2025/data/011_JMS23-E-456hall-SI-E-230630.pdf" target="_blank">英語版</a></td>
                <td>2025/06/30</td>
              </tr>
              <tr>
                <td colspan="4" class="cl1">東1-6ホール</td>
              </tr>

              <tr>
                <td>天井吊下げ位置<br>詳細図</td>
                <td><a href="/docs/2025/data/012_JMS23-Ehall_TRUSS-J-230519.pdf" target="_blank">日本語版</a></td>
                <td><a href="/docs/2025/data/012_JMS23-Ehall_TRUSS-E-230519.pdf" target="_blank">英語版</a></td>
                <td>2025/05/19</td>
              </tr>
              <tr>
                <td colspan="4" class="cl1">東7,8 ホール</td>
              </tr>
              <tr>
                <td>小間割図</td>
                <td><a href="/docs/2025/data/004_JMS23-E-78hall-P-J-230914.pdf" target="_blank">日本語版</a></td>
                <td><a href="/docs/2025/data/004_JMS23-E-78hall-P-E-230914.pdf" target="_blank">英語版</a></td>
                <td>2025/09/14</td>
              </tr>
              <tr>
                <td>展示規程図</td>
                <td><a href="/docs/2025/data/008_JMS23-E-78hall-R-J-230914.pdf" target="_blank">日本語版</a></td>
                <td><a href="/docs/2025/data/008_JMS23-E-78hall-R-E-230914.pdf" target="_blank">英語版</a></td>
                <td>2025/09/14</td>
              </tr>
              <tr>
                <td colspan="4" class="cl1">南3,4ホール</td>
              </tr>
              <tr>
                <td>小間割図</td>
                <td><a href="/docs/2025/data/013_JMS23-S-34hall-P-J-230914.pdf" target="_blank">日本語版</a></td>
                <td><!--<a href="/docs/2025/data/013_JMS23-S-34hall-P-J-230914.pdf" target="_blank">英語版</a>--></td>
                <td>2025/09/14</td>
              </tr>
              <tr>
                <td>展示規程図</td>
                <td><a href="/docs/2025/data/014_JMS23-S-34hall-R-J-230914.pdf" target="_blank">日本語版</a></td>
                <td><!--<a href="/docs/2025/data/009_JMS23-W-34hall-R-E-230616.pdf" target="_blank">英語版</a>--></td>
                <td>2025/09/14</td>
              </tr>

            </tbody>
          </table>
          <h3>各種データ</h3>
          <table class="common_table koma_chart">
            <tbody>
              <tr>
                <th rowspan="2">規程</th>
                <td class="cl1">日本語版</td>
                <td><a href="/docs/2025/regulation/regulation.pdf" target="_blank">PDF</a></td>
              </tr>
              <tr>
                <td class="cl1">英語版</td>
                <td><a href="/docs/2025/regulation/regulation_en.pdf" target="_blank">PDF</a></td>
              </tr>

              <tr>
                <th>ロゴガイドライン</th>
                <td colspan="2"><a href="/docs/2025/logo/JapanMobilityShow2025_manual_vol1_0724.pdf"
                    target="_blank">PDF</a><br>※Illustratorで編集いただけます。</td>
              </tr>
              <tr>
                <th rowspan="2">Premiere ロゴ</th>
                <td class="cl1">日本語版</td>
                <td><a href="/docs/2025/logo/premiereLOGO-J.pdf" target="_blank">PDF</a><br>
                  <a href="/docs/2025/logo/premiereLOGO-J.zip">ZIP : Adobe Illustrator</a>
                </td>
              </tr>
              <tr>
                <td class="cl1">英語版</td>
                <td><a href="/docs/2025/logo/premiereLOGO-E.pdf" target="_blank">PDF</a><br>
                  <a href="/docs/2025/logo/premiereLOGO-E.zip">ZIP : Adobe Illustrator</a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <h3>ホテル予約</h3>
      <p>株式会社JTBは、JAPAN MOBILITY SHOW 2025のオフィシャルトラベルエージェンシーとして、出展関係者の皆様に、会場周辺のホテルの宿泊手配サービスを提供いたします。ご希望の方は以下のJAPAN MOBILITY SHOW 2025のオフィシャルトラベルエージェンシーの窓口にご連絡ください。</p>
      <!--
      <p>
        ・オンライン予約は<a href="https://amarys-jtb.jp/japanmobilityshow2025/" target="_blank">こちら</a>からお申し込みいただけます。<br>
        ・詳しい予約方法は<a href="/docs/2025/other/hotel_reservation.pdf" target="_blank">こちら</a>をご覧ください。</p>
        -->
      <p>その他、以下のサービス提供いたしますので、ぜひご利用ください。<br>
        ・JAPAN MOBILITY SHOW 2025に係る前売り団体入場券（20名以上の団体に限る） <br>
・上記前売り団体入場券ホテルをセットにした宿泊プラン（20名以上の団体に限る） <br>
・上記以外の旅行サービスに関するご相談 
 </p>

      <div class="contact_ctn">
        <h4>＜お問い合わせ＞</h4>
        <p>JAPAN MOBILITY SHOW 2025オフィシャルトラベルエージェンシー<br>
          株式会社JTB&emsp;ビジネスソリューション事業本部&emsp;第三事業部<br>
          〒163-0426&emsp;東京都新宿区西新宿2-1-1&emsp;新宿三井ビルディング26F<br>
          電話：03-5909-8061&emsp;FAX：03-5909-8069<br>
          E-mail&emsp;<a href="mailto:jms2025@jtb.com">jms2025@jtb.com</a><br>
          担当：斉藤、篠崎、中村、広瀬<br>
          官公庁長官登録旅行業第64号</p>
      </div>

      <h3>Hotel Reservations</h3>
      <p>JTB Corp. is the Official Travel Agency of JAPAN MOBILITY SHOW 2025 and will be pleased to arrange accommodations in the vicinity of the venue for exhibitors. Should you have any requests in regards to arrange, please do not hesitate to contact the official travel agency of JAPAN MOBILITY SHOW 2025 the below .</p>
      <!--
      <p>
        For online hotel reservations, please click <a href="https://amarys-jtb.jp/japanmobilityshow2025/"
          target="_blank">here</a>.<br>
        For instructions on how to book, please click <a href="/docs/2025/other/hotel_reservation_en.pdf"
          target="_blank">here</a>.</p>
        -->
      <p>The following services will also be available. If you are interested in them, please contact the below.<br>
        ・Japan Mobility Show ticket and hotel packages（Groups of 20 or more only）<br>
・Other travel consultations and advice, etc.</p>

      <div class="contact_ctn">
        <h4>Contact:</h4>
        <p>Japan Mobility Show 2025 Official Travel Agency<br>
          JTB Corp. Branch 3<br>
          Japan Mobility Show 2025 Desk<br>
          26F Shinjuku Mitsui Building, <br>
          2-1-1, Nishi-Shinjuku, Shinjuku-ku, Tokyo <br>
          E-mail:<a href="mailto:jms2025@jtb.com">jms2025@jtb.com</a><br>
          Commissioner of Japan Tourism Travel Agency No. 64<br>
        </p>
      </div>

      <h3>申込書は受領済みです</h3>
      <p>申込書の再提出をご希望の場合は、下記までご連絡ください。</p>
      <div class="contact_ctn">
        <h4>出展に関するお問い合わせ・提出先</h4>
        <p>一般社団法人&emsp;日本自動車工業会<br>
          次世代モビリティ領域・ショー担当<br>
          TEL：03(5405)6127<br>
          <a href="https://www.japan-mobility-show.com/app/contact/" target="_blank">https://www.japan-mobility-show.com/app/contact/</a>
        </p>
      </div>




    </div>
  </article>
</template>

<script>
import PhaseHeader from '@/components/PhaseHeader'
import PhaseNav from '@/components/PhaseNav'
import APIClient from '@/store/repository/APIClient'
import moment from "moment"

import { ref, computed } from "vue"
import { unsetBillingList } from '@/helper/bill'
import { getIndexStatus, getIndexByRouterName } from '@/helper/indexStatus'

export default {
  components: {
    PhaseHeader,
    PhaseNav,
  },
  setup() {
    const statusList = ref({})
    ;(async () => {
      statusList.value = await getIndexStatus()
    })()
    const displayStatus = (route) => {
      try {
        const index = getIndexByRouterName(route)
        const date_from = statusList.value[index].date_from
        const date_to = statusList.value[index].date_to
        const from = date_from ? moment(date_from) : false
        const to = date_to ? moment(date_to).add(1, 'd') : false
        const now = moment()
        if ( now < from ) return false
        if ( !to ) return true
        if ( from < now && now < to ) return true
        if ( to < now ) return false
        return false
      } catch (e) {
        return false
      }
    }
    const billingErrorList = computed(() => {
      return unsetBillingList.value.reduce((acc, cur) => {
        if (!cur) return acc
        cur.status = displayStatus(cur.route)
        acc.push(cur)
        return acc
      }, [])
    })
    return {
      billingErrorList,
    };
  },
  async created() {
    this.api = new APIClient()
    this.inputStoreData('exhibitor_name')
    this.inputStoreData('koma_bango')
  },

  methods: {
    inputStoreData(key) {
      if (!this.$store.state.entrydata[key]) return;
      this[key] = this.$store.state.entrydata[key]
    },
  },

}

</script>

<style></style>
