<template>
  <header><h1>JAPAN MOBILITY SHOW 2025&emsp;出展手続きサイト・出展者専用サイト</h1></header>
  <article><h2>パスワードの設定</h2>
  <div id="form_ctn" class="form_box">
    <h3>パスワード設定用のメールを送信いたしました。</h3>
          <p>メール記載の方法でパスワードを登録してください。</p>

    

  </div>
</article>
</template>

<script>


</script>

<style>
</style>
