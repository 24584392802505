export default Object.freeze({
  DATES: [
    "10月18日(水)",
    "10月19日(木)",
    "10月20日(金)",
    "10月21日(土)",
    "10月22日(日)",
    "10月23日(月)",
    "10月24日(火)",
    "11月5日(日)",
    "11月6日(月)",
    "11月7日(火)",
    "11月8日(水)",
  ],
  DATES_META: [
    {date: "11月5日(日)", value: '20時〜', timeframe: ['午後']}
  ],
  BOOTH_DATES: [
    "10月25日 (水)",
    "10月26日 (木)",
    "10月27日 (金)",
    "10月28日 (土)",
    "10月29日 (日)",
    "10月30日 (月)",
    "10月31日 (火)",
    "11月1日	(水)",
    "11月2日	(木)",
    "11月3日	(金)",
    "11月4日	(土)",
    "11月5日	(日)",
  ],
  VEHICLES: [
    "積載車・トレーラー",
    "大型車",
    "中型車",
  ],
  METHODS: [
    "ラフタークレーン",
    "ユニック",
    "フォーク",
    "自走",
  ]
})
