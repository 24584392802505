<template>
  <RecursiveChecks
    @update:modelValue="updateValue"
    @updateTree="getBranch"
    :options="mergedOptions"
    :name="name"
  />
</template>

<script>
import RecursiveChecks from './RecursiveChecks'
import { isValue } from '@/helper/index';

export default {
  components: {
    RecursiveChecks
  },
  props: {
    modelValue: { type: String, required: true },
    options: { type: Array, required: true },
    name: { type: String, required: true },
    storeKey: { type: String, required: false },
  },
  data() {
    return {
      tree: [],
    };
  },
  methods: {
    nullifyDescendant: function(e, item) {
      // checkbox off の時、配下のradio checkboxをoffに
      if (e.target.type === 'checkbox') {
        if (!e.target.checked) {
          if (item.name === `${e.target.name}_${e.target.value}`) {
            item.checked = false
          }
        }
      }
      // radio 切替時、兄弟要素の配下のradio checkboxをoffに
      if (e.target.type === 'radio') {
        const regex = new RegExp(`${e.target.name}_.+`)
        if (regex.test(item.name)) {
          item.checked = false
        }
      }
    },
    updateValue: function (e) {
      const list = {}
      this.tree.forEach(items => {
        items.forEach(item => {
          this.nullifyDescendant(e, item)
          if (item.checked) {
            if (list[item.name]) {
              list[item.name].push(item.value)
            } else {
              list[item.name] = [item.value]
            }
          }
        })
      })
      this.$emit("update:modelValue", list);
    },
    listMapping: function (item, store, name) {
      const attr = store[name]
      if ( !isValue(attr) ) return false
      if ( attr.includes(item.value) ) {
        item.checked = true
      }
      const options = item.options
      if ( typeof options === 'undefined' ) return false
      options.forEach((it) => {
        this.listMapping(it, store, name+'_'+item.value)
      })
    },
    getBranch: function (v) {
      if (v.length <= 0) return
      this.tree.push(v)
    }
  },
  computed: {
    mergedOptions() {
      let _store = this.$store.state.form.category_div
      if (this.storeKey) {
        _store = this.$store.state[this.storeKey].category_div
      }
      const store = _store
      const list = this.options
      if ( store ) {
        list.forEach((item) => {
          this.listMapping(item, store, this.name)
        })
      }

      return list
    },
  },
};
</script>
