export default Object.freeze({
  "EXHIBITOR_ID": [
    { key : "トヨタ", label : "トヨタ自動車株式会社", value: 1151 },
    { key : "レクサス", label : "トヨタ自動車株式会社／Lexus　International", value: 1164 },
    { key : "ダイハツ", label : "ダイハツ工業株式会社", value: 1154 },
    { key : "スバル", label : "株式会社SUBARU", value: 1095 },
    { key : "日産", label : "日産自動車株式会社", value: 1165 },
    { key : "三菱", label : "三菱自動車工業株式会社", value: 1045 },
    { key : "マツダ", label : "マツダ株式会社", value: 1152 },
    { key : "ホンダ", label : "本田技研工業株式会社", value: 1047 },
    { key : "スズキ（4W）", label : "スズキ株式会社", value: 1156 },
    { key : "ヤマハ", label : "ヤマハ発動機株式会社", value: 1027 },
    { key : "カワサキ", label : "カワサキモータース株式会社", value: 1072 },
    { key : "UDトラックス", label : "UDトラックス株式会社", value: 1161 },
    { key : "いすゞ", label : "いすゞ自動車株式会社", value: 1158 },
    { key : "日野", label : "日野自動車株式会社", value: 1153 },
    { key : "三菱ふそう", label : "三菱ふそうトラック・バス株式会社", value: 1172 },
    { key : "ソニーホンダ", label : "ソニー・ホンダモビリティ株式会社", value: 1155 },
    { key : "BNW", label : "ビー・エム・ダブリュー株式会社", value: 1046 },
    { key : "BYD", label : "BYD Auto Japan株式会社", value: 1025 },
    { key : "メルセデス", label : "メルセデス・ベンツ日本株式会社", value: 1176 },
    { key : "トヨタ車体", label : "トヨタ車体株式会社", value: 1079 },
  ]
})
