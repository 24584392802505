<template>
  <table class="common_table" v-if="list.length">
    <tbody>
      <tr>
        <th class="w3em">番号 </th>
        <th>JP/WP</th>
        <th class="w15em">出展物名</th>
        <th class="w4em">数量 </th>
        <th class="w10em">公開 <br>
        日時</th>
        <th colspan="2" v-if="isShowUI">順番</th>
        <th colspan="2">編集</th>
      </tr>

      <tr v-for="(val, key) in list" :key="key">
        <td class="ta_right ">{{ key + 1 }}</td>
        <td>{{ val.category_premiere ? val.category_premiere : '指定無し' }}</td>
        <td>{{ val.name }}</td>
        <td class="ta_right">{{ val.quantity }}</td>
        <td>{{ val.embargo ? val.embargo : '指定無し' }}</td>
        <td class="edit_cell" v-if="isShowUI"><a @click.prevent="sortUp(val, fetch)" href="#form1"
            class="common_button_t2 modalOpen" v-if="showOrderPrev(key)">▲</a></td>
        <td class="edit_cell" v-if="isShowUI"><a @click.prevent="sortDown(val, fetch)" href="#form1"
            class="common_button_t2 modalOpen" v-if="showOrderNext(key, list.length - 1)">▼</a></td>
        <td class="edit_cell">
          <a @click.prevent="editHandler($event, val.application_id)" href="#form1"
            class="common_button_t2 modalOpen">変更</a>
        </td>
        <td class="edit_cell" v-if="isShowUI">
          <a @click.prevent="deleteHandler($event, val.application_id)"
            class="common_button_t2 gray_button delete_button">削除</a>
        </td>
      </tr>
    </tbody>
  </table>
  <div class="no_entry" v-else>登録がありません。「新規追加」ボタンより登録をお願いします。</div>
  <template v-if="isShowUI">
  <div>
    <a @click.prevent="newHandler" href="#form1" class="common_add_button modalOpen">新規追加</a>
  </div>
  </template>

  <Teleport to="body">

    <!-- モーダルコンテンツ / -->
    <!-- 製作注意問い合わせ先は何種類かあります。読み込み形式で簡単に編集できるようにしたほうが良いかと思います。-->
    <div class="overlay" id="form1">
      <div id="modal_content" class="modal_contents w80">
        <!-- modal contents ここから-->
        <div class="modalClose_ctn">
          <a @click.prevent="modalClose" class="modalClose"></a>
        </div>
        <div class="entry_title">
          <h4>出展物リスト</h4>
          <h5>出展物&nbsp;&nbsp;{{ uploadLabel }}</h5>
        </div>
        <div class="entry_modal_ctn">
          <ul class="common_ul">
            <li>ワールドプレミア(WP)、ジャパンプレミア(JP)の出展物については新機能/特徴を具体的に記入してください。</li>
            <li>英語（日本語）に入力がない場合は、英語版（日本語版）のJAPAN MOBILITY SHOW 2025 サイトに掲載されません。</li>
            <li>ワールドプレミア(WP)、ジャパンプレミア(JP)の詳細情報の公開はご指示いただいた指定日に公開することも可能です。</li>
          </ul>

          <table class="common_table">
            <tbody>
              <tr>
                <th>出展物名（日本語）</th>
                <td><input type="text" class="full" v-model="form.name">
                  <p class="alert" v-if="errors.name">{{ errors.name }}</p></td>
              </tr>
              <tr>
                <th>出展物名（英語）</th>
                <td><input type="text" class="full" v-model="form.name_en">
                  <p class="alert" v-if="errors.name_en">{{ errors.name_en }}</p></td>
              </tr>
              <tr>
                <th>数量</th>
                <td><input type="number" class="w5em" v-model="form.quantity">
                  <p class="alert" v-if="errors.quantity">{{ errors.quantity }}</p></td>
              </tr>
              <tr>
                <th>展示カテゴリー</th>
                <td>
                  <div class="select_ctn">
                    <select id="category_div" name="category_div" class="validate[required]" v-model="form.exhibition_category">
                      <option
                        value="車両"
                        :selected="form.exhibition_category === '車両'"
                      >車両</option>
                      <option
                        value="部品"
                        :selected="form.exhibition_category === '部品'"
                      >部品</option>
                      <option
                        value="コンセプトカー"
                        :selected="form.exhibition_category === 'コンセプトカー'"
                      >コンセプトカー</option>
                      <option
                        value="その他"
                        :selected="form.exhibition_category === 'その他'"
                      >その他</option>
                    </select>
                  </div>
                  <p class="alert" v-if="errors.exhibition_category">{{ errors.exhibition_category }}</p>
                  <p>"その他"を選択した場合、下記に内容を入力してください。</p>
                  <input type="text" class="full"
                  v-model="form.exhibition_category_other"
                  :disabled="form.exhibition_category !== 'その他'"
                  >
                  <p class="alert" v-if="errors.exhibition_category_other">{{ errors.exhibition_category_other }}</p>
                </td>
              </tr>
              <tr>
                <th>出展区分カテゴリー</th>
                <td>
                  <ul class="common_list_vertical_narrow">
                    <li><label><input type="radio" name="category_exhibit"
                      v-model="form.category_exhibit"
                      value="参考出展"
                      :checked="form.category_exhibit === '参考出展'"
                    ><span class="radio_text">参考出展</span></label></li>
                    <li><label><input type="radio" name="category_exhibit"
                      v-model="form.category_exhibit"
                      value="市販車(品)"
                      :checked="form.category_exhibit === '市販車(品)'"
                    ><span class="radio_text">市販車(品)</span></label>
                    </li>
                    <li><label><input type="radio" name="category_exhibit"
                      v-model="form.category_exhibit"
                      value="その他"
                      :checked="form.category_exhibit === 'その他'"
                    ><span class="radio_text">その他</span></label><input type="text" class="w15em"
                      v-model="form.category_exhibit_other"
                      :disabled="form.category_exhibit !== 'その他'"
                    ></li>
                  </ul>
                  <p class="alert" v-if="errors.category_exhibit">{{ errors.category_exhibit }}</p>
                  <p class="alert" v-if="errors.category_exhibit_other">{{ errors.category_exhibit_other }}</p>
                </td>
              </tr>
              <tr>
                <th>プレミアカテゴリー</th>
                <td>
                  <ul class="common_list_vertical_narrow">
                    <li><label><input type="radio" name="category_premiere"
                      v-model="form.category_premiere"
                      value="WP"
                      :checked="form.category_premiere === 'WP'"
                    ><span class="radio_text">ワールドプレミア(WP)</span></label></li>
                    <li><label><input type="radio" name="category_premiere"
                      v-model="form.category_premiere"
                      value="JP"
                      :checked="form.category_premiere === 'JP'"
                    ><span class="radio_text">ジャパンプレミア(JP)</span></label></li>
                    <li><label><input type="radio" name="category_premiere"
                      v-model="form.category_premiere"
                      value=""
                      :checked="form.category_premiere === ''"
                    ><span class="radio_text">どちらでもない</span></label></li>
                  </ul>
                  <p class="alert" v-if="errors.category_premiere">{{ errors.category_premiere }}</p>

                  <h6><strong>ワールドプレミア（WP）の定義「世界初の発表となる出展物」</strong></h6>
                  <p style="margin-left: 20px;">＝JAPAN MOBILITY SHOW 2025で、世界に初めて披露される出展物</p>
                  <h6><strong>ジャパンプレミア（JP）の定義「日本初の発表となる出展物」</strong></h6>
                  <p style="margin-left: 20px;">＝既に海外のモーターショーなどで披露されているが、日本国内ではJAPAN MOBILITY SHOW 2025において初めて披露される出展物</p>
                  <p>なお、ワールドプレミア/ジャパンプレミアの判断については、出展者様にお任せしております。</p>
                </td>
              </tr>
              <tr>
                <th>公開日時</th>
                <td>
                  <div class="select_ctn">
                    <select id="embargo" name="embargo" v-model="form.embargo">
                      <option value="">指定無し</option>
                      <option
                        value="10/25 PM〜"
                        :selected="form.embargo === '10/25 PM〜'"
                      >10/25 PM〜</option>
                      <option
                        value="10/26 AM〜"
                        :selected="form.embargo === '10/26 AM〜'"
                      >10/26 AM〜</option>
                      <option
                        value="10/26 PM〜"
                        :selected="form.embargo === '10/26 PM〜'"
                      >10/26 PM〜</option>
                    </select>
                  </div>
                  <p class="alert" v-if="errors.embargo">{{ errors.embargo }}</p>
                  <p>指定無しの場合はプレスデー初日10/25(水)午前8:00〜サイトに公開されます。</p>
                </td>
              </tr>
              <tr>
                <th>画像</th>
                <td>
                  <p>JAPAN MOBILITY SHOW 2025 オフィシャルサイトに掲載する画像の提供をお願いします。とくにWP、JPにつきましては、積極的にご協力いただけますよう、お願いします。ファイル形式はjpgもしくはpngで1600

                    x 900ピクセル以上、16:9か4:3の横長画像でご提供ください。画像ファイルの容量は4MB以内としてください。</p>

                  <input
                  type="file"
                  id="upload_file"
                  name="upload_file"
                  v-show="!form.file"
                  @change="loadFile($event, (v) => {
                    form.file = v
                  })"
                  >
                  <div class="w10em" v-if="form.file">
                    <img :src="form.file">
                    <button @click="cancelFile">ファイルを削除</button>
                  </div>
                  <p class="alert" v-if="errors.file">{{ errors.file }}</p>
                </td>
              </tr>

              <tr v-if="form.exhibition_category === '車両'">
                <th>エネルギーソース<br>
                  （原動力）
                </th>
                <td>
                  <ul class="common_list_vertical_narrow">
                    <li v-for="(val, key) in [
                        {value: 'ICE', list: [
                          'ガソリン',
                          'ディーゼル',
                          '合成燃料（対応）：CO2とH2を合成して製造される燃料',
                          'バイオ燃料（対応）：再生可能な生物資源を原料にした代替燃料',
                          '水素',
                          'CNG：圧縮天然ガス',
                          'LPG：液化石油ガス',
                          'CBG：圧縮バイオメタンガス',
                        ]},
                        {value: 'HEV', list: [
                          'ガソリン',
                          'ディーゼル',
                          '合成燃料（対応）：CO2とH2を合成して製造される燃料',
                          'バイオ燃料（対応）：再生可能な生物資源を原料にした代替燃料',
                          '水素',
                          'CNG：圧縮天然ガス',
                          'LPG：液化石油ガス',
                          'CBG：圧縮バイオメタンガス',
                        ]},
                        {value: 'PHEV', list: [
                          'ガソリン',
                          'ディーゼル',
                          '合成燃料（対応）：CO2とH2を合成して製造される燃料',
                          'バイオ燃料（対応）：再生可能な生物資源を原料にした代替燃料',
                          '水素',
                          'CNG：圧縮天然ガス',
                          'LPG：液化石油ガス',
                          'CBG：圧縮バイオメタンガス',
                        ]},
                        {value: 'BEV'},
                        {value: 'FCEV'},
                        {value: 'その他'},
                      ]" :key="key"><label><input type="radio" name="energy_source1"
                      v-model="form.energy_source1"
                      :value="val.value"
                      :checked="form.energy_source1 === val.value"
                      @change="changeEnergySource"
                    ><span class="radio_text">{{ val.value }}</span></label>
                      <ul class="common_list_vertical_narrow " style="margin-left: 5em;" v-if="form.energy_source1 === val.value">
                        <li v-for="(v, k) in val.list" :key="k"><label><input type="radio" name="energy_source2"
                          v-model="form.energy_source2"
                          :value="v"
                          :checked="form.energy_source2 === v"
                          :disabled="form.energy_source1 !== val.value"
                        ><span class="radio_text">{{ v }}</span></label></li>
                      </ul>
                    </li>
                  </ul>
                  <p class="alert" v-if="errors.energy_source1">{{ errors.energy_source1 }}</p>
                  <p class="alert" v-if="errors.energy_source2">{{ errors.energy_source2 }}</p>
                </td>
              </tr>
              <tr>
                <th>新機能/特徴<br>
                  （日本語）</th>
                <td>
                  <p>（300字以内）機種依存文字は入力できません。
                  </p><textarea rows="5" class="full" v-model="form.features"></textarea>
                  <p class="alert" v-if="errors.features">{{ errors.features }}</p>
                </td>
              </tr>
              <tr>
                <th>新機能/特徴<br>
                  （英語）</th>
                <td>
                  <p>（150 words 以内）</p><textarea name="textarea" rows="5" class="full" v-model="form.features_en"></textarea>
                  <p class="alert" v-if="errors.features_en">{{ errors.features_en }}</p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <ErrorComponent></ErrorComponent>

        <div class="button_ctn" v-if="isShowUI">
          <ul>
            <li>
              <button @click.prevent="modalClose" class="gray_button modalClose">キャンセル</button>
            </li>
            <li>
              <button @click="sendForm" class="regist_button">
                上記内容で登録
              </button>
            </li>
          </ul>
        </div>
        <!-- modal contents ここまで-->
      </div>
    </div>
    <!-- / モーダルコンテンツ -->
  </Teleport>
</template>

<script setup>
import { reactive, ref, computed, watch } from 'vue'
import APIClient from '@/store/repository/APIClient'
import { forEach, cloneDeep } from 'lodash'
import { FormValidate } from '@/helper/FormValidate'
import * as yup from 'yup'
import moment from "moment"
import { loadFile, getFileExtension, uploadFile, deleteFile } from '@/helper/files'
import YupConst from '@/const/yup'
import ErrorComponent from '@/components/ErrorComponent.vue'
import { store } from '@/store'

import {
  modalOpen,
  modalClose,
} from '@/helper/modal'

import {
  sortUp,
  sortDown,
  showOrderPrev,
  showOrderNext,
} from '@/helper/order'

import {
  isValue,
  isUrl,
} from '@/helper/index'

import {
  range,
} from '@/helper/math'

import ApiConst from '@/const/api'

import { isShowable as isShowUI } from '@/helper/indexStatus'

const maxListLength = 20

const api = new APIClient()

const list = reactive([])

const formInit = {
  application_id: null,
  name: '',
  name_en: '',
  quantity: null,
  exhibition_category: '',
  exhibition_category_other: '',
  category_exhibit: '',
  category_exhibit_other: '',
  category_premiere: '',
  embargo: '',
  file: '',
  file_application_id: null,
  file_form_no: null,
  energy_source1: '',
  energy_source2: '',
  features: '',
  features_en: '',
}
const form = reactive(cloneDeep(formInit))

let editAppId = null
watch(form, (el) => {
  if (el.exhibition_category !== 'その他') form.exhibition_category_other = ''
  if (el.category_exhibit !== 'その他') form.category_exhibit_other = ''

  if (el.exhibition_category !== '車両') {
    el.energy_source1 = ''
    el.energy_source2 = ''
  }

  if (editAppId !== el.application_id) {
    forEach(errors, (v, k) => {
      errors[k] = formInit[k]
    })
    document.getElementById('upload_file').value = ''
    store.dispatch('message/initializeErrors')
  }
  editAppId = el.application_id
})

const energy_source1_old = ref('')
const changeEnergySource = () => {
  if (form.energy_source1 !== energy_source1_old.value) form.energy_source2 = ''
  energy_source1_old.value = form.energy_source1
}

const uploadLabel = computed(() => {
  return form.application_id ? '変更' : '新規登録'
})

const fetch = async () => {
  const query = {
    form_no: 1,
    application_div: "exhibits_list",
  }
  const fetchApi = await api.get(ApiConst.API_APP_LIST, query)
  const res = fetchApi.response
  const errors = res.data?.error_results
  if (errors) {
    throw new Error('Not found.')
  }

  const form_no_range = range(11, res.data.results.list.length - 1 + 11)
  const form_exists_nos = res.data.results.list.map(el => el.json_data.file_form_no)
  const form_no_stack = form_no_range.filter(i => form_exists_nos.indexOf(i) == -1)

  list.splice(0)
  res.data.results.list.map((el) => {
    return {
      application_id: el.application_id,
      input_date: moment(el.updated_at).format("YY-MM-DD"),
      seq_no: el.seq_no,
      ...el.json_data
    }
  }).forEach((val, key) => {
    forEach(val, (v, k) => {
      if (k === 'file_form_no') {
        if (v) {
          val[k] =  v
        } else {
          val[k] = form_no_stack.shift()
        }
        return
      }
      val[k] = v ?? formInit[k]
    })
    list[key] = val
  })
}
fetch()

const newHandler = (event) => {
  document.getElementById('upload_file').value = ''
  forEach(formInit, (v, k) => {
    form[k] = v
  })
  modalOpen(event)
}

const editHandler = (event, key) => {
  forEach(form, (v, k) => {
    form[k] = list.find(e => e.application_id === key)[k]
  })
  modalOpen(event)
}

const deleteHandler = async (event, key) => {
  if (!window.confirm('削除して宜しいですか？')) return false

  try {
    const application_id = list.find(e => e.application_id === key).application_id
    const operation = "D"
    const query = { operation, application_id }
    const res = await api.post(ApiConst.API_APP_EDIT, query)
    if (res.response.data?.error_results) {
      throw new Error('API Validation Error')
    }
    fetch()
  } catch (e) {
    return false
  }
}

const cancelFile = () => {
  form.file = ''
  document.getElementById('upload_file').value = ''
}

const supportedFormat = YupConst.IMAGE_FILE_FORMAT
const scheme = {
  name: yup.string().trim().required('必須の項目です。'),
  name_en: yup.string().trim(),
  quantity: yup.number().typeError('数値を入力してください。').nullable().required('必須の項目です。').min(1, '1以上の数値を入力してください。'),
  exhibition_category: yup.string().trim().required('必須の項目です。'),
  exhibition_category_other: yup.string().trim().test('exhibition_category_other', 'その他の内容を記入してください。', function(v) {
    if (this.parent.exhibition_category !== 'その他') return true
    if (v !== '') return true
    return false
  }),
  category_exhibit: yup.string().trim().required('必須の項目です。'),
  category_exhibit_other: yup.string().trim().test('category_exhibit_other', 'その他の内容を記入してください。', function(v) {
    if (this.parent.category_exhibit !== 'その他') return true
    if (v !== '') return true
    return false
  }),
  category_premiere: yup.string().trim(),
  embargo: yup.string().trim(),
  file: yup.string().test('fileType', YupConst.IMAGE_FILE_FORMAT_MSG, v => {
    if (!v) return true
    if (isUrl(v)) return true
    const extension = getFileExtension(v)
    return supportedFormat.includes(extension)
  }).max(YupConst.IMAGE_BASE64_SIZE_5MB, YupConst.IMAGE_BASE64_SIZE_5MB_MSG),
  energy_source1: yup.string().trim().test('energy_source1', '[展示カテゴリー]で「車両」を選択した場合のみ、必須の項目です。', function(v) {
    if (this.parent.exhibition_category !== '車両') return true
    if (v !== '') return true
    return false
  }),
  features: yup.string().trim().max(YupConst.TEXT_MAX_EXHIBITS_LIST, YupConst.TEXT_MAX_EXHIBITS_LIST_MSG),
  features_en: yup.string().trim()
    .matches(new RegExp(YupConst.TEXT_MAX_PATTERN_EXHIBITS_LIST_EN), YupConst.TEXT_MAX_EXHIBITS_LIST_EN_MSG),
}
const validate = new FormValidate(scheme, form)
const errors = validate.errors
const sendForm = async () => {
  try {
    if ((Object.keys(await validate.getErrorObject()).length)) {
      throw new Error('入力項目に不備があります。')
    }
    const params = cloneDeep(form)
    const application_id = params.application_id
    if (!application_id && maxListLength <= list.length) {
      throw new Error(`リストの上限は${maxListLength}です。`)
    }
    const file = params.file
    delete params.application_id
    if ( params.file ) delete params.file
    const operation = application_id ? "R" : "I"
    const query = operation === "I"
      ? { operation, application_div: "exhibits_list", form_no: 1, json_data: params }
      : { operation, application_id, json_data: params }
    const res = await api.post(ApiConst.API_APP_EDIT, query)
    if (res.response.data?.error_results) {
      throw new Error('API Validation Error')
    }
    const { application_id : relation_application_id } = res.response.data.results
    if (!relation_application_id) {
      throw new Error('Application ID not received.')
    }

    if (!isValue(file)) {
      if (isValue(params.file_application_id)) {
        await deleteFile(params.file_application_id)
      }
      fetch()
      modalClose()
      return
    }

    if (isUrl(file)) {
      fetch()
      modalClose()
      return
    }
    await fetch()
    const searchEl = list.find(el => el.application_id === relation_application_id)
    const form_no = searchEl?.file_form_no
    uploadFile('exhibits_list', form_no, file, relation_application_id).then(() => {
      fetch()
      modalClose()
    })
  } catch (e) {
    store.commit('message/errors', [e.message])
    return false
  }
}
</script>

<style></style>
