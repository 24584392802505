export default {
  namespaced: true,
  state: {
  },
  mutations: {
    test_flag(state, payload) {
      state.test_flag = payload
    },
    function_code_list(state, payload) {
      state.function_code_list = payload
    },
		admin_application(state, payload) {
      state.admin_application = payload
    },
		fix_category_disp_list(state, payload) {
      state.fix_category_disp_list = payload
    },
		updated_at(state, payload) {
      state.updated_at = payload
    },
  },
  actions: {
    update({ commit }, res) {
      const results = res?.response?.data?.results
      commit('test_flag', results?.json_data?.test_flag)
      commit('function_code_list', results?.json_data?.function_code_list)
      commit('admin_application', results?.json_data?.admin_application)
      commit('fix_category_disp_list', results?.json_data?.fix_category_disp_list)
      commit('updated_at', res?.response?.headers?.date)
    },
  }
}
