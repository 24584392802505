import moment from "moment"

const waitSecond = 2

const passedWaitTime = (state, commit, endpoint, application_div, form_no) => {
  try {
    // 履歴がなければ送信OK
    if (state.endpoint !== endpoint) {
      throw new Error('No endpoints are in the history.')
    }
    if (state.application_div !== application_div) {
      throw new Error('No application_div are in the history.')
    }
    if (state.form_no !== form_no) {
      throw new Error('No form_no are in the history.')
    }
    if (!state.logged_at) {
      throw new Error('No updated data available.')
    }
    const now = moment()
    const diff = moment(state.logged_at).add(waitSecond, 's')
    if (diff < now) {
      // 指定時間経過後は送信OK
      throw new Error('Update data is out of date.')
    }
    return false
  } catch (e) {
    console.log(e)
    commit('message', e.message)
    return true
  }
}

export default {
  namespaced: true,
  state: {
    endpoint: '',
    application_div: '',
    form_no: '',
    message: '',
    logged_at: '',
  },
  mutations: {
    endpoint(state, payload) {
      state.endpoint = payload
    },
    application_div(state, payload) {
      state.application_div = payload
    },
    form_no(state, payload) {
      state.form_no = payload
    },
    message(state, payload) {
      state.message = payload
    },
    logged_at(state, payload) {
      state.logged_at = payload
    },
  },
  actions: {
    log({ state, commit }, {endpoint, params}) {
      try {
        if (
          !params.application_div
          ||
          !params.form_no
        ) {
          throw new Error('One or all of the specified parameters are missing.')
        }
        const isPassed = passedWaitTime(state, commit, endpoint, params.application_div, params.form_no)
        if (isPassed) {
          // 指定時間経過後は履歴を残して送信OK
          commit('endpoint', endpoint)
          commit('application_div', params.application_div)
          commit('form_no', params.form_no)
          commit('logged_at', moment())
          throw new Error('Waiting time has elapsed')
        }
        alert('重複登録の可能性があるため1件のみ登録しました。内容をご確認ください。')
        return false
      } catch (e) {
        console.log(e)
        commit('message', e.message)
        // 送信NG
        return true
      }
    },
  }
}
