import axios from 'axios'
import moment from "moment";
import ApiConst from '@/const/api'
import { store } from '@/store'
const statusKeyMap = ApiConst.statusKeyMap
import { useRouter } from 'vue-router'
import { isValue } from '@/helper/index';
import streamSaver from 'streamsaver';

const router = useRouter();
export default class APIClient {
  constructor() {
  }

  async get(path, query, addHeader) {
    let url = path
    let _header
    if (path.indexOf('api/admin/') > 0) {
      _header = this.adminCreateHeader(addHeader)
    } else {
      _header = this.createHeader(addHeader)
    }
    const header = _header
    const config = {
      headers: header,
      origin: this.proxy
    }

    if (query) {
      url += '?'
      Object.keys(query).forEach((key) => {
        url += (key + '=' + encodeURIComponent(query[key]) + '&')
      })
      url = url.slice(0, -1)
    }
    try {
      store.dispatch('message/initializeErrors')
      const response = await axios.get(url, config)
      if (response.data.error_results) {
        store.commit('message/errors', [...store.state.message.errors, ...response.data.error_results.message])
      }
      return new APIResponse(statusKeyMap.SUCCESS, response)
    } catch (e) {
      if (e.response) {
        if (e.response.status === ApiConst.API_CD_AUTH_ERROR) {
          const result = await this.refreshToken()
          if (result) {
            return this.get(path, query, addHeader)
          } else {
            console.error('[GET AUTH ERROR]' + e.response.data.message + '(' + e.response.data.errcode + ')' + path)
            return new APIResponse(statusKeyMap.AUTH_FAILED, e.response)
          }
        } else if (e.response.status === ApiConst.API_VALIDATION_ERROR) {
          console.warn('[API Validation Error]' + e.response.data.message + '(' + e.response.data.errcode + ')' + path)
          return new APIResponse(statusKeyMap.FAILED, e.response)
        } else {
          console.error('[GET RESPONSE ERROR]' + e.response.data.message + '(' + e.response.data.errcode + ')' + path)
          return new APIResponse(statusKeyMap.FAILED, e.response)
        }
      } else {
        console.error('[GET Connect Failed]' + ' "' + e + '"' + path)
        return new APIResponse(statusKeyMap.CONNECT_FAILED, e)
      }
    }
  }

  async post(path, body, addHeader, contentType = 'application/json', refresh = false) {
    let url = path
    if (refresh) {
      url = path
    }
    let _header
    if (path.indexOf('api/admin/') > 0) {
      _header = this.adminCreateHeader(addHeader, contentType)
    } else {
      _header = this.createHeader(addHeader, contentType)
    }
    const header = _header
    const config = {
      headers: header
    }
    try {
      store.dispatch('message/initializeErrors')
      if ([ApiConst.API_APP_EDIT, ApiConst.API_APP_UPLOAD].includes(path)) {
        const loggedSubmitTime = await store.dispatch("duplicate_submission_protection/log", {
          endpoint: path,
          params: body
        })
        if (!loggedSubmitTime) {
          throw new Error('Duplicate submission is prohibited.')
        }
      }
      const response = await axios.post(url, body, config)
      if (response.data.error_results) {
        store.commit('message/errors', [...store.state.message.errors, ...response.data.error_results.message])
      }
      if (body?.json_data?.billing_id) {
        // 請求先設定状況更新
        store.dispatch("bill/unsetRefresh")
      }
      return new APIResponse(statusKeyMap.SUCCESS, response)
    } catch (e) {
      if (e.response.status === ApiConst.API_PAYLOAD_TOO_LARGE_ERROR) {
        store.commit('message/errors', [...store.state.message.errors, 'PAYLOAD TOO LARGE ERROR'])
        console.error('[POST PAYLOAD TOO LARGE ERROR]' + e.response.data.message + '(' + e.response.data.errcode + ')' + path)
      }
      store.commit('message/errors', [...store.state.message.errors, ...e.response.data.error_results.message])

      if (e.response) {
        if (e.response.status === ApiConst.API_CD_AUTH_ERROR) {
          const result = await this.refreshToken()
          if (result) {
            return this.post(path, body, addHeader)
          } else {
            console.error('[POST AUTH ERROR]' + e.response.data.message + '(' + e.response.data.errcode + ')' + path)
            return new APIResponse(statusKeyMap.AUTH_FAILED, e.response)
          }
        } else {
          console.error('[POST RESPONSE ERROR]' + e.response.data.message + '(' + e.response.data.errcode + ')' + path)
          return new APIResponse(statusKeyMap.FAILED, e.response)
        }
      } else {
        console.error('[POST Connect Failed]' + ' "' + e + '"' + path)
        return new APIResponse(statusKeyMap.CONNECT_FAILED, e)
      }
    }
  }

  createHeader(addHeader, contentType) {
    let header = {
      'Content-Type': contentType,
      'Cache-Control': 'no-cache',
      'Access-Control-Allow-Origin': '*'
    }
    if (store.state.login.token) {
      header['X-Etoken'] = store.state.login.token
    }

    if (store.state.admin_spoofing_login.token) {
      header['X-Etoken'] = store.state.admin_spoofing_login.token
    }

    if (addHeader) {
      header = {
        ...header,
        ...addHeader
      }
    }
    return header
  }

  adminCreateHeader(addHeader, contentType) {
    let header = {
      'Content-Type': contentType,
      'Cache-Control': 'no-cache',
      'Access-Control-Allow-Origin': '*'
    }
    if (store.state.administrator.admin_token) {
      header['X-Atoken'] = store.state.administrator.admin_token
    }

    if (store.state.admin_spoofing_login.token) {
      header['X-Etoken'] = store.state.admin_spoofing_login.token
    }

    if (addHeader) {
      header = {
        ...header,
        ...addHeader
      }
    }
    return header
  }

  // 出展者認証
  async auth() {
    if (!store.state.login.token) return false
    const header = {}
    const payload = {}
    let successRespObj
    try {
      const respObj = await this.post(ApiConst.API_AUTH, payload, header, 'application/json', true)
      if (respObj.status !== statusKeyMap.SUCCESS) {
        const couldRefresh = await this.refreshToken()
        if (!couldRefresh) {
          throw new Error(`Response is error ${respObj.status}.`)
        }
      }
      const errors = respObj.response.data?.error_results
      if (errors) {
        const couldRefresh = await this.refreshToken()
        if (!couldRefresh) {
          throw new Error('Token is invalid.')
        }
      }
      successRespObj = respObj
    } catch (e) {
      console.error(e.message)
      return false
    }
    store.dispatch("login_user/update", successRespObj)
    return successRespObj
  }

  async refreshToken() {
    try {
      const limitDate = moment(store.state.login.limit_datetime_refresh_token)
      if (!limitDate.isValid()) {
        throw new Error("Incorrect refresh token expiration date.");
      }
      const dateDiff = limitDate.diff(moment())
      if (dateDiff < 0) {
        throw new Error("Refresh token expired.");
      }
    } catch (e) {
      console.error(e.message);
      router.push({ path: '/login' });
    }

    let header = {}
    if (store.state.login.refresh_token) {
      header = {
        'X-Etoken': store.state.login.token
      }
    }
    const respObj = await this.post(ApiConst.API_REFRESH_TOKEN, {
      refresh_token: store.state.login.refresh_token
    }, header, 'application/json', true)
    if (respObj.status !== statusKeyMap.SUCCESS) {
      return false
    } else {
      const results = respObj.response.data.results
      store.commit('login/token', results.token)
      store.commit('login/limit_datetime', results.limit_datetime)
      return true
    }
  }

  // 管理者認証
  async adminAuth() {
    if (!store.state.administrator.admin_token) return false
    const header = {}
    const payload = {}
    try {
      const respObj = await this.post(ApiConst.API_ADMIN_AUTH, payload, header, 'application/json', true)
      if (respObj.status !== statusKeyMap.SUCCESS) {
        const couldRefresh = await this.adminRefreshToken()
        if (!couldRefresh) {
          throw new Error(`Response is error ${respObj.status}.`)
        }
      }
      const errors = respObj.response.data?.error_results
      if (errors) {
        const couldRefresh = await this.adminRefreshToken()
        if (!couldRefresh) {
          throw new Error('Token is invalid.')
        }
      }
      store.dispatch("admin_application/update", respObj)
    } catch (e) {
      console.error(e.message)
      return false
    }
    return true
  }

  async adminRefreshToken() {
    try {
      const limitDate = moment(store.state.administrator.limit_datetime_refresh_token)
      if (!limitDate.isValid()) {
        throw new Error("Incorrect refresh token expiration date.");
      }
      const dateDiff = limitDate.diff(moment())
      if (dateDiff < 0) {
        throw new Error("Refresh token expired.");
      }
    } catch (e) {
      console.error(e.message);
      router.push({ path: '/admin' });
    }

    let header = {}
    if (store.state.administrator.admin_refresh_token) {
      header = {
        'X-Atoken': store.state.administrator.admin_token
      }
    }
    const respObj = await this.post(ApiConst.API_ADMIN_REFRESH_TOKEN, {
      refresh_token: store.state.administrator.admin_refresh_token
    }, header, 'application/json', true)
    if (respObj.status !== statusKeyMap.SUCCESS) {
      return false
    } else {
      const results = respObj.response.data.results
      store.commit('administrator/admin_token', results.admin_token)
      store.commit('administrator/limit_datetime', results.limit_datetime)
      return true
    }
  }

  async getConfig() {
    const fetchApi = await this.get(ApiConst.API_CONFIG)
    const res = fetchApi.response

    const membership_category = res.data.results.membership_category
    if (membership_category) {
      res.data.results.membership_category_options = [{
        "label": "選択してください。",
        "value": ""
      }, ...Object.entries(membership_category).map(value => {
        return {
          "label": value[1],
          "value": value[0]
        }
      })]


      res.data.results.membership_category_options_admin = Object.entries(membership_category).map(value => {
        return {
          "label": value[1],
          "value": value[0]
        }
      })
    }

    const joint_adjacent = res.data.results.joint_adjacent
    if (joint_adjacent) {
      res.data.results.joint_adjacent_options = Object.entries(joint_adjacent).map(value => {
        return {
          "label": value[1],
          "value": value[0]
        }
      })
    }

    const category_div1 = res.data.results.category_div1
    const category_div2 = res.data.results.category_div2

    if (category_div1 && category_div2) {
      res.data.results.category_div_options = Object.entries(category_div1).map(value => {
        const object = {
          "label": value[1],
          "value": value[0],
          "isSingle": true,
        }
        const options = category_div2[value[0]]
        const parent_value = value[0]
        if (options) {
          object.options = Object.entries(options).map(value => {
            return {
              "label": value[1],
              "value": value[0],
              "isSingle": parent_value === '20' ? true : false,
            }
          })
        }
        return object
      })
    }

    const past_achievement = res.data.results.past_achievement
    if (past_achievement) {
      res.data.results.past_achievement_options = Object.entries(past_achievement).map(value => {
        return {
          "label": value[1],
          "value": value[0]
        }
      })
    }

    const staff_authority = res.data.results.staff_authority
    if (staff_authority) {
      res.data.results.staff_authority_options = Object.entries(staff_authority).map(value => {
        return {
          "label": value[1],
          "value": value[0]
        }
      })
    }

    //管理側
    const exhibit_participation_status_options = res.data.results.exhibit_participation_status_options
    if (exhibit_participation_status_options) {
      res.data.results.exhibit_participation_status_options = [{
        "label": "選択してください。",
        "value": ""
      }, ...Object.entries(exhibit_participation_status_options).map(value => {
        return {
          "label": value[1],
          "value": value[0]
        }
      })]
    }
    const second_order_status = res.data.results.second_order_status
    if (second_order_status) {
      res.data.results.second_order_status_options = Object.entries(second_order_status).map(value => {
        return {
          "label": value[1],
          "value": value[0]
        }
      })
    }
    const category_disp = res.data.results.category_disp
    if (category_disp) {
      res.data.results.category_disp_options_plus = [{
        "label": "選択してください。",
        "value": ""
      }, ...Object.entries(category_disp).map(value => {
        return {
          "label": value[1],
          "value": value[0]
        }
      })]
    }
    const hall_div = res.data.results.hall_div
    if (hall_div) {
      res.data.results.hall_div_options_plus = [{
        "label": "選択してください。",
        "value": ""
      }, ...Object.entries(hall_div).map(value => {
        return {
          "label": value[1],
          "value": value[0]
        }
      })]
    }


    return res
  }

  async GetPreEntryDetail() {
    const fetchApi = await this.get('/api/exhibitor/second_order/detail')
    const res = fetchApi.response

    return res
  }

  async postAdminPreList(query) {
    const fetchApi = await this.post('/api/admin/exhibit_participation/list', query)
    const res = fetchApi.response

    //管理画面1次一覧画面変数格納
    const admin_pre_list = res.data.results.list
    //管理画面1次カテゴリ表示用
    const category_options = store.state.disicion.membership_category_options
    admin_pre_list.map(item => {
      item.membership_category_label = category_options.find(it => it.value === String(item.membership_category))?.label
    });
    res.data.results.list = admin_pre_list

    //管理画面1次過去の出展履歴
    const past_achievement_options = store.state.disicion.past_achievement_options
    admin_pre_list.map(item => {
      item.past_achievement_label = past_achievement_options.find(it => it.value === String(item.past_achievement))?.label
    });
    res.data.results.list = admin_pre_list

    //管理画面1次カテゴリ
    const category_div1_options = store.state.disicion.category_div_options
    admin_pre_list.map(item => {
      item.category_div1_label = category_div1_options.find(it => it.value === String(item.category_div1))?.label
    });
    res.data.results.list = admin_pre_list

    //管理画面1次承認非承認
    const exhibit_participation_status_options = store.state.disicion.exhibit_participation_status_options
    admin_pre_list.map(item => {
      item.exhibit_participation_status_label = exhibit_participation_status_options.find(it => it.value === String(item.exhibit_participation_status))?.label
    });
    res.data.results.list = admin_pre_list


    return res
  }

  async postAdminPreDetail(query) {
    const fetchApi = await this.get('/api/admin/exhibit_participation/detail', query)
    const res = fetchApi.response
    //管理画面1次一覧画面変数格納
    const admin_pre_detail = res.data.results

    //管理画面1次過去の出展履歴
    const past_achievement_options = store.state.disicion.past_achievement_options
    admin_pre_detail.past_achievement_label = past_achievement_options.find(it => it.value === String(admin_pre_detail.past_achievement))?.label

    //管理画面1次承認非承認
    const exhibit_participation_status_options = store.state.disicion.exhibit_participation_status_options
    admin_pre_detail.exhibit_participation_status_label = exhibit_participation_status_options.find(it => it.value === String(admin_pre_detail.exhibit_participation_status))?.label
    //管理画面2次会員カテゴリ表示用
    const membership_category_options = store.state.disicion.membership_category_options_admin
    admin_pre_detail.membership_category_label = membership_category_options.find(it => it.value === String(admin_pre_detail.membership_category))?.label

    //管理画面2次会員カテゴリ表示用
    const category_div_options = store.state.disicion.category_div_options
    admin_pre_detail.category_div_label = category_div_options.find(it => it.value === String(admin_pre_detail.category_div1))?.label

    res.data.results = admin_pre_detail

    return res
  }

  async postAdminPreJudge(query) {
    const fetchApi = await this.post('/api/admin/exhibit_participation/judge', query)
    const res = fetchApi.response

    return res
  }

  async postAdminSecondList(query) {
    const fetchApi = await this.get('/api/admin/second_order/list', query)
    const res = fetchApi.response

    //管理画面2次一覧画面変数格納
    const admin_second_list = res.data.results.list
    //管理画面2次会員区分表示用
    const category_options = store.state.disicion.membership_category_options
    admin_second_list.map(item => {
      item.membership_category_label = category_options.find(it => it.value === String(item.membership_category))?.label
    });


    //管理画面2次カテゴリ
    const category_div1_options = store.state.disicion.category_div_options
    admin_second_list.map(item => {
      item.category_div1_label = category_div1_options.find(it => it.value === String(item.category_div1))?.label
    });

    //管理画面2次出展者ステータス
    const second_order_status_options = store.state.admin.second_order_status_options
    admin_second_list.map(item => {
      item.second_order_status_label = second_order_status_options.find(it => it.value === String(item.second_order_status))?.label
    });


    res.data.results.list = admin_second_list

    return res
  }

  getAdminSecondListQuery(query) {
    const category_options = store.state.disicion.membership_category_options_admin
    const category_div_options = store.state.disicion.category_div_options
    const searchLabels = {
      exhibitor_name: '会社名',
      cancel_flag: 'キャンセルフラグ',
      test_flag: 'テストフラグ',
      koma_bango: '小間番号',
      membership_category: '会員区分',
      category_div: '出展カテゴリー',
    }
    const convertValue = (options, value) => {
      let ary = []
      value.split(',').forEach(item => {
        ary.push(options.find(el => el.value === item)?.label)
      })
      return ary.join(',')
    }
    const results = Object.entries(query).map(item => {
      const key = item[0]
      const val = item[1]
      const keyString = Object.entries(searchLabels).find(el => {
        const k = el[0]
        if (k == key) {
          return true
        }
        return false
      })?.[1]

      let valString = val
      if (key === 'membership_category') {
        valString = convertValue(category_options, val)
      }
      if (key === 'category_div') {
        valString = convertValue(category_div_options, val)
      }
      if (key === 'cancel_flag') {
        valString = val ? 'キャンセル' : ''
      }
      if (key === 'test_flag') {
        valString = val ? 'テストを含む' : ''
      }
      const result = `【${keyString}】${valString}`
      return result
    })
    return results
  }
  async getAdminSecondList(query) {
    store.commit('search/admin_second_list_query', this.getAdminSecondListQuery(query))
    const fetchApi = await this.get('/api/admin/second_order/list', query)
    const res = fetchApi.response

    //管理画面2次一覧画面変数格納
    const list = res.data.results.list
    //管理画面2次会員区分表示用
    const category_options = store.state.disicion.membership_category_options_admin
    list.map(item => {
      item.membership_category_label = category_options.find(it => it.value == item.membership_category)?.label
    });

    //管理画面2次カテゴリ
    const category_div1_options = store.state.disicion.category_div_options
    list.map(item => {
      item.category_div1_label = category_div1_options.find(it => it.value == item.category_div1)?.label
    });
   //管理画面2次出展者ステータス
    const second_order_status_options = store.state.admin.second_order_status_options
   list.map(item => {
     item.second_order_status_label = second_order_status_options.find(it => it.value == item.second_order_status)?.label
   });
 


    res.data.results.list = list

    return res
  }

  async postAdminSecondDetail(query) {
    const fetchApi = await this.get('/api/admin/second_order/detail', query)
    const res = fetchApi.response
    //管理画面1次一覧画面変数格納
    const admin_pre_detail = res.data.results

    //管理画面1次過去の出展履歴
    const past_achievement_options = store.state.disicion.past_achievement_options
    admin_pre_detail.past_achievement_label = past_achievement_options.find(it => it.value === String(admin_pre_detail.past_achievement))?.label

    //管理画面1次承認非承認
    const exhibit_participation_status_options = store.state.disicion.exhibit_participation_status_options
    admin_pre_detail.exhibit_participation_status_label = exhibit_participation_status_options.find(it => it.value === String(admin_pre_detail.exhibit_participation_status))?.label
    //管理画面2次会員カテゴリ表示用
    const membership_category_options = store.state.disicion.membership_category_options_admin
    admin_pre_detail.membership_category_label = membership_category_options.find(it => it.value === String(admin_pre_detail.membership_category))?.label

    //管理画面2次会員カテゴリ表示用
    const category_div_options = store.state.disicion.category_div_options
    admin_pre_detail.category_div_label = category_div_options.find(it => it.value === String(admin_pre_detail.category_div1))?.label
    
    res.data.results = admin_pre_detail

    return res
  }

  async getAdminExhibitParticipationList(query) {
    const fetchApi = await this.get('/api/admin/exhibit_participation/list', query)
    const res = fetchApi.response

    const list = res.data.results.list

    const exhibit_participation_status_options = store.state.disicion.exhibit_participation_status_options
    const category_options = store.state.disicion.membership_category_options_admin
    const past_achievement_options = store.state.disicion.past_achievement_options
    const category_div_options = store.state.disicion.category_div_options
    list.map(
      item => {
        if (isValue(item.exhibit_participation_status)) item.exhibit_participation_status_label = exhibit_participation_status_options.find(it => isValue(it.value) && it.value == item.exhibit_participation_status)?.label

        if (isValue(item.membership_category)) item.membership_category_label = category_options.find(it => it.value == item.membership_category)?.label

        if (isValue(item.past_achievement)) item.past_achievement_label = past_achievement_options.find(it => it.value == item.past_achievement)?.label

        if (isValue(item.category_div1)) item.category_div1_label = category_div_options.find(it => it.value == item.category_div1)?.label
      });

    return res
  }

  getFileName(contentDisposition) {
    const matched = contentDisposition.replace(/\s/g, "").match(/.*filename=(.+\.[^.]+)/)
    const fileName = matched[1] || 'file'

    return fileName;
  }

  async download(url) {
    await fetch(url).then(async (response) => {
      const fileName = response.url.match(".+/(.+?)([\\?#;].*)?$")[1];
      const blob = response.body;
      const fileSize = Number(response.headers.get('content-length'));
      const fileStream = streamSaver.createWriteStream(
        fileName, // DL時のファイル名を指定できます
        { size: fileSize } // size を渡すと、DLにかかる時間を表示することができます
      );

      await blob?.pipeTo(fileStream);
    });
  }
}

class APIResponse {
  constructor(status, response) {
    this.status = status // only statusKeyMap
    this.response = response
  }
}
