<template>
  <div id="admin">
    <header>
      <h1>JAIS 2025 出展者登録DB&nbsp;&nbsp;管理者用ページ</h1>
    </header>
    <article>
      <h2>水道使用量の入力</h2>
      <div id="app">
        <div id="form_ctn" class="form_box">
          <ol class="breadcrumbs">
            <AdminNav :categoryTop="false" :category="3" pageName="水道使用量の入力" />
          </ol>
          <h3>水道使用量の入力</h3>

          <ErrorComponent></ErrorComponent>

          <table class="common_table">
            <thead>
              <tr>
                <th>小間番号</th>
                <th>出展者名</th>
                <th>水道使用量</th>
                <th>水道使用料</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(val, key) in list"
                :key="key"
              >
                <td>{{ val.koma_bango }}</td>
                <td>{{ val.exhibitor_name }}</td>
                <td><input v-model="form['water_num_' + Number(key + 1)]" type="text" class="w10em"> m<sup>3</sup></td>
                <td align="right">{{ calcPay(form['water_num_' + Number(key + 1)]) }}<br>{{ UNIT_COST_LABEL }}</td>
              </tr>
            </tbody>
            <tfoot>
            </tfoot>
          </table>
          <ul class="common_list_horizontal">
            <li><button class="common_button" @click="send" :disabled="sendDisabled">{{ sendLabel }}</button></li>
          </ul>
        </div>
      </div>
    </article>
  </div>
</template>

<script setup>
import Big from 'big.js'
import { ref, reactive } from 'vue'
import ErrorComponent from '@/components/ErrorComponent.vue';
import ApiConst from '@/const/api'
import AdminNav from "@/components/AdminNav"
import SANITARY_REGIST from '@/const/sanitary_regist'
import { getQuery, fetchList, sendForm, sendDisabled, sendLabel } from '@/helper/adminDemandSearch'

const query = getQuery()

const UNIT_COST_LABEL = `単価${SANITARY_REGIST.UNIT_COST.toLocaleString()}/㎥`

const calcPay = (hour) => {
  const val = Number(hour)
  if ( !val ) return '0 円'
  return Big(val).mul(SANITARY_REGIST.UNIT_COST).toNumber().toLocaleString()+' 円'
}

const list = ref([])

const form = reactive({
})


const fetch = () => {
  fetchList(ApiConst.API_ADMIN_SANITARY_LIST, query, list, form)
}
fetch()

const send = () => {
  sendForm( ApiConst.API_ADMIN_SANITARY_BULKEDIT, form, fetch)
}
</script>

<style></style>
