<template>
  <div id="admin">
    <header>
      <h1>JAIS 2025 出展者登録DB&nbsp;&nbsp;管理者用ページ</h1>
    </header>
    <article>
      <h2>管理者メニュー</h2>
      <div id="form_ctn" class="form_box">
        <h3>各種申込</h3>
        <ul class="admin_menu_list">
          <li v-if="enableFunctionCode('fc_0015_01')"><router-link :to="{ name: 'AdminDeadline' }"
              class="common_button">各種書類締切日の設定</router-link></li>
          <li v-if="enableFunctionCode('fc_0007_01')"><router-link :to="{ name: 'AdminApplicationSearch' }"
              class="common_button">各種申込の管理</router-link></li>
          <li v-if="enableFunctionCode('fc_0006_01')"><router-link :to="{ name: 'AdminSpoofingSearch' }"
              class="common_button">各種申込の代理登録</router-link></li>
          <li v-if="enableFunctionCode('fc_0017_01')"><router-link :to="{ name: 'AdminStatusSearch' }"
              class="common_button">各出展者の各種申込ステータス確認</router-link></li>
        </ul>

        <h3>申込み管理</h3>
        <ul class="admin_menu_list">
          <li v-if="enableFunctionCode('fc_0001_01')">
            <router-link :to="{ name: 'AdminPreIndex' }" class="common_button">一次申込管理</router-link>
          </li>
          <li v-if="enableFunctionCode('fc_0001_01')">
            <router-link :to="{ name: 'AdminSecondIndex' }" class="common_button">二次申込管理</router-link>
          </li>
          <li v-if="enableFunctionCode('fc_0002_01')">
            <router-link :to="{ name: 'AdminSecondMultipleIndex' }" class="common_button">一括管理</router-link>
          </li>

        </ul>
 
        <h3>請求関連管理</h3>
        <ul class="admin_menu_list">

          <li v-if="enableFunctionCode('fc_0009_01')"><router-link :to="{
            name: 'AdminDemandSearch',
            params: {
              admin_demand_search : 'AdminSanitaryregist'
            }
          }" class="common_button">水道使用料の入力</router-link></li>
          <li v-if="enableFunctionCode('fc_0010_01')"><router-link :to="{
            name: 'AdminDemandSearch',
            params: {
              admin_demand_search : 'AdminOvertimework'
            }
          }" class="common_button">深夜残業代の入力</router-link></li>
          <li v-if="enableFunctionCode('fc_0011_01')"><router-link :to="{
            name: 'AdminDemandSearch',
            params: {
              admin_demand_search : 'AdminOvertimenetwork'
            }
          }" class="common_button">通信通話料（超過分）の入力</router-link></li>

          <li v-if="enableFunctionCode('fc_0013_01')"><router-link :to="{ name: 'AdminDemand' }" class="common_button">請求書発行</router-link></li>

        </ul>
        
        <h3>その他</h3>
        <ul class="admin_menu_list">
          <li v-if="enableFunctionCode('fc_0005_01')"><router-link :to="{ name: 'AdminAccountIndex' }"
              class="common_button">管理者アカウント管理</router-link></li>
        </ul>
      </div>
    </article>
  </div>
</template>

<script>

import APIClient from '@/store/repository/APIClient'

import { enableFunctionCode } from '@/helper/adminAuthority'


export default {
  components: {

  },
  setup() {




    return {
      enableFunctionCode
    };
  },
  data() {
    return {

    }
  },
  async created() {
    this.api = new APIClient()


  },
  mounted() {

  },
  methods: {

  },
  watch: {


  },
  computed: {

  }
};
</script>

<style></style>
