<template>
<div class="one_entry_contents">
  <h4>電気使用申込</h4>
  <div class="one_entry_contents_inner">
    <ol class="common_ol">
      <li>JAPAN MOBILITY SHOW 2025規程「<a href="/docs/2025/regulation/regulation.pdf#page=40" target="_blank">7-1
          電気</a>」(P.37-38)を参照の上、入力してください。</li>
      <li>「電気工事設計図」を別途ご提出ください。</li>
      <li>電気開閉器の設置希望位置を「電気工事設計図」に記入してください。</li>
      <li>蛍光灯、高圧水銀灯、その他の放電灯は、50%増しの容量計算をしてください。<br>
        （幹線主開閉器遮断の防止）</li>
      <li>申込容量は各電気方式種別毎の総括容量で入力してください。</li>
      <li>交流三相４線式230V/400V（動力、電灯）の電気幹線工事については、事務局へお問い合わせ下さい。</li>
      <li>
        電源交換用のトランスは各自用意してください。また、展示会場内に20kW以上のトランス、全出力が20kW以上の高圧変更設備を持ち込む場合は、消防署への届出が必要となります。事前に(株)東京ビッグサイトまでご相談ください。
      </li>
      <li>控室用の電気申込は、別途「出展者用控室使用申込」よりお申込ください。</li>
    </ol>
    <ul class="common_ul">
      <li class="attention_color">幹線工事費は9月下旬に請求書を発行しますので10月31日(火)までに入金してください。<br>
        また、電気使用料は会期終了後、別途請求いたします。</li>
    </ul>

    <h5>電気使用申込</h5>
    <ElectricityCalcForm />

    <h5>小間内電気施工業者</h5>
    <ContractorForm />

    <h5>請求先</h5>
    <BillForm />

    <h5>電気工事設計図のアップロード</h5>
    <ol class="common_ol">
      <li>ダウンロードボタンをクリックして送付用PDFファイルをダウンロードしてください。<br>
        <a @click="download" class="common_add_button">記入用紙のダウンロード</a></li>
        <li>必要事項を記載のうえ、下記よりファイルをアップロードしてください。</li>
      <li>ファイル形式はPDF形式とさせていただきます。</li>
      <li>複数ファイルをアップロードされる場合はZIP形式でのアップロードをお願いします。</li>
      <li>5MB以上のファイルは事務局宛に メールにてご連絡ください。</li>
      <li>提出締切日は<span class="attention_color">2025年9月4日(月)</span>です。</li>
    </ol>
    <BlueprintsForm />
  </div>
</div>
</template>

<script setup>
import APIClient from '@/store/repository/APIClient'
import ElectricityCalcForm from "@/views/p3/ExhibitionElectroRegist/ElectricityCalcForm"
import ContractorForm from "@/views/p3/ExhibitionElectroRegist/ContractorForm"
import BillForm from "@/views/p3/ExhibitionElectroRegist/BillForm"
import BlueprintsForm from "@/views/p3/ExhibitionElectroRegist/BlueprintsForm"

import ApiConst from '@/const/api'

const api = new APIClient()

const download = async () => {
  try {
    const query = {
      application_div_form_no : "electro_regist_5"
    }
    const fetchApi = await api.post(ApiConst.API_APP_PDF_DOWNLOAD, query)
    const res = fetchApi.response
    const errors = res.data?.error_results
    if (errors) {
      throw new Error('Not found.')
    }
    await api.download(res.data.results.url)
  } catch (e) {
    console.log(e)
    return false
  }
}
</script>
