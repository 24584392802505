import { makeUnsetDeliveringList } from '@/helper/delivery'

export default {
  namespaced: true,
  state: {
    list: [],
    unset: [],
  },
  mutations: {
    list(state, payload) {
      state.list = payload
    },
    unset(state, payload) {
      state.unset = payload
    },
  },
  actions: {
    unset({ commit }, results) {
      commit('unset', results)
    },
    unsetRefresh() {
      makeUnsetDeliveringList()
    },
  },
}
