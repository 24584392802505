<template>
  <table class="common_table">
    <tbody>
      <tr>
        <th>無線LANの使用</th>
        <td>{{ currentData.need ? currentData.need : '未登録' }}</td>
      </tr>

    </tbody>
  </table>

  <template v-if="isShowUI">
  <div><a @click.prevent="editHandler" href="#form3" class="common_add_button modalOpen">{{ uploadLabel }}</a></div>
  </template>

  <Teleport to="body">
    <!-- モーダルコンテンツ / -->
    <div class="overlay" id="form3">
      <div id="modal_content" class="modal_contents w80">
        <div class="modalClose_ctn"><a @click.prevent="modalClose" class="modalClose"></a></div>
        <!-- modal contents ここから-->
        <div class="entry_title">
          <h4>臨時通信設備使用申込（高速通信回線）</h4>

          <div class="entry_title">
            <h5>無線LANの使用&nbsp;&nbsp;{{ uploadLabel }}</h5>
          </div>


          <div class="entry_modal_ctn">

            <table class="common_table">
              <tbody>
                <tr>
                  <th>無線LANの使用</th>
                  <td>
                    <ul class="common_list_horizontal">
                      <li><label>
                        <input type="radio" name="need"
                        v-model="form.need"
                        value="必要"
                        :checked="form.need === '必要'">
                        <span class="radio_text">必要</span></label></li>
                      <li><label>
                        <input type="radio" name="need"
                        v-model="form.need"
                        value="不要"
                        :checked="form.need === '不要'">
                        <span class="radio_text">不要</span></label></li>
                    </ul>
                    <p class="alert" v-if="errors.need">{{ errors.need }}</p>
                  </td>
                </tr>
              </tbody>
            </table>

            <div class="button_ctn">
              <ul>
                <li><button @click.prevent="modalClose" class="gray_button modalClose">キャンセル</button></li>
                <li><button @click="sendForm" class="regist_button">上記内容で登録</button></li>
              </ul>
            </div>
            <!-- modal contents ここまで-->

          </div>
        </div>
        <!-- / モーダルコンテンツ -->
      </div>
    </div>
    <!-- / モーダルコンテンツ -->
  </Teleport>
</template>

<script setup>
import { reactive, computed } from 'vue'
import { forEach, cloneDeep } from 'lodash'
import APIClient from '@/store/repository/APIClient'
import * as yup from 'yup'
import moment from "moment"
import { FormValidate } from '@/helper/FormValidate'

import {
  modalOpen,
  modalClose,
} from '@/helper/modal'

import ApiConst from '@/const/api'

import { isShowable as isShowUI } from '@/helper/indexStatus'

const api = new APIClient()

const formInit = {
  application_id: null,
  need: '',
}

const form = reactive(cloneDeep(formInit))
const currentData = reactive(cloneDeep(formInit))

const isNew = computed(() => {
  return !currentData.application_id
})

const uploadLabel = computed(() => {
  return !isNew.value ? '変更' : '新規登録'
})

const fetch = async () => {
  try {
    const query = {
      form_no: 4,
      application_div: "extra_comm2",
    }
    const fetchApi = await api.get(ApiConst.API_APP_LIST, query)
    const res = fetchApi.response
    const errors = res.data?.error_results
    if (errors) {
      throw new Error('Not found.')
    }
    const data = res.data.results.list.map((el) => {
      return {
        application_id: el.application_id,
        input_date: moment(el.updated_at).format("YY-MM-DD"),
        seq_no: el.seq_no,
        ...el.json_data
      }
    })[0]
    forEach(currentData, (v, k) => {
      currentData[k] = data[k]
    })
    forEach(form, (v, k) => {
      form[k] = data[k] ?? formInit[k]
    })
  } catch (e) {
    console.log(e)
    return false
  }
}
fetch()

const editHandler = (event) => {
  modalOpen(event)
}

const scheme = {
  need: yup.string().trim().required('必須の項目です。'),
}
const validate = new FormValidate(scheme, form)
const errors = validate.errors
const sendForm = async () => {
  try {
    if ((Object.keys(await validate.getErrorObject()).length)) {
      throw new Error('入力項目に不備があります。')
    }
    const params = cloneDeep(form)
    const application_id = params.application_id
    delete params.application_id
    const operation = application_id ? "R" : "I"
    const query = operation === "I"
      ? { operation, application_div: "extra_comm2", form_no: 4, json_data: params }
      : { operation, application_id, json_data: params }
    const res = await api.post(ApiConst.API_APP_EDIT, query)
    if (res.response.data?.error_results) {
      throw new Error('API Validation Error')
    }
    fetch()
    modalClose()
  } catch (e) {
    return false
  }
}
</script>

<style></style>
