<template>
  <ol class="common_ol" v-if="isNew">
    <li>下記の「登録」ボタンをクリックすると、事前アンケートでご回答いただいた利用希望内容が表示されます。</li>
    <li>「ユニットハウス、スペース渡し、システム」タイプの控室は、下記の「控室用電気使用申込」から使用容量を登録してください。
      <ol class="common_ol">
        <li>複数の控室を利用する場合、各控室で使用する電気容量の合計を登録してください。</li>
        <li>東展示棟の既存諸室は1室あたり2口コンセントが2～4か所あり、100V電源を1.5kwまでご利用いただけます。（電気使用申込をする必要はありません）</li>
        <li>東展示棟の諸室は100V電源を1.5kwまで増設いただけます。ご希望される場合は下記よりお申し込みください。</li>
      </ol>
    </li>
  </ol>
  <ol class="common_ol" v-else>
    <li>下記の内容で申込を受付ました。</li>
    <li>内容の変更をご希望の場合は、事務局までご連絡ください。<br>なお、変更をご希望の場合、物理的にお受けできないことがありますので、予めご了承ください。</li>
    <li>控室の決定通知は、9月上旬に発送いたします。</li>
  </ol>
  <ul class="common_ul">
    <li class="attention_color">控室の使用料は、9月下旬に請求書を発行します。</li>
  </ul>
  <h5>控室申込状況</h5>
  <template v-if="isNew">
  <template v-if="isShowUI">
    <div class="no_entry">登録がありません。「登録」ボタンより登録をお願いします。</div>
    <div><a
      @click.prevent="newHandler"
      href="#form01"
      class="common_add_button modalOpen"
    >登録</a></div>
  </template>
  </template>
  <template v-else>
    <table class="common_table">
      <tbody>
        <tr>
          <th>仕様</th>
          <th>タイプ</th>
          <th class="w15em">使用料</th>
          <th>数量</th>
          <th class="w15em">使用料小計（税込）</th>
        </tr>
        <tr v-for="(v, k) in filteredAnteroomList" :key="k">
          <td>{{ v.category }}</td>
          <td>{{ v.key }}</td>
          <td class="ta_right">{{ v.priceUnit }}</td>
          <td class="ta_right">{{ v.qtyUnit }}</td>
          <td class="ta_right">{{ v.totalUnit }}</td>
        </tr>
        <tr>
          <th colspan="3">合計</th>
          <td class="ta_right">{{ totalQty.toLocaleString() }}</td>
          <td class="ta_right">{{ totalPrice.toLocaleString() }} 円</td>
        </tr>
      </tbody>
    </table>
  </template>
  <Teleport to="body">
    <!-- モーダルコンテンツ / -->
    <div class="overlay" id="form01">
      <div id="modal_content" class="modal_contents w80">
        <div class="modalClose_ctn"><a @click.prevent="modalClose" class="modalClose"></a></div>
        <!-- modal contents ここから-->
        <div class="entry_title">
          <h4>出展者用控室申込</h4>

          <h5>控室申込状況&nbsp;&nbsp;{{uploadLabel}}</h5>

          <div class="entry_modal_ctn">
            <ol class="common_ol">
              <li>事前アンケートでご回答いただいた内容と間違いがないことを確認の上、「上記内容で登録」ボタンをクリックしてください。</li>
              <li>内容に間違いがある場合、内容の変更をご希望の場合は「キャンセル」ボタンをクリックし、事務局までご連絡ください。<br>
              なお、変更をご希望の場合、物理的にお受けできないことがありますので、予めご了承ください。</li>
            </ol>
            <table class="common_table">
              <tbody>
                <tr>
                  <th>仕様</th>
                  <th>タイプ</th>
                  <th class="w15em">使用料</th>
                  <th>数量</th>
                  <th class="w15em">使用料小計（税込）</th>
                </tr>
                <template v-for="(val, key) in anteroomGroup" :key="key">
                <tr v-for="(v, k) in val" :key="k">
                  <td v-if="k === 0" :rowspan="val.length">{{ key }}</td>
                  <td>{{ v.key }}</td>
                  <td class="ta_right">{{ v.priceUnit }}</td>
                  <td class="ta_right">{{ v.qtyUnit }}</td>
                  <td class="ta_right">{{ v.totalUnit }}</td>
                </tr>
                </template>
                <tr>
                  <th colspan="3">合計</th>
                  <td class="ta_right">{{ totalQty.toLocaleString() }}</td>
                  <td class="ta_right">{{ totalPrice.toLocaleString() }} 円</td>
                </tr>
              </tbody>
            </table>

            <div class="button_ctn">
              <ul>
                <li><button @click="modalClose" class="gray_button modalClose">キャンセル</button></li>
                <li><button
                  @click="sendForm"
                  class="regist_button"
                >上記内容で登録</button></li>
              </ul>
            </div>
            <!-- modal contents ここまで-->

          </div>
        </div>
        <!-- / モーダルコンテンツ -->
      </div>
    </div>
    <!-- / モーダルコンテンツ -->
  </Teleport>
</template>

<script setup>
import { reactive, computed } from 'vue'
import { forEach, cloneDeep, chain } from 'lodash'

import APIClient from '@/store/repository/APIClient'
import moment from "moment"
import { getApplicationStatus } from '@/helper/anteroom'

import {
  modalOpen,
  modalClose,
} from '@/helper/modal'

import {
  isValue
} from '@/helper/index'

import ApiConst from '@/const/api'

import { isShowable as isShowUI } from '@/helper/indexStatus'

const anteroomList = getApplicationStatus().map(el => {
  el.priceUnit = `${el.price?.toLocaleString()} 円`
  el.total = (el.qty ?? 0) * (el.price ?? 0)
  el.qtyUnit = el.qty ? el.qty?.toLocaleString() : ''
  el.totalUnit = el.total ? `${el.total?.toLocaleString()} 円` : ''
  return el
})

const filteredAnteroomList = anteroomList.filter(el => el.qty)

const anteroomGroup = chain(anteroomList)
  .groupBy(el => el.category)
  .value()

const totalQty = anteroomList.reduce((sum, el) => isValue(el.qty) ? sum + el.qty : sum, 0)
const totalPrice = anteroomList.reduce((sum, el) => isValue(el.total) ? sum + el.total : sum, 0)

const api = new APIClient()

const formInit = {
  application_id: null,
}

const currentData = reactive(cloneDeep(formInit))

const isNew = computed(() => {
  return !currentData.application_id
})

const uploadLabel = computed(() => {
  return !isNew.value ? '変更' : '新規登録'
})

const fetch = async () => {
  try {
    const query = {
      form_no: 1,
      application_div : "staff_anteroom",
    }
    const fetchApi = await api.get(ApiConst.API_APP_LIST, query)
    const res = fetchApi.response
    const errors = res.data?.error_results
    if (errors) {
      throw new Error('Not found.')
    }
    const data = res.data.results.list.map((el) => {
      return {
        application_id: el.application_id,
        input_date: moment(el.updated_at).format("YY-MM-DD"),
        seq_no: el.seq_no,
        ...el.json_data
      }
    })[0]
    forEach(currentData, (v, k) => {
      currentData[k] = data[k]
    })
  } catch (e) {
    console.log(e)
    return false
  }
}
fetch()

const newHandler = (event) => {
  modalOpen(event)
}

const sendForm = async () => {
  try {
    const operation = "I"
    const query = {operation, application_div : "staff_anteroom", form_no: 1}
    const res = await api.post(ApiConst.API_APP_EDIT, query)
    if (res.response.data?.error_results) {
      throw new Error('API Validation Error')
    }
    fetch()
    modalClose()
  } catch (e) {
    return false
  }
}
</script>

<style></style>
