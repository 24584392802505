import ApiConst from '@/const/api'
import APIClient from '@/store/repository/APIClient'
const api = new APIClient()

export const sortUp = (me, callback) => {
  orderChange(me, 'up', callback)
}

export const sortDown = (me, callback) => {
  orderChange(me, 'down', callback)
}

export const showOrderPrev = order => 0 < order
export const showOrderNext = (order, max) => max > order

export const orderChange = async (me, method, callback) => {
  try {
    const params = {
      application_id: me.application_id,
      updown: method
    }

    await api.post(ApiConst.API_APP_SORT, params)
    callback()
  } catch (e) {
    return false
  }
}
