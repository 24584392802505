<template>
  <div id="admin">
    <header>
      <h1>JAIS 2025 出展者登録DB&nbsp;&nbsp;管理者用ページ</h1>
    </header>
    <article>
      <h2> 管理者アカウント管理</h2>
      <div id="form_ctn" class="form_box">
        <h3>管理者アカウントの編集</h3>
        <table id="second_search_result_table" class="common_table">
          <thead></thead>
          <tbody>
            <template v-for="(el, index) in account_list" :key="index">
            <tr>
              <td><router-link :to="{name:'AdminAccountEdit', params: {id: el.admin_id } }">{{ el.json_data?.admin_name }}</router-link></td>
            </tr>
            </template>
          </tbody>
          <tfoot>
            <tr>
              <td></td>
            </tr>
          </tfoot>
        </table>

        <div class="button_ctn">
          <button class="submit" @click="router.push({ name: 'AdminAccountEdit' })">
            新規アカウントの追加
          </button>
        </div>

        <ul class="common_list_horizontal">
                    <li>
                        <router-link :to="{ name: 'AdminIndex' }" custom v-slot="{ navigate }">
                            <button @click="navigate" class="common_button gray_button">
                                管理メニュートップに戻る
                            </button>
                        </router-link>
                    </li>
                </ul>
        
      </div>

      <ul class="common_list_horizontal">
                    <li>
                        <router-link :to="{ name: 'AdminIndex' }" custom v-slot="{ navigate }">
                            <button @click="navigate" class="common_button gray_button">
                                管理メニュートップに戻る
                            </button>
                        </router-link>
                    </li>
                </ul>
                
    </article>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { useRouter } from 'vue-router'
import APIClient from '@/store/repository/APIClient'

const router = useRouter();
const api = new APIClient()

let account_list = ref([])

const fetch = async () => {
  try {
    const fetchApi = await api.get('/api/admin/adminaccount/list')
    const res = fetchApi.response
    const errors = res.data?.error_results
    if (errors) {
      throw new Error('Not found.')
    }
    account_list.value = res.data.results.list
  } catch (e) {
    console.error(e.message)
  }
}

fetch()

</script>

<style></style>
