export default {
  namespaced: true,
  state: {
  },
  mutations: {
    token(state, payload) {
      state.token = payload
    },
    limit_datetime(state, payload) {
      state.limit_datetime = payload
    },
    refresh_token(state, payload) {
      state.refresh_token = payload
    },
    limit_datetime_refresh_token(state, payload) {
      state.limit_datetime_refresh_token = payload
    },
    
  },
}
