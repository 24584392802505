import ApiConst from '@/const/api'
import APIClient from '@/store/repository/APIClient'
import moment from "moment"
import { sleep } from '@/helper/index'
const api = new APIClient()

const getCache = (state) => {
  try {
    const updateAt = state.updated_at
    if (!updateAt) {
      throw new Error('No updated data available.')
    }
    const now = moment()
    const diff = moment(updateAt).add(1, 's')
    if (diff < now) {
      throw new Error('Update data is out of date.')
    }
    return state.p_list
  } catch (e) {
    console.log(e)
    return false
  }
}

export default {
  namespaced: true,
  state: {
    updating: false,
    updated_at: '',
    p_list: {},
  },
  mutations: {
    updating(state, payload) {
      state.updating = payload
    },
    updated_at(state, payload) {
      state.updated_at = payload
    },
    p_list(state, payload) {
      state.p_list = payload
    },
  },
  actions: {
    async update({ state, commit }, useCashe = true) {
      if (useCashe) {
        const cachedStatus = getCache(state)
        if (cachedStatus) {
          commit('p_list', cachedStatus)
          return
        }

        if (state.updating) {
          // キャッシュ更新時は更新を待つ、指定回数後
          // ステータスが変わらなければ、ストアを返して終了
          const loopMax = 10
          for (let i = 0; i <= loopMax; i++) {
            await sleep(100)
            if (!state.updating) {
              return getCache(state)
            }
            if (i >= loopMax) {
              // 更新完了ステータス
              commit('updating', false)
              return getCache(state)
            }
          }
        }
      }

      // 更新中ステータス
      commit('updating', true)

      try {
        const fetchApi = await api.get(ApiConst.API_EXHIBITOR_PARKING_CARD_P_LIST)
        console.log(fetchApi)
        const res = fetchApi.response
        const errors = res.data?.error_results
        if (errors) {
          throw new Error('Not found.')
        }
        commit('p_list', res?.data?.results)
        commit('updated_at', moment())
        commit('updating', false)
      } catch (e) {
        console.log(e)
        commit('updating', false)
        return false
      }
    },
  }
}
