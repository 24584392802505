<template>
  <div id="admin">
    <header>
      <h1>JAIS 2025 出展者登録DB&nbsp;&nbsp;管理者用ページ</h1>
    </header>
    <article>
      <h2>{{ getPageName }}</h2>
      <div id="form_ctn" class="form_box">
        <ErrorComponent></ErrorComponent>

        <AdminNav :categoryTop="false" :category="4" :pageName="pageName" />
        <h3>各種申込ステータス</h3>

        <div id="form_ctn" class="form_box">
          <h3>{{ pageName }}の申請状況</h3>

          <table class="application_status">
            <thead>
              <tr>
                <th>項目</th>
                <th>締切</th>
                <th>ステータス</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(li, key) in list" :key="key">
                <td>{{ getNameByKey(key) }}</td>
                <td>9/4(火)</td>
                <td>
                  <DisplayTagSimple
                    :status="li.status"
                  />
                </td>
              </tr>
            </tbody>
          </table>

        </div>

      </div>
    </article>
  </div>
</template>

<script setup>
import AdminNav from "@/components/AdminNav"
import { useRoute } from 'vue-router'
import { ref } from "vue";
import APIClient from '@/store/repository/APIClient'
import ErrorComponent from '@/components/ErrorComponent.vue'

import ApiConst from '@/const/api'
import Applications from '@/const/applications'

import DisplayTagSimple from '@/views/p3/IndexStatus/DisplayIndexStatusTagSimple'

const getNameByKey = (key) => {
  const map = Applications.list
  return map[key] ?? ''
}

const route = useRoute()
const pageID = route.params.id
const pageName = route.params.name

const api = new APIClient()

let list = ref([])

const fetch = async (exhibitor_id) => {
  const query = {
    exhibitor_id
  }
  try {
    const fetchApi = await api.get(ApiConst.API_ADMIN_APPLICATION_STATUS_STATUS_LIST, query)
    const res = fetchApi.response
    const errors = res.data?.error_results
    if (errors) {
      throw new Error('Not found.')
    }
    list.value = res.data.results
  } catch (e) {
    console.error(e.message)
  }
}

if (pageID) fetch(pageID)
</script>

<style></style>
