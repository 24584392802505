<template>
  <div id="admin">
    <header>
      <h1>JAIS 2025 出展者登録DB&nbsp;&nbsp;管理者用ページ</h1>
    </header>
    <article>
      <h2>各出展者の各種申込ステータス確認</h2>
      <div id="form_ctn" class="form_box">
        <AdminNav category="2" :categoryTop="true" pageName="各出展者の各種申込ステータス確認" />
        <h3>出展者の検索</h3>

        <table class="common_table">
          <tbody>
            <tr>
              <th>小間番号</th>
              <td><input type="text" class="quarter" v-model="form.koma_bango"></td>
            </tr>
            <tr>
              <th>出展者名</th>
              <td><input type="text" class="quarter" v-model="form.exhibitor_name"> を含む</td>
            </tr>
            <tr>
              <th>出展カテゴリー</th>
              <td>
                <ul class="flex_list2">
                  <MyCheckboxVertical
                    v-model="form.array_category_disp"
                    name="category_disp"
                    :options="category_disp_options"
                  />
                </ul>

              </td>
            </tr>
            <tr>
              <th>ホール</th>
              <td>
                <ul class="common_list_horizontal_iblock">
                  <MyCheckboxVertical
                    v-model="form.array_hall_div"
                    name="hall_div"
                    :options="hall_div_options"
                  />
                </ul>
              </td>
            </tr>

          </tbody>
        </table>
        <ul class="common_list_horizontal">
          <li>
            <router-link
              :to="{name: 'AdminIndex'}"
              custom
              v-slot="{ navigate }"
            >
              <button
                @click="navigate"
                class="common_button gray_button"
              >
                管理メニュートップに戻る
              </button>
            </router-link>
          </li>
          <li><button class="common_button" @click="sendForm">出展者検索開始</button></li>
          <!--<li><button class="common_button" onclick="location.href='application_result/exhibition/anchor_bolt.html'">出展者検索開始</button></li>-->
        </ul>

      </div>
    </article>
  </div>
</template>

<script setup>
import AdminNav from "@/components/AdminNav"
import { computed, reactive } from "vue"
import APIClient from '@/store/repository/APIClient'
import { cloneDeep, map } from 'lodash'
import { store } from '@/store'

import { useRouter } from 'vue-router'
const router = useRouter()

import MyCheckboxVertical from '@/components/MyCheckboxVertical'

const api = new APIClient()

const category_disp_options = computed(() =>
  map(store.state.disicion.category_disp_options, (v, k) => {
    return {value: k, label: v}
  })
)
const hall_div_options = computed(() =>
  map(store.state.disicion.hall_div_options, (v, k) => {
    return {value: k, label: v}
  })
)
const fetchConfig = async () => {
  const res = await api.getConfig()
  store.commit('disicion/admin_application_options', res.data.results.admin_application)
  store.commit('disicion/category_disp_options', res.data.results.category_disp)
  store.commit('disicion/hall_div_options', res.data.results.hall_div)
}
fetchConfig()

const formInit = {
  admin_application: "",
  koma_bango: "",
  exhibitor_name: "",
  array_category_disp: "",
  array_hall_div: "",
  unconfirmed_flag: "",
  confirmed_date: "",
}
const form = reactive(formInit)
const sendForm = async () => {
  try {
    const query = cloneDeep(form)
    delete query.admin_application
    router.push({
      name: 'AdminStatusResult',
      params: { admin_application: form.admin_application },
      query
    })
  } catch (e) {
    return false
  }
}
</script>

<style></style>
