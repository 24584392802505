import { reactive } from 'vue'
import { reduce, cloneDeep } from 'lodash'
import { isValue } from '@/helper/index'
import * as yup from 'yup'

export class FormValidate {
  constructor(scheme, form) {
    this._scheme = scheme
    this._form = form
    this._validations = yup.object().shape(scheme)
    this._errors = reactive(reduce(cloneDeep(scheme),  (e, v, k) => {
      e[k] = ''
      return e
    }, {}))
  }

  get errors() {
    return this._errors
  }

  valid (f) {
    this._validations.validateAt(f, this._form).then(() => {
      this._errors[f] = ''
    }).catch(err => {
      this._errors[f] = err.message
    })
  }

  async getErrorObject () {
    Object.keys(this._errors).forEach(k => {
      this.valid(k)
    })
    await new Promise(resolve => setTimeout(resolve, 5))
    const errorResults = reduce(this._errors, (e, v, k) => {
      if (isValue(v)) {
        e[k] = v
      }
      return e
    }, {})
    return errorResults
  }
}
