<template>
  <header>
    <h1>JAPAN MOBILITY SHOW 2025&emsp;出展手続きサイト・出展者専用サイト</h1>
  </header>
  <article>
    <h2>申込内容の確認</h2>




    <div id="form_ctn" class="form_box">

      <ErrorComponent></ErrorComponent>

      <SecondSteps step="2" />
      <h3>出展分類</h3>

      <h4>出展カテゴリー</h4>
      {{ category_div_label }}
      <div v-if="!appeal_information_visible" style="margin-top:1.5rem;">
        <h4>主な製品/サービス情報</h4>
        <p>{{ appeal_information }}</p>
       
      </div>
      <div class="button_ctn">
          <router-link to="/second/form01" class="submit">上記内容を修正</router-link>
        </div>
    </div>

    <div id="form_ctn" class="form_box">
      <h3>申込小間面積</h3>

      <div v-if="indoorVisible">
        <h4>乗用車、商用車、二輪車、車体（屋内）部門</h4>
        <p>{{ booth_area }}m<sup>2</sup> </p>
        <dl class="exhibition_fee_list">
          <dt>出展料</dt>
          <dd>¥ {{ calced_exhibition_fee }}<span>（申込面積×@{{ calced_unit_price }}）（消費税込）</span></dd>
        </dl>
        <dl class="exhibition_fee_list">
          <dt>出展申込金</dt>
          <dd>¥ {{ calced_application_fee }}<span>（申込面積の50%×@{{ calced_unit_price }}）（消費税込）</span></dd>
        </dl>
      </div>

      <div v-if="outdoorVisible">
        <h4>車体（屋外）部門</h4>
        <p>{{ booth_area }}m<sup>2</sup> </p>
        <dl class="exhibition_fee_list">
          <dt>出展料</dt>
          <dd>¥ {{ calced_exhibition_fee }}<span>（申込面積×@{{ calced_unit_price }}）（消費税込）</span></dd>
        </dl>
        <dl class="exhibition_fee_list">
          <dt>出展申込金</dt>
          <dd>¥ {{ calced_application_fee }}<span>（申込面積の50%×@{{ calced_unit_price }}）（消費税込）</span></dd>
        </dl>
      </div>

      <div v-if="partsMemberVisible">
        <h4>部品・機械器具部門（JAPIA、JAMTAの正会員）</h4>
        <p>{{ booth_area }}小間 </p>
        <dl class="exhibition_fee_list">
          <dt>出展料</dt>
          <dd>¥ {{ calced_exhibition_fee }}<span>（申込面積×@{{ calced_unit_price }}）（消費税込）</span></dd>
        </dl>
        <dl class="exhibition_fee_list">
          <dt>出展申込金</dt>
          <dd>¥ {{ calced_application_fee }}<span>（申込面積の50%×@{{ calced_unit_price }}）（消費税込）</span></dd>
        </dl>
      </div>

      <div v-if="partsVisible">
        <h4>部品・機械器具部門（JAPIA、JAMTAの非会員）</h4>
        <p>{{ booth_area }}小間 </p>
        <dl class="exhibition_fee_list">
          <dt>出展料</dt>
          <dd>¥ {{ calced_exhibition_fee }}<span>（申込面積×@{{ calced_unit_price }}）（消費税込）</span></dd>
        </dl>
        <dl class="exhibition_fee_list">
          <dt>出展申込金</dt>
          <dd>¥ {{ calced_application_fee }}<span>（申込面積の50%×@{{ calced_unit_price }}）（消費税込）</span></dd>
        </dl>
      </div>

      <div v-if="mobilityMemberVisible">
        <h4>モビリティ関連</h4>
        <p>{{ booth_area }}小間 </p>
        <dl class="exhibition_fee_list">
          <dt>出展料</dt>
          <dd>¥ {{ calced_exhibition_fee }}<span>（申込面積×@{{ calced_unit_price }}）（消費税込）</span></dd>
        </dl>
        <dl class="exhibition_fee_list">
          <dt>出展申込金</dt>
          <dd>¥ {{ calced_application_fee }}<span>（申込面積の50%×@{{ calced_unit_price }}）（消費税込）</span></dd>
        </dl>
      </div>

      <div v-if="mobilityVisible">
        <h4>モビリティ関連</h4>
        <p>{{ booth_area }}小間 </p>
        <dl class="exhibition_fee_list">
          <dt>出展料</dt>
          <dd>¥ {{ calced_exhibition_fee }}<span>（申込面積×@{{ calced_unit_price }}）（消費税込）</span></dd>
        </dl>
        <dl class="exhibition_fee_list">
          <dt>出展申込金</dt>
          <dd>¥ {{ calced_application_fee }}<span>（申込面積の50%×@{{ calced_unit_price }}）（消費税込）</span></dd>
        </dl>
      </div>

      <div v-if="kidzaniaMemberVisible">
        <h4>Out of KidZania in JMS 2025（JAMA、JAPIA、JABIA、JAMTA、JAIAの正会員）</h4>
        <p>{{ booth_area }}小間 </p>
        <dl class="exhibition_fee_list">
          <dt>出展料</dt>
          <dd>¥ {{ calced_exhibition_fee }}<span>（申込面積×@{{ calced_unit_price }}）（消費税込）</span></dd>
        </dl>
        <dl class="exhibition_fee_list">
          <dt>出展申込金</dt>
          <dd>¥ {{ calced_application_fee }}<span>（申込面積の50%×@{{ calced_unit_price }}）（消費税込）</span></dd>
        </dl>
      </div>

      <div v-if="kidzaniaVisible">
        <h4>Out of KidZania in JMS 2025（JAMA、JAPIA、JABIA、JAMTA、JAIAの非会員）</h4>
        <p>{{ booth_area }}小間 </p>
        <dl class="exhibition_fee_list">
          <dt>出展料</dt>
          <dd>¥ {{ calced_exhibition_fee }}<span>（申込面積×@{{ calced_unit_price }}）（消費税込）</span></dd>
        </dl>
        <dl class="exhibition_fee_list">
          <dt>出展申込金</dt>
          <dd>¥ {{ calced_application_fee }}<span>（申込面積の50%×@{{ calced_unit_price }}）（消費税込）</span></dd>
        </dl>
      </div>


      <div v-if="otherVisible">
        <h4>その他部門</h4>
        <p>{{ booth_area }}小間 </p>
        <dl class="exhibition_fee_list">
          <dt>出展料</dt>
          <dd>¥ {{ calced_exhibition_fee }}<span>（申込面積×@{{ calced_unit_price }}）（消費税込）</span></dd>
        </dl>
        <dl class="exhibition_fee_list">
          <dt>出展申込金</dt>
          <dd>¥ {{ calced_application_fee }}<span>（申込面積の50%×@{{ calced_unit_price }}）（消費税込）</span></dd>
          <dd>¥ 7,000,未実装<span>（申込面積の50%×@{{ calced_unit_price }}）（消費税込）</span></dd>
        </dl>
      </div>






      <h3>共同出展/隣接配置</h3>
      <p>{{ joint_adjacent_label }}</p>
      <div v-if="this.joint_adjacent == 1">
        <h4>共同出展</h4>
        <p>{{ joint_exhibition }}</p>
        <h4>隣接配置</h4>
        <p>{{ adjacent_exhibition }}</p>

      </div>
      <div class="button_ctn">
        <router-link to="/second/form02" class="submit">上記内容を修正</router-link>
      </div>
    </div>

    <div id="form_ctn" class="form_box">
      <h3>出展者会社名</h3>

      <h4>和文名称</h4>
      {{ exhibitor_name }}
      <h4>フリガナ</h4>
      {{ exhibitor_name_kana }}
      <h4>英文名称</h4>
      <p>{{ exhibitor_name_en }}</p>

      <h3>出展者本社所在地</h3>
      <h4>郵便番号</h4>
      <p>{{ postCode }}</p>
      <h4>和文所在地</h4>
      <p>{{ address_pref_label }}{{ address_jp }}</p>
      <h4>英文所在地</h4>
      <p>{{ address_en }}</p>
      <h4>国・地域</h4>

      <p>{{ country_main_label }} {{ country_other_label }} </p>

      <h3>法人代表者</h3>
      <h4>役職</h4>
      <p>{{ representative_position }}</p>
      <h4>氏名</h4>
      <p>{{ representative_last_name }}&emsp;{{ representative_first_name }}</p>

      <div v-if="brand1_name_jp !== '' || brand1_name_en !== ''">
      <h3>出展する取り扱いブランド名</h3>
     
        <h4>出展する取扱ブランド名1</h4>
        <div class="border_box">
          <h5>和文名</h5>
          <p>{{ brand1_name_jp }}</p>
          <h5>英文名</h5>
          <p>{{ brand1_name_en }}</p>
          <h5>国・地域</h5>
          <p>{{ brand1_country_main_label }} {{ brand1_country_other_label }}</p>
        </div>
      </div>

      <div v-if="brand2_name_jp !== '' || brand2_name_en !== ''">
        <h4>出展する取扱ブランド名2</h4>
        <div class="border_box">
          <h5>和文名</h5>
          <p>{{ brand2_name_jp }}</p>
          <h5>英文名</h5>
          <p>{{ brand2_name_en }}</p>
          <h5>国・地域</h5>
          <p>{{ brand2_country_main_label }} {{ brand2_country_other_label }}</p>
        </div>
      </div>
      <div v-if="brand3_name_jp !== '' || brand3_name_en !== ''">
        <h4>出展する取扱ブランド名3</h4>
        <div class="border_box">
          <h5>和文名</h5>
          <p>{{ brand3_name_jp }}</p>
          <h5>英文名</h5>
          <p>{{ brand3_name_en }}</p>
          <h5>国・地域</h5>
          <p>{{ brand3_country_main_label }} {{ brand3_country_other_label }}</p>
        </div>
      </div>
      <div v-if="brand4_name_jp !== '' || brand4_name_jp !== ''">
        <h4>出展する取扱ブランド名4</h4>
        <div class="border_box">
          <h5>和文名</h5>
          <p>{{ brand4_name_jp }}</p>
          <h5>英文名</h5>
          <p>{{ brand4_name_en }}</p>
          <h5>国・地域</h5>
          <p>{{ brand4_country_main_label }} {{ brand4_country_other_label }}</p>
        </div>
      </div>
      <div v-if="brand5_name_jp !== '' || brand5_name_en !== ''">
        <h4>出展する取扱ブランド名5</h4>
        <div class="border_box">
          <h5>和文名</h5>
          <p>{{ brand5_name_jp }}</p>
          <h5>英文名</h5>
          <p>{{ brand5_name_en }}</p>
          <h5>国・地域</h5>
          <p>{{ brand5_country_main_label }} {{ brand5_country_other_label }}</p>
        </div>
      </div>

      <div class="button_ctn">
        <router-link to="/second/form03" class="submit">上記内容を修正</router-link>
      </div>
    </div>
    <div id="form_ctn" class="form_box">

      <h3>出展代理者または連絡先会社名（出展会社と異なる場合）</h3>

      <h4>和文名称</h4>

      <p>{{ deputy_exhibitor_name }}<span v-if="deputy_exhibitor_name == ''">{{ exhibitor_name }}</span></p>

      <h4>フリガナ</h4>
      <p>{{ deputy_exhibitor_name_kana }}<span v-if="deputy_exhibitor_name_kana == ''">{{ exhibitor_name_kana }}</span>
      </p>
      <h4>英文名称</h4>
      <p>{{ deputy_exhibitor_name_en }}<span v-if="deputy_exhibitor_name_en == ''">{{ exhibitor_name_en }}</span></p>

      <h3>法人代表者（出展会社と異なる場合）</h3>
      <h4>役職</h4>
      <p>{{ deputy_representative_position }}<span v-if="deputy_representative_position == ''">{{
          representative_position
      }}</span></p>
      <h4>氏名</h4>
      <p>{{ deputy_representative_last_name }}<span v-if="deputy_representative_last_name == ''">{{
          representative_last_name
      }}</span>&emsp;{{ deputy_representative_first_name }}<span v-if="deputy_representative_first_name == ''">{{
            representative_first_name
        }}</span></p>


      <h3>主担当者</h3>
      <h4>部署</h4>
      <p>{{ staff_department }}</p>
      <h4>役職</h4>
      <p>{{ staff_position }}</p>
      <h4>氏名</h4>
      <p>{{ staff_last_name }}&emsp;{{ staff_first_name }}</p>


      <h4>郵便番号</h4>
      <p>{{ manager_postCode }}</p>
      <h4>和文所在地</h4>
      <p>{{ manager_address_pref_label }}{{ manager_address_jp }}</p>
      <h4>英文所在地</h4>
      <p>{{ manager_address_en }}</p>
      <h4>電話番号</h4>
      <p>{{ staff_tel }}</p>
      <h4>メールアドレス</h4>
      <p>{{ staff_email }}</p>

      <div class="v-if_box" v-for="(deputy, index) in deputies" :key="deputy.key">
        <h3>副担当者{{ index + 1 }}</h3>


        <h4>部署</h4>
        <p>{{ deputy.department }}</p>
        <h4>役職</h4>
        <p>{{ deputy.position }}</p>
        <h4>氏名</h4>
        <p>{{ deputy.last_name }}&emsp;{{ deputy.first_name }}</p>


        <h4>郵便番号</h4>
        <p>{{ deputy.postCode }}</p>
        <h4>和文所在地</h4>
        <p>{{ deputy.address_pref_label }}{{ deputy.address_jp }}</p>
        <h4>英文所在地</h4>
        <p>{{ deputy.address_en }}</p>
        <h4>電話番号</h4>
        <p>{{ deputy.tel }}</p>
        <h4>メールアドレス</h4>
        <p>{{ deputy.email }}</p>
      </div>
      <div class="button_ctn">
        <router-link to="/second/form04" class="submit">上記内容を修正</router-link>
      </div>


    </div>

    <div class="button_ctn">
      <button :class="sendStatus" @click="registComplete" v-html="sendLabel"></button>
    </div>

  </article>
</template>

<script>
import APIClient from '@/store/repository/APIClient'
import ErrorComponent from '@/components/ErrorComponent.vue';
import {
  indoorVisible,
  outdoorVisible,
  partsMemberVisible,
  partsVisible,
  mobilityMemberVisible,
  mobilityVisible,
  kidzaniaMemberVisible,
  kidzaniaVisible,
  otherVisible
} from '@/helper/categoryVisible';
import {
  perseIntOrNull,
  isValue
} from '@/helper/index';
import ADDRESS from '@/const/address_elements.js'
import { sleep } from '@/helper/index'
import SecondSteps from './SecondSteps.vue';


const defaultSendLabel = "この内容で登録して<br>出展申込書のダウンロード"


export default {
  components: {
    ErrorComponent,
    SecondSteps,
  },
  setup() {

  },
  data() {
    return {
      postCode: '',
      brand1_name_jp: '',
      brand1_name_en: '',
      brand1_country_main: '',
      brand1_country_other: '',
      brand2_name_jp: '',
      brand2_name_en: '',
      brand2_country_main: '',
      brand2_country_other: '',
      brand3_name_jp: '',
      brand3_name_en: '',
      brand3_country_main: '',
      brand3_country_other: '',
      brand4_name_jp: '',
      brand4_name_en: '',
      brand4_country_main: '',
      brand4_country_other: '',
      brand5_name_jp: '',
      brand5_name_en: '',
      brand5_country_main: '',
      brand5_country_other: '',
      deputy_exhibitor_name: '',
      deputy_exhibitor_name_kana: '',
      deputy_exhibitor_name_en: '',
      deputy_representative_position: '',
      deputy_representative_last_name: '',
      deputy_representative_first_name: '',
      address_pref: this.$store.state.entrydata.address_pref,
      manager_address_pref: this.$store.state.entrydata.manager_address_pref,
      address_pref_options: ADDRESS.address_pref_options,
      sendStatus: 'submit',
      sendLabel: defaultSendLabel,
    }
  },
  async created() {
    this.api = new APIClient()
    const res = await this.api.getConfig()

    this.$store.commit('disicion/membership_category_options', res.data.results.membership_category_options)
    this.$store.commit('disicion/category_div_options', res.data.results.category_div_options)
    this.$store.commit('disicion/joint_adjacent_options', res.data.results.joint_adjacent_options)
    this.$store.commit('disicion/country_main_options', res.data.results.country_main_options)
    this.$store.commit('disicion/country_other_options', res.data.results.country_other_options)
  },
  mounted() {

    this.inputStoreData('exhibitor_name')
    this.inputStoreData('exhibitor_name_kana')
    this.inputStoreData('exhibitor_name_en')
    this.inputStoreData('staff_last_name')
    this.inputStoreData('staff_first_name')
    this.inputStoreData('staff_tel')
    this.inputStoreData('staff_email')
    this.inputStoreData('appeal_information')

    this.inputStoreData('postCode')
    this.inputStoreData('address_pref')
    this.inputStoreData('address_jp')
    this.inputStoreData('address_en')
    this.inputStoreData('country_main')
    this.inputStoreData('country_other')
    this.inputStoreData('representative_position')
    this.inputStoreData('representative_last_name')
    this.inputStoreData('representative_first_name')
    this.inputStoreData('brand1_name_jp')
    this.inputStoreData('brand1_name_en')
    this.inputStoreData('brand1_country_main')
    this.inputStoreData('brand1_country_other')
    this.inputStoreData('brand2_name_jp')
    this.inputStoreData('brand2_name_en')
    this.inputStoreData('brand2_country_main')
    this.inputStoreData('brand2_country_other')
    this.inputStoreData('brand3_name_jp')
    this.inputStoreData('brand3_name_en')
    this.inputStoreData('brand3_country_main')
    this.inputStoreData('brand3_country_other')
    this.inputStoreData('brand4_name_jp')
    this.inputStoreData('brand4_name_en')
    this.inputStoreData('brand4_country_main')
    this.inputStoreData('brand4_country_other')
    this.inputStoreData('brand5_name_jp')
    this.inputStoreData('brand5_name_en')
    this.inputStoreData('brand5_country_main')
    this.inputStoreData('brand5_country_other')


    //pre
    this.inputStoreData('membership_category')
    //form01
    this.inputStoreData('category_div')

    //form02
    this.inputStoreData('booth_area')

    this.inputStoreData('joint_adjacent')
    this.inputStoreData('joint_exhibition')
    this.inputStoreData('adjacent_exhibition')
    this.inputStoreData('calced_application_fee')
    this.inputStoreData('calced_exhibition_fee')
    this.inputStoreData('calced_unit_price')
    this.inputStoreData('calced_unit')

    //form04
    this.inputStoreData('deputy_exhibitor_name')
    this.inputStoreData('deputy_exhibitor_name_kana')
    this.inputStoreData('deputy_exhibitor_name_en')
    this.inputStoreData('deputy_representative_position')
    this.inputStoreData('deputy_representative_last_name')
    this.inputStoreData('deputy_representative_first_name')
    this.inputStoreData('manager_postCode')
    this.inputStoreData('manager_address_pref')
    this.inputStoreData('manager_address_jp')
    this.inputStoreData('manager_address_en')
    this.inputStoreData('staff_department')
    this.inputStoreData('staff_position')


  },
  methods: {
    inputStoreData(key) {
      if (!this.$store.state.entrydata[key]) return;
      this[key] = this.$store.state.entrydata[key]
    },
    async registComplete() {

      try {
        const category_div_store = this.$store.state.entrydata.category_div
        const category_div1 = category_div_store.category_div.join(',')
        const category_div2 = category_div_store["category_div_" + category_div1]

        const query = {
          //左がAPI仕様書の名前 右がdataの名前
          membership_category: this.membership_category,
          category_div1: perseIntOrNull(category_div1),
          category_div2: (category_div2 === "undefined")
                ? null
                : Array.isArray(category_div2)
                  ? category_div2.join(',')
                  : category_div2,
          appeal_information: this.appeal_information,
          order_area: perseIntOrNull(this.booth_area),
          order_tanka: perseIntOrNull(this.calced_unit_price),
          order_unit: this.calced_unit,
          order_kingaku: perseIntOrNull(this.calced_exhibition_fee),
          order_deposit: perseIntOrNull(this.calced_application_fee),
          joint_flag: perseIntOrNull(this.joint_adjacent),
          jointly: this.joint_exhibition,
          adjoin: this.adjacent_exhibition,
          exhibitor_name: this.exhibitor_name,
          exhibitor_name_kana: this.exhibitor_name_kana,
          exhibitor_name_en: this.exhibitor_name_en,
          exhibitor_zip: this.postCode,
          exhibitor_pref: this.address_pref,
          exhibitor_address: this.address_jp,
          exhibitor_address_en: this.address_en,
          exhibitor_country: perseIntOrNull(this.country_main),
          exhibitor_country_other: perseIntOrNull(this.country_other),
          representative_post: this.representative_position,
          representative_last_name: this.representative_last_name,
          representative_first_name: this.representative_first_name,
          brand_name_1: this.brand1_name_jp,
          brand_name_en_1: this.brand1_name_en,
          brand_country_1: perseIntOrNull(this.brand1_country_main),
          brand_country_other_1: perseIntOrNull(this.brand1_country_other),
          brand_name_2: this.brand2_name_jp,
          brand_name_en_2: this.brand2_name_en,
          brand_country_2: perseIntOrNull(this.brand2_country_main),
          brand_country_other_2: perseIntOrNull(this.brand2_country_other),
          brand_name_3: this.brand3_name_jp,
          brand_name_en_3: this.brand3_name_en,
          brand_country_3: perseIntOrNull(this.brand3_country_main),
          brand_country_other_3: perseIntOrNull(this.brand3_country_other),
          brand_name_4: this.brand4_name_jp,
          brand_name_en_4: this.brand4_name_en,
          brand_country_4: perseIntOrNull(this.brand4_country_main),
          brand_country_other_4: perseIntOrNull(this.brand4_country_other),
          brand_name_5: this.brand5_name_jp,
          brand_name_en_5: this.brand5_name_en,
          brand_country_5: perseIntOrNull(this.brand5_country_main),
          brand_country_other_5: perseIntOrNull(this.brand5_country_other),

          agent_name: this.deputy_exhibitor_name,
          agent_name_kana: this.deputy_exhibitor_name_kana,
          agent_name_en: this.deputy_exhibitor_name_en,
          agent_representative_post: this.deputy_representative_position,
          agent_representative_last_name: this.deputy_representative_last_name,
          agent_representative_first_name: this.deputy_representative_first_name,

          staff_department: this.staff_department,
          staff_post: this.staff_position ?? null,
          staff_last_name: this.staff_last_name,
          staff_first_name: this.staff_first_name,
          staff_zip: this.manager_postCode,
          staff_pref: this.manager_address_pref,
          staff_address: this.manager_address_jp,
          staff_address_en: this.manager_address_en,
          staff_tel: this.staff_tel,
          staff_email: this.staff_email,

          staff_department_1: this.deputies?.[0]?.department ?? null,
          staff_post_1: this.deputies?.[0]?.position ?? null,
          staff_last_name_1: this.deputies?.[0]?.last_name ?? null,
          staff_first_name_1: this.deputies?.[0]?.first_name ?? null,
          staff_zip_1: this.deputies?.[0]?.postCode ?? null,
          staff_pref_1: this.deputies?.[0]?.address_pref ?? null,
          staff_address_1: this.deputies?.[0]?.address_jp ?? null,
          staff_address_en_1: this.deputies?.[0]?.address_en ?? null,
          staff_tel_1: this.deputies?.[0]?.tel ?? null,
          staff_email_1: this.deputies?.[0]?.email ?? null,

          staff_department_2: this.deputies?.[1]?.department ?? null,
          staff_post_2: this.deputies?.[1]?.position ?? null,
          staff_last_name_2: this.deputies?.[1]?.last_name ?? null,
          staff_first_name_2: this.deputies?.[1]?.first_name ?? null,
          staff_zip_2: this.deputies?.[1]?.postCode ?? null,
          staff_pref_2: this.deputies?.[1]?.address_pref ?? null,
          staff_address_2: this.deputies?.[1]?.address_jp ?? null,
          staff_address_en_2: this.deputies?.[1]?.address_en ?? null,
          staff_tel_2: this.deputies?.[1]?.tel ?? null,
          staff_email_2: this.deputies?.[1]?.email ?? null,

          staff_department_3: this.deputies?.[2]?.department ?? null,
          staff_post_3: this.deputies?.[2]?.position ?? null,
          staff_last_name_3: this.deputies?.[2]?.last_name ?? null,
          staff_first_name_3: this.deputies?.[2]?.first_name ?? null,
          staff_zip_3: this.deputies?.[2]?.postCode ?? null,
          staff_pref_3: this.deputies?.[2]?.address_pref ?? null,
          staff_address_3: this.deputies?.[2]?.address_jp ?? null,
          staff_address_en_3: this.deputies?.[2]?.address_en ?? null,
          staff_tel_3: this.deputies?.[2]?.tel ?? null,
          staff_email_3: this.deputies?.[2]?.email ?? null,

          staff_department_4: this.deputies?.[3]?.department ?? null,
          staff_post_4: this.deputies?.[3]?.position ?? null,
          staff_last_name_4: this.deputies?.[3]?.last_name ?? null,
          staff_first_name_4: this.deputies?.[3]?.first_name ?? null,
          staff_zip_4: this.deputies?.[3]?.postCode ?? null,
          staff_pref_4: this.deputies?.[3]?.address_pref ?? null,
          staff_address_4: this.deputies?.[3]?.address_jp ?? null,
          staff_address_en_4: this.deputies?.[3]?.address_en ?? null,
          staff_tel_4: this.deputies?.[3]?.tel ?? null,
          staff_email_4: this.deputies?.[3]?.email ?? null,



        }

        this.sendStatus = 'comptele'
        this.sendLabel = "入力を受け付けました。<br>ダウンロードファイルを生成中です。"

        const res = await this.api.post('/api/exhibitor/second_order', query)


        if (res.response.data?.error_results) {
          window.scrollTo({
            top: 0,
            behavior: 'smooth',
          })
          throw new Error('API Validation Error')
        }

        this.sendStatus = 'comptele'
        this.sendLabel = "受付が完了しました<br>ファイルがダウンロードされるまでお待ちください。"

        await sleep(2000)

        await this.api.download(res.response.data.results.url)

      } catch (e) {
        console.error(e.message)
        return false
      }


      this.$router.push({ path: '/second/regist_complete' })
    }
  },
  computed: {
    membership_category() {
      return this.$store.state.entrydata.membership_category
    },
    category_div() {
      return this.$store.state.entrydata.category_div
    },
    category_div_label() {
      const checked_options = Object.entries(this.$store.state.entrydata.category_div).map(value => {
        if (!isValue(value[1])) return false
        const label = value[0]
        const values = typeof value[1] === 'string' ? value[1].split(',') : value[1]
        if (!isValue(values)) return false
        return {
          'label': label,
          'value': values,
        }
      }).filter(Boolean)
      const options = this.$store.state.disicion.category_div_options
      if (!checked_options || !options) return
      const results = checked_options.map(item => {
        const label = item.label
        const values = item.value
        const parentValue = label.replace('category_div_', '')
        const parentObj = options.find(v => v.value === parentValue)
        let opts = options
        if (parentObj) {
          opts = parentObj.options
        }
        const labels = values.map(v => {
          const m = opts.find(f => f.value === v)
          return m.label
        })
        return {
          'label': label,
          'value': labels,
        }
      })
      results.sort((a, b) => {
        return (a.label.length - b.label.length);
      })
      return results.map(v => v.value).join(' - ')//ツナギ文字

    },
    category_div_options() {
      return this.$store.state.disicion.category_div_options
    },

    exhibitor_name() {
      return this.$store.state.entrydata.exhibitor_name
    },
    exhibitor_name_kana() {
      return this.$store.state.entrydata.exhibitor_name_kana
    },
    exhibitor_name_en() {
      return this.$store.state.entrydata.exhibitor_name_en
    },

    country_main() {
      return String(this.$store.state.entrydata.country_main)
    },
    country_main_label() {
      const array = this.$store.state.disicion.country_main_options
      if (!array) return
      if (this.country_main == 99999999) return
      return array.find(item => item.value === this.country_main)?.label
    },
    country_other() {
      return this.$store.state.entrydata.country_other
    },
    country_other_label() {
      const array = this.$store.state.disicion.country_other_options
      if (!array) return
      if (this.country_other == "") return
      return array.find(item => item.value === this.country_other)?.label || ''
    },
    brand1_country_main_label() {
      const array = this.$store.state.disicion.country_main_options
      if (!array) return
      if (this.brand1_country_main == 99999999) return
      return array.find(item => item.value === this.brand1_country_main)?.label
    },
    brand1_country_other_label() {
      const array = this.$store.state.disicion.country_other_options
      if (!array) return
      if (this.brand1_country_other == "") return
      return array.find(item => item.value === this.brand1_country_other)?.label
    },
    brand2_country_main_label() {
      const array = this.$store.state.disicion.country_main_options
      if (!array) return
      if (this.brand2_country_main == 99999999) return
      return array.find(item => item.value === this.brand2_country_main)?.label
    },
    brand2_country_other_label() {
      const array = this.$store.state.disicion.country_other_options
      if (!array) return
      if (!isValue(this.brand2_country_other)) return ''
      return array.find(item => item.value === this.brand2_country_other)?.label || ''
    },
    brand3_country_main_label() {
      const array = this.$store.state.disicion.country_main_options
      if (!array) return
      if (this.brand3_country_main == 99999999) return
      return array.find(item => item.value === this.brand3_country_main)?.label
    },
    brand3_country_other_label() {
      const array = this.$store.state.disicion.country_other_options
      if (!array) return
      if (this.brand3_country_other == "") return
      return array.find(item => item.value === this.brand3_country_other)?.label
    },
    brand4_country_main_label() {
      const array = this.$store.state.disicion.country_main_options
      if (!array) return
      if (this.brand4_country_main == 99999999) return
      return array.find(item => item.value === this.brand4_country_main)?.label
    },
    brand4_country_other_label() {
      const array = this.$store.state.disicion.country_other_options
      if (!array) return
      if (!isValue(this.brand4_country_other)) return ''
      return array.find(item => item.value === this.brand4_country_other)?.label
    },
    brand5_country_main_label() {
      const array = this.$store.state.disicion.country_main_options
      if (!array) return
      if (this.brand5_country_main == 99999999) return
      return array.find(item => item.value === this.brand5_country_main)?.label
    },
    brand5_country_other_label() {
      const array = this.$store.state.disicion.country_other_options
      if (!array) return
      if (!isValue(this.brand5_country_other)) return ''
      return array.find(item => item.value === this.brand5_country_other)?.label
    },


    representative_position() {
      return this.$store.state.entrydata.representative_position
    },
    representative_last_name() {
      return this.$store.state.entrydata.representative_last_name
    },
    representative_first_name() {
      return this.$store.state.entrydata.representative_first_name
    },
    address_pref_label() {
      const array = this.address_pref_options
      return array.find(item => item.value === this.address_pref)?.label
    },
    manager_address_pref_label() {
      const array = this.address_pref_options
      return array.find(item => item.value === this.manager_address_pref)?.label
    },
    deputies() {
      const dups = this.$store.state.entrydata.deputies
      dups?.forEach(dup => {
        dup.address_pref_label = this.address_pref_options.find(item => item.value === dup.address_pref)?.label
      });
      //

      return dups
    },

    joint_adjacent() {
      return String(this.$store.state.entrydata.joint_adjacent)
    },

    joint_adjacent_label() {
      const array = this.$store.state.disicion.joint_adjacent_options
      if (!array) return
      return array.find(item => item.value === this.joint_adjacent)?.label
    },

    indoorVisible() {
      return indoorVisible(this)
    },
    outdoorVisible() {
      return outdoorVisible(this)
    },
    partsMemberVisible() {
      return partsMemberVisible(this)
    },
    partsVisible() {
      return partsVisible(this)
    },
    mobilityMemberVisible() {
      return mobilityMemberVisible(this)
    },
    mobilityVisible() {
      return mobilityVisible(this)
    },
    kidzaniaMemberVisible() {
      return kidzaniaMemberVisible(this)
    },
    kidzaniaVisible() {
      return kidzaniaVisible(this)
    },
    otherVisible() {
      return otherVisible(this)
    },
    appeal_information_visible() {
      const category_div_store = this.$store.state.entrydata.category_div
      if (category_div_store.category_div.includes('10')) return true
      return false
    },
  }
}
</script>
