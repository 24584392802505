import { createRouter, createWebHistory } from "vue-router";
import { store } from "@/store";
import moment from "moment";
import ApiConst from '@/const/api'

import Form from "@/views/PreForm.vue";
import Confirm from "@/views/PreConfirm.vue";
import Complete from "@/views/PreComplete.vue";

import SecondLogin from "@/views/SecondLogin.vue";
import SecondIndex from "@/views/SecondIndex.vue";
import SecondRegulations from "@/views/SecondRegulations.vue";
import SecondForm01 from "@/views/SecondForm01.vue";
import SecondForm02 from "@/views/SecondForm02.vue";
import SecondForm03 from "@/views/SecondForm03.vue";
import SecondForm04 from "@/views/SecondForm04.vue";
import SecondConfirm from "@/views/SecondConfirm.vue";
import SecondRegistComplete from "@/views/SecondRegistComplete.vue";
import PdfUpload from "@/views/PdfUpload.vue";
import PdfUploadComplete from "@/views/PdfUploadComplete.vue";
import SecondCompleteSelect from "@/views/SecondCompleteSelect.vue";
import SecondAllComplete from "@/views/SecondAllComplete.vue";

import Phase2Index from "@/views/p2/Phase2Index.vue";
import Phase2Confirm from "@/views/p2/Phase2Confirm.vue";
import Phase2Form01 from "@/views/p2/Phase2Form01.vue";
import Phase2Form01Confirm from "@/views/p2/Phase2Form01Confirm.vue";
import Phase2Form02 from "@/views/p2/Phase2Form02.vue";
import Phase2Form02Confirm from "@/views/p2/Phase2Form02Confirm.vue";

import ApplicationStatus from "@/views/p3/ApplicationStatus.vue";
import PublicityIndex from "@/views/p3/PublicityIndex.vue";
import PublicityAdvertisingPrint from "@/views/p3/PublicityAdvertisingPrint.vue";
import PublicityExhibitorsList from "@/views/p3/PublicityExhibitorsList.vue";
import PublicityNumExhibits from "@/views/p3/PublicityNumExhibits.vue";
import PublicityBoothInfo from "@/views/p3/PublicityBoothInfo.vue";
import PublicityExhibitsList from "@/views/p3/PublicityExhibitsList.vue";
import PublicityPressreleaseLink from "@/views/p3/PublicityPressreleaseLink.vue";

import ExhibitionIndex from "@/views/p3/ExhibitionIndex.vue";
import ExhibitionAnchorBolt from "@/views/p3/ExhibitionAnchorBolt.vue";

import ExhibitionLotExecutive from "@/views/p3/ExhibitionLotExecutive.vue";
import ExhibitionTaxCargo from "@/views/p3/ExhibitionTaxCargo.vue";
import ExhibitionWirelessMic from "@/views/p3/ExhibitionWirelessMic.vue";
import ExhibitionLotWorker from "@/views/p3/ExhibitionLotWorker.vue";
import ExhibitionLotPlanning from "@/views/p3/ExhibitionLotPlanning.vue";
import ExhibitionCeilingStructure from "@/views/p3/ExhibitionCeilingStructure.vue";
import ExhibitionLightingChange from "@/views/p3/ExhibitionLightingChange.vue";
import ExhibitionProhibitedActs from "@/views/p3/ExhibitionProhibitedActs.vue";
import ExhibitionRoofHang from "@/views/p3/ExhibitionRoofHang.vue";
import ExhibitionTwoStoried from "@/views/p3/ExhibitionTwoStoried.vue";
import ExhibitionCleaning from "@/views/p3/ExhibitionCleaning.vue";
import ExhibitionDetector from "@/views/p3/ExhibitionDetector.vue";
import ExhibitionSanitaryRegist from "@/views/p3/ExhibitionSanitaryRegist.vue";
import ExhibitionElectroRegist from "@/views/p3/ExhibitionElectroRegist.vue";
import ExhibitionElectroCompleted from "@/views/p3/ExhibitionElectroCompleted.vue";
import ExhibitionExtraComm from "@/views/p3/ExhibitionExtraComm.vue";
import ExhibitionExtraComm2 from "@/views/p3/ExhibitionExtraComm2.vue";
import ExhibitionAntennaRegist from "@/views/p3/ExhibitionAntennaRegist.vue";
import ExhibitionStaffAnteroom from "@/views/p3/ExhibitionStaffAnteroom.vue";
import ExhibitionOperationPlan from "@/views/p3/ExhibitionOperationPlan.vue";
import ExhibitionSurveyQuestionnaires from "@/views/p3/ExhibitionSurveyQuestionnaires.vue";
import ExhibitionFoodHandling from "@/views/p3/ExhibitionFoodHandling.vue";
import ExhibitionCarryInout from "@/views/p3/ExhibitionCarryInout.vue";
import ExhibitionShowlogo from "@/views/p3/ExhibitionShowlogo.vue";
import ExhibitionCollaboration from "@/views/p3/ExhibitionCollaboration.vue";

import TicketIndex from "@/views/p3/TicketIndex.vue";
import TicketEntranceTicket from "@/views/p3/TicketEntranceTicket.vue";
import TicketEventParticipation from "@/views/p3/TicketEventParticipation.vue";
import TicketNormalTicket from "@/views/p3/TicketNormalTicket.vue";
import TicketGuidebookOrder from "@/views/p3/TicketGuidebookOrder.vue";
import TicketParkingPass from "@/views/p3/TicketParkingPass.vue";

import PasswordReset from "@/views/PasswordReset.vue";
import PasswordResetSendComplete from "@/views/PasswordResetSendComplete.vue";
import PasswordEnter from "@/views/PasswordEnter.vue";
import PasswordEnterComplete from "@/views/PasswordEnterComplete.vue";

import AdminLogin from "@/views/admin/AdminLogin.vue";
import AdminIndex from "@/views/admin/AdminIndex.vue";

import AdminPreIndex from "@/views/admin/AdminPreIndex.vue";
import AdminPreList from "@/views/admin/AdminPreList.vue";
import AdminPreDetail from "@/views/admin/AdminPreDetail.vue";
import AdminPreDetailConfirm from "@/views/admin/AdminPreDetailConfirm.vue";
import AdminPreDetailComplete from "@/views/admin/AdminPreDetailComplete.vue";

import AdminSecondIndex from "@/views/admin/AdminSecondIndex.vue";
import AdminSecondList from "@/views/admin/AdminSecondList.vue";
import AdminSecondDetail from "@/views/admin/AdminSecondDetail.vue";

import AdminSecondMultipleIndex from "@/views/admin/AdminSecondMultipleIndex.vue";
import AdminSecondMultipleList from "@/views/admin/AdminSecondMultipleList.vue";
import AdminDeadline from "@/views/admin/AdminDeadline.vue";
import AdminSpoofingSearch from "@/views/admin/spoofing/AdminSpoofingSearch.vue";
import AdminSpoofingResult from "@/views/admin/spoofing/AdminSpoofingResult.vue";
import AdminSpoofingEdit from "@/views/admin/spoofing/AdminSpoofingEdit.vue";
import AdminStatusSearch from "@/views/admin/status/AdminStatusSearch.vue";
import AdminStatusResult from "@/views/admin/status/AdminStatusResult.vue";
import AdminStatusList from "@/views/admin/status/AdminStatusList.vue";
import AdminApplicationSearch from "@/views/admin/application/AdminApplicationSearch.vue";
import AdminApplicationResult from "@/views/admin/application/AdminApplicationResult.vue";
import AdminAccountIndex from "@/views/admin/account/AdminAccountIndex.vue";
import AdminAccountEdit from "@/views/admin/account/AdminAccountEdit.vue";
import AdminDemand from "@/views/admin/AdminDemand.vue";
import AdminDemandSearch from "@/views/admin/demand/AdminDemandSearch.vue";
import AdminOvertimenetwork from "@/views/admin/demand/result/AdminOvertimenetwork.vue";
import AdminOvertimework from "@/views/admin/demand/result/AdminOvertimework.vue";
import AdminSanitaryregist from "@/views/admin/demand/result/AdminSanitaryregist.vue";

import NotFound from "@/views/NotFound.vue";

import MaintenanceAnnounce from "@/views/MaintenanceAnnounce.vue";

import { getRequiredStaffLevel, isStaffAuthorized } from "@/helper/staffAuthority";

import { getIndexStatus } from '@/helper/indexStatus';

import { getPrice as getGuidebookPrice } from '@/helper/guidebook'

import {
  isValue
} from '@/helper/index';

import APIClient from "@/store/repository/APIClient";

import { makeUnsetBillingList } from '@/helper/bill';

const api = new APIClient();

const grantStaffAuthorityAndCategoryDispProps = (to, from, next) => {
  const queueList = [];

  queueList.push(api.GetPreEntryDetail().then((entrydatRes) => {
    to.meta.decideArea = entrydatRes.data.results.json_data.decide_area;
    to.meta.categoryDisp = entrydatRes.data.results.json_data.category_disp;
  }));
  queueList.push(api.auth().then((authObj) => {
    to.meta.staffAuthority = authObj?.response?.data?.results?.staff_authority;
  }));
  queueList.push(getIndexStatus().then((statusList) => {
    to.meta.statusList = statusList;
  }));
  Promise.all(queueList).then(() => {
    next();
  });
}

const proxyLogin = async (to, from, next) => {
  try {
    const exhibitor_id = to.params.exhibitor_id
    const query = {
      exhibitor_id
    }
    const res = await api.post(ApiConst.API_ADMIN_PROXY_LOGIN, query)
    const data = res.response.data
    if (data.error_results) {
      throw new Error('Could not login.')
    }
    store.commit('admin_spoofing_login/token', data.results.token)
    store.commit('admin_spoofing_login/limit_datetime', data.results.limit_datetime)
    store.commit('entrydata/exhibitor_id', exhibitor_id)
  } catch (e) {
    console.error(e)
    return false
  }

  const view = to.params.admin_application
  try {
    if (view === 'aa_normal_ticket_0') {
      to.meta.couponQtyUnitCost = await getGuidebookPrice()
    }
    if (view === 'aa_guidebook_order_0') {
      to.meta.couponQtyUnitCost = await getGuidebookPrice()
    }
  } catch (e) {
    console.error(e)
  }
  next();
}

const setGuidebookPrice = async (to, from, next) => {
  to.meta.couponQtyUnitCost = await getGuidebookPrice()
  next();
}

const routes = [
  {
    path: '/maintenance',
    name: 'MaintenanceAnnounce',
    component: MaintenanceAnnounce
  },
  {
    path: "/",
    name: "form",
    component: Form,
  },
  {
    path: "/confirm",
    name: "confirm",
    component: Confirm,
  },
  {
    path: "/complete",
    name: "complete",
    component: Complete,
  },
  {
    path: "/login",
    name: "login",
    component: SecondLogin,
  },
  {
    path: "/logged_induction",
    name: "logged_induction",
  },
  {
    path: "/second/index",
    name: "second_index",
    component: SecondIndex,
    meta: {
      exhibitorAuth: true,
      secondOrderStatus: 1
    },
  },
  {
    path: "/second/regulations",
    name: "second_regulations",
    component: SecondRegulations,
    meta: {
      exhibitorAuth: true,
      secondOrderStatus: 1
    },
  },
  {
    path: "/second/form01",
    name: "second_form01",
    component: SecondForm01,
    meta: {
      exhibitorAuth: true,
      secondOrderStatus: 1
    },
  },
  {
    path: "/second/form02",
    name: "second_form02",
    component: SecondForm02,
    meta: {
      exhibitorAuth: true,
      secondOrderStatus: 1
    },
  },
  {
    path: "/second/form03",
    name: "second_form03",
    component: SecondForm03,
    meta: {
      exhibitorAuth: true,
      secondOrderStatus: 1
    },
  },
  {
    path: "/second/form04",
    name: "second_form04",
    component: SecondForm04,
    meta: {
      exhibitorAuth: true,
      secondOrderStatus: 1
    },
  },
  {
    path: "/second/confirm",
    name: "second_confirm",
    component: SecondConfirm,
    meta: {
      exhibitorAuth: true,
      secondOrderStatus: 1
    },
  },
  {
    path: "/second/regist_complete",
    name: "regist_complete",
    component: SecondRegistComplete,
    meta: {
      exhibitorAuth: true,
      secondOrderStatus: 1
    },
  },
  {
    path: "/second/pdf_upload",
    name: "pdf_upload",
    component: PdfUpload,
    meta: {
      exhibitorAuth: true,
      secondOrderStatus: 1
    },
  },
  {
    path: "/second/pdf_upload_complete",
    name: "pdf_upload_complete",
    component: PdfUploadComplete,
    meta: {
      exhibitorAuth: true,
      secondOrderStatus: 2
    },
  },
  {
    path: "/second/complete_select",
    name: "complete_select",
    component: SecondCompleteSelect,
    meta: {
      exhibitorAuth: true,
      secondOrderStatus: 1
    },
  },
  {
    path: "/second/all_complete",
    name: "all_complete",
    component: SecondAllComplete,
    meta: {
      exhibitorAuth: true,
      secondOrderStatus: 2
    },
  },
  {
    path: "/p2/index",
    name: "p2_index",
    component: Phase2Index,
    beforeEnter: (to, from, next) => {
      makeUnsetBillingList()
      next();
    },
    meta: {
      exhibitorAuth: true,
      secondOrderStatus: 2,
      adminContainer: true,
    },
  },
  {
    path: "/p2/confirm",
    name: "p2_confirm",
    component: Phase2Confirm,
    meta: {
      exhibitorAuth: true,
      secondOrderStatus: 2,
      adminContainer: true,
    },
  },
  {
    path: "/p2/form01",
    name: "p2_form01",
    component: Phase2Form01,
    meta: {
      exhibitorAuth: true,
      secondOrderStatus: 2,
      adminContainer: true,
    },
  },
  {
    path: "/p2/form01_confirm",
    name: "p2_form01_confirm",
    component: Phase2Form01Confirm,
    meta: {
      exhibitorAuth: true,
      secondOrderStatus: 2,
      adminContainer: true,
    },
  },
  {
    path: "/p2/form02",
    name: "p2_form02",
    component: Phase2Form02,
    meta: {
      exhibitorAuth: true,
      secondOrderStatus: 2,
      adminContainer: true,
    },
  },
  {
    path: "/p2/form02_confirm",
    name: "p2_form02_confirm",
    component: Phase2Form02Confirm,
    meta: {
      exhibitorAuth: true,
      secondOrderStatus: 2,
      adminContainer: true,
    },
  },
  {
    path: "/p3/status",
    name: "p3_status",
    component: ApplicationStatus,
    beforeEnter: grantStaffAuthorityAndCategoryDispProps,
    meta: {
      exhibitorAuth: true,
      secondOrderStatus: 2,
      adminContainer: true,
    },
  },
  {
    path: "/p3/publicity/index",
    name: "p3_publicity_index",
    component: PublicityIndex,
    beforeEnter: grantStaffAuthorityAndCategoryDispProps,
    meta: {
      exhibitorAuth: true,
      secondOrderStatus: 2,
      adminContainer: true,
    },
  },
  {
    path: "/p3/publicity/advertising_print",
    name: "p3_publicity_advertising_print",
    component: PublicityAdvertisingPrint,
    meta: {
      exhibitorAuth: true,
      secondOrderStatus: 2,
      staffAuthority: true,
      adminContainer: true,
    },
  },
  {
    path: "/p3/publicity/exhibitors_list",
    name: "p3_publicity_exhibitors_list",
    component: PublicityExhibitorsList,
    meta: {
      exhibitorAuth: true,
      secondOrderStatus: 2,
      staffAuthority: true,
      adminContainer: true,
    },
  },
  {
    path: "/p3/publicity/num_exhibits",
    name: "p3_publicity_num_exhibits",
    component: PublicityNumExhibits,
    meta: {
      exhibitorAuth: true,
      secondOrderStatus: 2,
      staffAuthority: true,
      adminContainer: true,
    },
  },
  {
    path: "/p3/publicity/booth_info",
    name: "p3_publicity_booth_info",
    component: PublicityBoothInfo,
    meta: {
      exhibitorAuth: true,
      secondOrderStatus: 2,
      staffAuthority: true,
      adminContainer: true,
    },
  },
  {
    path: "/p3/publicity/exhibits_list",
    name: "p3_publicity_exhibits_list",
    component: PublicityExhibitsList,
    meta: {
      exhibitorAuth: true,
      secondOrderStatus: 2,
      staffAuthority: true,
      adminContainer: true,
    },
  },
  {
    path: "/p3/publicity/pressrelease_link",
    name: "p3_publicity_pressrelease_link",
    component: PublicityPressreleaseLink,
    meta: {
      exhibitorAuth: true,
      secondOrderStatus: 2,
      staffAuthority: true,
      adminContainer: true,
    },
  },
  {
    path: "/p3/exhibition/index",
    name: "p3_exhibition_index",
    component: ExhibitionIndex,
    beforeEnter: grantStaffAuthorityAndCategoryDispProps,
    meta: {
      exhibitorAuth: true,
      secondOrderStatus: 2,
      adminContainer: true,
    },
  },
  {
    path: "/p3/exhibition/anchor_bolt",
    name: "p3_exhibition_anchor_bolt",
    component: ExhibitionAnchorBolt,
    meta: {
      exhibitorAuth: true,
      secondOrderStatus: 2,
      staffAuthority: true,
      adminContainer: true,
    },
  },
  {
    path: "/p3/exhibition/lot_executive",
    name: "p3_exhibition_lot_executive",
    component: ExhibitionLotExecutive,
    meta: {
      exhibitorAuth: true,
      secondOrderStatus: 2,
      staffAuthority: true,
      adminContainer: true,
    },
  },
  {
    path: "/p3/exhibition/tax_cargo",
    name: "p3_exhibition_tax_cargo",
    component: ExhibitionTaxCargo,
    meta: {
      exhibitorAuth: true,
      secondOrderStatus: 2,
      staffAuthority: true,
      adminContainer: true,
    },
  },
  {
    path: "/p3/exhibition/wireless_mic",
    name: "p3_exhibition_wireless_mic",
    component: ExhibitionWirelessMic,
    meta: {
      exhibitorAuth: true,
      secondOrderStatus: 2,
      staffAuthority: true,
      adminContainer: true,
    },
  },
  {
    path: "/p3/exhibition/lot_worker",
    name: "p3_exhibition_lot_worker",
    component: ExhibitionLotWorker,
    meta: {
      exhibitorAuth: true,
      secondOrderStatus: 2,
      staffAuthority: true,
      adminContainer: true,
    },
  },
  {
    path: "/p3/exhibition/lot_planning",
    name: "p3_exhibition_lot_planning",
    component: ExhibitionLotPlanning,
    meta: {
      exhibitorAuth: true,
      secondOrderStatus: 2,
      staffAuthority: true,
      adminContainer: true,
    },
  },
  {
    path: "/p3/exhibition/ceiling_structure",
    name: "p3_exhibition_ceiling_structure",
    component: ExhibitionCeilingStructure,
    meta: {
      exhibitorAuth: true,
      secondOrderStatus: 2,
      staffAuthority: true,
      adminContainer: true,
    },
  },
  {
    path: "/p3/exhibition/lighting_change",
    name: "p3_exhibition_lighting_change",
    component: ExhibitionLightingChange,
    meta: {
      exhibitorAuth: true,
      secondOrderStatus: 2,
      staffAuthority: true,
      adminContainer: true,
    },
  },
  {
    path: "/p3/exhibition/prohibited_acts",
    name: "p3_exhibition_prohibited_acts",
    component: ExhibitionProhibitedActs,
    beforeEnter: grantStaffAuthorityAndCategoryDispProps,
    meta: {
      exhibitorAuth: true,
      secondOrderStatus: 2,
      staffAuthority: true,
      adminContainer: true,
    },
  },
  {
    path: "/p3/exhibition/roof_hang",
    name: "p3_exhibition_roof_hang",
    component: ExhibitionRoofHang,
    meta: {
      exhibitorAuth: true,
      secondOrderStatus: 2,
      staffAuthority: true,
      adminContainer: true,
    },
  },
  {
    path: "/p3/exhibition/two_storied",
    name: "p3_exhibition_two_storied",
    component: ExhibitionTwoStoried,
    meta: {
      exhibitorAuth: true,
      secondOrderStatus: 2,
      staffAuthority: true,
      adminContainer: true,
    },
  },
  {
    path: "/p3/exhibition/cleaning",
    name: "p3_exhibition_cleaning",
    component: ExhibitionCleaning,
    meta: {
      exhibitorAuth: true,
      secondOrderStatus: 2,
      staffAuthority: true,
      adminContainer: true,
    },
  },
  {
    path: "/p3/exhibition/detector",
    name: "p3_exhibition_detector",
    component: ExhibitionDetector,
    meta: {
      exhibitorAuth: true,
      secondOrderStatus: 2,
      staffAuthority: true,
      adminContainer: true,
    },
  },
  {
    path: "/p3/exhibition/sanitary_regist",
    name: "p3_exhibition_sanitary_regist",
    component: ExhibitionSanitaryRegist,
    meta: {
      exhibitorAuth: true,
      secondOrderStatus: 2,
      staffAuthority: true,
      adminContainer: true,
    },
  },
  {
    path: "/p3/exhibition/electro_regist",
    name: "p3_exhibition_electro_regist",
    component: ExhibitionElectroRegist,
    meta: {
      exhibitorAuth: true,
      secondOrderStatus: 2,
      staffAuthority: true,
      adminContainer: true,
    },
  },
  {
    path: "/p3/exhibition/electro_completed",
    name: "p3_exhibition_electro_completed",
    component: ExhibitionElectroCompleted,
    meta: {
      exhibitorAuth: true,
      secondOrderStatus: 2,
      staffAuthority: true,
      adminContainer: true,
    },
  },
  {
    path: "/p3/exhibition/extra_comm",
    name: "p3_exhibition_extra_comm",
    component: ExhibitionExtraComm,
    meta: {
      exhibitorAuth: true,
      secondOrderStatus: 2,
      staffAuthority: true,
      adminContainer: true,
    },
  },
  {
    path: "/p3/exhibition/extra_comm2",
    name: "p3_exhibition_extra_comm2",
    component: ExhibitionExtraComm2,
    meta: {
      exhibitorAuth: true,
      secondOrderStatus: 2,
      staffAuthority: true,
      adminContainer: true,
    },
  },
  {
    path: "/p3/exhibition/antenna_regist",
    name: "p3_exhibition_antenna_regist",
    component: ExhibitionAntennaRegist,
    meta: {
      exhibitorAuth: true,
      secondOrderStatus: 2,
      staffAuthority: true,
      adminContainer: true,
    },
  },
  {
    path: "/p3/exhibition/staff_anteroom",
    name: "p3_exhibition_staff_anteroom",
    component: ExhibitionStaffAnteroom,
    meta: {
      exhibitorAuth: true,
      secondOrderStatus: 2,
      staffAuthority: true,
      adminContainer: true,
    },
  },
  {
    path: "/p3/exhibition/operation_plan",
    name: "p3_exhibition_operation_plan",
    component: ExhibitionOperationPlan,
    meta: {
      exhibitorAuth: true,
      secondOrderStatus: 2,
      staffAuthority: true,
      adminContainer: true,
    },
  },
  {
    path: "/p3/exhibition/survey_questionnaires",
    name: "p3_exhibition_survey_questionnaires",
    component: ExhibitionSurveyQuestionnaires,
    meta: {
      exhibitorAuth: true,
      secondOrderStatus: 2,
      staffAuthority: true,
      adminContainer: true,
    },
  },
  {
    path: "/p3/exhibition/food_handling",
    name: "p3_exhibition_food_handling",
    component: ExhibitionFoodHandling,
    meta: {
      exhibitorAuth: true,
      secondOrderStatus: 2,
      staffAuthority: true,
      adminContainer: true,
    },
  },
  {
    path: "/p3/exhibition/carry_inout",
    name: "p3_exhibition_carry_inout",
    component: ExhibitionCarryInout,
    beforeEnter: grantStaffAuthorityAndCategoryDispProps,
    meta: {
      exhibitorAuth: true,
      secondOrderStatus: 2,
      staffAuthority: true,
      adminContainer: true,
    },
  },
  {
    path: "/p3/exhibition/showlogo",
    name: "p3_exhibition_showlogo",
    component: ExhibitionShowlogo,
    beforeEnter: grantStaffAuthorityAndCategoryDispProps,
    meta: {
      exhibitorAuth: true,
      secondOrderStatus: 2,
      staffAuthority: true,
      adminContainer: true,
    },
  },
  {
    path: "/p3/exhibition/collaboration",
    name: "p3_exhibition_collaboration",
    component: ExhibitionCollaboration,
    meta: {
      exhibitorAuth: true,
      secondOrderStatus: 2,
      staffAuthority: true,
      adminContainer: true,
    },
  },
  {
    path: "/p3/ticket/index",
    name: "p3_ticket_index",
    component: TicketIndex,
    beforeEnter: grantStaffAuthorityAndCategoryDispProps,
    meta: {
      exhibitorAuth: true,
      secondOrderStatus: 2,
      adminContainer: true,
    },
  },
  {
    path: "/p3/ticket/entrance_ticket",
    name: "p3_ticket_entrance_ticket",
    component: TicketEntranceTicket,
    meta: {
      exhibitorAuth: true,
      secondOrderStatus: 2,
      staffAuthority: true,
      adminContainer: true,
    },
  },
  {
    path: "/p3/ticket/event_participation",
    name: "p3_ticket_event_participation",
    component: TicketEventParticipation,
    beforeEnter: grantStaffAuthorityAndCategoryDispProps,
    meta: {
      exhibitorAuth: true,
      secondOrderStatus: 2,
      staffAuthority: true,
      adminContainer: true,
    },
  },
  {
    path: "/p3/ticket/normal_ticket",
    name: "p3_ticket_normal_ticket",
    component: TicketNormalTicket,
    beforeEnter: setGuidebookPrice,
    meta: {
      exhibitorAuth: true,
      secondOrderStatus: 2,
      staffAuthority: true,
      adminContainer: true,
    },
  },
  {
    path: "/p3/ticket/guidebook_order",
    name: "p3_ticket_guidebook_order",
    component: TicketGuidebookOrder,
    beforeEnter: setGuidebookPrice,
    meta: {
      exhibitorAuth: true,
      secondOrderStatus: 2,
      staffAuthority: true,
      adminContainer: true,
    },
  },
  {
    path: "/p3/ticket/parking_pass",
    name: "p3_ticket_parking_pass",
    component: TicketParkingPass,
    meta: {
      exhibitorAuth: true,
      secondOrderStatus: 2,
      staffAuthority: true,
      adminContainer: true,
    },
  },
  {
    path: "/password_reset",
    name: "password_reset",
    component: PasswordReset,
  },
  {
    path: "/password_reset_send_complete",
    name: "password_reset_send_complete",
    component: PasswordResetSendComplete,
  },
  {
    path: "/password_enter",
    name: "password_enter",
    component: PasswordEnter,
  },
  {
    path: "/password_enter_complete",
    name: "password_enter_complete",
    component: PasswordEnterComplete,
  },
  {
    path: "/admin/",
    name: "AdminLogin",
    component: AdminLogin,
  },
  {
    path: "/admin/index",
    name: "AdminIndex",
    component: AdminIndex,
    meta: {
      adminAuth: true
    },
  },
  {
    path: "/admin/pre_index",
    name: "AdminPreIndex",
    component: AdminPreIndex,
    meta: {
      adminAuth: true
    },
  },
  {
    path: "/admin/pre_list",
    name: "AdminPreList",
    component: AdminPreList,
    meta: {
      adminAuth: true
    },
  },
  {
    path: "/admin/pre_detail/:id",
    name: "AdminPreDetail",
    component: AdminPreDetail,
    meta: {
      adminAuth: true
    },
  },
  {
    path: "/admin/pre_detail_confirm/:id",
    name: "AdminPreDetailConfirm",
    component: AdminPreDetailConfirm,
    meta: {
      adminAuth: true
    },
  },
  {
    path: "/admin/pre_detail_complete/:id",
    name: "AdminPreDetailComplete",
    component: AdminPreDetailComplete,
    meta: {
      adminAuth: true
    },
  },
  {
    path: "/admin/second_index",
    name: "AdminSecondIndex",
    component: AdminSecondIndex,
    meta: {
      adminAuth: true
    },
  },
  {
    path: "/admin/second_list",
    name: "AdminSecondList",
    component: AdminSecondList,
    meta: {
      adminAuth: true
    },
  },
  {
    path: "/admin/second_detail",
    name: "AdminSecondDetailInsert",
    component: AdminSecondDetail,
    meta: {
      adminAuth: true
    },
  },
  {
    path: "/admin/second_detail/:id",
    name: "AdminSecondDetail",
    component: AdminSecondDetail,
    meta: {
      adminAuth: true
    },
  },
  {
    path: "/admin/second_multiple_index",
    name: "AdminSecondMultipleIndex",
    component: AdminSecondMultipleIndex,
    meta: {
      adminAuth: true
    },
  },
  {
    path: "/admin/second_multiple_list",
    name: "AdminSecondMultipleList",
    component: AdminSecondMultipleList,
    meta: {
      adminAuth: true
    },
  },
  {
    path: "/admin/deadline",
    name: "AdminDeadline",
    component: AdminDeadline,
    meta: {
      adminAuth: true
    },
  },
  {
    path: "/admin/spoofing/search",
    name: "AdminSpoofingSearch",
    component: AdminSpoofingSearch,
    meta: {
      adminAuth: true
    },
  },
  {
    path: "/admin/spoofing/edit/:admin_application/:exhibitor_id",
    name: "AdminSpoofingEdit",
    component: AdminSpoofingEdit,
    beforeEnter: proxyLogin,
    meta: {
      adminAuth: true,
      adminSpoofing: true
    },
  },
  {
    path: "/admin/spoofing/:admin_application",
    name: "AdminSpoofingResult",
    component: AdminSpoofingResult,
    meta: {
      adminAuth: true
    },
  },
  {
    path: "/admin/status/search",
    name: "AdminStatusSearch",
    component: AdminStatusSearch,
    meta: {
      adminAuth: true
    },
  },
  {
    path: "/admin/status/result",
    name: "AdminStatusResult",
    component: AdminStatusResult,
    meta: {
      adminAuth: true
    },
  },
  {
    path: "/admin/status/list/:id/:name",
    name: "AdminStatusList",
    component: AdminStatusList,
    meta: {
      adminAuth: true
    },
  },
  {
    path: "/admin/application/search",
    name: "AdminApplicationSearch",
    component: AdminApplicationSearch,
    meta: {
      adminAuth: true
    },
  },
  {
    path: "/admin/application/:admin_application",
    name: "AdminApplicationResult",
    component: AdminApplicationResult,
    meta: {
      adminAuth: true
    },
  },
  {
    path: "/admin/account",
    name: "AdminAccountIndex",
    component: AdminAccountIndex,
    meta: {
      adminAuth: true
    },
  },
  {
    path: "/admin/account/edit/:id?",
    name: "AdminAccountEdit",
    component: AdminAccountEdit,
    meta: {
      adminAuth: true
    },
  },
  {
    path: "/admin/demand",
    name: "AdminDemand",
    component: AdminDemand,
    meta: {
      adminAuth: true
    },
  },
  {
    path: "/admin/demand/search/:admin_demand_search",
    name: "AdminDemandSearch",
    component: AdminDemandSearch,
    beforeEnter: (to, from, next) => {
      if ([
        'AdminOvertimenetwork',
        'AdminOvertimework',
        'AdminSanitaryregist',
      ].includes(to.params.admin_demand_search)) {
        next()
      }
      next({name: 'AdminIndex'})
    },
    meta: {
      adminAuth: true
    },
  },
  {
    path: "/admin/overtimenetwork",
    name: "AdminOvertimenetwork",
    component: AdminOvertimenetwork,
    meta: {
      adminAuth: true
    },
  },
  {
    path: "/admin/overtimework",
    name: "AdminOvertimework",
    component: AdminOvertimework,
    meta: {
      adminAuth: true
    },
  },
  {
    path: "/admin/sanitaryregist",
    name: "AdminSanitaryregist",
    component: AdminSanitaryregist,
    meta: {
      adminAuth: true
    },
  },
  {
    path: '/:catchAll(.*)',
    name: 'NotFound',
    component: NotFound
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    return {
      top: 0
    }
  },
});

let exhibitorAuth

router.beforeEach(async (to, from, next) => {

  const matched = to.matched;
  const pageName = to.name

  /* メンテナンス時のコード
  if (pageName !== 'MaintenanceAnnounce') {
    next({name: 'MaintenanceAnnounce'});
  }
*/

  // 代理登録画面以外は代理トークン削除
  if (
    matched.some((record) => {
      const path = record.path
      const isSpoofing = path.indexOf('admin/spoofing/edit/') > 0
      return !isSpoofing;
    })
  ) {
    store.dispatch("admin_spoofing_login/logout")
  }

  // 出展者認証
  if (
    matched.some((record) => {
      const auth = record.meta.exhibitorAuth;
      if (auth) return true;
    })
  ) {
    try {
      const limitDate = moment(store.state.login.limit_datetime)
      if (!limitDate.isValid()) {
        const couldRefresh = await api.refreshToken()
        if (! couldRefresh) {
          throw new Error("Incorrect token expiration date.");
        }
      }
      const dateDiff = limitDate.diff(moment())
      if (dateDiff < 0) {
        const couldRefresh = await api.refreshToken()
        if (! couldRefresh) {
          throw new Error("Token expired.");
        }
      }

      const isAuth = await api.auth();
      exhibitorAuth = isAuth
      if (!isAuth) {
        throw new Error("Unauthorized, access denied.");
      }
    } catch (e) {
      console.error(e.message);
      next({
        path: "/login",
        query: {
          redirect: to.fullPath,
        },
      });
    }

    // ２次申込で確定済みのアカウントを再編集できないように
    const pageSecondOrderStatus = to.meta.secondOrderStatus
    if (isValue(pageSecondOrderStatus)) {
      try {
        // const currentSecondOrderStatus = store.state.login_user?.second_order_status
        const currentSecondOrderStatus = store.state.login_user?.second_order_status === 2 ? 1 : store.state.login_user?.second_order_status 
        if (!isValue(currentSecondOrderStatus)) {
          throw new Error("second_order_status is not defined.");
        }
        if (currentSecondOrderStatus - 1 >= pageSecondOrderStatus) {
          throw new Error("The second application has already been completed.");
        }
      } catch (e) {
        console.error(e.message);
        next({
          path: "/login",
          query: {
            redirect: to.fullPath,
          },
        });
      }
    }
  }

  if (
    matched.some((record) => {
      const auth = record.meta.staffAuthority;
      if (auth) return true;
    })
  ) {
    try {
      const requiredStaffLevel = await getRequiredStaffLevel(to.name)
      if (!requiredStaffLevel) {
        throw new Error("Could not retrieve access authority list.");
      }
      const authObj = exhibitorAuth ?? await api.auth();
      if (!authObj) {
        throw new Error("Unauthorized, staff authority denied.");
      }
      const staff_authority = authObj?.response?.data?.results?.staff_authority
      const isStaffAuth = isStaffAuthorized(requiredStaffLevel, staff_authority);
      if (!isStaffAuth) {
        throw new Error("Login information could not be found in the access privileges list.");
      }
      next();
    } catch (e) {
      console.error(e.message);
      next({
        path: "/login",
        query: {
          redirect: to.fullPath,
        },
      });
    }
  }

  if (pageName === 'logged_induction') {
    try {
      const authObj = await api.auth();
      if (!authObj) {
        throw new Error("Unauthorized, access denied.");
      }
      const currentSecondOrderStatus = authObj?.response?.data?.results?.second_order_status
      if (currentSecondOrderStatus === 1) {
        next({ name: 'complete_select' });
      }
      if (currentSecondOrderStatus === 2) {
        // いったん出展者管理画面は出さない next({ name: 'p2_index' });
        next({ name: 'pdf_upload' });
      }
      next({ name: 'second_index' });
    } catch (e) {
      console.error(e.message);
      next({
        path: "/login",
        query: {
          redirect: to.fullPath,
        },
      });
    }
  }

  // 管理者認証
  if (
    matched.some((record) => {
      const auth = record.meta.adminAuth;
      if (auth) return true;
    })
  ) {
    try {
      const limitDate = moment(store.state.administrator.limit_datetime)
      if (!limitDate.isValid()) {
        const couldRefresh = await api.adminRefreshToken()
        if (! couldRefresh) {
          throw new Error("Incorrect token expiration date.");
        }
      }
      const dateDiff = limitDate.diff(moment())
      if (dateDiff < 0) {
        const couldRefresh = await api.adminRefreshToken()
        if (! couldRefresh) {
          throw new Error("Token expired.");
        }
      }

      const isAuth = await api.adminAuth();
      if (!isAuth) {
        throw new Error("Unauthorized, access denied.");
      }
    } catch (e) {
      console.error(e.message);
      next({
        path: "/admin",
        query: {
          redirect: to.fullPath,
        },
      });
    }
  }
  next();
});
router.afterEach(() => {
  store.dispatch("message/initializeErrors");
});

export default router;
