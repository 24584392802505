<template>
  <header>
    <h1>JAPAN MOBILITY SHOW 2025&emsp;出展手続きサイト・出展者専用サイト</h1>
  </header>
  <article>
    <h2>出展に関するお問い合わせ</h2>
    <div id="form_ctn" class="form_box">
      <SecondSteps step="1" />
      <h3>本登録の説明</h3>
      <p class="regulation_button">
        <a href="/docs/2025/regulation/regulation.pdf" class="common_button" target="_blank">JAPAN MOBILITY SHOW 2025 規程</a>
      </p>

      <div class="attention">
        <p>出展申込締切日▶2025年1月31日(金)</p>
      </div>



      <h3>出展申込に関するスケジュール</h3>

      <table class="common_table">
        <thead>
          <tr>
            <th>内容</th>
            <th>日程（予定）</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>出展申込締切日</td>
            <td class="ta_center">2025年1月31日（金）</td>
          </tr>
          <tr>
            <td>小間決定通知（完成車、車体（屋内）部門）</td>
            <td class="ta_center">2025年4月下旬</td>
          </tr>
          <tr>
            <td>小間決定通知（部品・機械器具、モビリティ関連）</td>
            <td class="ta_center">2025年5月下旬</td>
          </tr>
        </tbody>
      </table>

      <h3>出展に関するお問い合わせ</h3>
      <div class="contact_ctn">
        <h4>一般社団法人日本自動車工業会<br>
次世代モビリティ領域ショー担当
</h4>
        <p>TEL：03(5405)6127<br>
          <a href="https://www.japan-mobility-show.com/app/contact/" target="_blank">https://www.japan-mobility-show.com/app/contact/</a>
        </p>
      </div>
      <div id="start_button" class="button_ctn">
        <router-link to="/second/regulations" class="submit">出展申込開始</router-link>
  </div>



    </div>
  </article>
</template>

<script>
import SecondSteps from './SecondSteps.vue';

export default {
  components: {
    SecondSteps,
  },
};
</script>

<style>

</style>
