<template>
  <template v-if="pointer">
    <DisplayTagSimple
      v-if="simple"
      :status="pointer.status"
    />
    <DisplayTag
      v-else
      :status="pointer.status"
    />
  </template>
</template>

<script setup>
import { defineProps, ref, computed } from "vue"
import { getIndexStatus, getIndexByRouterName } from '@/helper/indexStatus'
import DisplayTag from './DisplayIndexStatusTag'
import DisplayTagSimple from './DisplayIndexStatusTagSimple'

const statusList = ref({})
;(async () => {
  statusList.value = await getIndexStatus()
})()

const props = defineProps({
  name: {
    type: String,
    default: null,
  },
  simple: {
    type: Boolean,
    default: false,
  },
})

const pointer = computed(() => {
  try {
    const index = getIndexByRouterName(props.name)
    return statusList.value[index]
  } catch (e) {
    return null
  }
})
</script>
