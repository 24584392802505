<template>
  <table class="common_table" v-if="!isNew">
    <tbody>
      <tr>
        <th>日本語版: URL</th>
        <td><template v-if="currentData.jp"><a :href="currentData.jp" target="_blank" rel="noopener">{{ currentData.jp }}</a></template>
          <template v-else>未登録</template></td>
      </tr>
      <tr>
        <th>英語版: URL</th>
        <td><template v-if="currentData.en"><a :href="currentData.en" target="_blank" rel="noopener">{{ currentData.en }}</a></template>
          <template v-else>未登録</template></td>
      </tr>
    </tbody>
  </table>
  <div class="no_entry" v-else>登録がありません。「新規追加」ボタンより登録をお願いします。</div>

  <template v-if="isShowUI">
  <div><a
    @click.prevent="editHandler"
    href="#form1"
    class="common_add_button modalOpen"
  >{{uploadLabel}}</a></div>
  </template>

  <Teleport to="body">
    <!-- モーダルコンテンツ / -->
    <div class="overlay" id="form1">
      <div id="modal_content" class="modal_contents w80">
        <div class="modalClose_ctn"><a
          @click.prevent="modalClose"
          class="modalClose"
        ></a></div>
        <!-- modal contents ここから-->
        <div class="entry_title">
          <h4>出展者一覧</h4>
          <h5>リンク希望URL&nbsp;&nbsp;{{uploadLabel}}</h5>


          <div class="entry_modal_ctn">
            <p>JAPAN MOBILITY SHOW 2025 オフィシャルサイトの御社紹介欄にリンクを張らせていただきますので、御社のホームページやJAPAN MOBILITY SHOW特設サイト等のURL入力をお願いします。</p>
            <br>
            <p>前回ショーサイト掲出イメージ（<a href="https://2019.tokyo-motorshow.com/outline/exhibitors_list.html" target="_blank">日本語版</a>：<a href="https://2019.tokyo-motorshow.com/en/outline/exhibitors_list.html" target="_blank">英語版</a>）</p>
            <br>
            <table class="common_table">
              <tbody>
                <tr>
                  <th>日本語版: URL</th>
                  <td><input type="text" class="full" v-model="form.jp">
                    <p class="alert" v-if="errors.jp">{{ errors.jp }}</p></td>
                </tr>
                <tr>
                  <th>英語版: URL</th>
                  <td><input type="text" class="full" v-model="form.en">
                    <p class="alert" v-if="errors.en">{{ errors.en }}</p></td>
                </tr>
              </tbody>
            </table>

            <div class="button_ctn">
              <ul>
                <li><button
                  @click.prevent="modalClose"
                  class="gray_button modalClose"
                >キャンセル</button></li>
                <li><button
                  @click="sendForm"
                  class="regist_button"
                >上記内容で登録</button></li>
              </ul>
            </div>
            <!-- modal contents ここまで-->

          </div>
        </div>
        <!-- / モーダルコンテンツ -->
      </div>
    </div>
    <!-- / モーダルコンテンツ -->
  </Teleport>
</template>

<script setup>
import { reactive, computed } from 'vue'
import { forEach, cloneDeep } from 'lodash'
import APIClient from '@/store/repository/APIClient'
import * as yup from 'yup'
import moment from "moment"
import { FormValidate } from '@/helper/FormValidate'

import {
  modalOpen,
  modalClose,
} from '@/helper/modal'

import ApiConst from '@/const/api'

import { isShowable as isShowUI } from '@/helper/indexStatus'

const api = new APIClient()

const formInit = {
  application_id: null,
  jp: '',
  en: '',
}

const form = reactive(cloneDeep(formInit))
const currentData = reactive(cloneDeep(formInit))

const isNew = computed(() => {
  return !currentData.application_id
})

const uploadLabel = computed(() => {
  return !isNew.value ? '変更' : '新規登録'
})

const fetch = async () => {
  try {
    const query = {
      form_no: 1,
      application_div : "exhibitors_list",
    }
    const fetchApi = await api.get(ApiConst.API_APP_LIST, query)
    const res = fetchApi.response
    const errors = res.data?.error_results
    if (errors) {
      throw new Error('Not found.')
    }
    const data = res.data.results.list.map((el) => {
      return {
        application_id: el.application_id,
        input_date: moment(el.updated_at).format("YY-MM-DD"),
        seq_no: el.seq_no,
        ...el.json_data
      }
    })[0]
    forEach(currentData, (v, k) => {
      currentData[k] = data[k]
    })
    forEach(form, (v, k) => {
      form[k] = data[k] ?? formInit[k]
    })
  } catch (e) {
    console.log(e)
    return false
  }
}
fetch()

const editHandler = (event) => {
  modalOpen(event)
}

const scheme = {
  jp: yup.string().url('URL形式で入力してください。'),
  en: yup.string().url('URL形式で入力してください。'),
}
const validate = new FormValidate(scheme, form)
const errors = validate.errors
const sendForm = async () => {
  try {
    if ((Object.keys(await validate.getErrorObject()).length)) {
      throw new Error('入力項目に不備があります。')
    }
    const params = cloneDeep(form)
    const application_id = params.application_id
    delete params.application_id
    const operation = application_id ? "R" : "I"
    const query = operation === "I"
      ? {operation, application_div : "exhibitors_list", form_no: 1, json_data: params}
      : {operation, application_id, json_data: params}
    const res = await api.post(ApiConst.API_APP_EDIT, query)
    if (res.response.data?.error_results) {
      throw new Error('API Validation Error')
    }
    fetch()
    modalClose()
  } catch (e) {
    return false
  }
}
</script>

<style></style>
