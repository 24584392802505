import { ref } from 'vue'
import { forEach, map, compact, cloneDeep } from 'lodash'
import APIClient from '@/store/repository/APIClient'
import {
  isValue
} from '@/helper/index'

const api = new APIClient()

export const getQuery = () => {
  const searchStr = location.search
  const searchList = map([...new URLSearchParams(searchStr)], ((el) => {
    return {key: el[0], value: el[1]}
  })).reduce((group, p) => {
    group[p.key] = group[p.key] ?? []
    group[p.key].push(p.value)
    return group
  }, {})
  
  const query = {
    koma_bango: searchList?.koma_bango[0],
    exhibitor_name: searchList?.exhibitor_name[0],
    array_category_disp: searchList?.array_category_disp,
    array_hall_div: searchList?.array_hall_div,
  }
  forEach(query, (v, k) => {
    if (Array.isArray(v) && compact(v).length < 1) {
      delete query[k]
    } else if (!isValue(v)) {
      delete query[k]
    }
  })

  return query
}

export const fetchList = async (endpoint, query, list, form) => {
  try {
    const fetchApi = await api.post(endpoint, query)
    const res = fetchApi.response
    const errors = res.data?.error_results
    if (errors) {
      throw new Error('Not found.')
    }

    list.value = []
    forEach(res.data?.results?.list, (val, key) => {
      list.value.push(val)
      const n = key + 1
      form['exhibitor_id_' + n] = val?.exhibitor_id
      form['koma_bango_' + n] = val?.koma_bango
      form['exhibitor_name_' + n] = val?.exhibitor_name ?? ''

      form['water_num_' + n] = val?.water_num
      form['overtime_hour_' + n] = val?.overtime_hour
      form['overtime_cost_' + n] = val?.overtime_cost
    })
  } catch (e) {
    console.log(e)
    return false
  }
}



export const sendDisabled = ref(false)
const defaultSendLabel = '登録'
export const sendLabel = ref(defaultSendLabel)

const pushRestriction = (message) => {
  sendLabel.value = message
  sendDisabled.value = true
  setTimeout(() => {
    sendLabel.value = defaultSendLabel
    sendDisabled.value = false
  }, 2000)
}
const onInvalidSubmit = (errors) => {
  console.error(errors)
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  })
}
export const sendForm = async (endpoint, form, callback) => {
  sendDisabled.value = true
  sendLabel.value = '情報を更新しています'
  try {
    const query = cloneDeep(form)
    const res = await api.post(endpoint, query)
    if (res.response.data?.error_results) {
      onInvalidSubmit('入力項目に不備があります。')
      pushRestriction('入力項目に不備があります。')
      throw new Error('API Validation Error')
    }
    callback()
  } catch (e) {
    onInvalidSubmit(e)
    pushRestriction(e)
    return false
  }
  pushRestriction('情報を更新しています')
}
