<template>
  <table class="common_table" v-if="!isNew">
    <tbody>
      <tr>
        <th>出展車両総数</th>
        <td class="ta_right">{{ currentData.total }}</td>
      </tr>
      <tr>
        <th><span style="margin-left:1em;">内、出展車両WP総数</span></th>
        <td class="ta_right"><span style="margin-left:1m;">{{ currentData.wp }}</span></td>
      </tr>
      <tr>
        <th><span style="margin-left:1em;">内、出展車両JP総数</span></th>
        <td class="ta_right">{{ currentData.jp }}</td>
      </tr>
    </tbody>
  </table>
  <div class="no_entry" v-else>登録がありません。「新規追加」ボタンより登録をお願いします。</div>

  <template v-if="isShowUI">
  <div><a
    @click.prevent="editHandler"
    href="#form1"
    class="common_add_button modalOpen"
  >{{uploadLabel}}</a></div>
  </template>

  <Teleport to="body">
    <!-- モーダルコンテンツ / -->
    <div class="overlay" id="form1">
      <div id="modal_content" class="modal_contents w80">
        <div class="modalClose_ctn"><a
          @click.prevent="modalClose"
          class="modalClose"
        ></a></div>
        <!-- modal contents ここから-->
        <div class="entry_title">
          <h4>出展車両数一覧</h4>
          <h5>出展合計（ワールドプレミア/ジャパンプレミア含む）&nbsp;&nbsp;{{uploadLabel}}</h5>


          <div class="entry_modal_ctn">
            <p>出展予定の車両数およびWP/JPの総数を入力願います。</p>
            <table class="common_table">
              <tbody>
                <tr>
                  <th>出展車両総数</th>
                  <td><input type="number" min="0" class="w10em" v-model="form.total">
                    <p class="alert" v-if="errors.total">{{ errors.total }}</p></td>
                </tr>
                <tr>
                  <th><span style="margin-left:1m;">内、出展車両WP総数</span></th>
                  <td><input type="number" min="0" class="w10em" v-model="form.wp">
                    <p class="alert" v-if="errors.wp">{{ errors.wp }}</p></td>
                </tr>
                <tr>
                  <th><span style="margin-left:1m;">内、出展車両JP総数</span></th>
                  <td><input type="number" min="0" class="w10em" v-model="form.jp">
                    <p class="alert" v-if="errors.jp">{{ errors.jp }}</p></td>
                </tr>
              </tbody>
            </table>

            <div class="button_ctn">
              <ul>
                <li><button
                  @click.prevent="modalClose"
                  class="gray_button modalClose"
                >キャンセル</button></li>
                <li><button
                  @click="sendForm"
                  class="regist_button"
                >上記内容で登録</button></li>
              </ul>
            </div>
            <!-- modal contents ここまで-->

          </div>
        </div>
        <!-- / モーダルコンテンツ -->
      </div>
    </div>
    <!-- / モーダルコンテンツ -->
  </Teleport>
</template>

<script setup>
import { reactive, computed } from 'vue'
import { forEach, cloneDeep } from 'lodash'
import APIClient from '@/store/repository/APIClient'
import * as yup from 'yup'
import moment from "moment"
import { FormValidate } from '@/helper/FormValidate'

import {
  modalOpen,
  modalClose,
} from '@/helper/modal'

import ApiConst from '@/const/api'

import { isShowable as isShowUI } from '@/helper/indexStatus'

const api = new APIClient()

const formInit = {
  application_id: null,
  total: null,
  wp: null,
  jp: null,
}

const form = reactive(cloneDeep(formInit))
const currentData = reactive(cloneDeep(formInit))

const isNew = computed(() => {
  return !currentData.application_id
})

const uploadLabel = computed(() => {
  return !isNew.value ? '変更' : '新規登録'
})

const fetch = async () => {
  try {
    const query = {
      form_no: 1,
      application_div : "num_exhibits",
    }
    const fetchApi = await api.get(ApiConst.API_APP_LIST, query)
    const res = fetchApi.response
    const errors = res.data?.error_results
    if (errors) {
      throw new Error('Not found.')
    }
    const data = res.data.results.list.map((el) => {
      return {
        application_id: el.application_id,
        input_date: moment(el.updated_at).format("YY-MM-DD"),
        seq_no: el.seq_no,
        ...el.json_data
      }
    })[0]
    forEach(currentData, (v, k) => {
      currentData[k] = data[k]
    })
    forEach(form, (v, k) => {
      form[k] = data[k] ?? formInit[k]
    })
  } catch (e) {
    console.log(e)
    return false
  }
}
fetch()

const editHandler = (event) => {
  modalOpen(event)
}

const scheme = {
  total: yup.number().typeError('数値を入力してください。').required('必須の項目です。')
    .min(0, '0以上の数値を入力してください。'),
  wp: yup.number().typeError('数値を入力してください。').required('必須の項目です。')
    .min(0, '0以上の数値を入力してください。'),
  jp: yup.number().typeError('数値を入力してください。').required('必須の項目です。')
    .min(0, '0以上の数値を入力してください。'),
}
const validate = new FormValidate(scheme, form)
const errors = validate.errors
const sendForm = async () => {
  try {
    if ((Object.keys(await validate.getErrorObject()).length)) {
      throw new Error('入力項目に不備があります。')
    }
    const params = cloneDeep(form)
    const application_id = params.application_id
    delete params.application_id
    const operation = application_id ? "R" : "I"
    const query = operation === "I"
      ? {operation, application_div : "num_exhibits", form_no: 1, json_data: params}
      : {operation, application_id, json_data: params}
    const res = await api.post(ApiConst.API_APP_EDIT, query)
    if (res.response.data?.error_results) {
      throw new Error('API Validation Error')
    }
    fetch()
    modalClose()
  } catch (e) {
    return false
  }
}
</script>

<style></style>
