<template>
  <header>
    <h1>JAPAN MOBILITY SHOW 2025&emsp;出展手続きサイト・出展者専用サイト</h1>
  </header>
  <article>
    <h2>二次申込（出展申込書の作成）</h2>
    <div id="form_ctn" class="form_box">
      
      <ErrorComponent></ErrorComponent>

      <SecondSteps step="1" />
      <h3>申込小間面積</h3>

      <div v-if="indoorVisible">
        <h4>乗用車、商用車、二輪車、車体（屋内）部門</h4>
        <div class="border_box">
          <h5 class="required">申込小間面積</h5>

          <my-input name="booth_area" width_class="quarter" type="number" v-model="booth_area" :step="step" min="0">
          </my-input>
          m<sup>2</sup>
          <p class="alert" v-if="errors.booth_area">{{ errors.booth_area }}</p>
          <dl class="exhibition_fee_list">
            <dt>出展料</dt>
            <dd>¥ {{ indoor_exhibition_fee }}<span>（申込面積×@{{ indoor_coefficient }}）（消費税込）</span></dd>
          </dl>
          <dl class="exhibition_fee_list">
            <dt>出展申込金</dt>
            <dd>¥ {{ indoor_application_fee }}<span>（申込面積の50%×@{{ indoor_coefficient }}）（消費税込）</span></dd>
          </dl>
        </div>
      </div>


      <div v-else-if="outdoorVisible">
        <h4>車体（屋外）部門</h4>
        <div class="border_box">
          <h5 class="required">申込小間面積</h5>

          <my-input name="booth_area" width_class="quarter" type="number" v-model="booth_area" :step="step" min="0">
          </my-input>
          m<sup>2</sup>
          <p class="alert" v-if="errors.booth_area">{{ errors.booth_area }}</p>
          <dl class="exhibition_fee_list">
            <dt>出展料</dt>
            <dd>¥ {{ outdoor_exhibition_fee }}<span>（申込面積×@{{ outdoor_coefficient }}）（消費税込）</span></dd>
          </dl>
          <dl class="exhibition_fee_list">
            <dt>出展申込金</dt>
            <dd>¥ {{ outdoor_application_fee }}<span>（申込面積の50%×@{{ outdoor_coefficient }}）（消費税込）</span></dd>
          </dl>
        </div>
      </div>

      <div v-else-if="partsMemberVisible">
        <h4>部品・機械器具部門（JAPIA、JAMTAの正会員）</h4>
        <div class="border_box">
          <h5 class="required">申込小間面積</h5>

          <my-input name="booth_area" width_class="quarter" type="number" v-model="booth_area" :step="step" min="0">
          </my-input>
          小間
          <p class="alert" v-if="errors.booth_area">{{ errors.booth_area }}</p>
          <dl class="exhibition_fee_list">
            <dt>出展料</dt>
            <dd>¥ {{ parts_member_exhibition_fee }}<span>（申込面積×@{{ parts_member_coefficient }}）（消費税込）</span></dd>
          </dl>
          <dl class="exhibition_fee_list">
            <dt>出展申込金</dt>
            <dd>¥ {{ parts_member_application_fee }}<span>（申込面積の50%×@{{ parts_member_coefficient }}）（消費税込）</span></dd>
          </dl>
        </div>
      </div>

      <div v-else-if="partsVisible">
        <h4>部品・機械器具部門（上記団体非会員）</h4>
        <div class="border_box">
          <h5 class="required">申込小間面積</h5>

          <my-input name="booth_area" width_class="quarter" type="number" v-model="booth_area" :step="step" min="0">
          </my-input>
          小間
          <p class="alert" v-if="errors.booth_area">{{ errors.booth_area }}</p>
          <dl class="exhibition_fee_list">
            <dt>出展料</dt>
            <dd>¥ {{ parts_exhibition_fee }}<span>（申込面積×@{{ parts_coefficient }}）（消費税込）</span></dd>
          </dl>
          <dl class="exhibition_fee_list">
            <dt>出展申込金</dt>
            <dd>¥ {{ parts_application_fee }}<span>（申込面積の50%×@{{ parts_coefficient }}）（消費税込）</span></dd>
          </dl>
        </div>
      </div>

      <div v-else-if="mobilityMemberVisible">
        <h4>モビリティ関連</h4>
        <div class="border_box">
          <h5 class="required">申込小間面積</h5>

          <my-input name="booth_area" width_class="quarter" type="number" v-model="booth_area" :step="step" min="0">
          </my-input>
          小間
          <p class="alert" v-if="errors.booth_area">{{ errors.booth_area }}</p>
          <dl class="exhibition_fee_list">
            <dt>出展料</dt>
            <dd>¥ {{ mobility_member_exhibition_fee }}<span>（申込面積×@{{ mobility_member_coefficient }}）（消費税込）</span></dd>
          </dl>
          <dl class="exhibition_fee_list">
            <dt>出展申込金</dt>
            <dd>¥ {{ mobility_member_application_fee }}<span>（申込面積の50%×@{{ mobility_member_coefficient }}）（消費税込）</span></dd>
          </dl>
        </div>
      </div>

      <div v-else-if="mobilityVisible">
        <h4>モビリティ関連</h4>
        <div class="border_box">
          <h5 class="required">申込小間面積</h5>

          <my-input name="booth_area" width_class="quarter" type="number" v-model="booth_area" :step="step" min="0">
          </my-input>
          小間
          <p class="alert" v-if="errors.booth_area">{{ errors.booth_area }}</p>
          <dl class="exhibition_fee_list">
            <dt>出展料</dt>
            <dd>¥ {{ mobility_exhibition_fee }}<span>（申込面積×@{{ mobility_coefficient }}）（消費税込）</span></dd>
          </dl>
          <dl class="exhibition_fee_list">
            <dt>出展申込金</dt>
            <dd>¥ {{ mobility_application_fee }}<span>（申込面積の50%×@{{ mobility_coefficient }}）（消費税込）</span></dd>
          </dl>
        </div>
      </div>

      <div v-else-if="kidzaniaMemberVisible">
        <h4>Out of KidZania in JMS 2025（JAMA、JAPIA、JABIA、JAMTA、JAIAの正会員）</h4>
        <div class="border_box">
          <h5 class="required">申込小間面積</h5>

          <my-input name="booth_area" width_class="quarter" type="number" v-model="booth_area" :step="step" min="0">
          </my-input>
          小間

          <p class="alert" v-if="errors.booth_area">{{ errors.booth_area }}</p>
          <dl class="exhibition_fee_list">
            <dt>出展料</dt>
            <dd>¥ {{ kidzania_member_exhibition_fee }}<span>（申込面積×@{{ kidzania_member_coefficient }}）（消費税込）</span></dd>
          </dl>
          <dl class="exhibition_fee_list">
            <dt>出展申込金</dt>
            <dd>¥ {{ kidzania_member_application_fee }}<span>（申込面積の50%×@{{ kidzania_member_coefficient }}）（消費税込）</span></dd>
          </dl>

        </div>
      </div>

      <div v-else-if="kidzaniaVisible">
        <h4>Out of KidZania in JMS 2025（JAMA、JAPIA、JABIA、JAMTA、JAIAの非会員）</h4>
        <div class="border_box">
          <h5 class="required">申込小間面積</h5>

          <my-input name="booth_area" width_class="quarter" type="number" v-model="booth_area" :step="step" min="0">
          </my-input>
          小間

          <p class="alert" v-if="errors.booth_area">{{ errors.booth_area }}</p>
          <dl class="exhibition_fee_list">
            <dt>出展料</dt>
            <dd>¥ {{ kidzania_exhibition_fee }}<span>（申込面積×@{{ kidzania_coefficient }}）（消費税込）</span></dd>
          </dl>
          <dl class="exhibition_fee_list">
            <dt>出展申込金</dt>
            <dd>¥ {{ kidzania_application_fee }}<span>（申込面積の50%×@{{ kidzania_coefficient }}）（消費税込）</span></dd>
          </dl>

        </div>
      </div>


      <div v-else-if="otherVisible">
        <h4>その他部門</h4>
        <div class="border_box">
          <h5 class="required">申込小間面積</h5>

          <my-input name="booth_area" width_class="quarter" type="number" v-model="booth_area" :step="step" min="0">
          </my-input>
          小間
          <p class="alert" v-if="errors.booth_area">{{ errors.booth_area }}</p>
          <dl class="exhibition_fee_list">
            <dt>出展料</dt>
            <dd>¥ {{ other_exhibition_fee }}<span>（申込面積×@{{ other_coefficient }}）（消費税込）</span></dd>
          </dl>
          <dl class="exhibition_fee_list">
            <dt>出展申込金</dt>
            <dd>¥ {{ other_application_fee }}<span>（申込面積の50%×@{{ other_coefficient }}）（消費税込）</span></dd>
          </dl>
        </div>
      </div>

      <div v-else>
        <p>登録された会員区分と出展カテゴリーが合いませんでした。<br>
          <router-link to="/second/form01">出展カテゴリーを選択しなおす</router-link>か自工会までご連絡ください。
        </p>
      </div>


      <h3 class="required">共同出展/隣接配置</h3>
      <p>同一部門での共同出展または隣接配置を希望する場合は、その会社名（出展者に限る）をご入力ください。但し、両社の同意が必要となります。また、小間位置の指定はできません。</p>
      <ul class="common_list_horizontal">
        <my-radio v-model="joint_adjacent" name="joint_adjacent" :options="joint_adjacent_options">
        </my-radio>
        <p class="alert" v-if="errors.joint_adjacent">{{ errors.joint_adjacent }}</p>
      </ul>
      <transition name="fade">
        <div v-if="joint_adjacent === '1'">
          <div class="border_box mblh">
            <h4>共同出展</h4>
            <my-input name="joint_exhibition" width_class="full" type="text" v-model="joint_exhibition"></my-input>
            <p class="alert" v-if="errors.joint_exhibition">{{ errors.joint_exhibition }}</p>
          </div>

          <div class="border_box">
            <h4>隣接配置</h4>
            <my-input name="adjacent_exhibition" width_class="full" type="text" v-model="adjacent_exhibition">
            </my-input>
            <p class="alert" v-if="errors.adjacent_exhibition">{{ errors.adjacent_exhibition }}</p>
          </div>
        </div>
      </transition>

      <div class="button_ctn">
        <button class="submit" @click="sendForm">出展者の入力へ</button>
      </div>


    </div>
  </article>
</template>

<script>
import APIClient from '@/store/repository/APIClient'
import MyInput from '@/components//MyInput.vue';
import MyRadio from '@/components//MyRadio.vue';
import ErrorComponent from '@/components/ErrorComponent.vue';

import { useField, useForm } from 'vee-validate';
import * as yup from 'yup';
import { useRouter } from 'vue-router'
import { store } from '@/store'
import SecondSteps from './SecondSteps.vue';

import {
  indoorVisible,
  outdoorVisible,
  partsMemberVisible,
  partsVisible,
  mobilityMemberVisible,
  mobilityVisible,
  kidzaniaMemberVisible,
  kidzaniaVisible,
  otherVisible
} from '@/helper/categoryVisible';


const INDOOR_COEFFICIENT = 34700
const OUTDOOR_COEFFICIENT = 11600
const PARTS_MEMBER_COEFFICIENT = 411200
const PARTS_COEFFICIENT = 496700
const MOBILITY_MEMBER_COEFFICIENT = 411200
const MOBILITY_COEFFICIENT = 496700
const KIDZANIA_MEMBER_COEFFICIENT = 575700
const KIDZANIA_COEFFICIENT = 695400
const OTHER_COEFFICIENT = 496700
const SALES_TAX_RATE = 1.0
const APPLICATION_FEE_RATE_PER_AREA = 0.5

export default {
  components: {
    MyInput,
    MyRadio,
    ErrorComponent,
    SecondSteps,
  },
  setup() {
    const router = useRouter();




    const schema = yup.object({
      booth_area: yup.number().min(1, '最小小間面積は1です。').typeError("必須の項目です。半角数字を入力してください。").test(
        "booth_area",
        "偶数単位でお申し込みください。共同出展をご希望の方のみ1小間でお申し込みいただけます。",
        function (value) {
          if (['30', '40', '50', '99'].some(item => store.state.entrydata.category_div.category_div.includes(item))) {
            if (this.parent.joint_adjacent == 1) {
              if (value == 1) return true
            }
            if ((value % 2) == 0) return true
          }
          if (['10', '20', '60'].some(item => store.state.entrydata.category_div.category_div.includes(item))) {
            return true
          }
          return false
        }
      ),


      joint_adjacent: yup.string().required('必須の項目です。'),
      joint_exhibition: yup.string().test(
        "joint_exhibition",
        "同一部門での共同出展または隣接配置を希望する場合は、共同出展もしくは隣接配置の出展社名を必ず数字入力してください。",
        function (value) {
          const var_joint_adjacent = this.parent.joint_adjacent;
          const var_adjacent_exhibition = this.parent.adjacent_exhibition;
          if (var_joint_adjacent == 1) return true
          if (var_adjacent_exhibition !== "") return true
          return (value)
        }
      ),
      adjacent_exhibition: yup.string().test(
        "adjacent_exhibition",
        "同一部門での共同出展または隣接配置を希望する場合は、共同出展もしくは隣接配置の出展社名を必ず入力してください。",
        function (value) {
          const var_joint_adjacent = this.parent.joint_adjacent;
          const var_joint_exhibition = this.parent.joint_exhibition;
          if (var_joint_adjacent == 1) return true
          if (var_joint_exhibition !== "") return true
          return (value)
        }
      )
    });

    const { errors, handleSubmit } = useForm({
      validationSchema: schema,
    });

    const onInvalidSubmit = () => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    }

    const sendForm = handleSubmit(() => {
      router.push({ path: '/second/form03' });
    }, onInvalidSubmit);

    const { value: booth_area } = useField('booth_area', null, {
      validateOnValueUpdate: false,
    });
    const { value: joint_adjacent } = useField('joint_adjacent', null, {
      validateOnValueUpdate: false,
    });
    const { value: joint_exhibition } = useField('joint_exhibition', null, {
      validateOnValueUpdate: false,
    });
    const { value: adjacent_exhibition } = useField('adjacent_exhibition', null, {
      validateOnValueUpdate: false,
    });
    return {
      errors,
      joint_adjacent,
      booth_area,

      joint_exhibition,
      adjacent_exhibition,

      sendForm,
    };
  },
  data() {
    return {

      exhibition_fee: "0",
      application_fee: "0",
      indoor_coefficient: INDOOR_COEFFICIENT.toLocaleString(),
      outdoor_coefficient: OUTDOOR_COEFFICIENT.toLocaleString(),
      parts_member_coefficient: PARTS_MEMBER_COEFFICIENT.toLocaleString(),
      parts_coefficient: PARTS_COEFFICIENT.toLocaleString(),
      mobility_member_coefficient: MOBILITY_MEMBER_COEFFICIENT.toLocaleString(),
      mobility_coefficient: MOBILITY_COEFFICIENT.toLocaleString(),
      kidzania_member_coefficient: KIDZANIA_MEMBER_COEFFICIENT.toLocaleString(),
      kidzania_coefficient: KIDZANIA_COEFFICIENT.toLocaleString(),
      other_coefficient: OTHER_COEFFICIENT.toLocaleString(),
      calced_application_fee: "",
      calced_exhibition_fee: "",
      calced_unit_price: "",
      calced_unit: "",

    }
  },
  async created() {
    this.api = new APIClient()
    const res = await this.api.getConfig()

    this.$store.commit('disicion/joint_adjacent_options', res.data.results.joint_adjacent_options)


  },
  mounted() {
    this.inputStoreData('category_div')
    this.inputStoreData('booth_area')
    this.inputStoreData('joint_adjacent')
    this.inputStoreData('joint_exhibition')
    this.inputStoreData('adjacent_exhibition')
    this.inputStoreData('membership_category')

    
    //各カテゴリによって単価を一つの変数に入れる

    if (this.indoorVisible) {
      this.calced_unit_price = this.indoor_coefficient
    }
    else if (this.outdoorVisible) {
      this.calced_unit_price = this.outdoor_coefficient
    }
    else if (this.partsMemberVisible) {
      this.calced_unit_price = this.parts_member_coefficient
    }
    else if (this.partsVisible) {
      this.calced_unit_price = this.parts_coefficient
    }
    else if (this.mobilityMemberVisible) {
      this.calced_unit_price = this.mobility_member_coefficient
    }
    else if (this.mobilityVisible) {
      this.calced_unit_price = this.mobility_coefficient
    }
    else if (this.kidzaniaMemberVisible) {
      this.calced_unit_price = this.kidzania_member_coefficient
    }
    else if (this.kidzaniaVisible) {
      this.calced_unit_price = this.kidzania_coefficient
    }
    else if (this.otherVisible) {
      this.calced_unit_price = this.other_coefficient
    }
    else {
      this.calced_unit_price = 0
    }

    //各カテゴリによって単位を一つの変数に入れる

    if (this.indoorVisible) {
      this.calced_unit = 'm2'
    }
    else if (this.outdoorVisible) {
      this.calced_unit = 'm2'
    }
    else if (this.partsMemberVisible) {
      this.calced_unit = '小間'
    }
    else if (this.partsVisible) {
      this.calced_unit = '小間'
    }
    else if (this.mobilityVisible) {
      this.calced_unit = '小間'
    }
    else if (this.kidzaniaMemberVisible) {
      this.calced_unit = '小間'
    }
    else if (this.kidzaniaVisible) {
      this.calced_unit = '小間'
    }
    else if (this.otherVisible) {
      this.calced_unit = '小間'
    }
    else {
      this.calced_unit = 0
    }

  },
  methods: {
    inputStoreData(key) {
      if (!this.$store.state.entrydata[key]) return;
      this[key] = this.$store.state.entrydata[key]
    },


  },
  watch: {
    booth_area: function (v) {
      this.$store.commit('entrydata/booth_area', v)
    },
    indoor_exhibition_fee: function (v) {
      this.$store.commit('entrydata/indoor_exhibition_fee', v)
      if (this.indoorVisible == true) {
        this.$store.commit('entrydata/calced_exhibition_fee', v)
      }
    },
    indoor_application_fee: function (v) {
      this.$store.commit('entrydata/indoor_application_fee', v)
      if (this.indoorVisible == true) {
        this.$store.commit('entrydata/calced_application_fee', v)
      }
    },
    outdoor_exhibition_fee: function (v) {
      this.$store.commit('entrydata/outdoor_exhibition_fee', v)
      if (this.outdoorVisible === true) {
        this.$store.commit('entrydata/calced_exhibition_fee', v)
      }
    },
    outdoor_application_fee: function (v) {
      this.$store.commit('entrydata/outdoor_application_fee', v)
      if (this.outdoorVisible == true) {
        this.$store.commit('entrydata/calced_application_fee', v)
      }
    },
    parts_member_exhibition_fee: function (v) {
      this.$store.commit('entrydata/parts_member_exhibition_fee', v)
      if (this.partsMemberVisible === true) {
        this.$store.commit('entrydata/calced_exhibition_fee', v)
      }
    },
    parts_member_application_fee: function (v) {
      this.$store.commit('entrydata/parts_member_application_fee', v)
      if (this.partsMemberVisible == true) {
        this.$store.commit('entrydata/calced_application_fee', v)
      }
    },
    parts_exhibition_fee: function (v) {
      this.$store.commit('entrydata/parts_exhibition_fee', v)
      if (this.partsVisible == true) {
        this.$store.commit('entrydata/calced_exhibition_fee', v)
      }
    },
    parts_application_fee: function (v) {
      this.$store.commit('entrydata/parts_application_fee', v)
      if (this.partsVisible == true) {
        this.$store.commit('entrydata/calced_application_fee', v)
      }
    },
    mobility_member_exhibition_fee: function (v) {
      this.$store.commit('entrydata/mobility_member_exhibition_fee', v)
      if (this.mobilityMemberVisible == true) {
        this.$store.commit('entrydata/calced_exhibition_fee', v)
      }
    },
    mobility_member_application_fee: function (v) {
      this.$store.commit('entrydata/mobility_member_application_fee', v)
      if (this.mobilityMemberVisible == true) {
        this.$store.commit('entrydata/calced_application_fee', v)
      }
    },
    mobility_exhibition_fee: function (v) {
      this.$store.commit('entrydata/mobility_exhibition_fee', v)
      if (this.mobilityVisible == true) {
        this.$store.commit('entrydata/calced_exhibition_fee', v)
      }
    },
    mobility_application_fee: function (v) {
      this.$store.commit('entrydata/mobility_application_fee', v)
      if (this.mobilityVisible == true) {
        this.$store.commit('entrydata/calced_application_fee', v)
      }
    },
    kidzania_member_exhibition_fee: function (v) {
      this.$store.commit('entrydata/kidzania_member_exhibition_fee', v)
      if (this.kidzaniaMemberVisible === true) {
        this.$store.commit('entrydata/calced_exhibition_fee', v)
      }
    },
    kidzania_member_application_fee: function (v) {
      this.$store.commit('entrydata/kidzania_member_application_fee', v)
      if (this.kidzaniaMemberVisible == true) {
        this.$store.commit('entrydata/calced_application_fee', v)
      }
    },
    kidzania_exhibition_fee: function (v) {
      this.$store.commit('entrydata/kidzania_exhibition_fee', v)
      if (this.kidzaniaVisible == true) {
        this.$store.commit('entrydata/calced_exhibition_fee', v)
      }
    },
    kidzania_application_fee: function (v) {
      this.$store.commit('entrydata/kidzania_application_fee', v)
      if (this.kidzaniaVisible == true) {
        this.$store.commit('entrydata/calced_application_fee', v)
      }
    },

    other_exhibition_fee: function (v) {
      this.$store.commit('entrydata/other_exhibition_fee', v)
      if (this.otherVisible == true) {
        this.$store.commit('entrydata/calced_exhibition_fee', v)
      }
    },
    other_application_fee: function (v) {
      this.$store.commit('entrydata/other_application_fee', v)
      if (this.otherVisible == true) {
        this.$store.commit('entrydata/calced_application_fee', v)
      }
    },

    calced_unit_price: function (v) {

      this.$store.commit('entrydata/calced_unit_price', v)
    },
    calced_unit: function (v) {
      this.$store.commit('entrydata/calced_unit', v)
    },

    joint_adjacent: function (v) {
      this.$store.commit('entrydata/joint_adjacent', v)
    },
    joint_exhibition: function (v) {
      this.$store.commit('entrydata/joint_exhibition', v)
    },
    adjacent_exhibition: function (v) {
      this.$store.commit('entrydata/adjacent_exhibition', v)
    },


  },
  computed: {
    state() {
      return this.$store.state.entrydata
    },
    joint_adjacent_options() {
      return this.$store.state.disicion.joint_adjacent_options
    },
    category_div() {
      return this.$store.state.entrydata.category_div
    },
    membership_category() {
      return this.$store.state.entrydata.membership_category
    },
    indoor_exhibition_fee() {
      if (this.booth_area == null) return 0
      return Math.round(this.booth_area * INDOOR_COEFFICIENT * SALES_TAX_RATE).toLocaleString();
    },
    indoor_application_fee() {
      if (this.booth_area == null) return 0
      return Math.round(this.booth_area * APPLICATION_FEE_RATE_PER_AREA * INDOOR_COEFFICIENT * SALES_TAX_RATE).toLocaleString();
    },
    outdoor_exhibition_fee() {
      if (this.booth_area == null) return 0
      return Math.round(this.booth_area * OUTDOOR_COEFFICIENT * SALES_TAX_RATE).toLocaleString();
    },
    outdoor_application_fee() {
      if (this.booth_area == null) return 0
      return Math.round(this.booth_area * APPLICATION_FEE_RATE_PER_AREA * OUTDOOR_COEFFICIENT * SALES_TAX_RATE).toLocaleString();
    },
    parts_member_exhibition_fee() {
      if (this.booth_area == null) return 0
      return Math.round(this.booth_area * PARTS_MEMBER_COEFFICIENT * SALES_TAX_RATE).toLocaleString();
    },
    parts_member_application_fee() {
      if (this.booth_area == null) return 0
      return Math.round(this.booth_area * APPLICATION_FEE_RATE_PER_AREA * PARTS_MEMBER_COEFFICIENT * SALES_TAX_RATE).toLocaleString();
    },
    parts_exhibition_fee() {
      if (this.booth_area == null) return 0
      return Math.round(this.booth_area * PARTS_COEFFICIENT * SALES_TAX_RATE).toLocaleString();
    },
    parts_application_fee() {
      if (this.booth_area == null) return 0
      return Math.round(this.booth_area * APPLICATION_FEE_RATE_PER_AREA * PARTS_COEFFICIENT * SALES_TAX_RATE).toLocaleString();
    },
    mobility_member_exhibition_fee() {
      if (this.booth_area == null) return 0
      return Math.round(this.booth_area * MOBILITY_MEMBER_COEFFICIENT * SALES_TAX_RATE).toLocaleString();
    },
    mobility_member_application_fee() {
      if (this.booth_area == null) return 0
      return Math.round(this.booth_area * APPLICATION_FEE_RATE_PER_AREA * MOBILITY_MEMBER_COEFFICIENT * SALES_TAX_RATE).toLocaleString();
    },
    mobility_exhibition_fee() {
      if (this.booth_area == null) return 0
      return Math.round(this.booth_area * MOBILITY_COEFFICIENT * SALES_TAX_RATE).toLocaleString();
    },
    mobility_application_fee() {
      if (this.booth_area == null) return 0
      return Math.round(this.booth_area * APPLICATION_FEE_RATE_PER_AREA * MOBILITY_COEFFICIENT * SALES_TAX_RATE).toLocaleString();
    },
    kidzania_member_exhibition_fee() {
      if (this.booth_area == null) return 0
      return Math.round(this.booth_area * KIDZANIA_MEMBER_COEFFICIENT * SALES_TAX_RATE).toLocaleString();
    },
    kidzania_member_application_fee() {
      if (this.booth_area == null) return 0
      return Math.round(this.booth_area * APPLICATION_FEE_RATE_PER_AREA * KIDZANIA_MEMBER_COEFFICIENT * SALES_TAX_RATE).toLocaleString();
    },
    kidzania_exhibition_fee() {
      if (this.booth_area == null) return 0
      return Math.round(this.booth_area * KIDZANIA_COEFFICIENT * SALES_TAX_RATE).toLocaleString();
    },
    kidzania_application_fee() {
      if (this.booth_area == null) return 0
      return Math.round(this.booth_area * APPLICATION_FEE_RATE_PER_AREA * KIDZANIA_COEFFICIENT * SALES_TAX_RATE).toLocaleString();
    },

    other_exhibition_fee() {
      if (this.booth_area == null) return 0
      return Math.round(this.booth_area * OTHER_COEFFICIENT * SALES_TAX_RATE).toLocaleString();

    },
    other_application_fee() {
      if (this.booth_area == null) return 0
      return Math.round(this.booth_area * APPLICATION_FEE_RATE_PER_AREA * OTHER_COEFFICIENT * SALES_TAX_RATE).toLocaleString();
    },

    indoorVisible() {
      return indoorVisible(this)
    },
    outdoorVisible() {
      return outdoorVisible(this)
    },
    partsMemberVisible() {
      return partsMemberVisible(this)
    },
    partsVisible() {
      return partsVisible(this)
    },
    mobilityMemberVisible() {
      return mobilityMemberVisible(this)
    },
    mobilityVisible() {
      return mobilityVisible(this)
    },
    kidzaniaMemberVisible() {
      return kidzaniaMemberVisible(this)
    },
    kidzaniaVisible() {
      return kidzaniaVisible(this)
    },
    otherVisible() {
      return otherVisible(this)
    },


  }
};
</script>

<style>

</style>
