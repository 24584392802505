<template>
  <header><h1>JAPAN MOBILITY SHOW 2025&emsp;出展手続きサイト・出展者専用サイト</h1></header>
  <article><h2>個人情報の取扱いについて</h2>
  <div id="form_ctn" class="form_box">
    <SecondSteps step="1" />
    <h3>出展に関する規程</h3>
    <div class="regulations_ctn">
            <iframe src="/docs/2025/regulation/regulation.pdf" width="100%" height="100%"></iframe>

          </div>
    <label><input type="checkbox" v-model="regulation_check"><span
        class="checkbox_text">上記の出展に関する規程に同意します。</span></label>

    <h3>個人情報の取扱いについて</h3>
    <div class="privacy_ctn">
      <p>JAPAN MOBILITY SHOW 2025（以下「本ショー」といいます。）の主催者である、一般社団法人 日本自動車工業会（以下「事務局」といいます。）は、個人情報取得に際し、適法かつ公正な手段によって行なうものとします。出展のお申込、及び各種申請で取得した個人情報は、当会主催の本ショーの運営及び各種業務を円滑に行なう目的に限定し、事務局及び事務局関係者（業務委託先含む）が共同使用致します。（管理責任者：事務局、所在地：東京都港区芝大門1-1-30 日本自動車会館、副会長・専務理事：松永明）<br>
        取得した個人情報は、法令の定める場合を除き、事前に本人の同意を得ることなく、事務局及び事務局関係者以外の第三者に提供致しません。また、業務委託先には個人情報を厳密に管理するよう義務付けます。担当者の連絡先等が変更になる場合は必ず事務局に届け出てください。</p>
    </div>

    <label><input type="checkbox" v-model="privacy_check"><span
        class="checkbox_text">上記の個人情報の取扱についての方針に同意します。</span></label>

    <div class="button_ctn">

      <div class="common_div_button" @click="submit" :class="!isValid?'gray_button':''">出展分類の入力へ</div>

    </div>

  </div>
</article>
</template>

<script>
import SecondSteps from './SecondSteps.vue';
export default {
  components: {
    SecondSteps,
  },
  data() {
    return {
      regulation_check: false,
      privacy_check:false,
    }
  },
  methods:{
    submit(){
      if (this.isValid){
        this.$router.push({ name: 'second_form01'})
      }
    }
  },
  computed:{
    isValid(){
      if (this.regulation_check === false) return false
      if (this.privacy_check === false) return false

      return true
    }
  }

}
</script>

<style>
</style>
