<template>
  <fieldset>
    <label v-for="(option, index) in options" :key="index">
      <input type="checkbox" :name="name" :value="option.value" :checked="isChecked(option, modelValue)" @change="updateValue" :ref="setValuesRef" />
      <span class="checkbox_text">{{ option.label }}</span>
    </label>
  </fieldset>
</template>

<script>
export default {
  name: "MyCheckbox",
  props: {
    modelValue: { type: Array, required: true },
    options: { type: Array, required: true },
    name: { type: String, required: true },
  },
  data() {
    return {
      values: []
    };
  },
  beforeUpdate() {
    this.values = []
  },
  methods: {
    setValuesRef(el) {
      if (el) {
        this.values.push(el)
      }
    },
    updateValue: function () {
      const list = []
      this.values.forEach(item => {
        if (item.checked) {
          list.push(item.value);
        }
      })
      this.$emit("update:modelValue", list);
    },
    isChecked: (option, value) => {
      if (value?.includes(option.value)) {
        return true
      }
      return false
    }
  },
};
</script>
