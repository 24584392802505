<template>
  <table class="common_table">
    <tbody>
      <tr>
        <th colspan="2">コース</th>
        <th>単価</th>
        <th>申込数量</th>
        <th>計</th>
        <th class="w20em">備考</th>
      </tr>
      <tr>
        <td rowspan="2">共有回線インターネット</td>
        <td>最大10Mbps</td>
        <td class="ta_right">{{ sharedLine10mUnit.toLocaleString() }}円</td>
        <td class="ta_right">{{ unregisteredOrDigit(currentData.sharedLine10m) }}</td>
        <td class="ta_right">{{ calcCurrent.sharedLine10m.toLocaleString() }}円</td>
        <td rowspan="2">共有回線、ブロードバンドルーター（LANポート×4口）での提供<br>※通常のインターネットやE-mailご利用向け</td>
      </tr>
      <tr>
        <td>最大100Mbps</td>
        <td class="ta_right">{{ sharedLine100mUnit.toLocaleString() }}円</td>
        <td class="ta_right">{{ unregisteredOrDigit(currentData.sharedLine100m) }}</td>
        <td class="ta_right">{{ calcCurrent.sharedLine100m.toLocaleString() }}円</td>
      </tr>
      <tr>
        <td rowspan="2">光高速通信回線<br>（専用回線）</td>
        <td style="white-space:nowrap;">タイプＡ<br>（最大10Mbps）</td>
        <td class="ta_right">{{ privateLine10mUnit.toLocaleString() }}円</td>
        <td class="ta_right">{{ unregisteredOrDigit(currentData.privateLine10m) }}</td>
        <td class="ta_right">{{ calcCurrent.privateLine10m.toLocaleString() }}円</td>
        <td rowspan="2">専用回線、UTPケーブルでの提供<br>※映像伝送などの大容量通信向け<br>※ルーターや固定IPアドレスが必要な場合はオプションでお申し込みください。</td>
      </tr>
      <tr>
        <td style="white-space:nowrap;">タイプＢ<br>（最大100Mbps）</td>
        <td class="ta_right">{{ privateLine100mUnit.toLocaleString() }}円</td>
        <td class="ta_right" style="white-space:nowrap;">{{ unregisteredOrDigit(currentData.privateLine100m) }}</td>
        <td class="ta_right">{{ calcCurrent.privateLine100m.toLocaleString() }}円</td>
      </tr>
      <tr bgcolor="#FFFFFF">
        <td colspan="2" nowrap="">&nbsp;</td>
        <td colspan="2" nowrap="" class="ta_right">合計金額（消費税込）</td>
        <td class="ta_right">{{ calcCurrent.total.toLocaleString() }}円</td>
        <td>&nbsp;</td>
      </tr>
    </tbody>
  </table>
  <template v-if="isShowUI">
  <div><a v-if="isNew" @click.prevent="newHandler" href="#form1" class="common_add_button modalOpen">新規追加</a><a v-else
      @click.prevent="editHandler" href="#form1" class="common_add_button modalOpen">変更</a>&nbsp;&nbsp;<a
      @click.prevent="deleteHandler" href="#form1" class="common_add_button gray_button">クリア</a></div>
  </template>
  <Teleport to="body">
    <!-- モーダルコンテンツ / -->
    <div class="overlay" id="form1">
      <div id="modal_content" class="modal_contents w80">
        <div class="modalClose_ctn"><a @click.prevent="modalClose" class="modalClose"></a></div>
        <!-- modal contents ここから-->
        <div class="entry_title">
          <h4>臨時通信設備使用申込（高速通信回線）</h4>
          <h5>回線申込&nbsp;&nbsp;{{ uploadLabel }}</h5>

          <div class="entry_modal_ctn">
            <table class="common_table">
              <tbody>
                <tr>
                  <th colspan="2">コース</th>
                  <th>単価</th>
                  <th>申込数量</th>
                  <th>計</th>
                  <th class="w20em">備考</th>
                </tr>
                <tr>
                  <td rowspan="2">共有回線インターネット</td>
                  <td>最大10Mbps</td>
                  <td class="ta_right">{{ sharedLine10mUnit.toLocaleString() }}円</td>
                  <td class="ta_right"><input v-model="form.sharedLine10m" type="number" size="5" min="0" class="w3em">
                    <p class="alert" v-if="errors.sharedLine10m">{{ errors.sharedLine10m }}</p></td>
                  <td class="ta_right">{{ calcForm.sharedLine10m.toLocaleString() }}円</td>
                  <td rowspan="2">共有回線、ブロードバンドルーター（LANポート×4口）での提供<br>※通常のインターネットやE-mailご利用向け</td>
                </tr>
                <tr>
                  <td>最大100Mbps</td>
                  <td class="ta_right">{{ sharedLine100mUnit.toLocaleString() }}円</td>
                  <td class="ta_right"><input v-model="form.sharedLine100m" type="number" size="5" min="0" class="w3em">
                    <p class="alert" v-if="errors.sharedLine100m">{{ errors.sharedLine100m }}</p></td>
                  <td class="ta_right">{{ calcForm.sharedLine100m.toLocaleString() }}円</td>
                </tr>
                <tr>
                  <td rowspan="2">光高速通信回線<br>（専用回線）</td>
                  <td style="white-space:nowrap;">タイプＡ<br>（最大10Mbps）</td>
                  <td class="ta_right">{{ privateLine10mUnit.toLocaleString() }}円</td>
                  <td class="ta_right"><input v-model="form.privateLine10m" type="number" size="5" min="0" class="w3em">
                    <p class="alert" v-if="errors.privateLine10m">{{ errors.privateLine10m }}</p></td>
                  <td class="ta_right">{{ calcForm.privateLine10m.toLocaleString() }}円</td>
                  <td rowspan="2">専用回線、UTPケーブルでの提供<br>※映像伝送などの大容量通信向け<br>※ルーターや固定IPアドレスが必要な場合はオプションでお申し込みください。</td>
                </tr>
                <tr>
                  <td style="white-space:nowrap;">タイプＢ<br>（最大100Mbps）</td>
                  <td class="ta_right">{{ privateLine100mUnit.toLocaleString() }}円</td>
                  <td class="ta_right" style="white-space:nowrap;"><input v-model="form.privateLine100m" type="number" size="5" min="0" class="w3em">
                    <p class="alert" v-if="errors.privateLine100m">{{ errors.privateLine100m }}</p></td>
                  <td class="ta_right">{{ calcForm.privateLine100m.toLocaleString() }}円</td>
                </tr>
                <tr bgcolor="#FFFFFF">
                  <td colspan="2" nowrap="">&nbsp;</td>
                  <td colspan="2" nowrap="" class="ta_right">合計金額（消費税込）</td>
                  <td class="ta_right">{{ calcForm.total.toLocaleString() }}円</td>
                  <td>&nbsp;</td>
                </tr>
              </tbody>
            </table>


            <div class="button_ctn">
              <ul>
                <li><button @click="modalClose" class="gray_button modalClose">キャンセル</button></li>
                <li><button @click="sendForm" class="regist_button">上記内容で登録</button></li>
              </ul>
            </div>
            <!-- modal contents ここまで-->
          </div>
        </div>
        <!-- / モーダルコンテンツ -->
      </div>
    </div>
    <!-- / モーダルコンテンツ -->
  </Teleport>
</template>

<script setup>
import { reactive, computed } from 'vue'
import { sum, forEach, cloneDeep } from 'lodash'

import APIClient from '@/store/repository/APIClient'
import * as yup from 'yup'
import moment from "moment"
import { FormValidate } from '@/helper/FormValidate'

import {
  modalOpen,
  modalClose,
} from '@/helper/modal'

import ApiConst from '@/const/api'

import { isShowable as isShowUI } from '@/helper/indexStatus'

const api = new APIClient()

const formInit = {
  application_id: null,
  sharedLine10m: 0,
  sharedLine100m: 0,
  privateLine10m: 0,
  privateLine100m: 0,
}

const form = reactive(cloneDeep(formInit))
const currentData = reactive(cloneDeep(formInit))

const sharedLine10mUnit = 33000
const sharedLine100mUnit = 55000
const privateLine10mUnit = 77000
const privateLine100mUnit = 110000

const unregisteredOrDigit = (num) => {
  if (num) return num.toLocaleString()
  return '未登録'
}

const calcForm = computed(() => {
  return {
    sharedLine10m: form.sharedLine10m * sharedLine10mUnit,
    sharedLine100m: form.sharedLine100m * sharedLine100mUnit,
    privateLine10m: form.privateLine10m * privateLine10mUnit,
    privateLine100m: form.privateLine100m * privateLine100mUnit,
    total: sum([
      form.sharedLine10m * sharedLine10mUnit,
      form.sharedLine100m * sharedLine100mUnit,
      form.privateLine10m * privateLine10mUnit,
      form.privateLine100m * privateLine100mUnit,
    ])
  }
})

const calcCurrent = computed(() => {
  return {
    sharedLine10m: currentData.sharedLine10m * sharedLine10mUnit,
    sharedLine100m: currentData.sharedLine100m * sharedLine100mUnit,
    privateLine10m: currentData.privateLine10m * privateLine10mUnit,
    privateLine100m: currentData.privateLine100m * privateLine100mUnit,
    total: sum([
      currentData.sharedLine10m * sharedLine10mUnit,
      currentData.sharedLine100m * sharedLine100mUnit,
      currentData.privateLine10m * privateLine10mUnit,
      currentData.privateLine100m * privateLine100mUnit,
    ])
  }
})

const isNew = computed(() => {
  return !currentData.application_id
})

const uploadLabel = computed(() => {
  return !isNew.value ? '変更' : '新規登録'
})

const fetch = async () => {
  try {
    const query = {
      form_no: 1,
      application_div: "extra_comm2",
    }
    const fetchApi = await api.get(ApiConst.API_APP_LIST, query)
    const res = fetchApi.response
    const errors = res.data?.error_results
    if (errors) {
      throw new Error('Not found.')
    }
    forEach(currentData, (v, k) => {
      currentData[k] = formInit[k]
    })
    const data = res.data.results.list.map((el) => {
      return {
        application_id: el.application_id,
        input_date: moment(el.updated_at).format("YY-MM-DD"),
        seq_no: el.seq_no,
        ...el.json_data
      }
    })[0] ?? false
    if (!data) return

    forEach(currentData, (v, k) => {
      currentData[k] = data[k]
    })
    forEach(form, (v, k) => {
      form[k] = data[k] ?? formInit[k]
    })
  } catch (e) {
    console.log(e)
    return false
  }
}
fetch()

const newHandler = (event) => {
  forEach(formInit, (v, k) => {
    form[k] = v
  })
  modalOpen(event)
}

const editHandler = (event) => {
  modalOpen(event)
}

const deleteHandler = async () => {
  if (!window.confirm('クリアして宜しいでしょうか？')) return false

  try {
    const application_id = currentData.application_id
    const operation = "D"
    const query = { operation, application_id }
    const res = await api.post(ApiConst.API_APP_EDIT, query)
    if (res.response.data?.error_results) {
      throw new Error('API Validation Error')
    }
    fetch()
  } catch (e) {
    return false
  }
}

const scheme = {
  sharedLine10m: yup.number().typeError('数値を入力してください。'),
  sharedLine100m: yup.number().typeError('数値を入力してください。'),
  privateLine10m: yup.number().typeError('数値を入力してください。'),
  privateLine100m: yup.number().typeError('数値を入力してください。'),
}
const validate = new FormValidate(scheme, form)
const errors = validate.errors
const sendForm = async () => {
  try {
    if ((Object.keys(await validate.getErrorObject()).length)) {
      throw new Error('入力項目に不備があります。')
    }
    const params = cloneDeep(form)
    params.sharedLine10m_total_price = calcForm.value.sharedLine10m
    params.sharedLine100m_total_price = calcForm.value.sharedLine100m
    params.privateLine10m_total_price = calcForm.value.privateLine10m
    params.privateLine100m_total_price = calcForm.value.privateLine100m
    params.total_price = calcForm.value.total
    const application_id = params.application_id
    delete params.application_id
    const operation = application_id ? "R" : "I"
    const query = operation === "I"
      ? { operation, application_div: "extra_comm2", form_no: 1, json_data: params }
      : { operation, application_id, json_data: params }
    const res = await api.post(ApiConst.API_APP_EDIT, query)
    if (res.response.data?.error_results) {
      throw new Error('API Validation Error')
    }
    fetch()
    modalClose()
  } catch (e) {
    return false
  }
}
</script>

<style></style>
