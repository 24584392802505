<template>
  <fieldset class="select_ctn">
  <select
    :name="name"
    :v-model="name"
    @change="updateValue"
	>
		<option 
			v-for="(option,index) in options" 
			:key="index"
			:selected="option.value === modelValue"
      :value="option.value"
      >
			{{ option.label }}
		</option>
  </select>
  </fieldset>
</template>

<script>
export default {
  name: "MySelect",
  props: {
      modelValue: { type: String, required: true },
      options: { type: Array, required: true },
      name: { type: String, required: true },
      width_class: {
        type: String,
        required: false
      }

  },
  methods: {
    updateValue: function(e) {
      this.$emit('update:modelValue', e.target.value)
    }
  },
};
</script>
