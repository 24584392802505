<template>
  <table class="common_table" v-if="list.length">
    <tbody>
      <tr>
        <th rowspan="2">搬入日</th>
        <th rowspan="2">運搬車両</th>
        <th rowspan="2">搬入出方法</th>
        <th colspan="4">種</th>
        <th rowspan="2">備考</th>
        <th colspan="2" v-if="isShowUI">&nbsp;</th>
      </tr>
      <tr>
        <th>乗用車</th>
        <th>商用車</th>
        <th>二輪</th>
        <th>車体</th>
        <th colspan="2" v-if="isShowUI">編集</th>
      </tr>

      <tr v-for="(val, key) in list" :key="key">
        <td class="nowrap">{{ val.reassignment_date }}&nbsp;&nbsp;{{ val.reassignment_date_div }}</td>
        <td class="nowrap">{{ val.transport_vehicle }}&nbsp;&nbsp;{{ val.transport_vehicle_other }}</td>
        <td class="nowrap">{{ val.method }}&nbsp;&nbsp;{{ val.method_other }}</td>
        <td class="ta_right nowrap">{{ val.automobile_qty }}</td>
        <td class="ta_right nowrap">{{ val.commercial_qty }}</td>
        <td class="ta_right nowrap">{{ val.two_wheels_qty }}</td>
        <td class="ta_right nowrap">{{ val.body_qty }}</td>
        <td>{{val.note}}</td>
        <td class="edit_cell" v-if="isShowUI"><a @click.prevent="editHandler($event, val.application_id)" href="#form2" class="common_button_t2 modalOpen">変更</a></td>
        <td class="edit_cell" v-if="isShowUI"><a @click.prevent="deleteHandler($event, val.application_id)" class="common_button_t2 gray_button delete_button">削除</a></td>
      </tr>
    </tbody>
  </table>
  <div class="no_entry" v-else>登録がありません。「新規追加」ボタンより登録をお願いします。</div>
  <template v-if="isShowUI">
  <div>
    <a @click.prevent="newHandler" href="#form2" class="common_add_button modalOpen">新規追加</a>
  </div>
  </template>
  <Teleport to="body">

    <!-- モーダルコンテンツ / -->
    <!-- 製作注意問い合わせ先は何種類かあります。読み込み形式で簡単に編集できるようにしたほうが良いかと思います。-->
    <div class="overlay" id="form2">
      <div id="modal_content" class="modal_contents w80">
        <!-- modal contents ここから-->
        <div class="modalClose_ctn">
          <a @click.prevent="modalClose" class="modalClose"></a>
        </div>
        <div class="entry_title">
          <h4>計画搬入出調査書</h4>
          <div class="entry_title">
            <h5>展示車両とホール搬入出方法&nbsp;&nbsp;{{ uploadLabel }}</h5>
          </div>
        </div>
        <div class="entry_modal_ctn">
          <table class="common_table">
            <tbody>
              <tr>
                <th rowspan="2">搬入日</th>
                <th rowspan="2">運搬車両</th>
                <th rowspan="2">搬入出方法</th>
                <th colspan="4">種</th>
              </tr>
              <tr>
                <th>乗用車</th>
                <th>商用車</th>
                <th>二輪</th>
                <th>車体</th>
              </tr>
              <tr>
                <td class="nowrap">
                  <div class="select_ctn">
                    <select v-model="form.reassignment_date">
                      <option
                        v-for="(v,k) in reassignmentDateOptions"
                        :key="k"
                        :value="v"
                        :selected="form.reassignment_date === v"
                      >{{ concatSuffix(v) }}</option>
                    </select>
                  </div><br>
                  <p class="alert" v-if="errors.reassignment_date">{{ errors.reassignment_date }}</p>
                  <ul class="common_list_horizontal">
                    <li><label><input type="radio" name="ampm"
                      v-model="form.reassignment_date_div"
                      value="午前"
                      :disabled="constrainTimeframe('午前')"
                    ><span class="radio_text">午前</span></label></li>
                    <li><label><input type="radio" name="ampm"
                      v-model="form.reassignment_date_div"
                      value="午後"
                      :disabled="constrainTimeframe('午後')"
                    ><span class="radio_text">午後</span></label></li>
                  </ul>
                  <p class="alert" v-if="errors.reassignment_date_div">{{ errors.reassignment_date_div }}</p>
                </td>
                <td class="nowrap">
                  <ul class="common_list_vertical_narrow">
                    <li
                      v-for="(v,k) in transportVehicleOptions"
                      :key="k"
                    >
                      <label>
                        <input type="radio" name="transport_vehicle"
                          v-model="form.transport_vehicle"
                          :value="v"
                          :checked="form.transport_vehicle === v"
                        >
                        <span class="radio_text">{{ v }}</span></label>
                    </li>
                    <li>
                      <label>
                        <input type="radio" name="transport_vehicle"
                          v-model="form.transport_vehicle"
                          value="その他"
                          :checked="form.transport_vehicle === 'その他'"
                        >
                        <span class="radio_text">その他</span></label>
                      <br>
                      <!-- その他の場合必須-->
                      <input type="text" class="w8em"
                        v-model="form.transport_vehicle_other"
                        :disabled="form.transport_vehicle !== 'その他'"
                      >
                    </li>
                  </ul>
                  <p class="alert" v-if="errors.transport_vehicle">{{ errors.transport_vehicle }}</p>
                  <p class="alert" v-if="errors.transport_vehicle_other">{{ errors.transport_vehicle_other }}</p>
                </td>
                <td class="nowrap">
                  <ul class="common_list_vertical_narrow">
                    <li
                      v-for="(v,k) in methodOptions"
                      :key="k"
                    >
                      <label>
                        <input type="radio" name="method"
                          v-model="form.method"
                          :value="v"
                          :checked="form.method === v"
                        >
                        <span class="radio_text">{{ v }}</span></label>
                    </li>
                    <li>
                      <label>
                        <input type="radio" name="method"
                          v-model="form.method"
                          value="その他"
                          :checked="form.method === 'その他'"
                        >
                        <span class="radio_text">その他</span></label>
                      <br>
                      <!-- その他の場合必須-->
                      <input type="text" class="w8em"
                        v-model="form.method_other"
                        :disabled="form.method !== 'その他'"
                      >
                    </li>
                  </ul>
                  <p class="alert" v-if="errors.method">{{ errors.method }}</p>
                  <p class="alert" v-if="errors.method_other">{{ errors.method_other }}</p>
                </td>
                <td class="ta_right nowrap"><input type="number" class="w3em" v-model="form.automobile_qty">台
                  <p class="alert" v-if="errors.automobile_qty">{{ errors.automobile_qty }}</p></td>
                <td class="ta_right nowrap"><input type="number" class="w3em" v-model="form.commercial_qty">台
                  <p class="alert" v-if="errors.commercial_qty">{{ errors.commercial_qty }}</p></td>
                <td class="ta_right nowrap"><input type="number" class="w3em" v-model="form.two_wheels_qty">台
                  <p class="alert" v-if="errors.two_wheels_qty">{{ errors.two_wheels_qty }}</p></td>
                <td class="ta_right nowrap"><input type="number" class="w3em" v-model="form.body_qty">台
                  <p class="alert" v-if="errors.body_qty">{{ errors.body_qty }}</p></td>
              </tr>
              <tr>
                <th colspan="2">備考<br>
                展示車両の荷下ろし〜ブースまでの搬入手順を<br>ご記入ください。</th>
                <td colspan="5" class="nowrap">
                  <textarea name="textarea" rows="4" class="full" id="textarea" v-model="form.note"></textarea>
                  <p class="alert" v-if="errors.note">{{ errors.note }}</p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="button_ctn">
          <ul>
            <li>
              <button @click.prevent="modalClose" class="gray_button modalClose">キャンセル</button>
            </li>
            <li>
              <button @click="sendForm" class="regist_button">
                上記内容で登録
              </button>
            </li>
          </ul>
        </div>
        <!-- modal contents ここまで-->
      </div>
    </div>
    <!-- / モーダルコンテンツ -->
  </Teleport>
</template>

<script setup>
import { reactive, computed, watch, watchEffect } from 'vue'
import APIClient from '@/store/repository/APIClient'
import { forEach, cloneDeep } from 'lodash'
import { FormValidate } from '@/helper/FormValidate'
import * as yup from 'yup'
import moment from "moment"

import {
  modalOpen,
  modalClose,
} from '@/helper/modal'

import CarryInOutConst from '@/const/carry_inout'

import ApiConst from '@/const/api'

import { isShowable as isShowUI } from '@/helper/indexStatus'

const reassignmentDateOptions = CarryInOutConst.DATES

const transportVehicleOptions = CarryInOutConst.VEHICLES

const methodOptions = CarryInOutConst.METHODS

const concatSuffix = (v) => {
  const suffix = CarryInOutConst.DATES_META.find(el => el.date === v)
  return v + (suffix ? ' '+suffix.value : '')
}

const constrainTimeframe = computed(() => (v) => {
  const meta = CarryInOutConst.DATES_META.find(el => el.date === form.reassignment_date)
  if (!meta) return false
  if (meta.timeframe.includes(v)) return false
  return true
})

const api = new APIClient()

const list = reactive([])

const formInit = {
  application_id: null,
  reassignment_date: '',
  reassignment_date_div: '',
  transport_vehicle: '',
  transport_vehicle_other: '',
  method: '',
  method_other: '',
  automobile_qty: null,
  commercial_qty: null,
  two_wheels_qty: null,
  body_qty: null,
  note: "",
}
const form = reactive(cloneDeep(formInit))

watch(form, (el) => {
  if (el.transport_vehicle !== 'その他') form.transport_vehicle_other = ''
  if (el.method !== 'その他') form.method_other = ''
})
watchEffect(() => {
  const constrainMeta = CarryInOutConst.DATES_META.find(el => el.date === form.reassignment_date)
  if (constrainMeta) {
    form.reassignment_date_div = constrainMeta.timeframe[0]
  }
})

const uploadLabel = computed(() => {
  return form.application_id ? '変更' : '新規登録'
})

const fetch = async () => {
  const query = {
    form_no: 2,
    application_div: "carry_inout",
  }
  const fetchApi = await api.get(ApiConst.API_APP_LIST, query)
  const res = fetchApi.response
  const errors = res.data?.error_results
  if (errors) {
    throw new Error('Not found.')
  }
  list.splice(0)
  res.data.results.list.map((el) => {
    return {
      application_id: el.application_id,
      input_date: moment(el.updated_at).format("YY-MM-DD"),
      seq_no: el.seq_no,
      ...el.json_data
    }
  }).forEach((val, key) => {
    forEach(val, (v, k) => {
      val[k] = v ?? formInit[k]
    })
    list[key] = val
  })
}
fetch()

const newHandler = (event) => {
  forEach(formInit, (v, k) => {
    form[k] = v
  })
  modalOpen(event)
}

const editHandler = (event, key) => {
  forEach(form, (v, k) => {
    form[k] = list.find(e => e.application_id === key)[k]
  })
  modalOpen(event)
}

const deleteHandler = async (event, key) => {
  if (!window.confirm('削除して宜しいですか？')) return false

  try {
    const application_id = list.find(e => e.application_id === key).application_id
    const operation = "D"
    const query = { operation, application_id }
    const res = await api.post(ApiConst.API_APP_EDIT, query)
    if (res.response.data?.error_results) {
      throw new Error('API Validation Error')
    }
    fetch()
  } catch (e) {
    return false
  }
}

const scheme = {
  reassignment_date: yup.string().trim().required('必須の項目です。'),
  reassignment_date_div: yup.string().trim().required('必須の項目です。'),
  transport_vehicle: yup.string().trim().required('必須の項目です。'),
  transport_vehicle_other: yup.string().trim().test('transport_vehicle_other', 'その他運搬車両を記入してください。', function(v) {
    if (this.parent.transport_vehicle !== 'その他') return true
    if (v !== '') return true
    return false
  }),
  method: yup.string().trim().required('必須の項目です。'),
  method_other: yup.string().trim().test('method_other', 'その他搬入出方法を記入してください。', function(v) {
    if (this.parent.method !== 'その他') return true
    if (v !== '') return true
    return false
  }),
  automobile_qty: yup.number().typeError('数値を入力してください。').required('必須の項目です。'),
  commercial_qty: yup.number().typeError('数値を入力してください。').required('必須の項目です。'),
  two_wheels_qty: yup.number().typeError('数値を入力してください。').required('必須の項目です。'),
  body_qty: yup.number().typeError('数値を入力してください。').required('必須の項目です。'),
  note: yup.string().trim().max(1000, '1000文字以内で入力してください。'),
}
const validate = new FormValidate(scheme, form)
const errors = validate.errors
const sendForm = async () => {
  try {
    if ((Object.keys(await validate.getErrorObject()).length)) {
      throw new Error('入力項目に不備があります。')
    }
    const params = cloneDeep(form)
    const application_id = params.application_id
    delete params.application_id
    const operation = application_id ? "R" : "I"
    const query = operation === "I"
      ? { operation, application_div: "carry_inout", form_no: 2, json_data: params }
      : { operation, application_id, json_data: params }
    const res = await api.post(ApiConst.API_APP_EDIT, query)
    if (res.response.data?.error_results) {
      throw new Error('API Validation Error')
    }
    fetch()
    modalClose()
  } catch (e) {
    return false
  }
}
</script>

<style></style>
