<template>
  <header><h1>JAPAN MOBILITY SHOW 2025&emsp;出展手続きサイト・出展者専用サイト</h1></header>
  <article><h2>二次申込（出展申込書の作成）</h2>
  <div id="form_ctn" class="form_box">

    <ErrorComponent></ErrorComponent>

    <SecondSteps step="1" />
    <h3>出展分類</h3>
    <p>※JAPAN MOBILITY SHOW <a href="/docs/2025/regulation/regulation.pdf#page=10" target="_blank">規程2-6「出展物の条件」</a>を参照してください。</p>
    <h4 class="required">出展カテゴリー</h4>
    <ul id="common_list_vertical">

      <component v-model="category_div" name="category_div" :options="category_div_options" :is="activeCategoryDiv" storeKey="entrydata" />
      <p class="alert" v-if="errors.category_div">{{ errors.category_div }}</p>

    </ul>



    <div v-if="appeal_information_visible" class="vidivle_div">
      <h3 class="required">主な製品/サービス情報</h3>
      <my-textarea id="appeal_information" class="textarea_full" name="appeal_information" v-model="appeal_information">
      </my-textarea>
      <p class="alert" v-if="errors.appeal_information">{{ errors.appeal_information }}</p>
    </div>

    <div class="button_ctn">
      <button class="submit" @click="sendForm">申込小間面積・共同出展/隣接配置の入力へ</button>
    </div>


  </div>
</article>
</template>

<script>
import APIClient from '@/store/repository/APIClient'
import MyTextarea from '@/components/MyTextarea.vue';
import CategoryDiv from '@/components/CategoryDiv.vue';
import ErrorComponent from '@/components/ErrorComponent.vue';
import { useField, useForm } from 'vee-validate';
import * as yup from 'yup';
import { useRouter } from 'vue-router'
import CATEGORY_DIV from '@/const/category_div.js';
import SecondSteps from './SecondSteps.vue';


export default {
  components: {
    MyTextarea,
    CategoryDiv,
    ErrorComponent,
    SecondSteps,
  },
  setup() {
    const router = useRouter();

    const schema = yup.object({

      category_div: yup.object().test(
        "appeal_information_required",
        "出展カテゴリーを選択してください。車体の場合は屋内・屋外も選択してください",
        function (value) {
          const { category_div } = this.parent;
          if (category_div?.category_div.includes('20')) {
            if (!category_div?.category_div_20) return false
          }
          return (value)
        }
      ),

      appeal_information: yup.string().test(
        "appeal_information_required",
        "必須の項目です。",
        function (value) {
          const { category_div } = this.parent;
          if (!category_div?.category_div?.includes('10')) {
            return (value)
          }
          return true
        }
      )
    });

    const { errors, handleSubmit } = useForm({
      validationSchema: schema,
    });

    const onInvalidSubmit = () => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    }

    const sendForm = handleSubmit(() => {
      router.push({ path: '/second/form02' });
    }, onInvalidSubmit);


    const { value: category_div } = useField('category_div', null, {
      validateOnValueUpdate: false,
    });
    const { value: appeal_information } = useField('appeal_information', null, {
      validateOnValueUpdate: false,
    });
    return {
      errors,
      category_div,
      appeal_information,
      sendForm,
    };
  },
  data() {
    return {


    }
  },
  async created() {
    this.api = new APIClient()
    const res = await this.api.getConfig()
    this.$store.commit('disicion/category_div_options', res.data.results.category_div_options)
    this.activeCategoryDiv = CategoryDiv
  },
  mounted() {
    this.inputStoreData('category_div')
    this.inputStoreData('appeal_information')
  },
  methods: {
    inputStoreData(key) {
      if (!this.$store.state.entrydata[key]) return;
      this[key] = this.$store.state.entrydata[key]
    },
  },
  watch: {
    category_div: function (v) {
      this.$store.commit('entrydata/category_div', v)
    },
    appeal_information: function (v) {
      this.$store.commit('entrydata/appeal_information', v)
    },
  },
  computed: {

    membership_category() {
      return this.$store.state.entrydata.membership_category
    },
    category_div_options() {
      return this.$store.state.disicion.category_div_options.filter(el => {
        return (
          el.value !== CATEGORY_DIV.COLLABORATION_COOPERATION.value
        )
      })
    },
    appeal_information_visible() {
      if (!this.category_div?.category_div.includes('10')) return true
      return false
    },
  }
};
</script>

<style>
</style>
