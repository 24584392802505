<template>
  <div id="admin">
    <header>
      <h1>JMS 2025 出展者登録DB 管理者用ページ</h1>
    </header>
    <article>

      <h2>二次申込管理</h2>
      <div id="form_ctn" class="form_box">

        <ErrorComponent></ErrorComponent>
        <h3>申込者リスト</h3>
        <p>検索条件：{{ admin_second_list_query }}</p>
        <table id="second_search_result_table" class="common_table">
          <thead>
            <tr>
              <th class="sort_th" @click="sortKomaBango">小間番号</th>
              <th class="sort_th" @click="sortSecondOrderStatus">出展者の<br>
                ステータス</th>
              <th class="sort_th" @click="sortExhibitorName">会社名</th>
              <th class="sort_th" @click="sortMembershipCategory">会員区分</th>
              <th class="sort_th" @click="sortCategoryDiv1">出展カテゴリー</th>
              <th>申込書ダウンロード</th>

            </tr>
          </thead>
          <tbody>

            <template v-for="(list, index) in admin_second_list" :key="index">
              <tr>
                <td>{{ list.koma_bango }}</td>
                <td>{{ list.second_order_status_label }}</td>
                <td>
                  <router-link :to="{ name: 'AdminSecondDetail', params: { id: list.exhibitor_id } }">
                    {{ list.exhibitor_name }}</router-link>
                </td>
                <td>{{ list.membership_category_label }}</td>
                <td>{{ list.category_div1_label }}</td>
                <td class="ta_center">
                  <label>
                    <input type="checkbox" v-model="list.download_flag" name="download_flag"
                      @change="updateDownloadChecks"><span class="checkbox_text no_text"></span>
                  </label>
                </td>

              </tr>
            </template>
          </tbody>
          <tfoot>
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td><button class="common_button" @click="download">ダウンロード</button></td>
            </tr>
          </tfoot>
        </table>


        <div class="button_ctn">
<button class="submit" @click="downloadAll">表示されている出展者の二次申込全データをCSVでダウンロード</button>
</div>

      </div>



      <div id="form_ctn" class="form_box">
        <admin-second-search></admin-second-search>
      </div>

      <div class="button_ctn">
        <ul>
          <li><router-link :to="{ name: 'AdminIndex' }">メニュー画面に戻る</router-link>
          </li>
        </ul>
      </div>
    </article>
  </div>
</template>

<script>

import APIClient from '@/store/repository/APIClient'
import AdminSecondSearch from '@/components//AdminSecondSearch.vue';
import ErrorComponent from '@/components/ErrorComponent.vue';

import _ from 'lodash'

export default {
  components: {
    AdminSecondSearch,
    ErrorComponent,
  },
  setup() {


    return {


    };
  },

  data() {
    return {
      dlChecks: [],
    };
  },
  async created() {
    this.api = new APIClient()
    const res = await this.api.getConfig()
    this.$store.commit('disicion/membership_category_options_admin', res.data.results.membership_category_options_admin)

  },
  methods: {
    updateDownloadChecks() {
      const list = this.admin_second_list.filter(item => item.download_flag)
      this.dlChecks = list.map(item => item.exhibitor_id)
    },
    async download() {
      if (this.dlChecks.length < 1) {
        this.$store.commit('message/errors', ['ダウンロードファイルが未選択です。'])
        return
      }

      try {
        const query = {
          exhibitor_id: this.dlChecks
        }
        const res = await this.api.post('/api/admin/second_order/download', query)

        if (!res.response?.data) {
          this.$store.commit('message/errors', ['ダウンロードができません。'])
          throw new Error('API Not Found')
        }

        if (res.response.data?.error_results) {
          this.$store.commit('message/errors', res.response.data?.error_results.message)
          throw new Error('API Validation Error')
        }

        await this.api.download(res.response.data.results.url)

      } catch (e) {
        console.error(e.message)
        return false
      }
    },
    async downloadAll() {
      try {
        const query = this.$store.state.search.admin_second_list_params
        const res = await this.api.post('/api/admin/second_order/csv', query)

        if (!res.response?.data) {
          this.$store.commit('message/errors', ['ダウンロードができません。'])
          throw new Error('API Not Found')
        }

        if (res.response.data?.error_results) {
          this.$store.commit('message/errors', res.response.data?.error_results.message)
          throw new Error('API Validation Error')
        }

        await this.api.download(res.response.data.results.url)

      } catch (e) {
        console.error(e.message)
        return false
      }
    },
    sort(attribute) {
      const first = this.admin_second_list[0][attribute]
      const last = this.admin_second_list[this.admin_second_list.length - 1][attribute]
      if (first > last) {
        this.$store.commit('search/admin_second_list', _.sortBy(this.admin_second_list, attribute))
      } else {
        this.$store.commit('search/admin_second_list', _.sortBy(this.admin_second_list, attribute).reverse())
      }
    },
    sortKomaBango() {
      this.sort('koma_bango')
    },
    sortSecondOrderStatus() {
      this.sort('second_order_status')
    },
    sortExhibitorName() {
      this.sort('exhibitor_name')
    },
    sortCategoryDiv1() {
      this.sort('category_div1')
    },
    sortMembershipCategory() {
      this.sort('membership_category')
    },

  },
  watch: {

  },
  computed: {
    admin_second_list_query() {
      return this.$store.state.search.admin_second_list_query.join('　| ')
    },
    admin_second_list() {
      return this.$store.state.search.admin_second_list.map(item => {
        if (item.membership_category === null ) item.membership_category = 10000
        return item
      });
    },

  }
};
</script>

<style>

</style>
