export const nearLow = (list, value, key) => {
  return Math.max(...list.map(el => el[key]).filter(n => n < value))
}

export const nearHigh = (list, value, key) => {
  return Math.min(...list.map(el => el[key]).filter(n => n > value))
}

export const range = (start, end) => {
  const list = [];
  for (let i = start; i <= end; i++ ) {
    list.push(i);
  }
  return list;
}
