<template>
  <header><h1>JAPAN MOBILITY SHOW 2025&emsp;出展手続きサイト・出展者専用サイト</h1></header>
  <article><h2>パスワードの設定</h2>
  <div id="form_ctn" class="form_box">

    <ErrorComponent></ErrorComponent>
    
    <h3>登録メールアドレスを入力してください。</h3>
          <p>パスワードを設定するためのメールを送信します。<br>登録メールアドレス（ID）を入力して「送信」ボタンをクリックしてください。</p>

    <my-input name="reset_mail" width_class="half" type="text" v-model="reset_mail"
      placeholder="Emailアドレスを入力"></my-input>
      <p class="alert" v-if="errors.reset_mail">{{ errors.reset_mail }}</p>
    <div class="button_ctn">
      <button class="submit" @click="sendForm">送信</button>
    </div>

  </div>
</article>
</template>

<script>
import APIClient from '@/store/repository/APIClient'
import MyInput from '@/components//MyInput.vue';
import ErrorComponent from '@/components/ErrorComponent.vue';
import { useField, useForm } from 'vee-validate';
import * as yup from 'yup';
import { useRouter } from 'vue-router'

const api = new APIClient()

export default {
  components: {
    MyInput,
    ErrorComponent,
  },
  setup() {
    const router = useRouter();

    const schema = yup.object({
      reset_mail: yup.string().email('emailの形式が不正です。').required('必須の項目です。')

    });

    const { errors, handleSubmit } = useForm({
      validationSchema: schema,
    });

    const sendForm = handleSubmit(async (values) => {
      try {
        const query = {
          //左がAPI仕様書の名前 右がdataの名前
          login_id: values.reset_mail,
        }

        const res = await api.post('/api/exhibitor/passwordreset/request', query)
        if (res.response.data?.error_results) {
          throw new Error('API Validation Error')
        }
      } catch (e) {
        console.error(e.message)
        return false
      }
      router.push({ path: 'password_reset_send_complete' });
    });
    
    const { value: reset_mail } = useField('reset_mail', null, {
      validateOnValueUpdate: false,
    });
    
    return {
      errors,
      reset_mail,
      sendForm,
    };
  },
  async created() {
    this.api = new APIClient()

  },
  mounted() {

  },
  methods: {
    /*
    async sendForm() {
      try {
        const query = {
          //左がAPI仕様書の名前 右がdataの名前
          login_id: this.reset_mail,
        }

        const res = await this.api.post('/api/exhibitor/passwordreset/request', query)
        if (res.response.data?.error_results) {
          throw new Error('API Validation Error')
        }
      } catch (e) {
        console.error(e.message)
        return false
      }
      this.$router.push({ path: 'password_reset_send_complete' })
    }
    */
  },
  watch: {

  },
  computed: {

   
  }
};
</script>

<style>
</style>
