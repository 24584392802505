<template>
  <table class="common_table" v-if="list.length">
    <tbody>
      <tr>
        <th class="w3em">製造メーカー名</th>
        <th class="w15em">製品名および形式 </th>
        <th class="w4em">タイプ</th>
        <th class="w10em">周波数</th>
        <th>利用本数 </th>
        <th colspan="2">順番</th>
        <th colspan="2" v-if="isShowUI">編集</th>
      </tr>

      <tr v-for="(val, key) in list" :key="key">
        <td class="ta_right ">{{ val.maker_name }}</td>
        <td>{{ val.production_type }}</td>
        <td>{{ val.type_div === 'B' ? 'B' : 'A' }}</td>
        <td>{{ val.type_div === 'B' ? val.frequency_band + ' MHz' : val.type_div }}</td>
        <td class="ta_right">{{ val.quantity }}</td>
        <td class="edit_cell"><a @click.prevent="sortUp(val, fetch)" href="#form1" class="common_button_t2 modalOpen"
            v-if="showOrderPrev(key)">▲</a></td>
        <td class="edit_cell"><a @click.prevent="sortDown(val, fetch)" href="#form1" class="common_button_t2 modalOpen"
            v-if="showOrderNext(key, list.length - 1)">▼</a></td>
        <td class="edit_cell" v-if="isShowUI">
          <a @click.prevent="editHandler($event, val.application_id)" href="#modal1"
            class="common_button_t2 modalOpen">変更</a>
        </td>
        <td class="edit_cell" v-if="isShowUI">
          <a @click.prevent="deleteHandler($event, val.application_id)"
            class="common_button_t2 gray_button delete_button">削除</a>
        </td>
      </tr>
    </tbody>
  </table>
  <div class="no_entry" v-else>登録がありません。「新規追加」ボタンより登録をお願いします。</div>
  <template v-if="isShowUI">
  <div>
    <a @click.prevent="newHandler" href="#modal1" class="common_add_button modalOpen">新規追加</a>
  </div>
  </template>
  <Teleport to="body">

    <!-- モーダルコンテンツ / -->
    <!-- 製作注意問い合わせ先は何種類かあります。読み込み形式で簡単に編集できるようにしたほうが良いかと思います。-->
    <div class="overlay" id="modal1">
      <div id="modal_content" class="modal_contents w80">
        <!-- modal contents ここから-->
        <div class="modalClose_ctn">
          <a @click.prevent="modalClose" class="modalClose"></a>
        </div>
        <div class="entry_title">
          <h4>ワイヤレスマイク使用届</h4>
          <h5
            :set="counter = list.findIndex(el => el.application_id === form.application_id)"
          >No.{{ counter >= 0
            ? counter + 1
            : list.length + 1 }}&nbsp;&nbsp;{{ uploadLabel }}</h5>
        </div>
        <div class="entry_modal_ctn">
          <table class="common_table">
            <tbody>
              <tr>
                <th>製造メーカー名</th>
                <td><input type="text" class="full" v-model="form.maker_name">
                  <p class="alert" v-if="errors.maker_name">{{ errors.maker_name }}</p>
                </td>
              </tr>
              <tr>
                <th>製品名および形式</th>
                <td><input type="text" class="full" v-model="form.production_type">
                  <p class="alert" v-if="errors.production_type">{{ errors.production_type }}</p>
                </td>
              </tr>
              <tr>
                <th>運用会社名（免許保有会社）</th>
                <td><input type="text" class="full" v-model="form.operation_company">
                  <p class="alert" v-if="errors.operation_company">{{ errors.operation_company }}</p>
                </td>
              </tr>
              <tr>
                <th>周波数帯</th>
                <td>
                  <ul class="common_list_vertical_narrow">
                    <li><label>
                        <input type="radio" name="type_div" v-model="form.type_div" value="WS帯＆専用帯"
                          :checked="form.type_div === 'WS帯＆専用帯（470～714MHz）'">
                        <span class="radio_text">WS帯＆専用帯（470～714MHz）</span></label></li>
                    <li><label>
                        <input type="radio" name="type_div" v-model="form.type_div" value="1.2GHz帯"
                          :checked="form.type_div === '1.2GHz帯（1240～1260MHz）'">
                        <span class="radio_text">1.2GHz帯（1240～1260MHz）</span></label></li>
                    <li><label><input type="radio" name="type_div" v-model="form.type_div" value="B" :checked="form.type_div === 'B'"><span class="radio_text">B：</span></label><input type="text" class="w10em" v-model="form.frequency_band" :disabled="form.type_div !== 'B'"> MHz（例：806.125MHz）</li>
                  </ul>
                  <p class="alert" v-if="errors.type_div">{{ errors.type_div }}</p>
                  <p class="alert" v-if="errors.frequency_band">{{ errors.frequency_band }}</p>
                  <p class="ta_right"><a href="" target="_blank" class="common_add_button" @click.prevent="toggleTable">周波数登録状況</a></p>
                  <table class="common_table" v-if="isShowTable">
                    <tbody>
                      <tr>
                        <th class="w10em">周波数帯</th>
                        <th class="w10em">小間番号</th>
                        <th>出展者名</th>
                      </tr>
                      <tr v-for="(val, key) in frequencies" :key="key">
                        <td class="ta_center">{{ val.frequency_band }}MHz</td>
                        <td class="ta_center">{{ val.koma_bango }}</td>
                        <td>{{ val.exhibitor_name }}</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <th>本数</th>
                <td><input type="number" class="w5em" v-model="form.quantity">
                  <p class="alert" v-if="errors.quantity">{{ errors.quantity }}</p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="button_ctn">
          <ul>
            <li>
              <button @click.prevent="modalClose" class="gray_button modalClose">キャンセル</button>
            </li>
            <li>
              <button @click="sendHandler" class="regist_button">
                上記内容で登録
              </button>
            </li>
          </ul>
        </div>
        <!-- modal contents ここまで-->
      </div>
    </div>
    <!-- / モーダルコンテンツ -->
  </Teleport>
</template>

<script setup>
import { reactive, ref, computed, watch } from 'vue'
import APIClient from '@/store/repository/APIClient'
import { forEach, cloneDeep, sortBy } from 'lodash'
import { FormValidate } from '@/helper/FormValidate'
import * as yup from 'yup'
import moment from "moment"

import {
  modalOpen,
  modalClose,
} from '@/helper/modal'

import {
  sortUp,
  sortDown,
  showOrderPrev,
  showOrderNext,
} from '@/helper/order'

import ApiConst from '@/const/api'

import { isShowable as isShowUI } from '@/helper/indexStatus'

const api = new APIClient()

const isShowTable = ref(false)

const toggleTable = () => {
  isShowTable.value = !isShowTable.value
}

const list = reactive([])
const frequencies = reactive([])

const formInit = {
  application_id: null,
  maker_name: '',
  production_type: '',
  operation_company: '',
  type_div: '',
  frequency_band: '',
  quantity: null,
}
const form = reactive(cloneDeep(formInit))

watch(form, (el) => {
  if (el.type_div !== 'B') form.frequency_band = ''
})

const uploadLabel = computed(() => {
  return form.application_id ? '変更' : '新規登録'
})

const fetchFreq = async () => {
  const fetchApi = await api.get(ApiConst.API_WIRELESS_MIC_BLIST)
  const res = fetchApi.response
  const errors = res.data?.error_results
  if (errors) {
    throw new Error('Not found.')
  }
  frequencies.splice(0)
  sortBy(res.data.results.list, 'koma_bango').forEach((val, key) => {
    frequencies[key] = val
  })

}
fetchFreq()

const fetch = async () => {
  const query = {
    form_no: 1,
    application_div: "wireless_mic",
  }
  const fetchApi = await api.get(ApiConst.API_APP_LIST, query)
  const res = fetchApi.response
  const errors = res.data?.error_results
  if (errors) {
    throw new Error('Not found.')
  }
  list.splice(0)
  res.data.results.list.map((el) => {
    return {
      application_id: el.application_id,
      input_date: moment(el.updated_at).format("YY-MM-DD"),
      seq_no: el.seq_no,
      ...el.json_data
    }
  }).forEach((val, key) => {
    forEach(val, (v, k) => {
      val[k] = v ?? formInit[k]
    })
    list[key] = val
  })

}
fetch()

const newHandler = (event) => {
  forEach(formInit, (v, k) => {
    form[k] = v
  })
  modalOpen(event)
}

const editHandler = (event, key) => {
  forEach(form, (v, k) => {
    form[k] = list.find(e => e.application_id === key)[k]
  })
  modalOpen(event)
}

const deleteHandler = async (event, key) => {
  if (!window.confirm('削除して宜しいですか？')) return false

  try {
    const application_id = list.find(e => e.application_id === key).application_id
    const operation = "D"
    const query = { operation, application_id }
    const res = await api.post(ApiConst.API_APP_EDIT, query)
    if (res.response.data?.error_results) {
      throw new Error('API Validation Error')
    }
    fetchFreq()
    fetch()
  } catch (e) {
    return false
  }
}

const scheme = {
  maker_name: yup.string().trim().required('必須の項目です。'),
  production_type: yup.string().trim().required('必須の項目です。'),
  operation_company: yup.string().trim().required('必須の項目です。'),
  type_div: yup.string().trim().required('必須の項目です。'),
  frequency_band: yup.string().trim().test('frequency_band', '周波数帯を記入してください。', function(v) {
    if (this.parent.type_div !== 'B') return true
    if (v !== '') return true
    return false
  }),
  quantity: yup.number().typeError('数値を入力してください。').required('必須の項目です。'),
}
const validate = new FormValidate(scheme, form)
const errors = validate.errors
const sendHandler = async () => {
  try {
    if ((Object.keys(await validate.getErrorObject()).length)) {
      throw new Error('入力項目に不備があります。')
    }
    await fetchFreq()
    if (frequencies.find(el => (
      el.application_id !== form.application_id
      &&
      String(el.frequency_band) === String(form.frequency_band)
    ))) {
      alert('すでに登録されている周波数です。')
    }
    await sendForm()
    alert('登録が完了しました。')
    fetchFreq()
    fetch()
    modalClose()
  } catch (e) {
    return false
  }
}
const sendForm = async () => {
  try {
    const params = cloneDeep(form)
    const application_id = params.application_id
    delete params.application_id
    const operation = application_id ? "R" : "I"
    const query = operation === "I"
      ? { operation, application_div: "wireless_mic", form_no: 1, json_data: params }
      : { operation, application_id, json_data: params }
    const res = await api.post(ApiConst.API_APP_EDIT, query)
    if (res.response.data?.error_results) {
      throw new Error('API Validation Error')
    }
  } catch (e) {
    return false
  }
}
</script>

<style></style>
