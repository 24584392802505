<template>
  <header>
    <h1>JAPAN MOBILITY SHOW 2025 <br class="pc_none">出展手続きサイト・出展者専用サイト</h1>
  </header>
  <article id="p2">
    <PhaseHeader />
    <div id="form_ctn" class="form_box">
          <PhaseNav
            :current="3"
          />

          <ErrorComponent></ErrorComponent>

          <div id="form_ctn" class="form_box">
            <h3>出展者会社名</h3>

<h4>出展者名</h4>
<p>{{ exhibitor_name }}</p>

<h4>フリガナ</h4>
<p>{{ exhibitor_name_kana }}</p>

<h4>英文名</h4>
<p>{{ exhibitor_name_en }}</p>

<h3>出展者本社所在地</h3>
<h4>郵便番号</h4>
<p>{{ postCode }}</p>

<h4>所在地</h4>
<p>{{ address_pref_label }}{{ address_jp }}</p>

<h4>英文所在地</h4>
<p>{{ address_en }}</p>

<h4>国・地域</h4>
<p>{{ country_main_label }} {{ country_other_label }} </p>


<p class="alert" v-if="errors.country_main">{{ errors.country_main }}</p>
<p class="alert" v-if="errors.country_other">{{ errors.country_other }}</p>

<h3>法人代表者</h3>
<h4 class="required">役職</h4>
<my-input name="representative_position" width_class="half" type="text" v-model="representative_position"
  placeholder="例：代表取締役社長"></my-input>
<p class="alert" v-if="errors.representative_position">{{ errors.representative_position }}</p>
<h4 class="required">氏名</h4>
<ul class="common_list_horizontal">
  <li class="quater mr10">
    <my-input name="representative_last_name" width_class="full" type="text" v-model="representative_last_name"
      placeholder="例：山田"></my-input>
  </li>
  <li class="quater">
    <my-input name="representative_first_name" width_class="full" type="text" v-model="representative_first_name"
      placeholder="例：太郎"></my-input>
  </li>
</ul>
<p class="alert" v-if="errors.representative_last_name">{{ errors.representative_last_name }}</p>
<p class="alert" v-if="errors.representative_first_name">{{ errors.representative_first_name }}</p>


<div v-if="category_div?.category_div?.includes('10')">
<h3>出展する取り扱いブランド名</h3>

<div class="one_brand" v-if="brand1_name_jp !== '' || brand5_name_en !== ''">
  <h4>和文名</h4>
  <p>{{ brand1_name_jp }}</p>
  <h4>英文名</h4>
  <p>{{ brand1_name_en }}</p>
  <h4>国・地域</h4>
  <p>{{ brand1_country_main_label }} {{ brand1_country_other_label }}</p>
</div>

<div class="one_brand" v-if="brand2_name_jp !== '' || brand2_name_en !== ''">
  <h4>和文名</h4>
  <p>{{ brand2_name_jp }}</p>
  <h4>英文名</h4>
  <p>{{ brand2_name_en }}</p>
  <h4>国・地域</h4>
  <p>{{ brand2_country_main_label }} {{ brand2_country_other_label }}</p>
</div>

<div class="one_brand" v-if="brand3_name_jp !== '' || brand3_name_en !== ''">
  <h4>和文名</h4>
  <p>{{ brand3_name_jp }}</p>
  <h4>英文名</h4>
  <p>{{ brand3_name_en }}</p>
  <h4>国・地域</h4>
  <p>{{ brand3_country_main_label }} {{ brand3_country_other_label }}</p>
</div>

<div class="one_brand" v-if="brand4_name_jp !== '' || brand4_name_en !== ''">
  <h4>和文名</h4>
  <p>{{ brand4_name_jp }}</p>
  <h4>英文名</h4>
  <p>{{ brand4_name_en }}</p>
  <h4>国・地域</h4>
  <p>{{ brand4_country_main_label }} {{ brand4_country_other_label }}</p>
</div>

<div class="one_brand" v-if="brand5_name_jp !== '' || brand5_name_en !== ''">
  <h4>和文名</h4>
  <p>{{ brand5_name_jp }}</p>
  <h4>英文名</h4>
  <p>{{ brand5_name_en }}</p>
  <h4>国・地域</h4>
  <p>{{ brand5_country_main_label }} {{ brand5_country_other_label }}</p>
</div>


</div>
<div class="button_ctn">
  <button class="submit" @click="sendForm">登録内容の確認</button>
</div>


</div>
          
            </div>


  </article>
</template>

<script>
import PhaseHeader from '@/components/PhaseHeader'
import PhaseNav from '@/components/PhaseNav'
import APIClient from '@/store/repository/APIClient'
import MyInput from '@/components//MyInput.vue';
import ErrorComponent from '@/components/ErrorComponent.vue';
import { useField, useForm } from 'vee-validate';
import * as yup from 'yup';
import { useRouter } from 'vue-router'
import KenAll from 'ken-all';
import ADDRESS from '../../const/address_elements.js'
import {
  isValue
} from '@/helper/index';
export default {
  components: {
    MyInput,
    ErrorComponent,
    PhaseHeader,
    PhaseNav,
},
  setup() {
    const router = useRouter();

    const schema = yup.object({

      representative_position: yup.string().required('必須の項目です。'),
      representative_last_name: yup.string().required('"姓”が入力されていません。必須の項目です。'),
      representative_first_name: yup.string().required('"名”が入力されていません。必須の項目です。'),

    });

    const { errors, handleSubmit } = useForm({
      validationSchema: schema,
    });

    const onInvalidSubmit = () => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    }

    const sendForm = handleSubmit(() => {
      router.push({ path: '/p2/form01_confirm' });
    }, onInvalidSubmit);


    const { value: representative_position } = useField('representative_position', null, {
      validateOnValueUpdate: false,
    });
    const { value: representative_last_name } = useField('representative_last_name', null, {
      validateOnValueUpdate: false,
    });
    const { value: representative_first_name } = useField('representative_first_name', null, {
      validateOnValueUpdate: false,
    });

    return {
      errors,

      representative_position,
      representative_last_name,
      representative_first_name,

      sendForm,
    };
  },
  data() {
    return {
      postCode: '',

      address_pref_options: ADDRESS.address_pref_options,
      brand1_name_jp: '',
      brand1_name_en: '',
      brand1_country_main: '',
      brand1_country_other: '',
      brand2_name_jp:'',
      brand2_name_en:'',
      brand2_country_main:'',
      brand2_country_other:'',
      brand3_name_jp:'',
      brand3_name_en:'',
      brand3_country_main:'',
      brand3_country_other:'',
      brand4_name_jp:'',
      brand4_name_en:'',
      brand4_country_main:'',
      brand4_country_other:'',
      brand5_name_jp:'',
      brand5_name_en:'',
      brand5_country_main:'',
      brand5_country_other:'',


    }
  },
  async created() {
    this.api = new APIClient()
    const res = await this.api.getConfig()
    this.$store.commit('disicion/country_main_options', res.data.results.country_main_options)
    this.$store.commit('disicion/country_other_options', res.data.results.country_other_options)

  },
  mounted() {
    this.inputStoreData('koma_bango')
    this.inputStoreData('category_div')
    this.inputStoreData('exhibitor_name')
    this.inputStoreData('exhibitor_name_kana')
    this.inputStoreData('exhibitor_name_en')
    this.inputStoreData('postCode')
    this.inputStoreData('address_pref')
    this.inputStoreData('address_jp')
    this.inputStoreData('address_en')
    this.inputStoreData('country_main')
    this.inputStoreData('country_other')
    this.inputStoreData('representative_position')
    this.inputStoreData('representative_last_name')
    this.inputStoreData('representative_first_name')
    this.inputStoreData('brand1_name_jp')
    this.inputStoreData('brand1_name_en')
    this.inputStoreData('brand1_country_main')
    this.inputStoreData('brand1_country_other')
    this.inputStoreData('brand2_name_jp')
    this.inputStoreData('brand2_name_en')
    this.inputStoreData('brand2_country_main')
    this.inputStoreData('brand2_country_other')
    this.inputStoreData('brand3_name_jp')
    this.inputStoreData('brand3_name_en')
    this.inputStoreData('brand3_country_main')
    this.inputStoreData('brand3_country_other')
    this.inputStoreData('brand4_name_jp')
    this.inputStoreData('brand4_name_en')
    this.inputStoreData('brand4_country_main')
    this.inputStoreData('brand4_country_other')
    this.inputStoreData('brand5_name_jp')
    this.inputStoreData('brand5_name_en')
    this.inputStoreData('brand5_country_main')
    this.inputStoreData('brand5_country_other')
    this.inputStoreData('staff_email')
  },
  methods: {
    inputStoreData(key) {
      if (!this.$store.state.entrydata[key]) return;
      this[key] = this.$store.state.entrydata[key]
    },
    
    onChange() {
      if (this.postCode.length === 7) {
        KenAll(this.postCode).then(res => {
          
          const prefecture = res[0].shift()

          if (res.length === 0) {
            this.address_pref = '該当する住所はありません';
            this.address_jp = '該当する住所はありません';
          } else {
            const pref_match = this.address_pref_options.find(item => item.label === prefecture)
            this.address_pref = pref_match.value;
            this.address_jp = res[0].join('');
          }
        });
      }
    },
  },
  watch: {
    
    representative_position: function (v) {
      this.$store.commit('entrydata/representative_position', v)
    },
    representative_last_name: function (v) {
      this.$store.commit('entrydata/representative_last_name', v)
    },
    representative_first_name: function (v) {
      this.$store.commit('entrydata/representative_first_name', v)
    },
    


  },
  computed: {
    country_main_label() {
      const array = this.$store.state.disicion.country_main_options
      if (!array) return
      if (this.country_main == 99999999) return
      return array.find(item => item.value === this.country_main)?.label
    },
    country_main_options() {
      return this.$store.state.disicion.country_main_options
    },
    country_other_options() {
      return this.$store.state.disicion.country_other_options
    },
    brand1_country_main_label() {
      const array = this.$store.state.disicion.country_main_options
      if (!array) return
      if (this.brand1_country_main == 99999999) return
      return array.find(item => item.value === this.brand1_country_main)?.label
    },
    brand1_country_other_label() {
      const array = this.$store.state.disicion.country_other_options
      if (!array) return
      if (this.brand1_country_other == "") return
      return array.find(item => item.value === this.brand1_country_other)?.label
    },
    brand2_country_main_label() {
      const array = this.$store.state.disicion.country_main_options
      if (!array) return
      if (this.brand2_country_main == 99999999) return
      return array.find(item => item.value === this.brand2_country_main)?.label
    },
    brand2_country_other_label() {
      const array = this.$store.state.disicion.country_other_options
      if (!array) return
      if (!isValue(this.brand2_country_other)) return ''
      return array.find(item => item.value === this.brand2_country_other)?.label || ''
    },
    brand3_country_main_label() {
      const array = this.$store.state.disicion.country_main_options
      if (!array) return
      if (this.brand3_country_main == 99999999) return
      return array.find(item => item.value === this.brand3_country_main)?.label
    },
    brand3_country_other_label() {
      const array = this.$store.state.disicion.country_other_options
      if (!array) return
      if (this.brand3_country_other == "") return
      return array.find(item => item.value === this.brand3_country_other)?.label
    },
    brand4_country_main_label() {
      const array = this.$store.state.disicion.country_main_options
      if (!array) return
      if (this.brand4_country_main == 99999999) return
      return array.find(item => item.value === this.brand4_country_main)?.label
    },
    brand4_country_other_label() {
      const array = this.$store.state.disicion.country_other_options
      if (!array) return
      if (!isValue(this.brand4_country_other)) return ''
      return array.find(item => item.value === this.brand4_country_other)?.label
    },
    brand5_country_main_label() {
      const array = this.$store.state.disicion.country_main_options
      if (!array) return
      if (this.brand5_country_main == 99999999) return
      return array.find(item => item.value === this.brand5_country_main)?.label
    },
    brand5_country_other_label() {
      const array = this.$store.state.disicion.country_other_options
      if (!array) return
      if (!isValue(this.brand5_country_other)) return ''
      return array.find(item => item.value === this.brand5_country_other)?.label
    },
    address_pref_label() {
      const array = this.address_pref_options
      return array.find(item => item.value === this.address_pref)?.label
    },
    manager_address_pref_label() {
      const array = this.address_pref_options
      return array.find(item => item.value === this.manager_address_pref)?.label
    },
  }
};
</script>

<style>
</style>
