<template>
  <header><h1>JAPAN MOBILITY SHOW 2025&emsp;出展手続きサイト・出展者専用サイト</h1></header>
  <article><h2>二次申込（出展申込書の作成）</h2>
  <div id="form_ctn" class="form_box">

    <ErrorComponent></ErrorComponent>

    <SecondSteps step="1" />
    <h3>出展者会社名</h3>

    <h4 class="required">和文名称</h4>
    <my-input name="exhibitor_name" width_class="full" type="text" v-model="exhibitor_name">
    </my-input>
    <p>法人表記は「株式会社」「○○法人」とし、「(株)」などの略称は使用しないで下さい。</p>
    <p class="alert" v-if="errors.exhibitor_name">{{ errors.exhibitor_name }}</p>

    <h4 class="required">フリガナ</h4>
    <my-input name="exhibitor_name_kana" width_class="full" type="text" v-model="exhibitor_name_kana"></my-input>
    <p class="alert" v-if="errors.exhibitor_name_kana">{{ errors.exhibitor_name_kana }}</p>

    <h4 class="required">英文名称</h4>
    <my-input name="exhibitor_name_en" width_class="full" type="text" v-model="exhibitor_name_en"></my-input>
    <p class="alert" v-if="errors.exhibitor_name_en">{{ errors.exhibitor_name_en }}</p>

    <h3>出展者本社所在地</h3>
    <template v-if="!isForeign">
    <h4>郵便番号</h4>
    <input type="text" id="postCode" class="input" name="postCode" v-model="postCode" maxlength="8" @input="onZipInput($event, 'postCode')" @keyup="onChange"
      placeholder="例：123-4567">
    <p class="alert" v-if="errors.postCode">{{ errors.postCode }}</p>
    </template>
    <h4 class="required">和文所在地</h4>
    <ul class="common_list_horizontal">
      <li class="quarter ">

        <div class="select_ctn">
          <select name="address_pref" v-model="address_pref">
            <option value="">都道府県</option>
            <option v-for="(v,k) in address_pref_options" :key="k" :value="v.value">{{ v.label }}</option>
          </select>

        </div>
      </li>
      <template v-if="!isForeign">
      <li class="three_quarters pl10">
        <my-input name="address_jp" width_class="full" type="text" v-model="address_jp"
          placeholder="例：港区芝大門1-1-30&emsp;日本自動車会館">
        </my-input>

      </li>
      </template>
    </ul>
    <p class="alert" v-if="errors.address_pref">{{ errors.address_pref }}</p>
    <p class="alert" v-if="errors.address_jp">{{ errors.address_jp }}</p>
    <h4 class="required">英文所在地</h4>
    <my-input name="address_en" width_class="full" type="text" v-model="address_en"
      placeholder="例：Jidosha Kaikan, 1-30, Shiba Daimon 1-chome, Minato-ku, Tokyo 105-0012">
    </my-input>
    <p class="alert" v-if="errors.address_en">{{ errors.address_en }}</p>

    

    <h4 class="required">国・地域</h4>
    <div v-if="category_div">
    <country-select
      :initial_main="country_main"
      main_name="country_main"
      :main_options="country_main_options"
      v-model:main="country_main"
      :initial_other="country_other"
      other_name="country_other"
      :other_options="country_other_options"
      v-model:other="country_other"
      disabled_number="99999999"
    ></country-select>
  </div>
 

    <p class="alert" v-if="errors.country_main">{{ errors.country_main }}</p>
    <p class="alert" v-if="errors.country_other">{{ errors.country_other }}</p>

    <h3>法人代表者</h3>
    <h4 class="required">役職</h4>
    <my-input name="representative_position" width_class="half" type="text" v-model="representative_position"
      placeholder="例：代表取締役社長"></my-input>
    <p class="alert" v-if="errors.representative_position">{{ errors.representative_position }}</p>
    <h4 class="required">氏名</h4>
    <ul class="common_list_horizontal">
      <li class="quater mr10">
        <my-input name="representative_last_name" width_class="full" type="text" v-model="representative_last_name"
          placeholder="例：山田"></my-input>
      </li>
      <li class="quater">
        <my-input name="representative_first_name" width_class="full" type="text" v-model="representative_first_name"
          placeholder="例：太郎"></my-input>
      </li>
    </ul>
    <p class="alert" v-if="errors.representative_last_name">{{ errors.representative_last_name }}</p>
    <p class="alert" v-if="errors.representative_first_name">{{ errors.representative_first_name }}</p>


    <div v-if="category_div?.category_div?.includes('10')">
    <h3>出展する取り扱いブランド名</h3>
    <p>※乗用車、商用車、二輪車部門のみ<br>
      ※出展する取扱いブランドが複数ある場合のみ</p>

    <div class="one_brand">
      <h4>和文名</h4>
      <my-input name="brand1_name_jp" width_class="full" type="text" v-model="brand1_name_jp"></my-input>
      <h4>英文名</h4>
      <my-input name="brand1_name_en" width_class="full" type="text" v-model="brand1_name_en"></my-input>
      <h4>国・地域</h4>
          <country-select
            :initial_main="brand1_country_main"
            main_name="brand1_country_main"
            :main_options="country_main_options"
            v-model:main="brand1_country_main"
            :initial_other="brand1_country_other"
            other_name="brand1_country_other"
            :other_options="country_other_options"
            v-model:other="brand1_country_other"
            disabled_number="99999999"
          ></country-select>
    </div>

    <div class="one_brand" v-if="BrandNum >= 2">
      <h4>和文名</h4>
      <my-input name="brand2_name_jp" width_class="full" type="text" v-model="brand2_name_jp"></my-input>
      <h4>英文名</h4>
      <my-input name="brand2_name_en" width_class="full" type="text" v-model="brand2_name_en"></my-input>
      <h4>国・地域</h4>
          <country-select
            :initial_main="brand2_country_main"
            main_name="brand2_country_main"
            :main_options="country_main_options"
            v-model:main="brand2_country_main"
            :initial_other="brand2_country_other"
            other_name="brand2_country_other"
            :other_options="country_other_options"
            v-model:other="brand2_country_other"
            disabled_number="99999999"
          ></country-select>
    </div>

    <div class="one_brand" v-if="BrandNum >= 3">
      <h4>和文名</h4>
      <my-input name="brand3_name_jp" width_class="full" type="text" v-model="brand3_name_jp"></my-input>
      <h4>英文名</h4>
      <my-input name="brand3_name_en" width_class="full" type="text" v-model="brand3_name_en"></my-input>
      <h4>国・地域</h4>
          <country-select
            :initial_main="brand3_country_main"
            main_name="brand3_country_main"
            :main_options="country_main_options"
            v-model:main="brand3_country_main"
            :initial_other="brand3_country_other"
            other_name="brand3_country_other"
            :other_options="country_other_options"
            v-model:other="brand3_country_other"
            disabled_number="99999999"
          ></country-select>
    </div>

    <div class="one_brand" v-if="BrandNum >= 4">
      <h4>和文名</h4>
      <my-input name="brand4_name_jp" width_class="full" type="text" v-model="brand4_name_jp"></my-input>
      <h4>英文名</h4>
      <my-input name="brand4_name_en" width_class="full" type="text" v-model="brand4_name_en"></my-input>
      <h4>国・地域</h4>
          <country-select
            :initial_main="brand4_country_main"
            main_name="brand4_country_main"
            :main_options="country_main_options"
            v-model:main="brand4_country_main"
            :initial_other="brand4_country_other"
            other_name="brand4_country_other"
            :other_options="country_other_options"
            v-model:other="brand4_country_other"
            disabled_number="99999999"
          ></country-select>
    </div>

    <div class="one_brand" v-if="BrandNum >= 5">
      <h4>和文名</h4>
      <my-input name="brand5_name_jp" width_class="full" type="text" v-model="brand5_name_jp"></my-input>
      <h4>英文名</h4>
      <my-input name="brand5_name_en" width_class="full" type="text" v-model="brand5_name_en"></my-input>
      <h4>国・地域</h4>
          <country-select
            :initial_main="brand5_country_main"
            main_name="brand5_country_main"
            :main_options="country_main_options"
            v-model:main="brand5_country_main"
            :initial_other="brand5_country_other"
            other_name="brand5_country_other"
            :other_options="country_other_options"
            v-model:other="brand5_country_other"
            disabled_number="99999999"
          ></country-select>
    </div>
    <div class="common_add_button" @click="addbrand" v-if="BrandNum < 5">取り扱いブランドを追加</div>
    </div>
    <div class="button_ctn">
      <button class="submit" @click="sendForm">出展代理者・連絡先の入力へ</button>
    </div>

    

  </div>
</article>
</template>

<script>
import { computed } from 'vue'
import APIClient from '@/store/repository/APIClient'
import MyInput from '@/components//MyInput.vue';
import CountrySelect from '@/components//CountrySelect.vue';
import ErrorComponent from '@/components/ErrorComponent.vue';

import { useField, useForm } from 'vee-validate';
import * as yup from 'yup';
import { useRouter } from 'vue-router'
import KenAll from 'ken-all';
import ADDRESS from '../const/address_elements.js'
import SecondSteps from './SecondSteps.vue';
import YupConst from '@/const/yup';
import {
  zipNumOnlyTest,
  makeZip
} from '@/helper/index';

const zipRegExp = new RegExp(YupConst.ZIP_NULLABLE_PATTERN)
// 全角スペース \x20\u3000
const katakanaRegExp = new RegExp(YupConst.KATAKANA_PATTERN)

const foreignID =  ADDRESS.address_pref_options.find(el => {
  return el.label === ADDRESS.address_pref_foreign
})?.value

export default {
  components: {
    MyInput,
    CountrySelect,
    ErrorComponent,
    SecondSteps,
},
  setup() {
    const router = useRouter();

    const isForeign = computed(() => {
      return address_pref.value === foreignID
    })

    const schema = yup.object({
      exhibitor_name: yup.string().required('必須の項目です。'),
      exhibitor_name_kana: yup.string().required('必須の項目です。').matches(katakanaRegExp, YupConst.KATAKANA_PATTERN_MSG),
      exhibitor_name_en: yup.string().required('必須の項目です。'),
      postCode: yup.string().trim()
        .test(
          'postCode_pref',
          YupConst.ZIP_PATTERN_MSG,
          function(v) {
            if (isForeign.value) return true
            if (v === undefined) return false
            return v.match(zipRegExp)
          }
        ),
      address_pref: yup.string().required('必須の項目です。'),
      address_jp: yup.string()
        .test(
          'address_jp_required',
          "必須です。",
          function(v) {
            if (isForeign.value) return true
            if (v === undefined) return false
            if (v.length) return true
            return false
          }
        ),
      address_en: yup.string().required('必須の項目です。'),
      country_main: yup.string().required('必須の項目です。'),
      country_other: yup.string().test(
        "country_other_validation",
        "その他の国・地域を選択してください。",
        function (value) {
          const var_country_main = this.parent.country_main;
          if (var_country_main !== '99999999') return true
          return (value)
        }
      ),
      representative_position: yup.string().required('必須の項目です。'),
      representative_last_name: yup.string().required('"姓”が入力されていません。必須の項目です。'),
      representative_first_name: yup.string().required('"名”が入力されていません。必須の項目です。'),

    });

    const { errors, handleSubmit } = useForm({
      validationSchema: schema,
    });

    const onInvalidSubmit = () => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    }

    const sendForm = handleSubmit(() => {
      router.push({ path: '/second/form04' });
    }, onInvalidSubmit);

    const { value: exhibitor_name } = useField('exhibitor_name', null, {
      validateOnValueUpdate: false,
    });
    const { value: exhibitor_name_kana } = useField('exhibitor_name_kana', null, {
      validateOnValueUpdate: false,
    });
    const { value: exhibitor_name_en } = useField('exhibitor_name_en', null, {
      validateOnValueUpdate: false,
    });
    const { value: postCode } = useField('postCode', null, {
      validateOnValueUpdate: false,
    });
    const { value: address_pref } = useField('address_pref', null, {
      validateOnValueUpdate: false,
    });
    const { value: address_jp } = useField('address_jp', null, {
      validateOnValueUpdate: false,
    });
    const { value: address_en } = useField('address_en', null, {
      validateOnValueUpdate: false,
    });

    const { value: country_main } = useField('country_main', null, {
      validateOnValueUpdate: false,
    });
    const { value: country_other } = useField('country_other', null, {
      validateOnValueUpdate: false,
      
    });
    const { value: representative_position } = useField('representative_position', null, {
      validateOnValueUpdate: false,
    });
    const { value: representative_last_name } = useField('representative_last_name', null, {
      validateOnValueUpdate: false,
    });
    const { value: representative_first_name } = useField('representative_first_name', null, {
      validateOnValueUpdate: false,
    });

    return {
      errors,
      exhibitor_name,
      exhibitor_name_kana,
      exhibitor_name_en,
      postCode,
      address_pref,
      address_jp,
      address_en,
      country_main,
      country_other,
      representative_position,
      representative_last_name,
      representative_first_name,

      sendForm,
      isForeign,
    };
  },
  data() {
    return {
      BrandNum: this.$store.state.disicion.BrandNum ?? 1,
      address_pref_options: ADDRESS.address_pref_options,
      brand1_name_jp: '',
      brand1_name_en: '',
      brand1_country_main: '',
      brand1_country_other: '',
      brand2_name_jp:'',
      brand2_name_en:'',
      brand2_country_main:'',
      brand2_country_other:'',
      brand3_name_jp:'',
      brand3_name_en:'',
      brand3_country_main:'',
      brand3_country_other:'',
      brand4_name_jp:'',
      brand4_name_en:'',
      brand4_country_main:'',
      brand4_country_other:'',
      brand5_name_jp:'',
      brand5_name_en:'',
      brand5_country_main:'',
      brand5_country_other:'',


    }
  },
  async created() {
    this.api = new APIClient()
    const res = await this.api.getConfig()
    this.$store.commit('disicion/country_main_options', res.data.results.country_main_options)
    this.$store.commit('disicion/country_other_options', res.data.results.country_other_options)

  },
  mounted() {
    this.inputStoreData('category_div')
    this.inputStoreData('exhibitor_name')
    this.inputStoreData('exhibitor_name_kana')
    this.inputStoreData('exhibitor_name_en')
    this.inputStoreData('postCode')
    this.inputStoreData('address_pref')
    this.inputStoreData('address_jp')
    this.inputStoreData('address_en')
    this.inputStoreData('country_main')
    this.inputStoreData('country_other')
    this.inputStoreData('representative_position')
    this.inputStoreData('representative_last_name')
    this.inputStoreData('representative_first_name')
    this.inputStoreData('brand1_name_jp')
    this.inputStoreData('brand1_name_en')
    this.inputStoreData('brand1_country_main')
    this.inputStoreData('brand1_country_other')
    this.inputStoreData('brand2_name_jp')
    this.inputStoreData('brand2_name_en')
    this.inputStoreData('brand2_country_main')
    this.inputStoreData('brand2_country_other')
    this.inputStoreData('brand3_name_jp')
    this.inputStoreData('brand3_name_en')
    this.inputStoreData('brand3_country_main')
    this.inputStoreData('brand3_country_other')
    this.inputStoreData('brand4_name_jp')
    this.inputStoreData('brand4_name_en')
    this.inputStoreData('brand4_country_main')
    this.inputStoreData('brand4_country_other')
    this.inputStoreData('brand5_name_jp')
    this.inputStoreData('brand5_name_en')
    this.inputStoreData('brand5_country_main')
    this.inputStoreData('brand5_country_other')
    
  },
  methods: {
    onZipInput(e, key = null) {
      if (!zipNumOnlyTest(e.target.value)) return
      const zipValue = makeZip(e.target.value)
      this[key] = zipValue
    },

    inputStoreData(key) {
      if (!this.$store.state.entrydata[key]) return;
      this[key] = this.$store.state.entrydata[key]
    },
    
    onChange() {
      const value = this.postCode.replace(/[^0-9]/g, '')
      if (value.length === 7) {
        KenAll(value).then(res => {
          
          const prefecture = res[0].shift()

          if (res.length === 0) {
            this.address_pref = '該当する住所はありません';
            this.address_jp = '該当する住所はありません';
          } else {
            const pref_match = this.address_pref_options.find(item => item.label === prefecture)
            this.address_pref = pref_match.value;
            this.address_jp = res[0].join('');
          }
        });
      }
    },
    addbrand(){
      if(this.BrandNum < 5)
      this.BrandNum ++;
    }
  },
  watch: {
    exhibitor_name: function (v) {
      this.$store.commit('entrydata/exhibitor_name', v)
    },
    exhibitor_name_kana: function (v) {
      this.$store.commit('entrydata/exhibitor_name_kana', v)
    },
    exhibitor_name_en: function (v) {
      this.$store.commit('entrydata/exhibitor_name_en', v)
    },

    postCode: function (v) {
      this.$store.commit('entrydata/postCode', v)
    },
    address_pref: function (v) {
      this.$store.commit('entrydata/address_pref', v)
      if ( this.isForeign ) {
        this.postCode = ''
        this.address_jp = ''
      }
    },
    address_jp: function (v) {
      this.$store.commit('entrydata/address_jp', v)
    },
    address_en: function (v) {
      this.$store.commit('entrydata/address_en', v)
    },
    country_main: function (v) {
      this.$store.commit('entrydata/country_main', v)
    },
    country_other: function (v) {
      this.$store.commit('entrydata/country_other', v)
    },
    representative_position: function (v) {
      this.$store.commit('entrydata/representative_position', v)
    },
    representative_last_name: function (v) {
      this.$store.commit('entrydata/representative_last_name', v)
    },
    representative_first_name: function (v) {
      this.$store.commit('entrydata/representative_first_name', v)
    },
    BrandNum: function (v) {
      this.$store.commit('disicion/BrandNum', v)
    },
    brand1_name_jp: function (v) {
      this.$store.commit('entrydata/brand1_name_jp', v)
    },
    brand1_name_en: function (v) {
      this.$store.commit('entrydata/brand1_name_en', v)
    },
    brand1_country_main: function (v) {
      this.$store.commit('entrydata/brand1_country_main', v)
    },
    brand1_country_other: function (v) {
      this.$store.commit('entrydata/brand1_country_other', v)
    },
    brand2_name_jp: function (v) {
      this.$store.commit('entrydata/brand2_name_jp', v)
    },
    brand2_name_en: function (v) {
      this.$store.commit('entrydata/brand2_name_en', v)
    },
    brand2_country_main: function (v) {
      this.$store.commit('entrydata/brand2_country_main', v)
    },
    brand2_country_other: function (v) {
      this.$store.commit('entrydata/brand2_country_other', v)
    },
    brand3_name_jp: function (v) {
      this.$store.commit('entrydata/brand3_name_jp', v)
    },
    brand3_name_en: function (v) {
      this.$store.commit('entrydata/brand3_name_en', v)
    },
    brand3_country_main: function (v) {
      this.$store.commit('entrydata/brand3_country_main', v)
    },
    brand3_country_other: function (v) {
      this.$store.commit('entrydata/brand3_country_other', v)
    },
    brand4_name_jp: function (v) {
      this.$store.commit('entrydata/brand4_name_jp', v)
    },
    brand4_name_en: function (v) {
      this.$store.commit('entrydata/brand4_name_en', v)
    },
    brand4_country_main: function (v) {
      this.$store.commit('entrydata/brand4_country_main', v)
    },
    brand4_country_other: function (v) {
      this.$store.commit('entrydata/brand4_country_other', v)
    },
    brand5_name_jp: function (v) {
      this.$store.commit('entrydata/brand5_name_jp', v)
    },
    brand5_name_en: function (v) {
      this.$store.commit('entrydata/brand5_name_en', v)
    },
    brand5_country_main: function (v) {
      this.$store.commit('entrydata/brand5_country_main', v)
    },
    brand5_country_other: function (v) {
      this.$store.commit('entrydata/brand5_country_other', v)
    },



  },
  computed: {

    country_main_options() {
      return this.$store.state.disicion.country_main_options
    },
    country_other_options() {
      return this.$store.state.disicion.country_other_options
    },

  }
};
</script>

<style>
</style>
