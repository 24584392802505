import { makeUnsetBillingList } from '@/helper/bill'

export default {
  namespaced: true,
  state: {
    list: [],
    unset: [],
  },
  mutations: {
    list(state, payload) {
      state.list = payload
    },
    unset(state, payload) {
      state.unset = payload
    },
  },
  actions: {
    unset({ commit }, results) {
      commit('unset', results)
    },
    unsetRefresh() {
      makeUnsetBillingList()
    },
  },
}
