<template>
<div class="one_entry_contents">
  <h4>オフィシャルデー招待状の申込</h4>
  <div class="one_entry_contents_inner">
    <ol class="common_ol">
      <li>オフィシャルデーの招待状は、原則として出展者代表を含む{{ numberPeopleInvited }}名以内の役員とさせていただきます。</li>
      <li>発 送 日：2025年9月下旬頃を予定しております。</li>
    </ol>
    <h5>代表者ご芳名</h5>
    <RepresentativeForm />
    <template v-if="isShow(2)">
      <h5>ご案内貴社役員ご芳名1</h5>
      <OfficerForm1 />
    </template>
    <template v-if="isShow(3)">
      <h5>ご案内貴社役員ご芳名2</h5>
      <OfficerForm2 />
    </template>
    <template v-if="isShow(4)">
      <h5>ご案内貴社役員ご芳名3</h5>
      <OfficerForm3 />
    </template>
  </div>
</div>
</template>

<script setup>
import RepresentativeForm from '@/views/p3/TicketEventParticipation/RepresentativeForm'
import OfficerForm1 from '@/views/p3/TicketEventParticipation/OfficerForm1'
import OfficerForm2 from '@/views/p3/TicketEventParticipation/OfficerForm2'
import OfficerForm3 from '@/views/p3/TicketEventParticipation/OfficerForm3'

import { useRoute } from 'vue-router'
const route = useRoute()

import { getValueByMatrixCell, getNoteNumber } from "@/helper/staffAuthority"

const numberPeopleInvited = (() => {
  // カテゴリーによりパターンあり（登録人数:1=代表込3名 2=代表込2名 3=代表込4名）
  const table = {
    1: 3,
    2: 2,
    3: 4,
  }
  try {
    const value = getValueByMatrixCell(route.name)
    const index = getNoteNumber(value)
    return table[index]
  } catch (e) {
    console.log(e)
  }
  return null
})()
const isShow = (n) => {
  return n <= numberPeopleInvited
}
</script>
