import APIClient from "@/store/repository/APIClient"
import ApiConst from "@/const/api"
import { isValue } from "@/helper/index"
import { store } from "@/store"
import { computed } from "vue"

const endpoint = ApiConst.API_APP_LIST
const api = new APIClient()

const deliveringSetupDivs = [
  { form_no: 4, application_div: "anchor_bolt" },
  { form_no: 6, application_div: "cleaning" },
  { form_no: 9, application_div: "cleaning" },
  { form_no: 5, application_div: "detector" },
  { form_no: 4, application_div: "electro_regist" },
  { form_no: 6, application_div: "extra_comm" },
  { form_no: 8, application_div: "extra_comm2" },
  { form_no: 4, application_div: "parking_card" },
  { form_no: 5, application_div: "sanitary_regist" },
  { form_no: 1, application_div: "entrance_ticket" },
  { form_no: 2, application_div: "entrance_ticket" },
  { form_no: 1, application_div: "normal_ticket" },
  { form_no: 1, application_div: "guidebook_order" },
]

const mainFormDivs = [
  {
    form_no: 1,
    application_div: "anchor_bolt",
    route: "p3_exhibition_anchor_bolt",
    label: "アンカーボルト使用届",
  },
  {
    form_no: 1,
    application_div: "cleaning",
    route: "p3_exhibition_cleaning",
    label: "小間内清掃申込（小間内清掃）",
  },
  {
    form_no: 4,
    application_div: "cleaning",
    route: "p3_exhibition_cleaning",
    label: "小間内清掃申込（ワックス清掃）",
  },
  {
    form_no: 1,
    application_div: "detector",
    route: "p3_exhibition_detector",
    label: "無線式煙(熱)感知器・消火器使用申込",
  },
  {
    form_no: 2,
    application_div: "detector",
    route: "p3_exhibition_detector",
    label: "無線式煙(熱)感知器・消火器使用申込",
  },
  {
    form_no: 1,
    application_div: "electro_regist",
    route: "p3_exhibition_electro_regist",
    label: "電気使用申込",
  },
  {
    form_no: 1,
    application_div: "extra_comm",
    route: "p3_exhibition_extra_comm",
    label: "臨時通信設備使用申込（一般電話）",
  },
  {
    form_no: 1,
    application_div: "extra_comm2",
    route: "p3_exhibition_extra_comm2",
    label: "高速通信回線",
  },
  {
    form_no: 2,
    application_div: "extra_comm2",
    route: "p3_exhibition_extra_comm2",
    label: "高速通信回線",
  },
  {
    form_no: 2,
    application_div: "parking_card",
    route: "p3_ticket_parking_pass",
    label: "地下駐車場パスカード申込",
  },
  {
    form_no: 1,
    application_div: "sanitary_regist",
    route: "p3_exhibition_sanitary_regist",
    label: "給水使用申込",
  },
  {
    form_no: 1,
    application_div: "entrance_ticket",
    route: "p3_ticket_entrance_ticket",
    label: "出展者入門証追加",
  },
  {
    form_no: 2,
    application_div: "entrance_ticket",
    route: "p3_ticket_entrance_ticket",
    label: "出展関係業者バッジ申込",
  },
  {
    form_no: 1,
    application_div: "normal_ticket",
    route: "p3_ticket_normal_ticket",
    label: "一般入場券・自動車ガイドブック(Vol.70)引換券・封筒申込",
  },
  {
    form_no: 1,
    application_div: "guidebook_order",
    route: "p3_ticket_guidebook_order",
    label: "自動車ガイドブック(Vol.70)現物申込",
  },
]

const mainSetFormList = async () => {
  const queueList = []
  const results = []
  mainFormDivs.forEach((query) => {
    queueList.push(
      api.get(endpoint, query).then((res) => {
        try {
          const matches = res.response.data.results.list
          if (matches.length < 1) {
            throw new Error("Delivering address not yet set.")
          }
          results.push({ ...query, list: matches })
        } catch (e) {
          console.error(e.message)
          return false
        }
      })
    )
  })
  await Promise.all(queueList)
  return results
}

const fetchUnsetDeliveringList = async () => {
  const queueList = []
  const results = []
  deliveringSetupDivs.forEach((query) => {
    queueList.push(
      api.get(endpoint, query).then((res) => {
        try {
          const results = res?.response?.data?.results?.list
          if (!results || results.length < 1) {
            throw new Error("Delivering information not found.")
          }
          const matches = res.response.data.results.list.filter((el) =>
            !isValue(el.json_data.delivering_id)
          )
          if (matches.length >= 1) {
            throw new Error("Delivering address not yet set.")
          }
        } catch (e) {
          console.error(e.message)
          results.push(query)
          return false
        }
      })
    )
  })
  await Promise.all(queueList)
  return results
}

export const makeUnsetDeliveringList = async () => {
  const setForms = await mainSetFormList()
  const deliveringList = await fetchUnsetDeliveringList()
  const set = (acc, form_no, application_div) => {
    const matches = setForms.filter(
      (el) => el.form_no === form_no && el.application_div === application_div
    )
    if (matches.length) {
      acc.push(matches.shift())
      return acc
    }
  }
  const list = deliveringList.reduce((acc, cur) => {
    const { form_no, application_div } = cur
    if (form_no === 4 && application_div === "anchor_bolt")
      set(acc, 1, "anchor_bolt")
    if (form_no === 6 && application_div === "cleaning")
      set(acc, 1, "cleaning")
    if (form_no === 9 && application_div === "cleaning")
      set(acc, 4, "cleaning")
    if (form_no === 5 && application_div === "detector")
      set(acc, 1, "detector")
    if (form_no === 5 && application_div === "detector")
      set(acc, 2, "detector")
    if (form_no === 4 && application_div === "electro_regist")
      set(acc, 1, "electro_regist")
    if (form_no === 6 && application_div === "extra_comm")
      set(acc, 1, "extra_comm")
    if (form_no === 8 && application_div === "extra_comm2")
      set(acc, 1, "extra_comm2")
    if (form_no === 8 && application_div === "extra_comm2")
      set(acc, 2, "extra_comm2")
    if (form_no === 4 && application_div === "parking_card")
      set(acc, 2, "parking_card")
    if (form_no === 5 && application_div === "sanitary_regist")
      set(acc, 1, "sanitary_regist")
    if (form_no === 1 && application_div === "entrance_ticket")
      set(acc, 1, "entrance_ticket")
    if (form_no === 2 && application_div === "entrance_ticket")
      set(acc, 2, "entrance_ticket")
    if (form_no === 1 && application_div === "normal_ticket")
      set(acc, 1, "normal_ticket")
    if (form_no === 1 && application_div === "guidebook_order")
      set(acc, 1, "guidebook_order")
    return acc
  }, [])
  store.dispatch("delivery/unset", list.filter((el, index, self) => {
    // name だけをリスト化する
    const labelList = self.map(l => l['label']);
    // 重複を削除する
    if (labelList.indexOf(el.label) === index) {
      return el;
    }
  }))
}

export const unsetDeliveringList = computed(() => {
  const list = store.state.delivery.unset
  const errorList = list.map((v) => {
    return mainFormDivs.find(
      (el) =>
        el.form_no === v.form_no && el.application_div === v.application_div
    )
  })
  return errorList
})
