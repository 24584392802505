<template>
  <div id="admin">
    <header>
      <h1>JAIS 2025 出展者登録DB&nbsp;&nbsp;管理者用ページ</h1>
    </header>
    <article>
      <h2>{{ getPageName }}</h2>
      <div id="form_ctn" class="form_box">
        <ErrorComponent></ErrorComponent>

        <AdminNav :categoryTop="false" :category="2" :pageName="getPageName" />
        <h3>検索結果</h3>
        <p>検索条件：{{ searchCondition }}</p>

        <table class="common_table zebra_color">
          <thead>
            <tr>
              <th>会社名</th>
            </tr>
          </thead>
          <tbody>
          <template v-for="(el, index) in list" :key="index">
            <tr>
              <td><router-link :to="{name: 'AdminSpoofingEdit', params: { exhibitor_id: el.exhibitor_id }}">{{ el.exhibitor_name }}</router-link></td>
            </tr>
          </template>
          </tbody>
        </table>

      </div>
    </article>
  </div>
</template>

<script setup>
import AdminNav from "@/components/AdminNav"
import { ref, computed } from "vue";
import APIClient from '@/store/repository/APIClient'
import ErrorComponent from '@/components/ErrorComponent.vue'
import { getSearchCondition, getQuery, fetchList } from '@/helper/adminSpoofingVars'
import { store } from '@/store'
import { useRoute } from 'vue-router'

const api = new APIClient()
const route = useRoute()

const fetchConfig = async () => {
  const res = await api.getConfig()
  store.commit('disicion/admin_application_options', res.data.results.admin_application)
}
fetchConfig()

const getPageName = computed(() => {
  const key = route.params.admin_application
  const name = store.state.disicion.admin_application_options[key] ?? ''
  return name
})

const query = getQuery()

const searchCondition = getSearchCondition(query)

const list = ref([])

fetchList(query, list)
</script>

<style></style>
