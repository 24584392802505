<template>
  <li v-for="(option, index) in options" :key="index">
    <label>
      <input
        :type="option.isSingle ? 'radio' : 'checkbox'"
        :name="name"
        :value="option.value"
        :checked="option.checked ? option.checked : false"
        @change="updateValue"
        :ref="setValuesRef"
      />
      <span
        :class="option.isSingle ? 'radio_text' : 'checkbox_text'"
      >{{ option.label }}</span>
    </label>
    <ul class="common_list_vertical_level2" v-show="isRecursiveDraw(option)">
      <RecursiveChecks
        @updateTree="getBranch"
        :options="option.options"
        :name="name+`_${option.value}`"
        v-model="projection"
      />
    </ul>
  </li>
</template>

<script>
import RecursiveChecks from './RecursiveChecks'

export default {
  name: 'RecursiveChecks',
  components: {
    RecursiveChecks
  },
  props: {
    modelValue: { type: String, required: true },
    options: { type: Array, required: true },
    name: { type: String, required: true },
  },
  data() {
    return {
      values: [],
      projection: []
    };
  },
  beforeUpdate() {
    this.values = []
  },
  mounted() {
    this.$emit("updateTree", this.values);
  },
  watch: {
    projection: function (e) {
      this.$emit("update:modelValue", e);
    },
  },
  methods: {
    setValuesRef(el) {
      if (el) {
        this.values.push(el)
      }
    },
    updateValue: function (e) {
      const list = []
      this.values.forEach(item => {
        if (item.checked) {
          list.push(item.value);
        }
      })
      this.$emit("update:modelValue", e)

      this.filteringOptions(e)
    },
    filteringOptions: function (e) {
      this.options.forEach((option) => {
        if(!(e.target.checked)) {
          option.checked = false
          return
        }
        if (option.value === e.target.value) return
        if (!option.isSingle) return
        option.checked = false
      })
    },
    getBranch: function (v) {
      this.$emit("updateTree", v);
    },
    isRecursiveDraw: function (option) {
      return option.options && option.checked
    }
  },
};
</script>
