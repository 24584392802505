<template>
  <header>
    <h1>JAPAN MOBILITY SHOW 2025&emsp;出展手続きサイト・出展者専用サイト</h1>
  </header>
  <article>
    <h2>出展申込書のアップロード</h2>
    <div id="form_ctn" class="form_box">

      <ErrorComponent></ErrorComponent>

      <div id="complete_text">
        <SecondSteps step="4" />
        <h3>押印した申込書を、下記よりアップロードしてください。</h3>
        <DropZone :dropzoneClassName="customClass" :maxFiles="Number(1)"
          :uploadOnDrop="true" :multipleUpload="false" :parallelUpload="1" :dropzoneMessageClassName="customClass"
          @addedFile="uploaded">
          <template v-slot:message>
            ドロップしてください。
          </template>
        </DropZone>

      </div>

      <div class="button_ctn">
        <button class="submit" @click="submit">押印後のPDFファイルをアップロード</button>
      </div>
      <p class="attention_box">アップロードを完了させないと、申込手続きは終了しません。</p>



    </div>
  </article>
</template>

<script>
import APIClient from '@/store/repository/APIClient'
import DropZone from 'dropzone-vue'
import 'dropzone-vue/dist/dropzone-vue.common.css';
import ErrorComponent from '@/components/ErrorComponent.vue';
import SecondSteps from './SecondSteps.vue';

export default {

  components: {
    DropZone,
    ErrorComponent,
    SecondSteps,
  },
  name: 'App',
  setup() {


  },
  data() {
    return {
      file: ''
    }
  },
  async created() {
    this.api = new APIClient()
    
  },
  methods: {
    uploaded(e) {
      var fileReader = new FileReader();
      var base64;
      // Onload of file read the file content
      fileReader.onload = (event) => {
        base64 = event.target.result
        // Print data in console
        this.file = base64
      }
      // Convert data to base64
      fileReader.readAsDataURL(e.file)
    },

    async submit() {
      const query = {
        pdf_data: this.file
      }
      const resObj = await this.api.post('/api/exhibitor/second_order/upload', query)
      console.log("resObj",resObj.response.data.results.url)
      console.log("resObj",resObj)
      
      this.$store.commit('entrydata/pdf_url', resObj.response.data.results.url)
      
      this.$router.push({ path: '/second/pdf_upload_complete' })
    }
  },
}
</script>
