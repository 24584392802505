import {
  perseIntOrNull
} from '@/helper/index';

export default {
  namespaced: true,
  state: {
  },
  mutations: {
    second_order_status(state, payload) {
      state.second_order_status = payload
    },
		exhibitor_id(state, payload) {
      state.exhibitor_id = payload
    },
		staff_div(state, payload) {
      state.staff_div = payload
    },
		staff_authority(state, payload) {
      state.staff_authority = payload
    },
		updated_at(state, payload) {
      state.updated_at = payload
    }
  },
  actions: {
    update({ commit }, res) {
      const results = res?.response?.data?.results
      commit('second_order_status', perseIntOrNull(results?.second_order_status))
      commit('exhibitor_id', perseIntOrNull(results?.exhibitor_id))
      commit('staff_div', results.staff_div?.toString() ?? '')
      commit('staff_authority', perseIntOrNull(results?.staff_authority))
      commit('updated_at', res?.response?.headers?.date)
    },
  }
}
