export default {
  namespaced: true,
  state: {
  },
  mutations: {
    admin_pre_list(state, payload) {
      state.admin_pre_list = payload
    },
    admin_second_list(state, payload) {
      state.admin_second_list = payload
    },
    admin_second_list_query(state, payload) {
      state.admin_second_list_query = payload
    },
    admin_second_list_params(state, payload) {
      state.admin_second_list_params = payload
    },
  },
}
