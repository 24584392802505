<template>
  <table class="common_table">
    <tbody>
      <tr>
        <th>コース</th>
        <th>単価</th>
        <th>申込数量</th>
        <th>計</th>
        <th width="300">備考</th>
      </tr>
      <tr>
        <td>パソコン1～4台の接続</td>
        <td class="ta_right">{{ pcQty1Unit.toLocaleString() }}円</td>
        <td style="white-space:nowrap;">{{ unregisteredOrDigit(currentData.pcQty1) }}</td>
        <td class="ta_right" style="white-space:nowrap;">{{ calcCurrent.pcQty1.toLocaleString() }} 円</td>
        <td rowspan="5">・ルーター・HUB等必要機器の貸し出し<br>
          ・LANケーブル（5m以内）など必要本数の敷設<br>
          ・パソコン・ルーターなど必要な設定及び取扱説明<br>
          ・展示会会期中の保守
        </td>
      </tr>
      <tr>
        <td>パソコン5～8台の接続</td>
        <td class="ta_right">{{ pcQty2Unit.toLocaleString() }}円</td>
        <td style="white-space:nowrap;">{{ unregisteredOrDigit(currentData.pcQty2) }}</td>
        <td class="ta_right" style="white-space:nowrap;">{{ calcCurrent.pcQty2.toLocaleString() }} 円</td>
      </tr>
      <tr>
        <td>パソコン9～16台の接続</td>
        <td class="ta_right">{{ pcQty3Unit.toLocaleString() }}円</td>
        <td style="white-space:nowrap;">{{ unregisteredOrDigit(currentData.pcQty3) }}</td>
        <td class="ta_right" style="white-space:nowrap;">{{ calcCurrent.pcQty3.toLocaleString() }} 円</td>
      </tr>
      <tr>
        <td>パソコン17台以上の接続</td>
        <td colspan="3" rowspan="2" style="text-align:left; white-space:nowrap;">下記より別途見積依頼をしてください。</td>
      </tr>
      <tr>
        <td>LANケーブルが5mを超える場合</td>
      </tr>
      <tr>
        <td>固定IP：1アドレス</td>
        <td class="ta_right">{{ staticIpQty1Unit.toLocaleString() }}円</td>
        <td style="white-space:nowrap;">{{ unregisteredOrDigit(currentData.staticIpQty1) }}</td>
        <td class="ta_right" style="white-space:nowrap;">{{ calcCurrent.staticIpQty1.toLocaleString() }} 円</td>
        <td rowspan="2">インターネットへの公開サーバー設置など特殊な場合に必要となります。</td>
      </tr>
      <tr>
        <td>固定IP：8アドレス</td>
        <td class="ta_right">{{ staticIpQty2Unit.toLocaleString() }}円</td>
        <td style="white-space:nowrap;">{{ unregisteredOrDigit(currentData.staticIpQty2) }}</td>
        <td class="ta_right" style="white-space:nowrap;">{{ calcCurrent.staticIpQty2.toLocaleString() }} 円</td>
      </tr>
      <tr>
        <td style="white-space:nowrap;">&nbsp;</td>
        <td colspan="2" style="white-space:nowrap; text-align:right;">合計金額（消費税込）</td>
        <td class="ta_right"><span style="white-space:nowrap;">{{ calcCurrent.total.toLocaleString() }}</span> 円</td>
        <td>&nbsp;</td>
      </tr>
    </tbody>
  </table>
  <template v-if="isShowUI">
  <div><a v-if="isNew" @click.prevent="newHandler" href="#form2" class="common_add_button modalOpen">新規追加</a><a v-else
      @click.prevent="editHandler" href="#form2" class="common_add_button modalOpen">変更</a>&nbsp;&nbsp;<a
      @click.prevent="deleteHandler" href="#form2" class="common_add_button gray_button">クリア</a></div>
  </template>
  <Teleport to="body">
    <!-- モーダルコンテンツ / -->
    <div class="overlay" id="form2">
      <div id="modal_content" class="modal_contents w80">
        <div class="modalClose_ctn"><a @click.prevent="modalClose" class="modalClose"></a></div>
        <!-- modal contents ここから-->
        <div class="entry_title">
          <h4>臨時通信設備使用申込（高速通信回線）</h4>
          <h5>光高速通信回線用オプション&nbsp;&nbsp;{{ uploadLabel }}</h5>

          <div class="entry_modal_ctn">
            <table class="common_table">
              <tbody>
                <tr>
                  <th>コース</th>
                  <th>単価</th>
                  <th>申込数量</th>
                  <th>計</th>
                  <th width="300">備考</th>
                </tr>
                <tr>
                  <td>パソコン1～4台の接続</td>
                  <td class="ta_right">{{ pcQty1Unit.toLocaleString() }}円</td>
                  <td><input v-model="form.pcQty1" type="number" size="5" min="0" class="w5em">
                    <p class="alert" v-if="errors.pcQty1">{{ errors.pcQty1 }}</p></td>
                  <td class="ta_right" style="white-space:nowrap;">{{ calcForm.pcQty1.toLocaleString() }} 円</td>
                  <td rowspan="5">・ルーター・HUB等必要機器の貸し出し<br>
                    ・LANケーブル（5m以内）など必要本数の敷設<br>
                    ・パソコン・ルーターなど必要な設定及び取扱説明<br>
                    ・展示会会期中の保守
                  </td>
                </tr>
                <tr>
                  <td>パソコン5～8台の接続</td>
                  <td class="ta_right">{{ pcQty2Unit.toLocaleString() }}円</td>
                  <td style="white-space:nowrap;"><input v-model="form.pcQty2" type="number" size="5" min="0" class="w5em">
                    <p class="alert" v-if="errors.pcQty2">{{ errors.pcQty2 }}</p></td>
                  <td class="ta_right" style="white-space:nowrap;">{{ calcForm.pcQty2.toLocaleString() }} 円</td>
                </tr>
                <tr>
                  <td>パソコン9～16台の接続</td>
                  <td class="ta_right">{{ pcQty3Unit.toLocaleString() }}円</td>
                  <td style="white-space:nowrap;"><input v-model="form.pcQty3" type="number" size="5" min="0" class="w5em">
                    <p class="alert" v-if="errors.pcQty3">{{ errors.pcQty3 }}</p></td>
                  <td class="ta_right" style="white-space:nowrap;">{{ calcForm.pcQty3.toLocaleString() }} 円</td>
                </tr>
                <tr>
                  <td>パソコン17台以上の接続</td>
                  <td colspan="3" rowspan="2" style="text-align:left; white-space:nowrap;">下記より別途見積依頼をしてください。</td>
                </tr>
                <tr>
                  <td>LANケーブルが5mを超える場合</td>
                </tr>
                <tr>
                  <td>固定IP：1アドレス</td>
                  <td class="ta_right">{{ staticIpQty1Unit.toLocaleString() }}円</td>
                  <td style="white-space:nowrap;"><input v-model="form.staticIpQty1" type="number" size="5" min="0" class="w5em">
                    <p class="alert" v-if="errors.staticIpQty1">{{ errors.staticIpQty1 }}</p></td>
                  <td class="ta_right" style="white-space:nowrap;">{{ calcForm.staticIpQty1.toLocaleString() }} 円</td>
                  <td rowspan="2">インターネットへの公開サーバー設置など特殊な場合に必要となります。</td>
                </tr>
                <tr>
                  <td>固定IP：8アドレス</td>
                  <td class="ta_right">{{ staticIpQty2Unit.toLocaleString() }}円</td>
                  <td style="white-space:nowrap;"><input v-model="form.staticIpQty2" type="number" size="5" min="0" class="w5em">
                    <p class="alert" v-if="errors.staticIpQty2">{{ errors.staticIpQty2 }}</p></td>
                  <td class="ta_right" style="white-space:nowrap;">{{ calcForm.staticIpQty2.toLocaleString() }} 円</td>
                </tr>
                <tr>
                  <td style="white-space:nowrap;">&nbsp;</td>
                  <td colspan="2" style="white-space:nowrap; text-align:right;">合計金額（消費税込）</td>
                  <td class="ta_right"><span style="white-space:nowrap;">{{ calcForm.total.toLocaleString() }}</span> 円</td>
                  <td>&nbsp;</td>
                </tr>
              </tbody>
            </table>


            <div class="button_ctn">
              <ul>
                <li><button @click="modalClose" class="gray_button modalClose">キャンセル</button></li>
                <li><button @click="sendForm" class="regist_button">上記内容で登録</button></li>
              </ul>
            </div>
            <!-- modal contents ここまで-->
          </div>
        </div>
        <!-- / モーダルコンテンツ -->
      </div>
    </div>
    <!-- / モーダルコンテンツ -->
  </Teleport>
</template>

<script setup>
import { reactive, computed } from 'vue'
import { sum, forEach, cloneDeep } from 'lodash'

import APIClient from '@/store/repository/APIClient'
import * as yup from 'yup'
import moment from "moment"
import { FormValidate } from '@/helper/FormValidate'

import {
  modalOpen,
  modalClose,
} from '@/helper/modal'

import ApiConst from '@/const/api'

import { isShowable as isShowUI } from '@/helper/indexStatus'

const api = new APIClient()

const formInit = {
  application_id: null,
  pcQty1: 0,
  pcQty2: 0,
  pcQty3: 0,
  staticIpQty1: 0,
  staticIpQty2: 0,
}

const form = reactive(cloneDeep(formInit))
const currentData = reactive(cloneDeep(formInit))

const pcQty1Unit = 44000
const pcQty2Unit = 60500
const pcQty3Unit = 82500
const staticIpQty1Unit = 33000
const staticIpQty2Unit = 88000

const unregisteredOrDigit = (num) => {
  if (num) return num.toLocaleString()
  return '未登録'
}

const calcForm = computed(() => {
  return {
    pcQty1: form.pcQty1 * pcQty1Unit,
    pcQty2: form.pcQty2 * pcQty2Unit,
    pcQty3: form.pcQty3 * pcQty3Unit,
    staticIpQty1: form.staticIpQty1 * staticIpQty1Unit,
    staticIpQty2: form.staticIpQty2 * staticIpQty2Unit,
    total: sum([
      form.pcQty1 * pcQty1Unit,
      form.pcQty2 * pcQty2Unit,
      form.pcQty3 * pcQty3Unit,
      form.staticIpQty1 * staticIpQty1Unit,
      form.staticIpQty2 * staticIpQty2Unit,
    ])
  }
})

const calcCurrent = computed(() => {
  return {
    pcQty1: currentData.pcQty1 * pcQty1Unit,
    pcQty2: currentData.pcQty2 * pcQty2Unit,
    pcQty3: currentData.pcQty3 * pcQty3Unit,
    staticIpQty1: currentData.staticIpQty1 * staticIpQty1Unit,
    staticIpQty2: currentData.staticIpQty2 * staticIpQty2Unit,
    total: sum([
      currentData.pcQty1 * pcQty1Unit,
      currentData.pcQty2 * pcQty2Unit,
      currentData.pcQty3 * pcQty3Unit,
      currentData.staticIpQty1 * staticIpQty1Unit,
      currentData.staticIpQty2 * staticIpQty2Unit,
    ])
  }
})

const isNew = computed(() => {
  return !currentData.application_id
})

const uploadLabel = computed(() => {
  return !isNew.value ? '変更' : '新規登録'
})

const fetch = async () => {
  try {
    const query = {
      form_no: 2,
      application_div: "extra_comm2",
    }
    const fetchApi = await api.get(ApiConst.API_APP_LIST, query)
    const res = fetchApi.response
    const errors = res.data?.error_results
    if (errors) {
      throw new Error('Not found.')
    }
    forEach(currentData, (v, k) => {
      currentData[k] = formInit[k]
    })
    const data = res.data.results.list.map((el) => {
      return {
        application_id: el.application_id,
        input_date: moment(el.updated_at).format("YY-MM-DD"),
        seq_no: el.seq_no,
        ...el.json_data
      }
    })[0] ?? false
    if (!data) return

    forEach(currentData, (v, k) => {
      currentData[k] = data[k]
    })
    forEach(form, (v, k) => {
      form[k] = data[k] ?? formInit[k]
    })
  } catch (e) {
    console.log(e)
    return false
  }
}
fetch()

const newHandler = (event) => {
  forEach(formInit, (v, k) => {
    form[k] = v
  })
  modalOpen(event)
}

const editHandler = (event) => {
  modalOpen(event)
}

const deleteHandler = async () => {
  if (!window.confirm('クリアして宜しいでしょうか？')) return false

  try {
    const application_id = currentData.application_id
    const operation = "D"
    const query = { operation, application_id }
    const res = await api.post(ApiConst.API_APP_EDIT, query)
    if (res.response.data?.error_results) {
      throw new Error('API Validation Error')
    }
    fetch()
  } catch (e) {
    return false
  }
}

const scheme = {
  pcQty1: yup.number().typeError('数値を入力してください。'),
  pcQty2: yup.number().typeError('数値を入力してください。'),
  pcQty3: yup.number().typeError('数値を入力してください。'),
  pcQty4: yup.number().typeError('数値を入力してください。'),
}
const validate = new FormValidate(scheme, form)
const errors = validate.errors
const sendForm = async () => {
  try {
    if ((Object.keys(await validate.getErrorObject()).length)) {
      throw new Error('入力項目に不備があります。')
    }
    const params = cloneDeep(form)
    params.pcQty1_total_price = calcForm.value.pcQty1
    params.pcQty2_total_price = calcForm.value.pcQty2
    params.pcQty3_total_price = calcForm.value.pcQty3
    params.staticIpQty1_total_price = calcForm.value.staticIpQty1
    params.staticIpQty2_total_price = calcForm.value.staticIpQty2
    params.total_price = calcForm.value.total
    const application_id = params.application_id
    delete params.application_id
    const operation = application_id ? "R" : "I"
    const query = operation === "I"
      ? { operation, application_div: "extra_comm2", form_no: 2, json_data: params }
      : { operation, application_id, json_data: params }
    const res = await api.post(ApiConst.API_APP_EDIT, query)
    if (res.response.data?.error_results) {
      throw new Error('API Validation Error')
    }
    fetch()
    modalClose()
  } catch (e) {
    return false
  }
}
</script>

<style></style>
