<template>
  <table class="common_table">
    <tbody>
      <tr>
        <th>アンカーボルト使用料（単価）（消費税込）</th>
        <th>申込数量</th>
        <th>合計金額（消費税込）</th>
      </tr>

      <tr>
        <td class="ta_right">{{ perUnitFormat }}円</td>
        <td class="ta_right">{{ qty }}本 </td>
        <td class="ta_right">{{ total }}円</td>
      </tr>

    </tbody>
  </table>
  <template v-if="isShowUI">
  <div><a @click.prevent="modalOpen" href="#modal1" class="common_add_button modalOpen">{{uploadLabel}}</a></div>
  </template>

  <Teleport to="body">
    <!-- モーダルコンテンツ / -->
    <div class="overlay" id="modal1">
      <div id="modal_content" class="modal_contents w80">
        <!-- modal contents ここから-->
        <div class="modalClose_ctn"><a @click.prevent="modalClose" class="modalClose"></a></div>
        <div class="entry_title">
          <h4>アンカーボルト使用届</h4>
          <h5>アンカーボルト&nbsp;&nbsp;{{uploadLabel}}</h5>
        </div>
        <div class="entry_modal_ctn">
          <table class="common_table va_middle">
            <tbody>
              <tr>
                <th>アンカーボルト使用料（単価）（消費税込）</th>
                <th>申込数量</th>
                <th>合計金額（消費税込）</th>
              </tr>
              <tr>
                <td class="ta_right">{{ perUnitFormat }}円</td>
                <td class="ta_right "><input v-model="form.cost" type="number" min="0" size="5" style="ime-mode: disabled; "
                    class="three_quarters ta_right" /> 本
                    <p class="alert" v-if="errors.cost">{{ errors.cost }}</p>
                </td>
                <td class="ta_right">{{ tempTotal }} 円</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="button_ctn">
          <ul>
            <li>
              <button @click.prevent="modalClose" class="gray_button modalClose">キャンセル</button>
            </li>
            <li><button @click="sendForm" class="regist_button">上記内容で登録</button></li>
          </ul>
        </div>
        <!-- modal contents ここまで-->
      </div>
    </div>
    <!-- / モーダルコンテンツ -->
  </Teleport>
</template>


<script setup>
import APIClient from '@/store/repository/APIClient'
import { ref, reactive, computed, } from 'vue'
import { cloneDeep } from 'lodash'
import { FormValidate } from '@/helper/FormValidate'
import * as yup from 'yup'

import {
  isValue
} from '@/helper/index';

import {
  modalOpen,
  modalClose,
} from '@/helper/modal'

import ApiConst from '@/const/api'

import { isShowable as isShowUI } from '@/helper/indexStatus'

const api = new APIClient()

const perUnit = 1100
const perUnitFormat = perUnit.toLocaleString()
const qty = ref(0)
const id = ref(null)
const total = computed(() => {
  return (perUnit * qty.value).toLocaleString()
})

const uploadLabel = computed(() => {
  return isValue(qty.value) ? '変更' : '新規登録'
})

const formInit = {
  cost: null,
}
const form = reactive(cloneDeep(formInit))

const tempTotal = computed(() => {
  return (perUnit * form.cost).toLocaleString()
})

const fetch = async () => {
  const query = {
    form_no: 1,
    application_div: "anchor_bolt",
  }
  const fetchApi = await api.get(ApiConst.API_APP_LIST, query)
  const res = fetchApi.response
  const errors = res.data?.error_results
  if (errors) {
    throw new Error('Not found.')
  }
  id.value = res.data.results.list[0].application_id
  qty.value = res.data.results.list[0].json_data.cost
  form.cost = qty.value
}
fetch()

const scheme = {
  cost: yup.number().typeError('数値を入力してください。').required('必須の項目です。'),
}
const validate = new FormValidate(scheme, form)
const errors = validate.errors
const sendForm = async () => {
  try {
    if ((Object.keys(await validate.getErrorObject()).length)) {
      throw new Error('入力項目に不備があります。')
    }
    const application_id = id.value
    const params = {
      cost: form.cost
    }
    const operation = application_id ? "R" : "I"
    const query = operation === "I"
      ? { operation, application_div: "anchor_bolt", form_no: 1, json_data: params }
      : { operation, application_id, json_data: params }
    const fetchApi = await api.post(ApiConst.API_APP_EDIT, query)
    const res = fetchApi.response
    if (res.data?.error_results) {
      throw new Error('API Validation Error')
    }
    fetch()
    modalClose()
  } catch (e) {
    console.log(e)
    return false
  }
}
</script>

<style></style>
