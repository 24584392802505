export default {
  namespaced: true,
  state: {
  },
  mutations: {
    membership_category_options(state, payload) {
      state.membership_category_options = payload
    },
    membership_category_options_admin(state, payload) {
      state.membership_category_options_admin = payload
    },
    category_div_options(state, payload) {
      state.category_div_options = payload
    },
    country_main_options(state, payload) {
      state.country_main_options = payload
    },
    country_other_options(state, payload) {
      state.country_other_options = payload
    },
    past_achievement_options(state, payload) {
      state.past_achievement_options = payload
    },
    exhibit_participation_status_options(state, payload) {
      state.exhibit_participation_status_options = payload
    },
    address_pref_options(state, payload) {
      state.address_pref_options = payload
    },
    joint_adjacent_options(state, payload) {
      state.joint_adjacent_options = payload
    },
    BrandNum(state, payload) {
      state.BrandNum = payload
    },
    //phase2
    staff_authority_options(state, payload) {
      state.staff_authority_options = payload
    },
    category_disp_options(state, payload) {
      state.category_disp_options = payload
    },
    function_code_options(state, payload) {
      state.function_code_options = payload
    },
    admin_application_options(state, payload) {
      state.admin_application_options = payload
    },
    category_disp_options_plus(state, payload) {
      state.category_disp_options_plus = payload
    },
    hall_div_options(state, payload) {
      state.hall_div_options = payload
    },
    hall_div_options_plus(state, payload) {
      state.hall_div_options_plus = payload
    },
    
  },
}
