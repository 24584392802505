<template>
  <header>
    <h1>JAPAN MOBILITY SHOW 2025&emsp;出展手続きサイト・出展者専用サイト</h1>
  </header>
  <article>
    <h2>出展申込書のアップロード</h2>
    <div id="form_ctn" class="form_box">

        <SecondSteps step="3" />
        <h3>申込み内容の変更</h3>
        <div class="button_ctn">
          <router-link to="/second/confirm" class="submit" style="width: 30em;">申込内容の変更ページへ</router-link>
        </div>

        <h3>申込み書のアップロード</h3>
        <div class="button_ctn">
          <router-link to="/second/pdf_upload" class="submit" style="width: 30em;">申込み書のアップロードページへ</router-link>
        </div>

      </div>

  </article>
</template>

<script>
import SecondSteps from './SecondSteps.vue';
export default {
  components: {
    SecondSteps,
  },
}
</script>
