<template>
  <div id="admin">
    <header>
      <h1>JMS 2025 出展者登録DB 管理者用ページ</h1>
    </header>
    <article>
      <h2>ログイン</h2>
      <div id="form_ctn" class="form_box">
        <div v-for="(error, i) in errorList" :key="i">
          {{ error }}
        </div>
        <dl id="login_list">
          <dt>ID</dt>
          <dd>
            <my-input name="id" width_class="full" type="text" v-model="id"></my-input>
            <p class="alert" v-if="errors.id">{{ errors.id }}</p>
          </dd>
          <dt>パスワード</dt>
          <dd>
            <my-input name="password" width_class="full" type="password" v-model="password"></my-input>
            <p class="alert" v-if="errors.password">{{ errors.password }}</p>
          </dd>
        </dl>
        <p id="forget_pass">パスワード未設定の方、または忘れた方は<a href="">こちら</a></p>
        <div class="button_ctn">
          <button class="submit" @click="login">ログイン</button>
        </div>
      </div>
      
    </article>
    </div>
</template>

<script>
import { store } from '@/store'
import ApiConst from '@/const/api'
import APIClient from '@/store/repository/APIClient'
import MyInput from '@/components/MyInput.vue';

import { useField, useForm } from 'vee-validate';
import * as yup from 'yup';
import { useRouter } from 'vue-router'

const api = new APIClient()

export default {
  components: {
    MyInput,

  },
  setup() {
    const router = useRouter();

    const schema = yup.object({
      id: yup.string().required('必須の項目です。'),
      password: yup.string().required('必須の項目です。'),
    });

    const { errors, handleSubmit } = useForm({
      validationSchema: schema,
    });

    const login = handleSubmit(async (values) => {
      const res = await api.post(ApiConst.API_ADMIN_LOGIN, {
        admin_login_id : values.id,
        admin_login_pw : values.password,
      });
      const data = res.response.data
      if (data.error_results) {
        return
      }
      if (data.results) {
        store.commit('administrator/admin_token', data.results.admin_token)
        store.commit('administrator/limit_datetime', data.results.limit_datetime)
        store.commit('administrator/admin_refresh_token', data.results.admin_refresh_token)
        store.commit('administrator/limit_datetime_refresh_token', data.results.limit_datetime_refresh_token)
      }
      router.push({ name: 'AdminIndex' });
    });

    const { value: id } = useField('id', null, {
      validateOnValueUpdate: false,
    });

    const { value: password } = useField('password', null, {
      validateOnValueUpdate: false,
    });

    return {
      errors,
      id,
      password,

      login,
    };
  },
  data() {
    return {

    }
  },
  async created() {
    await api.get(`/sanctum/csrf-cookie`);
  },
  mounted() {

  },
  methods: {

  },
  watch: {

  },
  computed: {
    errorList() {
      return this.$store.state.message.errors
    },
  }
};
</script>

<style>

</style>
