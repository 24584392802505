<template>
  <div id="admin">
    <header>
      <h1>JMS 2025 出展者登録DB 管理者用ページ</h1>
    </header>
    <article>
      
      <h2>一次申込管理</h2>
      <div id="form_ctn" class="form_box">
      <h3>申込者リスト</h3>
            <p>検索条件：</p>
            <table class="common_table">
              <thead>
                <tr>
                  <th class="sort_th" @click="sortExhibitParticipatioId">申込番号</th>
                  <th class="sort_th" @click="sortExhibitParticipationStatus">審査ステータス</th>
                  <th class="sort_th" @click="sortExhibitorName">会社名</th>
                  <th class="sort_th" @click="sortMembershipCategory">会員区分</th>
                  <th class="sort_th" @click="sortExhibitParticipationStatus">過去の出展実績</th>
                  <th class="sort_th" @click="sortCategoryDiv1">出展カテゴリー</th>
                </tr>
              </thead>
              <tbody>


                
                <template v-for="(list, index) in admin_pre_list" :key="index">
                <tr :class="{
                    'status_hold': list.exhibit_participation_status === 0,
                    'status_approval': list.exhibit_participation_status === 1,
                    'status_denial': list.exhibit_participation_status === 2
                  }">
                  <td>{{list.exhibit_participation_id }}</td>
                  <td>{{list.exhibit_participation_status_label}}</td>
                  <td>
                    <router-link :to="{name:'AdminPreDetail', params: {id: list.exhibit_participation_id } }">
                      {{list.exhibitor_name }}</router-link></td>
                  <td>{{list.membership_category_label}}</td>
                  <td>{{list.past_achievement_label}}</td>
                  <td>{{list.category_div1_label}}</td>
                </tr>
              </template>
              </tbody>
            </table>



          </div>



          <div id="form_ctn" class="form_box">
        <admin-pre-search></admin-pre-search>
      </div>

      <div class="button_ctn">
          <ul>
            <li><router-link :to="{name:'AdminIndex'}">メニュー画面に戻る</router-link>
            </li>
          </ul>
        </div>
    </article>
  </div>
</template>

<script>

import APIClient from '@/store/repository/APIClient'
import AdminPreSearch from '@/components//AdminPreSearch.vue';

import _ from 'lodash'

export default {
  components: {
    AdminPreSearch
  },
  setup() {


    return {
      

    };
  },

  data() {
    return {
      admin_pre_list: this.$store.state.search.admin_pre_list,
    }
  },
  async created() {
    this.api = new APIClient()
    const res = await this.api.getConfig()
    this.$store.commit('disicion/exhibit_participation_status_options', res.data.results.exhibit_participation_status_options)
    this.$store.commit('disicion/membership_category_options_admin', res.data.results.membership_category_options_admin)
    this.$store.commit('disicion/past_achievement_options', res.data.results.past_achievement_options)
    this.$store.commit('disicion/category_div_options', res.data.results.category_div_options)


  },
  mounted() {

  },
  methods: {
    sort(attribute) {
      const first = this.admin_pre_list[0][attribute]
      const last = this.admin_pre_list[this.admin_pre_list.length - 1][attribute]
      if (first > last) {
        this.admin_pre_list = _.sortBy(this.admin_pre_list, attribute)
      } else {
        this.admin_pre_list = _.sortBy(this.admin_pre_list, attribute).reverse()
      }
    },
    sortExhibitParticipatioId() {
      this.sort('exhibit_participation_id')
    },
    sortExhibitParticipationStatus() {
      this.sort('exhibit_participation_status')
    },
    sortPastAchievement() {
      this.sort('past_achievement')
    },
    sortExhibitorName() {
      this.sort('exhibitor_name')
    },
    sortCategoryDiv1() {
      this.sort('category_div1')
    },
    sortMembershipCategory() {
      this.sort('membership_category')
    },
    
    
    
  },
  watch: {

  },
  computed: {
    



  }
};
</script>

<style>

</style>
