<template>
  <template v-if="!isNew">
    <table class="common_table">
      <tbody>
        <tr>
          <th style="width: 50%;">日本語</th>
          <th style="width: 50%;">英語</th>
        </tr>
        <tr>
          <td v-html="currentData.booth_introductionText"></td>
          <td v-html="currentData.booth_introduction_enText"></td>
        </tr>
        <tr>
          <th colspan="2">イメージパース・竣工写真など</th>
        </tr>
        <tr>
          <td colspan="2"><img :src="currentData.booth_file" alt="" v-if="currentData.booth_file" /><template v-else>未登録</template></td>
        </tr>
      </tbody>
    </table>
    <h6 class="title_h6">ステージイベントの紹介</h6>
    <table class="common_table">
      <tbody>
        <tr>
          <th style="width: 50%;">日本語</th>
          <th style="width: 50%;">英語</th>
        </tr>
        <tr>
          <td v-html="currentData.event_introductionText"></td>
          <td v-html="currentData.event_introduction_enText"></td>
        </tr>
        <tr>
          <th colspan="2">イメージ画像など</th>
        </tr>
        <tr>
          <td colspan="2"><img :src="currentData.event_file" alt="" v-if="currentData.event_file" /><template v-else>未登録</template></td>
        </tr>
      </tbody>
    </table>
  </template>
  <div class="no_entry" v-else>登録がありません。「新規追加」ボタンより登録をお願いします。</div>

  <template v-if="isShowUI">
  <div><a
    @click.prevent="editHandler"
    href="#form1"
    class="common_add_button modalOpen"
  >{{uploadLabel}}</a></div>
  </template>

  <Teleport to="body">
    <!-- モーダルコンテンツ / -->
    <div class="overlay" id="form1">
      <div id="modal_content" class="modal_contents w80">
        <div class="modalClose_ctn"><a
          @click.prevent="modalClose"
          class="modalClose"
        ></a></div>
        <!-- modal contents ここから-->
        <div class="entry_title">
          <h4>ブース情報（見どころ）・ステージイベントの紹介&nbsp;&nbsp;{{uploadLabel}}</h4>
        </div>
        <ul class="common_ul">
          <li>入力がない場合、御社紹介欄を作成することが出来ません。</li>
          <li>英語（日本語）に入力がない場合は、英語版（日本語版）のJAPAN MOBILITY SHOW 2025 オフィシャルサイトに掲載されません。</li>
        </ul>
        <div class="entry_title">
          <h5>ブース情報（見どころ）</h5>
        </div>

        <div class="entry_modal_ctn">

          <table class="common_table">
            <tbody>
              <tr>
                <th> ブース紹介：日本語 <br>
                  （600字程度）機種依存文字は入力できません。</th>
                <td><textarea rows="5" class="full" v-model="form.booth_introduction"></textarea>
                  <p class="alert" v-if="errors.booth_introduction">{{ errors.booth_introduction }}</p></td>
              </tr>
              <tr>
                <th>ブース紹介：英語 <br>
                  （300 words 程度）</th>
                <td><textarea name="textarea" rows="5" class="full" v-model="form.booth_introduction_en"></textarea>
                  <p class="alert" v-if="errors.booth_introduction_en">{{ errors.booth_introduction_en }}</p></td>
              </tr>
              <tr>
                <th>イメージパース・竣工写真など</th>
                <td>

                  <p class="attention_color mblh">オフィシャルサイトに掲載する画像の提供をお願いします。ファイル形式はjpgもしくはpngで1600 x 900ピクセル以上、16:9か4:3の横長画像でご提供ください。画像ファイルの容量は4MB以内としてください。

                  </p>
                  <input
                  type="file"
                  id="booth_file"
                  name="booth_file"
                  v-show="!form.booth_file"
                  @change="loadFile($event, (v) => {
                    form.booth_file = v
                  })"
                  >
                  <div class="w10em" v-if="form.booth_file">
                    <img :src="form.booth_file">
                    <button @click="cancelFile('booth_file')">ファイルを削除</button>
                  </div>
                  <p class="alert" v-if="errors.booth_file">{{ errors.booth_file }}</p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>


        <div class="entry_title">
          <h5>ステージイベントの紹介</h5>
        </div>
        <div class="entry_modal_ctn">
          <table class="common_table">
            <tbody>
              <tr>
                <th> ステージイベント紹介：日本語 <br>
                  （600字程度）機種依存文字は入力できません。</th>
                <td><textarea rows="5" class="full" v-model="form.event_introduction"></textarea>
                  <p class="alert" v-if="errors.event_introduction">{{ errors.event_introduction }}</p></td>
              </tr>
              <tr>
                <th>ステージイベント紹介：英語 <br>
                  （300 words 程度）</th>
                <td><textarea name="textarea" rows="5" class="full" v-model="form.event_introduction_en"></textarea>
                  <p class="alert" v-if="errors.event_introduction_en">{{ errors.event_introduction_en }}</p></td>
              </tr>
              <tr>
                <th>イメージ画像など</th>
                <td>
                  <p class="attention_color mblh">オフィシャルサイトに掲載する画像の提供をお願いします。ファイル形式はjpgもしくはpngで1600 x

                    900ピクセル以上、16:9か4:3の横長画像でご提供ください。画像ファイルの容量は4MB以内としてください。</p>

                  <input
                  type="file"
                  id="event_file"
                  name="event_file"
                  v-show="!form.event_file"
                  @change="loadFile($event, (v) => {
                    form.event_file = v
                  })"
                  >
                  <div class="w10em" v-if="form.event_file">
                    <img :src="form.event_file">
                    <button @click="cancelFile('event_file')">ファイルを削除</button>
                  </div>
                  <p class="alert" v-if="errors.event_file">{{ errors.event_file }}</p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <ErrorComponent></ErrorComponent>

        <div class="button_ctn">
          <ul>
            <li><button
              @click.prevent="modalClose"
              class="gray_button modalClose"
            >キャンセル</button></li>
            <li><button
              @click="sendForm"
              class="regist_button"
            >上記内容で登録</button></li>
          </ul>
        </div>
        <!-- modal contents ここまで-->
        <!-- / モーダルコンテンツ -->
      </div>
    </div>
    <!-- / モーダルコンテンツ -->
  </Teleport>
</template>

<script setup>
import { reactive, computed } from 'vue'
import { forEach, cloneDeep } from 'lodash'
import APIClient from '@/store/repository/APIClient'
import * as yup from 'yup'
import moment from "moment"
import { FormValidate } from '@/helper/FormValidate'
import { loadFile, getFileExtension, uploadFile, deleteFile } from '@/helper/files'
import YupConst from '@/const/yup'
import ErrorComponent from '@/components/ErrorComponent.vue'
import { store } from '@/store'

import {
  modalOpen,
  modalClose,
} from '@/helper/modal'

import {
  nl2br,
  isValue,
  isUrl,
} from '@/helper/index'

import ApiConst from '@/const/api'

import { isShowable as isShowUI } from '@/helper/indexStatus'

const api = new APIClient()

const formInit = {
  application_id: null,
  booth_introduction: '',
  booth_introduction_en: '',
  booth_file: '',
  booth_file_application_id: null,
  event_introduction: '',
  event_introduction_en: '',
  event_file: '',
  event_file_application_id: null,
}

const form = reactive(cloneDeep(formInit))
const currentData = reactive(cloneDeep(formInit))

const isNew = computed(() => {
  return !currentData.application_id
})

const uploadLabel = computed(() => {
  return !isNew.value ? '変更' : '新規登録'
})

const fetch = async () => {
  try {
    const query = {
      form_no: 1,
      application_div : "booth_info",
    }
    const fetchApi = await api.get(ApiConst.API_APP_LIST, query)
    const res = fetchApi.response
    const errors = res.data?.error_results
    if (errors) {
      throw new Error('Not found.')
    }
    const data = res.data.results.list.map((el) => {
      return {
        application_id: el.application_id,
        input_date: moment(el.updated_at).format("YY-MM-DD"),
        seq_no: el.seq_no,
        ...el.json_data
      }
    })[0]
    forEach(currentData, (v, k) => {
      currentData[k] = data[k]
    })
    currentData.booth_introductionText =  currentData.booth_introduction ? nl2br(currentData.booth_introduction) : '未入力'
    currentData.booth_introduction_enText =  currentData.booth_introduction_en ? nl2br(currentData.booth_introduction_en) : '未入力'
    currentData.event_introductionText =  currentData.event_introduction ? nl2br(currentData.event_introduction) : '未入力'
    currentData.event_introduction_enText =  currentData.event_introduction_en ? nl2br(currentData.event_introduction_en) : '未入力'

    forEach(form, (v, k) => {
      form[k] = data[k] ?? formInit[k]
    })
  } catch (e) {
    console.log(e)
    return false
  }
}
fetch()

const editHandler = (event) => {
  store.dispatch('message/initializeErrors')
  modalOpen(event)
}

const cancelFile = (id) => {
  form[id] = ''
  document.getElementById(id).value = ''
}

const supportedFormat = YupConst.IMAGE_FILE_FORMAT
const scheme = {
  booth_introduction: yup.string().trim().max(YupConst.TEXT_MAX_BOOTH_INFO, YupConst.TEXT_MAX_BOOTH_INFO_MSG),
  booth_introduction_en: yup.string().trim()
    .matches(new RegExp(YupConst.TEXT_MAX_PATTERN_BOOTH_INFO_EN), YupConst.TEXT_MAX_BOOTH_INFO_EN_MSG),
  booth_file: yup.string().test('fileType', YupConst.IMAGE_FILE_FORMAT_MSG, v => {
    if (!v) return true
    if (isUrl(v)) return true
    const extension = getFileExtension(v)
    return supportedFormat.includes(extension)
  }).max(YupConst.IMAGE_BASE64_SIZE_5MB, YupConst.IMAGE_BASE64_SIZE_5MB_MSG),
  event_introduction: yup.string().trim().max(YupConst.TEXT_MAX_BOOTH_INFO, YupConst.TEXT_MAX_BOOTH_INFO_MSG),
  event_introduction_en: yup.string().trim()
    .matches(new RegExp(YupConst.TEXT_MAX_PATTERN_BOOTH_INFO_EN), YupConst.TEXT_MAX_BOOTH_INFO_EN_MSG),
  event_file: yup.string().test('fileType', YupConst.IMAGE_FILE_FORMAT_MSG, v => {
    if (!v) return true
    if (isUrl(v)) return true
    const extension = getFileExtension(v)
    return supportedFormat.includes(extension)
  }).max(YupConst.IMAGE_BASE64_SIZE_5MB, YupConst.IMAGE_BASE64_SIZE_5MB_MSG),
}
const validate = new FormValidate(scheme, form)
const errors = validate.errors
const sendForm = async () => {
  try {
    if ((Object.keys(await validate.getErrorObject()).length)) {
      throw new Error('入力項目に不備があります。')
    }
    const params = cloneDeep(form)
    const application_id = params.application_id
    const booth_file = params.booth_file
    const event_file = params.event_file
    delete params.application_id
    if ( params.booth_file ) delete params.booth_file
    if ( params.event_file ) delete params.event_file
    const operation = application_id ? "R" : "I"
    const query = operation === "I"
      ? {operation, application_div : "booth_info", form_no: 1, json_data: params}
      : {operation, application_id, json_data: params}
    const res = await api.post(ApiConst.API_APP_EDIT, query)
    if (res.response.data?.error_results) {
      throw new Error('API Validation Error')
    }

    if (!isValue(booth_file) && isValue(params.booth_file_application_id)) {
      await deleteFile(params.booth_file_application_id)
    }
    if (!isValue(event_file) && isValue(params.event_file_application_id)) {
      await deleteFile(params.event_file_application_id)
    }

    const { application_id : relation_application_id } = res.response.data.results
    if (!relation_application_id) {
      throw new Error('Application ID not received.')
    }
    const queueList = []
    if (isValue(booth_file) && !isUrl(booth_file)) queueList.push(uploadFile('booth_info', 2, booth_file, relation_application_id))
    if (isValue(event_file) && !isUrl(event_file)) queueList.push(uploadFile('booth_info', 3, event_file, relation_application_id))
    if (queueList.length < 1) {
      fetch()
      modalClose()
      return
    }
    await Promise.all(queueList).then(() => {
      fetch()
      modalClose()
    })
  } catch (e) {
    store.commit('message/errors', [e.message])
    return false
  }
}
</script>

<style></style>
