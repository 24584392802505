import moment from "moment"

export default {
  namespaced: true,
  state: {
    updating: false,
  },
  mutations: {
    updating(state, payload) {
      state.updating = payload
    },
    exhibitor_application_list(state, payload) {
      state.exhibitor_application_list = payload
    },
  },
  actions: {
    update({ commit }, {data, key}) {
      const query = {
        data,
        updated_at: moment(),
      }
      commit(key, query)
    },
  }
}
