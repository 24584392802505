import { useRoute } from 'vue-router'
import { store } from '@/store'


const getPermission = () => {
  const route = useRoute()
  const path = route.path
  const pathMap = path.replace(/(?<!^)\/$/, '').split('/')
  const key = pathMap[pathMap.length - 1]
  try {
    const { admin_application } = store.state.admin_application
    return admin_application[key]
  } catch (e) {
    console.log(e)
    return null
  }
}

const checkAccessible = (permission, operation) => {
  const operationMap = {
    'view' : 2,
    'download' : 1,
    'entry' : 0,
  }
  try {
    const digit = operationMap[operation]
    const radix = parseInt(permission).toString(2).padStart(3, '0')
    return !!parseInt(radix[digit])
  } catch (e) {
    console.log(e)
    return null
  }
}

export const canEntry = () => {
  try {
    const permission = getPermission()
    return checkAccessible(permission, 'entry')
  } catch (e) {
    console.log(e)
    return null
  }
}

export const canDownload = () => {
  try {
    const permission = getPermission()
    return checkAccessible(permission, 'download')
  } catch (e) {
    console.log(e)
    return null
  }
}

export const enableTestFlag = () => !!store.state.admin_application.test_flag

export const enableFixCategoryDisp = (v) => store.state.admin_application.fix_category_disp_list.includes(v)

export const enableAdminApplication = (v) => {
  const pem = store.state.admin_application.admin_application[v]
  return checkAccessible(pem, 'view')
}

export const enableFunctionCode = (key) => {
  // key
  // https://aws-plus.backlog.jp/alias/wiki/1075456523
  // function_code_list
  const pem = store.state.admin_application.function_code_list
  return pem.includes(key)
}
