export default {
  namespaced: true,
  state: {
  },
  mutations: {
    admin_token(state, payload) {
      state.admin_token = payload
    },
    limit_datetime(state, payload) {
      state.limit_datetime = payload
    },
    admin_refresh_token(state, payload) {
      state.admin_refresh_token = payload
    },
    limit_datetime_refresh_token(state, payload) {
      state.limit_datetime_refresh_token = payload
    },
    
  },
}
