<template>
  <table class="common_table">
    <tbody>
      <tr>
        <th>消火器（単価）</th>
        <th class="w15em">申込数量</th>
        <th class="w15em">合計金額（税込）</th>
      </tr>
      <tr>
        <td class="ta_right">{{ unitCost.toLocaleString() }}円</td>
        <td class="ta_right">{{ currentData.qty }}台</td>
        <td class="ta_right">{{ calcCurrentTotal.toLocaleString() }}円</td>
      </tr>
    </tbody>
  </table>
  <template v-if="isShowUI">
  <div><a
    v-if="isNew"
    @click.prevent="newHandler"
    href="#form2"
    class="common_add_button modalOpen"
  >新規追加</a><a
    v-else
    @click.prevent="editHandler"
    href="#form2"
    class="common_add_button modalOpen"
  >変更</a>&nbsp;&nbsp;<a
    @click.prevent="deleteHandler"
    href="#form2"
    class="common_add_button gray_button"
  >クリア</a></div>
  </template>
  <Teleport to="body">
    <!-- モーダルコンテンツ / -->
    <div class="overlay" id="form2">
      <div id="modal_content" class="modal_contents w80">
        <div class="modalClose_ctn"><a @click.prevent="modalClose" class="modalClose"></a></div>
        <!-- modal contents ここから-->
        <div class="entry_title">
          <h4>無線式煙(熱)感知器・消火器使用申込</h4>
          <h5>消火器設置料&nbsp;&nbsp;{{ uploadLabel }}</h5>

          <div class="entry_modal_ctn">
            <table class="common_table">
              <tbody>
                <tr>
                  <th>消火器（単価）</th>
                  <th>申込数量</th>
                  <th>合計金額（税込）</th>
                </tr>
                <tr>
                  <td class="ta_right">{{ unitCost.toLocaleString() }}円</td>
                  <td class="ta_right "><input v-model="form.qty" type="number" size="5" style="ime-mode: disabled; " class="three_quarters ta_right"> 台
                    <p class="alert" v-if="errors.qty">{{ errors.qty }}</p></td>
                  <td class="ta_right">{{ calcTotal.toLocaleString() }} 円</td>
                </tr>
              </tbody>
            </table>


            <div class="button_ctn">
              <ul>
                <li><button @click="modalClose" class="gray_button modalClose">キャンセル</button></li>
                <li><button @click="sendForm" class="regist_button">上記内容で登録</button></li>
              </ul>
            </div>
            <!-- modal contents ここまで-->
          </div>
        </div>
        <!-- / モーダルコンテンツ -->
      </div>
    </div>
    <!-- / モーダルコンテンツ -->
  </Teleport>
</template>

<script setup>
import { reactive, computed } from 'vue'
import { forEach, cloneDeep } from 'lodash'

import APIClient from '@/store/repository/APIClient'
import * as yup from 'yup'
import moment from "moment"
import { FormValidate } from '@/helper/FormValidate'

import {
  modalOpen,
  modalClose,
} from '@/helper/modal'

import ApiConst from '@/const/api'

import { isShowable as isShowUI } from '@/helper/indexStatus'

const unitCost = 4400

const api = new APIClient()

const formInit = {
  application_id: null,
  qty: 0,
}

const form = reactive(cloneDeep(formInit))
const currentData = reactive(cloneDeep(formInit))

const calcTotal = computed(() => {
  return Number(form.qty) * Number(unitCost)
})

const calcCurrentTotal = computed(() => {
  return Number(currentData.qty) * Number(unitCost)
})

const isNew = computed(() => {
  return !currentData.application_id
})

const uploadLabel = computed(() => {
  return !isNew.value ? '変更' : '新規登録'
})

const fetch = async () => {
  try {
    const query = {
      form_no: 2,
      application_div: "detector",
    }
    const fetchApi = await api.get(ApiConst.API_APP_LIST, query)
    const res = fetchApi.response
    const errors = res.data?.error_results
    if (errors) {
      throw new Error('Not found.')
    }
    forEach(currentData, (v, k) => {
      currentData[k] = formInit[k]
    })
    const data = res.data.results.list.map((el) => {
      return {
        application_id: el.application_id,
        input_date: moment(el.updated_at).format("YY-MM-DD"),
        seq_no: el.seq_no,
        ...el.json_data
      }
    })[0]
    forEach(currentData, (v, k) => {
      currentData[k] = data[k]
    })
    forEach(form, (v, k) => {
      form[k] = data[k] ?? formInit[k]
    })
  } catch (e) {
    console.log(e)
    return false
  }
}
fetch()

const newHandler = (event) => {
  forEach(formInit, (v, k) => {
    form[k] = v
  })
  modalOpen(event)
}

const editHandler = (event) => {
  modalOpen(event)
}

const deleteHandler = async () => {
  if (!window.confirm('クリアして宜しいでしょうか？')) return false

  try {
    const application_id = currentData.application_id
    const operation = "D"
    const query = { operation, application_id }
    const res = await api.post(ApiConst.API_APP_EDIT, query)
    if (res.response.data?.error_results) {
      throw new Error('API Validation Error')
    }
    fetch()
  } catch (e) {
    return false
  }
}

const scheme = {
  qty: yup.number().typeError('数値を入力してください。').required('必須の項目です。'),
}
const validate = new FormValidate(scheme, form)
const errors = validate.errors
const sendForm = async () => {
  try {
    if ((Object.keys(await validate.getErrorObject()).length)) {
      throw new Error('入力項目に不備があります。')
    }
    const params = cloneDeep(form)
    params.unit_cost = unitCost
    params.total_price = calcTotal.value
    const application_id = params.application_id
    delete params.application_id
    const operation = application_id ? "R" : "I"
    const query = operation === "I"
      ? { operation, application_div: "detector", form_no: 2, json_data: params }
      : { operation, application_id, json_data: params }
    const res = await api.post(ApiConst.API_APP_EDIT, query)
    if (res.response.data?.error_results) {
      throw new Error('API Validation Error')
    }
    fetch()
    modalClose()
  } catch (e) {
    return false
  }
}
</script>

<style></style>
