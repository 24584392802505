<template>
  <div v-if="errorList.length">
    <ul id="error_list">
      <li v-for="(error, i) in errorList" :key="i">
        {{ error }}
      </li>
    </ul>
  </div>
</template>

<script>


export default {

  data() {
    return {

    }
  },
  methods: {

  },
  computed: {
    errorList() {
      return this.$store.state.message.errors
    },
  }
};
</script>