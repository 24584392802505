<template>
  <table class="common_table">
    <tbody>
      <tr>
        <th>電気方式</th>
        <th>周波数</th>
        <th>申込容量(kW)</th>
        <th>区分名称</th>
        <th>合計金額(消費税込)</th>
      </tr>
      <tr>
        <td>交流単相2線式 100V</td>
        <td>50ヘルツ</td>
        <td>{{ currentData.L2_1_100.toLocaleString() }} kW</td>
        <td rowspan="3">電灯</td>
        <td>3kW以下の幹線に適用</td>
      </tr>
      <tr>
        <td>交流単相3線式 100V</td>
        <td>50ヘルツ</td>
        <td>{{ currentData.L3_1_100.toLocaleString() }} kW</td>
        <td rowspan="2">会場標準電気(電灯)幹線</td>
      </tr>
      <!-- 2019.06.25 新規追加（山田） / -->
      <tr>
        <td>交流単相3線式 200V</td>
        <td>50ヘルツ</td>
        <td>{{ currentData.L3_1_200.toLocaleString() }} kW</td>
      </tr>
      <tr>
        <!-- / 2019.06.25 新規追加（山田） -->
        <td>交流三相3線式 200V</td>
        <td>50ヘルツ</td>
        <td>{{ currentData.L3_3_200.toLocaleString() }} kW</td>
        <td>動力</td>
        <td>会場標準電気(動力)幹線</td>
      </tr>
      <tr>
        <td colspan="5">&nbsp;</td>
      </tr>
      <tr>
        <td colspan="2">合計申込容量
        </td>
        <td>{{ calcCurrentTotalkW.toLocaleString() }}kW </td>
        <td valign="top">幹線工事費<br>
          <br>
          <br>
          電気使用料
        </td>
        <td valign="top"> {{ calcBackboneWorkCost.toLocaleString() }}円<br>
          （{{ constructionCost.toLocaleString() }}円/kW）<br><br>
          {{ calcElectroUseCost.toLocaleString() }}円<br>
          （{{ electricityUsageFee.toLocaleString() }}円/kW）
        </td>
      </tr>
    </tbody>
  </table>
  <template v-if="isShowUI">
  <div><a v-if="isNew" @click.prevent="newHandler" href="#form1" class="common_add_button modalOpen">新規追加</a><a v-else
      @click.prevent="editHandler" href="#form1" class="common_add_button modalOpen">変更</a>&nbsp;&nbsp;<a
      @click.prevent="deleteHandler" href="#form1" class="common_add_button gray_button">クリア</a></div>
  </template>
  <Teleport to="body">
    <!-- モーダルコンテンツ / -->
    <div class="overlay" id="form1">
      <div id="modal_content" class="modal_contents w80">
        <div class="modalClose_ctn"><a @click.prevent="modalClose" class="modalClose"></a></div>
        <!-- modal contents ここから-->
        <div class="entry_title">
          <h4>電気使用申込</h4>
          <h5>電気使用申込&nbsp;&nbsp;{{ uploadLabel }}</h5>

          <div class="entry_modal_ctn">
            <table class="common_table">
              <tbody>
                <tr>
                  <th>電気方式</th>
                  <th>周波数</th>
                  <th>申込容量(kW)</th>
                  <th>区分名称</th>
                  <th>合計金額(消費税込)</th>
                </tr>
                <tr>
                  <td>交流単相2線式 100V</td>
                  <td>50ヘルツ</td>
                  <td><input v-model="form.L2_1_100" type="number" size="5" min="0" class="ta_right w5em">
                    kW
                    <p class="alert" v-if="errors.L2_1_100">{{ errors.L2_1_100 }}</p></td>
                  <td rowspan="3">電灯</td>
                  <td>3kW以下の幹線に適用</td>
                </tr>
                <tr>
                  <td>交流単相3線式 100V</td>
                  <td>50ヘルツ</td>
                  <td><input v-model="form.L3_1_100" type="number" size="5" min="0" class="ta_right w5em">
                    kW
                    <p class="alert" v-if="errors.L3_1_100">{{ errors.L3_1_100 }}</p></td>
                  <td rowspan="2">会場標準電気(電灯)幹線</td>
                </tr>
                <tr>
                  <td>交流単相3線式 200V</td>
                  <td>50ヘルツ</td>
                  <td><input v-model="form.L3_1_200" type="number" size="5" min="0" class="ta_right w5em">
                    kW
                    <p class="alert" v-if="errors.L3_1_200">{{ errors.L3_1_200 }}</p></td>
                </tr>
                <tr>
                  <td>交流三相3線式 200V</td>
                  <td>50ヘルツ</td>
                  <td><input v-model="form.L3_3_200" type="number" size="5" min="0" class="ta_right w5em">
                    kW
                    <p class="alert" v-if="errors.L3_3_200">{{ errors.L3_3_200 }}</p></td>
                  <td>動力</td>
                  <td>会場標準電気(動力)幹線</td>
                </tr>
                <tr>
                  <td colspan="5">&nbsp;</td>
                </tr>
                <tr>
                  <td colspan="2">合計申込容量
                  </td>
                  <td>{{ calcTotalkW.toLocaleString() }} kW </td>
                  <td valign="top">幹線工事費<br>
                    <br>
                    <br>
                    電気使用料
                  </td>
                  <!-- ここは純粋に合計W数×各々の単価 -->
                  <td valign="top">{{ calcBackboneWorkCost.toLocaleString() }}円<br>
                    （{{ constructionCost.toLocaleString() }}円/kW）<br>
                    <br>
                    {{ calcElectroUseCost.toLocaleString() }}円<br>
                    （{{ electricityUsageFee.toLocaleString() }}円/kW）
                  </td>
                </tr>
              </tbody>
            </table>


            <div class="button_ctn">
              <ul>
                <li><button @click="modalClose" class="gray_button modalClose">キャンセル</button></li>
                <li><button @click="sendForm" class="regist_button">上記内容で登録</button></li>
              </ul>
            </div>
            <!-- modal contents ここまで-->
          </div>
        </div>
        <!-- / モーダルコンテンツ -->
      </div>
    </div>
    <!-- / モーダルコンテンツ -->
  </Teleport>
</template>

<script setup>
import { reactive, computed } from 'vue'
import { sum, forEach, cloneDeep } from 'lodash'

import APIClient from '@/store/repository/APIClient'
import * as yup from 'yup'
import moment from "moment"
import { FormValidate } from '@/helper/FormValidate'
import ElectroRegist from '@/const/electro_regist'

import {
  modalOpen,
  modalClose,
} from '@/helper/modal'

import ApiConst from '@/const/api'

import { isShowable as isShowUI } from '@/helper/indexStatus'

const api = new APIClient()

const formInit = {
  application_id: null,
  L2_1_100: 0,
  L3_1_100: 0,
  L3_1_200: 0,
  L3_3_200: 0,
}

const form = reactive(cloneDeep(formInit))
const currentData = reactive(cloneDeep(formInit))

const calcCurrentTotalkW = computed(() => {
  return sum([
    currentData.L2_1_100,
    currentData.L3_1_100,
    currentData.L3_1_200,
    currentData.L3_3_200,
  ])
})

const calcTotalkW = computed(() => {
  return sum([
    form.L2_1_100,
    form.L3_1_100,
    form.L3_1_200,
    form.L3_3_200,
  ])
})

const constructionCost = ElectroRegist.CONSTRUCTION_COST
const electricityUsageFee = ElectroRegist.ELECTRICITY_USAGE_FEE

const calcBackboneWorkCost = computed(() => {
  return constructionCost * calcTotalkW.value
})

const calcElectroUseCost = computed(() => {
  return electricityUsageFee * calcTotalkW.value
})

const isNew = computed(() => {
  return !currentData.application_id
})

const uploadLabel = computed(() => {
  return !isNew.value ? '変更' : '新規登録'
})

const fetch = async () => {
  try {
    const query = {
      form_no: 1,
      application_div: "electro_regist",
    }
    const fetchApi = await api.get(ApiConst.API_APP_LIST, query)
    const res = fetchApi.response
    const errors = res.data?.error_results
    if (errors) {
      throw new Error('Not found.')
    }
    forEach(currentData, (v, k) => {
      currentData[k] = formInit[k]
    })
    const data = res.data.results.list.map((el) => {
      return {
        application_id: el.application_id,
        input_date: moment(el.updated_at).format("YY-MM-DD"),
        seq_no: el.seq_no,
        ...el.json_data
      }
    })[0] ?? false
    if (!data) return

    forEach(currentData, (v, k) => {
      currentData[k] = data[k]
    })
    forEach(form, (v, k) => {
      form[k] = data[k] ?? formInit[k]
    })
  } catch (e) {
    console.log(e)
    return false
  }
}
fetch()

const newHandler = (event) => {
  forEach(formInit, (v, k) => {
    form[k] = v
  })
  modalOpen(event)
}

const editHandler = (event) => {
  modalOpen(event)
}

const deleteHandler = async () => {
  if (!window.confirm('クリアして宜しいでしょうか？')) return false

  try {
    const application_id = currentData.application_id
    const operation = "D"
    const query = { operation, application_id }
    const res = await api.post(ApiConst.API_APP_EDIT, query)
    if (res.response.data?.error_results) {
      throw new Error('API Validation Error')
    }
    fetch()
  } catch (e) {
    return false
  }
}

const scheme = {
  L2_1_100: yup.number().typeError('数値を入力してください。'),
  L3_1_100: yup.number().typeError('数値を入力してください。'),
  L3_1_200: yup.number().typeError('数値を入力してください。'),
  L3_3_200: yup.number().typeError('数値を入力してください。'),
}
const validate = new FormValidate(scheme, form)
const errors = validate.errors
const sendForm = async () => {
  try {
    if ((Object.keys(await validate.getErrorObject()).length)) {
      throw new Error('入力項目に不備があります。')
    }
    const params = cloneDeep(form)
    params.total_kw = calcTotalkW.value
    params.backbone_work_cost = calcBackboneWorkCost.value
    params.electro_use_cost = calcElectroUseCost.value
    const application_id = params.application_id
    delete params.application_id
    const operation = application_id ? "R" : "I"
    const query = operation === "I"
      ? { operation, application_div: "electro_regist", form_no: 1, json_data: params }
      : { operation, application_id, json_data: params }
    const res = await api.post(ApiConst.API_APP_EDIT, query)
    if (res.response.data?.error_results) {
      throw new Error('API Validation Error')
    }
    fetch()
    modalClose()
  } catch (e) {
    return false
  }
}
</script>

<style></style>
