<template>
  <header><h1>JAPAN MOBILITY SHOW 2025&emsp;出展申込サイト</h1></header>
  <article><h2>一次申込&emsp;入力フォーム</h2>
  <div id="form_ctn" class="form_box">

    <ErrorComponent></ErrorComponent>

    <h3>会社名</h3>

    <h4 class="required">和文名称</h4>
    <my-input name="exhibitor_name" width_class="full" type="text" v-model="exhibitor_name"
      placeholder="例：一般社団法人日本自動車工業会">
    </my-input>
    <p>法人表記は「株式会社」「○○法人」とし、「(株)」などの略称は使用しないで下さい。</p>
    <p class="alert" v-if="errors.exhibitor_name">{{ errors.exhibitor_name }}</p>

    <h4 class="required">フリガナ</h4>
    <my-input name="exhibitor_name_kana" width_class="full" type="text" v-model="exhibitor_name_kana"
      placeholder="例：ニホンジドウシャコウギョウカイ"></my-input>
    <p class="alert" v-if="errors.exhibitor_name_kana">{{ errors.exhibitor_name_kana }}</p>

    <h4 class="required">英文名称</h4>
    <my-input name="exhibitor_name_en" width_class="full" type="text" v-model="exhibitor_name_en"
      placeholder="例：Japan Automobile Manufacturers Association, Inc"></my-input>
    <p class="alert" v-if="errors.exhibitor_name_en">{{ errors.exhibitor_name_en }}</p>

    <h3>担当者</h3>
    <h4 class="required">氏名</h4>
    <my-input name="staff_last_name" width_class="quarter mr10" type="text" v-model="staff_last_name"
      placeholder="例：山田">
    </my-input>
    <my-input name="staff_first_name" width_class="quarter" type="text" v-model="staff_first_name" placeholder="例：太郎">
    </my-input>
    <p class="alert" v-if="errors.staff_last_name">{{ errors.staff_last_name }}</p>
    <p class="alert" v-if="errors.staff_first_name">{{ errors.staff_first_name }}</p>

    <h4 class="required">電話番号</h4>
    <my-input name="staff_tel" width_class="half" type="text" v-model="staff_tel" placeholder="例：03-1234-1234">
    </my-input>
    <p class="alert" v-if="errors.staff_tel">{{ errors.staff_tel }}</p>

    <h4 class="required">担当者メールアドレス</h4>
    <my-input name="staff_email" width_class="full" type="text" v-model="staff_email"
      placeholder="例：example@example.co.jp"></my-input>
    <p class="alert" v-if="errors.staff_email">{{ errors.staff_email }}</p>

    <h3 class="required">会員区分</h3>
    <my-select v-model="membership_category" :options="membership_category_options"></my-select>
    <p class="alert" v-if="errors.membership_category">{{ errors.membership_category }}</p>

    <h3 class="required">過去の出展実績</h3>
    <ul class="common_list_horizontal">
      <my-radio v-model="past_achievement" name="past_achievement" :options="past_achievement_options">
      </my-radio>
    </ul>
    <p class="alert" v-if="errors.past_achievement">{{ errors.past_achievement }}</p>

    <h3 class="required">出展カテゴリー</h3>
    <p>※JAPAN MOBILITY SHOW <a href="/docs/2025/regulation/regulation.pdf#page=10" target="_blank">規程2-6「出展物の条件」</a>を参照してください。</p>

    <ul id="common_list_vertical">
      <component v-model="category_div" name="category_div" :options="category_div_options" :is="activeCategoryDiv" />
      <p class="alert" v-if="errors.category_div">{{ errors.category_div }}</p>
    </ul>

    <h3 class="required">企業WEBサイト</h3>
    <my-input name="site_url" width_class="full" type="text" v-model="site_url" placeholder="例：https://example.co.jp">
    </my-input>
    <p class="alert" v-if="errors.site_url">{{ errors.site_url }}</p>

    <div v-if="appeal_information_visible" class="vidivle_div">
      <h3 class="required">主な製品/サービス情報</h3>
      <my-textarea id="appeal_information" class="textarea_full" name="appeal_information" v-model="appeal_information">
      </my-textarea>
      <p class="alert" v-if="errors.appeal_information">{{ errors.appeal_information }}</p>
    </div>
    <div class="button_ctn">
      <button class="submit" @click="sendForm">確認画面へ</button>
    </div>

  </div>
</article>
</template>

<script>
import APIClient from '@/store/repository/APIClient'
import MyInput from '@/components//MyInput.vue';
import MySelect from '@/components//MySelect.vue';
import MyRadio from '@/components//MyRadio.vue';
import MyCheckbox from '@/components//MyCheckbox.vue';
import MyTextarea from '@/components//MyTextarea.vue';
import CategoryDiv from '@/components//CategoryDiv.vue';
import ErrorComponent from '@/components/ErrorComponent.vue';
import { useField, useForm } from 'vee-validate';
import * as yup from 'yup';
import { useRouter } from 'vue-router'
import CATEGORY_DIV from '@/const/category_div.js';
import YupConst from '@/const/yup';

const phoneRegExp = new RegExp(YupConst.PHONE_PATTERN)
const katakanaRegExp = new RegExp(YupConst.KATAKANA_PATTERN)

export default {
  components: {
    MyInput,
    MySelect,
    MyRadio,
    MyCheckbox,
    MyTextarea,
    CategoryDiv,
    ErrorComponent,
  },
  setup() {
    const router = useRouter();

    const schema = yup.object({
      exhibitor_name: yup.string().required('必須の項目です。'),
      exhibitor_name_kana: yup.string().required('必須の項目です。').matches(katakanaRegExp, YupConst.KATAKANA_PATTERN_MSG),
      exhibitor_name_en: yup.string().required('必須の項目です。'),
      staff_last_name: yup.string().required('"姓”が入力されていません。必須の項目です。'),
      staff_first_name: yup.string().required('"名”が入力されていません。必須の項目です。'),
      staff_tel: yup.string().required('必須の項目です。').matches(phoneRegExp, YupConst.PHONE_PATTERN_MSG),
      staff_email: yup.string().email('emailの形式が不正です。').required('必須の項目です。'),
      membership_category: yup.string().required('必須の項目です。会員区分を選択してください。'),
      past_achievement: yup.string().required('必須の項目です。'),
      category_div: yup.object().required('必須の項目です。出展カテゴリーを選択してください。').test(
        "category_div_frame_lv2_required",
        "車体はオプションも選択必須です。",
        function (value) {
          if (value?.category_div.includes('20')) {
            if (!value?.category_div_20) {
              return false
            }
          }
          return true
        }
      ),
      site_url: yup.string().url('URLの形式が不正です。').required('必須の項目です。'),
      appeal_information: yup.string().test(
        "appeal_information_required",
        "必須の項目です。",
        function (value) {
          const { category_div } = this.parent;
          if (!category_div?.category_div.includes('10')) {
            return (value)
          }
          return true
        }
      )
    });

    const { errors, handleSubmit } = useForm({
      validationSchema: schema,
    });

    const onInvalidSubmit = () => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    }

    const sendForm = handleSubmit(() => {
      router.push({ path: 'confirm' });
    }, onInvalidSubmit);

    const { value: exhibitor_name } = useField('exhibitor_name', null, {
      validateOnValueUpdate: false,
    });
    const { value: exhibitor_name_kana } = useField('exhibitor_name_kana', null, {
      validateOnValueUpdate: false,
    });
    const { value: exhibitor_name_en } = useField('exhibitor_name_en', null, {
      validateOnValueUpdate: false,
    });
    const { value: staff_last_name } = useField('staff_last_name', null, {
      validateOnValueUpdate: false,
    });
    const { value: staff_first_name } = useField('staff_first_name', null, {
      validateOnValueUpdate: false,
    });
    const { value: staff_tel } = useField('staff_tel', null, {
      validateOnValueUpdate: false,
    });
    const { value: past_achievement } = useField('past_achievement', null, {
      validateOnValueUpdate: false,
    });
    const { value: membership_category } = useField('membership_category', null, {
      validateOnValueUpdate: false,
    });
    const { value: category_div } = useField('category_div', null, {
      validateOnValueUpdate: false,
    });
    const { value: staff_email } = useField('staff_email', null, {
      validateOnValueUpdate: false,
    });
    const { value: site_url } = useField('site_url', null, {
      validateOnValueUpdate: false,
    });
    const { value: appeal_information } = useField('appeal_information', null, {
      validateOnValueUpdate: false,
    });
    return {
      errors,
      exhibitor_name,
      exhibitor_name_kana,
      exhibitor_name_en,
      staff_last_name,
      staff_first_name,
      staff_tel,
      staff_email,
      membership_category,
      past_achievement,
      category_div,
      site_url,
      appeal_information,
      sendForm,
    };
  },
  async created() {
    this.api = new APIClient()
    const res = await this.api.getConfig()

    this.$store.commit('disicion/membership_category_options', res.data.results.membership_category_options)
    this.$store.commit('disicion/category_div_options', res.data.results.category_div_options)
    this.$store.commit('disicion/past_achievement_options', res.data.results.past_achievement_options)
    this.activeCategoryDiv = CategoryDiv
  },
  mounted() {
    this.inputStoreData('exhibitor_name')
    this.inputStoreData('exhibitor_name_kana')
    this.inputStoreData('exhibitor_name_en')
    this.inputStoreData('staff_last_name')
    this.inputStoreData('staff_first_name')
    this.inputStoreData('staff_tel')
    this.inputStoreData('staff_email')
    this.inputStoreData('membership_category')
    this.inputStoreData('past_achievement')
    this.inputStoreData('category_div')
    this.inputStoreData('site_url')
    this.inputStoreData('appeal_information')
  },
  methods: {
    //前回入力内容をstoreから初期値を拾う。
    inputStoreData(key) {
      if (!this.$store.state.form[key]) return;
      this[key] = this.$store.state.form[key]
    },
  },
  watch: {
    //dataでも更新されてるがそれをstoreに反映するためのコード
    exhibitor_name: function (v) {
      this.$store.commit('form/exhibitor_name', v)
    },
    exhibitor_name_kana: function (v) {
      this.$store.commit('form/exhibitor_name_kana', v)
    },
    exhibitor_name_en: function (v) {
      this.$store.commit('form/exhibitor_name_en', v)
    },
    staff_last_name: function (v) {
      this.$store.commit('form/staff_last_name', v)
    },
    staff_first_name: function (v) {
      this.$store.commit('form/staff_first_name', v)
    },
    staff_tel: function (v) {
      this.$store.commit('form/staff_tel', v)
    },
    staff_email: function (v) {
      this.$store.commit('form/staff_email', v)
    },
    membership_category: function (v) {
      this.$store.commit('form/membership_category', v)
    },
    past_achievement: function (v) {
      this.$store.commit('form/past_achievement', v)
    },
    category_div: function (v) {
      this.$store.commit('form/category_div', v)
    },
    site_url: function (v) {
      this.$store.commit('form/site_url', v)
    },
    appeal_information: function (v) {
      this.$store.commit('form/appeal_information', v)
    },
  },
  computed: {

    membership_category_options() {
      return this.$store.state.disicion.membership_category_options
    },
    category_div_options() {
      return this.$store.state.disicion.category_div_options.filter(el => {
        return (
          el.value !== CATEGORY_DIV.COLLABORATION_COOPERATION.value
         
        )
      })
    },
    past_achievement_options() {
      return this.$store.state.disicion.past_achievement_options
    },
    appeal_information_visible() {
      if (!this.category_div?.category_div?.includes('10')) return true
      return false
    }
  }
};
</script>

<style>
</style>
