<template>
  <div id="admin">
    <header>
      <h1>JMS 2025 出展者登録DB 管理者用ページ</h1>
    </header>
    <article>
      <h2>一次申込管理</h2>
      <div id="form_ctn" class="form_box">

        <h3>申込者個別情報</h3>


        <table class="common_table exhibitor_table">
          <tbody>
            <tr>
              <th colspan="2">申込番号
              </th>
              <td>{{exhibit_participation_id}}</td>
            </tr>
            <tr>
              <th colspan="2">審査ステータス</th>
              <td>{{exhibit_participation_status_label}}</td>
            </tr>
            <tr>
              <th rowspan="3">会社名</th>
              <th>和文名称</th>
              <td>{{exhibitor_name}}</td>
            </tr>
            <tr>
              <th>フリガナ</th>
              <td>{{exhibitor_name_kana}}</td>
            </tr>
            <tr>
              <th>英文名称</th>
              <td>{{exhibitor_name_en}}</td>
            </tr>
            <tr>
              <th rowspan="3">担当者</th>
              <th>氏名</th>
              <td>{{ staff_last_name }} {{staff_first_name}}</td>
            </tr>
            <tr>
              <th>電話番号</th>
              <td>{{staff_tel}}</td>
            </tr>
            <tr>
              <th>担当者メールアドレス</th>
              <td>{{staff_email}}</td>
            </tr>
            <tr>
              <th colspan="2">会員区分</th>
              <td>{{membership_category_label}}</td>
            </tr>
            <tr>
              <th colspan="2">過去の出展実績</th>
              <td>{{past_achievement_label}}</td>
            </tr>
            <tr>
              <th colspan="2">出展カテゴリー</th>
              <td>{{category_div_label}}</td>
            </tr>
            <tr>
              <th colspan="2">企業WEBサイト</th>
              <td><a :href="site_url" target="_blank">{{site_url}}</a></td>
            </tr>
            <tr>
              <th colspan="2">主な製品/サービス情報</th>
              <td>{{appeal_information}}</td>
            </tr>
          </tbody>
        </table>


        <div v-if="now_exhibit_participation_status == 0">
        <h4>審査ステータスの変更</h4>
        <table class="common_table exhibitor_table">
          <tbody>
            <tr>
              <th class="w350">審査ステータスの変更</th>
              <td>
                <div class="select_ctn">
                  <my-select v-model="exhibit_participation_status" :options="exhibit_participation_status_options">
                  </my-select>
                  <p class="alert" v-if="errors.exhibit_participation_status">{{ errors.exhibit_participation_status }}</p>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        </div>
        


        <div class="button_ctn">
          <ul>
            <li><router-link :to="{name:'AdminPreList'}" class="gray_button">検索結果に戻る</router-link>
            </li>

            <li v-if="now_exhibit_participation_status == 0"><button class="submit" @click="sendForm">確認画面へ</button></li>
          </ul>
        </div>


      </div>

      <div class="button_ctn">
          <ul>
            <li><router-link :to="{name:'AdminIndex'}">メニュー画面に戻る</router-link>
            </li>
          </ul>
        </div>
    </article>
  </div>
</template>

<script>
import APIClient from '@/store/repository/APIClient'
import MySelect from '@/components//MySelect.vue';
import { useField, useForm } from 'vee-validate';
import * as yup from 'yup';
import { useRouter } from 'vue-router'

export default {
  components: {
    MySelect,
  },
  setup() {
    const router = useRouter();

    const schema = yup.object({
      exhibit_participation_status: yup.string().required('必須の項目です。'),
      
    });

    const { errors, handleSubmit } = useForm({
      validationSchema: schema,
    });

    const sendForm = handleSubmit(() => {
      router.push({ name: 'AdminPreDetailConfirm' });
    });

    const { value: exhibit_participation_status } = useField('exhibit_participation_status', null, {
      validateOnValueUpdate: false,
    });

    return {
      errors,
      exhibit_participation_status,
      sendForm,
    };
  },

  data() {
    return {
      exhibit_participation_id: '',

      exhibitor_name: '',
      exhibitor_name_kana: '',
      exhibitor_name_en: '',
      membership_category: '',

      site_url: '',
      appeal_information: '',
      
    }
  },
  async created() {
    this.api = new APIClient()
    const res = await this.api.getConfig()
    this.$store.commit('disicion/exhibit_participation_status_options', res.data.results.exhibit_participation_status_options)
    this.$store.commit('disicion/membership_category_options_admin', res.data.results.membership_category_options_admin)
    this.$store.commit('disicion/past_achievement_options', res.data.results.past_achievement_options)
    this.$store.commit('disicion/category_div_options', res.data.results.category_div_options)
    const res_detail = await this.api.postAdminPreDetail({
      exhibit_participation_id: this.$route.params.id
    })
    this.exhibit_participation_id = res_detail.data.results.exhibit_participation_id
    this.exhibit_participation_status_label = res_detail.data.results.exhibit_participation_status_label
    this.exhibit_participation_status = res_detail.data.results.exhibit_participation_status
    this.now_exhibit_participation_status = res_detail.data.results.exhibit_participation_status
    this.exhibitor_name = res_detail.data.results.exhibitor_name
    this.exhibitor_name_kana = res_detail.data.results.exhibitor_name_kana
    this.exhibitor_name_en = res_detail.data.results.exhibitor_name_en
    this.membership_category_label = res_detail.data.results.membership_category_label
    this.category_div_label = res_detail.data.results.category_div_label
    this.past_achievement_label = res_detail.data.results.past_achievement_label
    this.site_url = res_detail.data.results.site_url
    this.appeal_information = res_detail.data.results.appeal_information
    this.staff_first_name = res_detail.data.results.staff_first_name
    this.staff_last_name = res_detail.data.results.staff_last_name
    this.staff_tel = res_detail.data.results.staff_tel
    this.staff_email = res_detail.data.results.staff_email
  },
  mounted() {

  },
  methods: {
    
  },
  watch: {
    exhibit_participation_status: function (v) {
      this.$store.commit('admin/exhibit_participation_status', v)
    },

  },
  computed: {

    exhibit_participation_status_options() {
      return this.$store.state.disicion.exhibit_participation_status_options.filter((item) => item.value !== "0")
    },

  }
};
</script>

<style>

</style>
