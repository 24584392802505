<template>
  <table class="common_table">
    <tbody>
      <tr>
        <th rowspan="2">商品名</th>
        <th colspan="2">販売予定価格</th>
        <th rowspan="2">税率</th>
        <th rowspan="2">制作予定数</th>
        <th rowspan="2">売上数量</th>
        <th rowspan="2">合計金額</th>
        <th rowspan="2">ロゴ使用料<br>10%</th>
        <th rowspan="2">支払額<br>
          （税込）</th>
      </tr>
      <tr>
        <th>（税別）</th>
        <th>（税込）</th>
      </tr>
      <tr
        v-for="(v,k) in currentData.sales"
        :key="k"
        :set="val = setFormat(mergeList(v))"
      >
        <td align="left">{{ val.product_name }}</td>
        <td align="right" class="ta_right">{{ val.priceUnit }}</td>
        <td align="right" class="ta_right">{{ val.with_tax_priceUnit }}</td>
        <td align="center" class="ta_right">{{ val.tax_rateUnit }}</td>
        <td align="right" class="ta_right">{{ val.quantityUnit }}</td>
        <td align="center" class="ta_right">{{ val.sales_quantityUnit ?? '未入力' }}</td>
        <td align="center" class="ta_right">{{ val.calcRowPrice ?? '未入力' }}</td>
        <td align="center" class="ta_right">{{ val.calcLogoFee ?? '未入力' }}</td>
        <td align="center" class="ta_right">{{ val.calcAmountPaid ?? '未入力' }}</td>
      </tr>
      <tr>
        <td align="left" colspan="5">合計</td>
        <td align="center" class="ta_right">{{ calcCurrentTotalQty ?? '未入力' }}</td>
        <td align="center" class="ta_right">{{ calcCurrentRowPrice ?? '未入力' }}</td>
        <td align="center" class="ta_right">{{ calcCurrentLogoFee ?? '未入力' }}</td>
        <td align="center" class="ta_right">{{ calcCurrentAmountPaid ?? '未入力' }}</td>
      </tr>
    </tbody>
  </table>
  <template v-if="isShowUI">
  <div><a @click.prevent="editHandler" href="#form4" class="common_add_button modalOpen">{{ uploadLabel }}</a>&nbsp;&nbsp;<a v-if="!isNew"
      @click.prevent="deleteHandler" href="#form4" class="common_add_button gray_button">クリア</a></div>
  </template>
  <Teleport to="body">
    <!-- モーダルコンテンツ / -->
    <div class="overlay" id="form4">
      <div id="modal_content" class="modal_contents w80">
        <div class="modalClose_ctn"><a @click.prevent="modalClose" class="modalClose"></a></div>
        <!-- modal contents ここから-->
        <div class="entry_title">
          <h4>出展者オリジナルグッズ販売届</h4>
          <h5>ショーロゴ入りグッズ売上報告&nbsp;&nbsp;{{ uploadLabel }}</h5>

          <div class="entry_modal_ctn">
            <table class="common_table">
              <tbody>
                <tr>
                  <th rowspan="2">商品名</th>
                  <th colspan="2">販売予定価格</th>
                  <th rowspan="2">税率</th>
                  <th rowspan="2">制作予定数</th>
                  <th rowspan="2">売上数量</th>
                  <th rowspan="2">合計金額<br>（税別）</th>
                  <th rowspan="2">ロゴ使用料<br>10%</th>
                  <th rowspan="2">支払額<br>
                    （税込）</th>
                </tr>
                <tr>
                  <th>（税別）</th>
                  <th>（税込）</th>
                </tr>
                <tr
                  v-for="(v,k) in form.sales"
                  :key="k"
                  :set="val = setFormat(mergeList(v))"
                >
                  <td align="left">{{ val.product_name }}</td>
                  <td align="right" class="ta_right">{{ val.priceUnit }}</td>
                  <td align="right" class="ta_right">{{ val.with_tax_priceUnit }}</td>
                  <td align="center" class="ta_right">{{ val.tax_rateUnit }}</td>
                  <td align="right" class="ta_right">{{ val.quantityUnit }}</td>
                  <td align="center"><input v-model="form.sales.find(el => el.application_id === v.application_id).sales_quantity" type="number" size="5" min="0" class="w8em"></td>
                  <td align="center" class="ta_right">{{ val.calcRowPrice ?? '未入力' }}</td>
                  <td align="center" class="ta_right">{{ val.calcLogoFee ?? '未入力' }}</td>
                  <td align="center" class="ta_right">{{ val.calcAmountPaid ?? '未入力' }}</td>
                </tr>
                <tr>
                  <th align="left" colspan="6">合計</th>
                  <td align="center" class="ta_right">{{ calcFormRowPrice ?? '未入力'}}</td>
                  <td align="center" class="ta_right">{{ calcFormLogoFee ?? '未入力'}}</td>
                  <td align="center" class="ta_right">{{ calcFormAmountPaid ?? '未入力'}}</td>
                </tr>



              </tbody>
            </table>


            <div class="button_ctn">
              <ul>
                <li><button @click="modalClose" class="gray_button modalClose">キャンセル</button></li>
                <li><button @click="sendForm" class="regist_button">上記内容で登録</button></li>
              </ul>
            </div>
            <!-- modal contents ここまで-->
          </div>
        </div>
        <!-- / モーダルコンテンツ -->
      </div>
    </div>
    <!-- / モーダルコンテンツ -->
  </Teleport>
</template>

<script setup>
import Big from 'big.js'
import { reactive, computed, watch } from 'vue'
import { sum, forEach, cloneDeep } from 'lodash'

import APIClient from '@/store/repository/APIClient'

import moment from "moment"

import {
  modalOpen,
  modalClose,
} from '@/helper/modal'

import ApiConst from '@/const/api'

import Tax from '@/const/tax'

const taxRateOptions = Tax.RATE

import {
  isValue
} from '@/helper/index';

import { isShowable as isShowUI } from '@/helper/indexStatus'

const api = new APIClient()

const salesInit = {
  "application_id": null,
  "sales_quantity": null
}
const formInit = {
  application_id: null,
  sales: [],
}

const form = reactive(cloneDeep(formInit))
const currentData = reactive(cloneDeep(formInit))

const productList = reactive([])

const mergeList = (v) => {
  return {
    ...v,
    ...productList.find(el => el.application_id === v.application_id),
  }
}

const setFormat = (v) => {
  try {
    v.sales_quantityUnit = v.sales_quantity?.toLocaleString() ?? null
    v.priceUnit = v.price?.toLocaleString() + '円' ?? null
    v.with_tax_priceUnit = v.with_tax_price?.toLocaleString() + '円' ?? null
    v.tax_rateUnit = taxRateOptions.find(el => el.value === v.tax_rate)?.label
    v.quantityUnit = v.quantity?.toLocaleString()
    v.calcRowPrice = calcRowPrice(v) ? calcRowPrice(v)?.toLocaleString() + '円' : null
    v.calcLogoFee = calcLogoFee(v) ? calcLogoFee(v)?.toLocaleString() + '円' : null
    v.calcAmountPaid = calcAmountPaid(v) ? calcAmountPaid(v)?.toLocaleString() + '円' : null
    return v
  } catch (e) {
    return v
  }
}

// ※制作注：
// ・合計金額=税別販売価格×売上数量
// ・ロゴ使用料=税込販売価格×0.1
// ・支払額=ロゴ使用料×1.1
const calcRowPrice = (el) => el?.sales_quantity ? Number(Big(el.price).mul(el.sales_quantity).toString()) : null
const calcLogoFee = (el) => el?.sales_quantity ? Number(Big(el.price).mul(el.sales_quantity).mul(0.1).round(0, Big.roundDown)) : null
const calcAmountPaid = (el) => el?.sales_quantity ? Number(Big(el.price).mul(el.sales_quantity).mul(0.1).mul(1.1).round(0, Big.roundDown)) : null

const calcCurrentTotalQty = computed(() => sum(currentData.sales.map(el => el.sales_quantity)))
const calcCurrentRowPrice = computed(() => {
  const number = currentData.sales.reduce((acc, val) => {
    try {
      const el = setFormat(mergeList(val))
      return acc + Number(Big(el.price).mul(el.sales_quantity))
    } catch (e) {
      return acc
    }
  }, 0)
  return number ? number?.toLocaleString() + '円' : null
})

const calcCurrentLogoFee = computed(() => {
  const number = currentData.sales.reduce((acc, val) => {
    try {
      const el = setFormat(mergeList(val))
      return acc + Number(Big(el.price).mul(el.sales_quantity).mul(0.1))
    } catch (e) {
      return acc
    }
  }, 0)
  const bigNumber = Big(number).round(0, Big.roundDown)
  return bigNumber ? Number(bigNumber).toLocaleString() + '円' : null
})
const calcCurrentAmountPaid = computed(() => {
  const number = currentData.sales.reduce((acc, val) => {
    try {
      const el = setFormat(mergeList(val))
      return acc + Number(Big(el.price).mul(el.sales_quantity).mul(0.1).mul(1.1))
    } catch (e) {
      return acc
    }
  }, 0)
  const bigNumber = Big(number).round(0, Big.roundDown)
  return bigNumber ? Number(bigNumber).toLocaleString() + '円' : null
})

const calcFormRowPrice = computed(() => {
  const number = form.sales.reduce((acc, val) => {
    try {
      const el = setFormat(mergeList(val))
      return acc + Number(Big(el.price).mul(el.sales_quantity))
    } catch (e) {
      return acc
    }
  }, 0)
  return number ? number?.toLocaleString() + '円' : null
})

const calcFormLogoFee = computed(() => {
  const number = form.sales.reduce((acc, val) => {
    try {
      const el = setFormat(mergeList(val))
      return acc + Number(Big(el.price).mul(el.sales_quantity).mul(0.1))
    } catch (e) {
      return acc
    }
  }, 0)
  const bigNumber = Big(number).round(0, Big.roundDown)
  return bigNumber ? bigNumber?.toLocaleString() + '円' : null
})
const calcFormAmountPaid = computed(() => {
  const number = form.sales.reduce((acc, val) => {
    try {
      const el = setFormat(mergeList(val))
      return acc + Number(Big(el.price).mul(el.sales_quantity).mul(0.1).mul(1.1))
    } catch (e) {
      return acc
    }
  }, 0)
  const bigNumber = Big(number).round(0, Big.roundDown)
  return bigNumber ? bigNumber?.toLocaleString() + '円' : null
})

const isNew = computed(() => {
  return !form.application_id
})

const uploadLabel = computed(() => {
  return !isNew.value ? '変更' : '新規登録'
})

const fetchItems = async () => {
  try {
    const resList = []
    const queueList = []
    const queries = [
      {
        form_no: 3,
        application_div: "showlogo",
      }
    ]
    queries.forEach(query => {
      queueList.push(api.get(ApiConst.API_APP_LIST, query).then((fetchApi) => {
          const res = fetchApi.response
          const errors = res.data?.error_results
          if (errors) {
            throw new Error('Not found.')
          }
          resList.push(res)
        }
      ))
    })
    Promise.all(queueList).then(() => {
      productList.splice(0)
      resList.forEach(res => {
        const data = res?.data?.results?.list.map((el) => {
          return {
            application_id: el.application_id,
            input_date: moment(el.updated_at).format("YY-MM-DD"),
            seq_no: el.seq_no,
            ...el.json_data
          }
        }) ?? false
        if (!data) return

        data.forEach((val) => {
          productList.push(val)
        })
      })
    })
  } catch (e) {
    console.log(e)
    return false
  }
}
fetchItems()

const fetch = async () => {
  try {
    const query = {
      form_no: 5,
      application_div: "showlogo",
    }
    const fetchApi = await api.get(ApiConst.API_APP_LIST, query)
    const res = fetchApi.response
    const errors = res.data?.error_results
    if (errors) {
      throw new Error('Not found.')
    }
    const data = res?.data?.results?.list.map((el) => {
      el.json_data.sales.forEach((val) => {
        forEach(val, ((v, k) => {
          if (!isValue(v)) v = salesInit[k]
        }))
      })
      return {
        application_id: el.application_id,
        input_date: moment(el.updated_at).format("YY-MM-DD"),
        seq_no: el.seq_no,
        ...el.json_data,
      }
    })[0] ?? {
      application_id: null,
      sales: [],
    }

    const _currentData = cloneDeep(data)
    const _form = cloneDeep(data)
    currentData.application_id = form.application_id = data.application_id
    forEach(productList, (v, k) => {
      const emptyObj = {
        application_id: v.application_id,
        sales_quantity: null,
      }
      currentData.sales[k] = _currentData.sales.find(
        el => el.application_id === v.application_id) ?? emptyObj
      form.sales[k] = _form.sales.find(
        el => el.application_id === v.application_id) ?? emptyObj
    })
  } catch (e) {
    console.log(e)
    return false
  }
}

watch(productList, () => {
  if (!productList.length) return
  fetch()
})

const editHandler = (event) => {
  modalOpen(event)
}

const deleteHandler = async () => {
  if (!window.confirm('クリアして宜しいでしょうか？')) return false

  try {
    const application_id = form.application_id
    const operation = "D"
    const query = { operation, application_id }
    const res = await api.post(ApiConst.API_APP_EDIT, query)
    if (res.response.data?.error_results) {
      throw new Error('API Validation Error')
    }
    fetch()
  } catch (e) {
    return false
  }
}

const sendForm = async () => {
  try {
    const params = {}
    params.sales = form.sales.map(el => {
      const p = {...el, ...productList.find(l => l.application_id === el.application_id)}
      p.row_price = calcRowPrice(p)
      p.logo_fee = calcLogoFee(p)
      p.amount_paid = calcAmountPaid(p)
      return p
    })
    const application_id = form.application_id

    const operation = application_id ? "R" : "I"
    const query = operation === "I"
      ? { operation, application_div: "showlogo", form_no: 5, json_data: params }
      : { operation, application_id, json_data: params }
    const res = await api.post(ApiConst.API_APP_EDIT, query)
    if (res.response.data?.error_results) {
      throw new Error('API Validation Error')
    }
    fetch()
    modalClose()
  } catch (e) {
    return false
  }
}
</script>

<style></style>
