<template>
  <div class="entry_change_button" v-if="isShow">
    <div class="button_ctn">
      <router-link
        :to="{name: name}"
        custom
        v-slot="{ navigate }"
      >
        <button
          @click="navigate"
          class="submit"
        >{{ displayStatus }}</button>
      </router-link>
    </div>
  </div>
</template>

<script setup>
import { defineProps, ref, computed } from "vue"
import moment from "moment"
import { getIndexStatus, getIndexByRouterName } from '@/helper/indexStatus'

const statusList = ref({})
;(async () => {
  statusList.value = await getIndexStatus()
})()

const props = defineProps({
  name: {
    type: String,
    default: null,
  },
})

const displayStatus = computed(() => {
  try {
    const index = getIndexByRouterName(props.name)
    const date_from = statusList.value[index].date_from
    const date_to = statusList.value[index].date_to
    const from = date_from ? moment(date_from) : false
    const to = date_to ? moment(date_to).add(1, 'd') : false
    const now = moment()
    if ( now < from ) return '開始前'
    if ( !to ) return '登録・変更'
    if ( from < now && now < to ) return '登録・変更'
    if ( to < now ) return '確認'
    return '不明'
  } catch (e) {
    return '不明'
  }
})

const isShow = computed(() => displayStatus.value !== '開始前')
</script>
