export default Object.freeze({
  "COLLABORATION_COOPERATION": {
    "label": "コラボ・連携",
    "value": "91",
    "isSingle": true
  },
  "OUT_OF_KIDZANIA": {
    "label": "Out of KidZania in JMS 2023",
    "value": "60",
    "isSingle": true
  }
})
