<template>
  <header><h1>JAPAN MOBILITY SHOW 2025&emsp;出展手続きサイト・出展者専用サイト</h1></header>
  <article><h2>出展申込書のダウンロード</h2>
  <div id="form_ctn" class="form_box">

    <div id="complete_text">

          
  
          <p class="attention_box">お申し込みはまだ完了していません。<br>
          登録されたメールにお手続きの詳細をお送りしました。<br>
          メール記載の手順に従ってお申し込みを進めてください。</p>
    </div>


  </div>
</article>
</template>

<script>

</script>