<template>
  <table class="common_table">
    <tbody>
      <tr>
        <th>
          ワックス清掃希望位置図
        </th>
        <td>{{ uploadDateResult }}<template v-if="uploadDate"><br><button @click="download">ダウンロード</button></template></td>
        <td class="edit_cell" v-if="isShowUI">
          <a
            @click.prevent="modalOpen"
            href="#wax4"
            class="common_button_t2 modalOpen"
            >{{ uploadLabel }}</a
          >
        </td>
      </tr>
    </tbody>
  </table>

  <Teleport to="body">
    <!-- モーダルコンテンツ / -->
    <div class="overlay" id="wax4">
      <div id="modal_content" class="modal_contents w80">
        <div class="modalClose_ctn"><a @click.prevent="modalClose" class="modalClose"></a></div>
        <!-- modal contents ここから-->
        <div class="entry_title">
          <h4>ワックス清掃申込</h4>
          <h5>ワックス清掃希望位置図&nbsp;&nbsp;{{uploadLabel}}</h5>
        </div>
        <div class="entry_modal_ctn">
          <table class="common_table">
            <tbody>
              <tr>
                <th>
                  ワックス清掃希望位置図
                </th>

                <td>
                  <input
                  type="file"
                  id="upload_file"
                  name="upload_file"
                  @change="loadFile($event, (v) => {
                    form.file = v
                  })"
                >
                <p class="alert" v-if="errors.file">{{ errors.file }}</p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="button_ctn">
          <ul>
            <li>
              <button
                @click.prevent="modalClose"
                class="gray_button modalClose"
              >キャンセル</button>
            </li>
            <li>
              <button
                @click="sendForm"
                class="regist_button"
              >アップロード</button>
            </li>
          </ul>
        </div>
        <!-- modal contents ここまで-->
      </div>
    </div>
    <!-- / モーダルコンテンツ -->
  </Teleport>
</template>

<script setup>
import APIClient from '@/store/repository/APIClient'
import { ref, reactive, computed } from 'vue'
import { cloneDeep } from 'lodash'
import { FormValidate } from '@/helper/FormValidate'
import * as yup from 'yup'
import moment from "moment"
import { loadFile, getFileExtension } from '@/helper/files'

import {
  modalOpen,
  modalClose,
} from '@/helper/modal'

import ApiConst from '@/const/api'

import { isShowable as isShowUI } from '@/helper/indexStatus'

const api = new APIClient()

const formInit = {
  file: '',
}
const form = reactive(cloneDeep(formInit))

const uploadDate = ref('')
const uploadDateResult = computed(() => {
  return uploadDate.value ? `${moment(uploadDate.value).format('YY-MM-DD HH:mm')} に送付しました。` : '未登録'
})
const uploadLabel = computed(() => {
  return uploadDate.value ? '変更' : '新規登録'
})

const supportedFormat = ['pdf'];
const scheme = {
  file: yup.string().required('必須の項目です。').test('fileType', "PDF形式のファイルをアップロードしてください。", v => {
    const extension = getFileExtension(v)
    return supportedFormat.includes(extension)
  }),
}
const validate = new FormValidate(scheme, form)
const errors = validate.errors
const sendForm = async () => {
  try {
    if ((Object.keys(await validate.getErrorObject()).length)) {
      throw new Error('入力項目に不備があります。')
    }
    const extension = form.file.toString().slice(form.file.indexOf('/') + 1, form.file.indexOf(';'))
    const query = {
      application_div : "cleaning",
      form_no: 5,
      upload_file: form.file,
      extension,
    }
    const fetchApi = await api.post(ApiConst.API_APP_UPLOAD, query)
    const res = fetchApi.response
    if (res.data?.error_results) {
      throw new Error('API Validation Error')
    }
    alert('登録が完了しました。')
    modalClose()
    uploadDate.value = res.data.results.updated_at
  } catch (e) {
    return false
  }
}

const fetch = async () => {
  try {
    const query = {
      form_no: 5,
      application_div : "cleaning",
    }
    const fetchApi = await api.get(ApiConst.API_APP_LIST, query)
    const res = fetchApi.response
    const errors = res.data?.error_results
    if (errors) {
      throw new Error('Not found.')
    }
    uploadDate.value = res.data.results.list[0].updated_at
  } catch (e) {
    console.log(e)
    return false
  }
}
fetch()

const download = async () => {
  try {
    const query = {
      form_no: 5,
      application_div : "cleaning",
    }
    const fetchApi = await api.post(ApiConst.API_APP_DOWNLOAD, query)
    const res = fetchApi.response
    const errors = res.data?.error_results
    if (errors) {
      throw new Error('Not found.')
    }
    await api.download(res.data.results.url)
  } catch (e) {
    console.log(e)
    return false
  }
}
</script>

<style></style>
