<template>
  <h3>出展者の検索</h3>
  
  <table class="common_table">
    <tbody>
      <tr>
        <th>審査ステータス</th>
        <td>
          <my-select v-model="exhibit_participation_status" :options="exhibit_participation_status_options"></my-select>
        </td>
      </tr>
      <tr>
        <th>会社名</th>
        <td>
          <my-input name="exhibitor_name" width_class="full" type="text" v-model="exhibitor_name"></my-input>

        </td>
      </tr>
      <tr>
        <th>会員区分</th>
        <td>
          <ul class="common_list_horizontal_iblock">
            <my-checkbox v-model="membership_category" :options="membership_category_options"></my-checkbox>

          </ul>

        </td>
      </tr>
      <tr>
        <th>過去の出展実績</th>
        <td>
          <ul class="common_list_horizontal_iblock">
            <my-checkbox v-model="past_achievement" name="past_achievement" :options="past_achievement_options">
            </my-checkbox>
          </ul>
        </td>
      </tr>
      <tr>
        <th>出展カテゴリー</th>
        <td>
          <ul class="common_list_horizontal_iblock">
            <my-checkbox v-model="category_div" name="category_div" :options="category_div_options"></my-checkbox>

          </ul>
        </td>
      </tr>
    </tbody>
  </table>

  <div class="button_ctn">
    <button class="submit" @click="sendForm">検索</button>
  </div>


</template>

<script>

import APIClient from '@/store/repository/APIClient'
import MyInput from '@/components/MyInput.vue';
import MySelect from '@/components/MySelect.vue';
import MyCheckbox from '@/components/MyCheckbox.vue';


export default {
  components: {
    MyInput,
    MySelect,
    MyCheckbox,
  },
  setup() {


    return {


    };
  },

  data() {
    return {
      exhibitor_name: '',
      category_div: '',
    }
  },
  async created() {
    this.api = new APIClient()
    const res = await this.api.getConfig()
    this.$store.commit('disicion/exhibit_participation_status_options', res.data.results.exhibit_participation_status_options)
    this.$store.commit('disicion/membership_category_options_admin', res.data.results.membership_category_options_admin)
    this.$store.commit('disicion/past_achievement_options', res.data.results.past_achievement_options)
    this.$store.commit('disicion/category_div_options', res.data.results.category_div_options)
  },
  mounted() {

  },
  methods: {
    async sendForm() {
      try {
        const query = {}
        if(this.exhibitor_name) query.exhibitor_name = this.exhibitor_name
        if(this.exhibit_participation_status) query.exhibit_participation_status = this.exhibit_participation_status
        if(this.membership_category) query.membership_category = this.membership_category
        if(this.past_achievement) query.past_achievement = this.past_achievement
        if(this.category_div) query.category_div1 = this.category_div.join(',')

        const res = await this.api.getAdminExhibitParticipationList(query)
        console.log(res)
        if (res.data?.error_results) {
          throw new Error('API Validation Error')
        }
        this.$store.commit('search/admin_pre_list', res.data.results.list)
      } catch (e) {
        console.error(e.message)
        return false
      }

      this.$router.push({ path: '/admin/pre_list' })
    }

  },
  watch: {


  },
  computed: {
    exhibit_participation_status_options() {
      return this.$store.state.disicion.exhibit_participation_status_options
    },
    membership_category_options() {
      return this.$store.state.disicion.membership_category_options_admin
    },
    past_achievement_options() {
      return this.$store.state.disicion.past_achievement_options
    },
    category_div_options() {
      return this.$store.state.disicion.category_div_options
    },
    admin_pre_list() {
      return this.$store.state.search.admin_pre_list
    }
  }
};
</script>
