<template>
  <table class="common_table">
    <tbody>
      <tr
        v-for="(v,k) in list"
        :key="k"
      >
        <th>{{ formatDate(k) }}</th>
        <td>{{ v }}台</td>
      </tr>
    </tbody>
  </table>
</template>

<script setup>
import { computed } from 'vue'
import { store } from '@/store'

import moment from "moment"

const list = computed(() => {
  return store.state.parking_card.p_list
})

const formatDate = (val) => {
  return moment(val).format('M月D日（ddd）')
}

const fetch = async () => {
  store.dispatch('parking_card/update')
}
fetch()

</script>

<style></style>
