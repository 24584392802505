export default Object.freeze({
  FORM_INIT: {
    delivery_id: null,
    company_name: "",
    company_name_kana: "",
    company_zip: "",
    company_pref: "",
    company_address: "",
    staff_affiliation: "",
    staff_post: "",
    staff_name: "",
    staff_name_kana: "",
    staff_tel1: "",
    staff_tel2: "",
    staff_email: "",
  },
})
