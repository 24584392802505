export const modalOpen = (e) => {
  const modal = document.querySelectorAll(".overlay")
  modal.forEach((el) => {
    el.style.display = "none"
  })

  const id = e.target.getAttribute("href")
  const target = document.querySelector(id)
  target.style.display = "flex"
}

export const modalClose = () => {
  const modalClose = document.querySelectorAll(".overlay")
  modalClose.forEach((el) => {
    el.style.display = "none"
  })
}