<template>
  <li v-for="(option, index) in mergedOptions" :key="index">
  <select
    @change="updateValue"
    :name="option.name"
    :ref="setValuesRef"
	>
		<option
			v-for="(o, i) in selects"
			:key="i"
			:selected="o.value === option.value"
      :value="o.value"
      >
			{{ o.label }}
		</option>
  </select>
  {{ option.label }}
  </li>
</template>

<script>
import { isValue } from '@/helper/index';

export default {
  props: {
    modelValue: { type: String, required: true },
    options: { type: Array, required: true },
    name: { type: String, required: true },
    storeKey: { type: String, required: false },
  },
  data() {
    return {
      values: [],
      selects: [
        {value: "0", label: 'アクセス不可'},
        {value: "1", label: '観覧可'},
        {value: "3", label: '観覧＋ダウンロード可'},
        {value: "7", label: '観覧＋入力＋ダウンロード可'},
      ]
    }
  },
  beforeUpdate() {
    this.values = []
  },
  methods: {
    setValuesRef(el) {
      if (el) {
        this.values.push(el)
      }
    },
    updateValue: function () {
      const map = {}
      this.values.forEach(item => {
        map[item.name] = item.value
      })
      this.$emit("update:modelValue", map)
    },
    listMapping: function (item, store) {
      const value = store[item.name]
      if ( !isValue(value) ) return false
      item.value = value
    }
  },
  computed: {
    mergedOptions() {
      const store = this.modelValue
      const list = this.options
      if ( store ) {
        list.forEach((item) => {
          this.listMapping(item, store)
        })
      }

      return list
    },
  },
}
</script>
