<template>
  <table class="common_table" v-if="!isNew">
    <tbody>
      <tr>
        <th colspan="2">会社名</th>
        <td>{{ currentData.company_name }}</td>
      </tr>
      <tr>
        <th colspan="2">会社名カナ</th>
        <td>{{ currentData.company_name_kana }}</td>
      </tr>
      <tr>
        <th rowspan="3">所在地</th>
        <template v-if="!isForeign">
        <th>郵便番号</th>
        <td>{{ currentData.company_zip }}</td>
        </template>
      </tr>

      <tr>
        <th>都道府県</th>
        <td>{{ currentData.company_pref }}</td>
      </tr>
      <tr>
        <th>住所</th>
        <td>{{ currentData.company_address }}</td>
      </tr>
      <tr>
        <th rowspan="7">担当者</th>
        <th>部署</th>
        <td>{{ currentData.staff_affiliation }}</td>
      </tr>
      <tr>
        <th>役職</th>
        <td>{{ currentData.staff_post }}</td>
      </tr>
      <tr>
        <th>氏名</th>
        <td>{{ currentData.staff_name }}</td>
      </tr>
      <tr>
        <th>氏名フリガナ</th>
        <td>{{ currentData.staff_name_kana }}</td>
      </tr>
      <tr>
        <th>電話番号</th>
        <td>{{ currentData.staff_tel1 }}</td>
      </tr>
      <tr>
        <th>緊急連絡先</th>
        <td>{{ currentData.staff_tel2 }}</td>
      </tr>

      <tr>
        <th>E-mail</th>
        <td>{{ currentData.staff_email }}</td>
      </tr>

    </tbody>
  </table>
  <div class="no_entry" v-else>登録がありません。「新規追加」ボタンより登録をお願いします。</div>

  <template v-if="isShowUI">
  <div><a
    v-if="!currentData.application_id"
    @click.prevent="newHandler"
    href="#form4"
    class="common_add_button modalOpen"
  >新規追加</a><a
    v-else
    @click.prevent="editHandler"
    href="#form4"
    class="common_add_button modalOpen"
  >変更</a></div>
  </template>

  <Teleport to="body">
    <!-- モーダルコンテンツ / -->
    <div class="overlay" id="form4">
      <div id="modal_content" class="modal_contents w80">
        <div class="modalClose_ctn"><a
          @click.prevent="modalClose"
          class="modalClose"
        ></a></div>
        <!-- modal contents ここから-->
        <div class="entry_title">
          <h4>臨時通信設備使用申込（一般電話）</h4>
          <h5>担当者連絡先（通信回線サービス担当からの詳細確認にお答えできる方）&nbsp;&nbsp;{{ uploadLabel }}</h5>
        </div>
        <div class="entry_modal_ctn">

          <table class="common_table">
            <tbody>
              <tr>
                <th colspan="2" class="required">会社名</th>
                <td><input type="text" class="full" v-model="form.company_name">
                <p class="alert" v-if="errors.company_name">{{ errors.company_name }}</p></td>
              </tr>
              <tr>
                <th colspan="2" class="required">会社名カナ</th>
                <td><input type="text" class="full" v-model="form.company_name_kana">
                <p class="alert" v-if="errors.company_name_kana">{{ errors.company_name_kana }}</p></td>
              </tr>
              <tr>
                <th rowspan="3">所在地</th>
                <template v-if="!isForeign">
                <th class="required">郵便番号</th>
                <td>
                  <input type="text" class="quarter" v-model="form.company_zip" @input="onZipInput" @keyup="onZipChange">
                  <p class="alert" v-if="errors.company_zip">{{ errors.company_zip }}</p>
                </td>
                </template>
              </tr>

              <tr>
                <th class="required">都道府県</th>
                <td>
                  <div class="select_ctn">
                    <select v-model="form.company_pref">
                      <option value="">都道府県</option>
                      <option
                        v-for="(v,k) in addressPrefOptions"
                        :key="k"
                        :value="v.label"
                        :selected="currentData.company_pref === v.label"
                      >{{ v.label }}</option>
                    </select>
                  </div>
                  <p class="alert" v-if="errors.company_pref">{{ errors.company_pref }}</p>
                </td>
              </tr>
              <tr>
                <th class="required">住所</th>
                <td><input name="delivery_worker_address" type="text" size="40" class="full" v-model="form.company_address">
                <p class="alert" v-if="errors.company_address">{{ errors.company_address }}</p>
                </td>
              </tr>
              <tr>
                <th rowspan="7">担当者</th>
                <th>部署</th>
                <td><input name="delivery_quarter_name" type="text" class="quarter" v-model="form.staff_affiliation">
                <p class="alert" v-if="errors.staff_affiliation">{{ errors.staff_affiliation }}</p></td>
              </tr>
              <tr>
                <th>役職</th>
                <td><input name="delivery_quarter_name" type="text" class="quarter" v-model="form.staff_post">
                <p class="alert" v-if="errors.staff_post">{{ errors.staff_post }}</p></td>
              </tr>
              <tr>
                <th class="required">氏名</th>
                <td><input name="delivery_prepared_name" type="text" size="30" class="validate[required]" v-model="form.staff_name">
                <p class="alert" v-if="errors.staff_name">{{ errors.staff_name }}</p></td>
              </tr>
              <tr>
                <th class="required">氏名フリガナ</th>
                <td><input name="delivery_prepared_name" type="text" size="30" class="validate[required]" v-model="form.staff_name_kana">
                <p class="alert" v-if="errors.staff_name_kana">{{ errors.staff_name_kana }}</p></td>
              </tr>
              <tr>
                <th>電話番号</th>
                <td><input type="text" name="delivery_worker_tel" class="validate[required]" v-model="form.staff_tel1">
                <p class="alert" v-if="errors.staff_tel1">{{ errors.staff_tel1 }}</p></td>
              </tr>
              <tr>
                <th class="required">緊急連絡先</th>
                <td><input type="text" name="delivery_worker_tel2" class="validate[required]" v-model="form.staff_tel2">
                <p class="alert" v-if="errors.staff_tel2">{{ errors.staff_tel2 }}</p>
                </td>
              </tr>

              <tr>
                <th>E-mail</th>
                <td><input type="text" name="delivery_worker_email" class="validate[custom[email]]" v-model="form.staff_email">
                <p class="alert" v-if="errors.staff_email">{{ errors.staff_email }}</p></td>
              </tr>

            </tbody>
          </table>

        </div>

        <div class="button_ctn">
          <ul>
            <li><button @click="modalClose" class="gray_button modalClose">キャンセル</button></li>
            <li><button
              @click="sendForm"
              class="regist_button"
            >上記内容で登録</button></li>
          </ul>
        </div>
        <!-- modal contents ここまで-->

      </div>
    </div>
    <!-- / モーダルコンテンツ -->
  </Teleport>
</template>

<script setup>
import { reactive, computed } from 'vue'
import { forEach, cloneDeep } from 'lodash'
import APIClient from '@/store/repository/APIClient'
import * as yup from 'yup'
import moment from "moment"
import { FormValidate } from '@/helper/FormValidate'
import KenAll from 'ken-all'
import ADDRESS from '@/const/address_elements.js'

import {
  modalOpen,
  modalClose,
} from '@/helper/modal'

import {
  zipNumOnlyTest,
  makeZip,
  isKana,
} from '@/helper/index'

import ApiConst from '@/const/api'

import { isShowable as isShowUI } from '@/helper/indexStatus'

import YupConst from '@/const/yup';

const zipRegExp = new RegExp(YupConst.ZIP_PATTERN)

const addressPrefOptions = ADDRESS.address_pref_options

const api = new APIClient()

const formInit = {
  application_id: null,
  company_name: "",
  company_name_kana: "",
  company_zip: "",
  company_pref: "",
  company_address: "",
  staff_affiliation: "",
  staff_post: "",
  staff_name: "",
  staff_name_kana: "",
  staff_tel1: "",
  staff_tel2: "",
  staff_email: "",
}

const form = reactive(cloneDeep(formInit))
const currentData = reactive(cloneDeep(formInit))

const isNew = computed(() => {
  return !currentData.application_id
})

const isForeign = computed(() => {
  return form.company_pref === ADDRESS.address_pref_foreign
})

const uploadLabel = computed(() => {
  return !isNew.value ? '変更' : '新規登録'
})

const fetch = async () => {
  try {
    const query = {
      form_no: 5,
      application_div: "extra_comm",
    }
    const fetchApi = await api.get(ApiConst.API_APP_LIST, query)
    const res = fetchApi.response
    const errors = res.data?.error_results
    if (errors) {
      throw new Error('Not found.')
    }
    const data = res.data.results.list.map((el) => {
      return {
        application_id: el.application_id,
        input_date: moment(el.updated_at).format("YY-MM-DD"),
        seq_no: el.seq_no,
        ...el.json_data
      }
    })[0]
    forEach(currentData, (v, k) => {
      currentData[k] = data[k]
    })
    forEach(form, (v, k) => {
      form[k] = data[k] ?? formInit[k]
    })
  } catch (e) {
    console.log(e)
    return false
  }
}
fetch()

const onZipInput = (e) => {
  if (!zipNumOnlyTest(e.target.value)) return
  const zipValue = makeZip(e.target.value)
  form.company_zip = zipValue
}
const onZipChange = (e) => {
  const value = e.target.value.replace(/[^0-9]/g, '')
  if (value.length !== 7) return
  KenAll(value).then(res => {
    if (res.length === 0) return
    const address = res[0]
    const prefecture = address.shift()
    const pref_match = addressPrefOptions.find(item => item.label === prefecture)
    form.company_pref = pref_match.label
    form.company_address = address.join('')
  })
}

const newHandler = (event) => {
  forEach(formInit, (v, k) => {
    form[k] = v
  })
  modalOpen(event)
}

const editHandler = (event) => {
  modalOpen(event)
}

const scheme = {
  company_name: yup.string().trim().required('必須の項目です。'),
  company_name_kana: yup.string().test('katakana-checker', 'カタカナで入力して下さい。', isKana).trim().required('必須の項目です。'),
  company_zip: yup.string().trim()
    .test(
      'company_zip_required',
      '必須の項目です。',
      function(v) {
        if ( isForeign.value ) return true
        return v.length
      }
    )
    .test(
      'company_zip_pref',
      YupConst.ZIP_PATTERN_MSG,
      function(v) {
        if ( isForeign.value ) return true
        return v.match(zipRegExp)
      }
    ),
  company_pref: yup.string().trim().required('必須の項目です。'),
  company_address: yup.string().trim().required('必須の項目です。'),
  staff_affiliation: yup.string().trim(),
  staff_post: yup.string().trim(),
  staff_name: yup.string().trim().required('必須の項目です。'),
  staff_name_kana: yup.string().test('katakana-checker', 'カタカナで入力して下さい。', isKana).trim().required('必須の項目です。'),
  staff_tel1: yup.string().trim(),
  staff_tel2: yup.string().trim().required('必須の項目です。'),
  staff_email: yup.string().trim().email('メールアドレスの形式が不正です。'),
}
const validate = new FormValidate(scheme, form)
const errors = validate.errors
const sendForm = async () => {
  try {
    if ((Object.keys(await validate.getErrorObject()).length)) {
      throw new Error('入力項目に不備があります。')
    }
    const params = cloneDeep(form)
    const application_id = params.application_id
    delete params.application_id
    const operation = application_id ? "R" : "I"
    const query = operation === "I"
      ? { operation, application_div: "extra_comm", form_no: 5, json_data: params }
      : { operation, application_id, json_data: params }
    const res = await api.post(ApiConst.API_APP_EDIT, query)
    if (res.response.data?.error_results) {
      throw new Error('API Validation Error')
    }
    fetch()
    modalClose()
  } catch (e) {
    return false
  }
}
</script>

<style></style>
