<template>
  <table class="common_table" v-if="!isNew">
    <tbody>
      <tr>
        <th colspan="2">会社名</th>
        <td>{{ currentBillData.company_name }}</td>
      </tr>
      <tr>
        <th colspan="2">会社名カナ</th>
        <td>{{ currentBillData.company_name_kana }}</td>
      </tr>
      <tr>
        <th rowspan="3">所在地</th>
        <th>郵便番号</th>
        <td>{{ makeZip(currentBillData.company_zip) }}</td>
      </tr>

      <tr>
        <th>住所1<!--（桁数制限は全角30桁、半角60桁）--></th>
        <td>{{ currentBillData.company_address1 }}</td>
      </tr>
      <tr>
        <th>住所2<!--（桁数制限は全角30桁、半角60桁）--></th>
        <td>{{ currentBillData.company_address2 }}</td>
      </tr>
      <tr>
        <th rowspan="6">担当者</th>
        <th>所属</th>
        <td>{{ currentBillData.staff_affiliation }}</td>
      </tr>
      <tr>
        <th>役職</th>
        <td>{{ currentBillData.staff_post }}</td>
      </tr>
      <tr>
        <th>氏名</th>
        <td>{{ currentBillData.staff_name }}</td>
      </tr>
      <tr>
        <th>電話番号</th>
        <td>{{ currentBillData.staff_tel1 }}</td>
      </tr>
      <tr>
        <th>緊急連絡先</th>
        <td>{{ currentBillData.staff_tel2 }}</td>
      </tr>

      <tr>
        <th>E-mail</th>
        <td>{{ currentBillData.staff_email }}</td>
      </tr>
      <tr>
        <th colspan="2">請求言語</th>
        <td>{{ langLabels[currentBillData.lang] }}</td>
      </tr>
    </tbody>
  </table>
  <div class="no_entry" v-else>登録がありません。「新規追加」ボタンより登録をお願いします。</div>
  <template v-if="isShowUI">
  <div>
    <a @click.prevent="modalOpen" href="#billing_address_entry" class="common_add_button modalOpen">{{ uploadLabel }}</a>
  </div>
  </template>
  <Teleport to="body">
    <!-- モーダルコンテンツ / -->
    <div class="overlay" id="billing_address_entry">
      <div id="modal_content" class="modal_contents w80">
        <!-- modal contents ここから-->
        <div class="modalClose_ctn">
          <a @click.prevent="modalClose" class="modalClose"></a>
        </div>

        <div class="entry_title">
          <h4>アンカーボルト使用届</h4>
          <h5>請求先（入力必須）&nbsp;&nbsp;{{uploadLabel}}</h5>
        </div>

        <div class="entry_modal_ctn">
          <BillRegistration
            :billingId="edit_billing_id"
            @emitSelectBill="selectBill"
            @emitFetch="fetch"
          />
        </div>

        <div class="button_ctn">
          <ul>
            <li>
              <button @click.prevent="modalClose" class="gray_button modalClose">
                キャンセル
              </button>
            </li>
            <li>
              <button class="regist_button" @click.prevent="selectionForm" :disabled="!edit_billing_id">
                上記内容で登録
              </button>
            </li>
          </ul>
        </div>
        <!-- modal contents ここまで-->
      </div>
      <!-- / モーダルコンテンツ -->
    </div>
    <!-- / モーダルコンテンツ -->
  </Teleport>
</template>

<script setup>
import APIClient from '@/store/repository/APIClient'
import { reactive, ref, computed } from "vue"
import { forEach, cloneDeep } from 'lodash'
import BillRegistration from '@/components/BillRegistration'

import {
  modalOpen,
  modalClose,
} from '@/helper/modal'

import {
  makeZip,
} from '@/helper/index'

import ApiConst from '@/const/api'
import BillConst from '@/const/bill'

import { isShowable as isShowUI } from '@/helper/indexStatus'

const langLabels = BillConst.LANG_LABELS
const formInit = BillConst.FORM_INIT

const api = new APIClient()

const forms = reactive([])
const edit_billing_id = ref(null)
const current_billing_id = ref(null)
const currentBillData = computed(() => {
  const data = forms.find(el => el.billing_id === current_billing_id.value) ?? cloneDeep(formInit)
  return data
})

const isNew = computed(() => {
  return !currentBillData.value.billing_id
})

const uploadLabel = computed(() => {
  return !isNew.value ? '変更' : '新規登録'
})

const fetch = async (values) => {
  forms.splice(0)
  forEach(values, (v, k) => {
    forms[k] = v
  })
}
fetch()

const selectBill = (i) => {
  edit_billing_id.value = i
}

const bill_application_id = ref(null)

const fetchBill = async () => {
  try {
    const query = {
      form_no: 4,
      application_div: "anchor_bolt",
    }
    const fetchApi = await api.get(ApiConst.API_APP_LIST, query)
    const res = fetchApi.response
    const errors = res.data?.error_results
    if (errors) {
      throw new Error('Not found.')
    }
    current_billing_id.value = res.data.results.list[0].json_data.billing_id
    edit_billing_id.value = current_billing_id.value
    bill_application_id.value = res.data.results.list[0].application_id
  } catch (e) {
    console.log(e)
    return false
  }
}
fetchBill()

const selectionForm = async () => {
  try {
    const params = {
      billing_id: edit_billing_id.value
    }
    const application_id = bill_application_id.value
    const operation = application_id ? "R" : "I"
    const query = operation === "I"
      ? { operation, application_div: "anchor_bolt", form_no: 4, json_data: params }
      : { operation, application_id, json_data: params }
    const res = await api.post(ApiConst.API_APP_EDIT, query)
    if (res.response.data?.error_results) {
      throw new Error('API Validation Error')
    }
    fetchBill()
    modalClose()
  } catch (e) {
    return false
  }
}
</script>

<style></style>
