export default Object.freeze({
  API_VALIDATION_ERROR: 400,
  API_CD_AUTH_ERROR: 401,
  API_NOT_ALLOW_OPERATION: 403,
  API_PAYLOAD_TOO_LARGE_ERROR: 413,

  API_LOGIN: '/api/exhibitor/login',
  API_AUTH: '/api/exhibitor/auth',
  API_REFRESH_TOKEN: '/api/exhibitor/reissuetoken',

  API_ADMIN_LOGIN: '/api/admin/login',
  API_ADMIN_AUTH: '/api/admin/auth',
  API_ADMIN_REFRESH_TOKEN: '/api/admin/reissuetoken',
  API_ADMIN_APP_LIST: '/api/admin/application/list',
  API_ADMIN_PROXY_LIST: '/api/admin/proxy/list',
  API_ADMIN_APPLICATION_STATUS_LIST: '/api/admin/application_status/list',
  API_ADMIN_APPLICATION_STATUS_STATUS_LIST: '/api/admin/application_status/status_list',
  API_ADMIN_APP_CSV: '/api/admin/application/csv',
  API_ADMIN_APP_ADMIN_EDIT: '/api/admin/application/admin_edit',
  API_ADMIN_DEADLINE_LIST: '/api/admin/deadline/list',
  API_ADMIN_DEADLINE_BULKEDIT: '/api/admin/deadline/bulkedit',
  API_ADMIN_OVERTIMENETWORK_LIST: '/api/admin/overtimenetwork/list',
  API_ADMIN_OVERTIMENETWORK_BULKEDIT: '/api/admin/overtimenetwork/bulkedit',
  API_ADMIN_OVERTIMEWORK_LIST: '/api/admin/overtimework/list',
  API_ADMIN_OVERTIMEWORK_BULKEDIT: '/api/admin/overtimework/bulkedit',
  API_ADMIN_SANITARY_LIST: '/api/admin/sanitary/list',
  API_ADMIN_SANITARY_BULKEDIT: '/api/admin/sanitary/bulkedit',
  API_ADMIN_PROXY_LOGIN: ' /api/admin/proxy_login',

  API_APP_EDIT: '/api/application/edit',
  API_APP_LIST: '/api/exhibitor/application/list',
  API_APP_UPLOAD: '/api/application/upload',
  API_APP_PDF_DOWNLOAD: '/api/application/pdf_download',
  API_APP_DOWNLOAD: '/api/application/download',
  API_BILLING_EDIT: '/api/billing/edit',
  API_BILLING_LIST: '/api/billing/list',
  API_DELIVERY_EDIT: '/api/delivery/edit',
  API_DELIVERY_LIST: '/api/delivery/list',
  API_APP_SORT: '/api/application/sort',
  API_WIRELESS_MIC_BLIST: '/api/exhibitor/wireless_mic/b_list',
  API_EXHIBITOR_APP_APPLIST: '/api/exhibitor/application/application_list',
  API_EXHIBITOR_PARKING_CARD_P_LIST: '/api/exhibitor/parking_card/p_list',

  API_CONFIG: '/api/config',

  // APIステータスマップ
  statusKeyMap : {
    SUCCESS: 'success',
    // APIレスポンスエラー
    FAILED: 'failed',
    // API認証エラー
    AUTH_FAILED: 'authFailed',
    // API接続エラー
    CONNECT_FAILED: 'connectFiled'
  }
})
