export default Object.freeze({
  "list": {
    advertising_print: '宣伝印刷物申込',
    exhibits_list: '出展物リスト',
    booth_info: 'ブース情報',
    num_exhibits: '出展車両数',
    exhibitors_list: '出展者一覧',
    pressrelease_link: 'プレスリリースリンク掲出登録',
    lot_executive: '小間内責任者届',
    lot_worker: '小間内装飾業者届',
    lot_planning: '小間設計図届',
    carry_inout: '計画搬入出調査書',
    tax_cargo: '保税貨物明細',
    cleaning: '小間内清掃／ワックス清掃申込',
    anchor_bolt: 'アンカーボルト使用届',
    ceiling_structure: '天井構造物設置届',
    two_storied: '二階建施設設計図届',
    roof_hang: '天井吊下げ施工届',
    lighting_change: '施設天井照明変更申込',
    prohibited_acts: '禁止行為解除承認申請',
    detector: '無線式煙(熱)感知器・消火器使用申込',
    electro_regist: '電気使用申込',
    electro_completed: '電気工事落成届',
    sanitary_regist: '給水使用申込',
    extra_comm: '臨時通信設備使用申込(一般電話・ISDN回線)',
    extra_comm2: '臨時通信設備使用申込(高速通信回線)',
    antenna_regist: 'アンテナ使用申込',
    staff_anteroom: '出展者用控室申込',
    operation_plan: '運営計画書届',
    wireless_mic: 'ワイヤレスマイク使用届',
    survey_questionnaires: '調査・アンケート実施届',
    food_handling: '食品取扱届',
    showlogo: '出展者オリジナルグッズ販売届',
    entrance_ticket: '出展者入門証追加・出展関係業者バッジ申込',
    normal_ticket: '一般入場券・ 自動車ガイドブック引換券・ 封筒申込',
    guidebook_order: '自動車ガイドブック現物申込',
    event_participation: 'オフィシャルデー招待状',
    collaboration: '協業情報',
    parking_card: '地下駐車場パスカード申込',
  }
})
