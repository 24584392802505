export const isValue = function (target) {
  if (
    Array.isArray(target)
  ) {
    return !!target.filter(Boolean).length
  }
  if (
    target !== "" &&
    target !== undefined &&
    target !== "undefined" &&
    target !== null &&
    target !== "null"
  ) {
    return true;
  }
  return false;
};

export const perseIntOrNull = function (target) {
  if (typeof target === "number") {
    return target;
  }
  if (isValue(target)) {
    const removed = target.replace(/,/g, "");
    return parseInt(removed);
  }
  return null;
};

const zipNumOnly = /^\d{7}$/;
export const zipNumOnlyTest = (str) => zipNumOnly.test(str)
export const makeZip = (str) => {
  if (!zipNumOnlyTest(str)) return str;
  return str.substr(0, 3) + "-" + str.substr(3);
};

export const isKana = function (value) {
  return !!value.match(/^[ァ-ヴー\s]*$/);
};

export const sleep = (waitSeconds) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, waitSeconds);
  });
};

export const nl2br = (str) => {
  return str?.replace(/\r?\n/g, '<br>') ?? str
}

export const isUrl = (string) => {
  try {
    const urlObject = new URL(string)
    return isValue(urlObject.origin)
  } catch (e) {
    return false
  }
}
