<template>
  <table class="common_table" v-if="list.length">
    <tbody>
      <tr>
        <th>申込日</th>
        <th>入力日</th>
        <th>受付番号</th>
        <th colspan="2">申込枚数</th>
        <th>合計金額 <br>
        （消費税込）</th>
        <th>納品先</th>
        <th>請求先</th>
        <th colspan="2">編集</th>
      </tr>
      <template v-for="(val, key) in list" :key="key">
      <tr :class="isAccepted(val.receipt_date) ? 'accepted' : ''">
        <td rowspan="3" class="accepted">{{ val.application_date }}</td>
        <td rowspan="3" class="accepted">{{ val.input_date }}</td>
        <td rowspan="3" class="accepted">{{ val.seq_no }}</td>
        <td>一般入場券</td>
        <td class="ta_right">{{ val.ticket_qtyUnit }}</td>
        <td rowspan="3" class="ta_right">{{ val.totalPriceUnit }}</td>
        <td rowspan="3" class="accepted">{{ getDeliveryName(val.delivery_id) }}</td>
        <td rowspan="3" class="accepted">{{ getBillName(val.billing_id) }}</td>
        <template v-if="isAccepted(val.receipt_date)">
          <td colspan="2" rowspan="3" class="accepted">{{ moment(val.receipt_date).format('MM/DD') }}申込受付済</td>
        </template>
        <template v-else>
          <td rowspan="3" class="edit_cell" v-if="isShowUI">
            <a @click.prevent="editHandler($event, key)" href="#form1" class="common_button_t2 modalOpen">変更</a>
          </td>
          <td rowspan="3" class="edit_cell" v-if="isShowUI">
            <a @click.prevent="deleteHandler($event, key)" class="common_button_t2 gray_button delete_button">削除</a>
          </td>
        </template>
      </tr>
      <tr :class="isAccepted(val.receipt_date) ? 'accepted' : ''">
        <td>ガイドブック引換券</td>
        <td class="ta_right">{{ val.coupon_qtyUnit }}</td>
      </tr>
      <tr :class="isAccepted(val.receipt_date) ? 'accepted' : ''">
        <td>ロゴ入り封筒 </td>
        <td class="ta_right">{{ val.envelope_qtyUnit }}</td>
      </tr>
      </template>
    </tbody>
  </table>
  <div class="no_entry" v-else>登録がありません。「新規追加」ボタンより登録をお願いします。</div>
  <template v-if="isShowUI">
  <div>
    <a @click.prevent="newHandler" href="#form1" class="common_add_button modalOpen">新規追加</a>
  </div>
  </template>

  <Teleport to="body">
    <!-- モーダルコンテンツ / -->
    <!-- 製作注意問い合わせ先は何種類かあります。読み込み形式で簡単に編集できるようにしたほうが良いかと思います。-->
    <div class="overlay" id="form1">
      <div id="modal_content" class="modal_contents w80">
        <!-- modal contents ここから-->
        <div class="modalClose_ctn">
          <a @click.prevent="modalClose" class="modalClose"></a>
        </div>
        <div class="entry_title">
          <h4>一般入場券・自動車ガイドブック(Vol.70)引換券・封筒申込&nbsp;&nbsp;{{ uploadLabel }}</h4>
          <h5>一般入場券・自動車ガイドブック(Vol.66)引換券・封筒申込</h5>
        </div>
        <div class="entry_modal_ctn">
          <table class="common_table">
            <tbody>
              <tr>
                <th>種類</th>
                <th>単価</th>
                <th>申込枚数</th>
                <th>合計金額（消費税込）</th>
              </tr>
              <tr>
                <td nowrap="">入場券</td>
                <td class="ta_right">{{ ticketQtyUnitCost.toLocaleString() }}円</td>
                <td class="ta_right"><input name="ticket_qty" type="number" class="w6em"
                  v-model="form.ticket_qty"> 枚
                  <p class="alert" v-if="errors.ticket_qty">{{ errors.ticket_qty }}</p></td>
                <td class="ta_right">{{ calcPriceByQty(form.ticket_qty, ticketQtyUnitCost)?.toLocaleString() }} 円</td>
              </tr>
              <tr>
                <td nowrap="">自動車ガイドブック（Vol70）引換券 </td>
                <td class="ta_right">{{ couponQtyUnitCost.toLocaleString() }}円</td>
                <td class="ta_right"><input name="coupon_qty" type="number" class="w6em"
                  v-model="form.coupon_qty"> 枚
                  <p class="alert" v-if="errors.coupon_qty">{{ errors.coupon_qty }}</p></td>
                <td class="ta_right">{{ calcPriceByQty(form.coupon_qty, couponQtyUnitCost)?.toLocaleString() }} 円</td>
              </tr>
              <tr>
                <td nowrap="">JAPAN MOBILITY SHOW 2025ロゴ入り封筒</td>
                <td class="ta_right">{{ envelopeQtyUnitCost.toLocaleString() }}円</td>
                <td class="ta_right"><input name="envelope_qty" type="number" class="w6em"
                  v-model="form.envelope_qty"> 枚
                  <p class="alert" v-if="errors.envelope_qty">{{ errors.envelope_qty }}</p></td>
                <td class="ta_right">{{ calcPriceByQty(form.envelope_qty, envelopeQtyUnitCost)?.toLocaleString() }} 円</td>
              </tr>
              <tr>
                <td colspan="2" nowrap="" style="border: none">&nbsp;</td>
                <th class="ta_right">合計金額</th>
                <td class="ta_right">{{ calcTotalPrice.toLocaleString() }} 円</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="entry_title">
          <h5>納品先（入力必須）</h5>
        </div>

        <div class="entry_modal_ctn">
          <DeliveryRegistration
            :deliveryId="form.delivery_id"
            @emitSelectDelivery="selectDelivery"
            @emitFetch="fetchDeliveries"
          />
          <p class="alert" v-if="errors.delivery_id">{{ errors.delivery_id }}</p>
        </div>

        <div class="entry_modal_ctn">
          <BillRegistration
            :billingId="form.billing_id"
            @emitSelectBill="selectBill"
            @emitFetch="fetchBills"
          />
          <p class="alert" v-if="errors.billing_id">{{ errors.billing_id }}</p>
        </div>

        <div class="button_ctn">
          <ul>
            <li>
              <button @click.prevent="modalClose" class="gray_button modalClose">キャンセル</button>
            </li>
            <li>
              <button @click="sendForm" class="regist_button">
                上記内容で登録
              </button>
            </li>
          </ul>
        </div>
        <!-- modal contents ここまで-->
      </div>
    </div>
    <!-- / モーダルコンテンツ -->
  </Teleport>
</template>

<script setup>
import Big from 'big.js'
import { reactive, computed } from 'vue'
import APIClient from '@/store/repository/APIClient'
import { sum, forEach, cloneDeep } from 'lodash'
import { FormValidate } from '@/helper/FormValidate'
import * as yup from 'yup'
import moment from "moment"
import DeliveryRegistration from '@/components/DeliveryRegistration'
import BillRegistration from '@/components/BillRegistration'

import {
  modalOpen,
  modalClose,
} from '@/helper/modal'

import { useRoute } from 'vue-router'

import ApiConst from '@/const/api'

import { isShowable as isShowUI } from '@/helper/indexStatus'

import { isSpoofing } from '@/helper/adminSpoofingVars'

const route = useRoute()

const ticketQtyUnitCost = 1350
const couponQtyUnitCost = route.meta.couponQtyUnitCost
const envelopeQtyUnitCost = 30

const api = new APIClient()

const list = reactive([])
const deliveries = reactive([])
const bills = reactive([])

const isAccepted = date => {
  if (isSpoofing()) return false
  if (moment() > moment(date) ) return true
  return false
}

const formInit = {
  application_id: null,
  ticket_qty: null,
  coupon_qty: null,
  envelope_qty: null,
  delivery_id: null,
  billing_id: null,
}
const form = reactive(cloneDeep(formInit))

const calcPriceByQty = (val, unitCost) => {
  if (val === null) return Number(val)
  return Big(val).mul(unitCost).toNumber()
}

const calcTotalPrice = computed(() => {
  return sum([
    calcPriceByQty(form.ticket_qty, ticketQtyUnitCost),
    calcPriceByQty(form.coupon_qty, couponQtyUnitCost),
    calcPriceByQty(form.envelope_qty, envelopeQtyUnitCost),
  ])
})

const uploadLabel = computed(() => {
  return form.application_id ? '変更' : '新規登録'
})


const getDeliveryName = (delivery_id) => {
  const data = deliveries.find(el => el.delivery_id === delivery_id)
  return data?.company_name
}
const selectDelivery = (i) => {
  form.delivery_id = i
}

const fetchDeliveries = async (values) => {
  deliveries.splice(0)
  forEach(values, (v, k) => {
    deliveries[k] = v
  })
}
fetchDeliveries()

const getBillName = (billing_id) => {
  const data = bills.find(el => el.billing_id === billing_id)
  return data?.company_name
}
const selectBill = (i) => {
  form.billing_id = i
}

const fetchBills = async (values) => {
  bills.splice(0)
  forEach(values, (v, k) => {
    bills[k] = v
  })
}
fetchBills()

const fetch = async () => {
  const query = {
    form_no: 1,
    application_div: "normal_ticket",
  }
  const fetchApi = await api.get(ApiConst.API_APP_LIST, query)
  const res = fetchApi.response
  const errors = res.data?.error_results
  if (errors) {
    throw new Error('Not found.')
  }
  list.splice(0)
  list.value = res.data.results.list.map((el) => {
    return {
      application_id: el.application_id,
      application_date: moment(el.created_at).format("YY-MM-DD"),
      input_date: moment(el.updated_at).format("YY-MM-DD"),
      seq_no: el.seq_no,
      receipt_date: el.send_date,
      ...el.json_data
    }
  }).forEach((val, key) => {
    forEach(val, (v, k) => {
      val[k] = v ?? formInit[k]
    })
    val.ticket_qtyUnit = val.ticket_qty ? val.ticket_qty.toLocaleString() + ' 冊' : ''
    val.coupon_qtyUnit = val.coupon_qty ? val.coupon_qty.toLocaleString() + ' 枚' : ''
    val.envelope_qtyUnit = val.envelope_qty ? val.envelope_qty.toLocaleString() + ' 枚' : ''
    val.totalPrice = sum([
      calcPriceByQty(val.ticket_qty, ticketQtyUnitCost),
      calcPriceByQty(val.coupon_qty, couponQtyUnitCost),
      calcPriceByQty(val.envelope_qty, envelopeQtyUnitCost),
    ])
    val.totalPriceUnit = val.totalPrice ? val.totalPrice.toLocaleString() + ' 円' : ''
    list[key] = val
  })
}
fetch()

const newHandler = (event) => {
  forEach(formInit, (v, k) => {
    form[k] = v
  })
  modalOpen(event)
}

const editHandler = (event, key) => {
  forEach(form, (v, k) => {
    form[k] = list[key][k]
  })
  modalOpen(event)
}

const deleteHandler = async (event, key) => {
  if (!window.confirm('削除して宜しいですか？')) return false

  try {
    const application_id = list[key].application_id
    const operation = "D"
    const query = { operation, application_id }
    const res = await api.post(ApiConst.API_APP_EDIT, query)
    if (res.response.data?.error_results) {
      throw new Error('API Validation Error')
    }
    fetch()
  } catch (e) {
    return false
  }
}

const scheme = {
  ticket_qty: yup.number().typeError("必須の項目です。半角数字を入力してください。").required('必須の項目です。'),
  coupon_qty: yup.number().typeError("必須の項目です。半角数字を入力してください。").required('必須の項目です。'),
  envelope_qty: yup.number().typeError("必須の項目です。半角数字を入力してください。").required('必須の項目です。'),
  delivery_id: yup.number().typeError("必須の項目です。").required('必須の項目です。'),
  billing_id: yup.number().typeError("必須の項目です。").required('必須の項目です。'),
}
const validate = new FormValidate(scheme, form)
const errors = validate.errors
const sendForm = async () => {
  try {
    if ((Object.keys(await validate.getErrorObject()).length)) {
      throw new Error('入力項目に不備があります。')
    }
    const params = cloneDeep(form)
    params.total_price = sum([
      calcPriceByQty(params.ticket_qty, ticketQtyUnitCost),
      calcPriceByQty(params.coupon_qty, couponQtyUnitCost),
      calcPriceByQty(params.envelope_qty, envelopeQtyUnitCost),
    ])
    const application_id = params.application_id
    delete params.application_id
    const operation = application_id ? "R" : "I"
    const query = operation === "I"
      ? { operation, application_div: "normal_ticket", form_no: 1, json_data: params }
      : { operation, application_id, json_data: params }
    const res = await api.post(ApiConst.API_APP_EDIT, query)
    if (res.response.data?.error_results) {
      throw new Error('API Validation Error')
    }
    fetch()
    modalClose()
  } catch (e) {
    return false
  }
}
</script>

<style></style>
