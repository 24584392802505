import { createStore } from "vuex";
import createPersistedState from 'vuex-persistedstate'
import DisicionModule from './modules/disicion'
import FormModule from './modules/form'
import EntryDataModule from './modules/entrydata'
import SearchModule from './modules/search'
import LoginModule from './modules/login'
import LoginUserModule from './modules/login_user'
import AdministratorModule from './modules/administrator'
import MessageModule from './modules/message'

import AdminModule from './modules/admin'
import AdminEditAccountModule from './modules/admin_edit_account'
import AdminApplication from './modules/admin_application'
import AdminSpoofingLogin from './modules/admin_spoofing_login'
import DeliveryModule from './modules/delivery'
import BillModule from './modules/bill'
import ApiCacheModule from './modules/api_cache'
import ApiParkingCard from './modules/parking_card'
import DuplicateSubmissionProtection from './modules/duplicate_submission_protection'

export const store = createStore({
  namespaced: true,
  state: {
  },
  modules: {
    disicion: DisicionModule,
    form: FormModule,
    entrydata: EntryDataModule,
    search: SearchModule,
    admin: AdminModule,
    login: LoginModule,
    login_user: LoginUserModule,
    administrator: AdministratorModule,
    message: MessageModule,
    admin_edit_account: AdminEditAccountModule,
    admin_application: AdminApplication,
    admin_spoofing_login: AdminSpoofingLogin,
    delivery: DeliveryModule,
    bill: BillModule,
    api_cache: ApiCacheModule,
    parking_card: ApiParkingCard,
    duplicate_submission_protection: DuplicateSubmissionProtection,
  },
  plugins: [createPersistedState(
    { // ストレージのキーを指定
      key: 'appName',
      // ストレージの種類を指定
      //storage: window.sessionStorage 本番用
      //storage: window.localStorage テスト用 他タブ等でも残る
      storage: window.sessionStorage
    }
  )]
});
