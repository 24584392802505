<template>
  <div id="admin">
    <header>
      <h1>JAIS 2025 出展者登録DB&nbsp;&nbsp;管理者用ページ</h1>
    </header>
    <article>
      <h2>請求データダウンロード</h2>
      <div id="form_ctn" class="form_box">
        <ol class="breadcrumbs">
          <AdminNav :categoryTop="true" pageName="請求データダウンロード" />
        </ol>
        <h3>請求データダウンロード</h3>

        <table class="common_table">
          <thead>
            <tr>
              <th style="width: 25%;">事前請求書 </th>
              <th style="width: 25%;">ショー用品請求書</th>
              <th style="width: 25%;">入門証・バッジ請求書</th>
              <th style="width: 25%;">事後請求書</th>
            </tr>
          </thead>
          <tbody>

            <tr>
              <td class="ta_center"><button class="common_button" style="width: 100%;" @click="download('/api/admin/demand/csv1')">ダウンロード</button></td>
              <td class="ta_center"><button class="common_button" style="width: 100%;" @click="download('/api/admin/demand/csv2')">ダウンロード</button></td>
              <td class="ta_center"><button class="common_button" style="width: 100%;" @click="download('/api/admin/demand/csv3')">ダウンロード</button></td>
              <td class="ta_center"><button class="common_button" style="width: 100%;" @click="download('/api/admin/demand/csv4')">ダウンロード</button></td>
            </tr>
          </tbody>

        </table>




      </div>
    </article>
  </div>
</template>

<script setup>
import APIClient from '@/store/repository/APIClient'
import AdminNav from "@/components/AdminNav"

const api = new APIClient()

const download = async (endpoint) => {
  try {
    const fetchApi = await api.post(endpoint)
    const res = fetchApi.response
    const errors = res.data?.error_results
    if (errors) {
      throw new Error('Not found.')
    }
    await api.download(res.data.results.url)
  } catch (e) {
    console.log(e)
    return false
  }
}
</script>

<style></style>
