import { computed } from "vue";
import { forEach, map, compact } from 'lodash'
import { store } from '@/store'
import APIClient from '@/store/repository/APIClient'
import { useRoute } from 'vue-router'
import {
  isValue
} from '@/helper/index'

import ApiConst from '@/const/api'

const api = new APIClient()


const fetchConfig = async () => {
  const res = await api.getConfig()
  store.commit('disicion/category_disp_options', res.data.results.category_disp)
  store.commit('disicion/hall_div_options', res.data.results.hall_div)
}

const category_disp_options = computed(() => store.state.disicion.category_disp_options)
const hall_div_options = computed(() => store.state.disicion.hall_div_options)

export const getSearchCondition = (query) => {
  const array = []
  forEach(query, (v, k) => {
    if (k === 'admin_application') return

    if (k === 'array_category_disp') {
      if (!category_disp_options.value) fetchConfig()
      v.forEach(el => {
        array.push(category_disp_options.value[el])
      })
    } else if (k === 'array_hall_div') {
      if (!hall_div_options.value) fetchConfig()
      v.forEach(el => {
        array.push(hall_div_options.value[el])
      })
    } else {
      if (isValue(v)) {
        array.push(v)
      }
    }
  })
  return array.join(' ')
}


export const getQuery = () => {
  const route = useRoute()
  const searchStr = location.search
  const searchList = map([...new URLSearchParams(searchStr)], ((el) => {
    return {key: el[0], value: el[1]}
  })).reduce((group, p) => {
    group[p.key] = group[p.key] ?? []
    group[p.key].push(p.value)
    return group
  }, {})
  
  const query = {
    admin_application: route.params.admin_application,
    koma_bango: searchList?.koma_bango[0],
    exhibitor_name: searchList?.exhibitor_name[0],
    array_category_disp: searchList?.array_category_disp,
    array_hall_div: searchList?.array_hall_div,
    unconfirmed_flag: searchList?.unconfirmed_flag[0],
    confirmed_date: searchList?.confirmed_date[0],
  }
  forEach(query, (v, k) => {
    if (Array.isArray(v) && compact(v).length < 1) {
      delete query[k]
    } else if (!isValue(v)) {
      delete query[k]
    }
  })

  return query
}

export const fetchList = async (query, list) => {
  try {
    const fetchApi = await api.post(ApiConst.API_ADMIN_PROXY_LIST, query)
    const res = fetchApi.response
    const errors = res.data?.error_results
    if (errors) {
      throw new Error('Not found.')
    }
    list.value = res.data.results.list
  } catch (e) {
    console.error(e.message)
  }
}

export const isSpoofing = () => {
  const route = useRoute()
  if (route.meta.adminSpoofing) return true
  return false
}
