import { store } from '@/store'
import router from '@/router'

export const logout = function () {
    
    store.commit('login/token', '')
    store.commit('login/limit_datetime', '')
    store.commit('login/refresh_token', '')
    store.commit('login/limit_datetime_refresh_token', '')

    router.push({ name: 'login' })
}