<template>
  <table class="common_table" v-if="list.length">
    <tbody>
      <tr>
        <th>種別</th>
        <th>清掃日 </th>
        <th>単価（消費税込）</th>
        <th>ワックス<br>
          清掃面積</th>
        <th>料金<br>
          （消費税込）</th>
        <th colspan="2" v-if="isShowUI">編集</th>
      </tr>

      <tr v-for="(val, key) in list" :key="key">
        <td>ワックス清掃<template v-if="val.maintenance_div === '希望する'">+<br>
          メンテナンス清掃</template></td>
        <td>{{ val.wax_date }}</td>
        <td>{{ unitCost }}円/1回・m<sup>2</sup></td>
        <td class="ta_right">{{ val.floor_space }}m<sup>2</sup></td>
        <td>{{ calcPriceByList(val.floor_space).toLocaleString() }}円</td>
        <td class="edit_cell" v-if="isShowUI">
          <a @click.prevent="editHandler($event, key)" href="#wax1" class="common_button_t2 modalOpen">変更</a>
        </td>
        <td class="edit_cell" v-if="isShowUI">
          <a @click.prevent="deleteHandler($event, key)" class="common_button_t2 gray_button delete_button">削除</a>
        </td>
      </tr>
    </tbody>
  </table>
  <div class="no_entry" v-else>登録がありません。「新規追加」ボタンより登録をお願いします。</div>
  <template v-if="isShowUI">
  <div>
    <a @click.prevent="newHandler" href="#wax1" class="common_add_button modalOpen">新規追加</a>
  </div>
  </template>
  <Teleport to="body">

    <!-- モーダルコンテンツ / -->
    <!-- 製作注意問い合わせ先は何種類かあります。読み込み形式で簡単に編集できるようにしたほうが良いかと思います。-->
    <div class="overlay" id="wax1">
      <div id="modal_content" class="modal_contents w80">
        <!-- modal contents ここから-->
        <div class="modalClose_ctn">
          <a @click.prevent="modalClose" class="modalClose"></a>
        </div>
        <div class="entry_title">
          <h4>ワックス清掃</h4>
          <div class="entry_title">
            <h5>ワックス清掃&nbsp;&nbsp;{{ uploadLabel }}</h5>
          </div>
        </div>
        <div class="entry_modal_ctn">
          <table class="common_table">
            <tbody>
              <tr>
                <th>清掃日</th>
                <td><input type="date" :min="waxDateMin" :max="waxDateMax" v-model="form.wax_date"> 2025年10月24日（火）から11月4日（土）のみ選択可能
                <p class="alert" v-if="errors.wax_date">{{ errors.wax_date }}</p></td>
              </tr>
              <tr>
                <th>ワックス清掃面積</th>
                <td><input name="floor_space" type="number" class="quarter" v-model="form.floor_space"> m<sup>2</sup>
                <p class="alert" v-if="errors.floor_space">{{ errors.floor_space }}</p></td>
              </tr>
              <tr>
                <th>メンテナンス清掃 <br>
                  （m²x{{maintenanceUnitCost}}円（消費税込））</th>
                <td>
                  <ul class="common_list_vertical_narrow">
                    <li v-for="(v, k) in maintenanceOptions" :key="k"><label><input type="radio" name="maintenance_div"
                      v-model="form.maintenance_div" :value="v" :checked="form.maintenance_div === v"><span
                      class="radio_text">{{ v }}</span></label></li>
                  </ul>
                  <p class="alert" v-if="errors.maintenance_div">{{ errors.maintenance_div }}</p></td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="button_ctn">
          <ul>
            <li>
              <button @click.prevent="modalClose" class="gray_button modalClose">キャンセル</button>
            </li>
            <li>
              <button @click="sendForm" class="regist_button">
                上記内容で登録
              </button>
            </li>
          </ul>
        </div>
        <!-- modal contents ここまで-->
      </div>
    </div>
    <!-- / モーダルコンテンツ -->
  </Teleport>
</template>

<script setup>
import { reactive, computed } from 'vue'
import APIClient from '@/store/repository/APIClient'
import { forEach, cloneDeep } from 'lodash'
import { FormValidate } from '@/helper/FormValidate'
import * as yup from 'yup'
import moment from "moment"

import {
  modalOpen,
  modalClose,
} from '@/helper/modal'

import ApiConst from '@/const/api'

import { isShowable as isShowUI } from '@/helper/indexStatus'

const maintenanceOptions = ['希望する', '希望しない']

const unitCost = 715
const maintenanceUnitCost = 385

const waxDateStart = moment('2025-10-24')
const waxDateEnd = moment('2025-11-04')
const waxDateMin = waxDateStart.format("YYYY-MM-DD")
const waxDateMax = waxDateEnd.format("YYYY-MM-DD")

const api = new APIClient()

const list = reactive([])

const formInit = {
  application_id: null,
  wax_date: "",
  floor_space: "",
  maintenance_div: "",
}
const form = reactive(cloneDeep(formInit))

const calcPriceByList = (val) => {
  return val * unitCost
    + (val.maintenance_div === '希望する' ? maintenanceUnitCost : 0)
}

const uploadLabel = computed(() => {
  return form.application_id ? '変更' : '新規登録'
})

const fetch = async () => {
  const query = {
    form_no: 4,
    application_div: "cleaning",
  }
  const fetchApi = await api.get(ApiConst.API_APP_LIST, query)
  const res = fetchApi.response
  const errors = res.data?.error_results
  if (errors) {
    throw new Error('Not found.')
  }
  list.splice(0)
  list.value = res.data.results.list.map((el) => {
    return {
      application_id: el.application_id,
      input_date: moment(el.updated_at).format("YY-MM-DD"),
      seq_no: el.seq_no,
      ...el.json_data
    }
  }).forEach((val, key) => {
    forEach(val, (v, k) => {
      val[k] = v ?? formInit[k]
    })
    list[key] = val
  })
}
fetch()

const newHandler = (event) => {
  forEach(formInit, (v, k) => {
    form[k] = v
  })
  modalOpen(event)
}

const editHandler = (event, key) => {
  forEach(form, (v, k) => {
    form[k] = list[key][k]
  })
  modalOpen(event)
}

const deleteHandler = async (event, key) => {
  if (!window.confirm('削除して宜しいですか？')) return false

  try {
    const application_id = list[key].application_id
    const operation = "D"
    const query = { operation, application_id }
    const res = await api.post(ApiConst.API_APP_EDIT, query)
    if (res.response.data?.error_results) {
      throw new Error('API Validation Error')
    }
    fetch()
  } catch (e) {
    return false
  }
}

const scheme = {
  wax_date: yup.date().nullable().typeError('正しい日付を入力してください')
  .min(waxDateStart, '2025年10月24日（火）から選択可能です。')
  .max(waxDateEnd, '2025年11月4日（土）まで選択可能です。'),
  floor_space: yup.number().typeError("必須の項目です。半角数字を入力してください。").required('必須の項目です。'),
  maintenance_div: yup.string().trim().required('必須の項目です。'),
}
const validate = new FormValidate(scheme, form)
const errors = validate.errors
const sendForm = async () => {
  try {
    if ((Object.keys(await validate.getErrorObject()).length)) {
      throw new Error('入力項目に不備があります。')
    }
    const params = cloneDeep(form)
    params.total_price = calcPriceByList(params.floor_space)
    const application_id = params.application_id
    delete params.application_id
    const operation = application_id ? "R" : "I"
    const query = operation === "I"
      ? { operation, application_div: "cleaning", form_no: 4, json_data: params }
      : { operation, application_id, json_data: params }
    const res = await api.post(ApiConst.API_APP_EDIT, query)
    if (res.response.data?.error_results) {
      throw new Error('API Validation Error')
    }
    fetch()
    modalClose()
  } catch (e) {
    return false
  }
}
</script>

<style></style>
