<template>
  <header>
    <h1>JAPAN MOBILITY SHOW 2025 <br class="pc_none" />出展者登録サイト</h1>
  </header>
  <article id="p2">
    <PhaseHeader />
    <div id="app">
      <div id="form_ctn" class="form_box">

        <PhaseNav :current="2" :category="3" :categoryTop="true" pageName="入場及び招待者用品" />

        <div id="form_ctn" class="form_box">
          <h3>入場及び招待者用品</h3>

          <!-- オフィシャルデー招待状の申込 / -->
          <div class="entry_ctn" v-if="isShow('p3_ticket_event_participation')">
            <h4>オフィシャルデー招待状の申込</h4>

            <p>オフィシャルデー（10月26日（木）13:30以降の入場者登録）</p>

            <div class="status_clocing_ctn">
              <DisplayIndexStatus
                name="p3_ticket_event_participation"
              />
              <!--管理画面で設定↓-->
              <div class="closing_day">締切：9月1日(金)</div>
            </div>
            <!-- 登録期間が終わっても閲覧可能にする-->
            <DeadlineButton
              name="p3_ticket_event_participation"
            />
            <div class="contact_list_link"><a @click.prevent="modalOpen" href="#contact_tbs" class="modalOpen">お問い合わせ先</a>
            </div>
          </div>
          <!-- / オフィシャルデー招待状の申込 -->

          <!-- 出展者入門証追加[規程2-15]・出展関係業者バッジ[規程5-1]申込 / -->
          <div class="entry_ctn" v-if="isShow('p3_ticket_entrance_ticket')">
            <h4>出展者入門証追加[規程2-15]・出展関係業者バッジ[規程5-1]申込</h4>
            <p>無償配布以外の出展者入門証、出展関係業者バッジの購入</p>
            <div class="status_clocing_ctn">
              <DisplayIndexStatus
                name="p3_ticket_entrance_ticket"
              />
              <!--管理画面で設定↓-->
              <div class="closing_day">締切：10月6日(金)</div>
            </div>
            <ul class="status_option">
              <li>合算料金 ¥{{ calcEntranceTicket }}</li>
            </ul>
            <!-- 登録期間が終わっても閲覧可能にする-->
            <DeadlineButton
              name="p3_ticket_entrance_ticket"
            />
            <div class="contact_list_link"><a @click.prevent="modalOpen" href="#contact_tbs" class="modalOpen">お問い合わせ先</a>
            </div>
          </div>
          <!-- / 出展者入門証追加[規程2-15]・出展関係業者バッジ[規程5-1]申込 -->

          <!-- 一般入場券・ 自動車ガイドブック(Vol.70)引換券・ 封筒申込 / -->
          <div class="entry_ctn" v-if="isShow('p3_ticket_normal_ticket')">
            <h4>一般入場券・ 自動車ガイドブック(Vol.70)引換券・ 封筒申込</h4>
            <p>出展者価格の前売り入場券、自動車ガイドブック引換券(ショー会場内のみ利用可能)を購入する場合</p>
            <div class="status_clocing_ctn">
              <DisplayIndexStatus
                name="p3_ticket_normal_ticket"
              />
              <!--管理画面で設定↓-->
              <div class="closing_day">締切：10月24日(火)</div>
            </div>
            <ul class="status_option">
              <li>料金合計 ¥{{ calcNormalTicket }}</li>
            </ul>
            <!-- 登録期間が終わっても閲覧可能にする-->
            <DeadlineButton
              name="p3_ticket_normal_ticket"
            />
            <div class="contact_list_link"><a @click.prevent="modalOpen" href="#contact_tbs" class="modalOpen">お問い合わせ先</a>
            </div>
          </div>
          <!-- / 一般入場券・ 自動車ガイドブック(Vol.70)引換券・ 封筒申込 -->

          <!-- 自動車ガイドブック(Vol.70)現物申込 / -->
          <div class="entry_ctn" v-if="isShow('p3_ticket_guidebook_order')">
            <h4>自動車ガイドブック(Vol.70)現物申込</h4>
            <p>自動車ガイドブック（Vol.70）現物の購入</p>
            <div class="status_clocing_ctn">
              <DisplayIndexStatus
                name="p3_ticket_guidebook_order"
              />
              <!--管理画面で設定↓-->
              <div class="closing_day">締切：10月24日(火)</div>
            </div>
            <ul class="status_option">
              <li>料金合計 ¥{{ calcGuidebookOrder }}</li>
            </ul>
            <!-- 登録期間が終わっても閲覧可能にする-->
            <DeadlineButton
              name="p3_ticket_guidebook_order"
            />
            <div class="contact_list_link"><a @click.prevent="modalOpen" href="#contact_toppan" class="modalOpen">お問い合わせ先</a></div>
          </div>
          <!-- / 自動車ガイドブック(Vol.70)現物申込 -->

          <!-- 地下駐車場パスカード申込 / -->
          <div class="entry_ctn" v-if="isShow('p3_ticket_parking_pass')">
            <h4>地下駐車場パスカード申込</h4>
            <p>東展示棟の地下駐車場を利用できる日付指定の駐車券の購入</p>
            <div class="status_clocing_ctn">
              <DisplayIndexStatus
                name="p3_ticket_parking_pass"
              />
              <!--管理画面で設定↓-->
              <div class="closing_day">締切：9月22日(金)</div>
            </div>
            <ul class="status_option">
              <li>料金合計 ¥{{ calcParkingCard }}</li>
            </ul>
            <!-- 登録期間が終わっても閲覧可能にする-->
            <DeadlineButton
              name="p3_ticket_parking_pass"
            />
            <div class="contact_list_link"><a @click.prevent="modalOpen" href="#contact_tbs" class="modalOpen">お問い合わせ先</a></div>
          </div>
          <!-- / 地下駐車場パスカード申込 -->
        </div>
      </div>

      <ModalTokyoBigSight :isButton="false" />
      <ModalToppan :isButton="false" />
      <ModalEdenred :isButton="false" />
    </div>
  </article>
</template>


<script setup>
import { ref, computed } from "vue"
import { sum } from 'lodash'
import PhaseHeader from '@/components/PhaseHeader'
import PhaseNav from '@/components/PhaseNav'
import DeadlineButton from './IndexStatus/DeadlineButton'
import DisplayIndexStatus from './IndexStatus/DisplayIndexStatus'
import ModalTokyoBigSight from "@/components/modal/ModalTokyoBigSight"
import ModalToppan from "@/components/modal/ModalToppan"
import { getIndexStatus } from '@/helper/indexStatus'
import PARKING_CARD from '@/const/parking_card'

import {
  modalOpen,
} from '@/helper/modal'

import { isShowByStaffAuthority } from "@/helper/staffAuthority"

const statusList = ref({})
;(async () => {
  statusList.value = await getIndexStatus()
})()

const calcEntranceTicket = computed(() => {
  const statusOther = statusList.value.entrance_ticket?.status_other
  const total = sum([
    (statusOther?.total_price_1 ?? 0),
    (statusOther?.total_price_2 ?? 0),
  ])
  return total.toLocaleString()
})

const calcNormalTicket = computed(() => {
  const statusOther = statusList.value.normal_ticket?.status_other
  const total = statusOther?.total_price ?? 0
  return total.toLocaleString()
})

const calcGuidebookOrder = computed(() => {
  const statusOther = statusList.value.guidebook_order?.status_other
  const total = statusOther?.total_price ?? 0
  return total.toLocaleString()
})

const calcParkingCard = computed(() => {
  const statusOther = statusList.value.parking_card?.status_other
  const total = (statusOther?.total_qty ?? 0 ) * PARKING_CARD.UNIT_COST
  return total.toLocaleString()
})

const isShow = (name) => {
  return isShowByStaffAuthority(name)
}
</script>

<style></style>
