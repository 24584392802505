<template>
  <input
    :type="type"
    :class="width_class"
    :value="modelValue"
    :placeholder="placeholder"
    @input="updateValue"
    :disabled="disabled"
  />
</template>

<script>
export default {
  name: "MyInput",
  props: {
    modelValue: { type: String, required: true },
    type: { type: String, required: true },
    placeholder: { type: String, required: false },
    //emits: ["update:modelValue", "change"],
    disabled: {
      type: Boolean,
      default: false,
      required: false
    },
    width_class: {
      type: String,
      required: false
    }
  },
  methods: {
    updateValue: function(e) {
      this.$emit('update:modelValue', e.target.value)
    }
  },
};
</script>