<template>
  <table class="common_table">
    <tbody>
      <tr>
        <th>給水管サイズ</th>
        <th>給水基本設備工事費（単価・消費税込）</th>
        <th>申込数量</th>
        <th>計</th>
        <th>用途</th>
      </tr>

      <tr v-for="(val, key) in sizes" :key="key">
        <td>{{ val.label }}</td>
        <td class="ta_right">{{ val.cost.toLocaleString() }}円</td>
        <td class="ta_right">{{ currentData[val.index].toLocaleString() }} 本</td>
        <td class="ta_right">{{ calcPriceByUnitCost(currentData[val.index], val.cost).toLocaleString() }} 円</td>
        <td>{{ currentData[val.index + '_purpose'] }}</td>
      </tr>

      <tr>
        <td colspan="5"></td>
      </tr>
      <tr>
        <td colspan="2" align="center">申込本数</td>
        <td class="ta_right">{{ calcCurrentTotalQty.toLocaleString() }} 本</td>
        <td colspan="2">合計金額（消費税込）
          <span class="ta_right">{{ calcCurrentTotalPrice.toLocaleString() }} 円</span>
        </td>
      </tr>
    </tbody>
  </table>
  <template v-if="isShowUI">
  <div><a v-if="isNew" @click.prevent="newHandler" href="#form1" class="common_add_button modalOpen">新規追加</a><a v-else
      @click.prevent="editHandler" href="#form1" class="common_add_button modalOpen">変更</a>&nbsp;&nbsp;<a
      @click.prevent="deleteHandler" href="#form1" class="common_add_button gray_button">クリア</a></div>
  </template>
  <Teleport to="body">
    <!-- モーダルコンテンツ / -->
    <div class="overlay" id="form1">
      <div id="modal_content" class="modal_contents w80">
        <div class="modalClose_ctn"><a @click.prevent="modalClose" class="modalClose"></a></div>
        <!-- modal contents ここから-->
        <div class="entry_title">
          <h4>給水使用申込</h4>
          <h5>引込給水管サイズ及び用途&nbsp;&nbsp;{{ uploadLabel }}</h5>

          <div class="entry_modal_ctn">
            <table class="common_table">
              <tbody>
                <tr>
                  <th>給水管サイズ</th>
                  <th>給水基本設備工事費<br>
                    （単価・消費税込）</th>
                  <th>申込数量</th>
                  <th>計</th>
                  <th>用途</th>
                </tr>
                <tr v-for="(val, key) in sizes" :key="key">
                  <td>{{ val.label }}</td>
                  <td class="ta_right">{{ val.cost.toLocaleString() }}円</td>
                  <td class="ta_right"><input v-model="form[val.index]" type="number" size="5" min="0"
                      style="ime-mode: disabled; " class="three_quarters ta_right"> 本
                    <p class="alert" v-if="errors[val.index]">{{ errors[val.index] }}</p>
                  </td>
                  <td class="ta_right">{{ calcPriceByUnitCost(form[val.index], val.cost).toLocaleString() }} 円</td>
                  <td><input v-model="form[val.index + '_purpose']" type="text" class="w15em">
                    <p class="alert" v-if="errors[val.index + '_purpose']">{{ errors[val.index + '_purpose'] }}</p></td>
                </tr>

                <tr>
                  <td colspan="5">
                    <p class="alert" v-if="errors.phi_all">{{ errors.phi_all }}</p>
                  </td>
                </tr>
                <tr>
                  <td colspan="2" align="center">申込本数</td>
                  <td class="ta_right">{{ calcTotalQty.toLocaleString() }} 本</td>
                  <td colspan="2">合計金額（消費税込）
                    <span class="ta_right">{{ calcTotalPrice.toLocaleString() }} 円</span>
                  </td>
                </tr>
              </tbody>
            </table>


            <div class="button_ctn">
              <ul>
                <li><button @click="modalClose" class="gray_button modalClose">キャンセル</button></li>
                <li><button @click="sendForm" class="regist_button">上記内容で登録</button></li>
              </ul>
            </div>
            <!-- modal contents ここまで-->
          </div>
        </div>
        <!-- / モーダルコンテンツ -->
      </div>
    </div>
    <!-- / モーダルコンテンツ -->
  </Teleport>
</template>

<script setup>
import { reactive, computed } from 'vue'
import { sum, forEach, cloneDeep } from 'lodash'

import APIClient from '@/store/repository/APIClient'
import * as yup from 'yup'
import moment from "moment"
import { FormValidate } from '@/helper/FormValidate'
import SANITARY_REGIST from '@/const/sanitary_regist'

import {
  modalOpen,
  modalClose,
} from '@/helper/modal'

import ApiConst from '@/const/api'

import { isShowable as isShowUI } from '@/helper/indexStatus'

const sizes = SANITARY_REGIST.SIZES

const api = new APIClient()

const formInit = {
  application_id: null,
  phi13: 0,
  phi20: 0,
  phi25: 0,
  phi32: 0,
  phi13_purpose: '',
  phi20_purpose: '',
  phi25_purpose: '',
  phi32_purpose: '',
}

const form = reactive(cloneDeep(formInit))
const currentData = reactive(cloneDeep(formInit))

const calcTotalQty = computed(() => {
  return sum([
    form.phi13,
    form.phi20,
    form.phi25,
    form.phi32,
  ])
})

const calcTotalPrice = computed(() => {
  return sum([
    form.phi13 * sizes.find(el => el.index === 'phi13').cost,
    form.phi20 * sizes.find(el => el.index === 'phi20').cost,
    form.phi25 * sizes.find(el => el.index === 'phi25').cost,
    form.phi32 * sizes.find(el => el.index === 'phi32').cost,
  ])
})

const calcCurrentTotalQty = computed(() => {
  return sum([
    currentData.phi13,
    currentData.phi20,
    currentData.phi25,
    currentData.phi32,
  ])
})

const calcCurrentTotalPrice = computed(() => {
  return sum([
    currentData.phi13 * sizes.find(el => el.index === 'phi13').cost,
    currentData.phi20 * sizes.find(el => el.index === 'phi20').cost,
    currentData.phi25 * sizes.find(el => el.index === 'phi25').cost,
    currentData.phi32 * sizes.find(el => el.index === 'phi32').cost,
  ])
})

const calcPriceByUnitCost = (qty, cost) => {
  return Number(qty) * Number(cost)
}

const isNew = computed(() => {
  return !currentData.application_id
})

const uploadLabel = computed(() => {
  return !isNew.value ? '変更' : '新規登録'
})

const fetch = async () => {
  try {
    const query = {
      form_no: 1,
      application_div: "sanitary_regist",
    }
    const fetchApi = await api.get(ApiConst.API_APP_LIST, query)
    const res = fetchApi.response
    const errors = res.data?.error_results
    if (errors) {
      throw new Error('Not found.')
    }
    forEach(currentData, (v, k) => {
      currentData[k] = formInit[k]
    })
    const data = res.data.results.list.map((el) => {
      return {
        application_id: el.application_id,
        input_date: moment(el.updated_at).format("YY-MM-DD"),
        seq_no: el.seq_no,
        ...el.json_data
      }
    })[0] ?? false
    if (!data) return

    forEach(currentData, (v, k) => {
      currentData[k] = data[k]
    })
    forEach(form, (v, k) => {
      form[k] = data[k] ?? formInit[k]
    })
  } catch (e) {
    console.log(e)
    return false
  }
}
fetch()

const newHandler = (event) => {
  forEach(formInit, (v, k) => {
    form[k] = v
  })
  modalOpen(event)
}

const editHandler = (event) => {
  modalOpen(event)
}

const deleteHandler = async () => {
  if (!window.confirm('クリアして宜しいでしょうか？')) return false

  try {
    const application_id = currentData.application_id
    const operation = "D"
    const query = { operation, application_id }
    const res = await api.post(ApiConst.API_APP_EDIT, query)
    if (res.response.data?.error_results) {
      throw new Error('API Validation Error')
    }
    fetch()
  } catch (e) {
    return false
  }
}

const scheme = {
  phi13: yup.number().typeError('数値を入力してください。'),
  phi20: yup.number().typeError('数値を入力してください。'),
  phi25: yup.number().typeError('数値を入力してください。'),
  phi32: yup.number().typeError('数値を入力してください。'),
  phi13_purpose: yup.string().trim().test('phi13_purpose', '用途を記入してください。', function(v) {
    if (this.parent.phi13 <= 0) return true
    if (v !== '') return true
    return false
  }),
  phi20_purpose: yup.string().trim().test('phi20_purpose', '用途を記入してください。', function(v) {
    if (this.parent.phi20 <= 0) return true
    if (v !== '') return true
    return false
  }),
  phi25_purpose: yup.string().trim().test('phi25_purpose', '用途を記入してください。', function(v) {
    if (this.parent.phi25 <= 0) return true
    if (v !== '') return true
    return false
  }),
  phi32_purpose: yup.string().trim().test('phi32_purpose', '用途を記入してください。', function(v) {
    if (this.parent.phi32 <= 0) return true
    if (v !== '') return true
    return false
  }),
  phi_all: yup.string().trim().test('phi_all', '申込数量を入力してください。', function() {
    if (
      this.parent.phi13
      ||
      this.parent.phi20
      ||
      this.parent.phi25
      ||
      this.parent.phi32
    ) return true
    return false
  }),
}
const validate = new FormValidate(scheme, form)
const errors = validate.errors
const sendForm = async () => {
  try {
    if ((Object.keys(await validate.getErrorObject()).length)) {
      throw new Error('入力項目に不備があります。')
    }
    const params = cloneDeep(form)
    params.phi13_total_price = calcPriceByUnitCost(params.phi13, sizes.find(el => el.index === "phi13").cost)
    params.phi20_total_price = calcPriceByUnitCost(params.phi20, sizes.find(el => el.index === "phi20").cost)
    params.phi25_total_price = calcPriceByUnitCost(params.phi25, sizes.find(el => el.index === "phi25").cost)
    params.phi32_total_price = calcPriceByUnitCost(params.phi32, sizes.find(el => el.index === "phi32").cost)
    params.total_price = calcTotalPrice.value

    const application_id = params.application_id
    delete params.application_id
    const operation = application_id ? "R" : "I"
    const query = operation === "I"
      ? { operation, application_div: "sanitary_regist", form_no: 1, json_data: params }
      : { operation, application_id, json_data: params }
    const res = await api.post(ApiConst.API_APP_EDIT, query)
    if (res.response.data?.error_results) {
      throw new Error('API Validation Error')
    }
    fetch()
    modalClose()
  } catch (e) {
    return false
  }
}
</script>

<style></style>
