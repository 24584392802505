<template>
  <div id="admin">
    <header>
      <h1>JMS 2025 出展者登録DB 管理者用ページ</h1>
    </header>
    <article>
    

      <h2>出展情報の一括入力(検索条件)</h2>
      <div id="form_ctn" class="form_box">
        <h3>出展者の検索</h3>
        <table class="common_table">
          <tbody>
            <tr>
              <th>会社名</th>
              <td>
                <my-input name="exhibitor_name" width_class="full" type="text" v-model="exhibitor_name"></my-input>

              </td>
            </tr>
            <tr>
              <th>小間番号</th>
              <td>
                <my-input name="koma_bango" width_class="quarter" type="text" v-model="koma_bango"></my-input>

              </td>
            </tr>
            <tr>
              <th>フラグ</th>
              <td>
                <ul class="common_list_horizontal_iblock">
                  <li>
                    <label>
                      <input type="checkbox" :name="cancel_flag" v-model="cancel_flag_model" />
                      <span class="checkbox_text">キャンセル</span>
                    </label>
                  </li>
                  <li v-if="enableTestFlag()">
                    <label>
                      <input type="checkbox" :name="test_flag" v-model="test_flag_model" />
                      <span class="checkbox_text">テストを含む</span>
                    </label>
                  </li>
                </ul>

              </td>
            </tr>
            <tr>
              <th>会員区分</th>
              <td>
                <ul class="common_list_horizontal_iblock">
                  <my-checkbox v-model="membership_category" :options="membership_category_options"></my-checkbox>

                </ul>

              </td>
            </tr>

            <tr>
              <th>出展カテゴリー</th>
              <td>
                <ul class="common_list_horizontal_iblock">
                  <my-checkbox v-model="category_div" name="category_div" :options="category_div_options"></my-checkbox>

                </ul>
              </td>
            </tr>
          </tbody>
        </table>

        <div class="button_ctn">
          <button class="submit" @click="sendForm">検索</button>
        </div>
      </div>


      <div class="button_ctn">
        <ul>
          <li><router-link :to="{ name: 'AdminIndex' }">メニュー画面に戻る</router-link>
          </li>
        </ul>
      </div>
    </article>
  </div>
</template>

<script>

import APIClient from '@/store/repository/APIClient'
import MyInput from '@/components/MyInput.vue';
import MyCheckbox from '@/components/MyCheckbox.vue';
import { enableTestFlag } from '@/helper/adminAuthority';


export default {
  components: {
    MyInput,
    MyCheckbox,
  },
  setup() {


    return {
      enableTestFlag,
    };
  },

  data() {
    return {
      exhibitor_name: '',
      cancel_flag_model: false,
      test_flag_model: false,
      koma_bango: '',
      membership_category: '',
      category_div: '',
    }
  },
  async created() {
    this.api = new APIClient()
    const res = await this.api.getConfig()
    this.$store.commit('disicion/membership_category_options_admin', res.data.results.membership_category_options_admin)
    this.$store.commit('disicion/past_achievement_options', res.data.results.past_achievement_options)
    this.$store.commit('disicion/category_div_options', res.data.results.category_div_options)
    this.$store.commit('admin/second_order_status_options', res.data.results.second_order_status_options)
  },
  mounted() {

  },
  methods: {
    async sendForm() {
      try {
        const query = {}
        if (this.exhibitor_name) query.exhibitor_name = this.exhibitor_name
        if (this.cancel_flag) query.cancel_flag = this.cancel_flag
        if (this.test_flag) query.test_flag = this.test_flag
        if (this.koma_bango) query.koma_bango = this.koma_bango
        if (this.membership_category) query.membership_category = this.membership_category.join(',')
        if (this.category_div) query.category_div1 = this.category_div.join(',')

        const res = await this.api.getAdminSecondList(query)
        if (res.data.error_results) {
          throw new Error('API Validation Error')
        }
        this.$store.commit('search/admin_second_list', res.data.results.list)
      } catch (e) {
        console.error(e.message)
        return false
      }

      this.$router.push('/admin/second_multiple_list');
    }
  },
  watch: {


  },
  computed: {
    exhibit_participation_status_options() {
      return this.$store.state.disicion.exhibit_participation_status_options
    },
    membership_category_options() {
      return this.$store.state.disicion.membership_category_options_admin
    },
    category_div_options() {
      return this.$store.state.disicion.category_div_options
    },
    cancel_flag() {
      return this.cancel_flag_model ? 1 : 0
    },
    test_flag() {
      return this.test_flag_model ? 1 : 0
    },
    admin_second_list() {
      return this.$store.state.search.admin_second_list
    }
  }
};
</script>

<style>

</style>
