<template>
  <header>
    <h1>JAPAN MOBILITY SHOW 2025&emsp;出展申込サイト</h1>
  </header>
  <article>
    <h2>一次申込の受付</h2>
    <div id="form_ctn" class="form_box">

      <div id="complete_text">
        <h3>お申し込みありがとうございました。</h3>
        <p>御社ご出展の申込みにあたり、一次申込を受け付け致しました。<br>
          申込みの内容を審査の上、出展基準を満たした場合は<br>
          二次申込のご案内をメールにてご送付致します。<br>
          審査には数日のお時間をいただく場合もございます。</p>

        <p>一般社団法人&emsp;日本自動車工業会<br>
          次世代モビリティ領域・ショー担当
        </p>

      </div>


    </div>
  </article>
</template>

<script>

</script>