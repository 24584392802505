<template>
  <header>
    <h1>JAPAN MOBILITY SHOW 2025 <br class="pc_none" />出展者登録サイト</h1>
  </header>
  <article id="p2">
    <PhaseHeader />
    <div id="app">
      <div id="form_ctn" class="form_box">

        <PhaseNav
          :current="2"
          :category="1"
          :categoryTop="true"
          pageName="広報資料関係"
        />

        <div id="form_ctn" class="form_box">
          <h3>広報・宣伝関係</h3>

          <!-- 出展者一覧 / -->
          <div class="entry_ctn" v-if="isShow('p3_publicity_exhibitors_list')">
            <p class="required_entry">全出展者必須の提出書類</p>
            <h4>出展者一覧</h4>
            <p>出展者名の確認とリンク先URLの入力</p>
            <div class="status_clocing_ctn">
              <DisplayIndexStatus
                name="p3_publicity_exhibitors_list"
              />
              <!--手入力↓-->
              <div class="closing_day">締切：9月13日(水)</div>
            </div>
            <!-- 登録期間が終わっても閲覧可能にする-->
            <DeadlineButton
              name="p3_publicity_exhibitors_list"
            />
            <div class="contact_list_link"><a @click.prevent="modalOpen" href="#contact_jama"
                class="contact_list_box">お問い合わせ先</a></div>
          </div>
          <!-- / 出展者一覧 -->

          <!-- 出展車両数 / -->
          <div class="entry_ctn" v-if="isShow('p3_publicity_num_exhibits')">
            <p class="required_entry">全出展者必須の提出書類</p>
            <h4>出展車両数</h4>
            <p>出展車両数（含、ワールドプレミア・ジャパンプレミア車両数）の入力</p>
            <!--
            <table class="common_table">
              <tbody>
                <tr>
                  <td>9月7日(木)</td>
                  <td>一次締切</td>
                  <td>出展車両数一覧（WP/JP数）を入力してください。</td>
                </tr>
                <tr>
                  <td style="padding-left: 20px;">→9月21日(木)</td>
                  <td style="padding-left: 30px;">記者発表</td>
                  <td>9月26日に予定している記者発表にて出展台数・WP/JP数を発表予定</td>
                </tr>
              </tbody>
            </table>
            -->
            <div class="status_clocing_ctn">
              <DisplayIndexStatus
                name="p3_publicity_num_exhibits"
              />
              <!--手入力↓-->
              <div class="closing_day">締切：9月13日(水)</div>
            </div>
            <!-- 登録期間が終わっても閲覧可能にする-->
            <DeadlineButton
              name="p3_publicity_num_exhibits"
            />
            <div class="contact_list_link"><a @click.prevent="modalOpen" href="#contact_jama" class="contact_list_box">お問い合わせ先</a></div>
          </div>
          <!-- / 出展車両数 -->

          <!-- ブース情報 / -->
          <div class="entry_ctn" v-if="isShow('p3_publicity_booth_info')">
            <p class="required_entry">全出展者必須の提出書類</p>
            <h4>ブース情報</h4>
            <p>見どころ・ステージイベントの紹介</p>
            <!--
            <table class="common_table">
              <tbody>
                <tr>
                  <td>10月15日(火)</td>
                  <td>最終締切</td>
                  <td>出展物や各社（ブース）の見どころなど全てを入力ください。</td>
                </tr>
                <tr>
                  <td style="padding-left: 20px;">→10月23日(水)</td>
                  <td style="padding-left: 30px;">プレスデー初日</td>
                  <td>10月23日のプレスデー（一日目）に、東京モーターショー公式webサイトにて公開予定</td>
                </tr>
              </tbody>
            </table>
            -->
            <div class="status_clocing_ctn">
              <DisplayIndexStatus
                name="p3_publicity_booth_info"
              />
              <!--手入力↓-->
              <div class="closing_day">締切：10月18日(水)</div>
            </div>
            <!-- 登録期間が終わっても閲覧可能にする-->
            <DeadlineButton
              name="p3_publicity_booth_info"
            />
            <div class="contact_list_link"><a @click.prevent="modalOpen" href="#contact_jama" class="contact_list_box">お問い合わせ先</a></div>
          </div>
          <!-- / ブース情報 -->

          <!-- 出展物リスト / -->
          <div class="entry_ctn" v-if="isShow('p3_publicity_exhibits_list')">
            <p class="required_entry">全出展者必須の提出書類</p>
            <h4>出展物リスト</h4>
            <p>出展物名、カテゴリー・区分、画像・動画、リンク先URL、エネルギーソース、特徴等の入力</p>
            <!--
            <table class="common_table">
              <tbody>
                <tr>
                  <td>9月13日(金)</td>
                  <td>一次締切</td>
                  <td>出展車両数一覧（WP/JP数）を入力してください。</td>
                </tr>
                <tr>
                  <td style="padding-left: 20px">→9月26日(木)</td>
                  <td style="padding-left: 30px">記者発表</td>
                  <td>
                    9月26日に予定している記者発表にて出展台数・WP/JP数を発表予定
                  </td>
                </tr>
                <tr>
                  <td>10月15日(火)</td>
                  <td>最終締切</td>
                  <td>
                    出展物や各社（ブース）の見どころなど全てを入力ください。
                  </td>
                </tr>
                <tr>
                  <td style="padding-left: 20px">→10月23日(水)</td>
                  <td style="padding-left: 30px">プレスデー初日</td>
                  <td>
                    10月23日のプレスデー（一日目）に、東京モーターショー公式webサイトにて公開予定
                  </td>
                </tr>
              </tbody>
            </table>
            -->
            <div class="status_clocing_ctn">
              <DisplayIndexStatus
                name="p3_publicity_exhibits_list"
              />
              <!--管理画面で設定↓-->
              <div class="closing_day">締切：10月18日(水)</div>
            </div>
            <!-- 登録期間が終わっても閲覧可能にする-->
            <DeadlineButton
              name="p3_publicity_exhibits_list"
            />
            <div class="contact_list_link">
              <a @click.prevent="modalOpen" href="#contact_jama" class="contact_list_box">お問い合わせ先</a>
            </div>
          </div>
          <!-- / 出展物リスト -->

          <!-- プレスリリースリンク掲出登録 / -->
          <div class="entry_ctn" v-if="isShow('p3_publicity_pressrelease_link')">
            <!--<p class="required_entry">全出展者必須の提出書類</p>-->
            <h4>プレスリリースリンク掲出登録</h4>
            <p>本ショーWebサイトとのリンクを希望する場合（無料）</p>
            <div class="status_clocing_ctn">
              <DisplayIndexStatus
                name="p3_publicity_pressrelease_link"
              />
              <!--管理画面で設定↓-->
              <div class="closing_day">締切：11月5日(日)</div>
            </div>
            <!-- 登録期間が終わっても閲覧可能にする-->
            <DeadlineButton
              name="p3_publicity_pressrelease_link"
            />
            <div class="contact_list_link">
              <a @click.prevent="modalOpen" href="#contact_jama" class="contact_list_box">お問い合わせ先</a>
            </div>
          </div>
          <!-- / プレスリリースリンク掲出登録 -->
        </div>
      </div>

      <ModalJAMA :isButton="false" />
    </div>
  </article>
</template>


<script setup>
import PhaseHeader from '@/components/PhaseHeader'
import PhaseNav from '@/components/PhaseNav'
import DeadlineButton from './IndexStatus/DeadlineButton'
import DisplayIndexStatus from './IndexStatus/DisplayIndexStatus'
import ModalJAMA from "@/components/modal/ModalJAMA"
import {
  modalOpen,
} from '@/helper/modal'

import { isShowByStaffAuthority } from "@/helper/staffAuthority"

const isShow = (name) => {
  return isShowByStaffAuthority(name)
}
</script>

<style></style>
