export default {
  namespaced: true,
  state: {
    current_edit_subject: {},
  },
  mutations: {
    current_edit_subject(state, payload) {
      state.current_edit_subject = payload
    },
  },
  actions: {
    update({ commit }, results) {
      const test_flag = results.json_data?.test_flag ?? 0
      const admin_application = results.json_data?.admin_application
      Object.keys(admin_application).forEach(key => {
        admin_application[key] = String(admin_application[key])
      })
      commit('current_edit_subject', {
        admin_id: results.admin_id?.toString() ?? '',
        admin_login_id: results.admin_login_id?.toString() ?? '',
        admin_name: results.json_data?.admin_name?.toString() ?? '',
        test_flag: parseInt(test_flag, 10),
        permission: results.json_data?.function_code_list?.map(String) ?? [],
        application: admin_application ?? {},
        fix_category: results.json_data?.fix_category_disp_list?.map(String) ?? [],
      })
    },
    reset({ commit }) {
      commit('current_edit_subject', {
        admin_id: '',
        admin_login_id: '',
        admin_name: '',
        test_flag: 0,
        permission: [],
        application: {},
        fix_category: [],
      })
    },
    put({ state, commit }, payload) {
      let object = state[payload.state]
      object[payload.key] = payload.value
      commit(payload.state, object)
    }
  },
}
