import APIClient from '@/store/repository/APIClient'
const api = new APIClient()

const getMemberShipCategoryRelatedData = async () => {
  const data = {}
  const queueList = []

  queueList.push(api.GetPreEntryDetail().then((res) => {
    data.membershipCategory = res.data.results.json_data.membership_category
  }))
  queueList.push(api.getConfig().then((res) => {
    data.membershipCategoryConfig = res.data.results.membership_category_options
  }))
  await Promise.all(queueList)
  return data
}

export const getPrice = async () => {
  const data = await getMemberShipCategoryRelatedData()
  const value = data.membershipCategoryConfig.find(el => el.label === '日本自動車工業会')?.value
  const category = data.membershipCategory
  const price = parseInt(value) === parseInt(category) ? 800 : 1100
  return price
}
