<template>
  <div id="admin">
    <header>
      <h1>JAIS 2025 出展者登録DB&nbsp;&nbsp;管理者用ページ</h1>
    </header>
    <article>
      <h2>通信通話料（超過分）の入力</h2>
      <div id="app">
        <div id="form_ctn" class="form_box">
          <ol class="breadcrumbs">
            <AdminNav :categoryTop="false" :category="3" pageName="通信通話料（超過分）の入力" />
          </ol>
          <h3>通信通話料（超過分）の入力</h3>

          <ErrorComponent></ErrorComponent>

          <table class="common_table">
            <thead>
              <tr>
                <th>小間番号</th>

                <th>出展者名</th>
                <th>通信通話料（超過分）</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(val, key) in list"
                :key="key"
              >
                <td>{{ val.koma_bango }}</td>
                <td>{{ val.exhibitor_name }}</td>
                <td><input v-model="form['overtime_cost_' + Number(key + 1)]" type="text" class="w10em">
                  円</td>
              </tr>
            </tbody>
            <tfoot>
            </tfoot>
          </table>
          <ul class="common_list_horizontal">
            <li><button class="common_button" @click="send" :disabled="sendDisabled">{{ sendLabel }}</button></li>
          </ul>
        </div>
      </div>
    </article>
  </div>
</template>

<script setup>
import { ref, reactive } from 'vue'
import ErrorComponent from '@/components/ErrorComponent.vue';

import ApiConst from '@/const/api'

import AdminNav from "@/components/AdminNav"
import { getQuery, fetchList, sendForm, sendDisabled, sendLabel } from '@/helper/adminDemandSearch'

const query = getQuery()

const list = ref([])

const form = reactive({
})


const fetch = () => {
  fetchList(ApiConst.API_ADMIN_OVERTIMENETWORK_LIST, query, list, form)
}
fetch()

const send = () => {
  sendForm( ApiConst.API_ADMIN_OVERTIMENETWORK_BULKEDIT, form, fetch)
}
</script>

<style></style>
