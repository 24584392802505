<template>
  <div class="billing_address_ctn" v-if="forms.length">
    <h4>登録済み請求先</h4>
    <ul class="billing_address_lists">
      <li class="billing_address_list" :class="(selectedBill === key ? 'on' : '')" v-for="(val, key) in storedList"
        :key="key">
        <div class="select_address">請求先{{ key + 1 }}</div>
        <div class="billing_address_innner_ctn">
          <h5 class="billing_address_title">会社情報</h5>

          <div class="corp_name">{{ val.company_name }}</div>
          <div class="address">
            {{ makeZip(val.company_zip) }}<br />{{ val.company_address1 }}{{ val.company_address2 }}
          </div>
          <h5 class="billing_address_title">担当者情報</h5>
          <div class="staff_post">{{ val.staff_affiliation }}&nbsp;&nbsp;{{ val.staff_post }}</div>
          <div class="staff_name">{{ val.staff_name }}</div>
          <div class="staff_tel">{{ val.staff_tel1 }}</div>
          <div class="staff_tel2">{{ val.staff_tel2 }}</div>
          <div class="staff_email">{{ val.staff_email }}</div>
          <ul class="button_list">
            <li @click="setForm(val.billing_id)" class="slide-down-btn">編集</li>
            <li @click="selectBill(val.billing_id)" class="select_list">この送り先に設定</li>
          </ul>
        </div>
      </li>
    </ul>
  </div>
  <div class="button_ctn">
    <ul>
      <li>
        <button @click="setForm" id="address_add_button" class="slide-down-btn">
          請求先を追加
        </button>
      </li>
    </ul>
  </div>
  <div class="billing_address_input_ctn" :style="toggleForm">
    <table class="common_table">
      <tbody>
        <tr>
          <th colspan="2" class="required">会社名</th>
          <td><input type="text" class="full" v-model="form.company_name" />
            <p class="alert" v-if="errors.company_name">{{ errors.company_name }}</p>
          </td>
        </tr>
        <tr>
          <th colspan="2" class="required">会社名カナ</th>
          <td><input type="text" class="full" v-model="form.company_name_kana" />
            <p class="alert" v-if="errors.company_name_kana">{{ errors.company_name_kana }}</p>
          </td>
        </tr>
        <tr>
          <th rowspan="3">所在地</th>
          <th class="required">郵便番号</th>
          <td>
            <input type="text" class="quarter" v-model="form.company_zip" @input="onZipInput" @keyup="onZipChange" />
            <p class="alert" v-if="errors.company_zip">{{ errors.company_zip }}</p>
          </td>
        </tr>

        <tr>
          <th class="required">
            住所1<!--（桁数制限は全角30桁、半角60桁）-->
          </th>
          <td>
            <input type="text" class="full" v-model="form.company_address1" />
            <p class="alert" v-if="errors.company_address1">{{ errors.company_address1 }}</p>
          </td>
        </tr>
        <tr>
          <th>住所2<!--（桁数制限は全角30桁、半角60桁）--></th>
          <td>
            <input name="delivery_worker_address" type="text" size="40" class="full"
              v-model="form.company_address2" />
            <p class="alert" v-if="errors.company_address2">{{ errors.company_address2 }}</p>
          </td>
        </tr>
        <tr>
          <th rowspan="6">担当者</th>
          <th class="required">所属</th>
          <td>
            <input name="delivery_quarter_name" type="text" class="quarter" v-model="form.staff_affiliation" />
            <p class="alert" v-if="errors.staff_affiliation">{{ errors.staff_affiliation }}</p>
          </td>
        </tr>
        <tr>
          <th>役職</th>
          <td>
            <input name="delivery_quarter_name" type="text" class="quarter" v-model="form.staff_post" />
            <p class="alert" v-if="errors.staff_post">{{ errors.staff_post }}</p>
          </td>
        </tr>
        <tr>
          <th class="required">氏名</th>
          <td>
            <input name="delivery_prepared_name" type="text" size="30" class="validate[required]"
              v-model="form.staff_name" />
            <p class="alert" v-if="errors.staff_name">{{ errors.staff_name }}</p>
          </td>
        </tr>
        <tr>
          <th class="required">電話番号</th>
          <td>
            <input type="text" name="delivery_worker_tel" class="validate[required]" v-model="form.staff_tel1" />
            <p class="alert" v-if="errors.staff_tel1">{{ errors.staff_tel1 }}</p>
          </td>
        </tr>
        <tr>
          <th>緊急連絡先</th>
          <td>
            <input type="text" name="delivery_worker_tel2" class="validate[required]" v-model="form.staff_tel2" />
            <p class="alert" v-if="errors.staff_tel2">{{ errors.staff_tel2 }}</p>
          </td>
        </tr>

        <tr>
          <th class="required">E-mail</th>
          <td>
            <input type="text" name="delivery_worker_email" class="validate[custom[email]]"
              v-model="form.staff_email" />
            <p class="alert" v-if="errors.staff_email">{{ errors.staff_email }}</p>
          </td>
        </tr>
        <tr>
          <th class="required" colspan="2">請求言語</th>
          <td>
            <ul class="common_list_horizontal">
              <li v-for="(v, k) in langLabels" :key="k">
                <label><input type="radio" name="building_language" v-model="form.lang" :value="k"
                    :checked="form.lang === k" /><span class="radio_text">{{ v }}</span></label>
              </li>
            </ul>
            <p class="alert" v-if="errors.lang">{{ errors.lang }}</p>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="button_ctn">
      <ul>
        <li>
          <button @click="cancelRegister" class="gray_button slide-up-btn">キャンセル</button>
        </li>

        <li>
          <button @click="register" class="slide-up-btn2">請求先を登録</button>
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup>
import APIClient from '@/store/repository/APIClient'
import { reactive, ref, computed, defineEmits, defineProps, watch, toRefs } from "vue"
import { mapValues, forEach, cloneDeep } from 'lodash'
import { FormValidate } from '@/helper/FormValidate'
import * as yup from 'yup'
import KenAll from 'ken-all'
import { isValue } from '@/helper/index';
import { store } from '@/store'

import {
  zipNumOnlyTest,
  makeZip,
  isKana,
} from '@/helper/index'

import ApiConst from '@/const/api'
import BillConst from '@/const/bill'

const emit = defineEmits(['emitSelectBill', 'emitFetch'])

const props = defineProps({
  billingId: {
    type: Number,
    default: null,
  },
})
const { billingId } = toRefs(props);
watch(billingId, (i) => {
  edit_billing_id.value = i
})

const langLabels = BillConst.LANG_LABELS
const formInit = BillConst.FORM_INIT

const storedList = computed(() => {
  return store.state.bill.list
})


const api = new APIClient()

const forms = reactive([])
const edit_billing_id = ref(null)
const selectedBill = computed(() => {
  const i = forms.findIndex(el => el.billing_id === edit_billing_id.value)
  return i
})

const fetch = async () => {
  try {
    const fetchApi = await api.get(ApiConst.API_BILLING_LIST)
    const res = fetchApi.response
    const errors = res.data?.error_results
    if (errors) {
      throw new Error('Not found.')
    }
    forms.splice(0)
    res.data.results.list.forEach((el) => {
      forms.push(mapValues(el, (v, k) => {
        if (!isValue(v)) {
          return formInit[k]
        }
        return v
      }))
    })
    store.commit('bill/list', forms)
    emit('emitFetch', forms)
  } catch (e) {
    console.log(e)
    return false
  }
}
fetch()

const selectBill = (i) => {
  edit_billing_id.value = i
  emit('emitSelectBill', i)
}

const onZipInput = (e) => {
  if (!zipNumOnlyTest(e.target.value)) return
  const zipValue = makeZip(e.target.value)
  form.company_zip = zipValue
}
const onZipChange = (e) => {
  const value = e.target.value.replace(/[^0-9]/g, '')
  if (value.length !== 7) return
  KenAll(value).then(res => {
    if (res.length === 0) return
    const address = res[0]
    form.company_address1 = address[0] + address[1] + address[2]
  })
}

const showForm = ref(false)
const toggleForm = computed(() => {
  if (showForm.value) return {display: 'block'}
  return {}
})
const form = reactive(cloneDeep(formInit))
const cancelRegister = () => {
  showForm.value = false
}
const setForm = (i) => {
  showForm.value = true
  forEach(formInit, (v, k) => {
    form[k] = v
  })
  const editor = cloneDeep(forms.find(el => el.billing_id === i))
  if (!editor) {
    forEach(formInit, (v, k) => {
      form[k] = v
    })
    return
  }
  forEach(formInit, (v, k) => {
    form[k] = editor[k]
  })
}

const scheme = {
  company_name: yup.string().trim().required('必須の項目です。'),
  company_name_kana: yup.string().test('katakana-checker', 'カタカナで入力して下さい。', isKana).trim().required('必須の項目です。'),
  company_zip: yup.string().trim().required('必須の項目です。'),
  company_address1: yup.string().max(30, '全角30文字以内で入力してください。').trim().required('必須の項目です。'),
  company_address2: yup.string().max(30, '全角30文字以内で入力してください。').trim(),
  staff_affiliation: yup.string().trim().required('必須の項目です。'),
  staff_post: yup.string().trim(),
  staff_name: yup.string().trim().required('必須の項目です。'),
  staff_tel1: yup.string().trim().required('必須の項目です。'),
  staff_tel2: yup.string().trim(),
  staff_email: yup.string().trim().email('メールアドレスの形式が不正です。').required('必須の項目です。'),
  lang: yup.string().trim().required('必須の項目です。'),
}
const validate = new FormValidate(scheme, form)
const errors = validate.errors
const register = async () => {
  try {
    if ((Object.keys(await validate.getErrorObject()).length)) {
      throw new Error('入力項目に不備があります。')
    }
    const params = cloneDeep(form)
    const billing_id = params.billing_id
    delete params.billing_id
    const operation = billing_id ? "R" : "I"
    const query = operation === "I"
      ? { operation, ...params }
      : { operation, billing_id, ...params }
    const fetchApi = await api.post(ApiConst.API_BILLING_EDIT, query)
    const res = fetchApi.response
    if (res.data?.error_results) {
      throw new Error('API Validation Error')
    }
    selectBill(res.data.results.billing_id)
    fetch()
    cancelRegister()
  } catch (e) {
    console.log(e)
    return false
  }
}
</script>

<style></style>
