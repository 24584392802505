import { store } from '@/store'
import { map, includes, findKey } from 'lodash'

import MakerConst from '@/const/maker'

const MAKERS = MakerConst.EXHIBITOR_ID

const PRICES = {
  "A1" : 2090000,
  "A2" : 2750000,
  "A3" : 3410000,
  "A4" : 4070000,
  "B1" : 3410000,
  "B2" : 4510000,
  "B3" : 5610000,
  "B4" : 6710000,
  "30㎡（1㎡当り14,850円）" : 445500,
}

const CATEGORIES = {
  "ユニットハウス" : [
    "A1",
    "A2",
    "A3",
    "A4",
    "B1",
    "B2",
    "B3",
    "B4",
  ],
  "既存諸室" : ["30㎡（1㎡当り14,850円）"],
}

const MAKER_PRICE_MATRIX = {
  "A1" : [null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,],
  "A2" : [null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,],
  "A3" : [null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,],
  "A4" : [null,null,null,null,null,1,null,null,null,null,null,null,null,null,null,null,null,null,null,],
  "B1" : [1,null,null,1,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,],
  "B2" : [2,null,1,null,null,null,1,null,null,null,null,null,null,null,null,null,null,null,null,],
  "B3" : [null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,],
  "B4" : [null,1,null,null,1,null,null,1,null,null,null,null,null,null,null,null,null,null,null,],
  "30㎡（1㎡当り14,850円）" : [null,null,null,2,null,null,null,6,4,4,2,2,2,2,1,2,4,2,2,1],
}
const getQtyByExhibitorId = (exhibitor_id) => {
  try {
    const pointer = MAKERS.findIndex(el => el.value === Number(exhibitor_id))
    return map(MAKER_PRICE_MATRIX, (v, k) => {
      return { category: findKey(CATEGORIES, el => includes(el, k)), key: k, qty:v[pointer], price: PRICES[k] }
    })
  } catch (e) {
    console.log(e)
  }
  return false
}

export const getApplicationStatus = () => {
  const exhibitor_id = store.state.entrydata.exhibitor_id
  return getQtyByExhibitorId(exhibitor_id)
}
