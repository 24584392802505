<template>
  <ul class="common_list_horizontal">
    <li class="w10em">
      <MySelect
        :name="main_name"
        v-model="main"
        :options="main_options"
      />
    </li>
    <li class="mr10" style="margin-left: 40px;">その他</li>
    <li class="w10em">
      <MySelect
        :name="other_name"
        v-model="other"
        :options="other_options"
        :disabled="isActive"
      />
    </li>
  </ul>
</template>

<script setup>
import { ref, computed, defineProps, defineEmits, watch, toRefs } from "vue"
import MySelect from '@/components//MySelect.vue';

const props = defineProps({
  initial_main: { type: String, required: true },
  initial_other: { type: String, required: true },
  main_name: { type: String, required: false },
  other_name: { type: String, required: false },
  main_options: { type: Object, required: true },
  other_options: { type: Object, required: true },
  disabled_number: { type: String, required: true },
})

const { initial_main, initial_other } = toRefs(props)
const main = ref(null)
const other = ref(null)
watch(initial_main, (i) => {
  main.value = i
})
watch(initial_other, (i) => {
  other.value = i
})

const emit = defineEmits(['update:main', 'update:other'])

watch(main, () => {
  if (main.value !== props.disabled_number) {
    other.value = ''
  }
  emit('update:main', main.value)
})

watch(other, () => {
  emit('update:other', other.value)
})

const isActive = computed(() => {
  const var_country_main = main.value;
  if (var_country_main !== props.disabled_number) return true
  return false
})
</script>
