<template>
  <div id="admin">
    <header>
      <h1>JMS 2025 出展者登録DB 管理者用ページ</h1>
    </header>
    <article>
      <h2>一次申込管理</h2>

      <div id="form_ctn" class="form_box">

        <ErrorComponent></ErrorComponent>

        <h3>メール配信/登録</h3>
        <div class="alert_box" v-if="exhibit_participation_status == 0 || exhibit_participation_status == 1">
          <p class="alert_title">以下の申込者に対して<span class="result_text">{{exhibit_participation_status_label}}</span>のメールを配信します。</p>
          <p class="alert_text">よろしければページ下部の「メール配信」ボタンをクリックしてください。</p>
        </div>
        <div class="alert_box" v-if="exhibit_participation_status == 2">
          <p class="alert_title">以下の申込者に対して<span class="result_text">{{exhibit_participation_status_label}}</span>とします。</p>
          <p class="alert_text">よろしければページ下部の「確定」ボタンをクリックしてください。</p>
        </div>



        <table class="common_table exhibitor_table">
          <tbody>
            <tr>
              <th colspan="2">1次申込番号
              </th>
              <td>{{exhibit_participation_id}}</td>
            </tr>
            <tr>
              <th colspan="2">審査ステータス</th>
              <td>{{exhibit_participation_status_label}}</td>
            </tr>
            <tr>
              <th rowspan="3">会社名</th>
              <th>和文名称</th>
              <td>{{exhibitor_name}}</td>
            </tr>
            <tr>
              <th>フリガナ</th>
              <td>{{exhibitor_name_kana}}</td>
            </tr>
            <tr>
              <th>英文名称</th>
              <td>{{exhibitor_name_en}}</td>
            </tr>
            <tr>
              <th colspan="2">会員区分</th>
              <td>{{membership_category_label}}</td>
            </tr>
            <tr>
              <th colspan="2">過去の出展実績</th>
              <td>{{past_achievement_label}}</td>
            </tr>
            <tr>
              <th colspan="2">出展カテゴリー</th>
              <td>{{category_div_label}}</td>
            </tr>
            <tr>
              <th colspan="2">企業WEBサイト</th>
              <td><a :href="site_url" target="_blank">{{site_url}}</a></td>
            </tr>
            <tr>
              <th colspan="2">主な製品/サービス情報</th>
              <td>{{appeal_information}}</td>
            </tr>

          </tbody>
        </table>

 

        <div class="button_ctn">
          <ul>
            <li><router-link :to="{name:'AdminPreDetail'}" class="gray_button">入力画面に戻る</router-link></li>
            <li><button class="submit" @click="sendForm">
              <span v-if="exhibit_participation_status == 0 || exhibit_participation_status == 1">メール配信</span>
              <span v-if="exhibit_participation_status == 2">確定</span>
            </button></li>
          </ul>
        </div>


      </div>

      <div class="button_ctn">
          <ul>
            <li><router-link :to="{name:'AdminIndex'}">メニュー画面に戻る</router-link>
            </li>
          </ul>
        </div>
    </article>
  </div>
</template>

<script>

import APIClient from '@/store/repository/APIClient'
import ErrorComponent from '@/components/ErrorComponent.vue';

export default {
  components: {
    ErrorComponent,
  },
  setup() {


    return {


    };
  },

  data() {
    return {
      exhibit_participation_status:'',
      membership_category: '',
    }
  },
  async created() {
    this.api = new APIClient()
    const res = await this.api.getConfig()
    this.$store.commit('disicion/exhibit_participation_status_options', res.data.results.exhibit_participation_status_options)
    this.$store.commit('disicion/membership_category_options_admin', res.data.results.membership_category_options_admin)
    this.$store.commit('disicion/past_achievement_options', res.data.results.past_achievement_options)
    this.$store.commit('disicion/category_div_options', res.data.results.category_div_options)

    const res_detail = await this.api.postAdminPreDetail({
      exhibit_participation_id: this.$route.params.id
    })
    this.exhibit_participation_id = res_detail.data.results.exhibit_participation_id

    this.exhibitor_name = res_detail.data.results.exhibitor_name
    this.exhibitor_name_kana = res_detail.data.results.exhibitor_name_kana
    this.exhibitor_name_en = res_detail.data.results.exhibitor_name_en
    this.membership_category = res_detail.data.results.membership_category
    this.category_div_label = res_detail.data.results.category_div_label
    this.past_achievement_label = res_detail.data.results.past_achievement_label
    this.site_url = res_detail.data.results.site_url
    this.appeal_information = res_detail.data.results.appeal_information


  },
  mounted() {
    this.inputStoreData('exhibit_participation_status')
  },
  methods: {
    inputStoreData(key) {
      if (!this.$store.state.admin[key]) return;
      this[key] = this.$store.state.admin[key]
    },
    async sendForm() {
      try {
        const query = {
          exhibit_participation_id: this.$route.params.id,
          exhibit_participation_status: this.exhibit_participation_status,
        }

        const res = await this.api.postAdminPreJudge(query)
        if (res.data?.error_results) {
          throw new Error('API Validation Error')
        }
      } catch (e) {
        console.error(e.message)
        return false
      }

      this.$router.push({ name: 'AdminPreDetailComplete' });
    }
  },
  watch: {


  },
  computed: {

    membership_category_options() {
      return this.$store.state.disicion.membership_category_options_admin
    },
    membership_category_label() {
      const array = this.$store.state.disicion.membership_category_options_admin
      return array.find(item => item.value === String(this.membership_category))?.label
    },
    exhibit_participation_status_label() {
      const array = this.$store.state.disicion.exhibit_participation_status_options
      return array.find(item => item.value === String(this.exhibit_participation_status))?.label
    },
    past_achievement_options() {
      return this.$store.state.disicion.past_achievement_options
    },

    category_div_options() {
      return this.$store.state.disicion.category_div_options
    },
  }
};
</script>

<style>

</style>
