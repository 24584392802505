<template>
    <header>
        <h1>JAPAN MOBILITY SHOW 2025&emsp;出展手続きサイト・出展者専用サイト</h1>
    </header>
    <article>
        <h2>申込完了</h2>
        <div id="form_ctn" class="form_box">

            <h3>申込書は受領済みです</h3>
            <p>申込書の再提出をご希望の場合は、下記までご連絡ください。</p>
            <div class="contact_ctn">
        <h4>出展に関するお問い合わせ・提出先</h4>
        <p>一般社団法人&emsp;日本自動車工業会<br>
次世代モビリティ領域・ショー担当<br>
TEL：03(5405)6127<br>
          <a href="https://www.japan-mobility-show.com/app/contact/" target="_blank">https://www.japan-mobility-show.com/app/contact/</a>
        </p>
      </div>
        </div>

    </article>
</template>

<script>

</script>