export default {
  namespaced: true,
  state: {
  },
  mutations: {
    token(state, payload) {
      state.token = payload
    },
    limit_datetime(state, payload) {
      state.limit_datetime = payload
    },
  },
  actions: {
    logout({ commit }) {
      commit('token', null)
      commit('limit_datetime', null)
    },
  }
}
