<template>
  <ul class="step_list">
    <li :class="{ active: step === '1' }">
      <h4>STEP1</h4>
      <h5>必要情報の入力</h5>
      <p>ページ下部「出展申込開始」をクリック後、「規程」及び「個人情報保護方針」を了承の上、画面に沿って必要な情報を入力してください。</p>
    </li>
    <li :class="{ active: step === '2' }">
      <h4>STEP2</h4>
      <h5>出展申込書のダウンロード</h5>
      <p>必要情報の入力内容ご確認後に、出展申込書ダウンロードボタンよりPDFファイルをダウンロードしてください。<br>
        同時に登録メールアドレス宛に出展申込書アップロード用のURLが記載されたメールが配信されます。</p>
    </li>
    <li :class="{ active: step === '3' }">
      <h4>STEP3</h4>
      <h5>出展申込書へ押印</h5>
      <p>出展申込書に社印を押印してください。<br>
        （PDFへの電子署名可。出力紙への押印の場合は、押印後にスキャンしたPDFをご準備ください。）</p>
    </li>
    <li :class="{ active: step === '4' }">
      <h4>STEP4</h4>
      <h5>出展申込書のアップロード</h5>
      <p>STEP 2で配信されたメール内のアップロード用のURLへログイン後、<br>
        画面の表示に沿って、押印済みの出展申込書PDFファイルをアップロードしてください。</p>
    </li>
  </ul>
  <ul class="step_list_notes">
    <li>出展申込書につきまして、郵送・FAXによる申込みは受け付けておりません。</li>
    <li>電子署名の際に証憑等の付属書類が生じた場合は事務局までご連絡ください。</li>
  </ul>
</template>

<script>
export default {
  props: ['step'],
};
</script>

<style>

</style>
