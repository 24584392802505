<template>
  <table class="common_table" v-if="!isNew">
    <tbody>
      <tr>
        <th>&nbsp;&nbsp;</th>
        <th class="w10em">搬入出日</th>
        <th>積載車・トレーラ</th>
        <th>大型車<br>（最大積載量10t程度）</th>
        <th>中型車<br>（最大積載量4t程度）
        </th>
        <th colspan="2">その他<br>（乗用車等）</th>
      </tr>
      <tr
        v-for="(v,k) in assignmentDateOptions"
        :key="k"
      >
        <td>{{ searchDate(v, currentData.plan)?.usage.map(
          (val) => usageOptions.find(el => el.value === val)?.label
        )?.join(',') }}</td>
        <td v-html="concatSuffix(v)"></td>
        <td class="ta_right">{{ searchDate(v, currentData.plan)?.trailer }} 台</td>
        <td class="ta_right">{{ searchDate(v, currentData.plan)?.heavy }} 台</td>
        <td class="ta_right">{{ searchDate(v, currentData.plan)?.medium }} 台</td>
        <td class="ta_right">{{ searchDate(v, currentData.plan)?.other }} 台</td>
        <td>{{ searchDate(v, currentData.plan)?.other_note }}</td>
      </tr>
    </tbody>
  </table>
  <div class="no_entry" v-else>登録がありません。「新規追加」ボタンより登録をお願いします。</div>
  <template v-if="isShowUI">
  <div><a
    v-if="isNew"
    @click.prevent="newHandler"
    href="#form1"
    class="common_add_button modalOpen"
  >新規追加</a><a
    v-else
    @click.prevent="editHandler"
    href="#form1"
    class="common_add_button modalOpen"
  >変更</a></div>
  </template>
  <Teleport to="body">
    <!-- モーダルコンテンツ / -->
    <div class="overlay" id="form1">
      <div id="modal_content" class="modal_contents w80">
        <div class="modalClose_ctn"><a @click.prevent="modalClose" class="modalClose"></a></div>
        <!-- modal contents ここから-->
        <div class="entry_title">
          <h4>計画搬入出調査書</h4>

          <div class="entry_title">
            <h5>搬入出車両（予定台数）&nbsp;&nbsp;{{uploadLabel}}</h5>
          </div>

          <div class="entry_modal_ctn">
          <table class="common_table">
            <tbody>
              <tr>
                <th v-html="usageDesc"></th>
                <th class="w10em">搬入出日</th>
                <th>積載車・トレーラ</th>
                <th>大型車<br>
                  (最大積載量10t程度)</th>
                <th>中型車<br>
                  (最大積載量4t程度) </th>
                <th colspan="2">その他<br>
                  (乗用車等)</th>
              </tr>
              <tr
                v-for="(v,k) in assignmentDateOptions"
                :key="k"
              >
                <td>
                  <ul class="common_list_horizontal">
                    <MyCheckboxVertical v-model="form.plan[k].usage" name="plan_usage" :options="usageOptions"></MyCheckboxVertical>
                  </ul>
                </td>
                <td v-html="concatSuffix(v)"></td>
                <td class="ta_right"><input type="number" min="0" class="w5em" v-model="form.plan[k].trailer"> 台</td>
                <td class="ta_right"><input type="number" min="0" class="w5em" v-model="form.plan[k].heavy"> 台</td>
                <td class="ta_right"><input type="number" min="0" class="w5em" v-model="form.plan[k].medium"> 台</td>
                <td class="ta_right w10em"><input type="number" min="0" class="w5em" v-model="form.plan[k].other"> 台</td>
                <td><input type="text" class="w8em" v-model="form.plan[k].other_note" :disabled="!isValue(form.plan[k].other)">
                  <p class="alert" v-if="planErrorMessage(`plan[${k}].other_note`)">{{ planErrorMessage(`plan[${k}].other_note`) }}</p>
                </td>
              </tr>
            </tbody>
          </table>


            <div class="button_ctn">
              <ul>
                <li><button @click="modalClose" class="gray_button modalClose">キャンセル</button></li>
                <li><button
                  @click="sendForm"
                  class="regist_button"
                >上記内容で登録</button></li>
              </ul>
            </div>
            <!-- modal contents ここまで-->

          </div>
        </div>
        <!-- / モーダルコンテンツ -->
      </div>
    </div>
    <!-- / モーダルコンテンツ -->
  </Teleport>
</template>

<script setup>
import { reactive, computed } from 'vue'
import { useRoute } from 'vue-router'
import { forEach, map, cloneDeep, sortBy } from 'lodash'

import APIClient from '@/store/repository/APIClient'
import * as yup from 'yup'
import moment from "moment"
import { FormValidate } from '@/helper/FormValidate'
import MyCheckboxVertical from '@/components/MyCheckboxVertical'

import {
  modalOpen,
  modalClose,
} from '@/helper/modal'

import {
  isValue
} from '@/helper/index';

import CarryInOutConst from '@/const/carry_inout'

import ApiConst from '@/const/api'

import { isShowable as isShowUI } from '@/helper/indexStatus'

const route = useRoute()

// 日付制限カテゴリー
const dateLimitCategoryDiv = {
  "12": "部品・機械器具",
  "13": "部品(共同)",
  "14": "次世代モビリティ関連",
}

const dateLimit1 = moment('2025-10-21')
const dateLimit2 = moment('2025-10-22')

const concatSuffix = (v) => {
  const suffix = CarryInOutConst.DATES_META.find(el => el.date === v)
  return v + (suffix ? '<br>'+suffix.value : '')
}

const assignmentDateOptions = (() => {
  const dates = CarryInOutConst.DATES
  if (Object.keys(dateLimitCategoryDiv).map(Number).includes(route.meta.categoryDisp)) {
    if (route.meta.decideArea >= 10) {
      return dates.filter((el) => {
        if (moment('2025-' + el.replace('月', '-').replace(/日.*$/, '')) < dateLimit1) return false
        return true
      })
    } else {
      return dates.filter((el) => {
        if (moment('2025-' + el.replace('月', '-').replace(/日.*$/, '')) < dateLimit2) return false
        return true
      })
    }
  }
  return dates
})()

const usageOptions = [
  {value: '施設物搬入出', label: '施'},
  {value: '展示物搬入出', label: '展'},
]

const usageDesc = map(usageOptions, (el) => el.label + '：' + el.value)?.join(`<br />`)

const api = new APIClient()

const planInit = {
  "date" : "",
  "trailer" : null,
  "heavy" : null,
  "medium" : null,
  "other" : null,
  "other_note" : "",
  "usage": []
}
const formInit = {
  application_id: null,
  plan: assignmentDateOptions.map((v) => {
    return {
      ...planInit,
      "date" : v,
    }
  }),
}

const form = reactive(cloneDeep(formInit))
const currentData = reactive(cloneDeep(formInit))

const searchDate = (v, currentData) => currentData.find((el) => el.date === v)

const isNew = computed(() => {
  return !currentData.application_id
})

const uploadLabel = computed(() => {
  return !isNew.value ? '変更' : '新規登録'
})

const fetch = async () => {
  try {
    const query = {
      form_no: 1,
      application_div : "carry_inout",
    }
    const fetchApi = await api.get(ApiConst.API_APP_LIST, query)
    const res = fetchApi.response
    const errors = res.data?.error_results
    if (errors) {
      throw new Error('Not found.')
    }
    const _formInit = cloneDeep(formInit)
    const data = res.data.results.list.map((el) => {
      assignmentDateOptions.forEach((val) => {
        const dateAttrs = val.match(/(\d+)月(\d+)日\(.\)/)
        const month = dateAttrs[1]
        const date = dateAttrs[2]
        const dateAsSordKey = moment(`2025-${month}-${date}`).format('YYYY-MM-DD')
        const target = el.json_data.plan.find(el => el.date === val)
        if (!target) {
          el.json_data.plan.push({
            ...planInit, date: val, dateAsSordKey
          })
          return
        } else {
          forEach(target, ((v, k) => {
            if (!isValue(v)) target[k] = planInit[k]
          }))
          target.dateAsSordKey = dateAsSordKey
        }
      })
      el.json_data.plan = sortBy(el.json_data.plan, (l) => {
        return l.dateAsSordKey
      })
      return {
        application_id: el.application_id,
        input_date: moment(el.updated_at).format("YY-MM-DD"),
        seq_no: el.seq_no,
        ...el.json_data
      }
    })[0]
    const _currentData = cloneDeep(data)
    const _form = cloneDeep(data)
    forEach(currentData, (v, k) => {
      currentData[k] = _currentData[k]
    })
    forEach(form, (v, k) => {
      form[k] = _form[k] ?? _formInit[k]
    })
  } catch (e) {
    console.log(e)
    return false
  }
}
fetch()

const newHandler = (event) => {
  forEach(formInit, (v, k) => {
    form[k] = v
  })
  modalOpen(event)
}

const editHandler = (event) => {
  modalOpen(event)
}

const planErrorMessage = (msg) => {
  return errors[msg] ?? false
}
const objectSchema = yup.object().shape({
  other_note: yup.string().trim().test('other_note',
    function(v) {
      errors[v.path] = 'その他の内容を入力してください。'
      return 'その他の内容を入力してください。'
    },
    function(v) {
      if (!isValue(this.parent.other)) return true
      if (v !== '') return true
      return false
    }
  ),
})
const scheme = {
  plan: yup.array().of(objectSchema),
}
const validate = new FormValidate(scheme, form)
const errors = validate.errors
const sendForm = async () => {
  try {
    if ((Object.keys(await validate.getErrorObject()).length)) {
      throw new Error('入力項目に不備があります。')
    }
    const params = cloneDeep(form)
    const application_id = params.application_id
    delete params.application_id
    const operation = application_id ? "R" : "I"
    const query = operation === "I"
      ? {operation, application_div : "carry_inout", form_no: 1, json_data: params}
      : {operation, application_id, json_data: params}
    const res = await api.post(ApiConst.API_APP_EDIT, query)
    if (res.response.data?.error_results) {
      throw new Error('API Validation Error')
    }
    fetch()
    modalClose()
  } catch (e) {
    console.log(e, errors)
    return false
  }
}
</script>

<style></style>
