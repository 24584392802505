<template>
  <component :is="loadedComponent" />
</template>

<script setup>
import { defineAsyncComponent } from 'vue'
import { useRoute } from "vue-router"

const loadedComponent = defineAsyncComponent(async () => {
  const route = useRoute()
  const view = route.params.admin_application
  return import(`@/views/admin/spoofing/editor/${view}.vue`)
})
</script>

<style></style>
