export default {
  namespaced: true,
  state: {
  },
  mutations: {
    staff_authority(state, payload) {
      state.staff_authority = payload
    },
    exhibitor_id(state, payload) {
      state.exhibitor_id = payload
    },
    exhibitor_name(state, payload) {
      state.exhibitor_name = payload
    },
    exhibitor_name_kana(state, payload) {
      state.exhibitor_name_kana = payload
    },
    exhibitor_name_en(state, payload) {
      state.exhibitor_name_en = payload
    },
    staff_last_name(state, payload) {
      state.staff_last_name = payload
    },
    staff_first_name(state, payload) {
      state.staff_first_name = payload
    },
    staff_tel(state, payload) {
      state.staff_tel = payload
    },
    staff_email(state, payload) {
      state.staff_email = payload
    },
    membership_category(state, payload) {
      state.membership_category = payload
    },
    past_achievement(state, payload) {
      state.past_achievement = payload
    },
    check_test(state, payload) {
      state.check_test = payload
    },
    site_url(state, payload) {
      state.site_url = payload
    },
    appeal_information(state, payload) {
      state.appeal_information = payload
    },
    category_div(state, payload) {
      state.category_div = payload
    },
    country_main(state, payload) {
      state.country_main = payload
    },
    country_other(state, payload) {
      state.country_other = payload
    },
    //SecondForm02
    booth_area(state, payload) {
      state.booth_area = payload
    },
    indoor_exhibition_fee(state, payload) {
      state.indoor_exhibition_fee = payload
    },
    indoor_application_fee(state, payload) {
      state.indoor_application_fee = payload
    },
    outdoor_exhibition_fee(state, payload) {
      state.outdoor_exhibition_fee = payload
    },
    outdoor_application_fee(state, payload) {
      state.outdoor_application_fee = payload
    },
    parts_member_exhibition_fee(state, payload) {
      state.parts_member_exhibition_fee = payload
    },
    parts_member_application_fee(state, payload) {
      state.parts_member_application_fee = payload
    },
    parts_exhibition_fee(state, payload) {
      state.parts_exhibition_fee = payload
    },
    parts_application_fee(state, payload) {
      state.parts_application_fee = payload
    },
    mobility_member_exhibition_fee(state, payload) {
      state.mobility_member_exhibition_fee = payload
    },
    mobility_member_application_fee(state, payload) {
      state.mobility_member_application_fee = payload
    },
    mobility_exhibition_fee(state, payload) {
      state.mobility_exhibition_fee = payload
    },
    mobility_application_fee(state, payload) {
      state.mobility_application_fee = payload
    },
    kidzania_member_exhibition_fee(state, payload) {
      state.kidzania_member_exhibition_fee = payload
    },
    kidzania_member_application_fee(state, payload) {
      state.kidzania_member_application_fee = payload
    },
    kidzania_exhibition_fee(state, payload) {
      state.kidzania_exhibition_fee = payload
    },
    kidzania_application_fee(state, payload) {
      state.kidzania_application_fee = payload
    },
    other_exhibition_fee(state, payload) {
      state.other_exhibition_fee = payload
    },
    other_application_fee(state, payload) {
      state.other_application_fee = payload
    },

    calced_application_fee(state, payload) {
      state.calced_application_fee = payload
    },
    calced_exhibition_fee(state, payload) {
      state.calced_exhibition_fee = payload
    },
    calced_unit_price(state, payload) {
      state.calced_unit_price = payload
    },
    calced_unit(state, payload) {
      state.calced_unit = payload
    },
    
    joint_adjacent(state, payload) {
      state.joint_adjacent = payload
    },
    joint_exhibition(state, payload) {
      state.joint_exhibition = payload
    },
    adjacent_exhibition(state, payload) {
      state.adjacent_exhibition = payload
    },
    //SecondForm03
    postCode(state, payload) {
      state.postCode = payload
    },
    address_pref(state, payload) {
      state.address_pref = payload
    },
    address_jp(state, payload) {
      state.address_jp = payload
    },
    address_en(state, payload) {
      state.address_en = payload
    },
    representative_position(state, payload) {
      state.representative_position = payload
    },
    representative_last_name(state, payload) {
      state.representative_last_name = payload
    },
    representative_first_name(state, payload) {
      state.representative_first_name = payload
    },
    brand1_name_jp(state, payload) {
      state.brand1_name_jp = payload
    },
    brand1_name_en(state, payload) {
      state.brand1_name_en = payload
    },
    brand1_country_main(state, payload) {
      state.brand1_country_main = payload
    },
    brand1_country_other(state, payload) {
      state.brand1_country_other = payload
    },
    brand2_name_jp(state, payload) {
      state.brand2_name_jp = payload
    },
    brand2_name_en(state, payload) {
      state.brand2_name_en = payload
    },
    brand2_country_main(state, payload) {
      state.brand2_country_main = payload
    },
    brand2_country_other(state, payload) {
      state.brand2_country_other = payload
    },
    brand3_name_jp(state, payload) {
      state.brand3_name_jp = payload
    },
    brand3_name_en(state, payload) {
      state.brand3_name_en = payload
    },
    brand3_country_main(state, payload) {
      state.brand3_country_main = payload
    },
    brand3_country_other(state, payload) {
      state.brand3_country_other = payload
    },
    brand4_name_jp(state, payload) {
      state.brand4_name_jp = payload
    },
    brand4_name_en(state, payload) {
      state.brand4_name_en = payload
    },
    brand4_country_main(state, payload) {
      state.brand4_country_main = payload
    },
    brand4_country_other(state, payload) {
      state.brand4_country_other = payload
    },
    brand5_name_jp(state, payload) {
      state.brand5_name_jp = payload
    },
    brand5_name_en(state, payload) {
      state.brand5_name_en = payload
    },
    brand5_country_main(state, payload) {
      state.brand5_country_main = payload
    },
    brand5_country_other(state, payload) {
      state.brand5_country_other = payload
    },
    //SecondForm04
    deputy_exhibitor_name(state, payload) {
      state.deputy_exhibitor_name = payload
    },
    deputy_exhibitor_name_kana(state, payload) {
      state.deputy_exhibitor_name_kana = payload
    },
    deputy_exhibitor_name_en(state, payload) {
      state.deputy_exhibitor_name_en = payload
    },
    deputy_representative_position(state, payload) {
      state.deputy_representative_position = payload
    },
    deputy_representative_last_name(state, payload) {
      state.deputy_representative_last_name = payload
    },
    deputy_representative_first_name(state, payload) {
      state.deputy_representative_first_name = payload
    },
    manager_postCode(state, payload) {
      state.manager_postCode = payload
    },
    manager_address_pref(state, payload) {
      state.manager_address_pref = payload
    },
    manager_address_jp(state, payload) {
      state.manager_address_jp = payload
    },
    manager_address_en(state, payload) {
      state.manager_address_en = payload
    },
    staff_department(state, payload) {
      state.staff_department = payload
    },
    staff_position(state, payload) {
      state.staff_position = payload
    },
    deputies(state, payload) {
      state.deputies = payload
    },
    BrandNum(state, payload) {
      state.BrandNum = payload
    },
    pdf_url(state, payload) {
      state.pdf_url = payload
    },
    koma_bango(state, payload) {
      state.koma_bango = payload
    },
    now_staff_email(state, payload) {
      state.now_staff_email = payload
    },
    
  },
}
