<template>
  <div id="admin">
    <header>
      <h1>JMS 2025 出展者登録DB 管理者用ページ</h1>
    </header>
    <article>
      <h2>二次申込管理</h2>
      <div id="form_ctn" class="form_box">
        <admin-second-search></admin-second-search>
      </div>
      <div id="form_ctn" class="form_box">
               <h3>出展者の追加</h3>
              
      
      <div class="button_ctn">
      <button class="submit" @click="$router.push({name: 'AdminSecondDetailInsert'})">新規登録</button>
      </div>
      </div>

      <div class="button_ctn">
          <ul>
            <li><router-link :to="{name:'AdminIndex'}">メニュー画面に戻る</router-link>
            </li>
          </ul>
        </div>
    </article>
    </div>
</template>

<script>

import APIClient from '@/store/repository/APIClient'

import AdminSecondSearch from '@/components/AdminSecondSearch.vue';

export default {
  components: {
    AdminSecondSearch
  },
  setup() {


    return {
      

    };
  },

  data() {
    return {

    }
  },
  async created() {
    this.api = new APIClient()
    const res = await this.api.getConfig()
    this.$store.commit('disicion/exhibit_participation_status_options', res.data.results.exhibit_participation_status_options)
    this.$store.commit('disicion/membership_category_options_admin', res.data.results.membership_category_options_admin)
    this.$store.commit('disicion/past_achievement_options', res.data.results.past_achievement_options)
    this.$store.commit('disicion/category_div_options', res.data.results.category_div_options)
  },
  mounted() {

  },
  methods: {

  },
  watch: {


  },
  computed: {
    exhibit_participation_status_options() {
      return this.$store.state.disicion.exhibit_participation_status_options
    },
    membership_category_options() {
      return this.$store.state.disicion.membership_category_options_admin
    },
    past_achievement_options() {
      return this.$store.state.disicion.past_achievement_options
    },
    category_div_options() {
      return this.$store.state.disicion.category_div_options
    },

  }
};
</script>

<style>

</style>
