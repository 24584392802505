<template>
  <table class="common_table" v-if="list.length">
    <tbody>
      <tr>
        <th>会社名</th>
        <th>共同事業・協業等の内容</th>
        <th colspan="2" v-if="isShowUI">編集</th>
      </tr>

      <tr v-for="(val, key) in list" :key="key">
        <td>{{ val.company_name }}</td>
        <td v-html="val.noteText"></td>
        <td class="edit_cell" v-if="isShowUI">
          <a @click.prevent="editHandler($event, key)" href="#modal1" class="common_button_t2 modalOpen">変更</a>
        </td>
        <td class="edit_cell" v-if="isShowUI">
          <a @click.prevent="deleteHandler($event, key)" class="common_button_t2 gray_button delete_button">削除</a>
        </td>
      </tr>
    </tbody>
  </table>
  <div class="no_entry" v-else>登録がありません。「新規追加」ボタンより登録をお願いします。</div>
  <template v-if="isShowUI">
  <div>
    <a @click.prevent="newHandler" href="#modal1" class="common_add_button modalOpen">新規追加</a>
  </div>
  </template>

  <Teleport to="body">
    <!-- モーダルコンテンツ / -->
    <!-- 製作注意問い合わせ先は何種類かあります。読み込み形式で簡単に編集できるようにしたほうが良いかと思います。-->
    <div class="overlay" id="modal1">
      <div id="modal_content" class="modal_contents w80">
        <!-- modal contents ここから-->
        <div class="modalClose_ctn">
          <a @click.prevent="modalClose" class="modalClose"></a>
        </div>
        <div class="entry_title">
          <h4>協業出展者情報</h4>
          <h5>協業出展者との共同事業・協業の内容について、簡潔にご記入下さい。</h5>
        </div>
        <div class="entry_modal_ctn">
          <textarea name="textarea2" rows="5" class="full" id="textarea2" v-model="form.note"></textarea>
            <p class="alert" v-if="errors.note">{{ errors.note }}</p>
        </div>

        <div class="entry_title">
          <h5>協業出展者情報</h5>
        </div>
        <div class="entry_modal_ctn">
          <table class="common_table">
            <tbody>
              <tr>
                <th rowspan="3">会社名</th>
                <th class="required">和文名称</th>
                <td><input type="text" class="full" v-model="form.company_name">
                  <p class="alert" v-if="errors.company_name">{{ errors.company_name }}</p></td>
              </tr>
              <tr>
                <th class="required">名称カナ</th>
                <td><input type="text" class="full" v-model="form.company_name_kana">
                  <p class="alert" v-if="errors.company_name_kana">{{ errors.company_name_kana }}</p></td>
              </tr>
              <tr>
                <th class="required">英文名称</th>
                <td><input type="text" class="full" v-model="form.company_name_en">
                  <p class="alert" v-if="errors.company_name_en">{{ errors.company_name_en }}</p></td>
              </tr>

              <tr>
                <th colspan="2">出展する取扱いブランド（国・地域）</th>
                <td>
                  <input type="text" class="full" v-model="form.brand" style="margin-bottom: 10px;">
                  <CountrySelect2
                    :initial_main="form.brand_country_main"
                    main_name="brand_country_main"
                    :main_options="country_main_options"
                    v-model:main="form.brand_country_main"
                    :initial_other="form.brand_country_other"
                    other_name="brand_country_other"
                    :other_options="country_other_options"
                    v-model:other="form.brand_country_other"
                    disabled_number="99999999"
                  />
                </td>
              </tr>

              <tr>
                <th rowspan="3">本社所在地</th>
                <th class="required">郵便番号</th>
                <td>
                  <input type="text" id="exhibitors_zip" class="input" name="exhibitors_zip" placeholder="例：000-0000" v-model="form.company_zip" @input="onZipInput" @keyup="onZipChange">
                  <p class="alert" v-if="errors.company_zip">{{ errors.company_zip }}</p>
                </td>
              </tr>

              <tr>
                <th class="required">都道府県</th>
                <td>
                  <div class="select_ctn">
                    <select v-model="form.company_pref">
                      <option value="">都道府県</option>
                      <option
                        v-for="(v,k) in addressPrefOptions"
                        :key="k"
                        :value="v.label"
                        :selected="form.company_pref === v.label"
                      >{{ v.label }}</option>
                    </select>
                  </div>
                  <p class="alert" v-if="errors.company_pref">{{ errors.company_pref }}</p>
                </td>
              </tr>
              <tr>
                <th class="required">住所</th>
                <td><input name="delivery_worker_address" type="text" size="40" class="full" v-model="form.company_address">
                <p class="alert" v-if="errors.company_address">{{ errors.company_address }}</p>
                </td>
              </tr>
              <tr>
                <th rowspan="2">代表者</th>
                <th>役職</th>
                <td><input type="text" class="full" v-model="form.delegate_post">
                <p class="alert" v-if="errors.delegate_post">{{ errors.delegate_post }}</p></td>
              </tr>
              <tr>
                <th>氏名</th>
                <td><input type="text" class="full" v-model="form.delegate_name">
                <p class="alert" v-if="errors.delegate_name">{{ errors.delegate_name }}</p></td>
              </tr>
              <tr>
                <th rowspan="7">担当者</th>
                <th>部署</th>
                <td><input name="delivery_quarter_name" type="text" class="full" v-model="form.staff_affiliation">
                <p class="alert" v-if="errors.staff_affiliation">{{ errors.staff_affiliation }}</p></td>
              </tr>
              <tr>
                <th>役職</th>
                <td><input name="delivery_quarter_name" type="text" class="full" v-model="form.staff_post">
                <p class="alert" v-if="errors.staff_post">{{ errors.staff_post }}</p></td>
              </tr>
              <tr>
                <th class="required">氏名</th>
                <td><input name="delivery_prepared_name" type="text" size="30" class="full" v-model="form.staff_name">
                <p class="alert" v-if="errors.staff_name">{{ errors.staff_name }}</p></td>
              </tr>
              <tr>
                <th>電話番号</th>
                <td><input type="text" name="delivery_worker_tel" class="full" v-model="form.staff_tel1">
                <p class="alert" v-if="errors.staff_tel1">{{ errors.staff_tel1 }}</p></td>
              </tr>

              <tr>
                <th>E-mail</th>
                <td><input type="text" name="delivery_worker_email" class="full" v-model="form.staff_email">
                <p class="alert" v-if="errors.staff_email">{{ errors.staff_email }}</p></td>
              </tr>

            </tbody>
          </table>
        </div>

        <div class="button_ctn">
          <ul>
            <li>
              <button @click.prevent="modalClose" class="gray_button modalClose">キャンセル</button>
            </li>
            <li>
              <button @click="sendForm" class="regist_button">
                上記内容で登録
              </button>
            </li>
          </ul>
        </div>
        <!-- modal contents ここまで-->
      </div>
    </div>
    <!-- / モーダルコンテンツ -->
  </Teleport>
</template>


<script setup>
import { reactive, computed } from 'vue'
import { store } from '@/store'
import APIClient from '@/store/repository/APIClient'
import { forEach, cloneDeep } from 'lodash'
import { FormValidate } from '@/helper/FormValidate'
import * as yup from 'yup'
import moment from "moment"
import KenAll from 'ken-all'
import ADDRESS from '@/const/address_elements.js'
import CountrySelect2 from '@/components/CountrySelect2'

import {
  modalOpen,
  modalClose,
} from '@/helper/modal'

import {
  zipNumOnlyTest,
  makeZip,
  isKana,
  nl2br,
} from '@/helper/index'

import ApiConst from '@/const/api'

import { isShowable as isShowUI } from '@/helper/indexStatus'

import YupConst from '@/const/yup';

const zipRegExp = new RegExp(YupConst.ZIP_PATTERN)

const addressPrefOptions = ADDRESS.address_pref_options.filter((el => el.value !== '99'))

const api = new APIClient()

const list = reactive([])

;(async () => {
  const res = await api.getConfig()
  store.commit('disicion/country_main_options', res.data.results.country_main_options)
  store.commit('disicion/country_other_options', res.data.results.country_other_options)
})()
const country_main_options = computed(() => {
  return store.state.disicion.country_main_options
})
const country_other_options = computed(() => {
  return store.state.disicion.country_other_options
})

const formInit = {
  application_id: null,
  note: "",
  company_name: "",
  company_name_kana: "",
  company_name_en: "",
  brand: "",
  brand_country_main: "",
  brand_country_other: "",
  company_zip: "",
  company_pref: "",
  company_address: "",
  delegate_post:  "",
  delegate_name:  "",
  staff_affiliation: "",
  staff_post: "",
  staff_name: "",
  staff_tel1: "",
  staff_email: "",
}
const form = reactive(cloneDeep(formInit))


const fetch = async () => {
  const query = {
    form_no: 1,
    application_div: "collaboration",
  }
  const fetchApi = await api.get(ApiConst.API_APP_LIST, query)
  const res = fetchApi.response
  const errors = res.data?.error_results
  if (errors) {
    throw new Error('Not found.')
  }
  list.splice(0)
  res.data.results.list.map((el) => {
    return {
      application_id: el.application_id,
      input_date: moment(el.updated_at).format("YY-MM-DD"),
      seq_no: el.seq_no,
      ...el.json_data
    }
  }).forEach((val, key) => {
    val.noteText = nl2br(val.note)
    forEach(val, (v, k) => {
      val[k] = v ?? formInit[k]
    })
    list[key] = val
  })
}
fetch()

const onZipInput = (e) => {
  if (!zipNumOnlyTest(e.target.value)) return
  const zipValue = makeZip(e.target.value)
  form.company_zip = zipValue
}
const onZipChange = (e) => {
  const value = e.target.value.replace(/[^0-9]/g, '')
  if (value.length !== 7) return
  KenAll(value).then(res => {
    if (res.length === 0) return
    const address = res[0]
    const prefecture = address.shift()
    const pref_match = addressPrefOptions.find(item => item.label === prefecture)
    form.company_pref = pref_match.label
    form.company_address = address.join('')
  })
}

const newHandler = (event) => {
  forEach(formInit, (v, k) => {
    form[k] = v
  })
  modalOpen(event)
}

const editHandler = (event, key) => {
  forEach(form, (v, k) => {
    form[k] = list[key][k]
  })
  modalOpen(event)
}

const deleteHandler = async (event, key) => {
  if (!window.confirm('削除して宜しいですか？')) return false

  try {
    const application_id = list[key].application_id
    const operation = "D"
    const query = { operation, application_id }
    const res = await api.post(ApiConst.API_APP_EDIT, query)
    if (res.response.data?.error_results) {
      throw new Error('API Validation Error')
    }
    fetch()
  } catch (e) {
    return false
  }
}

const scheme = {
  note: yup.string().trim().required('必須の項目です。'),
  company_name: yup.string().trim().required('必須の項目です。'),
  company_name_kana: yup.string().test('katakana-checker', 'カタカナで入力して下さい。', isKana).trim().required('必須の項目です。'),
  company_name_en: yup.string().trim().required('必須の項目です。'),
  brand: yup.string().trim(),
  brand_country_main: yup.string().trim(),
  brand_country_other: yup.string().trim(),
  company_zip: yup.string().trim().required('必須の項目です。').matches(zipRegExp, YupConst.ZIP_PATTERN_MSG),
  company_pref: yup.string().trim().required('必須の項目です。'),
  company_address: yup.string().trim().required('必須の項目です。'),
  delegate_post: yup.string().trim(),
  delegate_name: yup.string().trim(),
  staff_affiliation: yup.string().trim(),
  staff_post: yup.string().trim(),
  staff_name: yup.string().trim().required('必須の項目です。'),
  staff_tel1: yup.string().trim(),
  staff_email: yup.string().trim().email('メールアドレスの形式が不正です。'),
}
const validate = new FormValidate(scheme, form)
const errors = validate.errors
const sendForm = async () => {
  try {
    if ((Object.keys(await validate.getErrorObject()).length)) {
      throw new Error('入力項目に不備があります。')
    }
    const params = cloneDeep(form)
    const application_id = params.application_id
    delete params.application_id
    const operation = application_id ? "R" : "I"
    const query = operation === "I"
      ? { operation, application_div: "collaboration", form_no: 1, json_data: params }
      : { operation, application_id, json_data: params }
    const res = await api.post(ApiConst.API_APP_EDIT, query)
    if (res.response.data?.error_results) {
      throw new Error('API Validation Error')
    }
    fetch()
    modalClose()
  } catch (e) {
    return false
  }
}
</script>

<style></style>
