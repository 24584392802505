<template>
  <table class="common_table" v-if="!isNew">
    <tbody>
      <tr>
        <th class="w3em">プレスブリーフィング時のエンジン始動</th>
        <th class="w15em">燃料の種類</th>
        <th class="w4em">持込量(リットル)</th>
      </tr>
      <tr>
        <td><template v-if="currentData.do">エンジンの始動を{{currentData.do}}</template></td>
        <td>{{currentData.type}}</td>
        <td class="ta_right">{{currentData.quantity}}</td>
      </tr>
    </tbody>
  </table>
  <div class="no_entry" v-else>登録がありません。「新規追加」ボタンより登録をお願いします。</div>
  <template v-if="isShowUI">
  <div><a
    v-if="isNew"
    @click.prevent="newHandler"
    href="#form12"
    class="common_add_button modalOpen"
  >新規追加</a><a
    v-else
    @click.prevent="editHandler"
    href="#form12"
    class="common_add_button modalOpen"
  >変更</a></div>
  </template>
  <Teleport to="body">
    <!-- モーダルコンテンツ / -->
    <div class="overlay" id="form12">
      <div id="modal_content" class="modal_contents w80">
        <div class="modalClose_ctn"><a @click.prevent="modalClose" class="modalClose"></a></div>
        <!-- modal contents ここから-->
        <div class="entry_title">
          <h4>禁止行為解除承認申請</h4>

          <div class="entry_title">
            <h5>エンジンの始動&nbsp;&nbsp;{{uploadLabel}}</h5>
          </div>

          <div class="entry_modal_ctn">

          <table class="common_table">
            <tbody>
              <tr>
                <th>プレスブリーフィング時にエンジン始動</th>
                <td>
                  <ul class="common_list_horizontal">
                    <li><label><input type="radio" name="engine_do"
                      v-model="form.do"
                      value="する"
                      :checked="form.do === 'する'"><span class="radio_text">する&nbsp;&nbsp;</span></label></li>
                    <li><label><input type="radio" name="engine_do"
                      v-model="form.do"
                      value="しない"
                      :checked="form.do === 'しない'"><span class="radio_text">しない</span></label></li>
                  </ul>
                  <p class="alert" v-if="errors.do">{{ errors.do }}</p>
                </td>
              </tr>
              <tr>
                <th>燃料の種類</th>
                <td>
                  <ul class="common_list_horizontal">
                    <li><label><input type="radio" name="engine_type"
                      :disabled="isntDo"
                      v-model="form.type"
                      value="ガソリン"
                      :checked="form.type === 'ガソリン'"><span class="radio_text">ガソリン&nbsp;&nbsp;</span></label></li>
                    <li><label><input type="radio" name="engine_type"
                      :disabled="isntDo"
                      v-model="form.type"
                      value="軽油"
                      :checked="form.type === '軽油'"><span class="radio_text">軽油</span></label></li>
                  </ul>
                  <p class="alert" v-if="errors.type">{{ errors.type }}</p>
                </td>
              </tr>
              <tr>
                <th>燃料の持込量</th>
                <td><input type="number" min="0" class="w6em"
                  :disabled="isntDo"
                  v-model="form.quantity"
                > リットル
                  <p class="alert" v-if="errors.quantity">{{ errors.quantity }}</p></td>
              </tr>
            </tbody>
          </table>


            <div class="button_ctn">
              <ul>
                <li><button @click="modalClose" class="gray_button modalClose">キャンセル</button></li>
                <li><button
                  @click="sendForm"
                  class="regist_button"
                >上記内容で登録</button></li>
              </ul>
            </div>
            <!-- modal contents ここまで-->

          </div>
        </div>
        <!-- / モーダルコンテンツ -->
      </div>
    </div>
    <!-- / モーダルコンテンツ -->
  </Teleport>
</template>

<script setup>
import { reactive, computed, watch } from 'vue'
import { forEach, cloneDeep } from 'lodash'

import APIClient from '@/store/repository/APIClient'
import * as yup from 'yup'
import moment from "moment"
import { FormValidate } from '@/helper/FormValidate'

import {
  modalOpen,
  modalClose,
} from '@/helper/modal'

import ApiConst from '@/const/api'

import { isShowable as isShowUI } from '@/helper/indexStatus'

const api = new APIClient()

const formInit = {
  application_id: null,
  do: "",
  type: "",
  quantity: "",
}

const form = reactive(cloneDeep(formInit))
const currentData = reactive(cloneDeep(formInit))

const isntDo = computed(() => {
  if (form.do === 'しない') return true
  return false
})

watch(form, (el) => {
  if (el.do === 'しない') {
    form.type = formInit.type
    form.quantity = formInit.quantity
  }
})

const isNew = computed(() => {
  return !currentData.application_id
})

const uploadLabel = computed(() => {
  return !isNew.value ? '変更' : '新規登録'
})

const fetch = async () => {
  try {
    const query = {
      form_no: 8,
      application_div : "prohibited_acts",
    }
    const fetchApi = await api.get(ApiConst.API_APP_LIST, query)
    const res = fetchApi.response
    const errors = res.data?.error_results
    if (errors) {
      throw new Error('Not found.')
    }
    const data = res.data.results.list.map((el) => {
      return {
        application_id: el.application_id,
        input_date: moment(el.updated_at).format("YY-MM-DD"),
        seq_no: el.seq_no,
        ...el.json_data
      }
    })[0]
    forEach(currentData, (v, k) => {
      currentData[k] = data[k]
    })
    forEach(form, (v, k) => {
      form[k] = data[k] ?? formInit[k]
    })
  } catch (e) {
    console.log(e)
    return false
  }
}
fetch()

const newHandler = (event) => {
  forEach(formInit, (v, k) => {
    form[k] = v
  })
  modalOpen(event)
}

const editHandler = (event) => {
  modalOpen(event)
}

const scheme = {
  do: yup.string().trim().required('必須の項目です。'),
  type: yup.string().trim().test('type', '必須の項目です。', function(v) {
    if (this.parent.do !== 'する') return true
    if (v !== '') return true
    return false
  }),
  quantity: yup.string().test('quantity', '持込量を入力してください。', function(v) {
    if (this.parent.do !== 'する') return true
    if (v !== '') {
      if (parseInt(v, 10) > 0) return true
    }
    return false
  }),
}
const validate = new FormValidate(scheme, form)
const errors = validate.errors
const sendForm = async () => {
  try {
    if ((Object.keys(await validate.getErrorObject()).length)) {
      throw new Error('入力項目に不備があります。')
    }
    const params = cloneDeep(form)
    const application_id = params.application_id
    delete params.application_id
    const operation = application_id ? "R" : "I"
    const query = operation === "I"
      ? {operation, application_div : "prohibited_acts", form_no: 8, json_data: params}
      : {operation, application_id, json_data: params}
    const res = await api.post(ApiConst.API_APP_EDIT, query)
    if (res.response.data?.error_results) {
      throw new Error('API Validation Error')
    }
    fetch()
    modalClose()
  } catch (e) {
    return false
  }
}
</script>

<style></style>
