<template>
  <header><h1>JAPAN MOBILITY SHOW 2025&emsp;出展手続きサイト・出展者専用サイト</h1></header>
  <article><h2>申込完了</h2>
  <div id="form_ctn" class="form_box">

    <div id="complete_text">
      <SecondSteps step="4" />
      <h3>お申し込みありがとうございました。</h3>
      <p>これでJAPAN MOBILITY SHOW 2025への出展申込の手続きが完了致しました。<br>
お申し込みありがとうございました。<br>
今後の手続きやお知らせについては、出展者ページ（2025年春開設予定）や登録のメールアドレス宛にてお知らせします。
</p>
<div class="contact_ctn">
        <h4>出展に関するお問い合わせ・提出先</h4>
        <p>一般社団法人&emsp;日本自動車工業会<br>
次世代モビリティ領域・ショー担当<br>
TEL：03(5405)6127<br>
          <a href="https://www.japan-mobility-show.com/app/contact/" target="_blank">https://www.japan-mobility-show.com/app/contact/</a>
        </p>
      </div>
      <div class="button_ctn">
        <a class="submit" :href="pdf_url" download>お申し込み済みのPDFをダウンロード</a>
      
      </div>
      </div>


  </div>
</article>
</template>

<script>
import SecondSteps from './SecondSteps.vue';
export default {

components: {
  SecondSteps,
},
  computed: {
    pdf_url() {
      return this.$store.state.entrydata.pdf_url
    },
    
  }
}
</script>


