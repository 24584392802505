<template>
  <div id="admin">
    <header>
      <h1>JMS 2025 出展者登録DB 管理者用ページ</h1>
    </header>
    <article>

      <h2>出展者情報の一括入力</h2>
      <div id="form_ctn" class="form_box">

        <ErrorComponent></ErrorComponent>


        <h3>申込者リスト</h3>
        <p>検索条件：{{ admin_second_list_query }}</p>


        <table id="second_multiple_result_table" class="common_table">
          <thead>
            <tr>
              <th class="sort_th" @click="sortExhibitorName">出展者名</th>
              <th class="sort_th" @click="sortCancelFlag">キャン<br>セル</th>
              <th class="sort_th" @click="sortSecondOrderBango">申込<br>番号</th>
              <th class="sort_th" @click="sortSecondOrderDatetime">申込日</th>
              <th class="sort_th" @click="sortCategoryDiv1">出展<br>分類</th>
              <th class="sort_th" @click="sortMembershipCategory">会員<br>区分</th>
              <th class="sort_th" @click="sortKomaBango">小間<br>番号</th>
              <th class="sort_th" @click="sortHallDiv">ホール</th>
              <th class="sort_th" @click="sortOrderArea">申込面積</th>
              <th class="sort_th" @click="sortDecideArea">決定面積</th>
              <th class="sort_th" @click="sortUpdatedAt">最終更新日時</th>
            </tr>
          </thead>
          <tbody>
            <template v-for="(list, index) in admin_second_list" :key="index">

              <tr>
                <td>
                  <router-link :to="{ name: 'AdminSecondDetail', params: { id: list.exhibitor_id } }">
                    {{ list.exhibitor_name }}</router-link>
                </td>
                <td><label><input
                  type="checkbox"
                  v-model="list.cancel_flag"
                  true-value="1"
                  false-value="0"
                  name="cencel"
                ><span class="checkbox_text no_text"></span></label></td>
                <td>{{ list.second_order_bango }}</td>
                <td>{{ list.second_order_datetime }}</td>
                <td>{{ list.category_div1_label }}</td>
                <td>{{ list.membership_category_label }}</td>
                <td><input type="text" v-model="list.koma_bango" class="w6em"></td>
                <td>{{ hall_div }}
                  <div class="select_ctn">
                    <select v-model="list.hall_div">
                      <option v-for="(v, i) in hall_list" :key="i" :value="i">{{ v }}</option>
                    </select>
                  </div>
                </td>
                <td>{{ list.order_area }} {{ units_per_category(list.category_div1) }}</td>
                <td><input type="text" class="w6em ta_right" style="text-align:right" v-model="list.decide_area">{{ units_per_category(list.category_div1) }}</td>
                <td>{{ list.updated_at }} </td>
              </tr>
            </template>
          </tbody>
          <tfoot>
          </tfoot>
        </table>

        <ul class="common_list_horizontal">
          
          <li><router-link to="/admin/second_multiple_index" class="common_button">検索条件に戻る</router-link></li>
          <li v-if="enableFunctionCode('fc_0002_02')"><button class="common_button" @click="sendForm">登録</button></li>
        </ul>
      </div>



      <div class="button_ctn">
        <ul>
          <li><router-link :to="{ name: 'AdminIndex' }">メニュー画面に戻る</router-link>
          </li>
        </ul>
      </div>
    </article>
  </div>
</template>

<script>

import APIClient from '@/store/repository/APIClient'
import ErrorComponent from '@/components/ErrorComponent.vue';
import CATEGORY_DIV from '@/const/category_div.js';

import { enableFunctionCode } from '@/helper/adminAuthority'

import _ from 'lodash'

export default {
  components: {
    ErrorComponent,
  },
  setup() {
    return {
      enableFunctionCode,
    };
  },

  data() {
    return {
      hall_list: [],
    };
  },
  async created() {
    this.api = new APIClient()
    const res = await this.api.getConfig()
    this.$store.commit('disicion/membership_category_options_admin', res.data.results.membership_category_options_admin)
    this.$store.commit('disicion/category_div_options', res.data.results.category_div_options)
    this.hall_list = res.data.results.hall_div
  },

  methods: {
    units_per_category(cat) {
      if (cat === 10) return "㎡"
      if (cat === 20) return "㎡"
      if (cat === parseInt(CATEGORY_DIV.COLLABORATION_COOPERATION.value, 10)) return "㎡"
      return "小間"
    },
    async sendForm() {
      try {
        const query = {}

        this.admin_second_list.forEach((value, index) => {
          const i = parseInt(index) + 1
          query['exhibitor_id_' + i] = value.exhibitor_id
          query['cancel_flag_' + i] = parseInt(value.cancel_flag)
          query['koma_bango_' + i] = value.koma_bango
          query['decide_area_' + i] = parseInt(value.decide_area)
          query['hall_div_' + i] = parseInt(value.hall_div)
        })

        const res = await this.api.post('/api/admin/second_order/bulkedit', query)
        if (res.response.data?.error_results) {
          throw new Error('API Validation Error')
        }
        alert("一括更新処理が完了しました。")
      } catch (e) {
        console.error(e.message)
        alert("一括更新処理が失敗しました。")
        return false
      }
    },
    sort(attribute) {
      const first = this.admin_second_list[0][attribute]
      const last = this.admin_second_list[this.admin_second_list.length - 1][attribute]
      if (first > last) {
        this.$store.commit('search/admin_second_list', _.sortBy(this.admin_second_list, attribute))
      } else {
        this.$store.commit('search/admin_second_list', _.sortBy(this.admin_second_list, attribute).reverse())
      }
    },
    sortExhibitorName() {
      this.sort('exhibitor_name')
    },
    sortCancelFlag() {
      this.sort('cancel_flag')
    },
    sortSecondOrderBango() {
      this.sort('second_order_bango')
    },
    sortSecondOrderDatetime() {
      this.sort('second_order_datetime')
    },
    sortCategoryDiv1() {
      this.sort('category_div1')
    },
    sortMembershipCategory() {
      this.sort('membership_category')
    },
    sortKomaBango() {
      this.sort('koma_bango')
    },
    sortHallDiv() {
      this.sort('hall_div')
    },
    sortOrderArea() {
      this.sort('order_area')
    },
    sortDecideArea() {
      this.sort('decide_area')
    },
    sortUpdatedAt() {
      this.sort('updated_at')
    }
  },
  watch: {

  },
  computed: {
    admin_second_list_query() {
      return this.$store.state.search.admin_second_list_query?.join('　| ')
    },
    admin_second_list() {
      return this.$store.state.search.admin_second_list?.map(item => {
        if (item.membership_category === null ) item.membership_category = 10000
        return item
      });
    },
     
  }
};
</script>

<style>

</style>
