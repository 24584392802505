<template>
  <table class="common_table" v-if="list.length">
    <tbody>
      <tr>
        <th>入力日</th>
        <th>受付番号</th>
        <th>申込枚数</th>
        <th>合計金額 <br>
        （消費税込）</th>
        <th>納品先</th>
        <th>請求先</th>
        <th colspan="2">編集</th>
      </tr>

      <tr v-for="(val, key) in list" :key="key" :class="isAccepted(val.receipt_date) ? 'accepted' : ''">
        <td>{{ val.input_date }}</td>
        <td class="ta_right">{{ val.seq_no }}</td>
        <td class="ta_right">{{ val.applications_qty.toLocaleString() }} 冊</td>
        <td class="ta_right">{{ calcPriceByQty(val.applications_qty).toLocaleString() }} 円</td>
        <td>{{ getDeliveryName(val.delivery_id) }}</td>
        <td>{{ getBillName(val.billing_id) }}</td>
        <template v-if="isAccepted(val.receipt_date)">
          <td colspan="2">{{ moment(val.receipt_date).format('MM/DD') }}申込受付済</td>
        </template>
        <template v-else>
          <td class="edit_cell" v-if="isShowUI">
            <a @click.prevent="editHandler($event, key)" href="#form1" class="common_button_t2 modalOpen">変更</a>
          </td>
          <td class="edit_cell" v-if="isShowUI">
            <a @click.prevent="deleteHandler($event, key)" class="common_button_t2 gray_button delete_button">削除</a>
          </td>
        </template>
      </tr>
    </tbody>
  </table>
  <div class="no_entry" v-else>登録がありません。「新規追加」ボタンより登録をお願いします。</div>
  <template v-if="isShowUI">
  <div>
    <a @click.prevent="newHandler" href="#form1" class="common_add_button modalOpen">新規追加</a>
  </div>
  </template>

  <Teleport to="body">
    <!-- モーダルコンテンツ / -->
    <!-- 製作注意問い合わせ先は何種類かあります。読み込み形式で簡単に編集できるようにしたほうが良いかと思います。-->
    <div class="overlay" id="form1">
      <div id="modal_content" class="modal_contents w80">
        <!-- modal contents ここから-->
        <div class="modalClose_ctn">
          <a @click.prevent="modalClose" class="modalClose"></a>
        </div>
        <div class="entry_title">
          <h4>自動車ガイドブック(Vol.70)現物申込&nbsp;&nbsp;{{ uploadLabel }}</h4>
          <h5>自動車ガイドブック(Vol.70)現物申込</h5>
        </div>
        <div class="entry_modal_ctn">
          <table class="common_table">
            <tbody>
              <tr>
                <th>種類</th>
                <th>単価</th>
                <th>申込枚数</th>
                <th>合計金額（消費税込）</th>
              </tr>
              <tr>
                <td nowrap="">自動車ガイドブック(Vol.70)現物</td>
                <td class="ta_right">{{ unitCost.toLocaleString() }}円</td>
                <td class="ta_right"><input name="applications_qty" type="number" class="w6em"
                    v-model="form.applications_qty"> 枚
                  <p class="alert" v-if="errors.applications_qty">{{ errors.applications_qty }}</p>
                </td>
                <td class="ta_right">{{ calcPriceByQty(form.applications_qty).toLocaleString() }} 円</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="entry_title">
          <h5>納品先（入力必須）</h5>
        </div>
        <div class="entry_modal_ctn">
          <DeliveryRegistration
            :deliveryId="form.delivery_id"
            @emitSelectDelivery="selectDelivery"
            @emitFetch="fetchDeliveries"
          />
          <p class="alert" v-if="errors.delivery_id">{{ errors.delivery_id }}</p>
        </div>

        <div class="entry_modal_ctn">
          <BillRegistration
            :billingId="form.billing_id"
            @emitSelectBill="selectBill"
            @emitFetch="fetchBills"
          />
          <p class="alert" v-if="errors.billing_id">{{ errors.billing_id }}</p>
        </div>

        <div class="button_ctn">
          <ul>
            <li>
              <button @click.prevent="modalClose" class="gray_button modalClose">キャンセル</button>
            </li>
            <li>
              <button @click="sendForm" class="regist_button">
                上記内容で登録
              </button>
            </li>
          </ul>
        </div>
        <!-- modal contents ここまで-->
      </div>
    </div>
    <!-- / モーダルコンテンツ -->
  </Teleport>
</template>

<script setup>
import { reactive, computed } from 'vue'
import APIClient from '@/store/repository/APIClient'
import { forEach, cloneDeep } from 'lodash'
import { FormValidate } from '@/helper/FormValidate'
import * as yup from 'yup'
import moment from "moment"
import DeliveryRegistration from '@/components/DeliveryRegistration'
import BillRegistration from '@/components/BillRegistration'

import {
  modalOpen,
  modalClose,
} from '@/helper/modal'

import { useRoute } from 'vue-router'

import ApiConst from '@/const/api'

import { isShowable as isShowUI } from '@/helper/indexStatus'

import { isSpoofing } from '@/helper/adminSpoofingVars'

const route = useRoute()

const unitCost = route.meta.couponQtyUnitCost

const api = new APIClient()

const list = reactive([])
const deliveries = reactive([])
const bills = reactive([])

const isAccepted = date => {
  if (isSpoofing()) return false
  if (moment() > moment(date) ) return true
  return false
}

const formInit = {
  application_id: null,
  applications_qty: null,
  delivery_id: null,
  billing_id: null,
}
const form = reactive(cloneDeep(formInit))

const calcPriceByQty = (val) => {
  return val * unitCost
}

const uploadLabel = computed(() => {
  return form.application_id ? '変更' : '新規登録'
})

const getDeliveryName = (delivery_id) => {
  const data = deliveries.find(el => el.delivery_id === delivery_id)
  return data?.company_name
}
const selectDelivery = (i) => {
  form.delivery_id = i
}

const fetchDeliveries = async (values) => {
  deliveries.splice(0)
  forEach(values, (v, k) => {
    deliveries[k] = v
  })
}
fetchDeliveries()

const getBillName = (billing_id) => {
  const data = bills.find(el => el.billing_id === billing_id)
  return data?.company_name
}
const selectBill = (i) => {
  form.billing_id = i
}

const fetchBills = async (values) => {
  bills.splice(0)
  forEach(values, (v, k) => {
    bills[k] = v
  })
}
fetchBills()

const fetch = async () => {
  const query = {
    form_no: 1,
    application_div: "guidebook_order",
  }
  const fetchApi = await api.get(ApiConst.API_APP_LIST, query)
  const res = fetchApi.response
  const errors = res.data?.error_results
  if (errors) {
    throw new Error('Not found.')
  }
  list.splice(0)
  list.value = res.data.results.list.map((el) => {
    return {
      application_id: el.application_id,
      input_date: moment(el.updated_at).format("YY-MM-DD"),
      seq_no: el.seq_no,
      receipt_date: el.send_date,
      ...el.json_data
    }
  }).forEach((val, key) => {
    forEach(val, (v, k) => {
      val[k] = v ?? formInit[k]
    })
    list[key] = val
  })
}
fetch()

const newHandler = (event) => {
  forEach(formInit, (v, k) => {
    form[k] = v
  })
  modalOpen(event)
}

const editHandler = (event, key) => {
  forEach(form, (v, k) => {
    form[k] = list[key][k]
  })
  modalOpen(event)
}

const deleteHandler = async (event, key) => {
  if (!window.confirm('削除して宜しいですか？')) return false

  try {
    const application_id = list[key].application_id
    const operation = "D"
    const query = { operation, application_id }
    const res = await api.post(ApiConst.API_APP_EDIT, query)
    if (res.response.data?.error_results) {
      throw new Error('API Validation Error')
    }
    fetch()
  } catch (e) {
    return false
  }
}

const scheme = {
  applications_qty: yup.number().typeError("必須の項目です。半角数字を入力してください。").required('必須の項目です。'),
  delivery_id: yup.number().typeError("必須の項目です。").required('必須の項目です。'),
  billing_id: yup.number().typeError("必須の項目です。").required('必須の項目です。'),
}
const validate = new FormValidate(scheme, form)
const errors = validate.errors
const sendForm = async () => {
  try {
    if ((Object.keys(await validate.getErrorObject()).length)) {
      throw new Error('入力項目に不備があります。')
    }
    const params = cloneDeep(form)
    params.total_price = calcPriceByQty(params.applications_qty)
    const application_id = params.application_id
    delete params.application_id
    const operation = application_id ? "R" : "I"
    const query = operation === "I"
      ? { operation, application_div: "guidebook_order", form_no: 1, json_data: params }
      : { operation, application_id, json_data: params }
    const res = await api.post(ApiConst.API_APP_EDIT, query)
    if (res.response.data?.error_results) {
      throw new Error('API Validation Error')
    }
    fetch()
    modalClose()
  } catch (e) {
    return false
  }
}
</script>

<style></style>
