<template>
  <table class="common_table">
    <tbody>
      <tr>
        <th>利用日</th>
        <th>単価</th>
        <th>申込枚数</th>
        <th>金額 （消費税込）</th>
      </tr>
      <tr
        v-for="(v,k) in assignmentDateOptions"
        :key="k"
      >
        <td>{{ formatDate(v) }}</td>
        <td class="ta_right"><span class="ta_right">{{ unitCost.toLocaleString() }} 円</span></td>
        <td class="ta_right" :set="qty = searchDate(v, currentData.application)?.qty">{{ qty ? qty + ' 枚' : '' }}</td>
        <td class="ta_right" :set="cp = calcPrice(searchDate(v, currentData.application)?.qty)">{{ cp ? cp.toLocaleString() + ' 円' : '' }}</td>
      </tr>
      <tr>
        <th colspan="2">合計</th>
        <td class="ta_right">{{ calcTotal }} 枚</td>
        <td class="ta_right">{{ calcTotalPrice.toLocaleString() }} 円</td>
      </tr>
    </tbody>
  </table>
  <template v-if="isShowUI">
  <div><a
    v-if="isNew"
    @click.prevent="newHandler"
    href="#form1"
    class="common_add_button modalOpen"
  >新規追加</a><a
    v-else
    @click.prevent="editHandler"
    href="#form1"
    class="common_add_button modalOpen"
  >変更</a></div>
  </template>
  <Teleport to="body">
    <!-- モーダルコンテンツ / -->
    <div class="overlay" id="form1">
      <div id="modal_content" class="modal_contents w80">
        <div class="modalClose_ctn"><a @click.prevent="modalClose" class="modalClose"></a></div>
        <!-- modal contents ここから-->
        <div class="entry_title">
          <h4>地下駐車場パスカード申込</h4>
          <h5>地下駐車場パスカード申込&nbsp;&nbsp;{{uploadLabel}}</h5>

          <div class="entry_modal_ctn">
          <table class="common_table">
            <tbody>
              <tr>
                <th>利用日</th>
                <th>単価</th>
                <th>申込枚数</th>
                <th>合計金額 <br>
                （消費税込）</th>
              </tr>
              <tr
                v-for="(v,k) in assignmentDateOptions"
                :key="k"
              >
                <td>{{ formatDate(v) }}</td>
                <td class="ta_right">{{ unitCost.toLocaleString() }}円</td>
                <td class="ta_right"><input type="number" min="0" class="w6em" v-model="form.application[k].qty"> 枚
                  <p class="alert" v-if="errors[`application[${k}].qty`]">{{ errors[`application[${k}].qty`] }}</p></td>
                <td class="ta_right">{{ calcPrice(form.application[k].qty).toLocaleString() }} 円</td>
              </tr>
            </tbody>
          </table>


            <div class="button_ctn">
              <ul>
                <li><button @click="modalClose" class="gray_button modalClose">キャンセル</button></li>
                <li><button
                  @click="sendForm"
                  class="regist_button"
                >上記内容で登録</button></li>
              </ul>
            </div>
            <!-- modal contents ここまで-->

          </div>
        </div>
        <!-- / モーダルコンテンツ -->
      </div>
    </div>
    <!-- / モーダルコンテンツ -->
  </Teleport>
</template>

<script setup>
import { reactive, computed } from 'vue'
import { store } from '@/store'
import { forEach, cloneDeep, isNumber } from 'lodash'

import APIClient from '@/store/repository/APIClient'
import * as yup from 'yup'
import moment from "moment"
import { FormValidate } from '@/helper/FormValidate'
import PARKING_CARD from '@/const/parking_card'

import {
  modalOpen,
  modalClose,
} from '@/helper/modal'

import {
  isValue
} from '@/helper/index';

import ApiConst from '@/const/api'

import { isShowable as isShowUI } from '@/helper/indexStatus'


const pList = computed(() => {
  return store.state.parking_card.p_list
})

const assignmentDateOptions = [
  "20251025",
  "20251026",
]

const unitCost = PARKING_CARD.UNIT_COST

const calcPrice = (val) => {
  return val * unitCost
}

const formatDate = (val) => {
  return moment(val).format('M月D日（ddd）')
}

const api = new APIClient()

const applicationInit = {
  "date" : "",
  "qty" : "",
}
const formInit = {
  application_id: null,
  application: assignmentDateOptions.map((v) => {
    return {
      ...applicationInit,
      "date" : v,
    }
  }),
}

const form = reactive(cloneDeep(formInit))
const currentData = reactive(cloneDeep(formInit))

const searchDate = (v, currentData) => currentData.find((el) => el.date === v)

const calcTotal = computed(() => {
  return currentData?.application.reduce((acc, cur) => acc + cur.qty, 0)
})

const calcTotalPrice = computed(() => {
  return calcTotal.value * unitCost
})

const isNew = computed(() => {
  return !currentData.application_id
})

const uploadLabel = computed(() => {
  return !isNew.value ? '変更' : '新規登録'
})

const fetch = async () => {
  try {
    const query = {
      form_no: 2,
      application_div : "parking_card",
    }
    const fetchApi = await api.get(ApiConst.API_APP_LIST, query)
    const res = fetchApi.response
    const errors = res.data?.error_results
    if (errors) {
      throw new Error('Not found.')
    }
    const _formInit = cloneDeep(formInit)
    const data = res.data.results.list.map((el) => {
      el.json_data.application.forEach((val, key) => {
        forEach(el.json_data.application[key], ((v, k) => {
          if (!isValue(v)) el.json_data.application[key][k] = applicationInit[k]
        }))
      })
      return {
        application_id: el.application_id,
        input_date: moment(el.updated_at).format("YY-MM-DD"),
        seq_no: el.seq_no,
        ...el.json_data
      }
    })[0]
    const _currentData = cloneDeep(data)
    const _form = cloneDeep(data)
    forEach(currentData, (v, k) => {
      currentData[k] = _currentData[k]
    })
    forEach(form, (v, k) => {
      form[k] = _form[k] ?? _formInit[k]
    })
  } catch (e) {
    console.log(e)
    return false
  }
}
fetch()

const newHandler = (event) => {
  forEach(formInit, (v, k) => {
    form[k] = v
  })
  modalOpen(event)
}

const editHandler = (event) => {
  modalOpen(event)
}

const objectSchema = yup.object().shape({
  qty: yup.string().test('qty',
    function(el) {
      try {
        const index = el.path.match(/application\[(\d+)\]/)[1]
        const max = Object.values(pList.value)[index]
        errors[el.path] = `この日のパスカードは残り${max}枚です。`
        return `この日のパスカードは残り${max}枚です。`
      } catch (e) {
        console.log(e)
        return '不明なエラーです。'
      }
    },
    async function(val, el) {
      try {
        const value = parseInt(val)
        if (!isNumber(value)) return false
        const index = el.path.match(/application\[(\d+)\]/)[1]
        const max = Object.values(pList.value)[index]
        if (value > max) {
          return false
        }
        return true
      } catch (e) {
        console.log(e)
        return false
      }
    }
  ),
})
const scheme = {
  application: yup.array().of(objectSchema),
}
const validate = new FormValidate(scheme, form)
const errors = validate.errors
const sendForm = async () => {
  await store.dispatch('parking_card/update')
  try {
    if ((Object.keys(await validate.getErrorObject()).length)) {
      throw new Error('入力項目に不備があります。')
    }
    const params = cloneDeep(form)
    const application_id = params.application_id
    delete params.application_id
    const operation = application_id ? "R" : "I"
    const query = operation === "I"
      ? {operation, application_div : "parking_card", form_no: 2, json_data: params}
      : {operation, application_id, json_data: params}
    const res = await api.post(ApiConst.API_APP_EDIT, query)
    if (res.response.data?.error_results) {
      throw new Error('API Validation Error')
    }
    fetch()
    store.dispatch('parking_card/update', false)
    modalClose()
  } catch (e) {
    console.log(e, errors)
    return false
  }
}
</script>

<style></style>
