export const indoorVisible = function (target) {
  if (target.category_div?.category_div?.includes('10')) return true
  if (target.category_div?.category_div_20?.includes('201')) return true
  return false
};

export const outdoorVisible = function(target) {
 if (target.category_div?.category_div_20?.includes('202')) return true
  return false
};
export const partsMemberVisible = function(target) {
  if (target.category_div?.category_div?.includes('30') && target.membership_category?.includes('2')) return true
  if (target.category_div?.category_div?.includes('30') && target.membership_category?.includes('4')) return true
  if (target.category_div?.category_div?.includes('40') && target.membership_category?.includes('2')) return true
  if (target.category_div?.category_div?.includes('40') && target.membership_category?.includes('4')) return true
  return false
};
export const partsVisible = function(target) {
  if (target.category_div?.category_div?.includes('30') && target.membership_category?.includes('99')) return true
  if (target.category_div?.category_div?.includes('40') && target.membership_category?.includes('99')) return true
  return false
};
export const mobilityMemberVisible = function(target) {
  if (target.category_div?.category_div?.includes('50') && target.membership_category?.includes('2')) return true
  if (target.category_div?.category_div?.includes('50') && target.membership_category?.includes('4')) return true
  return false
};
export const mobilityVisible = function(target) {
  if (target.membership_category?.includes('2')) return false
  if (target.membership_category?.includes('4')) return false
  if (target.category_div?.category_div?.includes('50')) return true
  return false
};
export const kidzaniaMemberVisible = function(target) {
  if (target.category_div?.category_div?.includes('60') && target.membership_category?.includes('99')) return false
  if (target.category_div?.category_div?.includes('60')) return true
  return false
};
export const kidzaniaVisible = function(target) {
  if (target.category_div?.category_div?.includes('60') && target.membership_category?.includes('99')) return true
  return false
};

export const otherVisible = function(target) {
  if (target.category_div?.category_div?.includes('99')) return true
  return false
};
