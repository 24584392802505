<template>
  <table class="common_table" v-if="!isNew">
    <tbody>
      <tr>
        <th>入力日</th>
        <th>申込 <br>
          枚数</th>
        <th>合計金額 <br>
          （消費税込）</th>
        <th>納品先</th>
        <th>請求先</th>
        <th v-if="isAccepted(currentData.receipt_date)"></th>
      </tr>

      <tr :class="isAccepted(currentData.receipt_date) ? 'accepted' : ''">
        <td>{{ currentData.input_date }}</td>
        <td class="ta_right">{{ currentData.applications_qty.toLocaleString() }} 枚</td>
        <td class="ta_right">{{ calcPriceByQty(currentData.applications_qty).toLocaleString() }} 円</td>
        <td>{{ getDeliveryName(currentData.delivery_id) }}</td>
        <td>{{ getBillName(currentData.billing_id) }}</td>
        <template v-if="isAccepted(currentData.receipt_date)">
          <td colspan="2">{{ moment(currentData.receipt_date).format('MM/DD') }}申込受付済</td>
        </template>
      </tr>
    </tbody>
  </table>
  <div class="no_entry" v-else>登録がありません。「新規追加」ボタンより登録をお願いします。</div>
  <template v-if="isShowUI">
  <div v-if="!isAccepted(currentData.receipt_date)"><a v-if="isNew" @click.prevent="newHandler" href="#form2" class="common_add_button modalOpen">新規追加</a><a v-else
      @click.prevent="editHandler" href="#form2" class="common_add_button modalOpen">変更</a>&nbsp;&nbsp;<a
      @click.prevent="deleteHandler" href="#form2" class="common_add_button gray_button">クリア</a></div>
  </template>

  <Teleport to="body">
    <!-- モーダルコンテンツ / -->
    <!-- 製作注意問い合わせ先は何種類かあります。読み込み形式で簡単に編集できるようにしたほうが良いかと思います。-->
    <div class="overlay" id="form2">
      <div id="modal_content" class="modal_contents w80">
        <!-- modal contents ここから-->
        <div class="modalClose_ctn">
          <a @click.prevent="modalClose" class="modalClose"></a>
        </div>
        <div class="entry_title">
          <h4>出展関係業者バッジ申込</h4>
          <h5>出展関係業者バッジ申込&nbsp;&nbsp;{{ uploadLabel }}</h5>
        </div>
        <div class="entry_modal_ctn">
          <table class="common_table">
            <tbody>
              <tr>
                <th>種類</th>
                <th>単価</th>
                <th>申込枚数</th>
                <th>合計金額（消費税込）</th>
              </tr>
              <tr>
                <td nowrap="">関係業者バッジ</td>
                <td class="ta_right">{{ unitCost.toLocaleString() }}円</td>
                <td class="ta_right"><input name="applications_qty" type="number" class="w6em"
                    v-model="form.applications_qty"> 枚
                  <p class="alert" v-if="errors.applications_qty">{{ errors.applications_qty }}</p>
                </td>
                <td class="ta_right">{{ calcPriceByQty(form.applications_qty).toLocaleString() }} 円</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="entry_title">
          <h5>納品先（入力必須）</h5>
        </div>
        <div class="entry_modal_ctn">
          <DeliveryRegistration
            :deliveryId="form.delivery_id"
            @emitSelectDelivery="selectDelivery"
            @emitFetch="fetchDeliveries"
          />
          <p class="alert" v-if="errors.delivery_id">{{ errors.delivery_id }}</p>
        </div>

        <div class="entry_modal_ctn">
          <BillRegistration
            :billingId="form.billing_id"
            @emitSelectBill="selectBill"
            @emitFetch="fetchBills"
          />
          <p class="alert" v-if="errors.billing_id">{{ errors.billing_id }}</p>
        </div>

        <div class="button_ctn">
          <ul>
            <li>
              <button @click.prevent="modalClose" class="gray_button modalClose">キャンセル</button>
            </li>
            <li>
              <button @click="sendForm" class="regist_button">
                上記内容で登録
              </button>
            </li>
          </ul>
        </div>
        <!-- modal contents ここまで-->
      </div>
    </div>
    <!-- / モーダルコンテンツ -->
  </Teleport>
</template>

<script setup>
import { reactive, computed } from 'vue'
import APIClient from '@/store/repository/APIClient'
import { forEach, cloneDeep } from 'lodash'
import { FormValidate } from '@/helper/FormValidate'
import * as yup from 'yup'
import moment from "moment"
import DeliveryRegistration from '@/components/DeliveryRegistration'
import BillRegistration from '@/components/BillRegistration'

import {
  modalOpen,
  modalClose,
} from '@/helper/modal'

import ApiConst from '@/const/api'

import { isShowable as isShowUI } from '@/helper/indexStatus'

import { isSpoofing } from '@/helper/adminSpoofingVars'

const unitCost = 100

const api = new APIClient()

const deliveries = reactive([])
const bills = reactive([])

const isAccepted = date => {
  if (isSpoofing()) return false
  if (moment() > moment(date) ) return true
  return false
}

const formInit = {
  application_id: null,
  receipt_date: null,
  applications_qty: null,
  delivery_id: null,
  billing_id: null,
}
const form = reactive(cloneDeep(formInit))
const currentData = reactive(cloneDeep(formInit))

const isNew = computed(() => {
  return !currentData.application_id
})

const calcPriceByQty = (val) => {
  return val * unitCost
}

const uploadLabel = computed(() => {
  return form.application_id ? '変更' : '新規登録'
})

const getDeliveryName = (delivery_id) => {
  const data = deliveries.find(el => el.delivery_id === delivery_id)
  return data?.company_name
}
const selectDelivery = (i) => {
  form.delivery_id = i
}

const fetchDeliveries = async (values) => {
  deliveries.splice(0)
  forEach(values, (v, k) => {
    deliveries[k] = v
  })
}
fetchDeliveries()

const getBillName = (billing_id) => {
  const data = bills.find(el => el.billing_id === billing_id)
  return data?.company_name
}
const selectBill = (i) => {
  form.billing_id = i
}

const fetchBills = async (values) => {
  bills.splice(0)
  forEach(values, (v, k) => {
    bills[k] = v
  })
}
fetchBills()

const fetch = async () => {
  try {
    const query = {
      form_no: 2,
      application_div: "entrance_ticket",
    }
    const fetchApi = await api.get(ApiConst.API_APP_LIST, query)
    const res = fetchApi.response
    const errors = res.data?.error_results
    if (errors) {
      throw new Error('Not found.')
    }
    forEach(currentData, (v, k) => {
      currentData[k] = formInit[k]
    })
    const data = res.data.results.list.map((el) => {
      delete el.json_data.receipt_date
      return {
        application_id: el.application_id,
        input_date: moment(el.updated_at).format("YY-MM-DD"),
        seq_no: el.seq_no,
        receipt_date: el.send_date,
        ...el.json_data
      }
    })[0]
    forEach({...currentData, ...{input_date: null}}, (v, k) => {
      currentData[k] = data[k]
    })
    forEach(form, (v, k) => {
      form[k] = data[k] ?? formInit[k]
    })
  } catch (e) {
    console.log(e)
    return false
  }
}
fetch()

const newHandler = (event) => {
  forEach(formInit, (v, k) => {
    form[k] = v
  })
  modalOpen(event)
}

const editHandler = (event) => {
  modalOpen(event)
}

const deleteHandler = async () => {
  if (!window.confirm('クリアして宜しいでしょうか？')) return false

  try {
    const application_id = currentData.application_id
    const operation = "D"
    const query = { operation, application_id }
    const res = await api.post(ApiConst.API_APP_EDIT, query)
    if (res.response.data?.error_results) {
      throw new Error('API Validation Error')
    }
    fetch()
  } catch (e) {
    return false
  }
}

const scheme = {
  applications_qty: yup.number().typeError("必須の項目です。半角数字を入力してください。").required('必須の項目です。'),
  delivery_id: yup.number().typeError("必須の項目です。").required('必須の項目です。'),
  billing_id: yup.number().typeError("必須の項目です。").required('必須の項目です。'),
}
const validate = new FormValidate(scheme, form)
const errors = validate.errors
const sendForm = async () => {
  try {
    if ((Object.keys(await validate.getErrorObject()).length)) {
      throw new Error('入力項目に不備があります。')
    }
    const params = cloneDeep(form)
    params.total_price = calcPriceByQty(params.applications_qty)
    const application_id = params.application_id
    delete params.application_id
    const operation = application_id ? "R" : "I"
    const query = operation === "I"
      ? { operation, application_div: "entrance_ticket", form_no: 2, json_data: params }
      : { operation, application_id, json_data: params }
    const res = await api.post(ApiConst.API_APP_EDIT, query)
    if (res.response.data?.error_results) {
      throw new Error('API Validation Error')
    }
    fetch()
    modalClose()
  } catch (e) {
    return false
  }
}
</script>

<style></style>
