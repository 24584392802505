export default {
  namespaced: true,
  state: {
  },
  mutations: {
    errors(state, payload) {
      state.errors = payload
    },
  },
  actions: {
    initializeErrors (context) {
      context.commit('errors', []);
    },
  },
}
