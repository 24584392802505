<template>
  <table class="common_table" v-if="list.length">
    <tbody>
      <tr>
        <th>入力日</th>
        <th>希望枚数</th>
        <th>納品先</th>
        <th colspan="2" v-if="isShowUI">編集</th>
      </tr>

      <tr v-for="(val, key) in list" :key="key">
        <td>{{ val.input_date }}</td>
        <td v-html="val.quantityText"></td>
        <td>{{ val.company_name }}</td>
        <td class="edit_cell" v-if="isShowUI">
          <a @click.prevent="editHandler($event, key)" href="#modal1" class="common_button_t2 modalOpen">変更</a>
        </td>
        <td class="edit_cell" v-if="isShowUI">
          <a @click.prevent="deleteHandler($event, key)" class="common_button_t2 gray_button delete_button">削除</a>
        </td>
      </tr>
    </tbody>
  </table>
  <div class="no_entry" v-else>登録がありません。「新規追加」ボタンより登録をお願いします。</div>
  <template v-if="isShowUI">
  <div>
    <a @click.prevent="newHandler" href="#modal1" class="common_add_button modalOpen">新規追加</a>
  </div>
  </template>

  <Teleport to="body">
    <!-- モーダルコンテンツ / -->
    <!-- 製作注意問い合わせ先は何種類かあります。読み込み形式で簡単に編集できるようにしたほうが良いかと思います。-->
    <div class="overlay" id="modal1">
      <div id="modal_content" class="modal_contents w80">
        <!-- modal contents ここから-->
        <div class="modalClose_ctn">
          <a @click.prevent="modalClose" class="modalClose"></a>
        </div>
        <div class="entry_title">
          <h4>宣伝印刷物申込</h4>
          <h5>宣伝印刷物の種類・枚数</h5>
        </div>
        <div class="entry_modal_ctn">
          <table class="common_table">
            <tbody>
              <tr>
                <th>種類</th>
                <th>希望枚数</th>
              </tr>
              <tr>
                <td>ポスター&nbsp;&nbsp;B1判（サイズ：W728×H1,030）</td>
                <td align="right">
                  <input id="quantity1" name="quantity1" v-model="form.quantity1" type="text" size="5"
                    style="ime-mode: disabled" class="three_quarters ta_right" />枚
                </td>
              </tr>
              <tr>
                <td>ポスター&nbsp;&nbsp;B3判（サイズ：W515×H364）</td>
                <td align="right">
                  <input id="quantity2" name="quantity2" v-model="form.quantity2" type="text" size="5"
                    style="ime-mode: disabled" class="three_quarters ta_right" />枚
                </td>
              </tr>
              <tr>
                <td>事前広報パンフレット（和文）（サイズ：W105×H210）</td>
                <td align="right">
                  <input id="quantity3" name="quantity3" v-model="form.quantity3" type="text" size="5"
                    style="ime-mode: disabled" class="three_quarters ta_right" />枚
                </td>
              </tr>
              <tr>
                <td>事前広報パンフレット（英文）（サイズ：W105×H210）</td>
                <td align="right">
                  <input id="quantity4" name="quantity4" v-model="form.quantity4" type="text" size="5"
                    style="ime-mode: disabled" class="three_quarters ta_right" />枚
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="entry_title">
          <h5>納品先（入力必須）</h5>
        </div>
        <div class="entry_modal_ctn">
          <table class="common_table">
            <tbody>
              <tr>
                <th colspan="2" class="required">会社名</th>
                <td><input type="text" class="full" v-model="form.company_name">
                <p class="alert" v-if="errors.company_name">{{ errors.company_name }}</p></td>
              </tr>
              <tr>
                <th colspan="2" class="required">会社名カナ</th>
                <td><input type="text" class="full" v-model="form.company_name_kana">
                <p class="alert" v-if="errors.company_name_kana">{{ errors.company_name_kana }}</p></td>
              </tr>
              <tr>
                <th rowspan="3">所在地</th>
                <th class="required">郵便番号</th>
                <td>
                  <input type="text" class="quarter" v-model="form.company_zip" @keyup="onZipChange">
                  <p class="alert" v-if="errors.company_zip">{{ errors.company_zip }}</p>
                </td>
              </tr>

              <tr>
                <th class="required">都道府県</th>
                <td>
                  <div class="select_ctn">
                    <select v-model="form.company_pref">
                      <option value="">都道府県</option>
                      <option
                        v-for="(v,k) in addressPrefOptions"
                        :key="k"
                        :value="v.label"
                        :selected="form.company_pref === v.label"
                      >{{ v.label }}</option>
                    </select>
                  </div>
                  <p class="alert" v-if="errors.company_pref">{{ errors.company_pref }}</p>
                </td>
              </tr>
              <tr>
                <th class="required">住所</th>
                <td><input name="delivery_worker_address" type="text" size="40" class="full" v-model="form.company_address">
                <p class="alert" v-if="errors.company_address">{{ errors.company_address }}</p>
                </td>
              </tr>
              <tr>
                <th rowspan="7">担当者</th>
                <th>部署</th>
                <td><input name="delivery_quarter_name" type="text" class="quarter" v-model="form.staff_affiliation">
                <p class="alert" v-if="errors.staff_affiliation">{{ errors.staff_affiliation }}</p></td>
              </tr>
              <tr>
                <th>役職</th>
                <td><input name="delivery_quarter_name" type="text" class="quarter" v-model="form.staff_post">
                <p class="alert" v-if="errors.staff_post">{{ errors.staff_post }}</p></td>
              </tr>
              <tr>
                <th class="required">氏名</th>
                <td><input name="delivery_prepared_name" type="text" size="30" class="validate[required]" v-model="form.staff_name">
                <p class="alert" v-if="errors.staff_name">{{ errors.staff_name }}</p></td>
              </tr>
              <tr>
                <th class="required">氏名フリガナ</th>
                <td><input name="delivery_prepared_name" type="text" size="30" class="validate[required]" v-model="form.staff_name_kana">
                <p class="alert" v-if="errors.staff_name_kana">{{ errors.staff_name_kana }}</p></td>
              </tr>
              <tr>
                <th>電話番号</th>
                <td><input type="text" name="delivery_worker_tel" class="validate[required]" v-model="form.staff_tel1">
                <p class="alert" v-if="errors.staff_tel1">{{ errors.staff_tel1 }}</p></td>
              </tr>
              <tr>
                <th class="required">緊急連絡先</th>
                <td><input type="text" name="delivery_worker_tel2" class="validate[required]" v-model="form.staff_tel2">
                <p class="alert" v-if="errors.staff_tel2">{{ errors.staff_tel2 }}</p>
                </td>
              </tr>

              <tr>
                <th>E-mail</th>
                <td><input type="text" name="delivery_worker_email" class="validate[custom[email]]" v-model="form.staff_email">
                <p class="alert" v-if="errors.staff_email">{{ errors.staff_email }}</p></td>
              </tr>

            </tbody>
          </table>
        </div>

        <div class="button_ctn">
          <ul>
            <li>
              <button @click.prevent="modalClose" class="gray_button modalClose">キャンセル</button>
            </li>
            <li>
              <button @click="sendForm" class="regist_button">
                上記内容で登録
              </button>
            </li>
          </ul>
        </div>
        <!-- modal contents ここまで-->
      </div>
    </div>
    <!-- / モーダルコンテンツ -->
  </Teleport>
</template>


<script setup>
import { reactive } from 'vue'
import APIClient from '@/store/repository/APIClient'
import { forEach, cloneDeep } from 'lodash'
import { FormValidate } from '@/helper/FormValidate'
import * as yup from 'yup'
import moment from "moment"
import KenAll from 'ken-all'
import ADDRESS from '@/const/address_elements.js'

import {
  modalOpen,
  modalClose,
} from '@/helper/modal'

import {
  isKana,
} from '@/helper/index'

import ApiConst from '@/const/api'

import { isShowable as isShowUI } from '@/helper/indexStatus'

const addressPrefOptions = ADDRESS.address_pref_options.filter((el => el.value !== '99'))

const api = new APIClient()

const list = reactive([])

const formInit = {
  application_id: null,
  quantity1: null,
  quantity2: null,
  quantity3: null,
  quantity4: null,
  company_name: "",
  company_name_kana: "",
  company_zip: "",
  company_pref: "",
  company_address: "",
  staff_affiliation: "",
  staff_post: "",
  staff_name: "",
  staff_name_kana: "",
  staff_tel1: "",
  staff_tel2: "",
  staff_email: "",
}
const form = reactive(cloneDeep(formInit))


const fetch = async () => {
  const query = {
    form_no: 1,
    application_div: "advertising_print",
  }
  const fetchApi = await api.get(ApiConst.API_APP_LIST, query)
  const res = fetchApi.response
  const errors = res.data?.error_results
  if (errors) {
    throw new Error('Not found.')
  }
  list.splice(0)
  res.data.results.list.map((el) => {
    return {
      application_id: el.application_id,
      input_date: moment(el.updated_at).format("YY-MM-DD"),
      seq_no: el.seq_no,
      ...el.json_data
    }
  }).forEach((val, key) => {
    const quantities = [
      `ポスターB1判&nbsp;&nbsp;${val.quantity1 ?? 0}枚`,
      `ポスターB3判&nbsp;&nbsp;${val.quantity2 ?? 0}枚`,
      `パンフレット（和文）&nbsp;&nbsp;${val.quantity3 ?? 0}枚`,
      `パンフレット（英文）&nbsp;&nbsp;${val.quantity4 ?? 0}枚`,
    ]
    val.quantityText = quantities.join('<br>')
    forEach(val, (v, k) => {
      val[k] = v ?? formInit[k]
    })
    list[key] = val
  })
}
fetch()

const onZipChange = (e) => {
  const value = e.target.value.replace(/[^0-9]/g, '')
  if (value.length !== 7) return
  KenAll(value).then(res => {
    if (res.length === 0) return
    const address = res[0]
    const prefecture = address.shift()
    const pref_match = addressPrefOptions.find(item => item.label === prefecture)
    form.company_pref = pref_match.label
    form.company_address = address.join('')
  })
}

const newHandler = (event) => {
  forEach(formInit, (v, k) => {
    form[k] = v
  })
  modalOpen(event)
}

const editHandler = (event, key) => {
  forEach(form, (v, k) => {
    form[k] = list[key][k]
  })
  modalOpen(event)
}

const deleteHandler = async (event, key) => {
  if (!window.confirm('削除して宜しいですか？')) return false

  try {
    const application_id = list[key].application_id
    const operation = "D"
    const query = { operation, application_id }
    const res = await api.post(ApiConst.API_APP_EDIT, query)
    if (res.response.data?.error_results) {
      throw new Error('API Validation Error')
    }
    fetch()
  } catch (e) {
    return false
  }
}

const scheme = {
  company_name: yup.string().trim().required('必須の項目です。'),
  company_name_kana: yup.string().test('katakana-checker', 'カタカナで入力して下さい。', isKana).trim().required('必須の項目です。'),
  company_zip: yup.string().trim().required('必須の項目です。'),
  company_pref: yup.string().trim().required('必須の項目です。'),
  company_address: yup.string().trim().required('必須の項目です。'),
  staff_affiliation: yup.string().trim(),
  staff_post: yup.string().trim(),
  staff_name: yup.string().trim().required('必須の項目です。'),
  staff_name_kana: yup.string().test('katakana-checker', 'カタカナで入力して下さい。', isKana).trim().required('必須の項目です。'),
  staff_tel1: yup.string().trim(),
  staff_tel2: yup.string().trim().required('必須の項目です。'),
  staff_email: yup.string().trim().email('メールアドレスの形式が不正です。'),
}
const validate = new FormValidate(scheme, form)
const errors = validate.errors
const sendForm = async () => {
  try {
    if ((Object.keys(await validate.getErrorObject()).length)) {
      throw new Error('入力項目に不備があります。')
    }
    const params = cloneDeep(form)
    const application_id = params.application_id
    delete params.application_id
    const operation = application_id ? "R" : "I"
    const query = operation === "I"
      ? { operation, application_div: "advertising_print", form_no: 1, json_data: params }
      : { operation, application_id, json_data: params }
    const res = await api.post(ApiConst.API_APP_EDIT, query)
    if (res.response.data?.error_results) {
      throw new Error('API Validation Error')
    }
    fetch()
    modalClose()
  } catch (e) {
    return false
  }
}
</script>

<style></style>
