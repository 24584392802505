<template>
  <table class="common_table" v-if="!isNew">
    <tbody>
      <tr>
        <th>二階床面積</th>
        <td>{{ currentData.area }} m<sup>2</sup></td>
      </tr>
      <tr>
        <th>二階部分への展示物設置希望</th>
        <td>{{ currentData.hope_div }}{{
          currentData.hope_div === 'その他の展示物'
            ? ' ( ' + currentData.hope_div_other + ' )'
            : ''
        }}</td>
      </tr>

    </tbody>
  </table>
  <div class="no_entry" v-else>登録がありません。「新規追加」ボタンより登録をお願いします。</div>

  <template v-if="isShowUI">
  <div><a @click.prevent="editHandler" href="#form1" class="common_add_button modalOpen">{{ uploadLabel }}</a></div>
  </template>

  <Teleport to="body">
    <!-- モーダルコンテンツ / -->
    <div class="overlay" id="form1">
      <div id="modal_content" class="modal_contents w80">
        <div class="modalClose_ctn"><a @click.prevent="modalClose" class="modalClose"></a></div>
        <!-- modal contents ここから-->
        <div class="entry_title">
          <h4>二階床面積/二階部分への展示物設置希望</h4>

          <div class="entry_title">
            <h5>二階床面積/二階部分への展示物設置希望&nbsp;&nbsp;{{ uploadLabel }}</h5>
          </div>


          <div class="entry_modal_ctn">

            <table class="common_table">
              <thead>
                <tr>
                  <th class="w10em">項目</th>
                  <th>数量</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>二階床面積</th>
                  <td><input type="number" class="quarter" v-model="form.area"> m<sup>2</sup>（500m<sup>2</sup>以下としてください。）
                    <p class="alert" v-if="errors.area">{{ errors.area }}</p>
                  </td>
                </tr>
                <tr>
                  <th>二階部分への展示物設置希望</th>
                  <td>
                    <ul class="common_list_vertical_narrow">
                      <li><label>
                        <input type="radio" name="hope_div"
                        v-model="form.hope_div"
                        value="なし"
                        :checked="form.hope_div === 'なし'">
                        <span class="radio_text">なし</span></label></li>
                      <li><label>
                        <input type="radio" name="hope_div"
                        v-model="form.hope_div"
                        value="車両展示"
                        :checked="form.hope_div === '車両展示'">
                        <span class="radio_text">車両展示</span></label></li>
                      <li><label>
                        <input type="radio" name="hope_div"
                        v-model="form.hope_div"
                        value="その他の展示物"
                        :checked="form.hope_div === 'その他の展示物'">
                        <span class="radio_text">その他の展示物</span></label><input type="text" class="half"
                          v-model="form.hope_div_other"
                          :disabled="form.hope_div !== 'その他の展示物'"
                        ></li>
                    </ul>
                    <p class="alert" v-if="errors.hope_div">{{ errors.hope_div }}</p>
                    <p class="alert" v-if="errors.hope_div_other">{{ errors.hope_div_other }}</p>
                  </td>
                </tr>
              </tbody>
            </table>

            <div class="button_ctn">
              <ul>
                <li><button @click.prevent="modalClose" class="gray_button modalClose">キャンセル</button></li>
                <li><button @click="sendForm" class="regist_button">上記内容で登録</button></li>
              </ul>
            </div>
            <!-- modal contents ここまで-->

          </div>
        </div>
        <!-- / モーダルコンテンツ -->
      </div>
    </div>
    <!-- / モーダルコンテンツ -->
  </Teleport>
</template>

<script setup>
import { reactive, computed, watch } from 'vue'
import { forEach, cloneDeep } from 'lodash'
import APIClient from '@/store/repository/APIClient'
import * as yup from 'yup'
import moment from "moment"
import { FormValidate } from '@/helper/FormValidate'

import {
  modalOpen,
  modalClose,
} from '@/helper/modal'

import ApiConst from '@/const/api'

import { isShowable as isShowUI } from '@/helper/indexStatus'

const api = new APIClient()

const formInit = {
  application_id: null,
  area: null,
  hope_div: '',
  hope_div_other: '',
}

const form = reactive(cloneDeep(formInit))
const currentData = reactive(cloneDeep(formInit))

watch(form, (el) => {
  if (el.hope_div !== 'その他の展示物') form.hope_div_other = ''
})

const isNew = computed(() => {
  return !currentData.application_id
})

const uploadLabel = computed(() => {
  return !isNew.value ? '変更' : '新規登録'
})

const fetch = async () => {
  try {
    const query = {
      form_no: 1,
      application_div: "two_storied",
    }
    const fetchApi = await api.get(ApiConst.API_APP_LIST, query)
    const res = fetchApi.response
    const errors = res.data?.error_results
    if (errors) {
      throw new Error('Not found.')
    }
    const data = res.data.results.list.map((el) => {
      return {
        application_id: el.application_id,
        input_date: moment(el.updated_at).format("YY-MM-DD"),
        seq_no: el.seq_no,
        ...el.json_data
      }
    })[0]
    forEach(currentData, (v, k) => {
      currentData[k] = data[k]
    })
    forEach(form, (v, k) => {
      form[k] = data[k] ?? formInit[k]
    })
  } catch (e) {
    console.log(e)
    return false
  }
}
fetch()

const editHandler = (event) => {
  modalOpen(event)
}

const scheme = {
  area: yup.number().typeError('数値を入力してください。').required('必須の項目です。')
    .min(1, '1以上の数値を入力してください。'),
  hope_div: yup.string().trim().required('必須の項目です。'),
  hope_div_other: yup.string().trim().test('hope_div_other', 'その他展示物を記入してください。', function(v) {
    if (this.parent.hope_div !== 'その他の展示物') return true
    if (v !== '') return true
    return false
  }),
}
const validate = new FormValidate(scheme, form)
const errors = validate.errors
const sendForm = async () => {
  try {
    if ((Object.keys(await validate.getErrorObject()).length)) {
      throw new Error('入力項目に不備があります。')
    }
    const params = cloneDeep(form)
    const application_id = params.application_id
    delete params.application_id
    const operation = application_id ? "R" : "I"
    const query = operation === "I"
      ? { operation, application_div: "two_storied", form_no: 1, json_data: params }
      : { operation, application_id, json_data: params }
    const res = await api.post(ApiConst.API_APP_EDIT, query)
    if (res.response.data?.error_results) {
      throw new Error('API Validation Error')
    }
    fetch()
    modalClose()
  } catch (e) {
    return false
  }
}
</script>

<style></style>
