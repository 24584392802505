<template>
  <header>
    <h1>JAPAN MOBILITY SHOW 2025&emsp;出展手続きサイト・出展者専用サイト</h1>
  </header>
  <article>
    <h2>新規パスワードの設定完了</h2>
    <div id="form_ctn" class="form_box">
      <p>新しいパスワードの設定が完了いたしました。<br>
        ログイン画面よりログインしてください。</p>


    </div>
    <div class="button_ctn">
      <router-link to="/login">ログイン画面</router-link>
    </div>
  </article>
</template>

<script>


</script>

<style>

</style>
