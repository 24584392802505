<template>
  <div id="admin">
    <header>
      <h1>JMS 2025 出展者登録DB 管理者用ページ</h1>
    </header>
    <article>
      <h2>二次申込管理</h2>
      <div id="form_ctn" class="form_box">

        <ErrorComponent></ErrorComponent>

        <h3>申込者個別情報</h3>

        <h4>基本情報</h4>
        <table class="common_table exhibitor_table">
          <tbody>
            <tr>
              <th colspan="2">会員区分</th>
              <td>
                <my-select v-model="membership_category" :options="membership_category_options"></my-select>
                <p class="alert" v-if="errors.membership_category">{{ errors.membership_category }}</p>
              </td>
            </tr>
            <tr>
              <th colspan="2">過去の出展実績</th>
              <td>
                <ul class="common_list_vertical_narrow">
                  <my-radio v-model="past_achievement" name="past_achievement" :options="past_achievement_options"></my-radio>
                </ul>
                <p class="alert" v-if="errors.past_achievement">{{ errors.past_achievement }}</p>
              </td>
            </tr>
            <tr>
              <th colspan="2">出展者のステータス</th>
              <td>{{ second_order_status_label }}</td>
            </tr>
            <tr>
              <th rowspan="3">出展者会社名</th>
              <th>和文名称</th>
              <td>
                <my-input name="exhibitor_name" width_class="full" type="text" v-model="exhibitor_name">
                </my-input>
                <p class="alert" v-if="errors.exhibitor_name">{{ errors.exhibitor_name }}</p>
              </td>
            </tr>
            <tr>
              <th>フリガナ</th>
              <td>
                <my-input name="exhibitor_name_kana" width_class="full" type="text" v-model="exhibitor_name_kana">
                </my-input>
                <p class="alert" v-if="errors.exhibitor_name_kana">{{ errors.exhibitor_name_kana }}</p>
              </td>
            </tr>
            <tr>
              <th>英文名称</th>
              <td>
                <my-input name="exhibitor_name_en" width_class="full" type="text" v-model="exhibitor_name_en">
                </my-input>
                <p class="alert" v-if="errors.exhibitor_name_en">{{ errors.exhibitor_name_en }}</p>
              </td>
            </tr>
            <tr>
              <th colspan="2">フラグ</th>
              <td>
                <ul class="common_list_horizontal">
                  <li>
                    <label>
                      <input type="checkbox" :name="cancel_flag" v-model="cancel_flag_model" />
                      <span class="checkbox_text">キャンセル</span>
                    </label>
                  </li>
                  <li>
                    <label>
                      <input type="checkbox" :name="test_flag" v-model="test_flag_model" />
                      <span class="checkbox_text">テストを含む</span>
                    </label>
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <th colspan="2">受付情報</th>
              <td>
                <label>
                  <input type="checkbox" :name="reception_info" v-model="reception_info" />
                  <span class="checkbox_text">受付情報</span>
                </label>

              </td>
            </tr>
            <tr>
              <th colspan="2">PDFアップロード</th>
              <td>
                <div style="display: flex">
                  <div>
                    <template v-if="isEditMode">
                    <input
                      v-if="!pdf_not_required"
                      type="file"
                      id="upload_file"
                      name="upload_file"
                      ref="uploadFile"
                      @change="loadPdf"
                    >
                    </template>
                    <label>
                      <input type="checkbox" :name="pdf_not_required" v-model="pdf_not_required" />
                      <span class="checkbox_text">PDFのアップロードが無くても、申込完了とする</span>
                    </label>
                  </div>
                </div>
              </td>
            </tr>
            <template v-if="!pdf_not_required">
            <tr v-if="isEditMode">
              <th colspan="2">PDFダウンロード</th>
              <td><button class="common_button" @click="download">ダウンロード</button></td>
            </tr>
            </template>

            <tr>
              <th colspan="2">申込番号</th>
              <td>{{ second_order_bango }}
              </td>
            </tr>
            <tr>
              <th colspan="2">出展カテゴリー</th>
              <td>
                <div class="select_ctn">
                  <select v-model="category_disp">
                    <option v-for="item in category_disp_options_plus" :key="item.value" :value="item.value">{{ item.label
                    }}</option>
                  </select>
                </div>
              </td>
            </tr>
            <tr>
              <th colspan="2">出展分類</th>
              <td>
                <ul class="common_list_vertical top_border">
                  <component v-model="category_div" name="category_div" :options="category_div_options"
                    :is="activeCategoryDiv" storeKey="admin" />

                </ul>
                <p class="alert" v-if="errors.category_div">{{ errors.category_div }}</p>

              </td>
            </tr>
            <tr>
              <th colspan="2">企業WEBサイト</th>
              <td>
                <my-input name="site_url" width_class="full" type="text" v-model="site_url"></my-input>
              </td>
            </tr>
            <tr>
              <th colspan="2">主な製品/サービス情報</th>
              <td>
                <my-input name="appeal_information" width_class="full" type="text" v-model="appeal_information">
                </my-input>
              </td>
            </tr>
            <tr>
              <th colspan="2">小間番号</th>
              <td>
                <my-input name="booth_number" width_class="quarter" type="text" v-model="booth_number">
                </my-input>
              </td>
            </tr>
            <tr>
              <th rowspan="4">小間面積</th>
              <th>決定小間面積</th>
              <td>
                <my-input name="booth_area_fix" width_class="quarter" type="text" v-model="booth_area_fix"></my-input>
              </td>
            </tr>
            <tr>
              <th>申込小間面積</th>
              <td>
                <my-input name="booth_area" width_class="quarter" type="text" v-model="booth_area"></my-input>
                <p class="alert" v-if="errors.booth_area">{{ errors.booth_area }}</p>
              </td>
            </tr>
            <tr>
              <th>出展料</th>
              <td>
                <my-input name="calced_exhibition_fee" width_class="quarter" type="text"
                  v-model="calced_exhibition_fee"></my-input>
              </td>
            </tr>
            <tr>
              <th>出展申込金</th>
              <td>
                <my-input name="calced_application_fee" width_class="quarter" type="text"
                  v-model="calced_application_fee"></my-input>
              </td>
            </tr>
            <tr>
              <th colspan="2">ホール</th>
              <td>
                <div class="select_ctn">
                  <select v-model="hall_div">
                    <option v-for="item in hall_div_options_plus" :key="item.value" :value="item.value">{{ item.label }}
                    </option>
                  </select>
                </div>
              </td>
            </tr>
            <tr>
              <th colspan="2">共同出展/隣接配置</th>
              <td>
                <ul class="common_list_horizontal">
                  <my-radio v-model="joint_adjacent" name="joint_adjacent" :options="joint_adjacent_options">
                  </my-radio>
                </ul>
                <p class="alert" v-if="errors.joint_adjacent">{{ errors.joint_adjacent }}</p>

                <div class="border_box mblh">
                  <h4>共同出展</h4>
                  <my-input name="joint_exhibition" width_class="full" type="text" v-model="joint_exhibition">
                  </my-input>
                </div>
                <div class="border_box">
                  <h4>隣接配置</h4>
                  <my-input name="adjacent_exhibition" width_class="full" type="text" v-model="adjacent_exhibition">
                  </my-input>
                </div>


              </td>
            </tr>

          </tbody>
        </table>
        <h4>出展者情報</h4>
        <table class="common_table exhibitor_table">
          <tbody>

            <tr>
              <th rowspan="4">出展者本社所在地</th>
              <th>郵便番号</th>
              <td>
                <input type="text" id="postCode" class="input" name="postCode" v-model="postCode" maxlength="8" @input="onZipInput($event, 'postCode')"
                  @keyup="onZipChange($event, this, 'address_pref', 'address_jp')" placeholder="例：123-4567">
                <p class="alert" v-if="errors.postCode">{{ errors.postCode }}</p>
              </td>
            </tr>
            <tr>
              <th>和文所在地</th>
              <td>
                <ul class="common_list_horizontal">
                  <li class="quarter">
                    <div class="select_ctn">
                      <select name="address_pref" v-model="address_pref">
                        <option value="">都道府県</option>
                        <option v-for="(v, k) in address_pref_options" :key="k" :value="v.value">{{ v.label }}</option>
                      </select>
                    </div>
                  </li>
                  <li class="three_quarters">
                    <my-input name="address_jp" width_class="full" type="text" v-model="address_jp"
                      placeholder="例：港区芝大門1-1-30&emsp;日本自動車会館">
                    </my-input>
                  </li>
                </ul>

                <p class="alert" v-if="errors.address_pref">{{ errors.address_pref }}</p>
                <p class="alert" v-if="errors.address_jp">{{ errors.address_jp }}</p>
              </td>
            </tr>
            <tr>
              <th>英文所在地</th>
              <td>
                <my-input name="address_en" width_class="full" type="text" v-model="address_en"
                  placeholder="例：Jidosha Kaikan, 1-30, Shiba Daimon 1-chome, Minato-ku, Tokyo 105-0012">
                </my-input>

                <p class="alert" v-if="errors.address_en">{{ errors.address_en }}</p>
              </td>
            </tr>
            <tr>
              <th>国・地域</th>
              <td>

                <country-select v-if="savedDataRetrieval" :initial_main="country_main" main_name="country_main"
                  :main_options="country_main_options" v-model:main="country_main" :initial_other="country_other"
                  other_name="country_other" :other_options="country_other_options" v-model:other="country_other"
                  disabled_number="99999999"></country-select>

                <p class="alert" v-if="errors.country_main">{{ errors.country_main }}</p>
                <p class="alert" v-if="errors.country_other">{{ errors.country_other }}</p>
              </td>
            </tr>
            <tr>
              <th rowspan="2">法人代表者</th>
              <th>役職</th>
              <td>
                <my-input name="representative_position" width_class="half" type="text" v-model="representative_position"
                  placeholder="例：代表取締役社長"></my-input>
                <p class="alert" v-if="errors.representative_position">{{ errors.representative_position }}</p>
              </td>
            </tr>
            <tr>
              <th>氏名</th>
              <td>
                <ul class="common_list_horizontal">
                  <li class="quater mr10">
                    <my-input name="representative_last_name" width_class="full" type="text"
                      v-model="representative_last_name" placeholder="例：山田"></my-input>
                    <p class="alert" v-if="errors.representative_last_name">{{ errors.representative_last_name }}</p>
                  </li>
                  <li class="quater">
                    <my-input name="representative_first_name" width_class="full" type="text"
                      v-model="representative_first_name" placeholder="例：太郎"></my-input>
                    <p class="alert" v-if="errors.representative_first_name">{{ errors.representative_first_name }}</p>
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <th rowspan="3">取扱いブランド1</th>
              <th>和文名</th>
              <td>
                <my-input name="brand1_name_jp" width_class="full" type="text" v-model="brand1_name_jp"></my-input>
              </td>
            </tr>
            <tr>
              <th>英文名</th>
              <td>
                <my-input name="brand1_name_en" width_class="full" type="text" v-model="brand1_name_en"></my-input>
              </td>
            </tr>
            <tr>
              <th>国・地域</th>
              <td>
                <country-select v-if="savedDataRetrieval" :initial_main="brand1_country_main"
                  main_name="brand1_country_main" :main_options="country_main_options" v-model:main="brand1_country_main"
                  :initial_other="brand1_country_other" other_name="brand1_country_other"
                  :other_options="country_other_options" v-model:other="brand1_country_other"
                  disabled_number="99999999"></country-select>
              </td>
            </tr>
            <tr>
              <th rowspan="3">取扱いブランド2</th>
              <th>和文名</th>
              <td>
                <my-input name="brand2_name_jp" width_class="full" type="text" v-model="brand2_name_jp"></my-input>
              </td>
            </tr>
            <tr>
              <th>英文名</th>
              <td>
                <my-input name="brand2_name_en" width_class="full" type="text" v-model="brand2_name_en"></my-input>
              </td>
            </tr>
            <tr>
              <th>国・地域</th>
              <td>
                <country-select v-if="savedDataRetrieval" :initial_main="brand2_country_main"
                  main_name="brand2_country_main" :main_options="country_main_options" v-model:main="brand2_country_main"
                  :initial_other="brand2_country_other" other_name="brand2_country_other"
                  :other_options="country_other_options" v-model:other="brand2_country_other"
                  disabled_number="99999999"></country-select>
              </td>
            </tr>
            <tr>
              <th rowspan="3">取扱いブランド3</th>
              <th>和文名</th>
              <td>
                <my-input name="brand3_name_jp" width_class="full" type="text" v-model="brand3_name_jp"></my-input>
              </td>
            </tr>
            <tr>
              <th>英文名</th>
              <td>
                <my-input name="brand3_name_en" width_class="full" type="text" v-model="brand3_name_en"></my-input>
              </td>
            </tr>
            <tr>
              <th>国・地域</th>
              <td>
                <country-select v-if="savedDataRetrieval" :initial_main="brand3_country_main"
                  main_name="brand3_country_main" :main_options="country_main_options" v-model:main="brand3_country_main"
                  :initial_other="brand3_country_other" other_name="brand3_country_other"
                  :other_options="country_other_options" v-model:other="brand3_country_other"
                  disabled_number="99999999"></country-select>
              </td>
            </tr>
            <tr>
              <th rowspan="3">取扱いブランド4</th>
              <th>和文名</th>
              <td>
                <my-input name="brand4_name_jp" width_class="full" type="text" v-model="brand4_name_jp"></my-input>
              </td>
            </tr>
            <tr>
              <th>英文名</th>
              <td>
                <my-input name="brand4_name_en" width_class="full" type="text" v-model="brand4_name_en"></my-input>
              </td>
            </tr>
            <tr>
              <th>国・地域</th>
              <td>
                <country-select v-if="savedDataRetrieval" :initial_main="brand4_country_main"
                  main_name="brand4_country_main" :main_options="country_main_options" v-model:main="brand4_country_main"
                  :initial_other="brand4_country_other" other_name="brand4_country_other"
                  :other_options="country_other_options" v-model:other="brand4_country_other"
                  disabled_number="99999999"></country-select>
              </td>
            </tr>
            <tr>
              <th rowspan="3">取扱いブランド5</th>
              <th>和文名</th>
              <td>
                <my-input name="brand5_name_jp" width_class="full" type="text" v-model="brand5_name_jp"></my-input>
              </td>
            </tr>
            <tr>
              <th>英文名</th>
              <td>
                <my-input name="brand5_name_en" width_class="full" type="text" v-model="brand5_name_en"></my-input>
              </td>
            </tr>
            <tr>
              <th>国・地域</th>
              <td>
                <country-select v-if="savedDataRetrieval" :initial_main="brand5_country_main"
                  main_name="brand5_country_main" :main_options="country_main_options" v-model:main="brand5_country_main"
                  :initial_other="brand5_country_other" other_name="brand5_country_other"
                  :other_options="country_other_options" v-model:other="brand5_country_other"
                  disabled_number="99999999"></country-select>
              </td>
            </tr>


          </tbody>
        </table>

        <h4>出展代理者情報</h4>
        <table class="common_table exhibitor_table">
          <tbody>

            <tr>
              <th rowspan="3">出展代理者<br>
                または <br>
                連絡先会社名</th>
              <th>和文名称</th>
              <td>
                <my-input name="deputy_exhibitor_name" width_class="full" type="text" v-model="deputy_exhibitor_name"
                  placeholder="例：一般社団法人日本自動車工業会">
                </my-input>
              </td>
            </tr>
            <tr>
              <th>フリガナ</th>
              <td>
                <my-input name="deputy_exhibitor_name_kana" width_class="full" type="text"
                  v-model="deputy_exhibitor_name_kana" placeholder="例：ニホンジドウシャコウギョウカイ"></my-input>
              </td>
            </tr>
            <tr>
              <th>英文名称</th>
              <td>
                <my-input name="deputy_exhibitor_name_en" width_class="full" type="text"
                  v-model="deputy_exhibitor_name_en" placeholder="例：Japan Automobile Manufacturers Association, Inc">
                </my-input>
              </td>
            </tr>
            <tr>
              <th rowspan="2">法人代表者</th>
              <th>役職</th>
              <td>
                <my-input name="deputy_representative_position" width_class="half" type="text"
                  v-model="deputy_representative_position" placeholder="例：代表取締役社長"></my-input>
              </td>
            </tr>
            <tr>
              <th>氏名</th>
              <td>
                <ul class="common_list_horizontal">
                  <li class="quater mr10">
                    <my-input name="deputy_representative_last_name" width_class="full" type="text"
                      v-model="deputy_representative_last_name" placeholder="例：山田"></my-input>
                  </li>
                  <li class="quater">
                    <my-input name="deputy_representative_first_name" width_class="full" type="text"
                      v-model="deputy_representative_first_name" placeholder="例：太郎"></my-input>
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <th rowspan="8">主担当者</th>
              <th>部署</th>
              <td>
                <my-input name="staff_department" width_class="half" type="text" v-model="staff_department"
                  placeholder="例：広報部"></my-input>
                <p class="alert" v-if="errors.staff_department">{{ errors.staff_department }}</p>
              </td>
            </tr>
            <tr>
              <th>役職</th>
              <td>
                <my-input name="staff_position" width_class="half" type="text" v-model="staff_position"
                  placeholder="例：部長">
                </my-input>
              </td>
            </tr>
            <tr>
              <th>氏名</th>
              <td>
                <ul class="common_list_horizontal">
                  <li class="quater mr10">
                    <my-input name="staff_last_name" width_class="full mr10" type="text" v-model="staff_last_name"
                      placeholder="例：山田"></my-input>
                  </li>
                  <li class="quater">
                    <my-input name="staff_first_name" width_class="full" type="text" v-model="staff_first_name"
                      placeholder="例：太郎">
                    </my-input>
                  </li>
                </ul>
                <p class="alert" v-if="errors.staff_last_name">{{ errors.staff_last_name }}</p>
                <p class="alert" v-if="errors.staff_first_name">{{ errors.staff_first_name }}</p>
              </td>
            </tr>
            <tr>
              <th>郵便番号</th>
              <td><input type="text" id="manager_postCode" class="input" name="manager_postCode"
                  v-model="manager_postCode" maxlength="8" @input="onZipInput($event, 'manager_postCode')"
                  @keyup="onZipChange($event, this, 'manager_address_pref', 'manager_address_jp')"
                  placeholder="例：123-4567">
                <p class="alert" v-if="errors.manager_postCode">{{ errors.manager_postCode }}</p>
              </td>
            </tr>
            <tr>
              <th>和文所在地</th>
              <td>
                <div class="select_ctn">
                  <select name="manager_address_pref" v-model="manager_address_pref">
                    <option value="">都道府県</option>
                    <option v-for="(v, k) in address_pref_options" :key="k" :value="v.value">{{ v.label }}</option>
                  </select>
                </div>
                <my-input name="manager_address_jp" width_class="full" type="text" v-model="manager_address_jp"
                  placeholder="例：港区芝大門1-1-30&emsp;日本自動車会館">
                </my-input>
                <p class="alert" v-if="errors.manager_address_pref">{{ errors.manager_address_pref }}</p>
                <p class="alert" v-if="errors.manager_address_jp">{{ errors.manager_address_jp }}</p>

              </td>
            </tr>
            <tr>
              <th>英文所在地</th>
              <td>
                <my-input name="manager_address_en" width_class="full" type="text" v-model="manager_address_en"
                  placeholder="例：Jidosha Kaikan, 1-30, Shiba Daimon 1-chome, Minato-ku, Tokyo 105-0012">
                </my-input>
                <p class="alert" v-if="errors.manager_address_en">{{ errors.manager_address_en }}</p>

              </td>
            </tr>
            <tr>
              <th>電話番号</th>
              <td>
                <my-input name="staff_tel" width_class="half" type="text" v-model="staff_tel"
                  placeholder="例：03-1234-1234">
                </my-input>
                <p class="alert" v-if="errors.staff_tel">{{ errors.staff_tel }}</p>
              </td>
            </tr>
            <tr>
              <th>メールアドレス</th>
              <td>
                <my-input name="staff_email" width_class="full" type="text" v-model="staff_email"
                  placeholder="例：example@example.co.jp"></my-input>
                <p class="alert" v-if="errors.staff_email">{{ errors.staff_email }}</p>
              </td>
            </tr>

            <tr>
              <th rowspan="8">副担当者1</th>
              <th>部署</th>
              <td>
                <my-input name="staff_department_1" width_class="half" type="text" v-model="staff_department_1"
                  placeholder="例：広報部"></my-input>
              </td>
            </tr>
            <tr>
              <th>役職</th>
              <td>
                <my-input name="staff_post_1" width_class="half" type="text" v-model="staff_post_1" placeholder="例：部長">
                </my-input>
              </td>
            </tr>
            <tr>
              <th>氏名</th>
              <td>
                <ul class="common_list_horizontal">
                  <li class="quater mr10">
                    <my-input name="staff_last_name_1" width_class="full mr10" type="text" v-model="staff_last_name_1"
                      placeholder="例：山田"></my-input>
                  </li>
                  <li class="quater">
                    <my-input name="staff_first_name_1" width_class="full" type="text" v-model="staff_first_name_1"
                      placeholder="例：太郎">
                    </my-input>
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <th>郵便番号</th>
              <td>
                <input type="text" id="staff_zip_1" class="input" name="staff_zip_1" v-model="staff_zip_1" maxlength="8" @input="onZipInput($event, 'staff_zip_1')"
                  @keyup="onZipChange($event, this, 'staff_pref_1', 'staff_address_1')" placeholder="例：123-4567">
                <p class="alert" v-if="errors.staff_zip_1">{{ errors.staff_zip_1 }}</p>
              </td>
            </tr>
            <tr>
              <th>和文所在地</th>
              <td>
                <div class="select_ctn">
                  <select name="staff_pref_1" v-model="staff_pref_1">
                    <option value="">都道府県</option>
                    <option v-for="(v, k) in address_pref_options" :key="k" :value="v.value">{{ v.label }}</option>
                  </select>
                </div>
                <my-input name="staff_address_1" width_class="full" type="text" v-model="staff_address_1"
                  placeholder="例：港区芝大門1-1-30&emsp;日本自動車会館">
                </my-input>
                <p class="alert" v-if="errors.staff_address_1">{{ errors.staff_address_1 }}</p>

              </td>
            </tr>
            <tr>
              <th>英文所在地</th>
              <td>
                <my-input name="staff_address_en_1" width_class="full" type="text" v-model="staff_address_en_1"
                  placeholder="例：Jidosha Kaikan, 1-30, Shiba Daimon 1-chome, Minato-ku, Tokyo 105-0012">
                </my-input>
              </td>
            </tr>
            <tr>
              <th>電話番号</th>
              <td>
                <my-input name="staff_tel_1" width_class="half" type="text" v-model="staff_tel_1"
                  placeholder="例：03-1234-1234">
                </my-input>
              </td>
            </tr>
            <tr>
              <th>メールアドレス</th>
              <td>
                <my-input name="staff_email_1" width_class="full" type="text" v-model="staff_email_1"
                  placeholder="例：example@example.co.jp"></my-input>
              </td>
            </tr>
            <tr>
              <th rowspan="8">副担当者2</th>
              <th>部署</th>
              <td>
                <my-input name="staff_department_2" width_class="half" type="text" v-model="staff_department_2"
                  placeholder="例：広報部"></my-input>
              </td>
            </tr>
            <tr>
              <th>役職</th>
              <td>
                <my-input name="staff_post_2" width_class="half" type="text" v-model="staff_post_2" placeholder="例：部長">
                </my-input>
              </td>
            </tr>
            <tr>
              <th>氏名</th>
              <td>
                <ul class="common_list_horizontal">
                  <li class="quater mr10">
                    <my-input name="staff_last_name_2" width_class="full mr10" type="text" v-model="staff_last_name_2"
                      placeholder="例：山田"></my-input>
                  </li>
                  <li class="quater">
                    <my-input name="staff_first_name_2" width_class="full" type="text" v-model="staff_first_name_2"
                      placeholder="例：太郎">
                    </my-input>
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <th>郵便番号</th>
              <td>
                <input type="text" id="staff_zip_2" class="input" name="staff_zip_2" v-model="staff_zip_2" maxlength="8" @input="onZipInput($event, 'staff_zip_2')"
                  @keyup="onZipChange($event, this, 'staff_pref_2', 'staff_address_2')" placeholder="例：123-4567">
                <p class="alert" v-if="errors.staff_zip_2">{{ errors.staff_zip_2 }}</p>
              </td>
            </tr>
            <tr>
              <th>和文所在地</th>
              <td>
                <div class="select_ctn">
                  <select name="staff_pref_2" v-model="staff_pref_2">
                    <option value="">都道府県</option>
                    <option v-for="(v, k) in address_pref_options" :key="k" :value="v.value">{{ v.label }}</option>
                  </select>
                </div>
                <my-input name="staff_address_2" width_class="full" type="text" v-model="staff_address_2"
                  placeholder="例：港区芝大門1-1-30&emsp;日本自動車会館">
                </my-input>
                <p class="alert" v-if="errors.staff_address_2">{{ errors.staff_address_2 }}</p>
              </td>
            </tr>
            <tr>
              <th>英文所在地</th>
              <td>
                <my-input name="staff_address_en_2" width_class="full" type="text" v-model="staff_address_en_2"
                  placeholder="例：Jidosha Kaikan, 1-30, Shiba Daimon 1-chome, Minato-ku, Tokyo 105-0012">
                </my-input>
              </td>
            </tr>
            <tr>
              <th>電話番号</th>
              <td>
                <my-input name="staff_tel_2" width_class="half" type="text" v-model="staff_tel_2"
                  placeholder="例：03-1234-1234">
                </my-input>
              </td>
            </tr>
            <tr>
              <th>メールアドレス</th>
              <td>
                <my-input name="staff_email_2" width_class="full" type="text" v-model="staff_email_2"
                  placeholder="例：example@example.co.jp"></my-input>
              </td>
            </tr>
            <tr>
              <th rowspan="8">副担当者3</th>
              <th>部署</th>
              <td>
                <my-input name="staff_department_3" width_class="half" type="text" v-model="staff_department_3"
                  placeholder="例：広報部"></my-input>
              </td>
            </tr>
            <tr>
              <th>役職</th>
              <td>
                <my-input name="staff_post_3" width_class="half" type="text" v-model="staff_post_3" placeholder="例：部長">
                </my-input>
              </td>
            </tr>
            <tr>
              <th>氏名</th>
              <td>
                <ul class="common_list_horizontal">
                  <li class="quater mr10">
                    <my-input name="staff_last_name_3" width_class="full mr10" type="text" v-model="staff_last_name_3"
                      placeholder="例：山田"></my-input>
                  </li>
                  <li class="quater">
                    <my-input name="staff_first_name_3" width_class="full" type="text" v-model="staff_first_name_3"
                      placeholder="例：太郎">
                    </my-input>
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <th>郵便番号</th>
              <td>
                <input type="text" id="staff_zip_3" class="input" name="staff_zip_3" v-model="staff_zip_3" maxlength="8" @input="onZipInput($event, 'staff_zip_3')"
                  @keyup="onZipChange($event, this, 'staff_pref_3', 'staff_address_3')" placeholder="例：123-4567">
                <p class="alert" v-if="errors.staff_zip_3">{{ errors.staff_zip_3 }}</p>
              </td>
            </tr>
            <tr>
              <th>和文所在地</th>
              <td>
                <div class="select_ctn">
                  <select name="staff_pref_3" v-model="staff_pref_3">
                    <option value="">都道府県</option>
                    <option v-for="(v, k) in address_pref_options" :key="k" :value="v.value">{{ v.label }}</option>
                  </select>
                </div>
                <my-input name="staff_address_3" width_class="full" type="text" v-model="staff_address_3"
                  placeholder="例：港区芝大門1-1-30&emsp;日本自動車会館">
                </my-input>
                <p class="alert" v-if="errors.staff_address_3">{{ errors.staff_address_3 }}</p>
              </td>
            </tr>
            <tr>
              <th>英文所在地</th>
              <td>
                <my-input name="staff_address_en_3" width_class="full" type="text" v-model="staff_address_en_3"
                  placeholder="例：Jidosha Kaikan, 1-30, Shiba Daimon 1-chome, Minato-ku, Tokyo 105-0012">
                </my-input>
              </td>
            </tr>
            <tr>
              <th>電話番号</th>
              <td>
                <my-input name="staff_tel_3" width_class="half" type="text" v-model="staff_tel_3"
                  placeholder="例：03-1234-1234">
                </my-input>
              </td>
            </tr>
            <tr>
              <th>メールアドレス</th>
              <td>
                <my-input name="staff_email_3" width_class="full" type="text" v-model="staff_email_3"
                  placeholder="例：example@example.co.jp"></my-input>
              </td>
            </tr>
            <tr>
              <th rowspan="8">副担当者4</th>
              <th>部署</th>
              <td>
                <my-input name="staff_department_4" width_class="half" type="text" v-model="staff_department_4"
                  placeholder="例：広報部"></my-input>
              </td>
            </tr>
            <tr>
              <th>役職</th>
              <td>
                <my-input name="staff_post_4" width_class="half" type="text" v-model="staff_post_4" placeholder="例：部長">
                </my-input>
              </td>
            </tr>
            <tr>
              <th>氏名</th>
              <td>
                <ul class="common_list_horizontal">
                  <li class="quater mr10">
                    <my-input name="staff_last_name_4" width_class="full mr10" type="text" v-model="staff_last_name_4"
                      placeholder="例：山田"></my-input>
                  </li>
                  <li class="quater">
                    <my-input name="staff_first_name_4" width_class="full" type="text" v-model="staff_first_name_4"
                      placeholder="例：太郎">
                    </my-input>
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <th>郵便番号</th>
              <td>
                <input type="text" id="staff_zip_4" class="input" name="staff_zip_4" v-model="staff_zip_4" maxlength="8" @input="onZipInput($event, 'staff_zip_4')"
                  @keyup="onZipChange($event, this, 'staff_pref_4', 'staff_address_4')" placeholder="例：123-4567">
                <p class="alert" v-if="errors.staff_zip_4">{{ errors.staff_zip_4 }}</p>
              </td>
            </tr>
            <tr>
              <th>和文所在地</th>
              <td>
                <div class="select_ctn">
                  <select name="staff_pref_4" v-model="staff_pref_4">
                    <option value="">都道府県</option>
                    <option v-for="(v, k) in address_pref_options" :key="k" :value="v.value">{{ v.label }}</option>
                  </select>
                </div>
                <my-input name="staff_address_4" width_class="full" type="text" v-model="staff_address_4"
                  placeholder="例：港区芝大門1-1-30&emsp;日本自動車会館">
                </my-input>
                <p class="alert" v-if="errors.staff_address_4">{{ errors.staff_address_4 }}</p>
              </td>
            </tr>
            <tr>
              <th>英文所在地</th>
              <td>
                <my-input name="staff_address_en_4" width_class="full" type="text" v-model="staff_address_en_4"
                  placeholder="例：Jidosha Kaikan, 1-30, Shiba Daimon 1-chome, Minato-ku, Tokyo 105-0012">
                </my-input>
              </td>
            </tr>
            <tr>
              <th>電話番号</th>
              <td>
                <my-input name="staff_tel_4" width_class="half" type="text" v-model="staff_tel_4"
                  placeholder="例：03-1234-1234">
                </my-input>
              </td>
            </tr>
            <tr>
              <th>メールアドレス</th>
              <td>
                <my-input name="staff_email_4" width_class="full" type="text" v-model="staff_email_4"
                  placeholder="例：example@example.co.jp"></my-input>
              </td>
            </tr>

          </tbody>
        </table>

        <table class="common_table exhibitor_table" style="margin-top: 25px">
          <tbody>

            <tr>
              <th>備考</th>
              <td>
                <textarea name="note" rows="4" class="full" id="textarea" v-model="note"></textarea>
                  <p class="alert" v-if="errors.note">{{ errors.note }}</p>
              </td>
            </tr>
          </tbody>
        </table>

        <div class="button_ctn">
          <ul>
            <li>
              <router-link :to="{ name: 'AdminSecondList' }" class="gray_button">検索結果に戻る</router-link>
            </li>
            <li v-if="enableFunctionCode('fc_0001_02')"><button class="submit" :class="sendStatus" @click="sendForm">{{ sendLabel }}</button></li>
          </ul>
        </div>

      </div>

      <div class="button_ctn">
        <ul>
          <li><router-link :to="{ name: 'AdminIndex' }">メニュー画面に戻る</router-link>
          </li>
        </ul>
      </div>
    </article>
  </div>
</template>

<script>
import { ref } from 'vue';
import APIClient from '@/store/repository/APIClient'
import MyInput from '@/components//MyInput.vue';
import MyCheckbox from '@/components/MyCheckbox.vue';
import MyRadio from '@/components//MyRadio.vue';
import MySelect from '@/components//MySelect.vue';
import CategoryDiv from '@/components//CategoryDiv.vue';
import CountrySelect from '@/components//CountrySelect.vue';
import { useField, useForm } from 'vee-validate';
import * as yup from 'yup';
import { useRoute } from 'vue-router'
import KenAll from 'ken-all';
import ErrorComponent from '@/components/ErrorComponent.vue';
import { isValue } from '@/helper/index';
import {
  perseIntOrNull
} from '@/helper/index';
import ADDRESS from '@/const/address_elements.js'
import { store } from '@/store'
import { loadFile, getFileExtension } from '@/helper/files'

import { enableFunctionCode } from '@/helper/adminAuthority'

import YupConst from '@/const/yup';

import {
  zipNumOnlyTest,
  makeZip
} from '@/helper/index';

const zipRegExp = new RegExp(YupConst.ZIP_NULLABLE_PATTERN)

const foreignID =  ADDRESS.address_pref_options.find(el => {
  return el.label === ADDRESS.address_pref_foreign
})?.value

const isForeign = (v) => {
  return v === foreignID
}

const api = new APIClient()

export default {
  components: {
    MyInput,
    CategoryDiv,
    MyCheckbox,
    MyRadio,
    MySelect,
    CountrySelect,
    ErrorComponent,
  },
  setup() {
    const route = useRoute();

    const schema = yup.object({
      membership_category: yup.string().required('必須の項目です。会員区分を選択してください。'),
      category_div: yup.object().required('必須の項目です。'),
      booth_area: yup.number().required('必須の項目です。'),
      joint_adjacent: yup.number().required('必須の項目です。'),
      exhibitor_name: yup.string().required('必須の項目です。'),
      exhibitor_name_kana: yup.string().required('必須の項目です。'),
      exhibitor_name_en: yup.string().required('必須の項目です。'),
      country_main: yup.string().required('必須の項目です。'),
      country_other: yup.string().test(
        "country_other_validation",
        "その他の国・地域を選択してください。",
        function (value) {
          const var_country_main = this.parent.country_main;
          if (var_country_main !== '99999999') return true
          return (value)
        }
      ),
      postCode: yup.string().nullable().trim()
        .test(
          'postCode_required',
          '必須の項目です。',
          function(v) {
            if ( isForeign(address_pref.value) ) return true
            if (v === undefined || v === null) return false
            return v.length
          }
        )
        .test(
          'postCode_pref',
          YupConst.ZIP_PATTERN_MSG,
          function(v) {
            if ( isForeign(address_pref.value) ) return true
            if (v === undefined || v === null) return false
            return v.match(zipRegExp)
          }
        ),
      address_pref: yup.string().required('必須の項目です。'),
      address_jp: yup.string()
        .test(
          'address_jp_required',
          "必須です。",
          function(v) {
            if (isForeign(address_pref.value)) return true
            if (v === undefined || v === null) return false
            if (v.length) return true
            return false
          }
        ),
      address_en: yup.string().required('必須の項目です。'),
      staff_department: yup.string().required('必須の項目です。'),
      staff_last_name: yup.string().required('必須の項目です。'),
      staff_first_name: yup.string().required('必須の項目です。'),
      manager_postCode: yup.string().nullable().trim()
        .test(
          'postCode_required',
          '必須の項目です。',
          function(v) {
            if ( isForeign(manager_address_pref.value) ) return true
            if (v === undefined || v === null) return false
            return v.length
          }
        )
        .test(
          'postCode_pref',
          YupConst.ZIP_PATTERN_MSG,
          function(v) {
            if ( isForeign(manager_address_pref.value) ) return true
            if (v === undefined || v === null) return false
            return v.match(zipRegExp)
          }
        ),
      manager_address_pref: yup.string().required('必須の項目です。'),
      manager_address_jp: yup.string()
        .test(
          'manager_address_jp_required',
          "必須です。",
          function(v) {
            if (isForeign(manager_address_pref.value)) return true
            if (v === undefined || v === null) return false
            if (v.length) return true
            return false
          }
        ),
      manager_address_en: yup.string().required('必須の項目です。'),
      staff_tel: yup.string().required('必須の項目です。'),
      staff_email: yup.string().required('必須の項目です。'),
    });

    const { errors, handleSubmit } = useForm({
      validationSchema: schema,
    });

    const sendStatus = ref('')
    const defaultSendLabel = '編集内容の登録'
    const sendLabel = ref(defaultSendLabel)

    const errorBehavior = (message) => {
      sendStatus.value = 'error'
      sendLabel.value = message
      setTimeout(() => {
        sendStatus.value = ''
        sendLabel.value = defaultSendLabel
      }, 2000)
    }

    const onInvalidSubmit = ({ errors }) => {
      console.error(errors)
      errorBehavior('入力項目に不備があります。')
    }

    const pdfData = ref('')
    const uploadFile = ref(null)
    const loadPdf = (e) => {
      loadFile(e, (v) => {
        pdfData.value = v
      })
    }
    const upload = async () => {
      const pageID = route.params.id
      if (!isValue(pageID)) {
        return
      }

      try {
        const value = pdfData.value
        if (!value) {
          store.commit('message/errors', ['ファイルを読み込んでください。'])
          throw new Error('No file data.')
        }
        const extension = getFileExtension(value)
        if (extension !== 'pdf') {
          store.commit('message/errors', ['PDF形式のファイルを読み込んでください。'])
          throw new Error('Incorrect file format')
        }
        const query = {
          exhibitor_id: pageID,
          pdf_data: value,
        }
        const res = await api.post('/api/admin/second_order/upload', query)

        if (!res.response?.data) {
          throw new Error('API Not Found')
        }

        if (res.response.data?.error_results) {
          store.commit('message/errors', res.response.data?.error_results.message)
          throw new Error('API Validation Error')
        }

        alert('アップロードが完了しました。')
      } catch (e) {
        console.error(e.message)
        return false
      }
      return true
    }

    const sendForm = handleSubmit(async (values) => {
      try {
        if (pdfData.value) {
          const uploadRes = await upload()
          pdfData.value = ''
          uploadFile.value.value = null
          if (!uploadRes) {
            window.scrollTo({
              top: 0,
              behavior: 'smooth',
            })
            throw new Error('File upload failed.')
          }
        }
        
        const category_div1 = values.category_div.category_div?.join(',') ?? null
        const category_div2 = values.category_div['category_div_' + category_div1]?.join(',') ?? null

        const query = {
          //左がAPIの名前 右がこのページ
          cancel_flag: values.cancel_flag_model ? 1 : 0,
          test_flag: values.test_flag_model ? 1 : 0,
          reception_info: values.reception_info ? 1 : 0,
          exhibitor_name: values.exhibitor_name,
          exhibitor_name_kana: values.exhibitor_name_kana,
          exhibitor_name_en: values.exhibitor_name_en,
          category_disp: perseIntOrNull(values.category_disp),
          category_div1: perseIntOrNull(category_div1),
          category_div2: category_div2 ?? null,
          membership_category: values.membership_category,
          past_achievement: values.past_achievement,
          past_achievement_label: values.past_achievement_label,
          site_url: values.site_url,
          appeal_information: values.appeal_information,
          koma_bango: values.booth_number,
          decide_area: perseIntOrNull(values.booth_area_fix),
          order_area: perseIntOrNull(values.booth_area),
          order_kingaku: perseIntOrNull(values.calced_exhibition_fee),
          order_deposit: perseIntOrNull(values.calced_application_fee),
          hall_div: perseIntOrNull(values.hall_div),
          joint_flag: perseIntOrNull(values.joint_adjacent),
          jointly: values.joint_exhibition,
          adjoin: values.adjacent_exhibition,
          exhibitor_zip: values.postCode,
          exhibitor_pref: values.address_pref,
          exhibitor_address: values.address_jp,
          exhibitor_address_en: values.address_en,
          exhibitor_country: perseIntOrNull(values.country_main),
          exhibitor_country_other: perseIntOrNull(values.country_other),
          representative_post: values.representative_position,
          representative_last_name: values.representative_last_name,
          representative_first_name: values.representative_first_name,
          brand_name_1: values.brand1_name_jp,
          brand_name_en_1: values.brand1_name_en,
          brand_country_1: perseIntOrNull(values.brand1_country_main),
          brand_country_other_1: perseIntOrNull(values.brand1_country_other),
          brand_name_2: values.brand2_name_jp,
          brand_name_en_2: values.brand2_name_en,
          brand_country_2: perseIntOrNull(values.brand2_country_main),
          brand_country_other_2: perseIntOrNull(values.brand2_country_other),
          brand_name_3: values.brand3_name_jp,
          brand_name_en_3: values.brand3_name_en,
          brand_country_3: perseIntOrNull(values.brand3_country_main),
          brand_country_other_3: perseIntOrNull(values.brand3_country_other),
          brand_name_4: values.brand4_name_jp,
          brand_name_en_4: values.brand4_name_en,
          brand_country_4: perseIntOrNull(values.brand4_country_main),
          brand_country_other_4: perseIntOrNull(values.brand4_country_other),
          brand_name_5: values.brand5_name_jp,
          brand_name_en_5: values.brand5_name_en,
          brand_country_5: perseIntOrNull(values.brand5_country_main),
          brand_country_other_5: perseIntOrNull(values.brand5_country_other),
          agent_name: values.deputy_exhibitor_name,
          agent_name_kana: values.deputy_exhibitor_name_kana,
          agent_name_en: values.deputy_exhibitor_name_en,
          agent_representative_post: values.deputy_representative_position,
          agent_representative_last_name: values.deputy_representative_last_name,
          agent_representative_first_name: values.deputy_representative_first_name,
          staff_department: values.staff_department,
          staff_post: values.staff_position,
          staff_last_name: values.staff_last_name,
          staff_first_name: values.staff_first_name,
          staff_zip: values.manager_postCode,
          staff_pref: values.manager_address_pref,
          staff_address: values.manager_address_jp,
          staff_address_en: values.manager_address_en,
          staff_tel: values.staff_tel,
          staff_email: values.staff_email,
          staff_department_1: values.staff_department_1,
          staff_post_1: values.staff_post_1,
          staff_last_name_1: values.staff_last_name_1,
          staff_first_name_1: values.staff_first_name_1,
          staff_zip_1: values.staff_zip_1,
          staff_pref_1: values.staff_pref_1,
          staff_address_1: values.staff_address_1,
          staff_address_en_1: values.staff_address_en_1,
          staff_tel_1: values.staff_tel_1,
          staff_email_1: values.staff_email_1,
          staff_department_2: values.staff_department_2,
          staff_post_2: values.staff_post_2,
          staff_last_name_2: values.staff_last_name_2,
          staff_first_name_2: values.staff_first_name_2,
          staff_zip_2: values.staff_zip_2,
          staff_pref_2: values.staff_pref_2,
          staff_address_2: values.staff_address_2,
          staff_address_en_2: values.staff_address_en_2,
          staff_tel_2: values.staff_tel_2,
          staff_email_2: values.staff_email_2,
          staff_department_3: values.staff_department_3,
          staff_post_3: values.staff_post_3,
          staff_last_name_3: values.staff_last_name_3,
          staff_first_name_3: values.staff_first_name_3,
          staff_zip_3: values.staff_zip_3,
          staff_pref_3: values.staff_pref_3,
          staff_address_3: values.staff_address_3,
          staff_address_en_3: values.staff_address_en_3,
          staff_tel_3: values.staff_tel_3,
          staff_email_3: values.staff_email_3,
          staff_department_4: values.staff_department_4,
          staff_post_4: values.staff_post_4,
          staff_last_name_4: values.staff_last_name_4,
          staff_first_name_4: values.staff_first_name_4,
          staff_zip_4: values.staff_zip_4,
          staff_pref_4: values.staff_pref_4,
          staff_address_4: values.staff_address_4,
          staff_address_en_4: values.staff_address_en_4,
          staff_tel_4: values.staff_tel_4,
          staff_email_4: values.staff_email_4,
          note: values.note,
        }
        if (values.pdf_not_required) {
          query.second_order_status = 2
        }
        if (values.pdf_not_required) {
          query.second_order_status = 2
        }
        const pageID = route.params.id
        if (!isValue(pageID)) {
          // 新規投稿
          query.operation = 'I'
        } else {
          // 編集
          query.operation = 'R'
          query.exhibitor_id = pageID
        }

        const res = await api.post('/api/admin/second_order/edit', query)
        if (res.response.data?.error_results) {
          throw new Error('API Validation Error')
        }

        const searchResult = await api.getAdminSecondList({})
        if (searchResult.data?.error_results) {
          throw new Error('List could not be updated')
        }
        store.commit('search/admin_second_list', searchResult.data.results.list)
      } catch (e) {
        errorBehavior('エラーが発生しました。')
        return false
      }
      sendStatus.value = 'comptele'
      sendLabel.value = '登録が完了しました。'
    }, onInvalidSubmit);

    const { value: cancel_flag_model } = useField('cancel_flag_model', null, {
      initialValue: false,
    });
    const { value: test_flag_model } = useField('test_flag_model', null, {
      initialValue: false,
    });
    const { value: reception_info } = useField('reception_info', null, {
      initialValue: false,
    });
    const { value: hall_div } = useField('hall_div', null, {
      initialValue: null,
    });
    const { value: exhibitor_name } = useField('exhibitor_name', null, {
      validateOnValueUpdate: true,
    });
    const { value: exhibitor_name_kana } = useField('exhibitor_name_kana', null, {
      validateOnValueUpdate: true,
    });
    const { value: exhibitor_name_en } = useField('exhibitor_name_en', null, {
      validateOnValueUpdate: true,
    });
    const { value: category_disp } = useField('category_disp', null, {
      initialValue: null,
    });
    const { value: category_div } = useField('category_div', null, {
      validateOnValueUpdate: true,
    });
    const { value: membership_category } = useField('membership_category', null, {
      validateOnValueUpdate: true,
    });
    const { value: past_achievement } = useField('past_achievement', null, {
      validateOnValueUpdate: true,
    });
    const { value: past_achievement_label } = useField('past_achievement_label', null, {
      validateOnValueUpdate: true,
    });
    const { value: site_url } = useField('site_url', null, {
      validateOnValueUpdate: true,
    });
    const { value: appeal_information } = useField('appeal_information', null, {
      validateOnValueUpdate: true,
    });
    const { value: booth_number } = useField('booth_number', null, {
      validateOnValueUpdate: true,
    });
    const { value: booth_area_fix } = useField('booth_area_fix', null, {
      validateOnValueUpdate: true,
    });

    const { value: booth_area } = useField('booth_area', null, {
      validateOnValueUpdate: true,
    });
    const { value: calced_exhibition_fee } = useField('calced_exhibition_fee', null, {
      validateOnValueUpdate: true,
    });
    const { value: calced_application_fee } = useField('calced_application_fee', null, {
      validateOnValueUpdate: true,
    });
    const { value: joint_adjacent } = useField('joint_adjacent', null, {
      validateOnValueUpdate: true,
    });
    const { value: joint_exhibition } = useField('joint_exhibition', null, {
      validateOnValueUpdate: true,
    });
    const { value: adjacent_exhibition } = useField('adjacent_exhibition', null, {
      validateOnValueUpdate: true,
    });
    const { value: postCode } = useField('postCode', null, {
      validateOnValueUpdate: true,
    });
    const { value: address_pref } = useField('address_pref', null, {
      validateOnValueUpdate: true,
    });
    const { value: address_jp } = useField('address_jp', null, {
      validateOnValueUpdate: true,
    });
    const { value: address_en } = useField('address_en', null, {
      validateOnValueUpdate: true,
    });
    const { value: country_main } = useField('country_main', null, {
      validateOnValueUpdate: true,
    });
    const { value: country_other } = useField('country_other', null, {
      validateOnValueUpdate: true,
    });
    const { value: representative_position } = useField('representative_position', null, {
      validateOnValueUpdate: true,
    });
    const { value: representative_last_name } = useField('representative_last_name', null, {
      validateOnValueUpdate: true,
    });
    const { value: representative_first_name } = useField('representative_first_name', null, {
      validateOnValueUpdate: true,
    });
    const { value: brand1_name_jp } = useField('brand1_name_jp', null, {
      validateOnValueUpdate: true,
    });
    const { value: brand1_name_en } = useField('brand1_name_en', null, {
      validateOnValueUpdate: true,
    });
    const { value: brand1_country_main } = useField('brand1_country_main', null, {
      validateOnValueUpdate: true,
    });
    const { value: brand1_country_other } = useField('brand1_country_other', null, {
      validateOnValueUpdate: true,
    });
    const { value: brand2_name_jp } = useField('brand2_name_jp', null, {
      validateOnValueUpdate: true,
    });
    const { value: brand2_name_en } = useField('brand2_name_en', null, {
      validateOnValueUpdate: true,
    });
    const { value: brand2_country_main } = useField('brand2_country_main', null, {
      validateOnValueUpdate: true,
    });
    const { value: brand2_country_other } = useField('brand2_country_other', null, {
      validateOnValueUpdate: true,
    });
    const { value: brand3_name_jp } = useField('brand3_name_jp', null, {
      validateOnValueUpdate: true,
    });
    const { value: brand3_name_en } = useField('brand3_name_en', null, {
      validateOnValueUpdate: true,
    });
    const { value: brand3_country_main } = useField('brand3_country_main', null, {
      validateOnValueUpdate: true,
    });
    const { value: brand3_country_other } = useField('brand3_country_other', null, {
      validateOnValueUpdate: true,
    });
    const { value: brand4_name_jp } = useField('brand4_name_jp', null, {
      validateOnValueUpdate: true,
    });
    const { value: brand4_name_en } = useField('brand4_name_en', null, {
      validateOnValueUpdate: true,
    });
    const { value: brand4_country_main } = useField('brand4_country_main', null, {
      validateOnValueUpdate: true,
    });
    const { value: brand4_country_other } = useField('brand4_country_other', null, {
      validateOnValueUpdate: true,
    });
    const { value: brand5_name_jp } = useField('brand5_name_jp', null, {
      validateOnValueUpdate: true,
    });
    const { value: brand5_name_en } = useField('brand5_name_en', null, {
      validateOnValueUpdate: true,
    });
    const { value: brand5_country_main } = useField('brand5_country_main', null, {
      validateOnValueUpdate: true,
    });
    const { value: brand5_country_other } = useField('brand5_country_other', null, {
      validateOnValueUpdate: true,
    });
    const { value: deputy_exhibitor_name } = useField('deputy_exhibitor_name', null, {
      validateOnValueUpdate: true,
    });
    const { value: deputy_exhibitor_name_kana } = useField('deputy_exhibitor_name_kana', null, {
      validateOnValueUpdate: true,
    });
    const { value: deputy_exhibitor_name_en } = useField('deputy_exhibitor_name_en', null, {
      validateOnValueUpdate: true,
    });
    const { value: deputy_representative_position } = useField('deputy_representative_position', null, {
      validateOnValueUpdate: true,
    });
    const { value: deputy_representative_last_name } = useField('deputy_representative_last_name', null, {
      validateOnValueUpdate: true,
    });
    const { value: deputy_representative_first_name } = useField('deputy_representative_first_name', null, {
      validateOnValueUpdate: true,
    });
    const { value: staff_department } = useField('staff_department', null, {
      validateOnValueUpdate: true,
    });
    const { value: staff_position } = useField('staff_position', null, {
      validateOnValueUpdate: true,
    });
    const { value: staff_last_name } = useField('staff_last_name', null, {
      validateOnValueUpdate: true,
    });
    const { value: staff_first_name } = useField('staff_first_name', null, {
      validateOnValueUpdate: true,
    });
    const { value: manager_postCode } = useField('manager_postCode', null, {
      validateOnValueUpdate: true,
    });
    const { value: manager_address_pref } = useField('manager_address_pref', null, {
      validateOnValueUpdate: true,
    });
    const { value: manager_address_jp } = useField('manager_address_jp', null, {
      validateOnValueUpdate: true,
    });
    const { value: manager_address_en } = useField('manager_address_en', null, {
      validateOnValueUpdate: true,
    });
    const { value: staff_tel } = useField('staff_tel', null, {
      validateOnValueUpdate: true,
    });
    const { value: staff_email } = useField('staff_email', null, {
      validateOnValueUpdate: true,
    });
    const { value: staff_department_1 } = useField('staff_department_1', null, {
      validateOnValueUpdate: true,
    });
    const { value: staff_post_1 } = useField('staff_post_1', null, {
      validateOnValueUpdate: true,
    });
    const { value: staff_last_name_1 } = useField('staff_last_name_1', null, {
      validateOnValueUpdate: true,
    });
    const { value: staff_first_name_1 } = useField('staff_first_name_1', null, {
      validateOnValueUpdate: true,
    });
    const { value: staff_zip_1 } = useField('staff_zip_1', null, {
      validateOnValueUpdate: true,
    });
    const { value: staff_pref_1 } = useField('staff_pref_1', null, {
      validateOnValueUpdate: true,
    });
    const { value: staff_address_1 } = useField('staff_address_1', null, {
      validateOnValueUpdate: true,
    });
    const { value: staff_address_en_1 } = useField('staff_address_en_1', null, {
      validateOnValueUpdate: true,
    });
    const { value: staff_tel_1 } = useField('staff_tel_1', null, {
      validateOnValueUpdate: true,
    });
    const { value: staff_email_1 } = useField('staff_email_1', null, {
      validateOnValueUpdate: true,
    });
    const { value: staff_department_2 } = useField('staff_department_2', null, {
      validateOnValueUpdate: true,
    });
    const { value: staff_post_2 } = useField('staff_post_2', null, {
      validateOnValueUpdate: true,
    });
    const { value: staff_last_name_2 } = useField('staff_last_name_2', null, {
      validateOnValueUpdate: true,
    });
    const { value: staff_first_name_2 } = useField('staff_first_name_2', null, {
      validateOnValueUpdate: true,
    });
    const { value: staff_zip_2 } = useField('staff_zip_2', null, {
      validateOnValueUpdate: true,
    });
    const { value: staff_pref_2 } = useField('staff_pref_2', null, {
      validateOnValueUpdate: true,
    });
    const { value: staff_address_2 } = useField('staff_address_2', null, {
      validateOnValueUpdate: true,
    });
    const { value: staff_address_en_2 } = useField('staff_address_en_2', null, {
      validateOnValueUpdate: true,
    });
    const { value: staff_tel_2 } = useField('staff_tel_2', null, {
      validateOnValueUpdate: true,
    });
    const { value: staff_email_2 } = useField('staff_email_2', null, {
      validateOnValueUpdate: true,
    });
    const { value: staff_department_3 } = useField('staff_department_3', null, {
      validateOnValueUpdate: true,
    });
    const { value: staff_post_3 } = useField('staff_post_3', null, {
      validateOnValueUpdate: true,
    });
    const { value: staff_last_name_3 } = useField('staff_last_name_3', null, {
      validateOnValueUpdate: true,
    });
    const { value: staff_first_name_3 } = useField('staff_first_name_3', null, {
      validateOnValueUpdate: true,
    });
    const { value: staff_zip_3 } = useField('staff_zip_3', null, {
      validateOnValueUpdate: true,
    });
    const { value: staff_pref_3 } = useField('staff_pref_3', null, {
      validateOnValueUpdate: true,
    });
    const { value: staff_address_3 } = useField('staff_address_3', null, {
      validateOnValueUpdate: true,
    });
    const { value: staff_address_en_3 } = useField('staff_address_en_3', null, {
      validateOnValueUpdate: true,
    });
    const { value: staff_tel_3 } = useField('staff_tel_3', null, {
      validateOnValueUpdate: true,
    });
    const { value: staff_email_3 } = useField('staff_email_3', null, {
      validateOnValueUpdate: true,
    });
    const { value: staff_department_4 } = useField('staff_department_4', null, {
      validateOnValueUpdate: true,
    });
    const { value: staff_post_4 } = useField('staff_post_4', null, {
      validateOnValueUpdate: true,
    });
    const { value: staff_last_name_4 } = useField('staff_last_name_4', null, {
      validateOnValueUpdate: true,
    });
    const { value: staff_first_name_4 } = useField('staff_first_name_4', null, {
      validateOnValueUpdate: true,
    });
    const { value: staff_zip_4 } = useField('staff_zip_4', null, {
      validateOnValueUpdate: true,
    });
    const { value: staff_pref_4 } = useField('staff_pref_4', null, {
      validateOnValueUpdate: true,
    });
    const { value: staff_address_4 } = useField('staff_address_4', null, {
      validateOnValueUpdate: true,
    });
    const { value: staff_address_en_4 } = useField('staff_address_en_4', null, {
      validateOnValueUpdate: true,
    });
    const { value: staff_tel_4 } = useField('staff_tel_4', null, {
      validateOnValueUpdate: true,
    });
    const { value: staff_email_4 } = useField('staff_email_4', null, {
      validateOnValueUpdate: true,
    });
    const { value: note } = useField('note', null, {
      validateOnValueUpdate: true,
    });
    const { value: pdf_not_required } = useField('pdf_not_required', null, {
      validateOnValueUpdate: true,
    });



    return {
      errors,
      cancel_flag_model,
      test_flag_model,
      reception_info,
      exhibitor_name,
      exhibitor_name_kana,
      exhibitor_name_en,
      hall_div,
      category_disp,
      category_div,
      membership_category,
      past_achievement,
      past_achievement_label,
      site_url,
      appeal_information,
      booth_number,
      booth_area_fix,
      booth_area,
      calced_exhibition_fee,
      calced_application_fee,
      joint_adjacent,
      joint_exhibition,
      adjacent_exhibition,
      postCode,
      address_pref,
      address_jp,
      address_en,
      country_main,
      country_other,
      representative_position,
      representative_last_name,
      representative_first_name,
      brand1_name_jp,
      brand1_name_en,
      brand1_country_main,
      brand1_country_other,
      brand2_name_jp,
      brand2_name_en,
      brand2_country_main,
      brand2_country_other,
      brand3_name_jp,
      brand3_name_en,
      brand3_country_main,
      brand3_country_other,
      brand4_name_jp,
      brand4_name_en,
      brand4_country_main,
      brand4_country_other,
      brand5_name_jp,
      brand5_name_en,
      brand5_country_main,
      brand5_country_other,
      deputy_exhibitor_name,
      deputy_exhibitor_name_kana,
      deputy_exhibitor_name_en,
      deputy_representative_position,
      deputy_representative_last_name,
      deputy_representative_first_name,
      staff_department,
      staff_position,
      staff_last_name,
      staff_first_name,
      manager_postCode,
      manager_address_pref,
      manager_address_jp,
      manager_address_en,
      staff_tel,
      staff_email,
      staff_department_1,
      staff_post_1,
      staff_last_name_1,
      staff_first_name_1,
      staff_zip_1,
      staff_pref_1,
      staff_address_1,
      staff_address_en_1,
      staff_tel_1,
      staff_email_1,
      staff_department_2,
      staff_post_2,
      staff_last_name_2,
      staff_first_name_2,
      staff_zip_2,
      staff_pref_2,
      staff_address_2,
      staff_address_en_2,
      staff_tel_2,
      staff_email_2,
      staff_department_3,
      staff_post_3,
      staff_last_name_3,
      staff_first_name_3,
      staff_zip_3,
      staff_pref_3,
      staff_address_3,
      staff_address_en_3,
      staff_tel_3,
      staff_email_3,
      staff_department_4,
      staff_post_4,
      staff_last_name_4,
      staff_first_name_4,
      staff_zip_4,
      staff_pref_4,
      staff_address_4,
      staff_address_en_4,
      staff_tel_4,
      staff_email_4,
      note,
      pdf_not_required,

      isForeign,
      uploadFile,
      loadPdf,
      sendStatus,
      sendLabel,
      sendForm,
      enableFunctionCode,
    };
  },

  data() {
    return {
      second_order_bango: '',
      second_order_status: '',
      // propsに保存済みデータを確実に渡すため、APIデータ取得完了を監視
      // 新規投稿時はAPIへのアクセスがないため、常にtrue
      savedDataRetrieval: isValue(this.$route.params.id) ? false : true,
    }
  },
  async created() {
    this.address_pref_options = ADDRESS.address_pref_options

    this.api = api
    const res = await this.api.getConfig()
    this.$store.commit('disicion/exhibit_participation_status_options', res.data.results.exhibit_participation_status_options)
    this.$store.commit('disicion/membership_category_options_admin', res.data.results.membership_category_options_admin)
    this.$store.commit('disicion/past_achievement_options', res.data.results.past_achievement_options)
    this.$store.commit('disicion/category_div_options', res.data.results.category_div_options)
    this.$store.commit('disicion/joint_adjacent_options', res.data.results.joint_adjacent_options)
    this.$store.commit('disicion/country_main_options', res.data.results.country_main_options)
    this.$store.commit('disicion/country_other_options', res.data.results.country_other_options)
    this.$store.commit('disicion/category_disp_options_plus', res.data.results.category_disp_options_plus)
    this.$store.commit('disicion/hall_div_options_plus', res.data.results.hall_div_options_plus)
    this.activeCategoryDiv = CategoryDiv
    const pageID = this.$route.params.id
    if (!isValue(pageID)) {
      // 新規投稿
      this.isEditMode = false
      return
    }
    this.isEditMode = true
    // 編集
    const res_detail = await this.api.postAdminSecondDetail({
      exhibitor_id: pageID
    })
    this.second_order_status = res_detail.data.results.second_order_status
    this.second_order_bango = res_detail.data.results.json_data.second_order_bango
    this.exhibit_participation_status_label = res_detail.data.results.json_data.exhibit_participation_status_label
    this.exhibitor_name = res_detail.data.results.json_data.exhibitor_name
    this.exhibitor_name_kana = res_detail.data.results.json_data.exhibitor_name_kana
    this.exhibitor_name_en = res_detail.data.results.json_data.exhibitor_name_en
    this.category_disp = res_detail.data.results.json_data.category_disp
    const category_div1 = res_detail.data.results.json_data.category_div1
    const category_div2 = res_detail.data.results.json_data.category_div2
    const category_div = {
      category_div: typeof category_div1 === 'number' ? [String(category_div1)] : category_div1.split(','),
    }
    category_div['category_div_' + category_div1] = typeof category_div2 === 'string' ? category_div2.split(',') : null
    this.category_div = category_div
    this.membership_category = res_detail.data.results.json_data.membership_category
    this.past_achievement = res_detail.data.results.json_data.past_achievement
    this.past_achievement_label = res_detail.data.results.json_data.past_achievement_label
    this.site_url = res_detail.data.results.json_data.site_url
    this.appeal_information = res_detail.data.results.json_data.appeal_information
    this.booth_number = res_detail.data.results.json_data.koma_bango
    this.booth_area_fix = res_detail.data.results.json_data.decide_area
    this.booth_area = res_detail.data.results.json_data.order_area
    this.calced_exhibition_fee = res_detail.data.results.json_data.order_kingaku
    this.calced_application_fee = res_detail.data.results.json_data.order_deposit
    this.hall_div = res_detail.data.results.json_data.hall_div
    this.joint_adjacent = res_detail.data.results.json_data.joint_flag
    this.joint_exhibition = res_detail.data.results.json_data.jointly
    this.adjacent_exhibition = res_detail.data.results.json_data.adjoin

    this.postCode = res_detail.data.results.json_data.exhibitor_zip
    this.address_pref = res_detail.data.results.json_data.exhibitor_pref
    this.address_jp = res_detail.data.results.json_data.exhibitor_address
    this.address_en = res_detail.data.results.json_data.exhibitor_address_en
    this.country_main = String(res_detail.data.results.json_data.exhibitor_country)
    this.country_other = String(res_detail.data.results.json_data.exhibitor_country_other)
    this.representative_position = res_detail.data.results.json_data.representative_post
    this.representative_last_name = res_detail.data.results.json_data.representative_last_name
    this.representative_first_name = res_detail.data.results.json_data.representative_first_name
    this.brand1_name_jp = res_detail.data.results.json_data.brand_name_1
    this.brand1_name_en = res_detail.data.results.json_data.brand_name_en_1
    this.brand1_country_main = String(res_detail.data.results.json_data.brand_country_1)
    this.brand1_country_other = String(res_detail.data.results.json_data.brand_country_other_1)
    this.brand2_name_jp = res_detail.data.results.json_data.brand_name_2
    this.brand2_name_en = res_detail.data.results.json_data.brand_name_en_2
    this.brand2_country_main = String(res_detail.data.results.json_data.brand_country_2)
    this.brand2_country_other = String(res_detail.data.results.json_data.brand_country_other_2)
    this.brand3_name_jp = res_detail.data.results.json_data.brand_name_3
    this.brand3_name_en = res_detail.data.results.json_data.brand_name_en_3
    this.brand3_country_main = String(res_detail.data.results.json_data.brand_country_3)
    this.brand3_country_other = String(res_detail.data.results.json_data.brand_country_other_3)
    this.brand4_name_jp = res_detail.data.results.json_data.brand_name_4
    this.brand4_name_en = res_detail.data.results.json_data.brand_name_en_4
    this.brand4_country_main = String(res_detail.data.results.json_data.brand_country_4)
    this.brand4_country_other = String(res_detail.data.results.json_data.brand_country_other_4)
    this.brand5_name_jp = res_detail.data.results.json_data.brand_name_5
    this.brand5_name_en = res_detail.data.results.json_data.brand_name_en_5
    this.brand5_country_main = String(res_detail.data.results.json_data.brand_country_5)
    this.brand5_country_other = String(res_detail.data.results.json_data.brand_country_other_5)
    this.deputy_exhibitor_name = res_detail.data.results.json_data.agent_name
    this.deputy_exhibitor_name_kana = res_detail.data.results.json_data.agent_name_kana
    this.deputy_exhibitor_name_en = res_detail.data.results.json_data.agent_name_en
    this.deputy_representative_position = res_detail.data.results.json_data.agent_representative_post
    this.deputy_representative_last_name = res_detail.data.results.json_data.agent_representative_last_name
    this.deputy_representative_first_name = res_detail.data.results.json_data.agent_representative_first_name

    this.staff_department = res_detail.data.results.json_data.staff_department
    this.staff_position = res_detail.data.results.json_data.staff_post
    this.staff_last_name = res_detail.data.results.json_data.staff_last_name
    this.staff_first_name = res_detail.data.results.json_data.staff_first_name
    this.manager_postCode = res_detail.data.results.json_data.staff_zip
    this.manager_address_pref = res_detail.data.results.json_data.staff_pref
    this.manager_address_jp = res_detail.data.results.json_data.staff_address
    this.manager_address_en = res_detail.data.results.json_data.staff_address_en
    this.staff_tel = res_detail.data.results.json_data.staff_tel
    this.staff_email = res_detail.data.results.json_data.staff_email

    this.staff_department_1 = res_detail.data.results.json_data.staff_department_1
    this.staff_post_1 = res_detail.data.results.json_data.staff_post_1
    this.staff_last_name_1 = res_detail.data.results.json_data.staff_last_name_1
    this.staff_first_name_1 = res_detail.data.results.json_data.staff_first_name_1
    this.staff_zip_1 = res_detail.data.results.json_data.staff_zip_1
    this.staff_pref_1 = res_detail.data.results.json_data.staff_pref_1
    this.staff_address_1 = res_detail.data.results.json_data.staff_address_1
    this.staff_address_en_1 = res_detail.data.results.json_data.staff_address_en_1
    this.staff_tel_1 = res_detail.data.results.json_data.staff_tel_1
    this.staff_email_1 = res_detail.data.results.json_data.staff_email_1

    this.staff_department_2 = res_detail.data.results.json_data.staff_department_2
    this.staff_post_2 = res_detail.data.results.json_data.staff_post_2
    this.staff_last_name_2 = res_detail.data.results.json_data.staff_last_name_2
    this.staff_first_name_2 = res_detail.data.results.json_data.staff_first_name_2
    this.staff_zip_2 = res_detail.data.results.json_data.staff_zip_2
    this.staff_pref_2 = res_detail.data.results.json_data.staff_pref_2
    this.staff_address_2 = res_detail.data.results.json_data.staff_address_2
    this.staff_address_en_2 = res_detail.data.results.json_data.staff_address_en_2
    this.staff_tel_2 = res_detail.data.results.json_data.staff_tel_2
    this.staff_email_2 = res_detail.data.results.json_data.staff_email_2

    this.staff_department_3 = res_detail.data.results.json_data.staff_department_3
    this.staff_post_3 = res_detail.data.results.json_data.staff_post_3
    this.staff_last_name_3 = res_detail.data.results.json_data.staff_last_name_3
    this.staff_first_name_3 = res_detail.data.results.json_data.staff_first_name_3
    this.staff_zip_3 = res_detail.data.results.json_data.staff_zip_3
    this.staff_pref_3 = res_detail.data.results.json_data.staff_pref_3
    this.staff_address_3 = res_detail.data.results.json_data.staff_address_3
    this.staff_address_en_3 = res_detail.data.results.json_data.staff_address_en_3
    this.staff_tel_3 = res_detail.data.results.json_data.staff_tel_3
    this.staff_email_3 = res_detail.data.results.json_data.staff_email_3

    this.staff_department_4 = res_detail.data.results.json_data.staff_department_4
    this.staff_post_4 = res_detail.data.results.json_data.staff_post_4
    this.staff_last_name_4 = res_detail.data.results.json_data.staff_last_name_4
    this.staff_first_name_4 = res_detail.data.results.json_data.staff_first_name_4
    this.staff_zip_4 = res_detail.data.results.json_data.staff_zip_4
    this.staff_pref_4 = res_detail.data.results.json_data.staff_pref_4
    this.staff_address_4 = res_detail.data.results.json_data.staff_address_4
    this.staff_address_en_4 = res_detail.data.results.json_data.staff_address_en_4
    this.staff_tel_4 = res_detail.data.results.json_data.staff_tel_4
    this.staff_email_4 = res_detail.data.results.json_data.staff_email_4

    this.note = res_detail.data.results.json_data.note


    this.cancel_flag_model = res_detail.data.results.json_data.cancel_flag === 1 ? true : false
    this.test_flag_model = res_detail.data.results.json_data.test_flag === 1 ? true : false

    this.reception_info = res_detail.data.results.json_data.reception_info === 1 ? true : false
    this.savedDataRetrieval = true

  },
  mounted() {

  },
  methods: {
    onZipInput(e, key = null) {
      if (!zipNumOnlyTest(e.target.value)) return
      const zipValue = makeZip(e.target.value)
      this[key] = zipValue
    },
    onZipChange(e, target, pref, address) {
      const val = e.target.value.replace(/[^0-9]/g, '')
      if (val.length === 7) {
        KenAll(val).then(res => {
          const prefecture = res[0].shift()
          if (res.length === 0) {
            target[pref] = '該当する住所はありません';
            target[address] = '該当する住所はありません';
          } else {
            const pref_match = this.address_pref_options.find(item => item.label === prefecture)
            target[pref] = pref_match.value;
            target[address] = res[0].join('');
          }
        });
      }
    },
    async download() {
      const pageID = this.$route.params.id
      if (!isValue(pageID)) {
        return
      }

      try {
        const query = {
          exhibitor_id: [pageID]
        }
        const res = await this.api.post('/api/admin/second_order/download', query)

        if (!res.response?.data) {
          this.$store.commit('message/errors', ['ダウンロードができません。'])
          throw new Error('API Not Found')
        }

        if (res.response.data?.error_results) {
          this.$store.commit('message/errors', res.response.data?.error_results.message)
          throw new Error('API Validation Error')
        }

        await this.api.download(res.response.data.results.url)

      } catch (e) {
        console.error(e.message)
        return false
      }
    }
  },
  watch: {
    category_div: function (v) {
      this.$store.commit('admin/category_div', v)
    },
  },
  computed: {
    country_main_options() {
      return this.$store.state.disicion.country_main_options
    },
    country_other_options() {
      return this.$store.state.disicion.country_other_options
    },

    exhibit_participation_status_options() {
      return this.$store.state.disicion.exhibit_participation_status_options
    },
    membership_category_options() {
      return [{label : '選択してください', value : ''}, ...this.$store.state.disicion.membership_category_options_admin]
    },
    membership_category_label() {
      const array = this.$store.state.disicion.membership_category_options
      return array.find(item => item.value === String(this.membership_category))?.label
    },
    second_order_status_options() {
      return this.$store.state.admin.second_order_status_options
    },
    second_order_status_label() {
      const array = this.$store.state.admin.second_order_status_options
      return array.find(item => item.value === String(this.second_order_status))?.label
    },
    past_achievement_options() {
      return this.$store.state.disicion.past_achievement_options
    },
    joint_adjacent_options() {
      const options = this.$store.state.disicion.joint_adjacent_options.map(item => {
        item.value = typeof item.value === 'string' ? parseInt(item.value) : item.value
        return item
      })
      return options
    },
    category_div_options() {
      return this.$store.state.disicion.category_div_options
    },
    category_disp_options_plus() {
      return this.$store.state.disicion.category_disp_options_plus
    },
    hall_div_options_plus() {
      return this.$store.state.disicion.hall_div_options_plus
    },
  }
};
</script>

<style></style>
