<template>
  <li v-for="(option, index) in options" :key="index">
    <label>
      <input type="radio" :name="name" :value="option.value" :checked="modelValue === option.value"
        @change="updateValue" />
      <span class="radio_text">{{ option.label }}</span>
    </label>
  </li>
</template>

<script>
export default {
  name: "MyRadio",
  props: {
    modelValue: { type: String, required: true },
    options: { type: Array, required: true },
    name: { type: String, required: true },
  },
  methods: {
    updateValue: function (e) {
      this.$emit('update:modelValue', e.target.value)
    }
  },
};
</script>
