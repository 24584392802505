<template>
  <h2>
    <div class="p2_title">
      <span class="title">出展社名:</span> {{ exhibitor_name }}
    </div>
    <span class="koma_number" v-if="koma_bango">小間番号: {{ koma_bango }}</span>
    <button id="logout" @click="handleLogout">ログアウト</button>
  </h2>
</template>

<script setup>
import { store } from '@/store'
import {
  logout
} from '@/helper/logout';

const handleLogout = () => {
  logout()
}

const { exhibitor_name, koma_bango } = store.state.entrydata;
</script>
