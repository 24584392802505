<template>
  <table class="common_table" v-if="list.length">
    <tbody>
      <tr>
        <th class="w3em">番号 </th>
        <th class="w15em">保税貨物名 </th>
        <th class="w4em">個数</th>
        <th class="w10em">製造国名</th>
        <th>価格 <br>
        （F.O.B/U.S.$）</th>
        <th colspan="2" v-if="isShowUI">順番</th>
        <th colspan="2">編集</th>
      </tr>

      <tr v-for="(val, key) in list" :key="key">
        <td class="ta_right ">{{ key + 1 }}</td>
        <td>{{ val.item_name }}</td>
        <td class="ta_right">{{ val.quantityUnit }}</td>
        <td>{{ val.country_name }}</td>
        <td class="ta_right">{{ val.priceUnit }}</td>
        <td class="edit_cell" v-if="isShowUI"><a
          @click.prevent="sortUp(val, fetch)"
          href="#form1"
          class="common_button_t2 modalOpen"
          v-if="showOrderPrev(key)"
        >▲</a></td>
        <td class="edit_cell" v-if="isShowUI"><a
          @click.prevent="sortDown(val, fetch)"
          href="#form1"
          class="common_button_t2 modalOpen"
          v-if="showOrderNext(key, list.length - 1)"
        >▼</a></td>
        <td class="edit_cell">
          <a @click.prevent="editHandler($event, val.application_id)" href="#modal1" class="common_button_t2 modalOpen">変更</a>
        </td>
        <td class="edit_cell" v-if="isShowUI">
          <a @click.prevent="deleteHandler($event, val.application_id)" class="common_button_t2 gray_button delete_button">削除</a>
        </td>
      </tr>
    </tbody>
  </table>
  <div class="no_entry" v-else>登録がありません。「新規追加」ボタンより登録をお願いします。</div>
  <template v-if="isShowUI">
  <div>
    <a @click.prevent="newHandler" href="#modal1" class="common_add_button modalOpen">新規追加</a>
  </div>
  </template>
  <table class="common_table">
    <tbody>
      <tr>
        <th class="ta_right w18em">合計</th>
        <td class="ta_right w4em">{{ totalQty }}</td>
        <td class="ta_right">{{ totalPrice }}</td>
        <td class="w32em no_bg_border"></td>
      </tr>
    </tbody>
  </table>
  <Teleport to="body">

    <!-- モーダルコンテンツ / -->
    <!-- 製作注意問い合わせ先は何種類かあります。読み込み形式で簡単に編集できるようにしたほうが良いかと思います。-->
    <div class="overlay" id="modal1">
      <div id="modal_content" class="modal_contents w80">
        <!-- modal contents ここから-->
        <div class="modalClose_ctn">
          <a @click.prevent="modalClose" class="modalClose"></a>
        </div>
        <div class="entry_title">
          <h4>保税貨物</h4>
          <div class="entry_title">
            <h5
              :set="counter = list.findIndex(el => el.application_id === form.application_id)"
            >No.{{ counter >= 0
              ? counter + 1
              : list.length + 1 }}&nbsp;&nbsp;{{ uploadLabel }}</h5>
          </div>
        </div>
        <div class="entry_modal_ctn">
          <table class="common_table">
            <tbody>
              <tr>
                <th>保税貨物名</th>
                <td><input type="text" class="full" v-model="form.item_name">
                  <p class="alert" v-if="errors.item_name">{{ errors.item_name }}</p></td>
              </tr>
              <tr>
                <th>数量</th>
                <td><input type="number" class="quarter" v-model="form.quantity">
                  <p class="alert" v-if="errors.quantity">{{ errors.quantity }}</p></td>
              </tr>
              <tr>
                <th>製造国名</th>
                <td><input type="text" class="half" v-model="form.country_name">
                  <p class="alert" v-if="errors.country_name">{{ errors.country_name }}</p></td>
              </tr>
              <tr>
                <th>s価格(F.O.B/U.S.$)</th>
                <td>$ <input type="number" class="half" v-model="form.price">
                  <p class="alert" v-if="errors.price">{{ errors.price }}</p></td>
              </tr>

            </tbody>
          </table>
        </div>

        <div class="button_ctn" v-if="isShowUI">
          <ul>
            <li>
              <button @click.prevent="modalClose" class="gray_button modalClose">キャンセル</button>
            </li>
            <li>
              <button @click="sendForm" class="regist_button">
                上記内容で登録
              </button>
            </li>
          </ul>
        </div>
        <!-- modal contents ここまで-->
      </div>
    </div>
    <!-- / モーダルコンテンツ -->
  </Teleport>
</template>

<script setup>
import { reactive, computed } from 'vue'
import APIClient from '@/store/repository/APIClient'
import { forEach, cloneDeep } from 'lodash'
import { FormValidate } from '@/helper/FormValidate'
import * as yup from 'yup'
import moment from "moment"

import {
  modalOpen,
  modalClose,
} from '@/helper/modal'

import {
  sortUp,
  sortDown,
  showOrderPrev,
  showOrderNext,
} from '@/helper/order'

import ApiConst from '@/const/api'

import { isShowable as isShowUI } from '@/helper/indexStatus'

const api = new APIClient()

const list = reactive([])

const formInit = {
  application_id: null,
  item_name: '',
  quantity: null,
  country_name: '',
  price: null,
}
const form = reactive(cloneDeep(formInit))

const totalQty = computed(() => list.reduce((sum, el) => sum + el.quantity, 0).toLocaleString())
const totalPrice = computed(() => '$ ' + list.reduce((sum, el) => sum + el.price, 0).toLocaleString())

const uploadLabel = computed(() => {
  return form.application_id ? '変更' : '新規登録'
})

const fetch = async () => {
  const query = {
    form_no: 1,
    application_div: "tax_cargo",
  }
  const fetchApi = await api.get(ApiConst.API_APP_LIST, query)
  const res = fetchApi.response
  const errors = res.data?.error_results
  if (errors) {
    throw new Error('Not found.')
  }
  list.splice(0)
  res.data.results.list.map((el) => {
    return {
      application_id: el.application_id,
      input_date: moment(el.updated_at).format("YY-MM-DD"),
      seq_no: el.seq_no,
      ...el.json_data
    }
  }).forEach((val, key) => {
    forEach(val, (v, k) => {
      val[k] = v ?? formInit[k]
    })
    val.quantityUnit = val.quantity.toLocaleString()
    val.priceUnit = '$ ' + val.price.toLocaleString()
    list[key] = val
  })
}
fetch()

const newHandler = (event) => {
  forEach(formInit, (v, k) => {
    form[k] = v
  })
  modalOpen(event)
}

const editHandler = (event, key) => {
  forEach(form, (v, k) => {
    form[k] = list.find(e => e.application_id === key)[k]
  })
  modalOpen(event)
}

const deleteHandler = async (event, key) => {
  if (!window.confirm('削除して宜しいですか？')) return false

  try {
    const application_id = list.find(e => e.application_id === key).application_id
    const operation = "D"
    const query = { operation, application_id }
    const res = await api.post(ApiConst.API_APP_EDIT, query)
    if (res.response.data?.error_results) {
      throw new Error('API Validation Error')
    }
    fetch()
  } catch (e) {
    return false
  }
}

const scheme = {
  item_name: yup.string().trim().required('必須の項目です。'),
  quantity: yup.number().typeError('数値を入力してください。').required('必須の項目です。'),
  country_name: yup.string().trim().required('必須の項目です。'),
  price: yup.number().typeError('数値を入力してください。').required('必須の項目です。'),
}
const validate = new FormValidate(scheme, form)
const errors = validate.errors
const sendForm = async () => {
  try {
    if ((Object.keys(await validate.getErrorObject()).length)) {
      throw new Error('入力項目に不備があります。')
    }
    const params = cloneDeep(form)
    const application_id = params.application_id
    delete params.application_id
    const operation = application_id ? "R" : "I"
    const query = operation === "I"
      ? { operation, application_div: "tax_cargo", form_no: 1, json_data: params }
      : { operation, application_id, json_data: params }
    const res = await api.post(ApiConst.API_APP_EDIT, query)
    if (res.response.data?.error_results) {
      throw new Error('API Validation Error')
    }
    fetch()
    modalClose()
  } catch (e) {
    return false
  }
}
</script>

<style></style>
