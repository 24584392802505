export default Object.freeze({
  "PROHIBIT_PLATFORM_DEPENDENT_CHARACTERS": '^[！”＃＄％＆’（）＝～｜‘｛＋＊｝＜＞？＿－＾￥＠「；：」、。・!"#$%&\'()\\*\\+\\-\\.,\\/:;<=>?@\\[\\]^_{|}~ぁ-んァ-ヶーa-zA-Z0-9一-龠０-９ー、。\n\r\\s]*$',
  "PROHIBIT_PLATFORM_DEPENDENT_CHARACTERS_MSG": '機種依存文字は入力できません。',
  "HALF_WIDTH_ALPHANUMERIC": '^[!"#$%&\'()\\*\\+\\-\\.,\\/:;<=>?@\\[\\]^_{|}~0-9a-zA-Z\\s\\-\\_\n\r]*$',
  "HALF_WIDTH_ALPHANUMERIC_MSG": '半角英数字のみです。',
  "TEXT_MAX_BOOTH_INFO": 650,
  "TEXT_MAX_BOOTH_INFO_MSG": `${650}文字以内で入力してください。`,
  "TEXT_MAX_PATTERN_BOOTH_INFO_EN": `^\\s*(?:\\S+(?:\\s+\\S+){0,${350 - 1}}\\s*)?$`,
  "TEXT_MAX_BOOTH_INFO_EN_MSG": `${350} words以内で入力してください。`,
  "TEXT_MAX_EXHIBITS_LIST": 300,
  "TEXT_MAX_EXHIBITS_LIST_MSG": `${300}文字以内で入力してください。`,
  "TEXT_MAX_PATTERN_EXHIBITS_LIST_EN": `^\\s*(?:\\S+(?:\\s+\\S+){0,${150 - 1}}\\s*)?$`,
  "TEXT_MAX_EXHIBITS_LIST_EN_MSG": `${150} words以内で入力してください。`,
  "IMAGE_FILE_FORMAT": ['jpg', 'jpeg', 'png'],
  "IMAGE_FILE_FORMAT_MSG": 'JPEGもしくはPNG形式の画像のみサポートされています。',
  "IMAGE_BASE64_SIZE_5MB": 5 * 1024 * 1024,
  "IMAGE_BASE64_SIZE_5MB_MSG": '画像ファイルの容量は4MB以内としてください。',
  "PHONE_PATTERN": '^(?:\\+?\\d+-)?\\d+(?:-\\d+){2}$|^\\+?\\d+$',
  "PHONE_PATTERN_MSG": '電話番号の形式が不正です。',
  "ZIP_PATTERN": '^[0-9]{3}-[0-9]{4}$',
  "ZIP_NULLABLE_PATTERN": '^(?:|[0-9]{3}-[0-9]{4})$',
  "ZIP_PATTERN_MSG": '郵便番号の形式が不正です。',
  "KATAKANA_PATTERN": '^[ァ-ヴー、\x20\u3000０-９]+$',
  "KATAKANA_PATTERN_MSG": '全角カタカナで入力してください。',
})
