export default Object.freeze({
  LANG_LABELS: {
    'ja': '日本語',
    'en': '英語',
  },
  FORM_INIT: {
    billing_id: null,
    company_name: "",
    company_name_kana: "",
    company_zip: "",
    company_address1: "",
    company_address2: "",
    staff_affiliation: "",
    staff_post: "",
    staff_name: "",
    staff_tel1: "",
    staff_tel2: "",
    staff_email: "",
    lang: "",
  },
})
