<template>
  <div id="admin">
    <header>
      <h1>JAIS 2025 出展者登録DB&nbsp;&nbsp;管理者用ページ</h1>
    </header>
    <article>
      <div id="form_ctn" class="form_box">

        <ErrorComponent></ErrorComponent>

        <h3>管理者アカウント一覧</h3>

        <h4>基本情報</h4>
        <table class="common_table exhibitor_table">
          <tbody>
            <tr>
              <th>管理者アカウント名</th>
              <td>
                <input type="text" class="full" v-model="admin_name"  />
                <p class="alert" v-if="errors.admin_name">{{ errors.admin_name }}</p>
              </td>
            </tr>
            <tr>
              <th>ログインID</th>
              <td>
                <input type="text" class="full" v-model="admin_login_id" />
                <p class="alert" v-if="errors.admin_login_id">{{ errors.admin_login_id }}</p>
              </td>
            </tr>
            <tr>
              <th>ログインパスワード</th>
              <td>
                <input type="password" ref="admin_login_pw_ref" class="full" autocomplete="new-password" />
                <p>パスワードを変更しない場合は空白のままとしてください。</p>
              </td>
            </tr>
            <tr>
              <th>テストデータ参照権限</th>
              <td>
                <ul class="common_list_horizontal">
                  <li>
                    <label
                      ><input type="checkbox" v-model="test_flag" :true-value="1" :false-value="0" /><span class="checkbox_text"
                        >参照</span
                      ></label
                    >
                  </li>
                </ul>
              </td>
            </tr>
          </tbody>
        </table>
        <h4>許可機能</h4>
        <table class="common_table exhibitor_table">
          <tbody>
            <tr>
              <th>機能</th>
              <td>
                <ul class="flex_list2">
                  <MyCheckboxVertical v-model="permission" :options="permission_options"></MyCheckboxVertical>
                </ul>
              </td>
            </tr>
            <tr>
              <th>各種申込</th>
              <td>
                <ul>
                <PermissionSelect v-model="application" :options="application_options" />
                </ul>
              </td>
            </tr>
          </tbody>
        </table>
        <h4>観覧可能出展カテゴリー</h4>
        <table class="common_table exhibitor_table">
          <tbody>
            <tr>
              <th>出展カテゴリー</th>
              <td>
                <ul class="flex_list2">
                  <MyCheckboxVertical v-model="fix_category" :options="category_options"></MyCheckboxVertical>
                </ul>
              </td>
            </tr>
          </tbody>
        </table>

        <ul class="common_list_horizontal">
          <li>
            <router-link class="common_button gray_button" :to="{name:'AdminAccountIndex'}">一覧に戻る</router-link>
          </li>
          <li><button class="common_button" @click="sendForm" :class="sendStatus" :disabled="sendDisabled">{{ sendLabel }}</button></li>
        </ul>
      </div>

    </article>
  </div>
</template>

<script setup>
import { ref, computed, reactive } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { map, reduce, cloneDeep } from 'lodash'
import { store } from '@/store'
import APIClient from '@/store/repository/APIClient'
import MyCheckboxVertical from '@/components/MyCheckboxVertical.vue'
import PermissionSelect from '@/components/PermissionSelect.vue'
import * as yup from 'yup'
import { isValue } from '@/helper/index'
import ErrorComponent from '@/components/ErrorComponent.vue'
import { FormValidate } from '@/helper/FormValidate'

const route = useRoute()
const router = useRouter()

const pageID = route.params.id

const api = new APIClient()

const fetchConfig = async () => {
  const res = await api.getConfig()
  store.commit('disicion/category_disp_options', res.data.results.category_disp)
  store.commit('disicion/function_code_options', res.data.results.function_code)
  store.commit('disicion/admin_application_options', res.data.results.admin_application)
}
fetchConfig()

const fetch = async (admin_id) => {
  const query = {
    admin_id
  }
  const fetchApi = await api.get('/api/admin/adminaccount/detail', query)
  const res = fetchApi.response
  const errors = res.data?.error_results
  if (errors) {
    throw new Error('Not found.')
  }
  store.dispatch('admin_edit_account/update', res.data.results)
}
store.dispatch('admin_edit_account/reset')
if (pageID) fetch(pageID)

const writableSubject = (state, key) => {
  return {
    get: () => store.state.admin_edit_account[state][key],
    set: (value) => {
      store.dispatch('admin_edit_account/put', {state, key, value})
    }
  }
}

const accountData = {}
Array.prototype.slice.call(['admin_id', 'admin_name', 'admin_login_id', 'test_flag', 'permission', 'application', 'fix_category'], 0).forEach((key) => {
  accountData[key] = computed(writableSubject('current_edit_subject', key))
})
let {admin_id, admin_name, admin_login_id, test_flag, permission, application, fix_category} = accountData

const admin_login_pw_ref = ref(null)

const scheme = {
  admin_name: yup.string().trim().required('必須の項目です。').min(2, '2文字以上で入力してください。'),
  admin_login_id: yup.string().trim().required('必須の項目です。').min(5, '5文字以上で入力してください。'),
}
const fields = reactive(reduce(cloneDeep(scheme),  (e, v, k) => {
  e[k] = accountData[k]
  return e
}, {}))
const validate = new FormValidate(scheme, fields)
const errors = validate.errors

const sendStatus = ref('')
const sendDisabled = ref(false)
const defaultSendLabel = '編集を反映する'
const sendLabel = ref(defaultSendLabel)

const pushRestriction = (message) => {
  sendStatus.value = 'error'
  sendLabel.value = message
  sendDisabled.value = true
  setTimeout(() => {
    sendStatus.value = ''
    sendLabel.value = defaultSendLabel
    sendDisabled.value = false
  }, 2000)
}
const onInvalidSubmit = (errors) => {
  console.error(errors)
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  })
}
const sendForm = async () => {
  try {
    if ((Object.keys(await validate.getErrorObject()).length)) {
      throw new Error('入力項目に不備があります。')
    }
    const admin_login_pw = admin_login_pw_ref.value
    const query = {
      admin_login_id: admin_login_id.value,
      admin_name: admin_name.value,
      test_flag: test_flag.value,
      function_code_list: permission.value,
      admin_application: application.value,
      fix_category_disp_list: fix_category.value,
    }
    if (isValue(admin_id.value)) query.admin_id = admin_id.value
    if (isValue(admin_login_pw.value)) query.admin_login_pw = admin_login_pw.value
    if (!isValue(pageID)) {
      // 新規投稿
      query.operation = 'I'
    } else {
      // 編集
      query.operation = 'R'
    }
    const res = await api.post('/api/admin/adminaccount/edit', query)
    if (res.response.data?.error_results) {
      throw new Error('API Validation Error')
    }
  } catch (e) {
    onInvalidSubmit(e)
    pushRestriction('入力項目に不備があります。')
    return false
  }
  sendStatus.value = 'comptele'
  sendLabel.value = '登録が完了しました。'
  pushRestriction(`ID【${admin_id.value}】の情報を更新しています`)
  router.push({name:'AdminAccountIndex'})
}

const permission_options = computed(() => map(store.state.disicion.function_code_options, (k, v) => {
  return {value: v, label: k}
}))

const application_options = computed(() => map(store.state.disicion.admin_application_options, (k, v) => {
  return {name: v, label: k}
}))

const category_options = computed(() => map(store.state.disicion.category_disp_options, (k, v) => {
  return {value: v, label: k}
}))

</script>

<style></style>
