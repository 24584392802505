<template>
  <header><h1>JAPAN MOBILITY SHOW 2025&emsp;出展手続きサイト・出展者専用サイト</h1></header>
  <article><h2>404エラー</h2>
  <div id="form_ctn" class="form_box">

    <p>ページが見つかりません</p>

    <router-link to="/">1次申込が完了していない方はこちら</router-link>
    <br><br>
    <router-link to="/login">1次申込が完了している方はこちら</router-link>

  </div>
</article>
</template>

<script>

</script>

<style>
</style>
