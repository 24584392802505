<template>
  <header>
    <h1>JAPAN MOBILITY SHOW 2025&emsp;出展手続きサイト・出展者専用サイト</h1>
  </header>
  <article>
    <h2>新規パスワードの設定</h2>
    <div id="form_ctn" class="form_box">
      <ErrorComponent></ErrorComponent>
      <p>新しいパスワードは8文字以上で入力して「送信」ボタンをクリックしてください。<br>
        使える文字は半角英数字記号のみです。</p>
      <dl class="enableList"><dt>使用可能な文字 : </dt><dd><pre><span v-html="enablePassword"></span></pre></dd></dl>

      <ul class="common_list_vertical">
        <li>
          <my-input name="password" width_class="half" type="password" v-model="password"
            placeholder="新しいパスワードを入力してください。">
          </my-input>
          <p class="alert" v-if="errors.password">{{ errors.password }}</p>
        </li>
        <li>
          <my-input name="confirmation" width_class="half" type="password" v-model="confirmation"
            placeholder="新しいパスワードをもう一度入力してください。"></my-input>

          <p class="alert" v-if="errors.confirmation">{{ errors.confirmation }}</p>
        </li>
      </ul>
      <div class="button_ctn">
        <button class="submit" @click="sendForm">送信</button>
      </div>

    </div>
  </article>
</template>

<script>
import APIClient from '@/store/repository/APIClient'
import MyInput from '@/components//MyInput.vue';
import ErrorComponent from '@/components/ErrorComponent.vue';
import { useField, useForm } from 'vee-validate';

import * as yup from 'yup';
import { useRouter,useRoute } from 'vue-router'

const api = new APIClient()

export default {
  components: {
    MyInput,
    ErrorComponent,
  },
  setup() {


    const router = useRouter();
    const route = useRoute();
    console.log(route.query)
    const schema = yup.object({
      password: yup
        .string()
        .required("パスワードを入力してください。")
        .min(8, `8文字以上の半角英数字で入力してください。`),
        
      confirmation: yup.string()
        .oneOf([yup.ref('password'), null], 'パスワードが一致しません。').required("確認用パスワードを入力してください。")

    });

    const { errors, handleSubmit } = useForm({
      validationSchema: schema,
    });
    
    const sendForm =  handleSubmit(async (values) => {
      try {
        
        const query = {
          //左がAPI仕様書の名前 右がdataの名前
          login_pw: values.password,
          login_pw_confirm: values.confirmation,
          password_reset_key: route.query
        }

        const res = await api.post('/api/exhibitor/passwordreset/reset', query)
        if (res.response.data?.error_results) {
          throw new Error('API Validation Error')
        }
      } catch (e) {
        console.error(e.message)
        return false
      }
      router.push({ path: 'password_enter_complete' });
    });
    


    const { value: password } = useField('password', null, {
      validateOnValueUpdate: false,
    });
    const { value: confirmation } = useField('confirmation', null, {
      validateOnValueUpdate: false,
    });

    const enablePassword = `小文字のアルファベット (a-z)
大文字のアルファベット (A-Z)
数字 (0-9)
特殊文字：!@#$%^&*()_+<>?/.,;:'[]{}|~=-`

    return {
      errors,
      password,
      confirmation,
      sendForm,
      enablePassword,
    };
  },
  async created() {
    this.api = new APIClient()

  },
  mounted() {

  },
  methods: {

  },
  watch: {

  },
  computed: {
    

  }
};
</script>

<style scoped>
.enableList {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 0.5em;
  margin-bottom: 20px;
}
</style>
