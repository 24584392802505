<template>
  <header><h1>JAPAN MOBILITY SHOW 2025&emsp;出展手続きサイト・出展者専用サイト</h1></header>
  <article><h2>ただいまメンテナンス中です。</h2>
  <div id="form_ctn" class="form_box">

    <p class="ta_center">ただいまメンテナンスのため一時サービスを停止しております。<br>
大変ご不便をおかけいたしますが、今しばらくお待ちください。</p>


  </div>
</article>
</template>

<script>

</script>

<style>
</style>
