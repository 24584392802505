<template>
  <div :id="containerID">
    <router-view />
  </div>
  <section id="page_top"><a href="#main_ctn">▲</a></section>
</template>

<script setup>
import { watch, ref } from 'vue'
import { useRoute } from 'vue-router'

const route = useRoute();
const containerID = ref('')

watch(route, (to) => {
  const matched = to.matched;
  if (
    matched.some((record) => {
      const adminContainer = record.meta.adminContainer;
      if (adminContainer) return true;
    })
  ) {
    containerID.value = 'admin'
  } else {
    containerID.value = ''
  }
})
</script>

<style>
::placeholder {
  color: #dfdfdf;
}
/* 旧Edge対応 */
::-ms-input-placeholder {
  color: #dfdfdf;
}
/* IE対応 */
:-ms-input-placeholder {
  color: #dfdfdf;
}

#app article#p2 .tab2_list li {
  width: calc(100% / 4 - 10px);
}

table.application_status {
  width: 100%;
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
}
table.application_status th,
table.application_status td {
  border: 1px solid #459fb6;
  padding: 10px;
  text-align: center;
}
table.application_status th {
  background-color: #459fb6;
  border-right-color: #fff;
  color: #fff;
}
table.application_status th:last-child {
  border-right-color: #459fb6;
}

.status_tag {
  padding: 8px;
  line-height: 1;
  display: block;
  color: #fff;
}
.status_tag.finished {
  background-color: #36c574;
}
.status_tag.unfinished {
  background-color:#d74444;
}

.step_list li h5 {
  text-align: center;
  font-size: 1em;
  margin: 0;
  border-bottom: 1px dotted #aaa;
  padding-bottom: 5px;
}

.step_list li.active {
  border-color: #459fb6;
  background: #e0eef2;
}
.step_list li.active h4 {
  background-color: #459fb6;
  color: #fff;
}

.step_list + * {
  margin-top: 1em;
}

.step_list_notes {
  margin-bottom: 10px;

  li {
    display: flex;
    flex-wrap: nowrap;
    &::before {
      content: '※';
      display: block;
    }
  }
}

</style>
