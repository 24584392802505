<template>
  <header>
    <h1>JAPAN MOBILITY SHOW 2025&emsp;出展手続きサイト・出展者専用サイト</h1>
  </header>
  <article>
    <h2>ログイン</h2>
    <div id="form_ctn" class="form_box">

      <ErrorComponent></ErrorComponent>

      <dl id="login_list">
        <dt>ID</dt>
        <dd>
          <my-input name="id" width_class="full" type="text" v-model="id"></my-input>
          <p class="alert" v-if="errors.id">{{ errors.id }}</p>
        </dd>
        <dt>パスワード</dt>
        <dd>
          <my-input name="password" width_class="full" type="password" v-model="password"></my-input>
          <p class="alert" v-if="errors.password">{{ errors.password }}</p>
        </dd>
      </dl>
      <p id="forget_pass">パスワード未設定の方、または忘れた方は<a href="/password_reset">こちら</a></p>
      <div class="button_ctn">
        <button class="submit" @click="login">ログイン</button>
      </div>
    </div>
  </article>
</template>

<script>
import { store } from '@/store'
import ApiConst from '@/const/api'
import APIClient from '@/store/repository/APIClient'
import MyInput from '@/components/MyInput.vue';
import ErrorComponent from '@/components/ErrorComponent.vue';

import { useField, useForm } from 'vee-validate';
import * as yup from 'yup';
import { useRouter } from 'vue-router'
import {
  isValue
} from '@/helper/index';

const api = new APIClient()

export default {
  components: {
    MyInput,
    ErrorComponent,
  },
  setup() {
    const router = useRouter();

    const schema = yup.object({
      id: yup.string().required('必須の項目です。'),
      password: yup.string().required('必須の項目です。'),
    });

    const { errors, handleSubmit } = useForm({
      validationSchema: schema,
    });

    const login = handleSubmit(async (values) => {
      const res = await api.post(ApiConst.API_LOGIN, {
        login_id: values.id,
        login_pw: values.password,
      });
      const data = res.response.data
      if (data.error_results) {
        return
      }
      if (data.results) {
        store.commit('login/token', data.results.token)
        store.commit('login/limit_datetime', data.results.limit_datetime)
        store.commit('login/refresh_token', data.results.refresh_token)
        store.commit('login/limit_datetime_refresh_token', data.results.limit_datetime_refresh_token)
      }
      const entrydata = await api.GetPreEntryDetail()
      store.commit('entrydata/exhibitor_id', String(entrydata?.data?.results.exhibitor_id))
      store.commit('entrydata/membership_category', String(entrydata?.data?.results.json_data.membership_category))

      const category_div_store = {}
      category_div_store.category_div = [String(entrydata?.data?.results.json_data.category_div1)]
      const category_div2_key = "category_div_" + category_div_store.category_div
      category_div_store[category_div2_key] = entrydata?.data?.results.json_data.category_div2
      store.commit('entrydata/category_div', category_div_store)

      store.commit('entrydata/appeal_information', entrydata?.data?.results.json_data.appeal_information)
      store.commit('entrydata/booth_area', entrydata?.data?.results.json_data.order_area)
      store.commit('entrydata/calced_unit_price', entrydata?.data?.results.json_data.order_tanka)
      store.commit('entrydata/calced_unit', entrydata?.data?.results.json_data.order_unit)
      store.commit('entrydata/calced_exhibition_fee', (entrydata?.data?.results.json_data.order_kingaku)?.toLocaleString())
      store.commit('entrydata/calced_application_fee', (entrydata?.data?.results.json_data.order_deposit)?.toLocaleString())  
      store.commit('entrydata/joint_adjacent', String(entrydata?.data?.results.json_data.joint_flag ?? "0"))
      store.commit('entrydata/joint_exhibition', entrydata?.data?.results.json_data.jointly)
      store.commit('entrydata/adjacent_exhibition', entrydata?.data?.results.json_data.adjoin)
      store.commit('entrydata/exhibitor_name', entrydata?.data?.results.json_data.exhibitor_name)
      store.commit('entrydata/exhibitor_name_kana', entrydata?.data?.results.json_data.exhibitor_name_kana)
      store.commit('entrydata/exhibitor_name_en', entrydata?.data?.results.json_data.exhibitor_name_en)
      store.commit('entrydata/postCode', entrydata?.data?.results.json_data.exhibitor_zip)
      store.commit('entrydata/address_pref', entrydata?.data?.results.json_data.exhibitor_pref)
      store.commit('entrydata/address_jp', entrydata?.data?.results.json_data.exhibitor_address)
      store.commit('entrydata/address_en', entrydata?.data?.results.json_data.exhibitor_address_en)
      store.commit('entrydata/country_main', String(entrydata?.data?.results.json_data.exhibitor_country))
      store.commit('entrydata/country_other', String(entrydata?.data?.results.json_data.exhibitor_country_other))
      store.commit('entrydata/representative_position', entrydata?.data?.results.json_data.representative_post)
      store.commit('entrydata/representative_last_name', entrydata?.data?.results.json_data.representative_last_name)
      store.commit('entrydata/representative_first_name', entrydata?.data?.results.json_data.representative_first_name)
      store.commit('entrydata/brand1_name_jp', entrydata?.data?.results.json_data.brand_name_1)
      store.commit('entrydata/brand1_name_en', entrydata?.data?.results.json_data.brand_name_en_1)
      store.commit('entrydata/brand1_country_main', String(entrydata?.data?.results.json_data.brand_country_1))
      store.commit('entrydata/brand1_country_other', String(entrydata?.data?.results.json_data.brand_country_other_1))
      store.commit('entrydata/brand2_name_jp', entrydata?.data?.results.json_data.brand_name_2)
      store.commit('entrydata/brand2_name_en', entrydata?.data?.results.json_data.brand_name_en_2)
      store.commit('entrydata/brand2_country_main', String(entrydata?.data?.results.json_data.brand_country_2))
      store.commit('entrydata/brand2_country_other', String(entrydata?.data?.results.json_data.brand_country_other_2))
      store.commit('entrydata/brand3_name_jp', entrydata?.data?.results.json_data.brand_name_3)
      store.commit('entrydata/brand3_name_en', entrydata?.data?.results.json_data.brand_name_en_3)
      store.commit('entrydata/brand3_country_main', String(entrydata?.data?.results.json_data.brand_country_3))
      store.commit('entrydata/brand3_country_other', String(entrydata?.data?.results.json_data.brand_country_other_3))
      store.commit('entrydata/brand4_name_jp', entrydata?.data?.results.json_data.brand_name_4)
      store.commit('entrydata/brand4_name_en', entrydata?.data?.results.json_data.brand_name_en_4)
      store.commit('entrydata/brand4_country_main', String(entrydata?.data?.results.json_data.brand_country_4))
      store.commit('entrydata/brand4_country_other', String(entrydata?.data?.results.json_data.brand_country_other_4))
      store.commit('entrydata/brand5_name_jp', entrydata?.data?.results.json_data.brand_name_5)
      store.commit('entrydata/brand5_name_en', entrydata?.data?.results.json_data.brand_name_en_5)
      store.commit('entrydata/brand5_country_main', String(entrydata?.data?.results.json_data.brand_country_5))
      store.commit('entrydata/brand5_country_other', String(entrydata?.data?.results.json_data.brand_country_other_5))
      store.commit('entrydata/deputy_exhibitor_name', entrydata?.data?.results.json_data.agent_name)
      store.commit('entrydata/deputy_exhibitor_name_kana', entrydata?.data?.results.json_data.agent_name_kana)
      store.commit('entrydata/deputy_exhibitor_name_en', entrydata?.data?.results.json_data.agent_name_en)
      store.commit('entrydata/deputy_representative_position', entrydata?.data?.results.json_data.agent_representative_post)
      store.commit('entrydata/deputy_representative_last_name', entrydata?.data?.results.json_data.agent_representative_last_name)
      store.commit('entrydata/deputy_representative_first_name', entrydata?.data?.results.json_data.agent_representative_first_name)

      store.commit('entrydata/staff_department', entrydata?.data?.results.json_data.staff_department)
      store.commit('entrydata/staff_position', entrydata?.data?.results.json_data.staff_post)
      store.commit('entrydata/staff_last_name', entrydata?.data?.results.json_data.staff_last_name)
      store.commit('entrydata/staff_first_name', entrydata?.data?.results.json_data.staff_first_name)
      store.commit('entrydata/manager_postCode', entrydata?.data?.results.json_data.staff_zip)
      store.commit('entrydata/manager_address_pref', entrydata?.data?.results.json_data.staff_pref)
      store.commit('entrydata/manager_address_jp', entrydata?.data?.results.json_data.staff_address)
      store.commit('entrydata/manager_address_en', entrydata?.data?.results.json_data.staff_address_en)
      store.commit('entrydata/staff_tel', entrydata?.data?.results.json_data.staff_tel)
      store.commit('entrydata/staff_email', entrydata?.data?.results.json_data.staff_email)

      store.commit('entrydata/koma_bango', entrydata?.data?.results.json_data.koma_bango)
      store.commit('entrydata/staff_authority', entrydata?.data?.results.json_data.staff_authority)
      //副担当を入れる
      const deputies = [
        {
          department: entrydata?.data?.results.json_data.staff_department_1,
          position: entrydata?.data?.results.json_data.staff_post_1,
          last_name: entrydata?.data?.results.json_data.staff_last_name_1,
          first_name: entrydata?.data?.results.json_data.staff_first_name_1,
          postCode: entrydata?.data?.results.json_data.staff_zip_1,
          address_pref: entrydata?.data?.results.json_data.staff_pref_1,
          address_jp: entrydata?.data?.results.json_data.staff_address_1,
          address_en: entrydata?.data?.results.json_data.staff_address_en_1,
          tel: entrydata?.data?.results.json_data.staff_tel_1,
          email: entrydata?.data?.results.json_data.staff_email_1,
          staff_authority: entrydata?.data?.results.json_data.staff_authority_1,
        },
        {
          department: entrydata?.data?.results.json_data.staff_department_2,
          position: entrydata?.data?.results.json_data.staff_post_2,
          last_name: entrydata?.data?.results.json_data.staff_last_name_2,
          first_name: entrydata?.data?.results.json_data.staff_first_name_2,
          postCode: entrydata?.data?.results.json_data.staff_zip_2,
          address_pref: entrydata?.data?.results.json_data.staff_pref_2,
          address_jp: entrydata?.data?.results.json_data.staff_address_2,
          address_en: entrydata?.data?.results.json_data.staff_address_en_2,
          tel: entrydata?.data?.results.json_data.staff_tel_2,
          email: entrydata?.data?.results.json_data.staff_email_2,
          staff_authority: entrydata?.data?.results.json_data.staff_authority_2,
        },
        {
          department: entrydata?.data?.results.json_data.staff_department_3,
          position: entrydata?.data?.results.json_data.staff_post_3,
          last_name: entrydata?.data?.results.json_data.staff_last_name_3,
          first_name: entrydata?.data?.results.json_data.staff_first_name_3,
          postCode: entrydata?.data?.results.json_data.staff_zip_3,
          address_pref: entrydata?.data?.results.json_data.staff_pref_3,
          address_jp: entrydata?.data?.results.json_data.staff_address_3,
          address_en: entrydata?.data?.results.json_data.staff_address_en_3,
          tel: entrydata?.data?.results.json_data.staff_tel_3,
          email: entrydata?.data?.results.json_data.staff_email_3,
          staff_authority: entrydata?.data?.results.json_data.staff_authority_3,
        },
        {
          department: entrydata?.data?.results.json_data.staff_department_4,
          position: entrydata?.data?.results.json_data.staff_post_4,
          last_name: entrydata?.data?.results.json_data.staff_last_name_4,
          first_name: entrydata?.data?.results.json_data.staff_first_name_4,
          postCode: entrydata?.data?.results.json_data.staff_zip_4,
          address_pref: entrydata?.data?.results.json_data.staff_pref_4,
          address_jp: entrydata?.data?.results.json_data.staff_address_4,
          address_en: entrydata?.data?.results.json_data.staff_address_en_4,
          tel: entrydata?.data?.results.json_data.staff_tel_4,
          email: entrydata?.data?.results.json_data.staff_email_4,
          staff_authority: entrydata?.data?.results.json_data.staff_authority_4,
        },
      ]
      store.commit('entrydata/deputies', deputies.filter(item => {
        for (let k in item) {
          if (!isValue(item[k])) {
            delete item[k];
          }
        }
        if (Object.values(item).length > 0) {
          return item
        }
      }))

      router.push({ name: 'logged_induction' });
    });

    const { value: id } = useField('id', null, {
      validateOnValueUpdate: false,
    });

    const { value: password } = useField('password', null, {
      validateOnValueUpdate: false,
    });

    return {
      errors,
      id,
      password,

      login,
    };
  },
  data() {
    return {
    }
  },
  async created() {
    await api.get(`/sanctum/csrf-cookie`);
  },
  computed: {
    errorList() {
      return this.$store.state.message.errors
    },
  }
};
</script>

<style>

</style>
