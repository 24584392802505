<template>
  <header>
    <h1>JAPAN MOBILITY SHOW 2025 <br class="pc_none" />出展者登録サイト</h1>
  </header>
  <article id="p2">
    <PhaseHeader />
    <div id="app">
      <div id="form_ctn" class="form_box">
        <PhaseNav :current="2" :category="2" :categoryTop="false" pageName="電気工事落成届" />

        <div id="form_ctn" class="form_box">
          <h3>電気工事落成届</h3>

          <div class="entry_ctn">
            <ul class="common_ul">
              <li>
                電気工事落成届に関するお問合せは東京ビッグサイト事務局にて承ります。<br />
                <ModalTokyoBigSight />
              </li>
            </ul>

            <div class="one_entry_contents">
              <h4>電気工事落成届</h4>
              <div class="one_entry_contents_inner">
                <ol class="common_ol">
                  <li>この書類は小間内の電気工事終了後、JAPAN MOBILITY SHOW 2025会場（東京ビッグサイト）の東ホール/西ホール
                    事務局に提出してください。</li>
                  <li>この書類の提出後、小間内への送電を行います。</li>
                </ol>

                <ul class="common_ul">
                  <li class="attention_color">幹線工事費は9月下旬に請求書を発行しますので10月31日(火)までに入金してください。<br>
                    また、電気使用料は会期終了後、別途請求いたします。</li>
                </ul>


                <h5>電気工事落成届の提出</h5>
                <ol class="common_ol">
                  <li>ダウンロードボタンをクリックして送付用エクセルファイルをダウンロードしてください。<br>
                    <a @click="download" class="common_add_button">記入用紙のダウンロード</a>
                  </li>
                  <li>ダウンロードしたファイルを印刷してください。</li>
                  <li>工事完了後、印刷した電気工事落成届に必要事項を記入してください。</li>
                  <li>東ホール/西ホール事務局まで、<span class="attention_color">２部</span>提出してください。</li>
                </ol>
              </div>
            </div>
          </div>
          <!-- / 広報・宣伝印刷物申込 -->

          <p class="ta_center">
            <router-link :to="{ name: 'p3_exhibition_index' }" class="common_button_t2">
              &laquo; 出展・展示関係トップへ戻る
            </router-link>
          </p>
        </div>
      </div>

    </div>
  </article>
</template>

<script setup>
import APIClient from '@/store/repository/APIClient'
import PhaseHeader from '@/components/PhaseHeader'
import PhaseNav from "@/components/PhaseNav"
import ModalTokyoBigSight from "@/components/modal/ModalTokyoBigSight"

import ApiConst from '@/const/api'

const api = new APIClient()

const download = async () => {
  try {
    const query = {
      application_div_form_no : "electro_completed_1"
    }
    const fetchApi = await api.post(ApiConst.API_APP_PDF_DOWNLOAD, query)
    const res = fetchApi.response
    const errors = res.data?.error_results
    if (errors) {
      throw new Error('Not found.')
    }
    await api.download(res.data.results.url)
  } catch (e) {
    console.log(e)
    return false
  }
}
</script>

<style></style>
